import React from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';

function Objectcreator(props) {
    const handleChange = (value) => {
        if (props.onChange != undefined) {
            props.onChange(value);
        }
    }

    const renderOptions = (options) => {
        return (
            options.map((option,key) => 
                <option key={key} value={option.value}>{option.label}</option>
            )
        );
    }

    return(
        <div className="objectcreator">
            {props.value != undefined ?
                <div>
                    {props.value.map((value, key) => 
                        <div className="row" key={key}>
                            <div className="col col-md-3" style={{ marginBottom: "0px" }}>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        disabled={props.options.disabled}
                                        onChange={(evt) => {
                                            let temp = props.value;
                                            temp[key].title = evt.target.value;
                                            handleChange(temp);
                                        }}
                                        placeholder={props.options?.placeholderTitle ?? ""}
                                        value={value.title}
                                    />
                                </InputGroup>
                            </div>
                            <div className="col col-md-2" style={{ marginBottom: "0px" }}>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        as="select"
                                        disabled={props.disabled || props.options.objectType != undefined}
                                        onChange={(evt) => {
                                            let temp = props.value;
                                            temp[key].type = evt.currentTarget.value;
                                            handleChange(temp);
                                        }}
                                        //onChange={(evt) => { handleChange({label: evt.currentTarget.value, value: evt.currentTarget.value})}}
                                        value={props.options.objectType == undefined ? value.type : value.type = props.options.objectType}
                                    >
                                        {props.options != undefined && props.options.choosetext != undefined && props.options.objectType == undefined ? 
                                            <option value="">{props.options.choosetext}</option>
                                        : ""}
                                        {renderOptions(props.options.objectType != undefined ? props.options?.types.filter(i => i.value == props.options.objectType) ?? [] : props.options?.types)}
                                    </Form.Control>
                                </InputGroup>
                            </div>
                            <div className="col col-md-4" style={{ marginBottom: "0px" }}>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        disabled={props.disabled ? props.disabled : value.type == "" || value.type == "occurence" ? true : false}
                                        onChange={(evt) => {
                                            let temp = props.value;
                                            temp[key].comment = evt.target.value;
                                            handleChange(temp);
                                        }}
                                        placeholder={props.options?.placeholderComment ?? ""}
                                        value={value.comment}
                                    />
                                </InputGroup>
                            </div>
                            <div className="col col-md-2" style={{ marginBottom: "0px" }}>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        as="select"
                                        disabled={value.type == "occurence" || value.type == "systemvalue" ? false : true}
                                        onChange={(evt) => {
                                            let temp = props.value;
                                            temp[key].typeOfType = evt.currentTarget.value;
                                            handleChange(temp);
                                        }}
                                        //onChange={(evt) => { handleChange({label: evt.currentTarget.value, value: evt.currentTarget.value})}}
                                        value={value.typeOfType}
                                    >
                                        
                                    {value.type == "occurence" || value.type == "systemvalue" ? props.options != undefined && props.options.choosetext != undefined ? <option value="">{props.options.choosetext}</option> : "" : ""} 
                                    {value.type != "occurence" && value.type != "systemvalue" && props.options != undefined && props.options.unavailableText != undefined ? <option value="">{props.options.unavailableText}</option> : ""} 
                                    {renderOptions(props.options?.types?.find(type => type.value == value.type)?.types ?? [])}

                                    </Form.Control>
                                </InputGroup>
                            </div>
                            <div className="col col-md-1" style={{ marginBottom: "0px" }}>
                                {!props.disabled ?
                                    <Button
                                        onClick={ () => {
                                            let temp = props.value;
                                            temp.splice(key, 1);
                                            handleChange(temp);
                                        } }
                                    >{props.options?.deleteButtonText ?? ""}</Button>
                                : null}
                            </div>
                        </div>
                    )}
                </div>
            : null}
            {!props.disabled ?
                <Button
                    disabled={props.options.addBtnDisabled != undefined ? props.options.addBtnDisabled : false}
                    onClick={ () => {
                        handleChange([...(props.value != undefined ? props.value : []), {title: "", comment: "", type: ""}]);
                    } }
                    className="align-left"
                >{props.options?.addButtonText ?? ""}</Button>
            : null}
        </div>
    );
}

export default Objectcreator;