export const config = {
    appId: 'a710c0be-ef0e-459f-a1bf-f01fd01868ab',
    redirectUri: window.location.origin + '/loggingin/',
    scopes: [
        'user.read'
    ]
};

export const API_URL =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
    ? "https://test.diri.ai"
    : "";

export const generateUrl = (path: string) => {
    return `${API_URL}${path}`
}