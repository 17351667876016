import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import {
  ListGroup,
  Modal,
  InputGroup,
  Form,
  Button,
  FormGroup,
  Accordion,
  Spinner,
} from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";
import { Grid, Switch, Tooltip } from "@material-ui/core";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Doughnut } from "react-chartjs-2";

import { useAuth } from "../../../../../../Auth";
import { useAxios } from "../../../../../../AxiosHandler";

import BowtieTreatmentHandler from "../BowtieTreatmentHandler/BowtieTreatmentHandler";
import { useDynamicLanguage } from "../../../../../../DynamicLanguageProvider";
import Field from "../../../../../Shared/Wizard/Field/Field";
import PageTable from "../../../../../Shared/PageTable/PageTable";
import TreatmentEditorModal from "../../../../../Shared/Modals/Edit/TreatmentEditorModal";

import { generateUrl } from "../../../../../../config";

import "./BowtieCauseList.scss";
import TreatmentSelectorModal from "../../../../../Shared/Modals/TreatmentSelectorModal/TreatmentSelectorModal";

interface BowtieCauseListInterface {
  allTreatments: any;
  allOccurences: any;
  occurenceId: string;
  onCausesChanged: () => void;
  system: any;
  allCauses: any;
  showCauseModal: boolean;
}

interface ThreadsInterface {
  id: string;
}

function BowtieCauseList({
  allTreatments,
  allOccurences,
  occurenceId,
  onCausesChanged,
  system,
  allCauses,
  showCauseModal,
}: BowtieCauseListInterface) {
  const t = useTranslate();
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const td = useDynamicLanguage().td;

  let { systemid } = useParams();
  const myRef = React.useRef<any>();

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const [copyingChecked, setCopyingChecked] = useState(false);
  const [showDeleteCauseModal, setShowDeleteCauseModal] = useState(false);
  const [showEditCauseModal, setShowEditCauseModal] = useState(false);
  const [showNewCauseModal, setShowNewCauseModal] = useState(false);
  const [showExistingCauseModal, setShowExistingCauseModal] = useState(false);

  const [causes, setCauses] = useState([]);
  const [newCauseName, setNewCauseName] = useState("");
  const [newCauseCategory, setNewCauseCategory] = useState("");
  const [newCauseProbability, setNewCauseProbability] = useState("2");
  const [newCauseDescription, setNewCauseDescripton] = useState("");
  const [newCauseThreats, setNewCauseThreats] = useState([]);
  const [deleteCause, setDeleteCause] = useState("");
  const [editCause, setEditCause] = useState({});
  const [showLoadingCauses, setShowLoadingCauses] = useState(false);
  const [users, setUsers] = useState<Array<any>>([]);
  const [existingCauses, setExistingCauses] = useState<Array<any>>([]);

  const labelNone = t("treatment.label_none");
  const labelLow = t("treatment.label_low");
  const labelMiddel = t("treatment.label_middel");
  const labelHigh = t("treatment.label_high");
  const labelExtremelyHigh = t("treatment.label_extremely_high");

  const [editTreatmentId, setEditTreatmentId] = useState();
  const [showEditTreatmentModal, setShowEditTreatmentModal] = useState(false);

  const handleShowEditTreatmentModal = (object) => {
    setEditTreatmentId(object["_id"]);
    setShowEditTreatmentModal(true);
  };

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  let userdata = {};

  if (!!localStorageUser) {
    settings = JSON.parse(localStorageUser)["settings"];
    userdata = JSON.parse(localStorageUser)["userdata"];
  }

  const [loading, setLoading] = useState(false);
  const handleCloseNewCauseModal = () => setShowNewCauseModal(false);
  const handleCloseExistingCauseModal = () => {
    setShowExistingCauseModal(false);
    fetchCauses(occurenceId);
    onCausesChanged();
    setExistingCauses([]);
  };
  const handleShowNewCauseModal = () => setShowNewCauseModal(true);
  const handleShowExistingCauseModal = () => setShowExistingCauseModal(true);
  const handleCloseDeleteCauseModal = () => setShowDeleteCauseModal(false);
  const handleShowDeleteCauseModal = (evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    setDeleteCause(evt.target.getAttribute("data-causeid"));
    setShowDeleteCauseModal(true);
  };

  const handleCloseEditCauseModal = () => setShowEditCauseModal(false);
  const handleShowEditCauseModal = (evt) => {
    if (evt.target.getAttribute("data-causeid") != undefined) {
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
      setEditCause(
        causes.filter(
          (item) => item["_id"] === evt.target.getAttribute("data-causeid")
        )[0]
      );
      setShowEditCauseModal(true);
    }
  };

  const setEditCauseName = (value) => {
    setEditCause((editCause) => ({ ...editCause, name: value }));
  };
  const setEditCauseCategory = (value) => {
    setEditCause((editCause) => ({ ...editCause, category: value }));
  };
  const setEditCauseOccurence = (value) => {
    setEditCause((editCause) => ({ ...editCause, occurence: value }));
  };
  const setEditCauseOccurences = (value) => {
    if (value.length === 0) {
      setEditCauseOccurence("");
    }
    setEditCause((editCause) => ({ ...editCause, occurences: value }));
  };
  const setEditCauseProbability = (value) => {
    setEditCause((editCause) => ({ ...editCause, probability: value }));
  };
  const setEditCauseDescription = (value) => {
    setEditCause((editCause) => ({ ...editCause, description: value }));
  };

  const newCauseNameChange = (evt) => {
    setNewCauseName(evt.target.value);
  };

  const newCauseCategoryChange = (evt) => {
    setNewCauseCategory(evt.target.value);
  };

  const newCauseProbabilityChange = (evt) => {
    setNewCauseProbability(evt.target.value);
  };

  const changeNewCauseThreats = (key, value) => {
    let temp = newCauseThreats;
    (temp[key] as any) = value;
    setNewCauseThreats(JSON.parse(JSON.stringify(temp)));
  };

  const addNewCauseThreats = () => {
    let temp = newCauseThreats;
    (temp as Array<any>).push({ id: "" });
    setNewCauseThreats(JSON.parse(JSON.stringify(temp)));
  };

  const deleteNewCauseThreats = (key) => {
    let temp = newCauseThreats;
    temp.splice(key, 1);
    setNewCauseThreats(JSON.parse(JSON.stringify(temp)));
  };

  const addEditCauseThreats = () => {
    let temp = editCause;
    temp["threats"].push("");
    setEditCause(JSON.parse(JSON.stringify(temp)));
  };

  const changeEditCauseThreats = (key, value) => {
    let temp = editCause;
    temp["threats"][key] = value;
    setEditCause(JSON.parse(JSON.stringify(temp)));
  };

  const deleteEditCauseThreats = (key) => {
    let temp = editCause;
    temp["threats"].splice(key, 1);
    setEditCause(JSON.parse(JSON.stringify(temp)));
  };

  function valueToPercentage(value, maxvalue) {
    return ((value - 1) / (maxvalue - 1)) * 100;
  }

  function percentageToValue(percentage, maxvalue) {
    return (maxvalue - 1) * (percentage / 100) + 1;
  }

  function fetchCauses(occurenceid) {
    setCauses([]);
    setShowLoadingCauses(true);
    axios
      .single({
        method: "get",
        url: generateUrl("/api/cra/causes/bysystem/") + systemid,
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        res.data.forEach((cause) => {
          cause["probability"] = percentageToValue(
            cause["probability"],
            settings["gridY"]
          );
          if (
            (cause.occurences === undefined || cause.occurences.length === 0) &&
            cause.occurence != ""
          ) {
            cause.occurences = [
              {
                value: cause.occurence,
                label: allOccurences.find((i) => i._id === cause.occurence)
                  ?.name,
              },
            ];
          }
        });
        if (occurenceid != 0 && occurenceid != 999999) {
          setCauses(
            res.data.filter((item) =>
              item.occurences.map((o) => o.value).includes(occurenceid)
            )
          );
        }
        if (occurenceid === 0) {
          setCauses(res.data);
        }
        if (occurenceid === 999999) {
          setCauses(res.data.filter((item) => item.occurences.length === 0));
        }
        setShowLoadingCauses(false);
      });
  }

  const handleKeyDownCauseCreate = (event) => {
    if (event.key === "Enter") {
      handleCreateCause();
    }
  };

  const handleKeyDownCauseEdit = (event) => {
    if (event.key === "Enter") {
      handleEditCause();
    }
  };

  const handleCreateCause = () => {
    if (newCauseName === "") {
      notifyWarning(t("bowtie.toast_empty_name"));
      return undefined;
    }
    if (!newCauseCategory) {
      notifyWarning(t("bowtie.toast_empty_category"));
      return undefined;
    }

    if (newCauseName.length < 3) {
      notifyWarning(t("bowtie.toast_length"));
      return undefined;
    }

    axios
      .post(
        generateUrl("/api/cra/causes/"),
        {
          name: newCauseName,
          category: newCauseCategory,
          system: systemid,
          occurence: !!occurenceId ? occurenceId : "",
          occurences: !!occurenceId
            ? [
                {
                  value: occurenceId,
                  label: allOccurences.find((i) => i._id === occurenceId)?.name,
                },
              ]
            : [],
          probability: valueToPercentage(
            newCauseProbability,
            settings["gridY"]
          ),
          description: newCauseDescription,
          threats: newCauseThreats,
          tags: [],
          text: "",
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (_) {
        fetchCauses(occurenceId);
        notifySuccess(t("bowtie.toast_cause_created"));
        setShowNewCauseModal(false);
        onCausesChanged();
        setNewCauseThreats([]);
        setNewCauseName("");
        setNewCauseCategory("");
        setNewCauseDescripton("");
        setNewCauseProbability("2");
      });
  };

  const handleDeleteCause = () => {
    axios
      .delete(
        generateUrl("/api/cra/causes/") + deleteCause,
        {
          _id: deleteCause,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (_) {
        fetchCauses(occurenceId);
        notifySuccess(t("bowtie.toast_cause_deleted"));
        setShowDeleteCauseModal(false);
        onCausesChanged();
      });
  };

  function handleAttachExisting(cause) {
    if (
      (cause.occurences === undefined || cause.occurences.length === 0) &&
      cause.occurence != ""
    ) {
      cause.occurences = [
        {
          value: cause.occurence,
          label: allOccurences.find((i) => i._id === cause.occurence)?.name,
        },
      ];
    }

    axios
      .put(
        generateUrl("/api/cra/causes/") + cause["_id"],
        {
          name: cause["name"],
          category: cause["category"] != undefined ? cause["category"] : "",
          occurence: cause["occurence"],
          occurences: [
            ...cause["occurences"].filter((o) => o.value != occurenceId),
            {
              value: occurenceId,
              label: allOccurences.find((o) => o._id === occurenceId)?.name,
            },
          ],
          systemvalue: cause["systemvalue"],
          probability: cause["probability"],
          costMin: cause["costMin"],
          costMax: cause["costMax"],
          costAvg: cause["costAvg"],
          description: cause["description"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (_) {
        handleCloseExistingCauseModal();
      });
  }

  const addOrRemove = (cause: any) => {
    if (!isCauseSelected(cause["_id"]))
      setExistingCauses([...existingCauses, cause]);
  };

  function handleCopyExisting(cause) {
    addOrRemove(cause);
  }

  const getLastAddedCaues = (cause) => {
    return existingCauses.length
      ? existingCauses[existingCauses.length - 1]["_id"]
      : cause["_id"];
  };

  const isCauseSelected = (id) => {
    return existingCauses.some((e) => e["_id"] === id);
  };

  function copyExistingCause(cause, withTreatments) {
    if (!isCauseSelected(cause["_id"])) {
      setLoading(true);
      axios
        .post(
          generateUrl("/api/cra/causes/"),
          {
            name: cause["name"],
            system: systemid,
            category: cause["category"] != undefined ? cause["category"] : "",
            occurence: cause["occurence"],
            occurences: [
              {
                value: occurenceId,
                label: allOccurences.find((o) => o._id === occurenceId)?.name,
              },
            ],
            systemvalue: cause["systemvalue"],
            probability: cause["probability"],
            threats: cause["threats"],
            costMin: cause["costMin"],
            costMax: cause["costMax"],
            costAvg: cause["costAvg"],
            description: cause["description"],
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then(function (res) {
          handleCopyExisting({
            ...cause,
            newCause: res.data["_id"],
            treatmentCopied: withTreatments,
          });
          notifySuccess(t("bowtie.toast_cause_created"));
          setLoading(false);
          if (withTreatments) {
            handleAddTreatmentConnection(
              cause.treatments.map((e) => e),
              res.data["_id"]
            );
          }
        });
    } else {
      notifyWarning(t("bowtie.treatment_already_added"));
    }
  }

  const handleAddTreatmentConnection = (selectedTreatments, causeId) => {
    setLoading(true);
    axios
      .post(
        generateUrl("/api/cra/treatments/addToCause"),
        {
          treatments: selectedTreatments,
          cause: [causeId],
          system: systemid,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((_) => {
        setLoading(false);
        notifySuccess(t("treatments.toast.treatment_edited"));
      });
  };

  function fetchTreatmentsByCause(cause) {
    axios
      .single({
        method: "get",
        url: generateUrl("/api/cra/treatments/bycause/") + cause["_id"],
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        if (!!res.data.length) {
          handleAddTreatmentConnection(
            res.data.map((e) => e["_id"]),
            existingCauses.filter((e) => e["_id"] === cause["_id"])[0].newCause
          );

          const updateExistingCauses = [...existingCauses];
          const idx = updateExistingCauses.findIndex(
            (e) => e["_id"] === cause["_id"]
          );
          updateExistingCauses[idx].treatmentCopied = true;
          handleCopyExisting(updateExistingCauses);
        }
      });
  }

  const handleEditCause = () => {
    if (editCause["name"] === "") {
      notifyWarning(t("bowtie.toast_empty_name"));
      return undefined;
    }
    if (editCause["category"] === "" || editCause["category"] === undefined) {
      notifyWarning(t("bowtie.toast_empty_category"));
      return undefined;
    }

    if (editCause["name"].length < 3) {
      notifyWarning(t("bowtie.toast_length"));
      return undefined;
    }

    axios
      .put(
        generateUrl("/api/cra/causes/") + editCause["_id"],
        {
          name: editCause["name"],
          category: editCause["category"],
          occurence: editCause["occurence"],
          occurences: editCause["occurences"],
          description: editCause["description"],
          threats: editCause["threats"],
          probability: valueToPercentage(
            editCause["probability"],
            settings["gridY"]
          ),
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (_) {
        fetchCauses(occurenceId);
        setShowEditCauseModal(false);
        onCausesChanged();
      });
  };

  function toggleCopyingChecked() {
    setCopyingChecked(!copyingChecked);
  }

  async function fetchUsers() {
    axios
      .single({
        method: "post",
        url: generateUrl("/api/users/names"),
        data: allTreatments
          .map((i) => i.responsible)
          .filter((i) => i?.length === 24 ?? null),
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        setUsers(res.data);
      });
  }

  function readableProbability(probability) {
    if (probability <= 5) {
      return labelNone;
    } else if (probability > 5 && probability <= 25) {
      return labelLow;
    } else if (probability > 25 && probability <= 50) {
      return labelMiddel;
    } else if (probability > 50 && probability <= 75) {
      return labelHigh;
    } else if (probability > 75 && probability <= 95) {
      return labelExtremelyHigh;
    }
  }

  useEffect(() => {
    fetchUsers();
  }, [allTreatments]);

  useEffect(() => {
    fetchCauses(occurenceId);
    setCauses(allCauses);
  }, [occurenceId, allOccurences, allCauses]);

  useEffect(() => {
    if (showCauseModal) handleShowNewCauseModal();
  }, [showCauseModal]);

  return (
    <div className="bowtiecauselist">
      <div className="listheader">
        <h3>{t("bowtie.causes_title")}</h3>
        <Button
          className="newCauseBtn btn-with-icon"
          disabled={!system["accesses"]?.includes("edit") ?? true}
          onClick={handleShowNewCauseModal}
        >
          <i className="dripicons-plus" />
          {t("bowtie.causes_new")}
        </Button>

        <Button
          className="newCauseBtn btn-with-icon"
          disabled={!system["accesses"]?.includes("edit") || !occurenceId}
          style={{ marginLeft: "10px" }}
          onClick={handleShowExistingCauseModal}
        >
          <i className="dripicons-plus" />
          {t("bowtie.cause_existing")}
        </Button>
      </div>
      <ListGroup>
        {causes.map((item, key) => {
          let probability =
            item["probability"] -
            allTreatments
              .filter(
                (treatment) =>
                  treatment["cause"].includes(item["_id"]) &&
                  treatment["status"] === "closed"
              )
              .map((treatment) => treatment["probabilityreduction"])
              .reduce((total, number) => total + number, 0);
          return (
            <div className="bowtie-list-item" key={key}>
              <ListGroup.Item
                key={key}
                data-causeid={item["_id"]}
                onClick={handleShowEditCauseModal}
              >
                <p className="title" data-causeid={item["_id"]}>
                  {item["name"]}
                </p>
                <div
                  className="treatmentstatuscontainer"
                  data-causeid={item["_id"]}
                >
                  <div className={"statusindicator"} data-causeid={item["_id"]}>
                    {Number(settings["gridY"]) === 3 ? (
                      <div>
                        {Math.round(probability) <= 0 ? (
                          <div>
                            <span className="probability1">&nbsp;</span>
                            <span>{td(settings["gridYName1"])}</span>
                          </div>
                        ) : Math.round(probability) === 1 ? (
                          <div>
                            <span className="probability1">&nbsp;</span>
                            <span>{td(settings["gridYName1"])}</span>
                          </div>
                        ) : Math.round(probability) === 2 ? (
                          <div>
                            <span className="probability3">&nbsp;</span>
                            <span>{td(settings["gridYName2"])}</span>
                          </div>
                        ) : Math.round(probability) === 3 ? (
                          <div>
                            <span className="probability6">&nbsp;</span>
                            <span>{td(settings["gridYName3"])}</span>
                          </div>
                        ) : null}
                      </div>
                    ) : Number(settings["gridY"]) === 4 ? (
                      <div>
                        {Math.round(probability) <= 0 ? (
                          <div>
                            <span className="probability1">&nbsp;</span>
                            <span>{td(settings["gridYName1"])}</span>
                          </div>
                        ) : Math.round(probability) === 1 ? (
                          <div>
                            <span className="probability1">&nbsp;</span>
                            <span>{td(settings["gridYName1"])}</span>
                          </div>
                        ) : Math.round(probability) === 2 ? (
                          <div>
                            <span className="probability3">&nbsp;</span>
                            <span>{td(settings["gridYName2"])}</span>
                          </div>
                        ) : Math.round(probability) === 3 ? (
                          <div>
                            <span className="probability4">&nbsp;</span>
                            <span>{td(settings["gridYName3"])}</span>
                          </div>
                        ) : Math.round(probability) === 4 ? (
                          <div>
                            <span className="probability6">&nbsp;</span>
                            <span>{td(settings["gridYName4"])}</span>
                          </div>
                        ) : null}
                      </div>
                    ) : Number(settings["gridY"]) === 5 ? (
                      <div>
                        {Math.round(probability) <= 0 ? (
                          <div>
                            <span className="probability1">&nbsp;</span>
                            <span>{td(settings["gridYName1"])}</span>
                          </div>
                        ) : Math.round(probability) === 1 ? (
                          <div>
                            <span className="probability1">&nbsp;</span>
                            <span>{td(settings["gridYName1"])}</span>
                          </div>
                        ) : Math.round(probability) === 2 ? (
                          <div>
                            <span className="probability3">&nbsp;</span>
                            <span>{td(settings["gridYName2"])}</span>
                          </div>
                        ) : Math.round(probability) === 3 ? (
                          <div>
                            <span className="probability4">&nbsp;</span>
                            <span>{td(settings["gridYName3"])}</span>
                          </div>
                        ) : Math.round(probability) === 4 ? (
                          <div>
                            <span className="probability5">&nbsp;</span>
                            <span>{td(settings["gridYName4"])}</span>
                          </div>
                        ) : Math.round(probability) === 5 ? (
                          <div>
                            <span className="probability6">&nbsp;</span>
                            <span>{td(settings["gridYName5"])}</span>
                          </div>
                        ) : null}
                      </div>
                    ) : Number(settings["gridY"]) === 6 ? (
                      <div>
                        {Math.round(probability) <= 0 ? (
                          <div>
                            <span className="probability1">&nbsp;</span>
                            <span>{td(settings["gridYName1"])}</span>
                          </div>
                        ) : Math.round(probability) === 1 ? (
                          <div>
                            <span className="probability1">&nbsp;</span>
                            <span>{td(settings["gridYName1"])}</span>
                          </div>
                        ) : Math.round(probability) === 2 ? (
                          <div>
                            <span className="probability2">&nbsp;</span>
                            <span>{td(settings["gridYName2"])}</span>
                          </div>
                        ) : Math.round(probability) === 3 ? (
                          <div>
                            <span className="probability3">&nbsp;</span>
                            <span>{td(settings["gridYName3"])}</span>
                          </div>
                        ) : Math.round(probability) === 4 ? (
                          <div>
                            <span className="probability4">&nbsp;</span>
                            <span>{td(settings["gridYName4"])}</span>
                          </div>
                        ) : Math.round(probability) === 5 ? (
                          <div>
                            <span className="probability5">&nbsp;</span>
                            <span>{td(settings["gridYName5"])}</span>
                          </div>
                        ) : Math.round(probability) === 6 ? (
                          <div>
                            <span className="probability6">&nbsp;</span>
                            <span>{td(settings["gridYName6"])}</span>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div
                  className="treatmentcountcontainer"
                  data-causeid={item["_id"]}
                >
                  <div className="measure" data-causeid={item["_id"]}>
                    <div
                      className={
                        "ongoing " +
                        (allTreatments.filter(
                          (treatment) =>
                            treatment["cause"].includes(item["_id"]) &&
                            treatment["status"] === "processing"
                        ).length === 0
                          ? "zero"
                          : "")
                      }
                      data-causeid={item["_id"]}
                    >
                      <p>
                        {
                          allTreatments.filter(
                            (treatment) =>
                              treatment["cause"].includes(item["_id"]) &&
                              treatment["status"] === "processing"
                          ).length
                        }
                        /
                        {
                          allTreatments.filter((treatment) =>
                            treatment["cause"].includes(item["_id"])
                          ).length
                        }
                      </p>
                      <div style={{ width: "70px" }}>
                        <Doughnut
                          redraw={false}
                          data={
                            allTreatments.filter(
                              (treatment) =>
                                treatment["cause"].includes(item["_id"]) &&
                                treatment["status"] === "processing"
                            ).length != 0
                              ? {
                                  datasets: [
                                    {
                                      data: [
                                        allTreatments.filter(
                                          (treatment) =>
                                            treatment["cause"].includes(
                                              item["_id"]
                                            ) &&
                                            treatment["status"] === "processing"
                                        ).length,
                                        allTreatments.filter((treatment) =>
                                          treatment["cause"].includes(
                                            item["_id"]
                                          )
                                        ).length -
                                          allTreatments.filter(
                                            (treatment) =>
                                              treatment["cause"].includes(
                                                item["_id"]
                                              ) &&
                                              treatment["status"] ==
                                                "processing"
                                          ).length,
                                      ],
                                      backgroundColor: [
                                        "rgb(91, 130, 102, 1)",
                                        "rgb(200, 200, 200, 1)",
                                      ],
                                    },
                                  ],
                                  labels: ["Ongoing", "All"],
                                }
                              : {
                                  datasets: [
                                    {
                                      data: [0, 1],
                                      backgroundColor: [
                                        "rgb(91, 130, 102, 1)",
                                        "rgb(200, 200, 200, 1)",
                                      ],
                                    },
                                  ],
                                  labels: ["Ongoing", "All"],
                                }
                          }
                          options={{
                            legend: {
                              display: false,
                            },

                            tooltips: {
                              enabled: false,
                            },
                            cutoutPercentage: 60,
                          }}
                        />
                      </div>
                    </div>
                    <p>
                      {t("dashboard.measures_ongoing_o")}
                      <br />
                      {t("dashboard.measures_ongoing_m")}
                    </p>
                  </div>
                  <div className="measure" data-causeid={item["_id"]}>
                    <div className="closed" data-causeid={item["_id"]}>
                      <p>
                        {
                          allTreatments.filter(
                            (treatment) =>
                              treatment["cause"].includes(item["_id"]) &&
                              treatment["status"] === "closed"
                          ).length
                        }
                        /
                        {
                          allTreatments.filter((treatment) =>
                            treatment["cause"].includes(item["_id"])
                          ).length
                        }
                      </p>
                      <div style={{ width: "70px" }}>
                        <Doughnut
                          redraw={false}
                          data={
                            allTreatments.filter(
                              (treatment) =>
                                treatment["cause"].includes(item["_id"]) &&
                                treatment["status"] === "closed"
                            ).length != 0
                              ? {
                                  datasets: [
                                    {
                                      data: [
                                        allTreatments.filter(
                                          (treatment) =>
                                            treatment["cause"].includes(
                                              item["_id"]
                                            ) &&
                                            treatment["status"] === "closed"
                                        ).length,
                                        allTreatments.filter((treatment) =>
                                          treatment["cause"].includes(
                                            item["_id"]
                                          )
                                        ).length -
                                          allTreatments.filter(
                                            (treatment) =>
                                              treatment["cause"].includes(
                                                item["_id"]
                                              ) &&
                                              treatment["status"] === "closed"
                                          ).length,
                                      ],
                                      backgroundColor: [
                                        "rgb(91, 130, 102, 1)",
                                        "rgb(200, 200, 200, 1)",
                                      ],
                                    },
                                  ],
                                  labels: ["Realized", "All"],
                                }
                              : {
                                  datasets: [
                                    {
                                      data: [0, 1],
                                      backgroundColor: [
                                        "rgb(91, 130, 102, 1)",
                                        "rgb(200, 200, 200, 1)",
                                      ],
                                    },
                                  ],
                                  labels: ["Realized", "All"],
                                }
                          }
                          options={{
                            legend: {
                              display: false,
                            },
                            tooltips: {
                              enabled: false,
                            },
                            cutoutPercentage: 60,
                          }}
                        />
                      </div>
                    </div>
                    <p>
                      {t("dashboard.measures_realized_r")}
                      <br />
                      {t("dashboard.measures_realized_m")}
                    </p>
                  </div>
                </div>

                <div className="operationscontainer" data-causeid={item["_id"]}>
                  <i
                    className="dripicons-trash tableOperationBtn"
                    data-causeid={item["_id"]}
                    onClick={(e) =>
                      system["accesses"].includes("edit")
                        ? handleShowDeleteCauseModal(e)
                        : null
                    }
                  />
                </div>
                <div className="chainconnector causeconnector">
                  <div className="chain-icon">
                    <Droppable droppableId={"cause." + item["_id"]}>
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            position: "absolute",
                            right: "3px",
                            top: "5px",
                          }}
                        >
                          <Draggable
                            key={"cause." + key}
                            draggableId={"cause." + item["_id"]}
                            index={key}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className=""
                              >
                                {item["occurences"] === undefined ||
                                (item["occurences"] as Array<any>).length ==
                                  0 ? (
                                  <i className="dripicons-link-broken" />
                                ) : (item["occurences"] as Array<any>).length >
                                  1 ? (
                                  <React.Fragment>
                                    <i
                                      className="dripicons-link"
                                      style={{
                                        position: "absolute",
                                        left: "-2px",
                                        top: "13px",
                                        fontSize: "16px",
                                        zIndex: 101,
                                      }}
                                    />
                                    <i className="dripicons-link" />
                                  </React.Fragment>
                                ) : (
                                  <i className="dripicons-link" />
                                )}
                              </div>
                            )}
                          </Draggable>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                  <div className="chain-counter">
                    <p>{(item["occurences"] as Array<any>).length}</p>
                  </div>
                </div>
              </ListGroup.Item>
              <Accordion>
                <Accordion.Toggle eventKey="0" aria-label="expand">
                  <i className="dripicons-chevron-down" />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <div>
                    <p>
                      {t("bowtie.causes.category")}:{" "}
                      {!!item["category"] ? td(item["category"]) : null}
                    </p>
                    {!!(item["threats"] as Array<ThreadsInterface>).filter(
                      ({ id }) => id !== ""
                    ).length && (
                      <p>
                        {t("bowtie.threats_label")}:{" "}
                        {(item["threats"] as Array<string>)
                          .map((t) => {
                            return td(t);
                          })
                          .join(", ")}
                      </p>
                    )}
                    <hr />

                    {!!allTreatments.filter((treatment) =>
                      treatment["cause"].includes(item["_id"])
                    ).length && (
                      <>
                        <p className="treatments-title">
                          {t("bowtie.treatments.treatment")}:
                        </p>
                        {allTreatments
                          .filter((treatment) =>
                            treatment["cause"].includes(item["_id"])
                          )
                          .map((treatment, i) => (
                            <div
                              className="treatments-list"
                              key={i}
                              onClick={() =>
                                handleShowEditTreatmentModal(treatment)
                              }
                            >
                              <div className="treatments-list-top-row">
                                <p className="treatment-title">
                                  {t("bowtie.name")}: {treatment.name}
                                </p>
                                <p>
                                  {t(
                                    "bowtie.causes_measure_probability_reduction"
                                  )}
                                  : {readableProbability(treatment.oldvalue)}
                                </p>
                              </div>
                              <p>
                                {t("bowtie.measure_responsible")}:{" "}
                                {treatment.responsible != undefined
                                  ? users.find(
                                      (user) =>
                                        user._id === treatment.responsible
                                    )?.name ?? treatment.responsible
                                  : treatment.responsible}
                              </p>
                              <p>
                                {t("bowtie.status")}:{" "}
                                {t("treatment." + treatment.status)}
                              </p>
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </div>
          );
        })}
      </ListGroup>
      {showLoadingCauses ? (
        <h5 className="loading-text">{t("bowtie.causes_load")} ..</h5>
      ) : (
        ""
      )}
      <Modal
        show={showEditCauseModal}
        onHide={handleCloseEditCauseModal}
        size="lg"
        onEntered={() => myRef.current.focus()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("bowtie.causes_edit")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="treatments">
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>{t("bowtie.name")} *</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              disabled={!system["accesses"]?.includes("edit") ?? true}
              ref={myRef}
              onKeyDown={handleKeyDownCauseEdit}
              onChange={(evt) => setEditCauseName(evt.target.value)}
              value={editCause["name"]}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>{t("bowtie.category_cause")} *</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              disabled={!system["accesses"]?.includes("edit") ?? true}
              as="select"
              onKeyDown={handleKeyDownCauseEdit}
              onChange={(evt) =>
                setEditCauseCategory(
                  (evt.target as HTMLSelectElement).selectedOptions[0].value
                )
              }
              value={editCause["category"]}
            >
              <option value="">{t("bowtie.category_cause_choose")}</option>
              {settings["causeCategory"] != undefined &&
              !settings["causeCategory"]
                .map((item) => item)
                .includes(editCause["category"]) ? (
                <option value={editCause["category"]}>
                  {td(editCause["category"])}
                </option>
              ) : (
                ""
              )}
              {settings["causeCategory"] != undefined
                ? settings["causeCategory"].map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {td(item)}
                      </option>
                    );
                  })
                : ""}
            </Form.Control>
          </InputGroup>
          <Field
            type="selectmulti"
            title={t("bowtie.event_connect_name")}
            value={
              editCause["occurences"] != undefined
                ? editCause["occurences"].map((occ) => {
                    return {
                      value: occ.value,
                      label:
                        allOccurences.find(
                          (allOcc) => allOcc._id === occ.value
                        ) != undefined
                          ? allOccurences.find(
                              (allOcc) => allOcc._id === occ.value
                            ).name != "" &&
                            allOccurences.find(
                              (allOcc) => allOcc._id === occ.value
                            ).name != undefined
                            ? allOccurences.find(
                                (allOcc) => allOcc._id === occ.value
                              ).name
                            : td(
                                allOccurences.find(
                                  (allOcc) => allOcc._id === occ.value
                                ).occurenceTypeName
                              )
                          : "",
                    };
                  })
                : []
            }
            options={{
              choices: allOccurences.map((item) => {
                return {
                  label:
                    item.name != "" && item.name != undefined
                      ? item.name
                      : td(item.occurenceTypeName),
                  value: item._id,
                };
              }),
            }}
            onChange={(value) => setEditCauseOccurences(value)}
            disabled={!system["accesses"]?.includes("edit") ?? true}
          ></Field>
          <hr />
          <div>
            {editCause["threats"] != null
              ? editCause["threats"].map((threat, key) => (
                  <InputGroup key={key} className="mb-3 threats">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {t("bowtie.threats_label")}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div>
                      <Form.Control
                        disabled={!system["accesses"]?.includes("edit") ?? true}
                        as="select"
                        onKeyDown={handleKeyDownCauseEdit}
                        onChange={(evt) =>
                          changeEditCauseThreats(
                            key,
                            (evt.target as HTMLSelectElement).selectedOptions[0]
                              .value
                          )
                        }
                        value={
                          threat.id != undefined
                            ? (threat = threat.id)
                            : (threat = threat)
                        }
                      >
                        <option value="">{t("bowtie.threats_tie")}</option>
                        {!settings["threats"].includes(threat) ? (
                          <option disabled={true} value={threat}>
                            {td(threat)}
                          </option>
                        ) : null}
                        {threat != "" &&
                        threat != undefined &&
                        !settings["threats"]
                          .map((item) => item)
                          .includes(threat) ? (
                          <option value={threat}>{td(threat)}</option>
                        ) : (
                          ""
                        )}
                        {settings["threats"] != undefined
                          ? settings["threats"].map((item, choicekey) => {
                              return (
                                <option key={choicekey} value={item}>
                                  {td(item)}
                                </option>
                              );
                            })
                          : ""}
                      </Form.Control>
                      <InputGroup.Append>
                        <Button
                          disabled={
                            !system["accesses"]?.includes("edit") ?? true
                          }
                          onClick={(evt) => deleteEditCauseThreats(key)}
                        >
                          {t("bowtie.consequence_edit_delete_systemvalue_btn")}
                        </Button>
                      </InputGroup.Append>
                    </div>
                  </InputGroup>
                ))
              : null}
            <Button
              disabled={!system["accesses"]?.includes("edit") ?? true}
              onClick={addEditCauseThreats}
            >
              {t("bowtie.event_new_threats_add")}
            </Button>
            <hr />
          </div>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                {t("bowtie.causes_probability")}
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              disabled={!system["accesses"]?.includes("edit") ?? true}
              as="select"
              onKeyDown={handleKeyDownCauseEdit}
              onChange={(evt) =>
                setEditCauseProbability((evt.target as HTMLSelectElement).value)
              }
              value={Math.round(editCause["probability"]).toString()}
            >
              {Number(settings["gridY"]) > 0 ? (
                <option value="1">{td(settings["gridYName1"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridY"]) > 1 ? (
                <option value="2">{td(settings["gridYName2"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridY"]) > 2 ? (
                <option value="3">{td(settings["gridYName3"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridY"]) > 3 ? (
                <option value="4">{td(settings["gridYName4"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridY"]) > 4 ? (
                <option value="5">{td(settings["gridYName5"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridY"]) > 5 ? (
                <option value="6">{td(settings["gridYName6"])}</option>
              ) : (
                ""
              )}
            </Form.Control>
          </InputGroup>
          <hr />
          <InputGroup className="mb-3">
            <Field
              type="textarea"
              title={t("bowtie.cause_description")}
              value={editCause["description"]}
              onChange={(value) => setEditCauseDescription(value)}
              disabled={!system["accesses"]?.includes("edit") ?? true}
            ></Field>
          </InputGroup>
          <hr />
          <BowtieTreatmentHandler
            system={system}
            treatmentType="cause"
            causeId={editCause["_id"]}
            occurenceId={occurenceId}
            onTreatmentsChanged={() => onCausesChanged()}
          ></BowtieTreatmentHandler>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={handleCloseEditCauseModal}
          >
            {t("system.system_new_cancel")}
          </Button>
          <Button
            disabled={!system["accesses"]?.includes("edit") ?? true}
            onClick={handleEditCause}
          >
            {t("bowtie.save")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteCauseModal} onHide={handleCloseDeleteCauseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("bowtie.causes_delete")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("bowtie.causes_delete_info")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={handleCloseDeleteCauseModal}
          >
            {t("bowtie.cancel")}
          </Button>
          <Button onClick={handleDeleteCause}>{t("bowtie.delete")}</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showNewCauseModal}
        onHide={handleCloseNewCauseModal}
        onEntered={() => myRef.current.focus()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("bowtie.causes_register")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>{t("bowtie.causes_new_name")} *</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              ref={myRef}
              onKeyDown={handleKeyDownCauseCreate}
              onChange={newCauseNameChange}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>{t("bowtie.category_cause")} *</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              as="select"
              onKeyDown={handleKeyDownCauseCreate}
              onChange={newCauseCategoryChange}
            >
              <option value="">{t("bowtie.category_cause_choose")}</option>
              {settings["causeCategory"] != undefined
                ? settings["causeCategory"].map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {td(item)}
                      </option>
                    );
                  })
                : ""}
            </Form.Control>
          </InputGroup>
          <div className="row">
            <div className="col-sm-12">
              <hr />
              {newCauseThreats.map((threat, key) => (
                <InputGroup key={key} className="mb-3 systemvalueedit">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      {t("bowtie.threats_label")}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <div>
                    <Form.Control
                      as="select"
                      onKeyDown={handleKeyDownCauseCreate}
                      onChange={(evt) =>
                        changeNewCauseThreats(
                          key,
                          (evt.target as HTMLSelectElement).selectedOptions[0]
                            .value
                        )
                      }
                      value={threat["id"]}
                    >
                      <option value="">{t("bowtie.threats_tie")}</option>
                      {settings["threats"] != undefined
                        ? settings["threats"].map((item, key) => {
                            return (
                              <option key={key} value={item}>
                                {td(item)}
                              </option>
                            );
                          })
                        : ""}
                    </Form.Control>
                    <InputGroup.Append>
                      <Button onClick={(evt) => deleteNewCauseThreats(key)}>
                        {t("bowtie.consequence_edit_delete_systemvalue_btn")}
                      </Button>
                    </InputGroup.Append>
                  </div>
                </InputGroup>
              ))}
              <Button onClick={addNewCauseThreats}>
                {t("bowtie.event_new_threats_add")}
              </Button>
              <hr />
            </div>
          </div>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                {t("bowtie.causes_probability")}
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              as="select"
              onKeyDown={handleKeyDownCauseCreate}
              onChange={newCauseProbabilityChange}
              value={newCauseProbability}
            >
              {Number(settings["gridY"]) > 0 ? (
                <option value="1">{td(settings["gridYName1"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridY"]) > 1 ? (
                <option value="2">{td(settings["gridYName2"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridY"]) > 2 ? (
                <option value="3">{td(settings["gridYName3"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridY"]) > 3 ? (
                <option value="4">{td(settings["gridYName4"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridY"]) > 4 ? (
                <option value="5">{td(settings["gridYName5"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridY"]) > 5 ? (
                <option value="6">{td(settings["gridYName6"])}</option>
              ) : (
                ""
              )}
            </Form.Control>
          </InputGroup>
          <hr />
          <InputGroup className="mb-3">
            <Field
              type="textarea"
              title={t("bowtie.cause_description")}
              value={newCauseDescription}
              onChange={(value) => setNewCauseDescripton(value)}
            ></Field>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel posleft" onClick={handleCloseNewCauseModal}>
            {t("system.system_new_cancel")}
          </Button>
          <Button onClick={handleCreateCause}>{t("bowtie.save")}</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showExistingCauseModal}
        size="lg"
        onHide={handleCloseExistingCauseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("bowtie.causes_existing")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="riskMatrixToggle"
            style={{
              float: "none",
              marginTop: "0px",
              width: "fit-content",
            }}
          >
            <FormGroup>
              <Tooltip
                title={t("bowtie.cause_existing_toggle_tooltip")}
                placement="top"
              >
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>{t("bowtie.cause_existing_toggle_link")}</Grid>
                  <Grid item>
                    <Switch
                      size="small"
                      checked={copyingChecked}
                      onChange={toggleCopyingChecked}
                      color="primary"
                      className={"toggleswitch"}
                    />
                  </Grid>
                  <Grid item>{t("bowtie.cause_existing_toggle_copy")}</Grid>
                </Grid>
              </Tooltip>
            </FormGroup>
          </div>
          <div
            id="causesTable"
            key={
              !copyingChecked
                ? generateUrl("/api/cra/causes/getAllPaged/bysystem/") +
                  systemid
                : generateUrl("/api/cra/causes/getAllPaged/withTreatments")
            }
          >
            <PageTable
              dataUrl={
                !copyingChecked
                  ? generateUrl("/api/cra/causes/getAllPaged/bysystem/") +
                    systemid
                  : generateUrl("/api/cra/causes/getAllPaged/withTreatments/")
              }
              options={{
                pageSize: 10,
                showFields: [
                  {
                    label: t("bowtie.causes.existing.field.name"),
                    field: "name",
                  },
                  {
                    label: t("bowtie.causes.existing.field.system"),
                    field: "systemReadable",
                  },
                  {
                    label: t("bowtie.causes.existing.field.occurence"),
                    field: "occurenceReadable",
                  },
                  {
                    label: t("bowtie.causes.existing.field.description"),
                    field: "description",
                  },
                ],
              }}
              dataAfter={(dataLine) =>
                !copyingChecked ? (
                  <Button
                    style={{ width: "130px" }}
                    onClick={() => handleAttachExisting(dataLine)}
                  >
                    {t("bowtie.causes.existing.attach")}
                  </Button>
                ) : (
                  <div className="d-flex ml-auto">
                    {!isCauseSelected(dataLine["_id"]) && (
                      <Button
                        className="m-1"
                        onClick={() => copyExistingCause(dataLine, false)}
                        disabled={loading}
                      >
                        {loading &&
                          getLastAddedCaues(dataLine["_id"]) ===
                            dataLine["_id"] && (
                            <Spinner
                              as="span"
                              variant="primary"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              animation="border"
                            />
                          )}
                        {t("bowtie.causes.existing.copy")}
                      </Button>
                    )}
                    {!!dataLine.treatments &&
                      (isCauseSelected(dataLine["_id"])
                        ? !existingCauses.filter(
                            (e) => e["_id"] === dataLine["_id"]
                          )[0].treatmentCopied
                        : true) && (
                        <Button
                          className="m-1"
                          onClick={() => {
                            if (!isCauseSelected(dataLine["_id"])) {
                              copyExistingCause(dataLine, true);
                            } else {
                              fetchTreatmentsByCause(dataLine);
                            }
                          }}
                          disabled={loading}
                        >
                          {loading &&
                            getLastAddedCaues(dataLine["_id"]) ===
                              dataLine["_id"] && (
                              <Spinner
                                as="span"
                                variant="primary"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                              />
                            )}
                          {isCauseSelected(dataLine["_id"]) &&
                          !existingCauses.filter(
                            (e) => e["_id"] === dataLine["_id"]
                          )[0].treatmentCopied
                            ? t("bowtie.add_treatments")
                            : `${t("bowtie.causes.existing.copy")} ${t(
                                "bowtie.with_treatments"
                              )}`}
                        </Button>
                      )}
                  </div>
                )
              }
              loadingText={t("systems.loading")}
              hover={false}
              hidePagination={false}
              hideFilter={false}
              defaultSortField="name"
              defaultSortDirection="asc"
              selectedItems={existingCauses}
            ></PageTable>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel" onClick={handleCloseExistingCauseModal}>
            {t("diri.closebtn")}
          </Button>
        </Modal.Footer>
      </Modal>
      <TreatmentEditorModal
        show={showEditTreatmentModal}
        onSave={() => {
          setShowEditTreatmentModal(false);
          fetchCauses(occurenceId);
          onCausesChanged();
        }}
        onHide={() => {
          setShowEditTreatmentModal(false);
          setEditTreatmentId(undefined);
        }}
        onCancel={() => {
          setShowEditTreatmentModal(false);
          setEditTreatmentId(undefined);
        }}
        treatmentId={editTreatmentId}
      ></TreatmentEditorModal>
    </div>
  );
}

export default BowtieCauseList;
