import React, { useEffect, useState } from 'react';
import nb from 'date-fns/locale/nb';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import './styles/DateSelector.scss';
import { InputGroup } from 'react-bootstrap';
function DateSelector(props) {
    registerLocale('no', nb)
    const [localValue, setLocalValue] = useState<any>();
    const minDate = new Date(Date.now());

    const handleChange = (value) => {
        if (props.onChange != undefined) {
            props.onChange(value);
        }
    }

    function getDateFormat() {
        if (props.options != undefined && props.options.dateformat != undefined) {
            return props.options.dateformat;
        } else {
            return "d. MMMM yyyy";
        }
    }

    function getDateTimeFormat() {
        if (props.options != undefined && props.options.datetimeformat != undefined) {
            return props.options.datetimeformat;
        } else {
            return "d. MMMM yyyy 'kl.' HH.mm";
        }
    }

    useEffect(() => {
        if (props.value != undefined && props.value != "") {
            setLocalValue(moment(props.value));
        }
    }, [props.value]);

    return(
        <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text>{props.title}</InputGroup.Text>
            </InputGroup.Prepend>
            <DatePicker
                {...props}
                className={"dateselector form-control"}
                locale={JSON.parse((localStorage.getItem("user") as any)).userdata.language}
                dateFormat={ props.showTimeSelect != undefined ? getDateTimeFormat() : getDateFormat()}
                onChange={handleChange}
                selected={localValue != undefined ? localValue.toDate() : (props.selected != undefined ? props.selected : null)}
                value={localValue != undefined ? localValue.toDate() : (props.selected != undefined ? props.selected : null)}
                minDate={props.minDate != undefined ? props.minDate : minDate}
            />
        </InputGroup>
    );
}
export default DateSelector;