import * as React from "react";

import { useEffect } from "react";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import version from "../../../version.json";
import { useAuth } from "../../../Auth";

import { generateUrl } from "../../../config";

import "./VersionManager.scss";
import "../../../Assets/Dripicons/webfont/webfont.css";

function VersionManager(props) {
  const auth = useAuth();
  const t = useTranslate();
  const axios = require("axios").default;

  const notifySuccess = (text) =>
    toast(text, {
      position: "top-center",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      className: "success-toast",
      onClick: () => {
        localStorage.removeItem("versionNew");
        localStorage.removeItem("versionChecker");
        window.location.reload();
      },
    });

  async function fetchVersionInfo() {
    if (
      localStorage.getItem("versionNew") == undefined ||
      localStorage.getItem("versionNew") == "false"
    ) {
      await axios
        .all([
          axios({
            method: "get",
            url:
              generateUrl("/api/cra/version") +
              (auth.user?.["userdata"]?.["_id"]
                ? `/${auth.user["userdata"]["_id"]}`
                : ""),
            responseType: "json",
          }),
        ])
        .then(
          axios.spread((...res) => {
            if (res[0].data.updateJWT === true) {
              axios
                .all([
                  axios({
                    method: "post",
                    url: generateUrl("/api/login/updatejwt"),
                    data: { userid: auth.user["userdata"]["_id"] },
                    headers: { "x-access-token": auth.user["token"] },
                  }),
                ])
                .then(
                  axios.spread((...res2) => {
                    let userObject = {
                      id: res2[0].data.userdata.email,
                      auth: res2[0].data.auth,
                      token: res2[0].data.token,
                      userdata: res2[0].data.userdata,
                      settings: res2[0].data.settings,
                    };
                    localStorage.setItem("user", JSON.stringify(userObject));
                    localStorage.removeItem("unauthorized");
                    auth.setUser(() => {
                      return userObject;
                    });
                  })
                );
            }
            if (res[0].data != undefined && res[0].data.datetime != undefined) {
              if (new Date(res[0].data.datetime) > new Date(version.datetime)) {
                localStorage.getItem("versionNew");
                notifySuccess(t("versionmanager.new_version_available"));
                localStorage.setItem("versionNew", "true");
                window.clearInterval(
                  Number(localStorage.getItem("versionChecker"))
                );
                localStorage.removeItem("versionChecker");
              }
            }
          })
        );
    } else {
      localStorage.getItem("versionNew");
      //notifySuccess(t("versionmanager.new_version_available"));
      localStorage.setItem("versionNew", "true");
      window.clearInterval(Number(localStorage.getItem("versionChecker")));
      localStorage.removeItem("versionChecker");
    }
  }

  useEffect(() => {
    localStorage.setItem(
      "versionChecker",
      window
        .setInterval(function () {
          fetchVersionInfo();
        }, 15000)
        .toString()
    );
  }, []);

  return <div className="versionmanager"></div>;
}

export default VersionManager;
