import React, { useContext, createContext } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const axiosImport = require("axios").default;

let handlerTemp = null;
let lastError = "";

const notifyWarning = (text) =>
  toast(text, {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    className: "warning-toast",
  });

const axiosContext = createContext({
  axios: {
    post: post,
    delete: deleteFunc,
    get: get,
    put: put,
    all: all,
    single: single,
    spread: spread,
  },
});

export function ProvideAxios({ children }) {
  const axios = useProvideAxios();
  return (
    <axiosContext.Provider value={axios}>{children}</axiosContext.Provider>
  );
}

export const useAxios = () => {
  return useContext(axiosContext);
};

function unauthorizedHandler(error) {
  if (error.response == undefined) {
    if (
      error.message != lastError &&
      error.message != "Network Error" &&
      error.message != "timeout of 0ms exceeded"
    ) {
      lastError = error.message;
      notifyWarning("ERROR: " + error.message);
    }

    return Promise.reject(error);
  }
  if (
    error.response.status === 401 ||
    error.response.status === 403 ||
    error.response.status === 405
  ) {
    console.log("Axios unauthorized error!");
    localStorage.clear();
    window.location.href = "/login";
    return Promise.reject(error);
  }
  if (
    error.response.status < 400 ||
    (error.response.status >= 500 && error.message != lastError)
  ) {
    notifyWarning("Something went wrong! Please report if problem presists");
    if (
      error.response.data != undefined &&
      error.response.data.message != undefined &&
      error.response.status == 500
    ) {
      lastError = error.message;
      notifyWarning(error.response.data.message);
    } else {
      lastError = error.message;
      notifyWarning(
        "ERROR: " +
          error.response.status +
          " for '" +
          error.response.config.method +
          "' at: " +
          error.response.config.url
      );
    }
  }
  return Promise.reject(error);
}

function put(link, data, config) {
  if (handlerTemp == null) {
    handlerTemp = axiosImport.interceptors.response.use(
      undefined,
      unauthorizedHandler
    );
  }
  const promise = axiosImport.put(link, data, config);
  return promise;
}

function post(link, data, config) {
  if (handlerTemp == null) {
    handlerTemp = axiosImport.interceptors.response.use(
      undefined,
      unauthorizedHandler
    );
  }
  const promise = axiosImport.post(link, data, config);
  return promise;
}

function deleteFunc(link, data, config) {
  if (handlerTemp == null) {
    handlerTemp = axiosImport.interceptors.response.use(
      undefined,
      unauthorizedHandler
    );
  }
  const promise = axiosImport.delete(link, { headers: config.headers, data });
  return promise;
}

function get(link, data, config) {
  if (handlerTemp == null) {
    handlerTemp = axiosImport.interceptors.response.use(
      undefined,
      unauthorizedHandler
    );
  }
  const promise = axiosImport.get(link, data, config);
  return promise;
}

function single(data) {
  if (handlerTemp == null) {
    handlerTemp = axiosImport.interceptors.response.use(
      undefined,
      unauthorizedHandler
    );
  }
  const promise = axiosImport(data);
  return promise;
}

function all(data) {
  if (handlerTemp == null) {
    handlerTemp = axiosImport.interceptors.response.use(
      undefined,
      unauthorizedHandler
    );
  }
  const promise = axiosImport.all(data);
  return promise;
}

function spread(data) {
  if (handlerTemp == null) {
    handlerTemp = axiosImport.interceptors.response.use(
      undefined,
      unauthorizedHandler
    );
  }
  return axiosImport.spread(data);
}

function useProvideAxios() {
  if (handlerTemp == null) {
    handlerTemp = axiosImport.interceptors.response.use(
      undefined,
      unauthorizedHandler
    );
  }
  return {
    axios: axiosImport,
  };
}
