import React, { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function TextRich(props) {
    let quillRef = useRef(null);

    const handleChange = (value) => {
        if (props.onChange != undefined) {
            props.onChange(value);
        }
    }

    useEffect(() => {
        if (props.disabled) {
            (quillRef.current as any).getEditor().enable(false);
        }
    });

    return(
        <ReactQuill
            value={props.value != undefined && props.value != "" ? props.value : (props.defaultValue != undefined ? props.defaultValue : "")}
            onChange={(result) => handleChange(result)}
            ref={quillRef}
            modules={{
                toolbar: props.options == undefined || props.options.toolbar == undefined ? [[{ 'header': [1, 2, 3, false] }], ['bold', 'italic', 'underline'], [{ 'list': 'bullet' }], ['link']] : props.options.toolbar
            }}
        />
    );
}

export default TextRich;