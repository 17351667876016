import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslate } from 'react-polyglot';

function Bool(props) {
    
    const t = useTranslate();   

    const handleChange = (value) => {
        if (props.onChange != undefined) {
            props.onChange(value);
        }
    }

    return(
        <div className="wizardFieldBool">
            <p style={{
                marginBottom: "4px",
                marginTop: "20px",
                marginLeft: "10px",
                fontWeight: 600,
                color: "#495057"
            }}>{props.title}</p>
            <Button
                disabled={
                    props.disabled ||
                    (props.value !== undefined ? (props.value !== "" ? props.value : false) : (props.defaultValue !== "" ? props.defaultValue : false))
                }
                onClick={() => handleChange(true)}
                className="truebtn"
            >
                {(props.value !== undefined ? (props.value !== "" ? props.value : false) : (props.defaultValue !== "" ? props.defaultValue : false)) ? 
                    props.options != undefined && props.options.positive != undefined ? <span>{props.options.positive} <i className="dripicons-checkmark"/></span> : <span>{t("system_wizard.yes")} <i className="dripicons-checkmark"/></span>
                :
                    props.options != undefined && props.options.positive != undefined ? props.options.positive : t("system_wizard.yes")
                }
            </Button>
            <Button
                disabled={
                    props.disabled ||
                    (props.value !== undefined ? (props.value !== "" ? !props.value : false) : (props.defaultValue !== "" ? !props.defaultValue : false))
                }
                onClick={() => handleChange(false)}
                className="falsebtn"
            >
                {(props.value !== undefined ? (props.value !== "" ? !props.value : false) : (props.defaultValue !== "" ? !props.defaultValue : false)) ? 
                    props.options != undefined && props.options.negative != undefined ? <span>{props.options.negative} <i className="dripicons-checkmark"/></span> : <span>{t("system_wizard.no")} <i className="dripicons-checkmark"/></span>
                :
                    props.options != undefined && props.options.negative != undefined ? props.options.negative : t("system_wizard.no")
                }
            </Button>
        </div>
    );
}

export default Bool;