import React, { useEffect, useState } from "react";

import "./Field.scss";

import TextField from "./Fieldtypes/TextField";
import TextArea from "./Fieldtypes/TextArea";
import Checkbox from "./Fieldtypes/Checkbox";
import Slider from "./Fieldtypes/Slider";
import Select from "./Fieldtypes/Select";
import TextTitle from "./Fieldtypes/TextTitle";
import TextNormal from "./Fieldtypes/TextNormal";
import TextRich from "./Fieldtypes/TextRich";
import SelectMulti from "./Fieldtypes/SelectMulti";
import Divider from "./Fieldtypes/Divider";
import Bool from "./Fieldtypes/Bool";
import Bowtie from "./Fieldtypes/Bowtie";
import FileUpload from "./Fieldtypes/FileUpload";
import DateSelector from "./Fieldtypes/DateSelector";
import Autocomplete from "./Fieldtypes/Autocomplete";
import TreeSelector from "./Fieldtypes/TreeSelector";
import NumberField from "./Fieldtypes/NumberField";
import Objectcreator from "./Fieldtypes/Objectcreator";
import PersonSelector from "./Fieldtypes/PersonSelector";
import TextLanguage from "./Fieldtypes/TextLanguage";
import AutocompleteWithId from "./Fieldtypes/AutocompleteWithId";
import TagsField from "./Fieldtypes/TagsField";
import IndeterminateCheckbox from "./Fieldtypes/IndeterminateCheckbox";

const isEqual = require("react-fast-compare");

function Field(props) {
  const { type } = props;
  const [value, setValue] = useState(props.value);

  const handleFieldChange = (value) => {
    setValue(value);
    if (props.onChange != undefined) {
      props.onChange(value);
    }
  };

  const handleIdConnection = (valueID) => {
    if (props.handleIdConnection != undefined) {
      props.handleIdConnection(valueID);
    }
  };

  const renderField = (type) => {
    switch (type) {
      case "divider":
        return <Divider></Divider>;

      case "bowtie":
        return (
          <Bowtie
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
          ></Bowtie>
        );

      case "bool":
        return (
          <Bool
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            options={props.options}
          ></Bool>
        );

      case "select":
        return (
          <Select
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            options={props.options}
          ></Select>
        );

      case "selectmulti":
        return (
          <SelectMulti.SelectMulti
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            options={props.options}
          ></SelectMulti.SelectMulti>
        );

      case "input":
        return (
          <TextField
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            setFocus={props.setFocus}
            placeholder={props.placeholder}
            options={props.options}
          ></TextField>
        );

      case "textlanguage":
        return (
          <TextLanguage
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            options={props.options}
            onChange={handleFieldChange}
            onSave={() => {
              if (!!props.onSave) {
                props.onSave();
              }
            }}
          ></TextLanguage>
        );

      case "number":
        return (
          <NumberField
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
          ></NumberField>
        );

      case "autocomplete":
        return (
          <Autocomplete
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            options={props.options}
          ></Autocomplete>
        );

      case "treeselector":
        return (
          <TreeSelector
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            options={props.options}
          ></TreeSelector>
        );

      case "dateselector":
        return (
          <DateSelector
            {...props}
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            options={props.options}
          ></DateSelector>
        );

      case "fileupload":
        return (
          <FileUpload
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            options={props.options}
          ></FileUpload>
        );

      case "richtext":
        return (
          <TextRich
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            options={props.options}
            onChange={handleFieldChange}
          ></TextRich>
        );

      case "textarea":
        return (
          <TextArea
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
          ></TextArea>
        );

      case "checkbox":
        return (
          <Checkbox
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
          ></Checkbox>
        );

      case "slider":
        return (
          <Slider
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            options={props.options}
          ></Slider>
        );

      case "objectcreator":
        return (
          <Objectcreator
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            options={props.options}
          ></Objectcreator>
        );

      case "text_title":
        return (
          <TextTitle text={props.title} options={props.options}></TextTitle>
        );

      case "text_normal":
        return <TextNormal text={props.title}></TextNormal>;

      case "personselector":
        return (
          <PersonSelector
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            options={props.options}
          ></PersonSelector>
        );
      case "autocompletewithid":
        return (
          <AutocompleteWithId
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            options={props.options}
          ></AutocompleteWithId>
        );
      case "tagsfield":
        return (
          <TagsField
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            options={props.options}
          ></TagsField>
        );
      case "indeterminatecheckbox":
        return (
          <IndeterminateCheckbox
            title={props.title}
            value={value}
            defaultValue={props.value}
            disabled={props.disabled}
            onChange={handleFieldChange}
            titlePlacement={props.titlePlacement}
            parent={props.parent}
            checked={props.checked}
          ></IndeterminateCheckbox>
        );
    }
  };

  useEffect(() => {
    setValue(props.value);
  }, [props]);

  return (
    <div
      style={{ width: "100%" }}
      className={
        props.hidden == undefined || props.hidden == false
          ? "field"
          : "field hidden"
      }
    >
      {renderField(type)}
    </div>
  );
}

export default React.memo(Field, isEqual);
