export default [
  {
    versionNumber: "2.0.2",
    versionDate: "May 16, 2023",
    notes: ["Transfer rights and ownership option added on the Users page."],
  },
  {
    versionNumber: "2.0.1",
    versionDate: "May 15, 2023",
    notes: ["Small bug fixes"],
  },
  {
    versionNumber: "2.0.0",
    versionDate: "April 28, 2023",
    notes: ["Diri - Version 2.0.0"],
  },
];
