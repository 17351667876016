import React from 'react';
import { InputGroup } from 'react-bootstrap';
import {Slider as MaterialSlider} from '@material-ui/core/';

function Slider(props) {
    const handleChange = (value) => {
        if (props.onChange != undefined) {
            props.onChange(value);
        }
    }

    return(
        <InputGroup className="mb-3">
            <p>{props.title}</p>
            <MaterialSlider
                value={ props.value != undefined && props.value != "" ? props.value : (props.defaultValue != undefined ? props.defaultValue : 1) }
                onChange={ (evt, value) => handleChange(value) }
                aria-labelledby="discrete-slider-always"
                disabled={ props.disabled }
                valueLabelDisplay={ props.options != undefined && props.options.valueLabelDisplay != undefined ? props.options.valueLabelDisplay : "auto"}
                step={ props.options != undefined && props.options.step != undefined ? props.options.step : 1 }
                min={ props.options != undefined && props.options.min != undefined ? props.options.min : 0 }
                max={ props.options != undefined && props.options.max != undefined ? props.options.max : 10 }
                marks={ props.options != undefined && props.options.marks != undefined ? props.options.marks : undefined }
                style={ props.options != undefined && props.options.styles != undefined ? props.options.styles : undefined }
            />
        </InputGroup>
    );
}

export default Slider;