import * as React from 'react';
import {
    useHistory,
    useLocation,
  } from "react-router-dom";

import { toast } from 'react-toastify';
import './Analysis.scss';
import { Card, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../../Auth';
import { useAxios } from '../../../../AxiosHandler';
import { useTranslate } from 'react-polyglot';
import Wizard from '../../../Shared/Wizard/Wizard';

import SiteAssistant from "../../../Shared/SiteAssistant/SiteAssistant";
import DiriFaceDocked from "../../../../Assets/Images/diriassistant/diri.svg";
import { generateUrl } from '../../../../config';

function Analysis() {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    let history = useHistory();
    const t = useTranslate();  
    const location = useLocation();

    const [analysisInstance, setAnalysisInstance] = useState({});
    const [instanceData, setInstanceData] = useState([] as Array<any>);
    const [analysisTemplate, setAnalysisTemplate] = useState({ form: [] });
    
    const [analysisInstanceId, setAnalysisInstanceId] = useState("");
    const [analysisTemplateId, setAnalysisTemplateId] = useState("");

    const notifySuccess = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "success-toast"
    });

    async function fetchData(instanceId, templateId) {
        axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/analysisTemplates/") + templateId,
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/analysisInstances/") + instanceId,
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            })
        ])
        .then(axios.spread((...res) => {
            if(!res[0].data.accesses.includes("answer_survey")){
                for(const page of res[1].data.data){
                    page.fields = page.fields.map((field => {return {...field, disabled: true}} ));
                }
                for(const page of res[0].data.form){
                    page.fields = page.fields.map((field => {return {...field, disabled: true}} ));
                }
            }
            setAnalysisTemplate(res[0].data);

            setAnalysisInstance(res[1].data);
            let tempData;
            if (res[1].data["data"] != undefined && res[1].data["data"].length > 0) {
                tempData = res[1].data["data"];
            } else {
                tempData = res[0].data["form"];
            }
            setInstanceData(tempData);
        }));
    }

    function handleSaveInstance() {
        if (Object.keys(analysisInstance).length > 0) {
            axios.put(generateUrl('/api/cra/analysisInstances/') + analysisInstanceId, {
                org: auth.user["userdata"]["unit"][0],
                template: analysisInstance["template"],
                status: analysisInstance["status"],
                data: instanceData,
            }, {
                headers: {'x-access-token': auth.user["token"]}
            })
            .then(function (response) {
                notifySuccess(t("analysisInstance.toast_analysis_saved"));
                fetchData(analysisInstanceId, analysisTemplateId);
            });
        }
    }

    function handleCloseInstance() {
        if (Object.keys(analysisInstance).length > 0) {
            axios.put(generateUrl('/api/cra/analysisInstances/') + analysisInstanceId, {
                org: auth.user["userdata"]["unit"][0],
                template: analysisInstance["template"],
                status: "analysisInstance.status_closed",
                data: instanceData,
            }, {
                headers: {'x-access-token': auth.user["token"]}
            })
            .then(function (response) {
                fetchData(analysisInstanceId, analysisTemplateId);
                history.push("/analysislist");
            });
        }
    }

    function handleDownloadReport() {
        axios.single({
            method: "post",
            url: generateUrl("/api/cra/reports/analysisInstance/") + analysisInstanceId,
            responseType: "file",
            data: {
                analysisTemplate: analysisTemplate,
                analysisInstance: analysisInstance
            },
            headers: {'x-access-token': auth.user["token"]}
        }).then(res => {
            try {
                let file = Buffer.from(res.data.data);
                const blob = new Blob( [ file ], { type: 'text/plain' } );	
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                
                a.download = "Analyse_" + new Date().toISOString().split(".")[0] + ".docx";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } catch (err) {
                console.log(err);
            }
        });
    }

    useEffect(() => {
        fetchData(location["search"].split("?")[1].split("&")[0].split("=")[1], location["search"].split("?")[1].split("&")[1].split("=")[1]);
        setAnalysisInstanceId(location["search"].split("?")[1].split("&")[0].split("=")[1]);
        setAnalysisTemplateId(location["search"].split("?")[1].split("&")[1].split("=")[1]);
    }, []);

    return(
        <div className="maincontent">
            <SiteAssistant
                dockedLogo={DiriFaceDocked}
                featurekey="analysis"
            />
            <div className="systems-container analysis-container">
                <div className="pagetitle-container">
                    <h1>{t("analysisInstance.page_name")}</h1>
                </div>
                <Card>
                    <Card.Body>
                        {instanceData != undefined && instanceData.length > 0 ?
                            <div>
                                <h4 className="header-title">{analysisTemplate["name"]}</h4>
                                <Button className="backbtn" onClick={() => history.push("/analysislist")}>{t("analysisInstance.back")}</Button>
                                <br />
                                <Wizard
                                    data={ instanceData }
                                    options={{
                                        nextBtnText: "Neste",
                                        prevBtnText: "Forrige",
                                        completeBtnText: "Fullfør",
                                        disableComplete: true,
                                        disableEditing: analysisInstance["status"] == "analysisInstance.status_closed"
                                    }}
                                ></Wizard>
                                <div className="fixed">
                                        <div >
                                            <Button className="gettempreportbtn" onClick={ handleDownloadReport }>{t("analysisInstance.gettempreport")}</Button>
                                        </div>
                                        {analysisInstance["status"] != "analysisInstance.status_closed" ?
                                            <div>
                                                <Button disabled={!analysisTemplate["accesses"]?.includes("answer_survey")} className="savebtn" onClick={ handleSaveInstance }>{t("analysisInstance.save")}</Button>
                                                <Button disabled={!analysisTemplate["accesses"]?.includes("answer_survey")} className="saveandclosebtn" onClick={() => { if (window.confirm(t("analysisInstance.confirmclosetext")) == true) { handleCloseInstance();} } }>{t("analysisInstance.saveandclose")}</Button>
                                            </div>
                                        : ""}
                                </div>
                                   
                            </div>
                        : ""}
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}

export default Analysis;