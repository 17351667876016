import React, { useEffect, useState } from "react";

import { InputGroup, Form, Button, Modal } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { Tooltip } from "@material-ui/core";

import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";

import { useDynamicLanguage } from "../../../../../DynamicLanguageProvider";
import Field from "../Field";

import { generateUrl } from "../../../../../config";

function TextLanguage(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();
  const td = useDynamicLanguage().td;
  const hastd = useDynamicLanguage().hastd;
  const loadTexts = useDynamicLanguage().loadTexts;
  const texts = useDynamicLanguage().texts;

  const [show, setShow] = useState(false);
  const [text, setText] = useState((props.options?.lang?.text ?? "") || "");
  const [term, setTerm] = useState((props.options?.lang?.term ?? "") || "");
  const [allText, setAllText] = useState({});
  const [textMissing, setTextMissing] = useState(false);

  const [displayValue, setDisplayValue] = useState("");

  const [focus, setFocus] = useState(0);

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  let userdata = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
    userdata = JSON.parse(localStorageUser)["userdata"];
  }

  function handleChange(value) {
    if (props.onChange != undefined) {
      props.onChange(value);
    }
  }

  function handleOpen(evt) {
    if (!props.disabled) setShow(true);
  }

  function handleClose() {
    setShow(false);
  }

  function handleSaveLang() {
    let temptexts = {};
    let oldValue;
    if (
      props.value != undefined &&
      props.value != "" &&
      props.value.length != 24
    ) {
      oldValue = props.value;
    }

    if (
      props.value == undefined ||
      props.value == "" ||
      props.value.length != 24 ||
      props.value.includes(" ")
    ) {
      temptexts[userdata["language"]] = text;

      axios
        .post(
          generateUrl("/api/cra/languages/"),
          {
            term: term,
            category: props.options?.category ?? "",
            texts: temptexts,
            oldValue: oldValue,
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then(function (response) {
          handleChange(response.data._id);
          setShow(false);
          loadTexts();
          setTextMissing(false);
          fetchTextObject(response.data._id);

          if (!!props.onSave) props.onSave();
        });
    } else {
      temptexts = allText;
      temptexts[userdata["language"]] = text;

      axios
        .put(
          generateUrl("/api/cra/languages/") + props.value,
          {
            term: term,
            texts: temptexts,
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then(function (response) {
          handleChange(response.data._id);
          setShow(false);
          loadTexts();
          setTextMissing(false);

          if (!!props.onSave) props.onSave();
        });
    }
  }

  function fetchTextObject(id) {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/languages/") + id,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setText(res[0].data.texts[userdata["language"]]);
          setTerm(res[0].data.term);
          setAllText(res[0].data.texts);
        })
      );
  }

  useEffect(() => {
    if (
      props.value != undefined &&
      props.value.length == 24 &&
      !props.value.includes(" ")
    ) {
      setDisplayValue(td(props.value));
      if (props.value != undefined && props.value != "" && show == true) {
        fetchTextObject(props.value);
      }
    } else {
      setDisplayValue(props.value);
    }
  }, [show, props.value, term, texts]);

  useEffect(() => {
    setTextMissing(!hastd(props.value));
  }, [term, texts, props.value]);

  return (
    <div className="textlanguage">
      <InputGroup style={{ paddingTop: "0px" }}>
        {props.title != undefined ? (
          <InputGroup.Prepend>
            <InputGroup.Text>{props.title}</InputGroup.Text>
          </InputGroup.Prepend>
        ) : null}
        <div
          onClick={handleOpen}
          style={{
            cursor: props.disabled == true ? "default" : "pointer",
          }}
        >
          {textMissing ? (
            <React.Fragment>
              <div
                style={{
                  position: "absolute",
                  zIndex: 1,
                  top: "-5px",
                }}
              >
                <Tooltip title={t("dynamiclanguage.missing")} placement="top">
                  <i
                    className="fas fa-exclamation-triangle"
                    style={{
                      color: "#f5a623",
                      fontSize: "16px",
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                    }}
                  ></i>
                </Tooltip>
              </div>
              <Form.Control
                onClick={() => console.log("test")}
                className={props.disabled == true ? "disabled" : ""}
                style={{
                  pointerEvents: "none",
                  backgroundColor:
                    props.disabled == true ? "#f5f5f5" : "#fff8ea",
                  fontStyle: "italic",
                }}
                type="text"
                //disabled={true}
                value={displayValue}
              />
            </React.Fragment>
          ) : (
            <Form.Control
              onClick={() => console.log("test")}
              className={props.disabled == true ? "disabled" : ""}
              style={{
                pointerEvents: "none",
                backgroundColor: props.disabled == true ? "#f5f5f5" : "#fff8ea",
              }}
              type="text"
              //disabled={true}
              value={displayValue}
            />
          )}
        </div>
      </InputGroup>
      <Modal
        show={show}
        onHide={handleClose}
        onEntered={() => setFocus(focus + 1)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("dynamiclanguage.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Field
            setFocus={focus}
            title={t("dynamiclanguage.modal.langtext.title")}
            type="input"
            value={text}
            onChange={(val) => setText(val)}
          ></Field>
          <p>{t("dynamiclanguage.modal.langtext.description")}</p>
          <Field
            title={t("dynamiclanguage.modal.termtext.title")}
            type="input"
            value={term}
            disabled={props.value}
            onChange={(val) => setTerm(val)}
          ></Field>
          <p>{t("dynamiclanguage.modal.termtext.description")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel posleft" onClick={handleClose}>
            {(props.options?.closetext ?? "Close") || "Close"}
          </Button>
          <Button disabled={term == "" || text == ""} onClick={handleSaveLang}>
            {(props.options?.savetext ?? "Save") || "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TextLanguage;
