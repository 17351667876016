import * as React from "react";
import { useEffect, useState } from "react";

import { useAuth } from "../../../../Auth";

import { Tooltip } from "@material-ui/core";
import { Button } from "react-bootstrap";

import RegTemplate from "../../RegTemplates/RegTemplate";
import Shop from "../../Shop/Shop";

import { useTranslate } from "react-polyglot";

import "./RegAssistant.scss";
function RegAssistant(props) {
  const t = useTranslate();
  const auth = useAuth();

  const [regState, setRegState] = useState(
    props.type != undefined && props.type.includes(".") ? "register" : "select"
  );
  const [selectedType, setSelectedType] = useState(
    props.type != undefined && props.type.includes(".")
      ? props.type.split(".")[1]
      : ""
  );
  const [page, setPage] = useState(0);

  const [showShop, setShowShop] = useState(false);

  const localStorageUser = localStorage.getItem("user");
  let user = {};
  if (localStorageUser != null) {
    user = JSON.parse(localStorageUser);
  }

  function handleOnClick(type, regstate) {
    setPage(page + 1);
    setSelectedType(type);
    setRegState(regstate);
    if (props.chosenTemplate != undefined) {
      props.chosenTemplate(type);
    }
  }

  return (
    <div className="regassistant">
      {regState == "select" || page === -1 ? (
        <div className="row">
          <div className="col col-sm-4">
            {auth.user["userdata"]["actualAccessRole"]["rules"].find(
              (rule) => rule.objType == "system" && rule.objSubType == "system"
            )?.allowed == false ? (
              <div className={"regitem regitem-disabled disabled"}>
                <Tooltip
                  title={t("diri.regassistant.types.system.unavailable")}
                  placement="bottom"
                >
                  <div className="regitem-help">
                    <span>
                      <i className="dripicons-question" />
                    </span>
                  </div>
                </Tooltip>
                <h3>{t("diri.regassistant.types.system.title")}</h3>
                <p>{t("diri.regassistant.types.system.description")}</p>
                <Button
                  className="upgrade-btn"
                  onClick={() => {
                    setShowShop(true);
                  }}
                >
                  <i className="dripicons-lock" />{" "}
                  {t("diri.regassistant.requires_update")}
                </Button>
              </div>
            ) : props.countedSystems.countedSystem <= 0 &&
              props.countedSystems.countedSystem != undefined ? (
              <div className={"regitem regitem-disabled disabled"}>
                <Tooltip
                  title={t("diri.regassistant.types.system.reached_limit")}
                  placement="bottom"
                >
                  <div className="regitem-help">
                    <span>
                      <i className="dripicons-question" />
                    </span>
                  </div>
                </Tooltip>
                <h3>{t("diri.regassistant.types.system.title")}</h3>
                <p>{t("diri.regassistant.types.system.description")}</p>
                <Button
                  className="upgrade-btn"
                  onClick={() => {
                    setShowShop(true);
                  }}
                >
                  <i className="dripicons-lock" />{" "}
                  {t("diri.regassistant.requires_update")}
                </Button>
              </div>
            ) : auth.user["userdata"]["actualAccessRole"]["rules"].find(
                (rule) =>
                  rule.objType == "system" && rule.objSubType == "system"
              )?.actions.create != "allow" ? (
              <div className={"regitem regitem-disabled disabled"}>
                <Tooltip
                  title={t("diri.regassistant.types.system.not_allowed")}
                  placement="bottom"
                >
                  <div className="regitem-help">
                    <span>
                      <i className="dripicons-question" />
                    </span>
                  </div>
                </Tooltip>
                <h3>{t("diri.regassistant.types.system.title")}</h3>
                <p>{t("diri.regassistant.types.system.description")}</p>
              </div>
            ) : (
              <div className={"regitem"}>
                <h3>{t("diri.regassistant.types.system.title")}</h3>
                <p>{t("diri.regassistant.types.system.description")}</p>
                <Button
                  onClick={(evt) => {
                    handleOnClick("system", "register");
                  }}
                >
                  {t("diri.regassistant.get_started")}
                </Button>
              </div>
            )}
          </div>
          <div className="col col-sm-4">
            {auth.user["userdata"]["actualAccessRole"]["rules"].find(
              (rule) => rule.objType == "system" && rule.objSubType == "orgros"
            )?.allowed == false ? (
              <div className={"regitem regitem-disabled disabled"}>
                <Tooltip
                  title={t("diri.regassistant.types.orgros.unavailable")}
                  placement="bottom"
                >
                  <div className="regitem-help">
                    <span>
                      <i className="dripicons-question" />
                    </span>
                  </div>
                </Tooltip>
                <h3>{t("diri.regassistant.types.orgros.title")}</h3>
                <p>{t("diri.regassistant.types.orgros.description")}</p>
                <Button
                  className="upgrade-btn"
                  onClick={() => {
                    setShowShop(true);
                  }}
                >
                  <i className="dripicons-lock" />{" "}
                  {t("diri.regassistant.requires_update")}
                </Button>
              </div>
            ) : props.countedSystems.countedOrgros <= 0 &&
              props.countedSystems.countedOrgros != undefined ? (
              <div className={"regitem regitem-disabled disabled"}>
                <Tooltip
                  title={t("diri.regassistant.types.orgros.reached_limit")}
                  placement="bottom"
                >
                  <div className="regitem-help">
                    <span>
                      <i className="dripicons-question" />
                    </span>
                  </div>
                </Tooltip>
                <h3>{t("diri.regassistant.types.orgros.title")}</h3>
                <p>{t("diri.regassistant.types.orgros.description")}</p>
                <Button
                  className="upgrade-btn"
                  onClick={() => {
                    setShowShop(true);
                  }}
                >
                  <i className="dripicons-lock" />{" "}
                  {t("diri.regassistant.requires_update")}
                </Button>
              </div>
            ) : user["userdata"]["actualAccessRole"]["rules"].find(
                (rule) =>
                  rule.objType == "system" && rule.objSubType == "orgros"
              )?.actions.create != "allow" ? (
              <div className={"regitem regitem-disabled disabled"}>
                <Tooltip
                  title={t("diri.regassistant.types.orgros.not_allowed")}
                  placement="bottom"
                >
                  <div className="regitem-help">
                    <span>
                      <i className="dripicons-question" />
                    </span>
                  </div>
                </Tooltip>
                <h3>{t("diri.regassistant.types.orgros.title")}</h3>
                <p>{t("diri.regassistant.types.orgros.description")}</p>
              </div>
            ) : (
              <div className={"regitem"}>
                <h3>{t("diri.regassistant.types.orgros.title")}</h3>
                <p>{t("diri.regassistant.types.orgros.description")}</p>
                <Button
                  onClick={(evt) => {
                    handleOnClick("orgros", "register");
                  }}
                >
                  {t("diri.regassistant.get_started")}
                </Button>
              </div>
            )}
          </div>
          <div className="col col-sm-4">
            {auth.user["userdata"]["actualAccessRole"]["rules"].find(
              (rule) => rule.objType == "system" && rule.objSubType == "other"
            )?.allowed == false ? (
              <div className="regitem regitem-disabled disabled">
                <Tooltip
                  title={t("diri.regassistant.types.other.unavailable")}
                  placement="bottom"
                >
                  <div className="regitem-help">
                    <span>
                      <i className="dripicons-question" />
                    </span>
                  </div>
                </Tooltip>
                <h3>{t("diri.regassistant.types.other.title")}</h3>
                <p>{t("diri.regassistant.types.other.description")}</p>
                <Button
                  className="upgrade-btn"
                  onClick={() => {
                    setShowShop(true);
                  }}
                >
                  <i className="dripicons-lock" />{" "}
                  {t("diri.regassistant.requires_update")}
                </Button>
              </div>
            ) : props.countedSystems.countedOther <= 0 &&
              props.countedSystems.countedOther != undefined ? (
              <>
                <div className={"regitem regitem-disabled disabled"}>
                  <Tooltip
                    title={t("diri.regassistant.types.other.reached_limit")}
                    placement="bottom"
                  >
                    <div className="regitem-help">
                      <span>
                        <i className="dripicons-question" />
                      </span>
                    </div>
                  </Tooltip>
                  <h3>{t("diri.regassistant.types.other.title")}</h3>
                  <p>{t("diri.regassistant.types.other.description")}</p>
                  <Button
                    className="upgrade-btn"
                    onClick={() => {
                      setShowShop(true);
                    }}
                  >
                    <i className="dripicons-lock" />{" "}
                    {t("diri.regassistant.requires_update")}
                  </Button>
                </div>
              </>
            ) : user["userdata"]["actualAccessRole"]["rules"].find(
                (rule) => rule.objType == "system" && rule.objSubType == "other"
              )?.actions.create != "allow" ? (
              <div className="regitem regitem-disabled disabled">
                <Tooltip
                  title={t("diri.regassistant.types.other.not_allowed")}
                  placement="bottom"
                >
                  <div className="regitem-help">
                    <span>
                      <i className="dripicons-question" />
                    </span>
                  </div>
                </Tooltip>
                <h3>{t("diri.regassistant.types.other.title")}</h3>
                <p>{t("diri.regassistant.types.other.description")}</p>
              </div>
            ) : (
              <div className="regitem">
                <h3>{t("diri.regassistant.types.other.title")}</h3>
                <p>{t("diri.regassistant.types.other.description")}</p>
                <Button
                  onClick={(evt) => {
                    handleOnClick("other", "register");
                  }}
                >
                  {t("diri.regassistant.get_started")}
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {regState == "register" && page !== -1 ? (
        <div className="registercontainer">
          <RegTemplate
            regType={selectedType}
            regCompleted={(data) => {
              if (props.regCompleted != undefined) props.regCompleted(data);
            }}
            systemid={props.systemid}
            system={props.system}
            options={props.options}
            occurenceOptions={props.occurenceOptions}
            treatmentOptions={props.treatmentOptions}
            systemOptions={props.systemOptions}
            setPage={(e) => {
              setPage(e);
            }}
          ></RegTemplate>
        </div>
      ) : null}
      <Shop
        show={showShop}
        onHide={() => setShowShop(false)}
        size="lg"
        orgid={user["userdata"]["unit"][0]}
        onPurchaseComplete={() => setShowShop(false)}
      />
    </div>
  );
}

export default RegAssistant;
