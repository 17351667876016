import React from "react";

import { useHistory } from "react-router-dom";
import { useTranslate } from "react-polyglot";
import { Card, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import { useAuth } from "../../../Auth";

import ButtonComponent from "../../Shared/Button/Button";

import "react-toastify/dist/ReactToastify.css";
import "../Login/Login.scss";

import CRALogo from "../../../Assets/Images/diri-logo.svg";

function ForgotPassword() {
  const auth = useAuth();
  const t = useTranslate();
  const history = useHistory();

  const [email, setEmail] = React.useState("");

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyAlert = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "alert-toast",
    });

  const [loading, setLoading] = React.useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleForgotPassword();
    }
  };

  async function handleForgotPassword() {
    setLoading(true);
    let result = await auth.forgotPassword(email);

    notifySuccess(t("forgot_password.request_sent_successfully"));
    setTimeout(() => history.push("/login"), 200);
    setLoading(false);
  }

  return (
    <div className="logincontainer">
      <div className="row justify-content-center">
        <div className="col-lg-5">
          <Card>
            <div className="card-header pt-3 pb-3 text-center login-header">
              <a href="/">
                <span>
                  <img className="logo" src={CRALogo} alt="" width="200" />
                </span>
              </a>
            </div>
            <Card.Body>
              <div className="welcometext">
                <h4>{t("forgot_password.forgot_password")}</h4>
                <p>{t("forgot_password.please_enter_email")}</p>
              </div>
              <div className="loginform">
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>E-postadresse</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={t("login.email_info")}
                      value={email}
                      onKeyDown={handleKeyDown}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Form>
                <div className="buttonbox">
                  <ButtonComponent
                    text={t("forgot_password.request_reset")}
                    loading={loading}
                    onClick={handleForgotPassword}
                  />

                  <p
                    className="backToSignin"
                    onClick={() => history.push("/login")}
                  >
                    {t("forgot_password.back_to_signin")}
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
