import * as React from "react";

import "./ColorLine.scss";

function MainMenu(props) {
  return (
    <div
      style={{
        width: props.width != undefined ? props.width : "20px",
        height: props.height != undefined ? props.height : "20px",
        ...props.style,
      }}
      className="colorlinecomponent"
    >
      {props.data.map((i, key) => (
        <div
          key={key}
          style={{
            width: i.value + "%",
            height: "100%",
            backgroundColor: i.color,
          }}
        ></div>
      ))}
    </div>
  );
}

export default MainMenu;
