import * as React from 'react';

import { useTranslate } from 'react-polyglot';
import { useEffect, useState } from 'react';

import Field from '../../../../../Shared/Wizard/Field/Field';
import DateSelector from '../../../../../Shared/Wizard/Field/Fieldtypes/DateSelector';

function SystemInformationOrgros(props) {
    const t = useTranslate();
    
    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    

    return(
        <div className="systemtype">
            <DateSelector
                value={props.system["date"]}
                title={t("system.information.start_date")}
                disabled={true}
            ></DateSelector>
            <Field
                type="input"
                value={props.orgelements != undefined ? props.orgelements.find(i => i._id == props.system.org) != undefined ? props.orgelements.find(i => i._id == props.system.org).name : props.system["orgReadable"] : null}
                title={t("system.information.organization_name")}
                disabled={true}
            ></Field>
            <Field
                type="input"
                value={props.system.data != undefined ? props.system.data["industrycode"] : ""}
                title={t("system.information.industrial_classification")}
                disabled={true}
            ></Field>
            <Field
                type="select"
                value={props.system.data != undefined && props.system.data["industryssb"] != undefined ? props.system.data["industryssb"] : ""}
                options={{
                    "choices": [
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.agriculture"),
                            "value": "agriculture"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.mining"),
                            "value": "mining"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.industry"),
                            "value": "industry"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.supplyservices"),
                            "value": "supplyservices"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.construction"),
                            "value": "construction"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.retail"),
                            "value": "retail"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.transportstorage"),
                            "value": "transportstorage"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.accomodationfood"),
                            "value": "accomodationfood"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.infcom"),
                            "value": "infcom"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.financialinsurance"),
                            "value": "financialinsurance"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.realestate"),
                            "value": "realestate"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.technicalservices"),
                            "value": "technicalservices"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.businessservices"),
                            "value": "businessservices"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.publicadministration"),
                            "value": "publicadministration"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.teaching"),
                            "value": "teaching"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.healthsocialwork"),
                            "value": "healthsocialwork"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.cultural"),
                            "value": "cultural"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.otherservices"),
                            "value": "otherservices"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.paidprivatehouseholdwork"),
                            "value": "paidprivatehouseholdwork"
                        },
                        {
                            "label": t("diri.regassistant.types.orgros.industryssbselections.internationalorgs"),
                            "value": "internationalorgs"
                        }
                    ]
                }}
                title={t("system.information.standard_industrial_classification")}
                disabled={true}
            ></Field>
            <Field
                type="input"
                value={props.system.data != undefined ? props.system.data["employeescount"] : ""}
                title={t("system.information.number_of_employees")}
                disabled={true}
            ></Field>
        </div>
    );
}
 
export default SystemInformationOrgros;