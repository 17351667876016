import React, { useEffect, useState } from 'react';
import { useAxios } from "../../../../../AxiosHandler";

import { useTranslate } from 'react-polyglot';
import './TreatmentList.scss';
import { useAuth } from "../../../../../Auth";
import { Card, Table, FormGroup } from 'react-bootstrap';
import { Grid, Tooltip, Switch } from '@material-ui/core';
import { generateUrl } from '../../../../../config';

const TreatmentList = (props) => {
    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    const [treatments, setTreatments] = useState([{}]);

    const [mytreatmentsChecked, setMytreatmentsChecked] = useState(false);

    const [loading, setLoading] = useState(true);

    const t = useTranslate();   
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();

    const open = t("treatment.open");
    const processing = t("treatment.ongoing");
    const closed = t("treatment.closed");
    const labelNone = t("treatment.label_none");
    const labelLow = t("treatment.label_low");
    const labelMiddel = t("treatment.label_middel");
    const labelHigh = t("treatment.label_high");
    const labelExtremelyHigh = t("treatment.label_extremely_high");
    
    function toggleMytreatments(evt) {
        let quickToggle = !mytreatmentsChecked;
        setMytreatmentsChecked(!mytreatmentsChecked);
        fetchRiskData(quickToggle).then(data => {
            setTreatments(data["treatments"]);
        });
    }

    async function fetchRiskData(mytreatments = false) {
        let returns = {};
        await axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/systems/"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            })
        ])
        .then(axios.spread(async (...res) => {
            await axios.all([
                axios.single({
                    method: "get",
                    url: generateUrl("/api/cra/treatments"),
                    data: res[0].data.map(item => item["_id"]),
                    responseType: "json",
                    headers: {'x-access-token': auth.user["token"]}
                })
            ])
            .then(axios.spread((...res2) => {
                res2[0].data.forEach(treatment => {
                    treatment["probabilityreduction"] = Math.round(((treatment["probabilityreduction"] / 100) * (treatment["cause"] == undefined ? settings["gridX"] : settings["gridY"])) * 10) / 10;
                });

                let treatments = [];
                if (mytreatments == true) {
                    treatments = res2[0].data.filter(treatment => treatment["responsible"] == auth.user["userdata"]["_id"]);
                } else {
                    treatments = res2[0].data;
                }

                returns["treatments"] = treatments;
            }));
        }));
        return returns;
    }
    
    useEffect(() => {
		let cancelled = false;

        fetchRiskData().then(data => {
            if (!cancelled) {
                setTreatments(data["treatments"]);
                setLoading(false);
            }
        });
        
        return () => {
            cancelled = true;
        };
    }, []);

    return (
        <Card>
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                <h4 className="header-title" data-satarget="treatmentlist">{t("dashboard.cards.treatmentlist_name")}</h4>
                <div className="riskMatrixToggle">
                    <FormGroup>
                        <Tooltip
                            title= {t("dashboard.cards.treatmentlist.fliptooltip")}
                            placement="top"
                        >
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>{t("dashboard.cards.treatmentlist.flipalltreatments")}</Grid>
                                <Grid item>
                                    <Switch
                                        size="small"
                                        checked={mytreatmentsChecked}
                                        onChange={toggleMytreatments}
                                        className={"toggleswitch"}
                                        color="primary"
                                    />
                                </Grid>
                                <Grid item>{t("dashboard.cards.treatmentlist.flipmytreatments")}</Grid>
                            </Grid>
                        </Tooltip>
                    </FormGroup>
                </div>
                <p>{t("dashboard.company_measures_overview")}</p>
                <hr/>
                <div className="treatmentcountcontainerdashboard">
                    <div>
                        <div className={"ongoing " + (treatments.filter(treatment => treatment["status"] == "processing").length == 0 ? "zero" : "")}>
                            {treatments.filter(
                                treatment => treatment["status"] == "processing"
                            ).length}
                        </div>
                        {t("dashboard.measures_ongoing_o")}<br/>{t("dashboard.measures_ongoing_m")}
                    </div>
                </div>
                <div className="treatmenttable">
                    <h4>{t("dashboard.measures_ongoing_title")}</h4>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>{t("dashboard.measures_ongoing_name")}</th>
                                <th>{t("dashboard.measures_ongoing_impact")}</th>
                                <th>{t("dashboard.measures_ongoing_status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                treatments.filter(item => item["status"] == "processing").map((treatment, key) => {
                                    return(
                                        <tr key={key} data-systemid={treatment["_id"]} >
                                            <td data-systemid={treatment["_id"]}>{treatment["name"]}</td>
                                            <td data-systemid={treatment["_id"]}>
                                                { treatment["probabilityreduction"] == 0 ? labelNone: ""}
                                                { (treatment["probabilityreduction"] / (treatment["cause"] != undefined ? settings["gridY"] : settings["gridX"])) * 100 > 0 &&
                                                (treatment["probabilityreduction"] / (treatment["cause"] != undefined ? settings["gridY"] : settings["gridX"])) * 100 < 45 ? labelLow: ""}
                                                { (treatment["probabilityreduction"] / (treatment["cause"] != undefined ? settings["gridY"] : settings["gridX"])) * 100 >= 45 &&
                                                (treatment["probabilityreduction"] / (treatment["cause"] != undefined ? settings["gridY"] : settings["gridX"])) * 100 < 70 ? labelMiddel: ""}
                                                { (treatment["probabilityreduction"] / (treatment["cause"] != undefined ? settings["gridY"] : settings["gridX"])) * 100 >= 70 &&
                                                (treatment["probabilityreduction"] / (treatment["cause"] != undefined ? settings["gridY"] : settings["gridX"])) * 100 < 95 ? labelHigh: ""}
                                                { (treatment["probabilityreduction"] / (treatment["cause"] != undefined ? settings["gridY"] : settings["gridX"])) * 100 >= 95 ? labelExtremelyHigh: ""}
                                            </td>
                                            <td data-systemid={treatment["_id"]}>
                                                {treatment["status"] == "open" ? open : ""}
                                                {treatment["status"] == "processing" ? processing : ""}
                                                {treatment["status"] == "closed" ? closed : ""}
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    );
}

export default TreatmentList;
