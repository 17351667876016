import React, { useEffect, useState } from 'react';

import {
    useHistory
  } from "react-router-dom";

import './MissingData.scss';
import { useTranslate } from 'react-polyglot';
import { Card } from 'react-bootstrap';
import { useAxios } from "../../../../../AxiosHandler";
import { useAuth } from "../../../../../Auth";

function MissingData(props){

    const t = useTranslate();
    const history = useHistory();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();

    const [messages, setMessages] = useState<Array<any>>([]);
    const [systems, setSystems] = useState([]);
    const [link, setLink] = useState("");

    let rules = [
        {
            item: "system",
            rule: "not",
            key: "ownerPerson",
            value: "",
            message: t("dashboard.cards.missingdata.message.system_owner_person_empty")
        },
        {
            item: "system",
            rule: "not",
            key: "ownerPerson",
            value: undefined,
            message: t("dashboard.cards.missingdata.message.system_owner_person_empty")
        },
        {
            item: "system",
            rule: "not",
            key: "ownerPerson",
            value: null,
            message: t("dashboard.cards.missingdata.message.system_owner_person_empty"),
        },
        {
            item: "occurences",
            rule: "not",
            key: "type",
            value: undefined,
            message: t("dashboard.cards.missingdata.message.occurence_type_empty")
        },
        {
            item: "occurences",
            rule: "not",
            key: "type",
            value: "",
            message: t("dashboard.cards.missingdata.message.occurence_type_empty")
        },
/*         {
            item: "system",
            rule: "gt",
            key: "systemType",
            value: 2,
            message: t("dashboard.cards.missingdata.message.system_owner_person_empty")
        }, */
        {
            item: "systemvalue",
            rule: "not",
            key: "k",
            value: "",
            message: t("dashboard.cards.missingdata.message.systemvalue_missing_confidentiality")
        }
    ]

 

    function checkRules(objects, objectType, tempMessages){
        objects.forEach((object) => {
            let tempRules = rules.filter(rule => rule["item"] == objectType);
            tempRules.forEach((rule) => {
                switch(rule.rule) {
                    case "not":
                        if(object[rule.key] === rule.value) {
                            let message = rule.message;
                            let textString = t("dashboard.cards.missingdata.type." + objectType);
                            let messageObject = {
                                id: object._id,
                                objectTypeString: textString,
                                objectNameString: object.name,
                                message,
                                objectType,
                                systemId: object.system,
                            }
                            tempMessages.push(messageObject);
                        }
                        break;
                    case "gt":
                        if(rule.value != undefined && object[rule.key] < rule.value) {
                            let message = rule.message;
                            let textString = t("dashboard.cards.missingdata.type." + objectType);
                            let messageObject = {
                                id: object._id,
                                objectTypeString: textString,
                                objectNameString: object.name,
                                message,
                                objectType,
                                systemId: object.system
                            }
                            tempMessages.push(messageObject);
                        }
                        break;    
                }
            }); 
        });
        return tempMessages;
    }

    function getLink(object){
        let tempLink = "";
        switch(object.objectType){
            case "system":
                tempLink  = ("/system/" + object.id);
                break;
            case "systemvalue":
                tempLink  = ("/system/valueeval/" + object.systemId);
                break;
            case "occurences":
                tempLink  = ("/system/bowtie/" + object.systemId);
                break;
        }
        history.push(tempLink);
    }

    async function processData(res) {
        let tempMessages = [...messages];

        let allSystemsData = res[0].data;
        let systemPattern = /system/;
        let systemData = res[0].data.filter(data => data.systemType[0] == undefined || (data.systemType[0] != undefined && systemPattern.test(data.systemType[0].toLowerCase())));
        let systemOrgrosData = res[0].data.filter(data => data.systemType[0] != undefined && data.systemType[0] == "orgros");
        let systemOtherData = res[0].data.filter(data => data.systemType[0] != undefined && data.systemType[0] == "other");
        let systemvalueData = res[1].data;
        let occurences = res[2].data;

        setSystems(systemData);
        tempMessages = checkRules(systemData, "system", tempMessages);
        tempMessages = checkRules(systemvalueData, "systemvalue", tempMessages);
        tempMessages = checkRules(occurences, "occurences", tempMessages);
        setMessages(tempMessages);
    }

    useEffect(() => {
        if(systems.length < 1){
            Promise.all([
                props.requestData("systems"),
                props.requestData("systemvalues"),
                props.requestData("occurences")
            ]).then(async (data) => {
                await processData(data);
            });
        }
    }, []);

    useEffect(() => {
    }, [messages]);

    return (
        <Card data-satarget="missingdata">
            <Card.Body>
                <h4 className="header-title">{t("dashboard.cards.missingdata.title")}</h4>
                <p>{t("dashboard.cards.missingdata.information")}</p>
                <hr /> 
                <div className="pageitems missingdata"> 
                    {messages.length < 1 ? <p>{t("dashboard.cards.missingdata.no_missing_data")}</p> : null}
                    {messages.map((message, i) => 
                        <div key={i} className="missingDataItem" onClick={() => getLink(message)}>
                            <div className="missingDataInformation">
                                <h3 className="missingDataHeading missingDataMessage">{message.message}</h3>
                                <p className="missingDataLineOne">{t("dashboard.cards.missingdata.type_label")}: {message.objectTypeString}</p>
                                <p className="missingDataLineTwo">{t("dashboard.cards.missingdata.name_label")}: {message.objectNameString}</p>
                            </div>
                        </div>
                    )}
                </div>
            </Card.Body>            
        </Card>
    );
}

export default MissingData;