import React from 'react';
import { InputGroup } from 'react-bootstrap';

function Checkbox(props) {
    const handleChange = (value) => {
        if (props.onChange != undefined) {
            props.onChange(value);
        }
    }

    return(
        <InputGroup className="mb-3 checkbox checkbox-clickable">
            <InputGroup.Prepend>
                <InputGroup.Text onClick={() => !props.disabled ? handleChange(!(props.value != undefined ? props.value : (props.defaultValue != undefined ? props.defaultValue : false))) : null}>{props.title}</InputGroup.Text>
                <InputGroup.Checkbox onChange={(evt) => handleChange(evt.target.checked) } disabled={props.disabled} checked={props.value != undefined ? props.value : (props.defaultValue != undefined ? props.defaultValue : false)} name={props.name} /> 
            </InputGroup.Prepend>
        </InputGroup>
    );
}

export default Checkbox;