import * as React from "react";
import { useTranslate } from "react-polyglot";
import ValueCommunicationRegTemplate from "./SystemValueTemplates/ValueCommunicationRegTemplate";
import ValueCompanydataRegTemplate from "./SystemValueTemplates/ValueCompanydataRegTemplate";
import ValueEconomyRegTemplate from "./SystemValueTemplates/ValueEconomyRegTemplate";
import ValueHardwareRegTemplate from "./SystemValueTemplates/ValueHardwareRegTemplate";
import ValueOtherinfoRegTemplate from "./SystemValueTemplates/ValueOtherinfoRegTemplate";
import ValueOtherlawinfoRegTemplate from "./SystemValueTemplates/ValueOtherlawinfoRegTemplate";
import ValuePersoninformationRegTemplate from "./SystemValueTemplates/ValuePersoninformationRegTemplate";
import ValueUsernamePasswordRegTemplate from "./SystemValueTemplates/ValueUsernamePasswordRegTemplate";
import OrgrosRegTemplate from "./Templates/OrgrosRegTemplate";
import SystemRegTemplate from "./Templates/SystemRegTemplate";
import OtherRegTemplate from "./Templates/OtherRegTemplate";

function RegTemplate(props) {
  const t = useTranslate();

  switch (props.regType) {
    case "system":
      return (
        <SystemRegTemplate
          regCompleted={(data) => {
            if (props.regCompleted != undefined) props.regCompleted(data);
          }}
          setPage={(e) => {
            props.setPage(e);
          }}
        ></SystemRegTemplate>
      );
    case "orgros":
      return (
        <OrgrosRegTemplate
          regCompleted={(data) => {
            if (props.regCompleted != undefined) props.regCompleted(data);
          }}
          selectedOrgId={
            props.options != undefined ? props.options.selectedOrgId : undefined
          }
          occurenceOptions={props.occurenceOptions}
          treatmentOptions={props.treatmentOptions}
          systemOptions={props.systemOptions}
          setPage={(e) => {
            props.setPage(e);
          }}
        ></OrgrosRegTemplate>
      );
    case "other":
      return (
        <OtherRegTemplate
          regCompleted={(data) => {
            if (props.regCompleted != undefined) props.regCompleted(data);
          }}
          setPage={(e) => {
            props.setPage(e);
          }}
        ></OtherRegTemplate>
      );
    case "systemvalueusernamepassword":
      return (
        <ValueUsernamePasswordRegTemplate
          regCompleted={(data) => {
            if (props.regCompleted != undefined) props.regCompleted(data);
          }}
          systemid={props.systemid}
          system={props.system}
        ></ValueUsernamePasswordRegTemplate>
      );
    case "systemvaluepersoninformation":
      return (
        <ValuePersoninformationRegTemplate
          regCompleted={(data) => {
            if (props.regCompleted != undefined) props.regCompleted(data);
          }}
          systemid={props.systemid}
          system={props.system}
        ></ValuePersoninformationRegTemplate>
      );
    case "systemvalueeconomy":
      return (
        <ValueEconomyRegTemplate
          regCompleted={(data) => {
            if (props.regCompleted != undefined) props.regCompleted(data);
          }}
          systemid={props.systemid}
          system={props.system}
        ></ValueEconomyRegTemplate>
      );
    case "systemvaluecommunication":
      return (
        <ValueCommunicationRegTemplate
          regCompleted={(data) => {
            if (props.regCompleted != undefined) props.regCompleted(data);
          }}
          systemid={props.systemid}
          system={props.system}
        ></ValueCommunicationRegTemplate>
      );
    case "systemvaluecompanydata":
      return (
        <ValueCompanydataRegTemplate
          regCompleted={(data) => {
            if (props.regCompleted != undefined) props.regCompleted(data);
          }}
          systemid={props.systemid}
          system={props.system}
        ></ValueCompanydataRegTemplate>
      );
    case "systemvaluehardware":
      return (
        <ValueHardwareRegTemplate
          regCompleted={(data) => {
            if (props.regCompleted != undefined) props.regCompleted(data);
          }}
          systemid={props.systemid}
          system={props.system}
        ></ValueHardwareRegTemplate>
      );
    case "systemvalueotherlawinfo":
      return (
        <ValueOtherlawinfoRegTemplate
          regCompleted={(data) => {
            if (props.regCompleted != undefined) props.regCompleted(data);
          }}
          systemid={props.systemid}
          system={props.system}
        ></ValueOtherlawinfoRegTemplate>
      );
    case "systemvalueotherinfo":
      return (
        <ValueOtherinfoRegTemplate
          regCompleted={(data) => {
            if (props.regCompleted != undefined) props.regCompleted(data);
          }}
          systemid={props.systemid}
          system={props.system}
        ></ValueOtherinfoRegTemplate>
      );
    default:
      return <div></div>;
  }
}

export default RegTemplate;
