import * as React from 'react';

import './Filearchive.scss';

export interface FilearchiveProps {
    
}
 
export interface FilearchiveState {
    
}
 
class Filearchive extends React.Component<FilearchiveProps, FilearchiveState> {
    constructor(props: FilearchiveProps) {
        super(props);
        this.state = {
        
        };
    }
    render() { 
        return (
            <div className="maincontent">
<               div className="filearchive-container">
                    <h1 className="dashboard">Filearchive</h1>
                </div>
            </div>
        );
    }
}
 
export default Filearchive;