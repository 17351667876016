const treatmentTypes = [
    { label: "treatment.types.physical", value: "physical" },
    { label: "treatment.types.technical", value: "technical" },
    { label: "treatment.types.administrative", value: "administrative" },
];

const treatmentClasses = [
    { label: "treatment.classes.identify", value: "identify" },
    { label: "treatment.classes.protect", value: "protect" },
    { label: "treatment.classes.discover", value: "discover" },
    { label: "treatment.classes.handle", value: "handle" },
];

export { treatmentTypes, treatmentClasses };