import React, { useState, useEffect } from "react";

import { InputGroup, Form } from "react-bootstrap";

import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";

import { useDynamicLanguage } from "../../../../../DynamicLanguageProvider";

import { generateUrl } from "../../../../../config";

function Select(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const dynamicLanguage = useDynamicLanguage();
  const td = dynamicLanguage.td;

  const [loadedOptions, setLoadedOptions] = useState([]);
  const [localvalue, setLocalvalue] = useState(
    props.value != undefined && props.value != ""
      ? props.value.value != undefined
        ? props.value.value
        : props.value
      : props.defaultValue != undefined
      ? props.defaultValue
      : ""
  );

  const handleChange = (value) => {
    setLocalvalue(value.value);
    if (props.onChange != undefined) {
      props.onChange(value);
    }
  };

  const renderOptions = (options) => {
    return options.map((option, key) => (
      <option key={key} value={option.value}>
        {option.label != undefined &&
        option.label.length == 24 &&
        !option.label.includes(" ")
          ? td(option.label)
          : option.label}
      </option>
    ));
  };

  useEffect(() => {
    if (
      props.options != undefined &&
      props.options.typeLoad != undefined &&
      props.options.typeLoad != "Manual"
    ) {
      switch (props.options.typeLoad) {
        case "Manual":
          break;
        case "Org":
          axios
            .all([
              axios.single({
                method: "get",
                url: generateUrl("/api/orgelements/"),
                responseType: "json",
                headers: { "x-access-token": auth.user["token"] },
              }),
            ])
            .then(
              axios.spread((...res) => {
                setLoadedOptions(
                  res[0].data.map((orgelement) => {
                    return {
                      label: orgelement["name"],
                      value: orgelement["_id"],
                    };
                  })
                );
              })
            );
          break;
        case "System":
          axios
            .all([
              axios.single({
                method: "get",
                url: generateUrl("/api/cra/systems/"),
                responseType: "json",
                headers: { "x-access-token": auth.user["token"] },
              }),
            ])
            .then(
              axios.spread((...res) => {
                setLoadedOptions(
                  res[0].data.map((system) => {
                    return { label: system["name"], value: system["_id"] };
                  })
                );
              })
            );
          break;
        case "User":
          axios
            .all([
              axios.single({
                method: "get",
                url: generateUrl("/api/users"),
                responseType: "json",
                headers: { "x-access-token": auth.user["token"] },
              }),
            ])
            .then(
              axios.spread((...res) => {
                setLoadedOptions(
                  res[0].data.map((user) => {
                    return { label: user["name"], value: user["_id"] };
                  })
                );
              })
            );
          break;
      }
    }
  }, []);

  useEffect(() => {
    if (props.value != undefined) {
      setLocalvalue(
        props.value.value != undefined ? props.value.value : props.value
      );
    }
  }, [props.value]);

  return (
    <InputGroup className="mb-3">
      {props.title != undefined ? (
        <InputGroup.Prepend>
          <InputGroup.Text>
            {props.title.length == 24 && !props.title.includes(" ")
              ? td(props.title)
              : props.title}
          </InputGroup.Text>
        </InputGroup.Prepend>
      ) : null}
      <Form.Control
        as="select"
        disabled={props.disabled}
        //onChange={(evt) => { handleChange({label: evt.currentTarget.value, value: evt.currentTarget.value})}}
        onChange={(evt) => {
          handleChange({
            label:
              props.options?.choices?.[evt.currentTarget.value]?.label ??
              evt.currentTarget.value,
            value: evt.currentTarget.value,
          });
        }}
        value={localvalue}
      >
        {props.options != undefined && props.options.choosetext != undefined ? (
          <option value="">{props.options.choosetext}</option>
        ) : (
          ""
        )}
        {props.options != undefined && props.options.choices != undefined
          ? renderOptions(props.options.choices)
          : ""}
        {loadedOptions != undefined ? renderOptions(loadedOptions) : ""}
      </Form.Control>
    </InputGroup>
  );
}

export default Select;
