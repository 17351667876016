import React, { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import { Card, Table } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { Slider } from "@material-ui/core";
import { Bubble } from "react-chartjs-2";
import { Button } from "react-bootstrap";

import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";

import { useDynamicLanguage } from "../../../DynamicLanguageProvider";

import { generateUrl } from "../../../config";

import "./RiskMatrixChart.scss";

function RiskMatrixChart(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  let history = useHistory();
  const t = useTranslate();
  const td = useDynamicLanguage().td;

  const chartRef = useRef<any>();

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  const [loading, setLoading] = useState(true);
  const [hoverElements, setHoverElements] = useState([{}]);
  const [riskmatrixdata, setRiskmatrixdata] = useState([{}]);
  const [riskMatrixRangeValue, setRiskMatrixRangeValue] = useState(
    props.defaultValue != undefined ? props.defaultValue : 2
  );
  console.log(riskMatrixRangeValue);
  const [occurenceTypes, setOccurenceTypes] = useState<any>();
  const [consequenceTypes, setConsequenceTypes] = useState<any>();

  const riskMatrixMarks = [
    {
      value: 1,
      scaledValue: 1,
      label:
        props.label1 != undefined
          ? props.label1
          : t("system.riskmatrix_before"),
    },
    {
      value: 2,
      scaledValue: 2,
      label: t("system.riskmatrix_now"),
    },
    {
      value: 3,
      scaledValue: 3,
      label: t("system.riskmatrix_planned"),
    },
    {
      value: 4,
      scaledValue: 4,
      label:
        props.label3 != undefined ? props.label3 : t("system.riskmatrix_all"),
    },
  ];

  const label_consequence = t("system.consequence");
  const label_probability = t("system.probability");

  function percentageToValue(percentage, maxvalue) {
    return (maxvalue - 1) * (percentage / 100) + 1;
  }

  function isCloseTo(a, b) {
    let tolerance = 0.25;
    if (window.innerWidth <= 1500) {
      tolerance = 0.55;
    }

    let rangeMin = b - tolerance;
    let rangeMax = b + tolerance;

    return a <= rangeMax && a >= rangeMin;
  }

  function handleSetHoverElements(data) {
    if (
      data["pointItems"].reduce((a, b) => a["key"] + b["key"]) !=
      hoverElements.reduce((a, b) => a["key"] + b["key"])
    ) {
      setHoverElements(data["pointItems"]);
    }
  }

  // Function checking if point (x, y) is above the curve (y = -x + maxvalue)
  function aboveCurve(x, y, maxvalue) {
    let curve = -x + maxvalue;
    return y >= curve;
  }

  function handleGotoBowtieSpecific(evt, element) {
    history.push(
      "/system/bowtie/" +
        (props.systemid != undefined ? props.systemid : element.system) +
        "?occurence=" +
        evt.target.getAttribute("data-occurenceid")
    );
  }

  function fetchData(riskMatrixNumber) {
    axios
      .all([
        axios.single({
          method: "post",
          url:
            props.fromDashboard == true
              ? generateUrl("/api/cra/riskitems/bysystemaccess/")
              : generateUrl("/api/cra/riskitems/bysystem/") + props.systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
          data: {
            treatmentsCompleted:
              props.treatmentsCompleted != undefined && riskMatrixNumber === 3
                ? props.treatmentsCompleted
                : [],
          },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/occurencetypes/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/consequencetypes/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          let riskitems = res[0].data;
          setOccurenceTypes(res[1].data);
          setConsequenceTypes(res[2].data);
          let tempriskmatrixdata = [{}];
          riskitems.forEach((item) => {
            let itemXCalc =
              item["consequence"]["consequenceValue"] *
              item["consequenceReduction"].reduce(
                (acc, val) => ((100 - val) / 100) * acc,
                1
              );
            let itemYCalc =
              item["cause"]["probability"] *
              item["causeReduction"].reduce(
                (acc, val) => ((100 - val) / 100) * acc,
                1
              );
            let itemXPossibleCalc =
              item["consequence"]["consequenceValue"] *
              item["possibleConsequenceReduction"].reduce(
                (acc, val) => ((100 - val) / 100) * acc,
                1
              );
            let itemYPossibleCalc =
              item["cause"]["probability"] *
              item["possibleCauseReduction"].reduce(
                (acc, val) => ((100 - val) / 100) * acc,
                1
              );

            let itemX = percentageToValue(itemXCalc, settings["gridX"]);
            let itemY = percentageToValue(itemYCalc, settings["gridY"]);
            let itemXPossible = percentageToValue(
              itemXPossibleCalc,
              settings["gridX"]
            );
            let itemYPossible = percentageToValue(
              itemYPossibleCalc,
              settings["gridY"]
            );

            let itemXPercent =
              item["consequence"]["consequenceValue"] *
              item["consequenceReduction"].reduce(
                (acc, val) => ((100 - val) / 100) * acc,
                1
              );
            let itemYPercent =
              item["cause"]["probability"] *
              item["causeReduction"].reduce(
                (acc, val) => ((100 - val) / 100) * acc,
                1
              );
            let itemXPercentPossible =
              item["consequence"]["consequenceValue"] *
              item["possibleConsequenceReduction"].reduce(
                (acc, val) => ((100 - val) / 100) * acc,
                1
              );
            let itemYPercentPossible =
              item["cause"]["probability"] *
              item["possibleCauseReduction"].reduce(
                (acc, val) => ((100 - val) / 100) * acc,
                1
              );

            let pointSize = 8;

            if (riskMatrixNumber == 1) {
              itemX = percentageToValue(
                item["consequence"]["consequenceValue"],
                settings["gridX"]
              );
              itemY = percentageToValue(
                item["cause"]["probability"],
                settings["gridY"]
              );
              itemXPercent = item["consequence"]["consequenceValue"];
              itemYPercent = item["cause"]["probability"];
            }
            if (riskMatrixNumber == 4) {
              itemX = itemXPossible;
              itemY = itemYPossible;
              itemXPercent = itemXPercentPossible;
              itemYPercent = itemYPercentPossible;
            }

            if (itemY < 1) itemY = 1;
            if (itemX < 1) itemX = 1;
            if (itemYPercent < 1) itemYPercent = 1;
            if (itemXPercent < 1) itemXPercent = 1;

            if (
              tempriskmatrixdata.some((riskitem) => {
                if (riskitem["data"] != undefined) {
                  return (
                    isCloseTo(itemX, riskitem["data"][0]["x"]) &&
                    isCloseTo(itemY, riskitem["data"][0]["y"])
                  );
                }
                return false;
              })
            ) {
              let pointItem = {
                key: item["consequence"]["_id"] + item["cause"]["_id"],
                system: item["system"] != undefined ? item["system"] : "",
                systemName:
                  item["systemName"] != undefined ? item["systemName"] : "",
                causename: item["cause"]["name"],
                occurencename: item["occurence"]["name"],
                occurence: item["occurence"],
                consequenceObject: item["consequence"],
                consequence: itemX,
                probability: itemY,
              };
              let dataindex = tempriskmatrixdata.findIndex((riskitem) => {
                if (riskitem["data"] != undefined) {
                  return (
                    isCloseTo(itemX, riskitem["data"][0]["x"]) &&
                    isCloseTo(itemY, riskitem["data"][0]["y"])
                  );
                }
                return false;
              });
              tempriskmatrixdata[dataindex]["pointItems"].push(pointItem);
            } else {
              let pointColor = "rgb(91, 130, 102, 1)";
              let colors = [
                "rgb(91, 130, 102, 1)",
                "rgb(250, 205, 117, 1)",
                "rgb(216, 119, 90, 1)",
                "rgb(153, 55, 66, 1)",
              ];

              if (aboveCurve(itemXPercent, itemYPercent, 0)) {
                pointColor = colors[0];
              }
              if (aboveCurve(itemXPercent, itemYPercent, 70)) {
                pointColor = colors[1];
              }
              if (aboveCurve(itemXPercent, itemYPercent, 110)) {
                pointColor = colors[2];
              }
              if (aboveCurve(itemXPercent, itemYPercent, 150)) {
                pointColor = colors[3];
              }

              let riskMatrixItem = {
                label: item["consequence"]["_id"] + item["cause"]["_id"],
                key: item["consequence"]["_id"] + item["cause"]["_id"],
                backgroundColor: pointColor,
                borderColor: pointColor,
                pointBorderColor: "rgba(75,192,192,1)",
                pointHoverBackgroundColor: "rgba(75,192,192,1)",
                pointHoverBorderColor: "rgba(220,220,220,1)",
                hoverRadius: 4,
                pointItems: [
                  {
                    key: item["consequence"]["_id"] + item["cause"]["_id"],
                    system: item["system"] != undefined ? item["system"] : "",
                    systemName:
                      item["systemName"] != undefined ? item["systemName"] : "",
                    causename: item["cause"]["name"],
                    occurencename: item["occurence"]["name"],
                    occurence: item["occurence"],
                    consequenceObject: item["consequence"],
                    consequence: itemX,
                    probability: itemY,
                  },
                ],
                //data: objectarray,
                data: [
                  {
                    x: itemX,
                    y: itemY,
                    r: pointSize,
                  },
                ],
                pointStyle: "",
              };
              tempriskmatrixdata.push(riskMatrixItem);
            }
          });
          tempriskmatrixdata = [
            ...tempriskmatrixdata,
            tempriskmatrixdata.map((data) => {
              let length = data["pointItems"]?.length ?? null;
              data["pointStyle"] = createCanvasBubble(
                length,
                data["backgroundColor"]
              );
            }),
          ];
          setRiskmatrixdata(tempriskmatrixdata);
        })
      );
  }

  function createCanvasBubble(numberOfRisks, bgColor) {
    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    let radius;

    if (numberOfRisks <= 3) {
      radius = 14;
    } else if (numberOfRisks > 3 && numberOfRisks <= 8) {
      radius = 17;
    } else if (numberOfRisks > 8) {
      radius = 20;
    } else {
      radius = 14;
    }

    if (context != null) {
      context.beginPath();
      context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
      context.fillStyle = bgColor;
      context.fill();
      context.lineWidth = 5;
      context.fillStyle = "white";
      context.font = "700 16px sans-serif";
      context.closePath();
      let text = context.measureText(numberOfRisks);
      let textWidth = text.width;
      //let textHeight = text.fontBoundingBoxAscent; //Not compatible with Firefox
      context.fillText(numberOfRisks, centerX - textWidth / 2, centerY + 6);
    }
    return canvas;
  }

  const handleChange = (event, newValue) => {
    if (riskMatrixRangeValue != newValue) {
      setLoading(true);
      setRiskMatrixRangeValue(newValue);
      fetchData(newValue);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (props.systemid != undefined) {
      fetchData(riskMatrixRangeValue);
    }

    if (props.fromDashboard == true) {
      fetchData(riskMatrixRangeValue);
    }
  }, [props.systemid, props.fromDashboard, props.treatmentsCompleted]);

  useEffect(() => {
    setLoading(false);
  }, [riskmatrixdata]);

  return (
    <div className="riskmatrixchart">
      <Card>
        <Card.Body>
          <div
            className={
              "matrixchartloadingcontainer" + (loading ? "" : " hidden")
            }
          >
            <p>{t("dashboard.cards.loadingtext")}</p>
          </div>
          <h4
            style={{
              visibility: props.fromDashboard == true ? "hidden" : "visible",
            }}
            className="header-title"
          >
            {t("system.system_risk_matrix")}
          </h4>
          <div className="riskMatrixSlider no-print">
            <Slider
              min={1}
              max={4}
              step={1}
              defaultValue={props.defaultValue}
              marks={riskMatrixMarks}
              onChange={handleChange}
            />
          </div>

          <hr />
          <div className={"clickable"}>
            <Bubble
              ref={chartRef}
              redraw={false}
              data={{
                datasets: riskmatrixdata,
              }}
              datasetKeyProvider={function (dataset) {
                return dataset["key"];
              }}
              options={{
                animation: {
                  duration: 0,
                },
                legend: {
                  display: false,
                },
                layout: {
                  padding: {
                    left: 0,
                    right: 20,
                    top: 20,
                    bottom: 0,
                  },
                },
                scales: {
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: label_consequence,
                      },
                      ticks: {
                        max: Number(settings["gridX"]),
                        min: 1,
                        stepSize: 1,
                        padding: 25,
                        callback: function (value, index, values) {
                          return td(settings["gridXName" + value]);
                        },
                      },
                    },
                  ],
                  yAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: label_probability,
                      },
                      ticks: {
                        max: Number(settings["gridY"]),
                        min: 1,
                        stepSize: 1,
                        padding: 25,
                        callback: function (value, index, values) {
                          return td(settings["gridYName" + value]);
                        },
                      },
                    },
                  ],
                },
                onClick: function (evt, data) {
                  if (data.length > 0) {
                    handleSetHoverElements(
                      data[0]["_chart"]["data"]["datasets"][
                        data[0]["_datasetIndex"]
                      ]
                    );
                  }
                },
                tooltips: {
                  enabled: false,
                },
              }}
            />
          </div>
          <p className="no-print">
            {riskmatrixdata.length > 1 ? t("system.matrix_info") : ""}
          </p>
          <div className="chartDetails">
            {hoverElements[0]["key"] != undefined ? (
              <div>
                <h4 className="header-title">
                  {t("system.matrix_details_title")}
                </h4>
                <Button
                  className="no-print"
                  onClick={() => {
                    setHoverElements([{}]);
                  }}
                >
                  {t("system.matrix_details_closebtn")}
                </Button>

                <Table striped hover className="chartdetailstable">
                  <thead>
                    <tr>
                      <th>{t("system.causes")}</th>
                      <th>{t("system.event")}</th>
                      <th>{t("system.consequence")}</th>
                      {props.fromDashboard == true ? (
                        <th>{t("system.system")}</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {hoverElements.map((element, key) => {
                      return (
                        <tr
                          key={key}
                          onClick={(evt) =>
                            handleGotoBowtieSpecific(evt, element)
                          }
                        >
                          <td data-occurenceid={element["occurence"]["_id"]}>
                            {element["causename"]}
                          </td>
                          <td data-occurenceid={element["occurence"]["_id"]}>
                            {element["occurencename"] != undefined &&
                            element["occurencename"] != ""
                              ? element["occurencename"]
                              : occurenceTypes != undefined
                              ? td(
                                  occurenceTypes.find(
                                    (occ) =>
                                      occ._id == element["occurence"]["type"]
                                  ).name
                                )
                              : ""}
                          </td>
                          <td data-occurenceid={element["occurence"]["_id"]}>
                            {element["consequenceObject"]["name"] !=
                              undefined &&
                            element["consequenceObject"]["name"] != ""
                              ? element["consequenceObject"]["name"]
                              : consequenceTypes.find(
                                  (con) =>
                                    con._id ==
                                    element["consequenceObject"]["category"]
                                ) != undefined
                              ? td(
                                  consequenceTypes.find(
                                    (con) =>
                                      con._id ==
                                      element["consequenceObject"]["category"]
                                  ).name
                                )
                              : ""}
                          </td>
                          {props.fromDashboard == true ? (
                            <td>{element["systemName"]}</td>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              ""
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default RiskMatrixChart;
