import React, { useEffect, useState } from 'react';
import * as _ from "lodash";
import { useAxios } from "../../../../../AxiosHandler";
import moment from 'moment';

import { useTranslate } from 'react-polyglot';
import './TreatmentBurndown.scss';
import { useAuth } from "../../../../../Auth";
import { Card, Button } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import DatePicker from '../../../../Shared/Wizard/Field/Fieldtypes/DateSelector' 

const TreatmentBurndown = (props) => {

    const [allTreatments, setAllTreatments] = useState([{}]);
    const [completedTreatments, setCompletedTreatments] = useState([{}]);
    const [processTreatments, setProcessTreatments] = useState([{}]);
    const [monthLabels, setMonthLabels] = useState([]);
    const [chartOptions, setChartOptions] = useState({});
    const [loading, setLoading] = useState(true);

    const [startDate, setStartDate] = useState(new Date(moment().subtract(11, "months").toDate() ));
    const [endDate, setEndDate] = useState(new Date());

    const t = useTranslate();

    function handleDateRange(date,direction){
        if (direction != undefined && direction == "from") { setStartDate(date); }
        if (direction != undefined && direction == "to") { setEndDate(date); }
        fetchMonths();
    }

    async function fetchMonths(){
        let startDatepicker = moment(startDate, "YYYY-MM-DD");
        let endDatepicker = moment(endDate, "YYYY-MM-DD").endOf("month");
        let allMonthsInPeriod:any = [];

        while (startDatepicker.isBefore(endDatepicker)) {
            allMonthsInPeriod.push(startDatepicker.format("YYYY-MM"));
            startDatepicker = startDatepicker.add(1, "month");
        };
        setMonthLabels(allMonthsInPeriod);

        return allMonthsInPeriod;
    }

    async function processData(res) {
        let returns = {};

        let months = await fetchMonths();

        let closedTreatments = res[0].data.filter(item => item["status"]  == "closed");
        let processTreatments = res[0].data.filter(item => item["status"]  == "processing");
        let tmpClosedTreatments:any =[];
        let tmpProcessTreatments:any =[];
        let tmpAllTreatments:any =[];

        // for all treatments
        let orderedByAllTreatments;
            orderedByAllTreatments = _.groupBy(res[0].data, function(element) {
            return element.date.substring(0,7);
        });

        if(orderedByAllTreatments != undefined && orderedByAllTreatments.length != 0){
            let monthsAdded = 0;
            tmpAllTreatments = months.map(month => {
                monthsAdded += orderedByAllTreatments[month] != undefined ? orderedByAllTreatments[month].length : 0
                return monthsAdded;
            });
        }
        returns["allTreatments"] = tmpAllTreatments;

        // for completed treatments
        let orderedByCompletedTreatments;
        orderedByCompletedTreatments = _.groupBy(closedTreatments, function(element) {
            return element.date.substring(0,7);
        });

        if(orderedByCompletedTreatments != undefined && orderedByCompletedTreatments.length != 0){
            let monthsAdded = 0;
            tmpClosedTreatments = months.map(month => {
                monthsAdded += orderedByCompletedTreatments[month] != undefined ? orderedByCompletedTreatments[month].length : 0
                return monthsAdded;
            });
        }
        returns["tmpClosedTreatments"] = tmpClosedTreatments;

        // for process treatments
        let orderedByProcessTreatments;
        orderedByProcessTreatments = _.groupBy(processTreatments, function(element) {
            return element.date.substring(0,7);
        });

        if(orderedByProcessTreatments != undefined && orderedByProcessTreatments.length != 0){
            let monthsAdded = 0;
            tmpProcessTreatments = months.map(month => {
                monthsAdded += orderedByProcessTreatments[month] != undefined ? orderedByProcessTreatments[month].length : 0
                return monthsAdded;
            });
        }
        returns["tmpProcessTreatments"] = tmpProcessTreatments.map((item, key) => item + tmpClosedTreatments[key]);

        setAllTreatments(returns["allTreatments"]);
        setCompletedTreatments(returns["tmpClosedTreatments"]);
        setProcessTreatments(returns["tmpProcessTreatments"]);
        setChartOptions({
            legend: {
                display: true,
                position: 'top',
                labels: {
                    boxWidth: 80,
                    fontColor: 'black'
                },
                onClick: false
                },
                scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            let label = data.datasets[tooltipItem.datasetIndex].label || '';
                            let labelValue = tooltipItem.yLabel;

                            switch (data.datasets[tooltipItem.datasetIndex].datasetname) {
                                case "all":
                                    labelValue = tooltipItem.yLabel - data.datasets[1]["data"][tooltipItem.index];
                                    break;
                                case "progress":
                                    labelValue = tooltipItem.yLabel - data.datasets[0]["data"][tooltipItem.index];
                                    break;
                                case "completed":
                                    
                                    break;
                            }

                            return label + ": " + labelValue;
                        }
                    }
                }
        });
    }

    useEffect(() => {
        Promise.all([
            props.requestData("treatments")
        ]).then(async (data) => {
            await processData(data);
            setLoading(false);
        });
    }, [startDate, endDate]);

    return (
        <Card>
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                <h4 className="header-title">{t("dashboard.cards.treatmentburndown_name")}</h4>
                <p>{t("dashboard.cards.treatmentburndown_description")}</p>
                <hr />
                <div className="row">
                    <div className='col-md-6'>
                        <DatePicker
                            title={t("dashboard.cards.treatmentburndown_frommonth")}
                            selected={startDate}
                            onChange={date => handleDateRange(date as Date, "from")}
                            selectsStart
                            minDate={new Date(moment().subtract(3, "years").toDate())}
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="dd/MM/yyyy"
                            showMonthYearPicker
                        />
                    </div>
                    <div className='col-md-6'>
                        <DatePicker
                            title={t("dashboard.cards.treatmentburndown_tomonth")}
                            selected={endDate}
                            onChange={date => handleDateRange(date as Date, "to")}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={new Date(moment().subtract(3, "years").toDate())}
                            dateFormat="dd/MM/yyyy"
                            showMonthYearPicker
                        />
                    </div>
                    {/* <div className='col-md-4'><Button onClick={fetchMonths}>Apply</Button></div> */}
                </div>
                <hr/>
                <Line
                    redraw={false}
                    type = "line"
                    data={{
                        labels: monthLabels,
                        datasets: [
                            {
                                label: t("dashboard.cards.treatmentburndown_label_completedtreatments"),
                                backgroundColor: "#5B8266",
                                fill: true,
                                data: completedTreatments,
                                cubicInterpolationMode: "monotone",
                                datasetname: "completed"
                            },
                            {
                                label: t("dashboard.cards.treatmentburndown_label_processtreatments"),
                                backgroundColor: "#FACD75",
                                fill: true,
                                data: processTreatments,
                                cubicInterpolationMode: "monotone",
                                datasetname: "progress"
                            },
                            {
                                label: t("dashboard.cards.treatmentburndown_label_alltreatments"),
                                backgroundColor: "#d8775a",
                                fill: true,
                                data: allTreatments,
                                cubicInterpolationMode: "monotone",
                                datasetname: "all"
                            },
                        ]
                    }}
                    options={chartOptions}
                />
            </Card.Body>
        </Card>
    );
}

export default TreatmentBurndown;
