import * as React from "react";
import { Card, Button, InputGroup, Form, Accordion } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Settings.scss";
import "./SettingsCustomFields.scss";
import Field from "../../Shared/Wizard/Field/Field";
import { generateUrl } from "../../../config";

function SettingsCustomFields(props) {
  const t = useTranslate();
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const [redrawer, setRedrawer] = useState(true);
  const [settings, setSettings] = useState(props.settings);

  const [customiseItem, setCustomiseItem] = useState("");

  //Customfields for System
  const setSettingsCustomfieldsSystem = (value, key) => {
    let customfieldsSystem = settings["customfieldsSystem"];
    if (customfieldsSystem != undefined) {
      (customfieldsSystem[key] as Object)["key"] = value;
    }
    setSettings((settings) => ({
      ...settings,
      customfieldsSystem: customfieldsSystem,
    }));
    handleSaveSettingsCustomfields({
      ...settings,
      customfieldsSystem: customfieldsSystem,
    });
  };

  const setSettingsCustomfieldsSystemType = (value, key) => {
    let customfieldsSystem = settings["customfieldsSystem"];
    if (customfieldsSystem != undefined) {
      (customfieldsSystem[key] as Object)["type"] = value;
    }
    setSettings((settings) => ({
      ...settings,
      customfieldsSystem: customfieldsSystem,
    }));
    handleSaveSettingsCustomfields({
      ...settings,
      customfieldsSystem: customfieldsSystem,
    });
  };
  //Customfields for Treatment
  const setSettingsCustomfieldsTreatment = (value, key) => {
    let customfieldsTreatment = settings["customfieldsTreatment"];
    if (customfieldsTreatment != undefined) {
      (customfieldsTreatment[key] as Object)["key"] = value;
    }
    setSettings((settings) => ({
      ...settings,
      customfieldsTreatment: customfieldsTreatment,
    }));
    handleSaveSettingsCustomfields({
      ...settings,
      customfieldsTreatment: customfieldsTreatment,
    });
  };

  const setSettingsCustomfieldsTreatmentType = (value, key) => {
    let customfieldsTreatment = settings["customfieldsTreatment"];
    if (customfieldsTreatment != undefined) {
      (customfieldsTreatment[key] as Object)["type"] = value;
    }
    setSettings((settings) => ({
      ...settings,
      customfieldsTreatment: customfieldsTreatment,
    }));
    handleSaveSettingsCustomfields({
      ...settings,
      customfieldsTreatment: customfieldsTreatment,
    });
  };

  //Customfields for User
  const setSettingsCustomfieldsUser = (value, key) => {
    let customfieldsUser = settings["customfieldsUser"];

    if (customfieldsUser != undefined) {
      (customfieldsUser[key] as Object)["key"] = value;
    }

    setSettings((settings) => ({
      ...settings,
      customfieldsUser: customfieldsUser,
    }));
    handleSaveSettingsCustomfields({
      ...settings,
      customfieldsUser: customfieldsUser,
    });
  };

  const setSettingsCustomfieldsUserType = (value, key) => {
    let customfieldsUser = settings["customfieldsUser"];
    if (customfieldsUser != undefined) {
      (customfieldsUser[key] as Object)["type"] = value;
    }
    setSettings((settings) => ({
      ...settings,
      customfieldsUser: customfieldsUser,
    }));
    handleSaveSettingsCustomfields({
      ...settings,
      customfieldsUser: customfieldsUser,
    });
  };

  function handleCustomfieldsSystemAdd() {
    let customfieldsSystem = settings["customfieldsSystem"];
    if (customfieldsSystem != undefined) {
      customfieldsSystem.push({
        key: "",
        type: "string",
      });
    }
    setSettings((settings) => ({
      ...settings,
      customfieldsSystem: customfieldsSystem,
    }));
    handleSaveSettingsCustomfields({
      ...settings,
      customfieldsSystem: customfieldsSystem,
    });
  }

  function setSettingsCustomfieldsSystemDropdownOption(value, key, optionkey) {
    let customfieldsSystem = settings["customfieldsSystem"];
    let options = customfieldsSystem[key]["options"];
    options[optionkey] = value;
    setSettings((settings) => ({
      ...settings,
      customfieldsSystem: customfieldsSystem,
    }));
    handleSaveSettingsCustomfields({
      ...settings,
      customfieldsSystem: customfieldsSystem,
    });
  }

  function setSettingsCustomfieldsSystemDropdownOptionDelete(key, optionkey) {
    let customfieldsSystem = settings["customfieldsSystem"];
    let options = customfieldsSystem[key]["options"];
    options.splice(optionkey, 1);
    setSettings((settings) => ({
      ...settings,
      customfieldsSystem: customfieldsSystem,
    }));
    handleSaveSettingsCustomfields({
      ...settings,
      customfieldsSystem: customfieldsSystem,
    });
  }

  function handleCustomfieldsSystemDropdownOptionAdd(key) {
    let customfieldsSystem = settings["customfieldsSystem"];
    let field = customfieldsSystem[key];
    if (field["options"] != undefined) {
      field["options"].push("");
    } else {
      field["options"] = [""];
    }
    setRedrawer(!redrawer);
  }

  function handleCustomfieldsSystemCustomise(identity) {
    if (identity != customiseItem) {
      setCustomiseItem(identity);
    } else {
      setCustomiseItem("");
    }
  }

  function handleCustomfieldsSystemDelete(key) {
    let customfieldsSystem = settings["customfieldsSystem"];
    if (customfieldsSystem != undefined) {
      customfieldsSystem.splice(key, 1);
    }
    setSettings((settings) => ({
      ...settings,
      customfieldsSystem: customfieldsSystem,
    }));
  }

  //Customfields for Treatment
  function handleCustomfieldsTreatmentAdd() {
    let customfieldsTreatment = settings["customfieldsTreatment"];
    if (customfieldsTreatment != undefined) {
      customfieldsTreatment.push({
        key: "",
        type: "string",
      });
    }
    setSettings((settings) => ({
      ...settings,
      customfieldsTreatment: customfieldsTreatment,
    }));
  }

  function setSettingsCustomfieldsTreatmentDropdownOption(
    value,
    key,
    optionkey
  ) {
    let customfieldsTreatment = settings["customfieldsTreatment"];
    let options = customfieldsTreatment[key]["options"];
    options[optionkey] = value;
    setSettings((settings) => ({
      ...settings,
      customfieldsTreatment: customfieldsTreatment,
    }));
  }

  function setSettingsCustomfieldsTreatmentDropdownOptionDelete(
    key,
    optionkey
  ) {
    let customfieldsTreatment = settings["customfieldsTreatment"];
    let options = customfieldsTreatment[key]["options"];
    options.splice(optionkey, 1);
    setSettings((settings) => ({
      ...settings,
      customfieldsTreatment: customfieldsTreatment,
    }));
  }

  function handleCustomfieldsTreatmentDropdownOptionAdd(key) {
    let customfieldsTreatment = settings["customfieldsTreatment"];
    let field = customfieldsTreatment[key];
    if (field["options"] != undefined) {
      field["options"].push("");
    } else {
      field["options"] = [""];
    }
    setRedrawer(!redrawer);
  }

  function handleCustomfieldsTreatmentCustomise(identity) {
    if (identity != customiseItem) {
      setCustomiseItem(identity);
    } else {
      setCustomiseItem("");
    }
  }

  function handleCustomfieldsTreatmentDelete(key) {
    let customfieldsTreatment = settings["customfieldsTreatment"];
    if (customfieldsTreatment != undefined) {
      customfieldsTreatment.splice(key, 1);
    }
    setSettings((settings) => ({
      ...settings,
      customfieldsTreatment: customfieldsTreatment,
    }));
  }

  //Customfields for User
  function handleCustomfieldsUserAdd() {
    let customfieldsUser = settings["customfieldsUser"];
    if (customfieldsUser != undefined) {
      customfieldsUser.push({
        key: "",
        type: "string",
      });
    }
    setSettings((settings) => ({
      ...settings,
      customfieldsUser: customfieldsUser,
    }));
  }

  function setSettingsCustomfieldsUserDropdownOption(value, key, optionkey) {
    let customfieldsUser = settings["customfieldsUser"];
    let options = customfieldsUser[key]["options"];
    options[optionkey] = value;
    setSettings((settings) => ({
      ...settings,
      customfieldsUser: customfieldsUser,
    }));
  }

  function setSettingsCustomfieldsUserDropdownOptionDelete(key, optionkey) {
    let customfieldsUser = settings["customfieldsUser"];
    let options = customfieldsUser[key]["options"];
    options.splice(optionkey, 1);
    setSettings((settings) => ({
      ...settings,
      customfieldsUser: customfieldsUser,
    }));
  }

  function handleCustomfieldsUserDropdownOptionAdd(key) {
    let customfieldsUser = settings["customfieldsUser"];
    let field = customfieldsUser[key];
    if (field["options"] != undefined) {
      field["options"].push("");
    } else {
      field["options"] = [""];
    }
    setRedrawer(!redrawer);
  }

  function handleCustomfieldsUserCustomise(identity) {
    if (identity != customiseItem) {
      setCustomiseItem(identity);
    } else {
      setCustomiseItem("");
    }
  }

  function handleCustomfieldsUserDelete(key) {
    let customfieldsUser = settings["customfieldsUser"];
    if (customfieldsUser != undefined) {
      customfieldsUser.splice(key, 1);
    }
    setSettings((settings) => ({
      ...settings,
      customfieldsUser: customfieldsUser,
    }));
    handleSaveSettingsCustomfields({
      ...settings,
      customfieldsUser: customfieldsUser,
    });
  }

  function getUniqueListBy(arr, key) {
    let newArr: Array<any> = [];
    arr.reduce(function (a, b) {
      if (a[key] !== b[key]) newArr.push(b);
      return b;
    }, []);
    return newArr;
  }

  async function handleSaveSettingsCustomfields(payload?: any) {
    if (!payload) {
      payload = settings;
    }
    let customfieldsSystem = settings["customfieldsSystem"];
    let customfieldsTreatment = settings["customfieldsTreatment"];
    let customfieldsUser = settings["customfieldsUser"];

    const uniqSystemCustomfields = getUniqueListBy(customfieldsSystem, "key");
    const uniqTreatmentCustomfields = getUniqueListBy(
      customfieldsTreatment,
      "key"
    );
    const uniqUserCustomfields = getUniqueListBy(customfieldsUser, "key");

    axios
      .put(
        generateUrl("/api/cra/settings/") + settings["_id"],
        {
          org: settings["org"],

          customfieldsSystem: uniqSystemCustomfields,
          customfieldsTreatment: uniqTreatmentCustomfields,
          customfieldsUser: uniqUserCustomfields,

          apiPath: settings["apiPath"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        if (response.status == 200) {
          notifySuccess(t("settings.toast_savesuccess"));
        }
        if (props.onSettingsGeneralChanged != undefined) {
          props.onSettingsGeneralChanged();
        }
      });
  }

  useEffect(() => {
    setSettings(props.settings);
  }, [props]);

  return (
    <div>
      <Card>
        <Accordion.Toggle className="clickable" as={Card.Header} eventKey="2">
          <h4 className="header-title">
            {t("settings.customfields_title_system")}
          </h4>
          <p>{t("settings.customfields_info")}</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <div className="settingsarea">
              <div className="row">
                <div className="col-md-12 ">
                  <p>{t("settings.customfields_title_system")}</p>
                  {props.settingsInherited != undefined &&
                  props.settingsInherited["customfieldsSystem"] != undefined
                    ? props.settingsInherited["customfieldsSystem"].map(
                        (item, key) => {
                          return (
                            <div key={key} className="customfielditem">
                              <div className="row">
                                <div className="col-md-3 inputField flex-wrap">
                                  <Field
                                    type="textlanguage"
                                    value={item["key"]}
                                    onChange={(value) =>
                                      setSettingsCustomfieldsSystem(value, key)
                                    }
                                    options={{
                                      category:
                                        "settings.customfields.customfieldsSystem",
                                    }}
                                    disabled={true}
                                  ></Field>
                                </div>
                                <div>
                                  <InputGroup className="mb-3 flex-wrap">
                                    <Form.Control
                                      as="select"
                                      disabled={true}
                                      value={item["type"]}
                                    >
                                      <option value="text">
                                        {t("settings.customfields_type_text")}
                                      </option>
                                      <option value="dropdown">
                                        {t(
                                          "settings.customfields_type_dropdown"
                                        )}
                                      </option>
                                    </Form.Control>
                                  </InputGroup>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )
                    : ""}
                  {settings["customfieldsSystem"] != undefined
                    ? settings["customfieldsSystem"].map((item, key) => {
                        return (
                          <div key={key} className=" customfielditem">
                            <div className="row">
                              <div className="col-md-3 inputField flex-wrap">
                                <Field
                                  disabled={!props.userHasEditRights}
                                  type="textlanguage"
                                  value={item["key"]}
                                  onChange={(value) =>
                                    setSettingsCustomfieldsSystem(value, key)
                                  }
                                  options={{
                                    category:
                                      "settings.customfields.customfieldsSystem",
                                  }}
                                ></Field>
                              </div>
                              <div className="d-flex flex-wrap">
                                <InputGroup className="mb-3 flex-wrap">
                                  <Form.Control
                                    as="select"
                                    disabled={!props.userHasEditRights}
                                    onChange={(evt) =>
                                      setSettingsCustomfieldsSystemType(
                                        (evt.target as HTMLSelectElement).value,
                                        key
                                      )
                                    }
                                    value={
                                      settings["customfieldsSystem"].find(
                                        (field) => field["key"] == item["key"]
                                      )["type"]
                                    }
                                  >
                                    <option value="text">
                                      {t("settings.customfields_type_text")}
                                    </option>
                                    <option value="dropdown">
                                      {t("settings.customfields_type_dropdown")}
                                    </option>
                                  </Form.Control>
                                  <InputGroup.Prepend
                                    className={
                                      (customiseItem == "2-" + key
                                        ? "active"
                                        : "") +
                                      " clickable " +
                                      (item["type"] == "dropdown"
                                        ? ""
                                        : "disabled")
                                    }
                                    onClick={() =>
                                      item["type"] == "dropdown"
                                        ? handleCustomfieldsSystemCustomise(
                                            "2-" + key
                                          )
                                        : ""
                                    }
                                  >
                                    <InputGroup.Text>
                                      {t("settings.customfields_customise_btn")}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <InputGroup.Prepend
                                    className={
                                      "clickable delete " +
                                      (!props.userHasEditRights
                                        ? "disabled"
                                        : "")
                                    }
                                    onClick={() => {
                                      if (props.userHasEditRights) {
                                        if (
                                          window.confirm(
                                            t("settings.confirm_delete_text")
                                          )
                                        )
                                          handleCustomfieldsSystemDelete(key);
                                      }
                                    }}
                                  >
                                    <InputGroup.Text>
                                      {t("settings.customfields_delete_btn")}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                </InputGroup>
                              </div>
                            </div>
                            <div
                              className={
                                "customfieldcustomiser " +
                                (customiseItem == "2-" + key ? "" : "hidden")
                              }
                            >
                              {item["type"] == "text" ? (
                                <div>
                                  <p>test Customiser Text</p>
                                </div>
                              ) : (
                                ""
                              )}
                              {item["type"] == "dropdown" ? (
                                <div>
                                  {item["options"] != undefined
                                    ? item["options"].map(
                                        (option, optionkey) => {
                                          return (
                                            <div
                                              key={"option" + optionkey}
                                              className="row"
                                            >
                                              <div className="inputField flex-wrap">
                                                <Field
                                                  disabled={
                                                    !props.userHasEditRights
                                                  }
                                                  type="textlanguage"
                                                  value={
                                                    settings[
                                                      "customfieldsSystem"
                                                    ].find(
                                                      (field) =>
                                                        field["key"] ==
                                                        item["key"]
                                                    )["options"][optionkey]
                                                  }
                                                  onChange={(value) =>
                                                    setSettingsCustomfieldsSystemDropdownOption(
                                                      value,
                                                      key,
                                                      optionkey
                                                    )
                                                  }
                                                  options={{
                                                    category:
                                                      "settings.customfields.customfieldsSystem",
                                                  }}
                                                ></Field>
                                              </div>
                                              <div>
                                                <InputGroup className="mb-3 flex-wrap">
                                                  <InputGroup.Prepend
                                                    className={
                                                      "clickable delete " +
                                                      (!props.userHasEditRights
                                                        ? "disabled"
                                                        : "")
                                                    }
                                                    onClick={() => {
                                                      if (
                                                        props.userHasEditRights
                                                      ) {
                                                        if (
                                                          window.confirm(
                                                            t(
                                                              "settings.confirm_delete_text"
                                                            )
                                                          )
                                                        )
                                                          setSettingsCustomfieldsSystemDropdownOptionDelete(
                                                            key,
                                                            optionkey
                                                          );
                                                      }
                                                    }}
                                                  >
                                                    <InputGroup.Text>
                                                      {t(
                                                        "settings.customfields_delete_btn"
                                                      )}
                                                    </InputGroup.Text>
                                                  </InputGroup.Prepend>
                                                </InputGroup>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                    : ""}
                                  <Button
                                    disabled={!props.userHasEditRights}
                                    className="customfieldsSystemDropdownOptionAddBtn"
                                    onClick={() =>
                                      handleCustomfieldsSystemDropdownOptionAdd(
                                        key
                                      )
                                    }
                                  >
                                    {t(
                                      "settings.customfields_dropdownoption_add_btn"
                                    )}
                                  </Button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      })
                    : ""}
                  <Button
                    disabled={!props.userHasEditRights}
                    className="customfieldsSystemAddBtn"
                    onClick={handleCustomfieldsSystemAdd}
                  >
                    {t("settings.customfields_add_btn")}
                  </Button>
                </div>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle className="clickable" as={Card.Header} eventKey="3">
          <h4 className="header-title">
            {t("settings.customfields_title_treatment")}
          </h4>
          <p>{t("settings.customfields_info")}</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <div className="settingsarea">
              <div className="row">
                <div className="col-md-12">
                  <p>{t("settings.customfields_title_treatment")}</p>
                  {props.settingsInherited != undefined &&
                  props.settingsInherited["customfieldsTreatment"] != undefined
                    ? props.settingsInherited["customfieldsTreatment"].map(
                        (item, key) => {
                          return (
                            <div key={key} className="customfielditem">
                              <div className="row">
                                <div className="col-md-2 inputField">
                                  <Field
                                    type="textlanguage"
                                    value={item["key"]}
                                    onChange={(value) =>
                                      setSettingsCustomfieldsTreatment(
                                        value,
                                        key
                                      )
                                    }
                                    options={{
                                      category:
                                        "settings.customfields.customfieldsTreatment",
                                    }}
                                    disabled={true}
                                  ></Field>
                                </div>
                                <div>
                                  <InputGroup className="mb-3 flex-wrap">
                                    <Form.Control
                                      as="select"
                                      disabled={true}
                                      value={item["type"]}
                                    >
                                      <option value="text">
                                        {t("settings.customfields_type_text")}
                                      </option>
                                      <option value="dropdown">
                                        {t(
                                          "settings.customfields_type_dropdown"
                                        )}
                                      </option>
                                    </Form.Control>
                                  </InputGroup>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )
                    : ""}
                  {settings["customfieldsTreatment"] != undefined
                    ? settings["customfieldsTreatment"].map((item, key) => {
                        return (
                          <div key={key} className=" customfielditem">
                            <div className="row">
                              <div className="col-md-3 inputField flex-wrap">
                                <Field
                                  disabled={!props.userHasEditRights}
                                  type="textlanguage"
                                  value={item["key"]}
                                  onChange={(value) =>
                                    setSettingsCustomfieldsTreatment(value, key)
                                  }
                                  options={{
                                    category:
                                      "settings.customfields.customfieldsTreatment",
                                  }}
                                ></Field>
                              </div>
                              <div className="inputField flex-wrap">
                                <InputGroup className="mb-3 flex-wrap">
                                  <Form.Control
                                    as="select"
                                    disabled={!props.userHasEditRights}
                                    onChange={(evt) =>
                                      setSettingsCustomfieldsTreatmentType(
                                        (evt.target as HTMLSelectElement).value,
                                        key
                                      )
                                    }
                                    value={
                                      settings["customfieldsTreatment"].find(
                                        (field) => field["key"] == item["key"]
                                      )["type"]
                                    }
                                  >
                                    <option value="text">
                                      {t("settings.customfields_type_text")}
                                    </option>
                                    <option value="dropdown">
                                      {t("settings.customfields_type_dropdown")}
                                    </option>
                                  </Form.Control>
                                  <InputGroup.Prepend
                                    className={
                                      (customiseItem == "2-" + key
                                        ? "active"
                                        : "") +
                                      " clickable " +
                                      (item["type"] == "dropdown"
                                        ? ""
                                        : "disabled")
                                    }
                                    onClick={() =>
                                      item["type"] == "dropdown"
                                        ? handleCustomfieldsTreatmentCustomise(
                                            "2-" + key
                                          )
                                        : ""
                                    }
                                  >
                                    <InputGroup.Text>
                                      {t("settings.customfields_customise_btn")}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <InputGroup.Prepend
                                    className={
                                      "clickable delete " +
                                      (!props.userHasEditRights
                                        ? "disabled"
                                        : "")
                                    }
                                    onClick={() => {
                                      if (props.userHasEditRights) {
                                        if (
                                          window.confirm(
                                            t("settings.confirm_delete_text")
                                          )
                                        )
                                          handleCustomfieldsTreatmentDelete(
                                            key
                                          );
                                      }
                                    }}
                                  >
                                    <InputGroup.Text>
                                      {t("settings.customfields_delete_btn")}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                </InputGroup>
                              </div>
                            </div>
                            <div
                              className={
                                "customfieldcustomiser " +
                                (customiseItem == "2-" + key ? "" : "hidden")
                              }
                            >
                              {item["type"] == "text" ? (
                                <div>
                                  <p>test Customiser Text</p>
                                </div>
                              ) : (
                                ""
                              )}
                              {item["type"] == "dropdown" ? (
                                <div>
                                  {item["options"] != undefined
                                    ? item["options"].map(
                                        (option, optionkey) => {
                                          return (
                                            <div
                                              key={optionkey}
                                              className="row"
                                            >
                                              <div className="inputField flex-wrap">
                                                <Field
                                                  disabled={
                                                    !props.userHasEditRights
                                                  }
                                                  type="textlanguage"
                                                  value={
                                                    settings[
                                                      "customfieldsTreatment"
                                                    ].find(
                                                      (field) =>
                                                        field["key"] ==
                                                        item["key"]
                                                    )["options"][optionkey]
                                                  }
                                                  onChange={(value) =>
                                                    setSettingsCustomfieldsTreatmentDropdownOption(
                                                      value,
                                                      key,
                                                      optionkey
                                                    )
                                                  }
                                                  options={{
                                                    category:
                                                      "settings.customfields.customfieldsTreatment",
                                                  }}
                                                ></Field>
                                              </div>
                                              <div>
                                                <InputGroup className="mb-3 flex-wrap">
                                                  <InputGroup.Prepend
                                                    className={
                                                      "clickable delete " +
                                                      (!props.userHasEditRights
                                                        ? "disabled"
                                                        : "")
                                                    }
                                                    onClick={() => {
                                                      if (
                                                        props.userHasEditRights
                                                      ) {
                                                        if (
                                                          window.confirm(
                                                            t(
                                                              "settings.confirm_delete_text"
                                                            )
                                                          )
                                                        )
                                                          setSettingsCustomfieldsTreatmentDropdownOptionDelete(
                                                            key,
                                                            optionkey
                                                          );
                                                      }
                                                    }}
                                                  >
                                                    <InputGroup.Text>
                                                      {t(
                                                        "settings.customfields_delete_btn"
                                                      )}
                                                    </InputGroup.Text>
                                                  </InputGroup.Prepend>
                                                </InputGroup>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                    : ""}
                                  <Button
                                    disabled={!props.userHasEditRights}
                                    className="customfieldsTreatmentDropdownOptionAddBtn"
                                    onClick={() =>
                                      handleCustomfieldsTreatmentDropdownOptionAdd(
                                        key
                                      )
                                    }
                                  >
                                    {t(
                                      "settings.customfields_dropdownoption_add_btn"
                                    )}
                                  </Button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      })
                    : ""}
                  <Button
                    disabled={!props.userHasEditRights}
                    className="customfieldsTreatmentAddBtn"
                    onClick={handleCustomfieldsTreatmentAdd}
                  >
                    {t("settings.customfields_add_btn")}
                  </Button>
                </div>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle className="clickable" as={Card.Header} eventKey="4">
          <h4 className="header-title">
            {t("settings.customfields_title_user")}
          </h4>
          <p>{t("settings.customfields_info")}</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            <div className="settingsarea">
              <div className="row customfielditem">
                <div className="col-md-12">
                  <p>{t("settings.customfields_title_user")}</p>
                  {props.settingsInherited != undefined &&
                  props.settingsInherited["customfieldsUser"] != undefined
                    ? props.settingsInherited["customfieldsUser"].map(
                        (item, key) => {
                          return (
                            <div key={key} className="customfielditem">
                              <div className="row">
                                <div className="col-md-3 inputField flex-wrap">
                                  <Field
                                    type="textlanguage"
                                    value={item["key"]}
                                    onChange={(value) =>
                                      setSettingsCustomfieldsUser(value, key)
                                    }
                                    options={{
                                      category:
                                        "settings.customfields.customfieldsUser",
                                    }}
                                    disabled={true}
                                  ></Field>
                                </div>
                                <div>
                                  <InputGroup>
                                    <Form.Control
                                      as="select"
                                      disabled={true}
                                      value={item["type"]}
                                    >
                                      <option value="text">
                                        {t("settings.customfields_type_text")}
                                      </option>
                                      <option value="dropdown">
                                        {t(
                                          "settings.customfields_type_dropdown"
                                        )}
                                      </option>
                                    </Form.Control>
                                  </InputGroup>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )
                    : ""}
                  {settings["customfieldsUser"] != undefined
                    ? settings["customfieldsUser"].map((item, key) => {
                        return (
                          <div key={key}>
                            <div className="row">
                              <div className="col-md-3 inputField flex-wrap">
                                <Field
                                  disabled={!props.userHasEditRights}
                                  type="textlanguage"
                                  value={item["key"]}
                                  onChange={(value) =>
                                    setSettingsCustomfieldsUser(value, key)
                                  }
                                  options={{
                                    category:
                                      "settings.customfields.customfieldsUser",
                                  }}
                                ></Field>
                              </div>
                              <div className="inputField flex-wrap">
                                <InputGroup>
                                  <Form.Control
                                    as="select"
                                    disabled={!props.userHasEditRights}
                                    onChange={(evt) =>
                                      setSettingsCustomfieldsUserType(
                                        (evt.target as HTMLSelectElement).value,
                                        key
                                      )
                                    }
                                    value={
                                      settings["customfieldsUser"].find(
                                        (field) => field["key"] == item["key"]
                                      )["type"]
                                    }
                                  >
                                    <option value="text">
                                      {t("settings.customfields_type_text")}
                                    </option>
                                    <option value="dropdown">
                                      {t("settings.customfields_type_dropdown")}
                                    </option>
                                  </Form.Control>
                                  <InputGroup.Prepend
                                    className={
                                      (customiseItem == "2-" + key
                                        ? "active"
                                        : "") +
                                      " clickable " +
                                      (item["type"] == "dropdown"
                                        ? ""
                                        : "disabled")
                                    }
                                    onClick={() =>
                                      item["type"] == "dropdown"
                                        ? handleCustomfieldsUserCustomise(
                                            "2-" + key
                                          )
                                        : ""
                                    }
                                  >
                                    <InputGroup.Text>
                                      {t("settings.customfields_customise_btn")}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <InputGroup.Prepend
                                    className={
                                      "clickable delete " +
                                      (!props.userHasEditRights
                                        ? "disabled"
                                        : "")
                                    }
                                    onClick={() => {
                                      console.log(props.userHasEditRights);
                                      if (props.userHasEditRights) {
                                        if (
                                          window.confirm(
                                            t("settings.confirm_delete_text")
                                          )
                                        )
                                          handleCustomfieldsUserDelete(key);
                                      }
                                    }}
                                  >
                                    <InputGroup.Text>
                                      {t("settings.customfields_delete_btn")}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                </InputGroup>
                              </div>
                            </div>
                            <div
                              className={
                                "customfieldcustomiser " +
                                (customiseItem == "2-" + key ? "" : "hidden")
                              }
                            >
                              {item["type"] == "text" ? (
                                <div>
                                  <p>test Customiser Text</p>
                                </div>
                              ) : (
                                ""
                              )}
                              {item["type"] == "dropdown" ? (
                                <div>
                                  {item["options"] != undefined
                                    ? item["options"].map(
                                        (option, optionkey) => {
                                          return (
                                            <div
                                              key={optionkey}
                                              className="row"
                                            >
                                              <div className="inputField flex-wrap">
                                                <Field
                                                  disabled={
                                                    !props.userHasEditRights
                                                  }
                                                  type="textlanguage"
                                                  value={
                                                    settings[
                                                      "customfieldsUser"
                                                    ].find(
                                                      (field) =>
                                                        field["key"] ==
                                                        item["key"]
                                                    )["options"][optionkey]
                                                  }
                                                  onChange={(value) =>
                                                    setSettingsCustomfieldsUserDropdownOption(
                                                      value,
                                                      key,
                                                      optionkey
                                                    )
                                                  }
                                                  options={{
                                                    category:
                                                      "settings.customfields.customfieldsUser",
                                                  }}
                                                ></Field>
                                              </div>
                                              <div>
                                                <InputGroup>
                                                  <InputGroup.Prepend
                                                    className={
                                                      "clickable delete " +
                                                      (!props.userHasEditRights
                                                        ? "disabled"
                                                        : "")
                                                    }
                                                    onClick={() => {
                                                      if (
                                                        props.userHasEditRights
                                                      ) {
                                                        if (
                                                          window.confirm(
                                                            t(
                                                              "settings.confirm_delete_text"
                                                            )
                                                          )
                                                        )
                                                          setSettingsCustomfieldsUserDropdownOptionDelete(
                                                            key,
                                                            optionkey
                                                          );
                                                      }
                                                    }}
                                                  >
                                                    <InputGroup.Text>
                                                      {t(
                                                        "settings.customfields_delete_btn"
                                                      )}
                                                    </InputGroup.Text>
                                                  </InputGroup.Prepend>
                                                </InputGroup>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                    : ""}
                                  <Button
                                    disabled={!props.userHasEditRights}
                                    className="customfieldsUserDropdownOptionAddBtn"
                                    onClick={() =>
                                      handleCustomfieldsUserDropdownOptionAdd(
                                        key
                                      )
                                    }
                                  >
                                    {t(
                                      "settings.customfields_dropdownoption_add_btn"
                                    )}
                                  </Button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      })
                    : ""}
                  <Button
                    disabled={!props.userHasEditRights}
                    className="customfieldsUserAddBtn"
                    onClick={handleCustomfieldsUserAdd}
                  >
                    {t("settings.customfields_add_btn")}
                  </Button>
                </div>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* <Button disabled={!props.userHasEditRights} className="savesettingsBtn savebtn" style={{ marginBottom: "20px" }} onClick={handleSaveSettingsCustomfields}>{t("settings.save")}</Button> */}
    </div>
  );
}
export default SettingsCustomFields;
