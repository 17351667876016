import * as React from "react";
import {
  Card,
  Button,
  InputGroup,
  Form,
  Accordion,
  Modal,
} from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Settings.scss";
import _ from "lodash";
import "./SettingsGeneral.scss";
import Field from "../../Shared/Wizard/Field/Field";
import { generateUrl } from "../../../config";

function SettingsTypeNCategory(props) {
  const t = useTranslate();
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const [settings, setSettings] = useState(props.settingProps);
  const [occurenceTypes, setOccurenceTypes] = useState(props.occurenceTypes);
  const [consequenceTypes, setConsequenceTypes] = useState(
    props.consequenceTypes
  );
  const [deleteOccurenceTypes, setDeleteOccurenceTypes] = useState<Array<any>>(
    []
  );
  const [deleteConsequenceTypes, setDeleteConsequenceTypes] = useState<
    Array<any>
  >([]);

  // type of system
  const setSettingsSystemType = (value, key) => {
    let systemType = settings["systemType"];

    if (systemType != undefined) {
      (systemType[key] as Object) = value;
    }
    setSettings((settings) => ({ ...settings, systemType: systemType }));
  };

  function handleSystemTypeAdd() {
    let systemType = settings["systemType"];

    if (systemType != undefined) {
      systemType.push("");
    }
    setSettings((settings) => ({ ...settings, systemType: systemType }));
  }

  function handleSystemTypeDelete(removeId) {
    let systemType = settings["systemType"];

    if (systemType != undefined) {
      systemType.splice(removeId, 1);
    }
    setSettings((settings) => ({ ...settings, systemType: systemType }));
  }

  // type of systemvalue
  const setSettingsValueType = (value, key) => {
    let valueType = settings["valueType"];

    if (valueType != undefined) {
      (valueType[key] as Object) = value;
    }
    setSettings((settings) => ({ ...settings, valueType: valueType }));
  };

  function handleValueTypeAdd() {
    let valueType = settings["valueType"];

    if (valueType != undefined) {
      valueType.push("");
    }
    setSettings((settings) => ({ ...settings, valueType: valueType }));
  }

  function handleValueTypeDelete(removeId) {
    let valueType = settings["valueType"];

    if (valueType != undefined) {
      valueType.splice(removeId, 1);
    }
    setSettings((settings) => ({ ...settings, valueType: valueType }));
  }

  // Category of causes
  const setSettingsCauseCategory = (value, key) => {
    let causeCategory = settings["causeCategory"];

    if (causeCategory != undefined) {
      (causeCategory[key] as Object) = value;
    }
    setSettings((settings) => ({ ...settings, causeCategory: causeCategory }));
    // handleSaveSettingsType({ ...settings, causeCategory: causeCategory });
  };

  function handleCauseCategoryAdd() {
    let causeCategory = settings["causeCategory"];

    if (causeCategory != undefined) {
      causeCategory.push("");
    }
    setSettings((settings) => ({ ...settings, causeCategory: causeCategory }));
    // handleSaveSettingsType({ ...settings, causeCategory: causeCategory });
  }

  function handleCauseCategoryDelete(removeId) {
    let causeCategory = settings["causeCategory"];

    if (causeCategory != undefined) {
      causeCategory.splice(removeId, 1);
    }
    setSettings((settings) => ({ ...settings, causeCategory: causeCategory }));
    handleSaveSettingsType();
  }

  // Category of consequence
  const setSettingsConsequenceCategory = (value, key) => {
    let conseqCategory = settings["conseqCategory"];

    if (conseqCategory != undefined) {
      (conseqCategory[key] as Object) = value;
    }
    setSettings((settings) => ({
      ...settings,
      conseqCategory: conseqCategory,
    }));
  };

  function handleConsequenceCategoryAdd() {
    let conseqCategory = settings["conseqCategory"];

    if (conseqCategory != undefined) {
      conseqCategory.push("");
    }
    setSettings((settings) => ({
      ...settings,
      conseqCategory: conseqCategory,
    }));
  }

  function handleConsequenceCategoryDelete(removeId) {
    let conseqCategory = settings["conseqCategory"];

    if (conseqCategory != undefined) {
      conseqCategory.splice(removeId, 1);
    }
    setSettings((settings) => ({
      ...settings,
      conseqCategory: conseqCategory,
    }));
  }

  // threats
  const setSettingsThreats = (value, key) => {
    let threats = settings["threats"];

    if (threats != undefined) {
      (threats[key] as Object) = value;
    }
    setSettings((settings) => ({ ...settings, threats: threats }));
  };

  function handleThreatsAdd() {
    let threats = settings["threats"];

    if (threats != undefined) {
      threats.push("");
    }
    setSettings((settings) => ({ ...settings, threats: threats }));
  }

  function handleThreatsDelete(removeId) {
    let threats = settings["threats"];

    if (threats != undefined) {
      threats.splice(removeId, 1);
    }
    setSettings((settings) => ({ ...settings, threats: threats }));
    handleSaveSettingsType();
  }

  //Occurence types
  const setEditOccurenceTypeNames = (value, key) => {
    let types = occurenceTypes;

    if (types != undefined) {
      types[key].name = value;
    }
    setOccurenceTypes((types) => [...types]);
  };

  const handleRemoveOccurenceType = (key) => {
    let types = occurenceTypes;
    let tempDeleteOccurenceTypes = deleteOccurenceTypes;

    tempDeleteOccurenceTypes.push(types[key]);
    types.splice(key, 1);

    setDeleteOccurenceTypes(tempDeleteOccurenceTypes);
    setOccurenceTypes((types) => [...types]);
    handleSaveSettingsType();
  };

  const handleDeleteOccurenceTypes = () => {
    axios
      .delete(
        generateUrl("/api/cra/occurenceTypes/"),
        {
          deleteOccurenceTypes: deleteOccurenceTypes,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (res) {});
  };

  function saveOccurenceTypes() {
    axios
      .put(
        generateUrl("/api/cra/occurenceTypes/"),
        {
          occurenceTypes: occurenceTypes,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (res) {});
  }

  //Consequence types

  const setEditConsequenceTypeNames = (value, key) => {
    let types = consequenceTypes;

    if (types != undefined) {
      types[key].name = value;
    }
    setConsequenceTypes((types) => [...types]);
  };

  const handleRemoveConsequenceType = (key) => {
    let types = consequenceTypes;
    let tempDeleteConsequenceTypes = deleteConsequenceTypes;

    tempDeleteConsequenceTypes.push(types[key]);
    types.splice(key, 1);
    setDeleteConsequenceTypes(tempDeleteConsequenceTypes);
    setConsequenceTypes((types) => [...types]);
    handleSaveSettingsType();
  };

  const handleDeleteConsequenceTypes = () => {
    axios
      .delete(
        generateUrl("/api/cra/consequenceTypes/"),
        {
          deleteConsequenceTypes: deleteConsequenceTypes,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (res) {});
  };

  function saveConsequenceTypes() {
    axios
      .put(
        generateUrl("/api/cra/consequenceTypes/"),
        {
          consequenceTypes: consequenceTypes,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (res) {});
  }

  //Save

  function handleSaveSettingsType(payload?: any) {
    if (!payload) {
      payload = settings;
    }
    let systemType = settings["systemType"];
    let valueType = settings["valueType"];
    let causeCategory = settings["causeCategory"];
    let conseqCategory = settings["conseqCategory"];
    let threats = settings["threats"];

    const uniqSystemType = _.uniq(systemType).filter((entry) => {
      return entry.trim() != "";
    });
    const uniqValueType = _.uniq(valueType).filter((entry) => {
      return entry.trim() != "";
    });
    const uniqCauseCategory = _.uniq(causeCategory).filter((entry) => {
      return entry.trim() != "";
    });
    const uniqConseqCategory = _.uniq(conseqCategory).filter((entry) => {
      return entry.trim() != "";
    });
    const uniqThreats = _.uniq(threats).filter((entry) => {
      return entry.trim() != "";
    });

    saveOccurenceTypes();
    saveConsequenceTypes();

    if (deleteOccurenceTypes.length > 0) {
      handleDeleteOccurenceTypes();
    }
    if (deleteConsequenceTypes.length > 0) {
      handleDeleteConsequenceTypes();
    }

    axios
      .put(
        generateUrl("/api/cra/settings/") + settings["_id"],
        {
          org: settings["org"],

          systemType: uniqSystemType,
          valueType: uniqValueType,
          causeCategory: uniqCauseCategory,
          conseqCategory: uniqConseqCategory,
          threats: uniqThreats,

          apiPath: settings["apiPath"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        if (response.status == 200) {
          notifySuccess(t("settings.toast_savesuccess"));
        }
        if (props.onSettingsGeneralChanged != undefined) {
          props.onSettingsGeneralChanged();
        }
      });
  }

  useEffect(() => {
    setSettings(props.settingProps);
    setOccurenceTypes(props.occurenceTypes);
    setConsequenceTypes(props.consequenceTypes);
  }, [props]);

  return (
    <div className="type_definition">
      <Card>
        <Accordion.Toggle className="clickable" as={Card.Header} eventKey="8">
          <h4 className="header-title">{t("settings.category_cause")}</h4>
          <p>{t("settings.category_cause_info")}</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="8">
          <Card.Body>
            <div className="settingsarea">
              {props.settingsInherited != undefined &&
              props.settingsInherited["causeCategory"] != undefined
                ? props.settingsInherited["causeCategory"].map((item, key) => {
                    return (
                      <div className="row">
                        <div className="col-md-9 inputField">
                          <Field
                            title={t("settings.category_cause_single")}
                            type="textlanguage"
                            value={item}
                            onChange={(value) =>
                              setSettingsCauseCategory(value, key)
                            }
                            options={{
                              category: "settings.typencategory.causecategory",
                            }}
                            disabled={true}
                            onSave={() => handleSaveSettingsType()}
                          ></Field>
                        </div>
                      </div>
                    );
                  })
                : ""}
              {settings["causeCategory"] != undefined
                ? settings["causeCategory"].map((item, key) => {
                    return (
                      <div key={key} className="row">
                        <div className="col-md-9 inputField">
                          <Field
                            disabled={!props.userHasEditRights}
                            title={t("settings.category_cause_single")}
                            type="textlanguage"
                            value={item}
                            onChange={(value) =>
                              setSettingsCauseCategory(value, key)
                            }
                            options={{
                              category: "settings.typencategory.causecategory",
                            }}
                            onSave={() => handleSaveSettingsType()}
                          ></Field>
                        </div>
                        <div className="col-md-3 inputField">
                          <InputGroup className="mb-3 customfielditem">
                            <InputGroup.Prepend
                              className={
                                "clickable delete " +
                                (!props.userHasEditRights ? "disabled" : "")
                              }
                              onClick={() => {
                                if (props.userHasEditRights) {
                                  handleCauseCategoryDelete(key);
                                }
                              }}
                            >
                              <InputGroup.Text>
                                {t("settings.customfields_delete_btn")}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                          </InputGroup>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
            <div>
              <Button
                disabled={!props.userHasEditRights}
                onClick={handleCauseCategoryAdd}
              >
                {t("settings.customfields_dropdownoption_add_btn")}
              </Button>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle className="clickable" as={Card.Header} eventKey="9">
          <h4 className="header-title">{t("settings.category_cosequence")}</h4>
          <p>{t("settings.category_consequence_info")}</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="9">
          <Card.Body>
            <div className="requestedEventTypeRows">
              {consequenceTypes.map((type, key) => (
                <div key={key} className="row requestedEventTypeRow">
                  <div className="col-md-9 inputField">
                    <Field
                      title={t("settings.consequence_types.name")}
                      type="textlanguage"
                      value={type["name"]}
                      onChange={(value) =>
                        setEditConsequenceTypeNames(value, key)
                      }
                      options={{
                        category: "settings.typencategory.consequence",
                      }}
                      disabled={
                        !type.accesses || !type.accesses.includes("edit")
                      }
                      onSave={() => handleSaveSettingsType()}
                      //disabled={!props.userHasEditRights || (type.creatorOrg == undefined && auth.user["userdata"]["superadmin"] != true) || props.consequenceTypesOrgParents.includes(type.creatorOrg) }
                    ></Field>
                    <InputGroup className="mb-3 customfielditem" key={key}>
                      <InputGroup.Prepend
                        className={
                          "clickable delete " +
                          (props.consequenceTypesConnectedConsequences
                            .map((i) => i.category)
                            .includes(type["_id"]) ||
                          !type.accesses.includes("edit")
                            ? "disabled"
                            : "")
                        }
                        onClick={() =>
                          !props.consequenceTypesConnectedConsequences
                            .map((i) => i.category)
                            .includes(type["_id"]) &&
                          type.accesses.includes("edit")
                            ? handleRemoveConsequenceType(key)
                            : null
                        }
                      >
                        {!props.consequenceTypesConnectedConsequences
                          .map((i) => i.category)
                          .includes(type["_id"]) ? (
                          <InputGroup.Text>
                            {t("settings.customfields_delete_btn")}
                          </InputGroup.Text>
                        ) : (
                          <Tooltip
                            title={t(
                              "settings.consequence_types.connected_consequences"
                            )}
                            placement="right"
                          >
                            <InputGroup.Text>
                              {t("settings.customfields_delete_btn")}
                            </InputGroup.Text>
                          </Tooltip>
                        )}
                      </InputGroup.Prepend>
                    </InputGroup>
                  </div>
                  <div className="col-md-3">
                    {props.org != undefined &&
                    props.org.find((org) => org._id == type.creatorOrg) ? (
                      <p>
                        {" "}
                        {t("settings.consequence_types.creator") +
                          " " +
                          props.org.find((org) => org._id == type.creatorOrg)
                            .name}{" "}
                      </p>
                    ) : type.creatorOrg == undefined ? (
                      <p>{t("settings.consequence_types.default_type")}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle className="clickable" as={Card.Header} eventKey="10">
          <h4 className="header-title">{t("settings.threats_name")}</h4>
          <p>{t("settings.threats_information")}</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="10">
          <Card.Body>
            <div className="settingsarea">
              <div>
                {props.settingsInherited != undefined &&
                props.settingsInherited["threats"] != undefined
                  ? props.settingsInherited["threats"].map((item, key) => {
                      return (
                        <div className="row">
                          <div className="col-md-9 inputField">
                            <Field
                              title={t("settings.threats_name_single")}
                              type="textlanguage"
                              value={item}
                              onChange={(value) =>
                                setSettingsThreats(value, key)
                              }
                              options={{
                                category: "settings.typencategory.threats",
                              }}
                              disabled={true}
                              onSave={() => handleSaveSettingsType()}
                            ></Field>
                          </div>
                        </div>
                      );
                    })
                  : ""}
                {settings["threats"] != undefined
                  ? settings["threats"].map((item, key) => {
                      return (
                        <div key={key} className="row">
                          <div className="col-md-9 inputField">
                            <Field
                              disabled={!props.userHasEditRights}
                              title={t("settings.threats_name_single")}
                              type="textlanguage"
                              value={item}
                              onChange={(value) =>
                                setSettingsThreats(value, key)
                              }
                              options={{
                                category: "settings.typencategory.threats",
                              }}
                              onSave={() => handleSaveSettingsType()}
                            ></Field>
                          </div>
                          <div className="col-md-3 inputField">
                            <InputGroup className="mb-3 customfielditem">
                              <InputGroup.Prepend
                                className={
                                  "clickable delete " +
                                  (!props.userHasEditRights ? "disabled" : "")
                                }
                                onClick={() =>
                                  props.userHasEditRights
                                    ? handleThreatsDelete(key)
                                    : null
                                }
                              >
                                <InputGroup.Text>
                                  {t("settings.customfields_delete_btn")}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                            </InputGroup>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
            <div>
              <Button
                disabled={!props.userHasEditRights}
                onClick={handleThreatsAdd}
              >
                {t("settings.customfields_dropdownoption_add_btn")}
              </Button>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle className="clickable" as={Card.Header} eventKey="11">
          <h4 className="header-title">
            {t("settings.occurence_types.title")}
          </h4>
          <p>{t("settings.occurence_types.info_top")}</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="11">
          <Card.Body>
            <div className="requestedEventTypeRows">
              {occurenceTypes.map((type, key) => (
                <div key={key} className="row requestedEventTypeRow">
                  <div className="col-md-9 inputField">
                    <Field
                      title={t("settings.occurence_types.name")}
                      type="textlanguage"
                      value={type["name"]}
                      onChange={(value) =>
                        setEditOccurenceTypeNames(value, key)
                      }
                      options={{
                        category: "settings.typencategory.occurence_types",
                      }}
                      disabled={
                        !type.accesses || !type.accesses.includes("edit")
                      }
                      onSave={() => handleSaveSettingsType()}
                      //disabled={ (type.creatorOrg == undefined && auth.user["userdata"]["superadmin"] != true) || props.orgParents.includes(type.creatorOrg) }
                    ></Field>
                    <InputGroup className="mb-3 customfielditem" key={key}>
                      {/* <InputGroup.Prepend>
                                                <InputGroup.Text>{t("settings.occurence_types.name")}</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control disabled={ (type.creatorOrg == undefined && auth.user["userdata"]["superadmin"] != true) || props.orgParents.includes(type.creatorOrg) } onChange={evt => setEditOccurenceTypeNames(evt.target.value, key)} value={type["name"]}  /> */}
                      <InputGroup.Prepend
                        className={
                          "clickable delete " +
                          (props.occurenceTypesConnectedOccurences
                            .map((i) => i.type)
                            .includes(type["_id"]) ||
                          !type.accesses.includes("edit")
                            ? "disabled"
                            : "")
                        }
                        onClick={() =>
                          !props.occurenceTypesConnectedOccurences
                            .map((i) => i.type)
                            .includes(type["_id"]) &&
                          type.accesses.includes("edit")
                            ? handleRemoveOccurenceType(key)
                            : null
                        }
                      >
                        {!props.occurenceTypesConnectedOccurences
                          .map((i) => i.type)
                          .includes(type["_id"]) ? (
                          <InputGroup.Text>
                            {t("settings.customfields_delete_btn")}
                          </InputGroup.Text>
                        ) : (
                          <Tooltip
                            title={t(
                              "settings.occurence_types.connected_occurences"
                            )}
                            placement="right"
                          >
                            <InputGroup.Text>
                              {t("settings.customfields_delete_btn")}
                            </InputGroup.Text>
                          </Tooltip>
                        )}
                      </InputGroup.Prepend>
                    </InputGroup>
                  </div>
                  <div className="col-md-3">
                    {props.org != undefined &&
                    props.org.find((org) => org._id == type.creatorOrg) ? (
                      <p>
                        {" "}
                        {t("settings.occurence_types.creator") +
                          " " +
                          props.org.find((org) => org._id == type.creatorOrg)
                            .name}{" "}
                      </p>
                    ) : type.creatorOrg == undefined ? (
                      <p>{t("settings.occurence_types.default_type")}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* <Button disabled={!props.userHasEditRights} className="savesettingsBtn savebtn" onClick={handleSaveSettingsType}>{t("settings.save")}</Button> */}
    </div>
  );
}

export default SettingsTypeNCategory;
