import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import { useTranslate } from "react-polyglot";

import { useAuth } from "../../../../../../Auth";
import { useAxios } from "../../../../../../AxiosHandler";

import TreatmentEditorModal from "../../../../../Shared/Modals/Edit/TreatmentEditorModal";
import TreatmentSelectorModal from "../../../../../Shared/Modals/TreatmentSelectorModal/TreatmentSelectorModal";
import TreatmentDeleteModal from "../../../../../Shared/Modals/Delete/TreatmentDeleteModal";
import CreateTreatmentModal from "../../../../../Shared/Modals/Create/CreateTreatmentModal";

import { generateUrl } from "../../../../../../config";

import "./BowtieTreatmentHandler.scss";

function BowtieTreatmentHandler(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();
  let { systemid } = useParams();

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  const [users, setUsers] = useState([]);

  const [showLoadingTreatments, setShowLoadingTreatments] = useState(false);
  const [treatments, setTreatments] = useState([]);
  const [deleteTreatmentId, setDeleteTreatmentId] = useState();
  const [editTreatmentId, setEditTreatmentId] = useState();

  const [showNewTreatmentModal, setShowNewTreatmentModal] = useState(false);
  const [showEditTreatmentModal, setShowEditTreatmentModal] = useState(false);
  const [
    showSelectExistingTreatmentModal,
    setShowSelectExistingTreatmentModal,
  ] = useState(false);
  const [showCopyTreatmentModal, setShowCopyTreatmentModal] = useState(false);
  const [showDeleteTreatmentModal, setShowDeleteTreatmentModal] = useState(
    false
  );

  const [refetchTreatments, setRefetchTreatments] = useState(0);

  const handleCloseNewTreatmentModal = () => {
    setShowNewTreatmentModal(false);
  };

  const handleShowNewTreatmentModal = () => setShowNewTreatmentModal(true);
  const handleShowDeleteTreatmentModal = (evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    setDeleteTreatmentId(evt.target.getAttribute("data-treatmentid"));
    setShowDeleteTreatmentModal(true);
  };
  const handleShowEditTreatmentModal = (evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    setEditTreatmentId(evt.target.getAttribute("data-treatmentid"));
    setShowEditTreatmentModal(true);
  };

  async function fetchUsers() {
    await axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/users"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setUsers(res[0].data);
        })
      );
  }

  function fetchTreatmentsByCause(cause) {
    setTreatments([]);
    setShowLoadingTreatments(true);
    axios
      .single({
        method: "get",
        url: generateUrl("/api/cra/treatments/bycause/") + cause,
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        setTreatments(res.data);
        setShowLoadingTreatments(false);
      });
  }

  function fetchTreatmentsByConsequence(consequence) {
    setTreatments([]);
    setShowLoadingTreatments(true);
    axios
      .single({
        method: "get",
        url: generateUrl("/api/cra/treatments/byconsequence/") + consequence,
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        setTreatments(res.data);
        setShowLoadingTreatments(false);
      });
  }

  useEffect(() => {
    fetchUsers();
    if (props.treatmentType == "cause") {
      fetchTreatmentsByCause(props.causeId);
    } else if (props.treatmentType == "consequence") {
      fetchTreatmentsByConsequence(props.consequenceId);
    }
  }, []);

  useEffect(() => {
    if (props.treatmentType == "cause") {
      fetchTreatmentsByCause(props.causeId);
    } else if (props.treatmentType == "consequence") {
      fetchTreatmentsByConsequence(props.consequenceId);
    }
    if (props.onTreatmentsChanged != undefined) {
      props.onTreatmentsChanged();
    }
  }, [refetchTreatments]);

  return (
    <div className="bowtietreatmentlist">
      <h4>{t("bowtie.causes_measure_title")}</h4>
      <Button
        className="newTreatmentBtn"
        onClick={handleShowNewTreatmentModal}
        disabled={
          !props.system["accesses"].includes("edit") ||
          auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
            (rule) =>
              rule.objType == "treatment" && rule.objSubType == "treatment"
          ).actions.create != "allow"
        }
      >
        {t("bowtie.measure_new")}
      </Button>
      <br />
      <Button
        className="existingTreatmentBtn"
        onClick={() => setShowSelectExistingTreatmentModal(true)}
        disabled={!props.system["accesses"].includes("edit")}
      >
        {t("bowtie.treatments.select_existing_btn")}
      </Button>
      <Button
        className="existingTreatmentBtn"
        onClick={() => setShowCopyTreatmentModal(true)}
        disabled={
          !props.system["accesses"].includes("edit") ||
          auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
            (rule) =>
              rule.objType == "treatment" && rule.objSubType == "treatment"
          ).actions.create != "allow"
        }
      >
        {t("bowtie.treatments.copy_btn")}
      </Button>
      <Table striped>
        <thead>
          <tr>
            <th>{t("bowtie.name")}</th>
            <th>{t("bowtie.measure_responsible")}</th>
            <th>{t("bowtie.causes_measure_probability_reduction")}</th>
            <th>{t("bowtie.status")}</th>
            <th>{t("bowtie.causes_measure_onetimecost")}</th>
            <th>{t("bowtie.causes_measure_cost")}</th>
            <th>{t("bowtie.measure_operation")}</th>
          </tr>
        </thead>
        <tbody>
          {treatments.map((item, key) => {
            return (
              <tr
                key={key}
                data-treatmentid={item["_id"]}
                onClick={handleShowEditTreatmentModal}
              >
                <td data-treatmentid={item["_id"]}>
                  <i
                    data-treatmentid={item["_id"]}
                    className="dripicons-document"
                  />
                  {item["name"]}
                </td>
                <td data-treatmentid={item["_id"]}>
                  {users.filter((user) => user["_id"] == item["responsible"])
                    .length > 0
                    ? users.filter(
                        (user) => user["_id"] == item["responsible"]
                      )[0]["name"]
                    : item["responsible"]}
                </td>
                <td data-treatmentid={item["_id"]}>
                  {item["probabilityreduction"] == 0 ? "Ingen" : ""}
                  {item["probabilityreduction"] > 0 &&
                  item["probabilityreduction"] < 35
                    ? t("treatment.label_low")
                    : ""}
                  {item["probabilityreduction"] > 34 &&
                  item["probabilityreduction"] < 70
                    ? t("treatment.label_middel")
                    : ""}
                  {item["probabilityreduction"] > 69 &&
                  item["probabilityreduction"] < 90
                    ? t("treatment.label_high")
                    : ""}
                  {item["probabilityreduction"] > 90
                    ? t("treatment.label_extremely_high")
                    : ""}
                </td>
                <td data-treatmentid={item["_id"]}>
                  {item["status"] == "open" ? t("treatment.open") : ""}
                  {item["status"] == "suggested"
                    ? t("treatment.suggested")
                    : ""}
                  {item["status"] == "planned" ? t("treatment.planned") : ""}
                  {item["status"] == "processing" ? t("treatment.ongoing") : ""}
                  {item["status"] == "closed" ? t("treatment.closed") : ""}
                </td>
                <td data-treatmentid={item["_id"]}>
                  {item["onetimecost"] != undefined && item["onetimecost"] != ""
                    ? settings["currencySymbolBefore"] +
                      item["onetimecost"] +
                      settings["currencySymbolAfter"]
                    : ""}
                </td>
                <td data-treatmentid={item["_id"]}>
                  {item["cost"] != undefined && item["cost"] != ""
                    ? settings["currencySymbolBefore"] +
                      item["cost"] +
                      settings["currencySymbolAfter"]
                    : ""}
                </td>
                <td data-treatmentid={item["_id"]}>
                  <i
                    data-treatmentid={item["_id"]}
                    className="dripicons-trash tableOperationBtn"
                    onClick={(evt) =>
                      !props.system["accesses"].includes("edit")
                        ? null
                        : handleShowDeleteTreatmentModal(evt)
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {showLoadingTreatments ? (
        <h5 className="loading-text">{t("bowtie.measure_load")} ..</h5>
      ) : (
        ""
      )}
      <CreateTreatmentModal
        show={showNewTreatmentModal}
        onHide={() => {
          handleCloseNewTreatmentModal();
        }}
        causeId={props.causeId}
        consequenceId={props.consequenceId}
        occurenceId={props.occurenceId}
        treatmentType={props.treatmentType}
        onSave={() => {
          if (props.treatmentType == "cause" && props.causeId != undefined) {
            fetchTreatmentsByCause(props.causeId);
          } else if (
            props.treatmentType == "consequence" &&
            props.consequenceId != undefined
          ) {
            fetchTreatmentsByConsequence(props.consequenceId);
          }
          /* if(props.causeId != undefined) fetchTreatmentsByCause(props.causeId);
                    if(props.consequenceId != undefined) fetchTreatmentsByConsequence(props.consequenceId); */
        }}
      ></CreateTreatmentModal>
      <TreatmentEditorModal
        systemid={systemid}
        show={showEditTreatmentModal}
        onSave={() => {
          setShowEditTreatmentModal(false);
          setRefetchTreatments(refetchTreatments + 1);
        }}
        onHide={() => {
          setShowEditTreatmentModal(false);
          setEditTreatmentId(undefined);
        }}
        onCancel={() => {
          setShowEditTreatmentModal(false);
          setEditTreatmentId(undefined);
        }}
        treatmentId={editTreatmentId}
      ></TreatmentEditorModal>
      <TreatmentSelectorModal
        show={showSelectExistingTreatmentModal}
        size="lg"
        parentItemType={props.treatmentType}
        parentItemId={
          props.treatmentType == "cause" ? props.causeId : props.consequenceId
        }
        onChange={() => {
          setShowSelectExistingTreatmentModal(false);
          setRefetchTreatments(refetchTreatments + 1);
        }}
        onHide={() => setShowSelectExistingTreatmentModal(false)}
        onCancel={() => setShowSelectExistingTreatmentModal(false)}
      ></TreatmentSelectorModal>
      <TreatmentSelectorModal
        show={showCopyTreatmentModal}
        size="lg"
        systemid={systemid}
        parentItemType={props.treatmentType}
        parentItemId={
          props.treatmentType == "cause" ? props.causeId : props.consequenceId
        }
        occurenceId={props.occurenceId}
        onChange={() => {
          setShowCopyTreatmentModal(false);
          setRefetchTreatments(refetchTreatments + 1);
        }}
        onHide={() => setShowCopyTreatmentModal(false)}
        onCancel={() => setShowCopyTreatmentModal(false)}
        copy={true}
      ></TreatmentSelectorModal>
      <TreatmentDeleteModal
        fromBowtie={true}
        show={showDeleteTreatmentModal}
        treatmentId={deleteTreatmentId}
        parentItemType={props.treatmentType}
        parentItemId={
          props.treatmentType == "cause" ? props.causeId : props.consequenceId
        }
        onChange={() => {
          setShowDeleteTreatmentModal(false);
          setRefetchTreatments(refetchTreatments + 1);
        }}
        onHide={() => setShowDeleteTreatmentModal(false)}
        onCancel={() => setShowDeleteTreatmentModal(false)}
      ></TreatmentDeleteModal>
    </div>
  );
}

export default BowtieTreatmentHandler;
