import * as React from "react";

import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import { useAuth } from "../../../../Auth";
import { useAxios } from "../../../../AxiosHandler";

import Wizard from "../../Wizard/Wizard";
import WizardFieldManipulator from "../../WizardHelpers/WizardFieldManipulator";

import { generateUrl } from "../../../../config";

function OtherRegTemplate(props) {
  const t = useTranslate();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const auth = useAuth();

  const history = useHistory();

  const GetWizardFieldByReference =
    WizardFieldManipulator.GetWizardFieldByReference;
  const GetWizardValuesByReferenceList =
    WizardFieldManipulator.GetWizardValuesByReferenceList;

  const [autocompleteValue, setAutocompleteValue] = useState("");
  const [autocompleteFieldkey, setAutocompleteFieldkey] = useState("");

  const [template, setTemplate] = useState<any>([]);

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  async function fetchUsers() {
    await axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/users/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/orgElements/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systems/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setTemplate([
            {
              fields: [
                {
                  type: "text_title",
                  title: t("diri.regassistant.types.other.title"),
                },
                {
                  type: "dateselector",
                  title: t("diri.regassistant.types.other.startdate"),
                  reference: "startdate",
                },
                {
                  type: "input",
                  title: t("diri.regassistant.types.other.systemname") + " *",
                  reference: "name",
                },
                {
                  type: "text_normal",
                  title: t(
                    "diri.regassistant.types.other.ledetekst_systemname"
                  ),
                },
                {
                  id: 3,
                  title: t("diri.regassistant.types.other.system_responsible"),
                  value: "",
                  type: "autocompletewithid",
                  reference: "responsibleperson",
                  options: {
                    list: res[0].data.map((i, key) => {
                      return { id: key, value: i["name"], label: i["name"] };
                    }),
                    data: res[0].data,
                    minlength: 2,
                    noresults: "no matches",
                  },
                },
                {
                  type: "text_normal",
                  title: t(
                    "diri.regassistant.types.other.ledetekst_responsible"
                  ),
                },
                {
                  type: "textarea",
                  title: t("diri.regassistant.types.other.system_description"),
                  reference: "description",
                },
                {
                  type: "text_normal",
                  title: t(
                    "diri.regassistant.types.other.ledetekst_description"
                  ),
                },
                {
                  type: "textarea",
                  title: t("diri.regassistant.types.other.delimitations"),
                  reference: "limitationsdescription",
                },
                {
                  type: "text_normal",
                  title: t(
                    "diri.regassistant.types.other.ledetekst_delimitations"
                  ),
                },
                {
                  type: "selectmulti",
                  title: t(
                    "diri.regassistant.types.other.implicatedriskassessments"
                  ),
                  reference: "implicatedriskassessments",
                  options: {
                    choices: res[2].data.map((i) => {
                      return { value: i["_id"], label: i["nameReadable"] };
                    }),
                  },
                },
              ],
            },
            {
              fields: [
                {
                  type: "text_title",
                  title: t("diri.regassistant.types.other.system_participants"),
                  options: {
                    headertag: "h2",
                  },
                },
                {
                  title: t("wizard_title.input.riskassessmentparticipants"),
                  value: [],
                  type: "personselector",
                  reference: "riskassessmentparticipants",
                  options: {
                    users: res[0].data,
                    organizations: res[1].data,
                    minlength: 2,
                    noresults: t(
                      "wizard_title.input.riskassessmentparticipants_noresult"
                    ),
                    roleTitle: t(
                      "wizard_title.input.riskassessmentparticipants_role"
                    ),
                    organizationTitle: t(
                      "wizard_title.input.riskassessmentparticipants_organization"
                    ),

                    deleteButtonText: t(
                      "wizard_title.input.riskassessmentparticipants_deletebtn"
                    ),
                    addButtonText: t(
                      "wizard_title.input.riskassessmentparticipants_addbtn"
                    ),
                  },
                },
              ],
            },
          ]);
        })
      );
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleIdConnection = (fieldkey, valueID) => {
    setAutocompleteFieldkey(fieldkey);
    setAutocompleteValue(valueID);
  };

  const handleWizardComplete = (data) => {
    let values = GetWizardValuesByReferenceList(data, [
      "startdate",
      "name",
      "responsibleperson",
      "description",
      "limitationsdescription",
      "riskassessmentparticipants",
      "implicatedriskassessments",
    ]);

    if (values.name == undefined || values.name == "") {
      notifyWarning(
        t("diri.regassistant.types.other.toast.warning_missing_system_name")
      );
      return null;
    }

    values[autocompleteFieldkey] = autocompleteValue;

    axios
      .post(
        generateUrl("/api/cra/systems/"),
        {
          name: values.name,
          org: auth.user["userdata"]["unit"][0],
          systemType: ["other"],
          data: values,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        if (props.regCompleted != undefined) props.regCompleted(response.data);
        history.push("/system/" + response.data._id);
      });
  };

  return template.length > 0 ? (
    <Wizard
      handleIdConnection={(fieldkey, valueID) =>
        handleIdConnection(fieldkey, valueID)
      }
      data={JSON.parse(JSON.stringify(template))}
      onComplete={(data) => {
        handleWizardComplete(data);
      }}
      setPage={(e) => {
        props.setPage(e);
      }}
    ></Wizard>
  ) : null;
}

export default OtherRegTemplate;
