import * as React from "react";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./Systems.scss";
import { Card, Button, Modal, InputGroup, FormGroup } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { useState, useEffect } from "react";
import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";
import { Grid, Tooltip, Switch } from "@material-ui/core";
import { Typeahead } from "react-bootstrap-typeahead";

import Checkbox from "../../Shared/Wizard/Field/Fieldtypes/Checkbox";
import SiteAssistant from "../../Shared/SiteAssistant/SiteAssistant";
import ColorLine from "../../Shared/ColorLine/ColorLine";
import DiriFaceDocked from "../../../Assets/Images/diriassistant/diri.svg";
import PageTable from "../../Shared/PageTable/PageTable";
import SystemRegTemplate from "../../Shared/RegTemplates/Templates/SystemRegTemplate";
import Field from "../../Shared/Wizard/Field/Field";
import Shop from "../../Shared/Shop/Shop";
import FilterSelector from "../../Shared/FilterSelector/FilterSelector";
import LocaleHandler from "../../../LocaleHandler";
import { generateUrl } from "../../../config";

function Systems() {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();
  const { locale } = React.useContext(LocaleHandler);
  let history = useHistory();
  const myRef = React.useRef<any>();
  const [show, setShow] = useState(false);
  const [newSystemName, setNewSystemName] = useState("");
  const [updateTable, setUpdateTable] = useState(0);
  const [systems, setSystems] = useState([{}]);
  const [newSystemOptions, setNewSystemOptions] = useState([{}]);
  const [privateShowChecked, setPrivateShowChecked] = useState(false);
  const [privateFilter, setPrivateFilter] = useState<any[]>([
    {
      field: "accessRule",
      value: "unaccessible",
    },
  ]);

  const [systemsFilter, setSystemsFilter] = useState<any>([]);

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [newSystemCopyof, setNewSystemCopyof] = useState("");

  const [showWizardModal, setShowWizardModal] = useState(false);
  const [wizardDataLoaded, setWizardDataLoaded] = useState(false);

  const [assistantGotoKey, setAssistantGotoKey] = useState("");
  const [showShop, setShowShop] = useState(false);

  const [selectedRiskAssessments, setSelectedRiskAssessments] = useState<any>(
    []
  );
  const [showSelectedRiskModal, setShowSelectedRiskModal] = useState(false);

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const notifyAlert = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "alert-toast",
    });

  // const newSystemNameChange = (evt) => {
  //     setNewSystemName(evt.target.value);
  // };

  function systemStatusLanguage(system) {
    if (system.systemStatus == "registered") {
      return (
        <div style={{ whiteSpace: "nowrap" }}>
          <ColorLine
            style={{ float: "left", marginRight: "20px" }}
            data={[{ value: 100, color: "#c8c8c8" }]}
          ></ColorLine>
          {t("systems.systemstatuses.label_registered")}
        </div>
      );
    }
    if (system.systemStatus == "assetevaluated") {
      return (
        <div style={{ whiteSpace: "nowrap" }}>
          <ColorLine
            style={{ float: "left", marginRight: "20px" }}
            data={[{ value: 100, color: "#993742" }]}
          ></ColorLine>
          {t("systems.systemstatuses.label_assetevaluated")}
        </div>
      );
    }
    if (system.systemStatus == "riskevaluated") {
      return (
        <div style={{ whiteSpace: "nowrap" }}>
          <ColorLine
            style={{ float: "left", marginRight: "20px" }}
            data={[{ value: 100, color: "#d8775a" }]}
          ></ColorLine>
          {t("systems.systemstatuses.label_riskevaluated")}
        </div>
      );
    }
    if (system.systemStatus == "planned") {
      return (
        <div style={{ whiteSpace: "nowrap" }}>
          <ColorLine
            style={{ float: "left", marginRight: "20px" }}
            data={[{ value: 100, color: "#facd75" }]}
          ></ColorLine>
          {t("systems.systemstatuses.label_planned")}
        </div>
      );
    }
    if (system.systemStatus == "completed") {
      return (
        <div style={{ whiteSpace: "nowrap" }}>
          <ColorLine
            style={{ float: "left", marginRight: "20px" }}
            data={[{ value: 100, color: "#5b8266" }]}
          ></ColorLine>
          {t("systems.systemstatuses.label_completed") +
            " " +
            getDateString(new Date(system["actionplan"]["finishedvaliduntil"]))}
        </div>
      );
    }
  }

  function getDateString(date) {
    let dateString = "";
    dateString +=
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + ".";
    dateString +=
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) + ".";
    dateString += date.getFullYear();
    return dateString;
  }

  const newSystemNameChange = (value) => {
    if (value.length > 1) {
      axios
        .post(
          generateUrl("/api/cra/systems/search"),
          {
            filter: value,
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then(
          function (response) {
            setNewSystemOptions(response.data);
            setAutoCompleteOptions(
              response.data.map((item) => {
                return { id: item._id, label: item.name };
              })
            );
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        );
    }
    setNewSystemName(value);
  };

  const handleClose = () => setShow(false);

  const handleOpenSystem = (systemId) => {
    history.push("/system/" + systemId);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleCreateSystem();
    }
  };

  const handleCreateSystem = () => {
    if (newSystemName == "") {
      notifyWarning(t("systems.toast_system_empty"));
      return undefined;
    }

    let newCustomfields: any = [];
    if (settings["customfieldsSystem"].length > 0) {
      // Initialize the customfeilds with empty value for prventing system crash.
      settings["customfieldsSystem"].map((field) => {
        newCustomfields.push({
          id: field["key"],
          value: "",
        });
      });
    }

    let newSystem: any = {};
    if (newSystemCopyof.length != 0) {
      let fetchedSystem = newSystemOptions.filter(
        (i) => i["_id"] == newSystemCopyof
      );
      newSystem = {
        newDescription:
          fetchedSystem[0]["description"] != undefined
            ? fetchedSystem[0]["description"]
            : "",
        newCategory:
          fetchedSystem[0]["category"] != undefined
            ? fetchedSystem[0]["category"]
            : "",
        newPublicLaw:
          fetchedSystem[0]["publicLaw"] != undefined
            ? fetchedSystem[0]["publicLaw"]
            : false,
      };
    } else {
      newSystem = {
        newDescription: "",
        newCategory: "",
        newPublicLaw: false,
      };
    }
    axios
      .post(
        generateUrl("/api/cra/systems/"),
        {
          name: newSystemName,
          tags: [],
          text: "",
          detached: false,
          copyof: newSystemCopyof,
          org: auth.user["userdata"]["unit"][0],
          description: newSystem["newDescription"],
          category: newSystem["newCategory"],
          publicLaw: newSystem["newPublicLaw"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function () {
        fetchData();
        setUpdateTable(updateTable + 1);
        notifySuccess(t("systems.toast_systemcreated"));
        setShow(false);
      });
  };

  async function fetchData() {
    axios
      .single({
        method: "get",
        url: generateUrl("/api/cra/systems/"),
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        setSystems(res.data);
      });

    setWizardDataLoaded(true);
  }

  function requestCoownership(systemid) {
    axios
      .put(
        generateUrl("/api/cra/systems/addsharedrequest/") + systemid,
        {
          userid: auth.user["userdata"]["_id"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        if (response == undefined) {
          notifyWarning(t("systems.toast_accessrequestexisting"));
        } else {
          notifySuccess(t("systems.toast_accessrequested"));
        }
      });
  }

  function togglePrivateShow(evt) {
    let privateShow = !privateShowChecked;
    setPrivateShowChecked(!privateShowChecked);

    if (privateShow) {
      setPrivateFilter([]);
    } else {
      setPrivateFilter(
        JSON.parse(
          JSON.stringify([
            {
              field: "accessRule",
              value: "unaccessible",
            },
          ])
        )
      );
    }
  }

  function getSystemTypeText(systemtype) {
    let systemtypes: Array<string> = ["system", "orgros", "other"];

    let pattern = /system/;

    if (
      systemtypes.map((i) => i.toLowerCase()).includes(systemtype.toLowerCase())
    ) {
      return t("systems.systems_types." + systemtype.toLowerCase());
    } else if (pattern.test(systemtype.toLowerCase())) {
      return t("systems.systems_types.system");
    } else {
      return systemtype;
    }
  }

  function handleActionChange(type) {
    if (type == "default") {
      return;
    }

    if (type == "export") {
      axios
        .single({
          method: "post",
          url: generateUrl("/api/exportandimport/eisystems/export/"),
          responseType: "arraybuffer",
          data: {
            language: locale,
          },
          headers: { "x-access-token": auth.user["token"] },
        })
        .then((res) => {
          let file = Buffer.from(res.data);
          const blob = new Blob([file], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download =
            "risk_assessments_export_" +
            new Date().toISOString().split("T")[0] +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    }

    if (type == "import") {
      history.push("/import/systems");
    }
  }

  useEffect(() => {
    if (auth.user["userdata"] == undefined) {
      window.location.href = "/login";
    }
    fetchData();
  }, []);

  const addOrRemove = (risk: any) => {
    // !!selectedRiskAssessments.filter((e) => e["_id"] === risk["_id"]).length
    //   ? setSelectedRiskAssessments(
    //       selectedRiskAssessments.filter((e) => e["_id"] !== risk["_id"])
    //     )
    //   : setSelectedRiskAssessments([...selectedRiskAssessments, risk]);
    if (risk.isTemplate) {
      notifyWarning(t("Can not select a template!"));
    } else {
      !!selectedRiskAssessments.filter((e) => e["_id"] === risk["_id"]).length
        ? setSelectedRiskAssessments([])
        : setSelectedRiskAssessments([risk]);
    }
  };

  const handleFromTemplate = (systemSelected) => {
    axios
      .post(
        generateUrl("/api/cra/systems/fromTemplate"),
        {
          systemId: selectedRiskAssessments[0]["_id"],
          templateId: systemSelected["_id"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        setShowSelectedRiskModal(false);
        notifySuccess(t("settings.toast_savesuccess"));
      })
      .catch((err) => {
        notifyAlert(t("forgot_password.something_went_wrong"));
      });
  };

  return (
    <div className="maincontent">
      <SiteAssistant
        dockedLogo={DiriFaceDocked}
        featurekey="systems"
        gotoKey={assistantGotoKey}
        gotoKeyFinished={() => {
          setAssistantGotoKey("");
        }}
        regCompleted={(data) => {
          history.push("/system/" + data._id);
        }}
      />
      <div className="systems-container">
        <div className="pagetitle-container">
          <h1>{t("systems.systems_page_name")}</h1>
        </div>
        <Card>
          <Card.Body>
            <h4 className="header-title">{t("systems.systems_title")}</h4>
            {auth.user["userdata"]["actualAccessRole"]["rules"].some(
              (rule) => rule.actions.request_access_private === "allow"
            ) ? (
              <div className="riskMatrixToggle">
                <FormGroup>
                  <Tooltip
                    title={t("systems.privatetoggle_showtooltip")}
                    placement="top"
                  >
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Switch
                          size="small"
                          checked={privateShowChecked}
                          onChange={togglePrivateShow}
                          className={"toggleswitch"}
                          color="primary"
                        />
                      </Grid>
                      <Grid item>{t("systems.privatetoggle_show")}</Grid>
                    </Grid>
                  </Tooltip>
                </FormGroup>
              </div>
            ) : null}
            <div className="row systems-actions-container">
              <div className="d-flex col-md align-items-center">
                <Button
                  className="newsystemBtn"
                  onClick={() => {
                    setAssistantGotoKey("0");
                  }}
                >
                  {t("systems.systems_new")}
                </Button>
                <Button
                  className="newsystemBtn ml-1"
                  onClick={() => {
                    setShowSelectedRiskModal(true);
                  }}
                  disabled={!selectedRiskAssessments.length}
                >
                  {t("system.populate_risk")}
                </Button>
              </div>
              <div className="col-md-4 import-export-action-field">
                {auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
                  (rule) => rule.objType == "system"
                )?.actions.export_all_risk_assessments == "allow" ? (
                  <Field
                    type="select"
                    title={t("systems.systems_import_export")}
                    onChange={(value) => {
                      handleActionChange(value.value);
                      value.value = "default";
                    }}
                    disabled={
                      auth.user["userdata"]?.["actualAccessRole"]?.[
                        "rules"
                      ].find((rule) => rule.objType == "system")?.actions
                        .export_all_risk_assessments != "allow"
                    }
                    options={{
                      choices: [
                        {
                          label: t("systems.systems_choose_action"),
                          value: "default",
                        },
                        {
                          label: t("systems.systems_export"),
                          value: "export",
                        },
                        {
                          label: t("systems.systems_import"),
                          value: "import",
                        },
                      ],
                    }}
                  ></Field>
                ) : (
                  <Button
                    className="upgrade-btn"
                    onClick={() => {
                      setShowShop(true);
                    }}
                  >
                    <i className="dripicons-lock" />{" "}
                    {t("systems.requires_update_export_import")}
                  </Button>
                )}
              </div>
            </div>

            <br />

            <div />
            <FilterSelector
              filtersUrl={generateUrl("/api/cra/filters/bytype/")}
              filterType="systems"
              fields={[
                {
                  label: t("systems.systems_owner"),
                  value: "ownerPersonReadable",
                  type: "string",
                },
                {
                  label: t("systems.systems_responsible"),
                  value: "responsiblePersonReadable",
                  type: "string",
                },
                {
                  label: t("systems.systems_unit"),
                  value: "orgName",
                  type: "string",
                },
                {
                  label: t("systems.systems_status"),
                  value: "operationStatus",
                  type: "select",
                  choices: [
                    {
                      label: t(
                        "system_description.system_wizard.status.unknown"
                      ),
                      value: "Ukjent",
                    },
                    {
                      label: t(
                        "system_description.system_wizard.status.indevelopment"
                      ),
                      value: "Utvikling",
                    },
                    {
                      label: t("system_description.system_wizard.status.test"),
                      value: "Test",
                    },
                    {
                      label: t("system_description.system_wizard.status.pilot"),
                      value: "Pilot",
                    },
                    {
                      label: t(
                        "system_description.system_wizard.status.production"
                      ),
                      value: "Produksjon",
                    },
                    {
                      label: t(
                        "system_description.system_wizard.status.phasingout"
                      ),
                      value: "Under utfasing",
                    },
                    {
                      label: t(
                        "system_description.system_wizard.status.phasedout"
                      ),
                      value: "faset ut",
                    },
                    {
                      label: t(
                        "system_description.system_wizard.status.storage"
                      ),
                      value: "Under oppbevaring",
                    },
                    {
                      label: t(
                        "system_description.system_wizard.status.discontinued"
                      ),
                      value: "fjernet",
                    },
                  ],
                },
                {
                  label: t("systems.systems_systemstatus"),
                  value: "systemStatus",
                  type: "select",
                  choices: [
                    {
                      label: t("systems.systemstatuses.label_registered"),
                      value: "registered",
                    },
                    {
                      label: t("systems.systemstatuses.label_assetevaluated"),
                      value: "assetevaluated",
                    },
                    {
                      label: t("systems.systemstatuses.label_riskevaluated"),
                      value: "riskevaluated",
                    },
                    {
                      label: t("systems.systemstatuses.label_planned"),
                      value: "planned",
                    },
                    {
                      label: t("systems.systemstatuses.label_completed"),
                      value: "completed",
                    },
                  ],
                },
                {
                  label: t("systems.systems_type"),
                  value: "systemType",
                  type: "select",
                  choices: [
                    {
                      label: t("systems.systems_types.system"),
                      value: "system",
                    },
                    {
                      label: t("systems.systems_types.orgros"),
                      value: "orgros",
                    },
                    { label: t("systems.systems_types.other"), value: "other" },
                  ],
                },
                {
                  label: t("systems.systems_date"),
                  value: "date",
                  type: "date",
                },
                {
                  label: t("systems.systems_ispublic"),
                  value: "isPublic",
                  type: "select",
                  choices: [
                    { value: true, label: t("systems.yes") },
                    { value: false, label: t("systems.no") },
                  ],
                },
                {
                  label: t("systems.systems_myAssessment"),
                  value: "myAssessment",
                  type: "select",
                  choices: [
                    { value: true, label: t("systems.yes") },
                    { value: false, label: t("systems.no") },
                  ],
                },
                {
                  label: t("systems.systems_sharedWithMe"),
                  value: "sharedWithMe",
                  type: "select",
                  choices: [
                    { value: true, label: t("systems.yes") },
                    { value: false, label: t("systems.no") },
                  ],
                },
                {
                  label: t("systems.systems_isOrgAssessment"),
                  value: "isOrgAssessment",
                  type: "select",
                  choices: [
                    { value: true, label: t("systems.yes") },
                    { value: false, label: t("systems.no") },
                  ],
                },
                {
                  label: t("systems.systems_currentOrgIsOwnerOrg"),
                  value: "currentOrgIsOwnerOrg",
                  type: "select",
                  choices: [
                    { value: true, label: t("systems.yes") },
                    { value: false, label: t("systems.no") },
                  ],
                },
              ]}
              onChange={(filter) => {
                setSystemsFilter(filter.filters);
              }}
            ></FilterSelector>
            <div id="riskAssesmentsTable">
              <PageTable
                dataUrl={generateUrl("/api/cra/systems/getAllPaged")}
                options={{
                  pageSize: 10,
                  showFields: [
                    {
                      label: t("systems.systems_name"),
                      field: "nameReadable",
                      override: (dataLine) =>
                        dataLine["accessRule"] == "unaccessible"
                          ? dataLine["nameReadable"] +
                            " " +
                            t("systems.system_private")
                          : dataLine["nameReadable"],
                    },
                    {
                      label: t("systems.systems_type"),
                      field: "systemType",
                      override: (dataLine) =>
                        dataLine["systemType"] != undefined &&
                        dataLine["systemType"][0] != undefined
                          ? getSystemTypeText(dataLine["systemType"][0])
                          : "",
                      customFilters: [
                        {
                          label: t("systems.systems_types.system"),
                          value: "system",
                        },
                        {
                          label: t("systems.systems_types.orgros"),
                          value: "orgros",
                        },
                        {
                          label: t("systems.systems_types.other"),
                          value: "other",
                        },
                      ],
                    },
                    { label: t("systems.systems_unit"), field: "orgName" },
                    {
                      label: t("systems.systems_owner"),
                      field: "ownerPersonReadable",
                    },
                    {
                      label: t("systems.systems_status"),
                      field: "operationStatus",
                      override: (dataLine) => {
                        if (dataLine.operationStatus == "Ukjent") {
                          return t(
                            "system_description.system_wizard.status.unknown"
                          );
                        }
                        if (dataLine.operationStatus == "Utvikling") {
                          return t(
                            "system_description.system_wizard.status.indevelopment"
                          );
                        }
                        if (dataLine.operationStatus == "Test") {
                          return t(
                            "system_description.system_wizard.status.test"
                          );
                        }
                        if (dataLine.operationStatus == "Pilot") {
                          return t(
                            "system_description.system_wizard.status.pilot"
                          );
                        }
                        if (dataLine.operationStatus == "Produksjon") {
                          return t(
                            "system_description.system_wizard.status.production"
                          );
                        }
                        if (dataLine.operationStatus == "Under utfasing") {
                          return t(
                            "system_description.system_wizard.status.phasingout"
                          );
                        }

                        if (dataLine.operationStatus == "faset ut") {
                          return t(
                            "system_description.system_wizard.status.phasedout"
                          );
                        }
                        if (dataLine.operationStatus == "Under oppbevaring") {
                          return t(
                            "system_description.system_wizard.status.storage"
                          );
                        }
                        if (dataLine.operationStatus == "fjernet") {
                          return t(
                            "system_description.system_wizard.status.discontinued"
                          );
                        }
                        return dataLine.operationStatus;
                      },
                    },
                    {
                      label: t("systems.systems_systemstatus"),
                      field: "systemStatus",
                      override: (dataLine) => systemStatusLanguage(dataLine),
                      customFilters: [
                        {
                          label: t("systems.systemstatuses.label_registered"),
                          value: "registered",
                        },
                        {
                          label: t(
                            "systems.systemstatuses.label_assetevaluated"
                          ),
                          value: "assetevaluated",
                        },
                        {
                          label: t(
                            "systems.systemstatuses.label_riskevaluated"
                          ),
                          value: "riskevaluated",
                        },
                        {
                          label: t("systems.systemstatuses.label_planned"),
                          value: "planned",
                        },
                        {
                          label: t("systems.systemstatuses.label_completed"),
                          value: "completed",
                        },
                      ],
                    },
                    {
                      label: t("systems.systems_date"),
                      field: "dateReadable",
                      override: (dataLine) => {
                        let date = new Date(
                          new Date(dataLine["date"]).getTime() +
                            new Date(dataLine["date"]).getTimezoneOffset() / 60
                        ).getTime();

                        let dateYear = new Date(date).getFullYear();
                        let dateMonth = new Date(date).getMonth() + 1;
                        let dateDay = new Date(date).getDate();
                        let dateHour = new Date(date).getHours();
                        let dateMinute = new Date(date).getMinutes();

                        return (
                          dateYear +
                          "-" +
                          (dateMonth.toString().length == 1
                            ? "0" + dateMonth
                            : dateMonth) +
                          "-" +
                          (dateDay.toString().length == 1
                            ? "0" + dateDay
                            : dateDay) +
                          " " +
                          (dateHour.toString().length == 1
                            ? "0" + dateHour
                            : dateHour) +
                          ":" +
                          (dateMinute.toString().length == 1
                            ? "0" + dateMinute
                            : dateMinute)
                        );
                      },
                    },
                    {
                      label: t("systems.last_updated"),
                      field: "updatedAt",
                      override: (dataLine) => {
                        if (dataLine["updatedAt"]) {
                          let date = new Date(
                            new Date(dataLine["updatedAt"]).getTime() +
                              new Date(
                                dataLine["updatedAt"]
                              ).getTimezoneOffset() /
                                60
                          ).getTime();

                          let dateYear = new Date(date).getFullYear();
                          let dateMonth = new Date(date).getMonth() + 1;
                          let dateDay = new Date(date).getDate();
                          let dateHour = new Date(date).getHours();
                          let dateMinute = new Date(date).getMinutes();

                          return (
                            dateYear +
                            "-" +
                            (dateMonth.toString().length == 1
                              ? "0" + dateMonth
                              : dateMonth) +
                            "-" +
                            (dateDay.toString().length == 1
                              ? "0" + dateDay
                              : dateDay) +
                            " " +
                            (dateHour.toString().length == 1
                              ? "0" + dateHour
                              : dateHour) +
                            ":" +
                            (dateMinute.toString().length == 1
                              ? "0" + dateMinute
                              : dateMinute)
                          );
                        }
                        return "";
                      },
                    },
                  ],
                }}
                dataAfter={(dataLine) =>
                  dataLine["accessRule"] !== "accessible" && (
                    <Button
                      style={{ width: "130px" }}
                      onClick={() => requestCoownership(dataLine["_id"])}
                    >
                      {t("systems.system_request_coownership")}
                    </Button>
                  )
                }
                dataBefore={(dataLine) =>
                  dataLine["accessRule"] === "accessible" && (
                    <div className="checkbox">
                      <Field
                        type="indeterminatecheckbox"
                        checked={
                          !!selectedRiskAssessments.filter(
                            (e) => e["_id"] === dataLine["_id"]
                          ).length
                        }
                        onChange={() => addOrRemove(dataLine)}
                        parent={false}
                        titlePlacement="end"
                      ></Field>
                    </div>
                  )
                }
                exclusiveFilters={privateFilter}
                loadingText={t("systems.loading")}
                updateTable={updateTable}
                onLineClick={(dataLine) => {
                  if (dataLine["accessRule"] === "accessible")
                    handleOpenSystem(dataLine["_id"]);
                }}
                hover={true}
                hoverExempt={(dataLine) =>
                  dataLine["accessRule"] === "unaccessible"
                }
                hidePagination={false}
                hideFilter={false}
                defaultSortField="nameReadable"
                defaultSortDirection="asc"
                permaFilters={systemsFilter}
                selectedItems={selectedRiskAssessments}
                filterDataBy={
                  !systemsFilter.filter((e) => e.field === "operationStatus")
                    .length && {
                    key: "operationStatus",
                    value: "fjernet",
                  }
                }
              ></PageTable>
            </div>
            <br />
          </Card.Body>
        </Card>
        <Modal show={showWizardModal} onHide={() => setShowWizardModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("regtemplates.systemreg.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SystemRegTemplate
              regCompleted={(data) => {
                setShowWizardModal(false);
                fetchData();
              }}
            ></SystemRegTemplate>
          </Modal.Body>
        </Modal>
        <Modal
          show={show}
          onHide={handleClose}
          onEntered={() => myRef.current.focus()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("systems.systems_new_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("systems.systems_new_name")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Typeahead
                id="newSystemNameInput"
                onInputChange={newSystemNameChange}
                onChange={(items) => {
                  if (items.length > 0) {
                    if (items[0]["id"] != undefined && items[0]["id"] != "") {
                      setNewSystemCopyof(items[0]["id"]);
                    } else {
                      setNewSystemCopyof("");
                    }
                    newSystemNameChange(items[0]["label"]);
                  } else {
                    setNewSystemCopyof("");
                  }
                }}
                onKeyDown={handleKeyDown}
                ref={myRef}
                minLength={2}
                emptyLabel={t("bowtie.no_results")}
                options={autoCompleteOptions}
              />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>
              {t("systems.systems_new_cancel")}
            </Button>
            <Button onClick={handleCreateSystem}>
              {t("systems.systems_new_save")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Shop
        show={showShop}
        onHide={() => setShowShop(false)}
        size="lg"
        orgid={auth.user["userdata"]["unit"][0]}
        onPurchaseComplete={() => setShowShop(false)}
      />

      <Modal
        show={showSelectedRiskModal}
        size="lg"
        onHide={() => setShowSelectedRiskModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select risk assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="consequenceTable">
            <PageTable
              dataUrl={generateUrl("/api/cra/systems/getAllPaged")}
              defaultFilters={[
                {
                  field: "isTemplate",
                  value: "true",
                },
              ]}
              options={{
                pageSize: 10,
                showFields: [
                  {
                    label: t("systems.systems_name"),
                    field: "nameReadable",
                    override: (dataLine) =>
                      dataLine["accessRule"] == "unaccessible"
                        ? dataLine["nameReadable"] +
                          " " +
                          t("systems.system_private")
                        : dataLine["nameReadable"],
                  },
                  {
                    label: t("systems.systems_type"),
                    field: "systemType",
                    override: (dataLine) =>
                      dataLine["systemType"] != undefined &&
                      dataLine["systemType"][0] != undefined
                        ? getSystemTypeText(dataLine["systemType"][0])
                        : "",
                    customFilters: [
                      {
                        label: t("systems.systems_types.system"),
                        value: "system",
                      },
                      {
                        label: t("systems.systems_types.orgros"),
                        value: "orgros",
                      },
                      {
                        label: t("systems.systems_types.other"),
                        value: "other",
                      },
                    ],
                  },
                  { label: t("systems.systems_unit"), field: "orgName" },
                  {
                    label: t("systems.systems_owner"),
                    field: "ownerPersonReadable",
                  },
                  {
                    label: t("systems.systems_status"),
                    field: "operationStatus",
                    override: (dataLine) => {
                      if (dataLine.operationStatus == "Ukjent") {
                        return t(
                          "system_description.system_wizard.status.unknown"
                        );
                      }
                      if (dataLine.operationStatus == "Utvikling") {
                        return t(
                          "system_description.system_wizard.status.indevelopment"
                        );
                      }
                      if (dataLine.operationStatus == "Test") {
                        return t(
                          "system_description.system_wizard.status.test"
                        );
                      }
                      if (dataLine.operationStatus == "Pilot") {
                        return t(
                          "system_description.system_wizard.status.pilot"
                        );
                      }
                      if (dataLine.operationStatus == "Produksjon") {
                        return t(
                          "system_description.system_wizard.status.production"
                        );
                      }
                      if (dataLine.operationStatus == "Under utfasing") {
                        return t(
                          "system_description.system_wizard.status.phasingout"
                        );
                      }

                      if (dataLine.operationStatus == "faset ut") {
                        return t(
                          "system_description.system_wizard.status.phasedout"
                        );
                      }
                      if (dataLine.operationStatus == "Under oppbevaring") {
                        return t(
                          "system_description.system_wizard.status.storage"
                        );
                      }
                      if (dataLine.operationStatus == "fjernet") {
                        return t(
                          "system_description.system_wizard.status.discontinued"
                        );
                      }
                      return dataLine.operationStatus;
                    },
                  },
                  {
                    label: t("systems.systems_systemstatus"),
                    field: "systemStatus",
                    override: (dataLine) => systemStatusLanguage(dataLine),
                    customFilters: [
                      {
                        label: t("systems.systemstatuses.label_registered"),
                        value: "registered",
                      },
                      {
                        label: t("systems.systemstatuses.label_assetevaluated"),
                        value: "assetevaluated",
                      },
                      {
                        label: t("systems.systemstatuses.label_riskevaluated"),
                        value: "riskevaluated",
                      },
                      {
                        label: t("systems.systemstatuses.label_planned"),
                        value: "planned",
                      },
                      {
                        label: t("systems.systemstatuses.label_completed"),
                        value: "completed",
                      },
                    ],
                  },
                  {
                    label: t("systems.systems_date"),
                    field: "dateReadable",
                    override: (dataLine) => {
                      let date = new Date(
                        new Date(dataLine["date"]).getTime() +
                          new Date(dataLine["date"]).getTimezoneOffset() / 60
                      ).getTime();

                      let dateYear = new Date(date).getFullYear();
                      let dateMonth = new Date(date).getMonth() + 1;
                      let dateDay = new Date(date).getDate();
                      let dateHour = new Date(date).getHours();
                      let dateMinute = new Date(date).getMinutes();

                      return (
                        dateYear +
                        "-" +
                        (dateMonth.toString().length == 1
                          ? "0" + dateMonth
                          : dateMonth) +
                        "-" +
                        (dateDay.toString().length == 1
                          ? "0" + dateDay
                          : dateDay) +
                        " " +
                        (dateHour.toString().length == 1
                          ? "0" + dateHour
                          : dateHour) +
                        ":" +
                        (dateMinute.toString().length == 1
                          ? "0" + dateMinute
                          : dateMinute)
                      );
                    },
                  },
                ],
              }}
              exclusiveFilters={privateFilter}
              loadingText={t("systems.loading")}
              updateTable={updateTable}
              onLineClick={(dataLine) => {
                handleFromTemplate(dataLine);
              }}
              hover={true}
              hidePagination={false}
              hideFilter={false}
              defaultSortField="nameReadable"
              defaultSortDirection="asc"
            ></PageTable>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowSelectedRiskModal(false)}>
            {t("treatment.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Systems;
