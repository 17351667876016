import * as React from "react";

import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import { useAxios } from "../../../../AxiosHandler";
import { useAuth } from "../../../../Auth";

import { generateUrl } from "../../../../config";

import "react-toastify/dist/ReactToastify.css";

function TaskDeleteModal(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const handleDeleteTask = () => {
    axios
      .delete(
        generateUrl("/api/cra/tasks/") + props.deleteTaskId,
        {},
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        if (props.onDeleted != undefined) props.onDeleted();
        notifySuccess(t("tasks.toast.task_deleted"));
      });
  };

  useEffect(() => {}, [props.deleteTaskId]);

  return (
    <div>
      <Modal
        className="taskDeleteModal"
        show={props.show}
        onHide={() => {
          if (props.onHide != undefined) props.onHide();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("tasks.modal_task_delete.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("tasks.modal_task_delete.text")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={() => {
              if (props.onCancel != undefined) props.onCancel();
            }}
          >
            {t("tasks.modal_task_create.cancelbtn")}
          </Button>
          <Button onClick={handleDeleteTask}>
            {t("tasks.modal_task_delete.deletebtn")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TaskDeleteModal;
