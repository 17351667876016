import React, { useEffect } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

function Autocomplete(props) {
    const handleChange = (value) => {
        if (props.onChange != undefined) {
            props.onChange(value);
        }
    }

    useEffect(() => {

    }, [props.options.list]);

    return(
        <InputGroup className="mb-3 autocomplete">
            <InputGroup.Prepend>
                <InputGroup.Text>{props.title}</InputGroup.Text>
            </InputGroup.Prepend>
            <Typeahead
                id={"autocompleter-" + props.title}
                disabled={props.disabled}
                onInputChange={val => handleChange(val)}
                onChange={items => {
                    if (items.length > 0) {
                        handleChange(items[0]["label"]);
                    }
                }}
                placeholder={props.options.placeholder}
                defaultInputValue={props.value != undefined && props.value != "" ? props.value : (props.defaultValue != undefined ? props.defaultValue : "")}
                minLength={props.options != undefined && props.options.minlength != undefined ? props.options.minlength : 2}
                emptyLabel={props.options != undefined && props.options.noresults != undefined ? props.options.noresults : ""}
                options={props.options != undefined ? props.options.list : []}
            />
        </InputGroup>
    );
}

export default Autocomplete;