import React, { useState, useEffect } from "react";

import { InputGroup } from "react-bootstrap";
import Select from "react-select";

import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";

import { generateUrl } from "../../../../../config";

const typeList = ["System"];

function SelectMulti(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;

  const [loadedOptions, setLoadedOptions] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(0);

  const handleChange = (value) => {
    if (props.onChange != undefined) {
      props.onChange(value != null ? value : []);
    }
  };

  const handleChangeFixed = (value, option) => {
    if (option.removedValue && option.removedValue.isFixed) return;
    if (props.onChange != undefined) {
      props.onChange(value != null ? value : []);
    }
  };

  const renderOptions = (options) => {
    return options.map((option, key) => (
      <option key={key} value={option.value}>
        {option.label}
      </option>
    ));
  };

  const getOptions = () => {
    switch (props.options.typeLoad) {
      case "Manual":
        break;
      case "System":
        axios
          .all([
            axios.single({
              method: "get",
              url: generateUrl("/api/cra/systems/"),
              responseType: "json",
              headers: { "x-access-token": auth.user["token"] },
            }),
          ])
          .then(
            axios.spread((...res) => {
              setLoadedOptions(
                res[0].data.map((system) => {
                  return { label: system["name"], value: system["_id"] };
                })
              );
            })
          );
        break;

      case "User":
        axios
          .all([
            axios.single({
              method: "get",
              url: generateUrl("/api/users"),
              responseType: "json",
              headers: { "x-access-token": auth.user["token"] },
            }),
          ])
          .then(
            axios.spread((...res) => {
              setLoadedOptions(
                res[0].data.map((user) => {
                  return { label: user["name"], value: user["_id"] };
                })
              );
            })
          );
        break;
    }
  };

  const selectStyles = {
    multiValueRemove: (base, data) => {
      return data.data.isFixed ? { ...base, display: "none" } : base;
    },
  };

  useEffect(() => {
    if (
      props.options != undefined &&
      props.options.typeLoad != undefined &&
      props.options.typeLoad != "Manual"
    ) {
      getOptions();
    }
  }, []);

  useEffect(() => {
    setForceUpdate(forceUpdate + 1);
  }, [props.options.choices]);

  return (
    <InputGroup className="mb-3">
      <InputGroup.Prepend>
        <InputGroup.Text>{props.title}</InputGroup.Text>
      </InputGroup.Prepend>
      <Select
        className={"multiselect " + (props.disabled ? "disabled" : "")}
        closeMenuOnSelect={false}
        isDisabled={props.disabled}
        value={
          props.value != undefined && props.value != ""
            ? props.value
            : props.defaultValue != undefined
            ? props.defaultValue
            : []
        }
        isMulti
        onChange={
          props.options.hasFixedValue != true
            ? (vals) => handleChange(vals)
            : handleChangeFixed
        }
        options={[
          ...(props.options != undefined &&
          props.options.choices != undefined &&
          props.options.choices.length > 0
            ? props.options.choices
            : []),
          ...(loadedOptions != undefined ? loadedOptions : []),
        ]}
        styles={props.options.hasFixedValue ? selectStyles : undefined}
        isClearable={
          props.options.hasFixedValue == true
            ? !props.options.choices.some((choice) => choice.isFixed)
            : true
        }
        //defaultInputValue={props.options.choosetext}
        noOptionsMessage={props.options.noresults}
        placeholder={
          props.options != undefined && props.options.choosetext != undefined
            ? props.options.choosetext
            : "Click to select values .."
        }
      />
    </InputGroup>
  );
}

export default { SelectMulti, TypeList: typeList };
