import * as React from 'react';
import SystemInformationOrgros from './Types/SystemInformationOrgros';

import SystemInformationSystem from './Types/SystemInformationSystem';
import SystemInformationOther from './Types/SystemInformationOther';

function SystemInformation(props) {
    
    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    function drawInfoType() {
        if (props.system.systemType != undefined) {
            switch (props.system.systemType[0]) {
                case "orgros":
                    return <SystemInformationOrgros system={props.system} orgelements={props.orgelements}></SystemInformationOrgros>;
                case "other" :
                    return <SystemInformationOther system={props.system}></SystemInformationOther>;
                default:
                    return <SystemInformationSystem system={props.system}></SystemInformationSystem>;
            }
        }
    }

    return(
        <div className="systeminformation">
            {drawInfoType()}
        </div>
    );
}
 
export default SystemInformation;