import React, { useState, useContext, createContext } from "react";

import { generateUrl } from "./config";

const axios = require("axios").default;

const authContext = createContext({
  user: {},
  setUser: (user: any) => {},
  signin: (user: any, password: any) => {},
  signup: (user: any, password: any) => {},
  signout: (sessionClear?: boolean) => {},
  confirmPasswordReset: (password: string, email: string, token: string) => {},
  forgotPassword: (email: string) => {},
});

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState({});

  if (
    localStorage.getItem("user") != undefined &&
    localStorage.getItem("user") != JSON.stringify(user)
  ) {
    let userstring = localStorage.getItem("user");
    setUser(() => {
      return JSON.parse(String(userstring));
    });
  }

  async function fetchSettings(result) {
    await axios
      .get(
        generateUrl("/api/cra/settings/byorg/") + result["userdata"]["unit"][0],
        {
          responseType: "json",
          headers: { "x-access-token": result.token },
        }
      )
      .then((response) => {
        let user = localStorage.getItem("user");
        if (user != null) {
          user = JSON.parse(user);
          if (user != null) {
            console.log(user["settings"]);
            user["settings"] = response.data.appliedSettings;
            localStorage.setItem("user", JSON.stringify(user));
          }
        }
      });
  }

  async function signin(email, password) {
    let userObject = {
      id: "",
      auth: "",
      token: "",
      userdata: {},
      settings: {},
    };

    await axios
      .post(generateUrl("/api/login"), {
        email: email,
        password: password,
      })
      .then(async function (response) {
        userObject = {
          id: email,
          auth: response.data.auth,
          token: response.data.token,
          userdata: response.data.userdata,
          settings: response.data.settings,
        };
        localStorage.setItem("user", JSON.stringify(userObject));
        localStorage.removeItem("unauthorized");
        fetchSettings(response.data);

        await setUser(() => {
          return userObject;
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    return userObject;
  }

  const signup = (email, password) => {};

  const signout = (sessionClear = false) => {
    localStorage.clear();
    if (sessionClear) {
      sessionStorage.clear();
    }
    setUser({});
  };

  async function confirmPasswordReset(password, email, token) {
    let result;

    await axios
      .post(generateUrl("/api/login/reset-password"), {
        token: token,
        password: password,
        email: email,
      })
      .then(async function (response) {
        result = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    return result;
  }

  async function forgotPassword(email) {
    let result;

    await axios
      .post(generateUrl("/api/login/forgot-password"), {
        email: email,
      })
      .then(async function (response) {
        result = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    return result;
  }

  return {
    user,
    setUser,
    signin,
    signup,
    signout,
    confirmPasswordReset,
    forgotPassword,
  };
}
