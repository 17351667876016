import React from "react";

import { useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useTranslate } from "react-polyglot";

import PageTable from "../../Shared/PageTable/PageTable";
import { useDynamicLanguage } from "../../../DynamicLanguageProvider";
import { generateUrl } from "../../../config";

function ValueRegistry() {
  const t = useTranslate();
  const history = useHistory();
  const td = useDynamicLanguage().td;

  const customValueTypes = [
    {
      value: "systemvaluecommunication",
      label: t("system_asset.type.systemvaluecommunication"),
    },
    {
      value: "systemvaluecompanydata",
      label: t("system_asset.type.systemvaluecompanydata"),
    },
    {
      value: "systemvalueeconomy",
      label: t("system_asset.type.systemvalueeconomy"),
    },
    {
      value: "systemvaluehardware",
      label: t("system_asset.type.systemvaluehardware"),
    },
    {
      value: "systemvalueotherinfo",
      label: t("system_asset.type.systemvalueotherinfo"),
    },
    {
      value: "systemvalueotherlawinfo",
      label: t("system_asset.type.systemvalueotherlawinfo"),
    },
    {
      value: "systemvaluepersoninformation",
      label: t("system_asset.type.systemvaluepersoninformation"),
    },
    {
      value: "systemvalueusernamepassword",
      label: t("system_asset.type.systemvalueusernamepassword"),
    },
  ];

  function getSystemTypeText(systemtype) {
    let systemtypes: Array<string> = ["system", "orgros", "other"];

    let pattern = /system/;

    if (
      systemtypes.map((i) => i.toLowerCase()).includes(systemtype.toLowerCase())
    ) {
      return t("systems.systems_types." + systemtype.toLowerCase());
    } else if (pattern.test(systemtype.toLowerCase())) {
      return t("systems.systems_types.system");
    } else {
      return systemtype;
    }
  }

  return (
    <div className="maincontent">
      <div className="systems-container">
        <div className="pagetitle-container">
          <h1>{t("valueregistry.page_name")}</h1>
        </div>
        <Card>
          <Card.Body>
            <PageTable
              dataUrl={generateUrl("/api/cra/systemvalues/getAllPaged/")}
              options={{
                showFields: [
                  {
                    label: t("valueregistry.system_name"),
                    field: "systemName",
                  },
                  {
                    label: t("valueregistry.system_type"),
                    field: "systemType",
                    override: (dataLine) =>
                      dataLine["systemType"] != undefined &&
                      dataLine["systemType"][0] != undefined
                        ? getSystemTypeText(dataLine["systemType"][0])
                        : "",
                    customFilters: [
                      {
                        label: t("systems.systems_types.system"),
                        value: "system",
                      },
                      {
                        label: t("systems.systems_types.orgros"),
                        value: "orgros",
                      },
                      {
                        label: t("systems.systems_types.other"),
                        value: "other",
                      },
                    ],
                  },
                  {
                    label: t("valueregistry.value_name"),
                    field: "systemValueName",
                  },
                  {
                    label: t("valueregistry.value_type"),
                    field: "valueType",
                    override: (dataLine) => {
                      if (
                        customValueTypes
                          .map((i) => i.value)
                          .includes(dataLine.valueType)
                      ) {
                        return t("system_asset.type." + dataLine.valueType);
                      } else {
                        return dataLine.valueType;
                      }
                    },
                    customFilters: customValueTypes,
                  },
                  {
                    label: t("valueregistry.value_confidentiality"),
                    field: "k",
                  },
                  { label: t("valueregistry.value_integrity"), field: "i" },
                  { label: t("valueregistry.value_availability"), field: "t" },
                ],
                pageSize: 25,
              }}
              onLineClick={(dataLine) =>
                history.push("/system/valueeval/" + dataLine.systemId)
              }
              hidePagination={false}
              hidePagesize={false}
              hideFilter={false}
              hover={true}
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default ValueRegistry;
