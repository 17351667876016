import React, { useEffect, useState } from 'react';
import { useAxios } from "../../../../../AxiosHandler";

import { useTranslate } from 'react-polyglot';
import './TopConsequences.scss';
import { useAuth } from "../../../../../Auth";
import { Card, Modal, InputGroup, Form } from 'react-bootstrap';
import { HorizontalBar } from 'react-chartjs-2';
import _ from 'lodash';
import PageTable from '../../../../Shared/PageTable/PageTable';
import Field from '../../../../Shared/Wizard/Field/Field';
import { useDynamicLanguage } from '../../../../../DynamicLanguageProvider';
import { generateUrl } from '../../../../../config';

const TopConsequences = (props) => {
    const t = useTranslate();   
    const td = useDynamicLanguage().td;
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();
    const [allConseq, setAllConseq] = useState([{}]);
    const [allOccurences, setAllOccurences] = useState([{}]);
    //const [allSystemvalues, setAllSystemvalues] = useState<Array<Object>>();
    const [allSystemAssets, setAllSystemAssets] = useState([{}]);
    const [selectedOccurences, setSelectedOccurences] = useState<Array<any>>();
    const [selectedConseq, setSelectedConseq] = useState({});
    const [selectedAsset, setSelectedAsset] = useState<Array<Object>>();
    const [selectedConseqLevel, setSelectedConseqLevel] = useState();
    const [updateTable, setUpdateTable] = useState(0);
    // const [showLoadingCauses, setShowLoadingCauses] = useState(false);

    const [selectedOption, setSelectedOption] = useState("all");
    const [topConseq, setTopConseq] = useState<Array<any>>([]);
    const [labels, setLabels] = useState<Array<any>>([]);
    const [colors, setColors] = useState<Array<Object>>();
    const [loading, setLoading] = useState(true);
    const label_top5 = t("dashboard.cards.top5conseq");
    const [showConsequenceModal, setShowConsequenceModal] = useState(false);
    const handleCloseConsequenceModal = () => setShowConsequenceModal(false);

    const treatmentName = t("treatment.name");
    const treatmentCost = t("treatment.cost");
    const treatmentStatus = t("treatment.status");
    const treatmentResponsible = t("treatment.responsible");
    const treatmentTime = t("treatment.time");
    const probabilityReduction = t("treatment.probability_reduction");

    let selectTitle = t("dashboard.cards.drop_down_select_title");
    let selectOptions = [
        {
            "label": t("dashboard.cards.choice_all"),
            "value": "all"
        },
        {
            "label": t("dashboard.cards.choice_system"),
            "value": "system"
        },
        {
            "label": t("dashboard.cards.choice_orgros"),
            "value": "orgros"
        },
        {
            "label": t("dashboard.cards.choice_other"),
            "value": "other"
        },
    ]

    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    function handleSelectInput(value){
        setLoading(true);
        setSelectedOption(value);
    }

    function valueToPercentage(value, maxvalue) {
        return ((value - 1) / (maxvalue - 1) * 100);
    }

    function percentageToValue(percentage, maxvalue) {
        return ((maxvalue - 1) * (percentage / 100)) + 1;
    }

    function handleChartClick(event, elements){
        setShowConsequenceModal(true);

        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        // const dataset = chart.data.datasets[element._datasetIndex];
        
        let tmpSelectedConseq = topConseq[elements[0]._index];
        if ((tmpSelectedConseq["occurences"] == undefined || tmpSelectedConseq["occurences"].length == 0) && tmpSelectedConseq["occurence"] != "") {
            tmpSelectedConseq["occurences"] = [{ value: tmpSelectedConseq["occurence"], label: allOccurences.find(i => i["_id"] == tmpSelectedConseq["occurence"])?["name"] : ""}];
        }

        if (tmpSelectedConseq["_id"] != selectedConseq["_id"]) {
            let tmpOccurences:any = allOccurences.filter(i => i != undefined && tmpSelectedConseq["occurences"].map(o=>o.value).includes(i["_id"]));
    
            setSelectedConseq(tmpSelectedConseq);
            setSelectedOccurences(tmpOccurences);
            
            setSelectedAsset(allSystemAssets.filter(i => tmpSelectedConseq["systemvalue"].map(j => j["id"]).includes(i["_id"])).map(i => {
                return {
                    systemvalue: i["name"],
                    kit: tmpSelectedConseq["systemvalue"].find(j => j["id"] == i["_id"])["kit"]
                };
            }));
    
            // consequence level
            let tmpConvert:any;
    
            if(tmpSelectedConseq["consequenceValue"] != undefined){
                tmpConvert = Math.round(percentageToValue(tmpSelectedConseq["consequenceValue"], settings["gridY"]));
            }
    
            switch(tmpConvert){
                case 0:
                    setSelectedConseqLevel(settings["gridYName1"]);
                    break;
                case 1:
                    setSelectedConseqLevel(settings["gridYName1"]);
                    break;
                case 2:
                    setSelectedConseqLevel(settings["gridYName2"]);
                    break;
                case 3:
                    setSelectedConseqLevel(settings["gridYName3"]); 
                    break;
                case 4:
                    setSelectedConseqLevel(settings["gridYName4"]);
                    break;
                case 5:
                    setSelectedConseqLevel(settings["gridYName5"]);
                    break;
                case 6:
                    setSelectedConseqLevel(settings["gridYName6"]);
                    break;
                }
        }
    }

    const data = {
        labels:labels,
        datasets: [
            {
                label: label_top5,
                backgroundColor: colors,
                borderWidth: 1,
                data: topConseq.map(item => item["probability"]),
                objects: topConseq
          },
        ],
    };
      
    const options = {
            onClick: function(event, elements) {
                if (elements.length > 0) {
                    handleChartClick(event, elements);
            }},
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                stacked: true
                }],
                yAxes: [{
                stacked: true
                }],
            },
            tooltips: {
                mode: 'label',
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return data['datasets'][0]['data'][tooltipItem['index']];
                        }
                    }
            }
    }

    async function processData(res, type) {
        let systems = res[4].data;

        if (type == "all") {
            systems = res[4].data;
            systems = systems.map(system => system._id);
        } else if (type == "system") {
            systems = res[4].data.filter(res => res.systemType.toString().toLowerCase().includes(type));
            systems = systems.map(system => system._id);
        } else {
            systems = res[4].data.filter(res => res.systemType.toString().toLowerCase() == type);
            systems = systems.map(system => system._id);
        }

        setAllConseq(res[0].data.filter(res => systems.includes(res.system)));
        setAllOccurences(res[2].data);
        setAllSystemAssets(res[3].data)
        let allConseq = res[0].data.filter(res => systems.includes(res.system));
        let allTreatments = res[1].data;
        let tempConseq = [{}];

        allConseq.map((item,key) =>{
            let probability = (item["consequenceValue"] - allTreatments.filter(
                treatment => treatment["consequence"] == item["_id"]
            ).map(
                treatment => treatment["probabilityreduction"]
            ).reduce(
                (total, number) => total + number, 0
            ))
                
            tempConseq.push({
                ...item,
                id: item["_id"],
                //name: item["name"],
                probability : probability > 0 ? probability : 0
            });

            let topFive =_.orderBy(tempConseq, ['probability'],['desc']).slice(1,6)
            let tmpLabels = Array<String>();
            let tmpColors = Array<String>();

            if(topFive != undefined) {
                topFive.map((item,key) =>{
                    tmpLabels.push(item["name"] != "" && item["name"] != undefined ? item["name"] as String : td(res[5].data.find(i => i._id == item["category"])?.name ?? item["category"]));
                    if(item["probability"] >= 0 && item["probability"] <= 25) {
                        tmpColors.push("#5B8266")
                    } else if(item["probability"] >= 26 && item["probability"] <= 50){
                        tmpColors.push("#FACD75")
                    } else if(item["probability"] >= 51 && item["probability"] <= 75){
                        tmpColors.push("#D8775A")
                    } else if(item["probability"] >= 76 && item["probability"] <= 100){
                        tmpColors.push("#993742")
                    }                    
                })
            }
            setTopConseq(topFive);
            setLabels(tmpLabels);
            setColors(tmpColors);
        });
    }
    
    useEffect(() => {
        Promise.all([
            props.requestData("consequences"),
            props.requestData("treatments"),
            props.requestData("occurences"),
            props.requestData("systemvalues"),
            props.requestData("systems"),
            props.requestData("consequencetypes")
        ]).then(async (data) => {
            await processData(data, selectedOption);
            setLoading(false);
        });
    }, [selectedOption]);

    return (
        <Card>
            <Card.Body>
                 <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                <h4 className="header-title">{t("dashboard.cards.topconsequences_name")}</h4>
                <p>{t("dashboard.cards.topconsequences_description")}</p>
                <Field
                    type="select"
                    title={ selectTitle }
                    onChange={ value => handleSelectInput(value.value) }
                    options={{
                        "choices": selectOptions
                    }}
                > 
                </Field>
                <hr />
                <div>
                    <HorizontalBar
                        data={data}
                        options={options}
                    />                
                </div>
                <Modal show={showConsequenceModal} onHide={handleCloseConsequenceModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("bowtie.consequence")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="treatments">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>{t("bowtie.name")}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control readOnly disabled value={selectedConseq != undefined ? selectedConseq["name"] : ""}/>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>{t("bowtie.event_new_name")}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control readOnly disabled value={selectedOccurences != undefined ? selectedOccurences.map(i=>i.name).join(", ") : ""}/>
                        </InputGroup>
                        <hr/>
                        <div>
                        {
                            selectedAsset != undefined ? selectedAsset.map((item,key) => { return (
                                <InputGroup className="mb-3" key={key}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{t("bowtie.event_new_systemvalue_label")}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control readOnly disabled value={item["systemvalue"]}/>
                                {
                                    item["kit"] != undefined && item["kit"] == "k" ?
                                    <Form.Control readOnly disabled value={t("system_asset.system_confidential")}/> :
                                    item["kit"] != undefined &&  item["kit"] == "i" ?
                                    <Form.Control readOnly disabled value={t("system_asset.system_integrity")}/> :
                                    item["kit"] != undefined &&  item["kit"] == "t" ?
                                    <Form.Control readOnly disabled value={t("system_asset.system_availability")}/> : ""
                                }
                                </InputGroup>
                            )}) : ""
                        }
                        </div>
                        <hr/>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>{t("bowtie.consequence_new_level")}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control readOnly disabled value={selectedConseqLevel != undefined ? td(selectedConseqLevel): ""}/>
                        </InputGroup>
                        <hr />
                            {
                                selectedConseq != undefined ? 
                                <div>
                                    <PageTable
                                        dataUrl={generateUrl("/api/cra/treatments/getAllPaged/byconsequence/") + selectedConseq["_id"]}
                                        options={{
                                                showFields: [
                                                    { label: treatmentName, field: "name"},
                                                    { label: treatmentResponsible, field: "responsibleReadable"},
                                                    { label: probabilityReduction, field: "probabilityreduction", override: dataLine => dataLine["probabilityreduction"] != undefined ? 
                                                        dataLine["probabilityreduction"] == 0 ? t("treatment.label_none"):
                                                        dataLine["probabilityreduction"] > 0 && dataLine["probabilityreduction"] < 45 ? t("treatment.label_low") : 
                                                        dataLine["probabilityreduction"] >= 45 && dataLine["probabilityreduction"] < 70 ? t("treatment.label_middel") : 
                                                        dataLine["probabilityreduction"] >= 70 && dataLine["probabilityreduction"] < 95 ? t("treatment.label_high") : 
                                                        dataLine["probabilityreduction"] >= 95 ? t("treatment.label_extremely_high") : ""
                                                        : ""
                                                    },                                                
                                                    { label: treatmentCost, field: "cost"},
                                                    { label: treatmentStatus, field: "status"},
                                                    { label: treatmentTime, field: "dateReadable"}
                                                ],
                                                pageSize: 15
                                            }}
                                        // dataBefore={(dataLine) => <p>remember this thing</p>}
                                        // dataAfter={(dataLine) => <Button onClick ={(evt) => handleShowDeleteTreatmentModal(dataLine,evt)}> Delete </Button>}
                                        // onLineClick= {handleShowEditTreatmentModal}
                                        hidePagination= {false}
                                        hidePagesize= {false}
                                        hideFilter= {false}
                                        updateTable={updateTable}
                                        hover={true} 
                                    />
                                </div> : null  
                            }
                        </Modal.Body>
                    <Modal.Footer/>
                </Modal>
            </Card.Body>
        </Card>
    );
}

export default TopConsequences;
