import React, { useState, useEffect } from "react";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Pagination from "react-js-pagination";
import { Dropdown } from "react-bootstrap";

import "./PaginatedTable.scss";

function PaginatedTable(props) {
  const [userData, setUserData] = useState<any>([]);
  const [copyUserData, setCopyUserData] = useState<any>([]);
  const [sizePerPage, setSizePerPage] = useState(8);
  const [activePage, setActivePage] = useState(1);

  const [noOfAPICall, setNoOfAPICall] = useState(0);

  const handlePageChange = (e: number) => {
    setActivePage(e);
    const currentIndex = (e - 1) * sizePerPage;

    setUserData(
      copyUserData.slice(
        currentIndex,
        parseInt(currentIndex.toString(), 10) +
          parseInt(sizePerPage.toString(), 10)
      )
    );
  };

  const handlePaginationDropdown = (e) => {
    setSizePerPage(e.currentTarget.textContent);
    setUserData(copyUserData.slice(0, e.currentTarget.textContent));
  };

  /* Fetch User Data */
  const fetchUserData = async (type) => {
    if (type === "next") {
      setNoOfAPICall(noOfAPICall + 1);
    }
    if (type === "prev") {
      setNoOfAPICall(noOfAPICall - 1);
    }

    setUserData(props.data.slice(0, sizePerPage));
    setActivePage(1);
    setCopyUserData(props.data);
  };

  /* React Hooks */
  useEffect(() => {
    fetchUserData("type");
  }, []);

  const renderShowsTotal = (start, to, total) => {
    return (
      <p style={{ color: "blue" }}>
        From {start} to {to}, totals is {total}&nbsp;&nbsp;(its a customize
        text)
      </p>
    );
  };

  const options = {
    paginationSize: 1,
    sizePerPageList: [8, 5],
    sizePerPage: 8, // which size per page you want to locate as default
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    totalSize: 100,
    paginationShowsTotal: renderShowsTotal, // Accept bool or function
    paginationPosition: "top", // default is bottom, top and both is all available
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
  };

  return (
    <div className="react-bootstrap-table container mt-4 paginated-table">
      {userData.length > 0 ? (
        <React.Fragment>
          <BootstrapTable
            dataSort={true}
            hover
            fetchInfo={{ dataTotalSize: 1000 }}
            data={userData}
            options={options}
            autoCollapse={{ sort: true, search: true, filter: true }}
            search
          >
            {props.header.map((e, i) => (
              <TableHeaderColumn
                expandable={false}
                dataField={e}
                isKey={i === 0 ? true : false}
              >
                {e}
              </TableHeaderColumn>
            ))}
          </BootstrapTable>
          <div>
            <Dropdown style={{ float: "left", paddingBottom: ".5rem" }}>
              <Dropdown.Toggle
                variant="default"
                className="border mt-3"
                id="dropdown-basic"
                style={{ boxShadow: "none" }}
              >
                {sizePerPage}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => handlePaginationDropdown(e)}>
                  100
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => handlePaginationDropdown(e)}>
                  50
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => handlePaginationDropdown(e)}>
                  8
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {noOfAPICall > 0 ? (
                <button
                  className="external-pagination-btn pagination-btn-prev"
                  onClick={(e) => fetchUserData("prev")}
                >
                  Prev
                </button>
              ) : null}
              <Pagination
                hideFirstLastPages
                pageRangeDisplayed={1}
                activePage={activePage}
                itemsCountPerPage={sizePerPage}
                totalItemsCount={copyUserData.length}
                onChange={handlePageChange}
                innerClass="mt-2 pagination"
              />
              {copyUserData.length / sizePerPage === activePage ? (
                <button
                  className="external-pagination-btn pagination-btn-next"
                  onClick={(e) => fetchUserData("next")}
                >
                  Next
                </button>
              ) : null}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <i className="fa fa-spinner fa-3x fa-spin" />
      )}
    </div>
  );
}

export default PaginatedTable;
