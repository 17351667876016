import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Button, Modal } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import moment from "moment";

import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";

import Field from "../../../../Shared/Wizard/Field/Field";
import PageTable from "../../../../Shared/PageTable/PageTable";
import CostFormatter from "../../../../Shared/NumberFormatters/CostFormatter";

import { generateUrl } from "../../../../../config";

import "react-toastify/dist/ReactToastify.css";

function Complete(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  let history = useHistory();
  const t = useTranslate();

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  let { systemid } = useParams();

  const [system, setSystem] = useState<any>();
  const [updateTable, setUpdateTable] = useState(0);
  const [confirmSignActionPlan, setConfirmSignActionPlan] = useState(false);

  const [showConfirmSignActionPlanModal, setShowConfirmSignActionPlanModal] =
    useState(false);

  const treatmentName = t("treatment.name");
  const treatmentCostOnetime = t("treatment.costonetime");
  const treatmentCost = t("treatment.cost");
  const treatmentStatus = t("treatment.status");
  const treatmentAffects = t("treatment.affects");
  const treatmentResponsible = t("treatment.responsible");
  const treatmentTime = t("treatment.time");
  const treatmentDueTime = t("treatment.duedate");

  const handleBackSystems = (evt) => {
    history.push("/system/" + system["_id"]);
    //history.goBack();
  };

  const handleSaveSystem = () => {
    axios
      .put(
        generateUrl("/api/cra/systems/signTreatmentPlan/") + system["_id"],
        {
          system: system,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchData();
        notifySuccess(t("system.toast_systemactionplansaved"));
        history.push("/system/" + system["_id"]);
      });
  };

  async function fetchData() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systems/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          if (res[0].data.accesses.includes("show_treatment_plan")) {
            let tempSystem = res[0].data;
            if (tempSystem["actionplan"] == undefined) {
              tempSystem["actionplan"] = {
                text: "",
                treatments: [],
                signedby: "",
                signedat: "",
                changedat: new Date().toISOString(),
                finishedat: "",
                finishedsign: "",
                finishedvaliduntil: "",
                validuntil: "",
              };
            }
            setSystem(res[0].data);
          } else {
            auth.signout();
          }
        })
      );
  }

  const formatDate = (date: Date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const dayWithZero = day < 10 ? `0${day}` : day;
    const monthWithZero = month < 10 ? `0${month}` : month;
    return `${dayWithZero}.${monthWithZero}.${year}`;
  };

  useEffect(() => {
    if (auth.user["userdata"] == undefined) {
      window.location.href = "/login";
    }
    fetchData();
  }, []);
  console.log(system);
  return (
    <div className="maincontent">
      {system != undefined ? (
        <div className="systems-container">
          <div className="pagetitle-container">
            <h1>{t("system_complete.title")}</h1>
            <Button
              className="newsystemBtn backbtn"
              onClick={handleBackSystems}
            >
              {
                /* t("system_description.system_back") */ t(
                  "system.go_to_assessment"
                )
              }
            </Button>
          </div>
          <Card>
            <Card.Body>
              {system != undefined ? (
                <div>
                  <h2>{t("system_complete.title")}</h2>
                  <hr />
                  <h3>{t("system_complete.plantreatments_title")}</h3>
                  <p>{t("system_complete.plantreatments_description")}</p>
                  <PageTable
                    dataUrl={
                      generateUrl(
                        "/api/cra/treatments/getAllPaged/fromActionplan/bySystem/"
                      ) + system["_id"]
                    }
                    options={{
                      showFields: [
                        {
                          label: treatmentStatus,
                          field: "status",
                          override: (dataline) => {
                            switch (dataline.status) {
                              case "open":
                                return t("treatment.open");
                              case "suggested":
                                return t("treatment.suggested");
                              case "planned":
                                return t("treatment.planned");
                              case "processing":
                                return t("treatment.processing");
                              case "closed":
                                return t("treatment.closed");
                              case "total":
                                return t("treatment.total");
                            }
                          },
                          customFilters: [
                            { value: "", label: t("treatment.removefilter") },
                            { value: "open", label: t("treatment.open") },
                            {
                              value: "suggested",
                              label: t("treatment.suggested"),
                            },
                            { value: "planned", label: t("treatment.planned") },
                            {
                              value: "processing",
                              label: t("treatment.processing"),
                            },
                            { value: "closed", label: t("treatment.closed") },
                          ],
                        },
                        { label: treatmentName, field: "name" },
                        {
                          label: treatmentResponsible,
                          field: "responsibleReadable",
                        },
                        {
                          label: treatmentCostOnetime,
                          field: "onetimecost",
                          override: (dataLine) => (
                            <CostFormatter>
                              {dataLine.onetimecost != undefined &&
                              dataLine.onetimecost != null &&
                              dataLine.onetimecost != ""
                                ? dataLine.onetimecost
                                : ""}
                            </CostFormatter>
                          ),
                        },
                        {
                          label: treatmentCost,
                          field: "cost",
                          override: (dataLine) => (
                            <CostFormatter>
                              {dataLine.cost != undefined &&
                              dataLine.cost != null &&
                              dataLine.cost != ""
                                ? dataLine.cost
                                : ""}
                            </CostFormatter>
                          ),
                        },

                        { label: treatmentAffects, field: "bowtie" },
                        { label: treatmentTime, field: "dateReadable" },
                        { label: treatmentDueTime, field: "duedate" },
                      ],
                      pageSize: 25,
                    }}
                    hoverExempt={() => true}
                    hidePagination={false}
                    hidePagesize={false}
                    hideFilter={false}
                    updateTable={updateTable}
                    hover={false}
                    complete={true}
                  />

                  <hr />
                  <h3>{t("system_complete.plansign_title")}</h3>
                  <p>{t("system_complete.plansign_description")}</p>
                  <Button
                    disabled={
                      !system?.["accesses"]?.includes("sign_treatment_plan") ??
                      true
                    }
                    className="newsystemBtn"
                    onClick={() => {
                      setShowConfirmSignActionPlanModal(true);
                      let validityDate = new Date();
                      validityDate = moment(validityDate)
                        .add(
                          settings["validityTreatmentPlanAmount"],
                          settings["validityTreatmentPlanType"]
                        )
                        .toDate();
                      setSystem({
                        ...system,
                        actionplan: {
                          ...system["actionplan"],
                          finishedsign: auth.user["userdata"]["name"],
                          finishedat: new Date().toISOString(),
                          finishedvaliduntil: validityDate.toISOString(),
                        },
                      });
                    }}
                  >
                    {system["actionplan"]["signedby"] == ""
                      ? t("system_actionplan.form.signbtn")
                      : t("system_actionplan.form.signagainbtn")}
                  </Button>
                  <p>
                    {t("system_actionplan.form.signedby") +
                      ": " +
                      (!!system["actionplan"]["finishedsign"]
                        ? system["actionplan"]["finishedsign"]
                        : t("system_actionplan.form.not_signed"))}
                  </p>
                  <p>
                    {t("system_actionplan.form.signedat") +
                      ": " +
                      (!!system["actionplan"]["finishedat"]
                        ? formatDate(
                            new Date(system["actionplan"]["finishedat"])
                          )
                        : t("system_actionplan.form.not_signed"))}
                  </p>
                  <p>
                    {t("system_actionplan.form.validuntil") +
                      ": " +
                      (!system["actionplan"]["finishedvaliduntil"]
                        ? t("system_actionplan.form.not_signed")
                        : new Date(system["actionplan"]["finishedvaliduntil"]) <
                          new Date()
                        ? t("system_actionplan.form.invalid")
                        : formatDate(
                            new Date(system["actionplan"]["finishedvaliduntil"])
                          ))}
                  </p>
                  <hr />
                  {/* <Button
                                        className="newsystemBtn"
                                        disabled={
                                            system["actionplan"]["finishedat"] == undefined || 
                                            system["actionplan"]["finishedat"] == "" || 
                                            new Date(system["actionplan"]["finishedat"]) < new Date(system["actionplan"]["changedat"]) ||
                                            (system["actionplan"]["finishedat"] != undefined && system["actionplan"]["finishedat"] != "" && new Date(system["actionplan"]["signedat"]) > new Date(system["actionplan"]["finishedat"]))
                                        }
                                        onClick={handleSaveSystem}
                                    >{t("system_complete.submit")}</Button> */}
                  <p>
                    {system["actionplan"]["finishedat"] != undefined &&
                    system["actionplan"]["finishedat"] != ""
                      ? new Date(system["actionplan"]["signedat"]) >
                        new Date(system["actionplan"]["finishedat"])
                        ? t("system_complete.pleasesignchange")
                        : ""
                      : ""}
                  </p>
                  {/* <p>{system["actionplan"]["finishedat"] == "" ? t("system_actionplan.form.pleasesignnew") : ""}</p> */}
                  <p>
                    {system["actionplan"]["finishedat"] == undefined ||
                    new Date(system["actionplan"]["finishedat"]) <
                      new Date(system["actionplan"]["changedat"])
                      ? t("system_actionplan.form.pleasesign")
                      : ""}
                  </p>
                </div>
              ) : null}
            </Card.Body>
          </Card>
          <Modal
            show={showConfirmSignActionPlanModal}
            onHide={() => setShowConfirmSignActionPlanModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {t("system_complete.plansign.confirm_title")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{t("system_complete.plansign.confirm_disclaimer01")}</p>
              <div className="widecheckbox">
                <Field
                  type="checkbox"
                  title={t("system_complete.plansign.confirm_disclaimer02")}
                  value={confirmSignActionPlan}
                  onChange={(value) => setConfirmSignActionPlan(value)}
                ></Field>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  setShowConfirmSignActionPlanModal(false);
                  fetchData();
                }}
                className="cancel posleft"
              >
                {t("system_complete.plansign.cancel")}
              </Button>
              <Button
                disabled={
                  !confirmSignActionPlan ||
                  !system?.["accesses"]?.includes("sign_treatment_plan")
                }
                onClick={() => {
                  setShowConfirmSignActionPlanModal(false);
                  setConfirmSignActionPlan(false);
                  handleSaveSystem();
                }}
              >
                {" "}
                {t("system_actionplan.form.signbtn")}{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : null}
    </div>
  );
}

export default Complete;
