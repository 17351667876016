import React, { useState, useEffect } from 'react';
import { InputGroup, Form, Button, Modal } from 'react-bootstrap';
import { useAuth } from '../../../../../Auth';
import { useAxios } from '../../../../../AxiosHandler';
import TreeOverview from '../../../TreeOverview/TreeOverview';

function TreeSelector(props) {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;

    const [show, setShow] = useState(false);

    const [localvalue, setLocalvalue] = useState(props.value != undefined && props.value != "" ?
        props.value.value != undefined ?
            props.value.value
        :
            props.value
    :
        (props.defaultValue != undefined ? props.defaultValue : ""));

    const handleChange = (value) => {
        setLocalvalue(value.value);
        if (props.onChange != undefined) {
            props.onChange(value);
        }
    }

    useEffect(() => {
        if (props.value != undefined) {
            setLocalvalue(
                props.value.value != undefined ?
                    props.value.value
                :
                    props.value
            );
        }
    }, [props.value]);

    return(
        <div className="treeselector">
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>{props.title}</InputGroup.Text>
                </InputGroup.Prepend>
                <Button disabled={props.disabled != undefined ? props.disabled : false} className="openselectorbtn" onClick={() => setShow(true)}><i className="dripicons-store" /></Button>
                <p className="selectedtext">{ props.options != undefined && props.options.list != undefined ?
                    props.options.list.find(i => i["_id"] == localvalue) != undefined ? 
                        props.options.list.find(i => i["_id"] == localvalue)[props.options != undefined && props.options.namekey != undefined ? props.options.namekey : "name"]
                    : props.options != undefined && props.options.placeholder != undefined ? props.options.placeholder : "Select object"
                : ""}</p>
            </InputGroup>
            <Modal className="treeselectormodal" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TreeOverview
                        data={props.options != undefined && props.options.list != undefined ? props.options.list : []}
                        elementName={props.options != undefined && props.options.namekey != undefined ? (org => `${org[props.options.namekey]}`) : undefined}
                        hideEditButton={true}
                        hideNewButton={true}
                        accentColor="#facd75"
                        backgroundColor="rgb(255, 250, 240)"
                        customButtons={(org) =>
                            <div className="button" onClick={evt => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                handleChange(org["_id"]);
                                setShow(false);
                            }}>
                                {props.options != undefined && props.options.selecttext != undefined ? props.options.selecttext : "Select"}
                            </div>
                        }
                    ></TreeOverview>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="cancel" onClick={() => setShow(false)}>
                    {props.options != undefined && props.options.canceltext != undefined ? props.options.canceltext : "Cancel"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default TreeSelector;