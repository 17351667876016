import * as React from "react";

import { useEffect, useState } from "react";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import { useAuth } from "../../../../Auth";
import { useAxios } from "../../../../AxiosHandler";

import Wizard from "../../Wizard/Wizard";
import WizardFieldManipulator from "../../WizardHelpers/WizardFieldManipulator";
import OtherinfoTemplate from "../../WizardTemplates/SystemValues/OtherinfoTemplate";

import { generateUrl } from "../../../../config";

function ValueOtherinfoRegTemplate(props) {
  const t = useTranslate();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const auth = useAuth();

  const GetWizardFieldByReference =
    WizardFieldManipulator.GetWizardFieldByReference;
  const GetWizardValuesByReferenceList =
    WizardFieldManipulator.GetWizardValuesByReferenceList;
  const [template, setTemplate] = useState<any>([]);

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  function getSystemvalueOptionsByKIT(kit) {
    let options = Array();
    if (settings["systemvalue" + kit + "Num"] != undefined) {
      for (
        let i = 1;
        i <= parseInt(settings["systemvalue" + kit + "Num"]);
        i++
      ) {
        options.push({
          label: settings["systemvalue" + kit + "Level" + i],
          value: i,
        });
      }
      return options;
    }
  }

  useEffect(() => {
    OtherinfoTemplate(t, axios, auth).then((data) => {
      setTemplate(data);
    });
  }, []);

  function valueToPercentage(value, maxvalue) {
    let isNull =
      value == undefined || value == null || value.length == 0
        ? 1
        : parseInt(value);
    return ((isNull - 1) / (maxvalue - 1)) * 100;
  }

  const handleWizardComplete = (data) => {
    let values = GetWizardValuesByReferenceList(data, [
      "title",
      "description",
      "reasoning",
      "confidentiality",
      "integrity",
      "createitembool",
      "reasoning_not",
    ]);

    if (values.createitembool === "") {
      notifyWarning(
        t("wizard_title.assets.otherdata.toast.question_needs_answer")
      );
      return undefined;
    }

    // create Systemvalue from data
    axios
      .post(
        generateUrl("/api/cra/systemvalues/"),
        {
          createitem: values.createitembool,
          name:
            values.title != undefined && values.title != ""
              ? values.title
              : t("system_asset.type.systemvalueotherinfo"),
          valueType: "systemvalueotherinfo",
          system: props.systemid,
          description: values.description,
          reasoning: values.reasoning,
          sensitivepo: false,
          relevant: values.createitembool,
          k:
            values.confidentiality != undefined
              ? valueToPercentage(
                  Number(values.confidentiality),
                  settings["systemvalueKNum"]
                )
              : "",
          i:
            values.integrity != undefined
              ? valueToPercentage(
                  Number(values.integrity),
                  settings["systemvalueINum"]
                )
              : "",
          t:
            props.system["availability"] != undefined
              ? props.system["availability"]
              : "",
          o: "",
          e: "",
          regdata: data,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        if (props.regCompleted != undefined) props.regCompleted(data);
      });
  };

  return template.length != 0 ? (
    <Wizard
      data={template}
      onComplete={(data) => {
        handleWizardComplete(data);
      }}
    ></Wizard>
  ) : null;
}

export default ValueOtherinfoRegTemplate;
