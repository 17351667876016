import React from 'react';
import { InputGroup } from 'react-bootstrap';
import { Checkbox, FormControlLabel } from '@material-ui/core';

function IndeterminateCheckbox(props) {
    const handleChange = (event) => {
        if (props.onChange != undefined) {
            props.onChange(event);
        }
    }

    function areNotEquals(values){       
        if(values.some(value => value == false) && values.some(value => value == true)){
            return true;
        } 
    }

    function areTrue(values){
        if(values.length == 0 || values.some(value => value == false)){
            return false;
        } else {
            return true;
        }
    }

    return(
        <div className='indeterminateCheckbox'>
            <FormControlLabel
                label={props?.title ?? ""}
                labelPlacement={props?.titlePlacement ?? "end"} //top/start/bottom/end
                control={
                    <Checkbox 
                        disabled={props.disabled}
                        checked={props.parent == true ? areTrue(props.checked) : props.checked}
                        indeterminate={props.parent == true ? areNotEquals(props.checked) : false}
                        onChange={(evt) => handleChange(evt.target.checked) }
                    />
                }
            />
            
        </div>
    );
}

export default IndeterminateCheckbox;