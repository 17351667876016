import * as React from "react";

import { Card, Button, InputGroup, Form, Accordion } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { debounce } from "lodash";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";

import "react-toastify/dist/ReactToastify.css";
import "./SettingsGeneral.scss";

import Field from "../../Shared/Wizard/Field/Field";

import { generateUrl } from "../../../config";

function SettingsGeneral(props) {
  const t = useTranslate();
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const [settings, setSettings] = useState(props.settings);
  const [settingsInherited, setSettingsInherited] = useState(
    props.settingsInherited
  );

  const setSettingsGridXName1 = (value) => {
    setSettings((settings) => ({ ...settings, gridXName1: value }));
    handleSaveSettingsGeneral({ ...settings, gridXName1: value });
  };
  const setSettingsGridXName2 = (value) => {
    setSettings((settings) => ({ ...settings, gridXName2: value }));
    handleSaveSettingsGeneral({ ...settings, gridXName2: value });
  };
  const setSettingsGridXName3 = (value) => {
    setSettings((settings) => ({ ...settings, gridXName3: value }));
    handleSaveSettingsGeneral({ ...settings, gridXName3: value });
  };
  const setSettingsGridXName4 = (value) => {
    setSettings((settings) => ({ ...settings, gridXName4: value }));
    handleSaveSettingsGeneral({ ...settings, gridXName4: value });
  };
  const setSettingsGridXName5 = (value) => {
    setSettings((settings) => ({ ...settings, gridXName5: value }));
    handleSaveSettingsGeneral({ ...settings, gridXName5: value });
  };
  const setSettingsGridXName6 = (value) => {
    setSettings((settings) => ({ ...settings, gridXName6: value }));
    handleSaveSettingsGeneral({ ...settings, gridXName6: value });
  };

  const setSettingsGridYName1 = (value) => {
    setSettings((settings) => ({ ...settings, gridYName1: value }));
    handleSaveSettingsGeneral({ ...settings, gridYName1: value });
  };
  const setSettingsGridYName2 = (value) => {
    setSettings((settings) => ({ ...settings, gridYName2: value }));
    handleSaveSettingsGeneral({ ...settings, gridYName2: value });
  };
  const setSettingsGridYName3 = (value) => {
    setSettings((settings) => ({ ...settings, gridYName3: value }));
    handleSaveSettingsGeneral({ ...settings, gridYName3: value });
  };
  const setSettingsGridYName4 = (value) => {
    setSettings((settings) => ({ ...settings, gridYName4: value }));
    handleSaveSettingsGeneral({ ...settings, gridYName4: value });
  };
  const setSettingsGridYName5 = (value) => {
    setSettings((settings) => ({ ...settings, gridYName5: value }));
    handleSaveSettingsGeneral({ ...settings, gridYName5: value });
  };
  const setSettingsGridYName6 = (value) => {
    setSettings((settings) => ({ ...settings, gridYName6: value }));
    handleSaveSettingsGeneral({ ...settings, gridYName6: value });
  };

  const setSettingsSystemvalueKNum = (value) => {
    if (value >= 3 && value <= 6) {
      setSettings((settings) => ({ ...settings, systemvalueKNum: value }));
    }
  };
  const setSettingsSystemvalueKLevel1 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueKLevel1: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueKLevel1: value });
  };
  const setSettingsSystemvalueKLevel2 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueKLevel2: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueKLevel2: value });
  };
  const setSettingsSystemvalueKLevel3 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueKLevel3: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueKLevel3: value });
  };
  const setSettingsSystemvalueKLevel4 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueKLevel4: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueKLevel4: value });
  };
  const setSettingsSystemvalueKLevel5 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueKLevel5: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueKLevel5: value });
  };
  const setSettingsSystemvalueKLevel6 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueKLevel6: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueKLevel6: value });
  };

  const setSettingsSystemvalueINum = (value) => {
    if (value >= 3 && value <= 6) {
      setSettings((settings) => ({ ...settings, systemvalueINum: value }));
    }
  };

  const setSettingsSystemvalueILevel1 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueILevel1: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueILevel1: value });
  };
  const setSettingsSystemvalueILevel2 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueILevel2: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueILevel2: value });
  };
  const setSettingsSystemvalueILevel3 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueILevel3: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueILevel3: value });
  };
  const setSettingsSystemvalueILevel4 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueILevel4: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueILevel4: value });
  };
  const setSettingsSystemvalueILevel5 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueILevel5: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueILevel5: value });
  };
  const setSettingsSystemvalueILevel6 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueILevel6: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueILevel6: value });
  };

  const setSettingsSystemvalueTNum = (value) => {
    if (value >= 3 && value <= 6) {
      setSettings((settings) => ({ ...settings, systemvalueTNum: value }));
    }
  };
  const setSettingsSystemvalueTLevel1 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueTLevel1: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueTLevel1: value });
  };
  const setSettingsSystemvalueTLevel2 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueTLevel2: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueTLevel2: value });
  };
  const setSettingsSystemvalueTLevel3 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueTLevel3: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueTLevel3: value });
  };
  const setSettingsSystemvalueTLevel4 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueTLevel4: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueTLevel4: value });
  };
  const setSettingsSystemvalueTLevel5 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueTLevel5: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueTLevel5: value });
  };
  const setSettingsSystemvalueTLevel6 = (value) => {
    setSettings((settings) => ({ ...settings, systemvalueTLevel6: value }));
    handleSaveSettingsGeneral({ ...settings, systemvalueTLevel6: value });
  };

  const setSettingsCurrencySymbolBefore = (value) => {
    setSettings((settings) => ({ ...settings, currencySymbolBefore: value }));
  };
  const setSettingsCurrencySymbolAfter = (value) => {
    setSettings((settings) => ({ ...settings, currencySymbolAfter: value }));
  };

  const setSettingsValidityTreatmentPlanType = (value) => {
    setSettings((settings) => ({
      ...settings,
      validityTreatmentPlanType: value,
    }));
    handleSaveSettingsGeneral({
      ...settings,
      validityTreatmentPlanType: value,
    });
  };

  const setSettingsValidityTreatmentPlanAmount = (value) => {
    setSettings((settings) => ({
      ...settings,
      validityTreatmentPlanAmount: value,
    }));
  };

  const setSettingsGridX = (value) => {
    if (value >= 3 && value <= 6) {
      setSettings((settings) => ({ ...settings, gridX: value }));
    }
  };

  const setSettingsGridY = (value) => {
    if (value >= 3 && value <= 6) {
      setSettings((settings) => ({ ...settings, gridY: value }));
    }
  };

  async function handleSaveSettingsGeneral(payload?: any) {
    if (!payload) {
      payload = settings;
    }
    console.log(payload);
    axios
      .put(
        generateUrl("/api/cra/settings/byorg/") +
          auth.user["userdata"]["unit"][0],
        payload,
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        if (response.status == 200) {
          notifySuccess(t("settings.toast_savesuccess"));
        }
        if (props.onSettingsGeneralChanged != undefined) {
          props.onSettingsGeneralChanged();
        }
      });
  }

  useEffect(() => {
    setSettings(props.settings);
    setSettingsInherited(props.settingsInherited);
  }, [props]);

  const debouncedSave = useRef(
    debounce((val) => {
      handleSaveSettingsGeneral(val);
    }, 1000)
  ).current;

  return (
    <div>
      <Card>
        <Accordion.Toggle className="clickable" as={Card.Header} eventKey="0">
          <h4 className="header-title">{t("settings.risk_matrix")}</h4>
          <p>{t("settings.risk_matrix_info_top")}</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <p>{t("settings.risk_matrix_info")}</p>
            <div className="settingsarea">
              <div className="row">
                <div className="col-md-4">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {t("settings.consequence_title")}:
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className="d-flex w-100">
                      <Form.Control
                        type="number"
                        min="3"
                        max="6"
                        disabled={
                          !props.userHasEditRights ||
                          (settings["gridX"] == undefined &&
                            props.org != undefined &&
                            props.org.parent != "")
                        }
                        onChange={(evt) => setSettingsGridX(evt.target.value)}
                        value={
                          settings["gridX"] != undefined
                            ? settings["gridX"]
                            : settingsInherited != undefined
                            ? settingsInherited["gridX"]
                            : ""
                        }
                        onBlur={(evt) =>
                          debouncedSave({
                            ...settings,
                            gridX: evt.target.value,
                          })
                        }
                      />
                      {props.org != undefined && props.org.parent != "" ? (
                        <>
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              console.log(settings["gridX"]);

                              setSettings({
                                ...settings,
                                gridX: settings["gridX"],
                              });

                              handleSaveSettingsGeneral({
                                ...settings,
                                gridX: settings["gridX"],
                              });
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn"
                            style={{ marginBottom: "20px", marginLeft: 10 }}
                            onClick={() => {
                              setSettings({
                                ...settings,
                                gridX: settingsInherited["gridX"],
                              });

                              handleSaveSettingsGeneral({
                                ...settings,
                                gridX: settingsInherited["gridX"],
                              });
                            }}
                          >
                            {t("settings.btn_stopoverride")}
                          </Button>
                        </>
                      ) : null}
                    </div>
                  </InputGroup>
                  <p>{t("settings.consequence_name")}</p>

                  <div className="flexfields">
                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_1") + ":"}
                          type="textlanguage"
                          value={
                            settings["gridXName1"] != undefined
                              ? settings["gridXName1"]
                              : settingsInherited != undefined
                              ? settingsInherited["gridXName1"]
                              : ""
                          }
                          onChange={(value) => setSettingsGridXName1(value)}
                          options={{
                            category: "settings.general.riskmatrixgridx",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["gridXName1"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["gridXName1"] == undefined) {
                                setSettings({ ...settings, gridXName1: "" });
                                handleSaveSettingsGeneral({
                                  ...settings,
                                  gridXName1: "",
                                });
                              } else {
                                setSettings({
                                  ...settings,
                                  gridXName1: undefined,
                                });
                                handleSaveSettingsGeneral({
                                  ...settings,
                                  gridXName1: undefined,
                                });
                              }
                            }}
                          >
                            {settings["gridXName1"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_2") + ":"}
                          type="textlanguage"
                          value={
                            settings["gridXName2"] != undefined
                              ? settings["gridXName2"]
                              : settingsInherited != undefined
                              ? settingsInherited["gridXName2"]
                              : ""
                          }
                          onChange={(value) => setSettingsGridXName2(value)}
                          options={{
                            category: "settings.general.riskmatrixgridx",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["gridXName2"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["gridXName2"] == undefined)
                                setSettings({ ...settings, gridXName2: "" });
                              else
                                setSettings({
                                  ...settings,
                                  gridXName2: undefined,
                                });
                            }}
                          >
                            {settings["gridXName2"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_3") + ":"}
                          type="textlanguage"
                          value={
                            settings["gridXName3"] != undefined
                              ? settings["gridXName3"]
                              : settingsInherited != undefined
                              ? settingsInherited["gridXName3"]
                              : ""
                          }
                          onChange={(value) => setSettingsGridXName3(value)}
                          options={{
                            category: "settings.general.riskmatrixgridx",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["gridXName3"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["gridXName3"] == undefined)
                                setSettings({ ...settings, gridXName3: "" });
                              else
                                setSettings({
                                  ...settings,
                                  gridXName3: undefined,
                                });
                            }}
                          >
                            {settings["gridXName3"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_4") + ":"}
                          type="textlanguage"
                          value={
                            settings["gridXName4"] != undefined
                              ? settings["gridXName4"]
                              : settingsInherited != undefined
                              ? settingsInherited["gridXName4"]
                              : ""
                          }
                          onChange={(value) => setSettingsGridXName4(value)}
                          options={{
                            category: "settings.general.riskmatrixgridx",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["gridXName4"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["gridXName4"] == undefined)
                                setSettings({ ...settings, gridXName4: "" });
                              else
                                setSettings({
                                  ...settings,
                                  gridXName4: undefined,
                                });
                            }}
                          >
                            {settings["gridXName4"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_5") + ":"}
                          type="textlanguage"
                          value={
                            settings["gridXName5"] != undefined
                              ? settings["gridXName5"]
                              : settingsInherited != undefined
                              ? settingsInherited["gridXName5"]
                              : ""
                          }
                          onChange={(value) => setSettingsGridXName5(value)}
                          options={{
                            category: "settings.general.riskmatrixgridx",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["gridXName5"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["gridXName5"] == undefined)
                                setSettings({ ...settings, gridXName5: "" });
                              else
                                setSettings({
                                  ...settings,
                                  gridXName5: undefined,
                                });
                            }}
                          >
                            {settings["gridXName5"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_6") + ":"}
                          type="textlanguage"
                          value={
                            settings["gridXName6"] != undefined
                              ? settings["gridXName6"]
                              : settingsInherited != undefined
                              ? settingsInherited["gridXName6"]
                              : ""
                          }
                          onChange={(value) => setSettingsGridXName6(value)}
                          options={{
                            category: "settings.general.riskmatrixgridx",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["gridXName6"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["gridXName6"] == undefined)
                                setSettings({ ...settings, gridXName6: "" });
                              else
                                setSettings({
                                  ...settings,
                                  gridXName6: undefined,
                                });
                            }}
                          >
                            {settings["gridXName6"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {t("settings.probability_title")}:
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className="d-flex w-100">
                      <Form.Control
                        type="number"
                        min="3"
                        max="6"
                        onChange={(evt) => setSettingsGridY(evt.target.value)}
                        value={
                          settings["gridY"] != undefined
                            ? settings["gridY"]
                            : settingsInherited != undefined
                            ? settingsInherited["gridY"]
                            : ""
                        }
                        onBlur={(evt) =>
                          debouncedSave({
                            ...settings,
                            gridY: evt.target.value,
                          })
                        }
                      />
                      {props.org != undefined && props.org.parent != "" ? (
                        <>
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              console.log(settings["gridY"]);

                              setSettings({
                                ...settings,
                                gridY: settings["gridY"],
                              });

                              handleSaveSettingsGeneral({
                                ...settings,
                                gridY: settings["gridY"],
                              });
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn"
                            style={{ marginBottom: "20px", marginLeft: 10 }}
                            onClick={() => {
                              setSettings({
                                ...settings,
                                gridY: settingsInherited["gridY"],
                              });

                              handleSaveSettingsGeneral({
                                ...settings,
                                gridY: settingsInherited["gridY"],
                              });
                            }}
                          >
                            {t("settings.btn_stopoverride")}
                          </Button>
                        </>
                      ) : null}
                    </div>
                  </InputGroup>
                  <p>{t("settings.probability_name")}</p>
                  <div className="flexfields">
                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_1") + ":"}
                          type="textlanguage"
                          value={
                            settings["gridYName1"] != undefined
                              ? settings["gridYName1"]
                              : settingsInherited != undefined
                              ? settingsInherited["gridYName1"]
                              : ""
                          }
                          onChange={(value) => setSettingsGridYName1(value)}
                          options={{
                            category: "settings.general.riskmatrixgridy",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["gridYName1"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["gridYName1"] == undefined)
                                setSettings({ ...settings, gridYName1: "" });
                              else
                                setSettings({
                                  ...settings,
                                  gridYName1: undefined,
                                });
                            }}
                          >
                            {settings["gridYName1"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_2") + ":"}
                          type="textlanguage"
                          value={
                            settings["gridYName2"] != undefined
                              ? settings["gridYName2"]
                              : settingsInherited != undefined
                              ? settingsInherited["gridYName2"]
                              : ""
                          }
                          onChange={(value) => setSettingsGridYName2(value)}
                          options={{
                            category: "settings.general.riskmatrixgridy",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["gridYName2"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["gridYName2"] == undefined)
                                setSettings({ ...settings, gridYName2: "" });
                              else
                                setSettings({
                                  ...settings,
                                  gridYName2: undefined,
                                });
                            }}
                          >
                            {settings["gridYName2"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_3") + ":"}
                          type="textlanguage"
                          value={
                            settings["gridYName3"] != undefined
                              ? settings["gridYName3"]
                              : settingsInherited != undefined
                              ? settingsInherited["gridYName3"]
                              : ""
                          }
                          onChange={(value) => setSettingsGridYName3(value)}
                          options={{
                            category: "settings.general.riskmatrixgridy",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["gridYName3"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["gridYName3"] == undefined)
                                setSettings({ ...settings, gridYName3: "" });
                              else
                                setSettings({
                                  ...settings,
                                  gridYName3: undefined,
                                });
                            }}
                          >
                            {settings["gridYName3"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_4") + ":"}
                          type="textlanguage"
                          value={
                            settings["gridYName4"] != undefined
                              ? settings["gridYName4"]
                              : settingsInherited != undefined
                              ? settingsInherited["gridYName4"]
                              : ""
                          }
                          onChange={(value) => setSettingsGridYName4(value)}
                          options={{
                            category: "settings.general.riskmatrixgridy",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["gridYName4"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["gridYName4"] == undefined)
                                setSettings({ ...settings, gridYName4: "" });
                              else
                                setSettings({
                                  ...settings,
                                  gridYName4: undefined,
                                });
                            }}
                          >
                            {settings["gridYName4"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_5") + ":"}
                          type="textlanguage"
                          value={
                            settings["gridYName5"] != undefined
                              ? settings["gridYName5"]
                              : settingsInherited != undefined
                              ? settingsInherited["gridYName5"]
                              : ""
                          }
                          onChange={(value) => setSettingsGridYName5(value)}
                          options={{
                            category: "settings.general.riskmatrixgridy",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["gridYName5"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["gridYName5"] == undefined)
                                setSettings({ ...settings, gridYName5: "" });
                              else
                                setSettings({
                                  ...settings,
                                  gridYName5: undefined,
                                });
                            }}
                          >
                            {settings["gridYName5"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_6") + ":"}
                          type="textlanguage"
                          value={
                            settings["gridYName6"] != undefined
                              ? settings["gridYName6"]
                              : settingsInherited != undefined
                              ? settingsInherited["gridYName6"]
                              : ""
                          }
                          onChange={(value) => setSettingsGridYName6(value)}
                          options={{
                            category: "settings.general.riskmatrixgridy",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["gridYName6"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["gridYName6"] == undefined)
                                setSettings({ ...settings, gridYName6: "" });
                              else
                                setSettings({
                                  ...settings,
                                  gridYName6: undefined,
                                });
                            }}
                          >
                            {settings["gridYName6"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle className="clickable" as={Card.Header} eventKey="1">
          <h4 className="header-title">{t("settings.systemvalue_levels")}</h4>
          <p>{t("settings.systemvalues_info")}</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <div className="settingsarea">
              <div className="row">
                <div className="col-md-4">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {t("settings.confidentiality_title")}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className="d-flex w-100">
                      <Form.Control
                        type="number"
                        min="3"
                        max="6"
                        disabled={
                          !props.userHasEditRights ||
                          (settings["systemvalueKNum"] == undefined &&
                            props.org != undefined &&
                            props.org.parent != "")
                        }
                        onChange={(evt) =>
                          setSettingsSystemvalueKNum(evt.target.value)
                        }
                        value={
                          settings["systemvalueKNum"] != undefined
                            ? settings["systemvalueKNum"]
                            : settingsInherited != undefined
                            ? settingsInherited["systemvalueKNum"]
                            : ""
                        }
                        onBlur={(evt) =>
                          debouncedSave({
                            ...settings,
                            systemvalueKNum: evt.target.value,
                          })
                        }
                      />
                      {props.org != undefined && props.org.parent != "" ? (
                        <Button
                          disabled={!props.userHasEditRights}
                          className="inherittogglebtn savebtn"
                          style={{ marginBottom: "20px" }}
                          onClick={() => {
                            if (settings["systemvalueKNum"] == undefined)
                              setSettings({
                                ...settings,
                                systemvalueKNum:
                                  settingsInherited["systemvalueKNum"],
                              });
                            else
                              setSettings({
                                ...settings,
                                systemvalueKNum: undefined,
                              });
                          }}
                        >
                          {settings["systemvalueKNum"] == undefined
                            ? t("settings.btn_startoverride")
                            : t("settings.btn_stopoverride")}
                        </Button>
                      ) : null}
                    </div>
                  </InputGroup>
                  <hr />

                  <div className="flexfields">
                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_1") + ":"}
                          type="textlanguage"
                          value={
                            settings["systemvalueKLevel1"] != undefined
                              ? settings["systemvalueKLevel1"]
                              : settingsInherited != undefined
                              ? settingsInherited["systemvalueKLevel1"]
                              : ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueKLevel1(value)
                          }
                          options={{
                            category: "settings.general.confidentiality",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueKLevel1"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>

                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueKLevel1"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueKLevel1: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueKLevel1: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueKLevel1"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_2") + ":"}
                          type="textlanguage"
                          value={
                            settings["systemvalueKLevel2"] != undefined
                              ? settings["systemvalueKLevel2"]
                              : settingsInherited != undefined
                              ? settingsInherited["systemvalueKLevel2"]
                              : ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueKLevel2(value)
                          }
                          options={{
                            category: "settings.general.confidentiality",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueKLevel2"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueKLevel2"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueKLevel2: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueKLevel2: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueKLevel2"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_3") + ":"}
                          type="textlanguage"
                          value={
                            settings["systemvalueKLevel3"] != undefined
                              ? settings["systemvalueKLevel3"]
                              : settingsInherited != undefined
                              ? settingsInherited["systemvalueKLevel3"]
                              : ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueKLevel3(value)
                          }
                          options={{
                            category: "settings.general.confidentiality",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueKLevel3"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueKLevel3"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueKLevel3: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueKLevel3: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueKLevel3"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_4") + ":"}
                          type="textlanguage"
                          value={
                            settings["systemvalueKLevel4"] != undefined
                              ? settings["systemvalueKLevel4"]
                              : settingsInherited != undefined
                              ? settingsInherited["systemvalueKLevel4"]
                              : ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueKLevel4(value)
                          }
                          options={{
                            category: "settings.general.confidentiality",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueKLevel4"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueKLevel4"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueKLevel4: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueKLevel4: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueKLevel4"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_5") + ":"}
                          type="textlanguage"
                          //value={settings["systemvalueKLevel5"] != undefined ? settings["systemvalueKLevel5"] : settingsInherited != undefined ? settingsInherited["systemvalueKLevel5"] : ""}
                          value={
                            settings["systemvalueKLevel5"] != undefined
                              ? settings["systemvalueKLevel5"]
                              : settingsInherited?.["systemvalueKLevel5"] ?? ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueKLevel5(value)
                          }
                          options={{
                            category: "settings.general.confidentiality",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueKLevel5"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup className="mb-2">
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueKLevel5"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueKLevel5: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueKLevel5: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueKLevel5"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_6") + ":"}
                          type="textlanguage"
                          //value={settings["systemvalueKLevel6"] != undefined ? settings["systemvalueKLevel6"] : settingsInherited != undefined ? settingsInherited["systemvalueKLevel6"] : ""}
                          value={
                            settings["systemvalueKLevel6"] != undefined
                              ? settings["systemvalueKLevel6"]
                              : settingsInherited?.["systemvalueKLevel6"] ?? ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueKLevel6(value)
                          }
                          options={{
                            category: "settings.general.confidentiality",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueKLevel6"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueKLevel6"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueKLevel6: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueKLevel6: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueKLevel6"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {t("settings.integrity_title")}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className="d-flex w-100">
                      <Form.Control
                        type="number"
                        min="3"
                        max="6"
                        disabled={
                          !props.userHasEditRights ||
                          (settings["systemvalueINum"] == undefined &&
                            props.org != undefined &&
                            props.org.parent != "")
                        }
                        onChange={(evt) =>
                          setSettingsSystemvalueINum(evt.target.value)
                        }
                        value={
                          settings["systemvalueINum"] != undefined
                            ? settings["systemvalueINum"]
                            : settingsInherited != undefined
                            ? settingsInherited["systemvalueINum"]
                            : ""
                        }
                        onBlur={(evt) =>
                          debouncedSave({
                            ...settings,
                            systemvalueINum: evt.target.value,
                          })
                        }
                      />
                      {props.org != undefined && props.org.parent != "" ? (
                        <Button
                          disabled={!props.userHasEditRights}
                          className="inherittogglebtn savebtn"
                          style={{ marginBottom: "20px" }}
                          onClick={() => {
                            if (settings["systemvalueINum"] == undefined)
                              setSettings({
                                ...settings,
                                systemvalueINum:
                                  settingsInherited["systemvalueINum"],
                              });
                            else
                              setSettings({
                                ...settings,
                                systemvalueINum: undefined,
                              });
                          }}
                        >
                          {settings["systemvalueINum"] == undefined
                            ? t("settings.btn_startoverride")
                            : t("settings.btn_stopoverride")}
                        </Button>
                      ) : null}
                    </div>
                  </InputGroup>
                  <hr />
                  <div className="flexfields">
                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_1") + ":"}
                          type="textlanguage"
                          value={
                            settings["systemvalueILevel1"] != undefined
                              ? settings["systemvalueILevel1"]
                              : settingsInherited != undefined
                              ? settingsInherited["systemvalueILevel1"]
                              : ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueILevel1(value)
                          }
                          options={{
                            category: "settings.general.integrity",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueILevel1"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueILevel1"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueILevel1: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueILevel1: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueILevel1"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_2") + ":"}
                          type="textlanguage"
                          value={
                            settings["systemvalueILevel2"] != undefined
                              ? settings["systemvalueILevel2"]
                              : settingsInherited != undefined
                              ? settingsInherited["systemvalueILevel2"]
                              : ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueILevel2(value)
                          }
                          options={{
                            category: "settings.general.integrity",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueILevel2"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup className="mb-2">
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueILevel2"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueILevel2: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueILevel2: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueILevel2"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_3") + ":"}
                          type="textlanguage"
                          value={
                            settings["systemvalueILevel3"] != undefined
                              ? settings["systemvalueILevel3"]
                              : settingsInherited != undefined
                              ? settingsInherited["systemvalueILevel3"]
                              : ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueILevel3(value)
                          }
                          options={{
                            category: "settings.general.integrity",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueILevel3"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueILevel3"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueILevel3: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueILevel3: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueILevel3"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_4") + ":"}
                          type="textlanguage"
                          value={
                            settings["systemvalueILevel4"] != undefined
                              ? settings["systemvalueILevel4"]
                              : settingsInherited != undefined
                              ? settingsInherited["systemvalueILevel4"]
                              : ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueILevel4(value)
                          }
                          options={{
                            category: "settings.general.integrity",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueILevel4"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueILevel4"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueILevel4: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueILevel4: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueILevel4"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_5") + ":"}
                          type="textlanguage"
                          //value={settings["systemvalueILevel5"] != undefined ? settings["systemvalueILevel5"] : settingsInherited != undefined ? settingsInherited["systemvalueILevel5"] : ""}
                          value={
                            settings["systemvalueILevel5"] != undefined
                              ? settings["systemvalueILevel5"]
                              : settingsInherited?.["systemvalueILevel5"] ?? ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueILevel5(value)
                          }
                          options={{
                            category: "settings.general.integrity",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueILevel5"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueILevel5"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueILevel5: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueILevel5: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueILevel5"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_6") + ":"}
                          type="textlanguage"
                          //value={settings["systemvalueILevel6"] != undefined ? settings["systemvalueILevel6"] : settingsInherited != undefined ? settingsInherited["systemvalueILevel6"] : ""}
                          value={
                            settings["systemvalueILevel6"] != undefined
                              ? settings["systemvalueILevel6"]
                              : settingsInherited?.["systemvalueILevel6"] ?? ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueILevel6(value)
                          }
                          options={{
                            category: "settings.general.integrity",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueILevel6"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueILevel6"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueILevel6: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueILevel6: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueILevel6"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {t("settings.accessibility_title")}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className="d-flex w-100">
                      <Form.Control
                        type="number"
                        min="3"
                        max="6"
                        disabled={
                          !props.userHasEditRights ||
                          settings["systemvalueTNum"] == undefined
                        }
                        onChange={(evt) =>
                          setSettingsSystemvalueTNum(evt.target.value)
                        }
                        value={
                          settings["systemvalueTNum"] != undefined
                            ? settings["systemvalueTNum"]
                            : settingsInherited != undefined
                            ? settingsInherited["systemvalueTNum"]
                            : ""
                        }
                        onBlur={(evt) =>
                          debouncedSave({
                            ...settings,
                            systemvalueTNum: evt.target.value,
                          })
                        }
                      />
                      {props.org != undefined && props.org.parent != "" ? (
                        <Button
                          disabled={!props.userHasEditRights}
                          className="inherittogglebtn savebtn"
                          style={{ marginBottom: "20px" }}
                          onClick={() => {
                            if (settings["systemvalueTNum"] == undefined)
                              setSettings({
                                ...settings,
                                systemvalueTNum:
                                  settingsInherited["systemvalueTNum"],
                              });
                            else
                              setSettings({
                                ...settings,
                                systemvalueTNum: undefined,
                              });
                          }}
                        >
                          {settings["systemvalueTNum"] == undefined
                            ? t("settings.btn_startoverride")
                            : t("settings.btn_stopoverride")}
                        </Button>
                      ) : null}
                    </div>
                  </InputGroup>
                  <hr />
                  <div className="flexfields">
                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_1") + ":"}
                          type="textlanguage"
                          value={
                            settings["systemvalueTLevel1"] != undefined
                              ? settings["systemvalueTLevel1"]
                              : settingsInherited != undefined
                              ? settingsInherited["systemvalueTLevel1"]
                              : ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueTLevel1(value)
                          }
                          options={{
                            category: "settings.general.accessibility",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueTLevel1"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueTLevel1"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueTLevel1: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueTLevel1: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueTLevel1"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_2") + ":"}
                          type="textlanguage"
                          value={
                            settings["systemvalueTLevel2"] != undefined
                              ? settings["systemvalueTLevel2"]
                              : settingsInherited != undefined
                              ? settingsInherited["systemvalueTLevel2"]
                              : ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueTLevel2(value)
                          }
                          options={{
                            category: "settings.general.accessibility",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueTLevel2"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueTLevel2"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueTLevel2: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueTLevel2: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueTLevel2"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_3") + ":"}
                          type="textlanguage"
                          value={
                            settings["systemvalueTLevel3"] != undefined
                              ? settings["systemvalueTLevel3"]
                              : settingsInherited != undefined
                              ? settingsInherited["systemvalueTLevel3"]
                              : ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueTLevel3(value)
                          }
                          options={{
                            category: "settings.general.accessibility",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueTLevel3"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueTLevel3"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueTLevel3: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueTLevel3: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueTLevel3"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_4") + ":"}
                          type="textlanguage"
                          value={
                            settings["systemvalueTLevel4"] != undefined
                              ? settings["systemvalueTLevel4"]
                              : settingsInherited != undefined
                              ? settingsInherited["systemvalueTLevel4"]
                              : ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueTLevel4(value)
                          }
                          options={{
                            category: "settings.general.accessibility",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueTLevel4"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueTLevel4"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueTLevel4: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueTLevel4: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueTLevel4"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_5") + ":"}
                          type="textlanguage"
                          //value={settings["systemvalueTLevel5"] != undefined ? settings["systemvalueTLevel5"] : settingsInherited != undefined ? settingsInherited["systemvalueTLevel5"] : ""}
                          value={
                            settings["systemvalueTLevel5"] != undefined
                              ? settings["systemvalueTLevel5"]
                              : settingsInherited?.["systemvalueTLevel5"] ?? ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueTLevel5(value)
                          }
                          options={{
                            category: "settings.general.accessibility",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueTLevel5"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueTLevel5"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueTLevel5: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueTLevel5: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueTLevel5"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-2">
                      <div className="col-md-9">
                        <Field
                          title={t("settings.level_6") + ":"}
                          type="textlanguage"
                          //value={settings["systemvalueTLevel6"] != undefined ? settings["systemvalueTLevel6"] : settingsInherited != undefined ? settingsInherited["systemvalueTLevel6"] : ""}
                          value={
                            settings["systemvalueTLevel6"] != undefined
                              ? settings["systemvalueTLevel6"]
                              : settingsInherited?.["systemvalueTLevel6"] ?? ""
                          }
                          onChange={(value) =>
                            setSettingsSystemvalueTLevel6(value)
                          }
                          options={{
                            category: "settings.general.accessibility",
                          }}
                          disabled={
                            !props.userHasEditRights ||
                            (settings["systemvalueTLevel6"] == undefined &&
                              props.org != undefined &&
                              props.org.parent != "")
                          }
                        ></Field>
                      </div>
                      <InputGroup>
                        {props.org != undefined && props.org.parent != "" ? (
                          <Button
                            disabled={!props.userHasEditRights}
                            className="inherittogglebtn savebtn"
                            style={{ marginBottom: "20px" }}
                            onClick={() => {
                              if (settings["systemvalueTLevel6"] == undefined)
                                setSettings({
                                  ...settings,
                                  systemvalueTLevel6: "",
                                });
                              else
                                setSettings({
                                  ...settings,
                                  systemvalueTLevel6: undefined,
                                });
                            }}
                          >
                            {settings["systemvalueTLevel6"] == undefined
                              ? t("settings.btn_startoverride")
                              : t("settings.btn_stopoverride")}
                          </Button>
                        ) : null}
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle className="clickable" as={Card.Header} eventKey="5">
          <h4 className="header-title">{t("settings.internationalisation")}</h4>
          <p>{t("settings.currencybeforeafter_info")}</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="5">
          <Card.Body>
            <div className="settingsarea">
              <div className="row">
                <div className="col-md-6">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {t("settings.currency_symbol_before")}:
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className="d-flex w-100">
                      <Form.Control
                        disabled={
                          !props.userHasEditRights ||
                          settings["currencySymbolBefore"] == undefined
                        }
                        onChange={(evt) =>
                          setSettingsCurrencySymbolBefore(evt.target.value)
                        }
                        value={
                          settings["currencySymbolBefore"] != undefined
                            ? settings["currencySymbolBefore"]
                            : settingsInherited != undefined
                            ? settingsInherited["currencySymbolBefore"]
                            : ""
                        }
                        onBlur={(evt) =>
                          debouncedSave({
                            ...settings,
                            currencySymbolBefore: evt.target.value,
                          })
                        }
                      />
                      {props.org != undefined && props.org.parent != "" ? (
                        <Button
                          disabled={!props.userHasEditRights}
                          className="inherittogglebtn savebtn"
                          style={{ marginBottom: "20px" }}
                          onClick={() => {
                            if (settings["currencySymbolBefore"] == undefined)
                              setSettings({
                                ...settings,
                                currencySymbolBefore:
                                  settingsInherited["currencySymbolBefore"],
                              });
                            else
                              setSettings({
                                ...settings,
                                currencySymbolBefore: undefined,
                              });
                          }}
                        >
                          {settings["currencySymbolBefore"] == undefined
                            ? t("settings.btn_startoverride")
                            : t("settings.btn_stopoverride")}
                        </Button>
                      ) : null}
                    </div>
                  </InputGroup>
                </div>
                <div className="col-md-6">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {t("settings.currency_symbol_after")}:
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className="d-flex w-100">
                      <Form.Control
                        disabled={
                          !props.userHasEditRights ||
                          settings["currencySymbolAfter"] == undefined
                        }
                        onChange={(evt) =>
                          setSettingsCurrencySymbolAfter(evt.target.value)
                        }
                        value={
                          settings["currencySymbolAfter"] != undefined
                            ? settings["currencySymbolAfter"]
                            : settingsInherited != undefined
                            ? settingsInherited["currencySymbolAfter"]
                            : ""
                        }
                        onBlur={(evt) =>
                          debouncedSave({
                            ...settings,
                            currencySymbolAfter: evt.target.value,
                          })
                        }
                      />
                      {props.org != undefined && props.org.parent != "" ? (
                        <Button
                          disabled={!props.userHasEditRights}
                          className="inherittogglebtn savebtn"
                          style={{ marginBottom: "20px" }}
                          onClick={() => {
                            if (settings["currencySymbolAfter"] == undefined)
                              setSettings({
                                ...settings,
                                currencySymbolAfter:
                                  settingsInherited["currencySymbolAfter"],
                              });
                            else
                              setSettings({
                                ...settings,
                                currencySymbolAfter: undefined,
                              });
                          }}
                        >
                          {settings["currencySymbolAfter"] == undefined
                            ? t("settings.btn_startoverride")
                            : t("settings.btn_stopoverride")}
                        </Button>
                      ) : null}
                    </div>
                  </InputGroup>
                </div>
              </div>
              <p>
                {settings != undefined && settingsInherited != undefined
                  ? t("settings.currency_symbol_sample") +
                    ": " +
                    (settings["currencySymbolBefore"] != undefined
                      ? settings["currencySymbolBefore"]
                      : settingsInherited["currencySymbolBefore"]) +
                    "499" +
                    (settings["currencySymbolAfter"] != undefined
                      ? settings["currencySymbolAfter"]
                      : settingsInherited["currencySymbolAfter"])
                  : ""}
              </p>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle className="clickable" as={Card.Header} eventKey="6">
          <h4 className="header-title">{t("settings.validitytimes.title")}</h4>
          <p>{t("settings.validitytimes.info")}</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="6">
          <Card.Body>
            <div className="settingsarea">
              <div className="row">
                <h3
                  style={{
                    width: "100%",
                    marginLeft: "20px",
                    fontSize: "18px",
                  }}
                >
                  {t("settings.validitytimes.treatmentplan_title")}
                </h3>
                <div className="col-md-4">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {t("settings.validitytimes.treatmentplan_type")}:
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className="d-flex w-100">
                      <Form.Control
                        as="select"
                        disabled={
                          !props.userHasEditRights ||
                          settings["validityTreatmentPlanType"] == undefined
                        }
                        onChange={(evt) =>
                          setSettingsValidityTreatmentPlanType(
                            (evt.target as HTMLSelectElement).value
                          )
                        }
                        value={
                          settings["validityTreatmentPlanType"] != undefined
                            ? settings["validityTreatmentPlanType"]
                            : settingsInherited != undefined
                            ? settingsInherited["validityTreatmentPlanType"]
                            : ""
                        }
                      >
                        <option value="hours">
                          {t(
                            "settings.validitytimes.treatmentplan_option_hours"
                          )}
                        </option>
                        <option value="days">
                          {t(
                            "settings.validitytimes.treatmentplan_option_days"
                          )}
                        </option>
                        <option value="months">
                          {t(
                            "settings.validitytimes.treatmentplan_option_months"
                          )}
                        </option>
                        <option value="years">
                          {t(
                            "settings.validitytimes.treatmentplan_option_years"
                          )}
                        </option>
                      </Form.Control>
                      {props.org != undefined && props.org.parent != "" ? (
                        <Button
                          disabled={!props.userHasEditRights}
                          className="inherittogglebtn savebtn"
                          style={{ marginBottom: "20px" }}
                          onClick={() => {
                            if (
                              settings["validityTreatmentPlanType"] == undefined
                            )
                              setSettings({
                                ...settings,
                                validityTreatmentPlanType:
                                  settingsInherited[
                                    "validityTreatmentPlanType"
                                  ],
                              });
                            else
                              setSettings({
                                ...settings,
                                validityTreatmentPlanType: undefined,
                              });
                          }}
                        >
                          {settings["validityTreatmentPlanType"] == undefined
                            ? t("settings.btn_startoverride")
                            : t("settings.btn_stopoverride")}
                        </Button>
                      ) : null}
                    </div>
                  </InputGroup>
                </div>
                <div className="col-md-4">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {t("settings.validitytimes.treatmentplan_amount")}:
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className="d-flex w-100">
                      <Form.Control
                        type="number"
                        min="0"
                        max="100"
                        disabled={
                          !props.userHasEditRights ||
                          settings["validityTreatmentPlanAmount"] == undefined
                        }
                        onChange={(evt) =>
                          setSettingsValidityTreatmentPlanAmount(
                            evt.target.value
                          )
                        }
                        value={
                          settings["validityTreatmentPlanAmount"] != undefined
                            ? settings["validityTreatmentPlanAmount"]
                            : settingsInherited != undefined
                            ? settingsInherited["validityTreatmentPlanAmount"]
                            : ""
                        }
                        onBlur={(evt) =>
                          debouncedSave({
                            ...settings,
                            validityTreatmentPlanAmount: evt.target.value,
                          })
                        }
                      />
                      {props.org != undefined && props.org.parent != "" ? (
                        <Button
                          disabled={!props.userHasEditRights}
                          className="inherittogglebtn savebtn"
                          style={{ marginBottom: "20px" }}
                          onClick={() => {
                            if (
                              settings["validityTreatmentPlanAmount"] ==
                              undefined
                            )
                              setSettings({
                                ...settings,
                                validityTreatmentPlanAmount:
                                  settingsInherited[
                                    "validityTreatmentPlanAmount"
                                  ],
                              });
                            else
                              setSettings({
                                ...settings,
                                validityTreatmentPlanAmount: undefined,
                              });
                          }}
                        >
                          {settings["validityTreatmentPlanAmount"] == undefined
                            ? t("settings.btn_startoverride")
                            : t("settings.btn_stopoverride")}
                        </Button>
                      ) : null}
                    </div>
                  </InputGroup>
                </div>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* <Button className="savesettingsBtn savebtn" style={{ marginBottom: "20px" }} disabled={(!props.userHasEditRights) || props.inheriting} onClick={handleSaveSettingsGeneral}>{props.inheriting ? t("settings.saveinherited") : t("settings.save")}</Button> */}
    </div>
  );
}

export default SettingsGeneral;
