import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Card, Button, Form, InputGroup } from "react-bootstrap";
import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";
import LocaleHandler from "../../../LocaleHandler";
import "react-toastify/dist/ReactToastify.css";
import "./MyAccount.scss";
import SiteAssistant from "../../Shared/SiteAssistant/SiteAssistant";
import DiriFaceDocked from "../../../Assets/Images/diriassistant/diri.svg";
import { useDynamicLanguage } from "../../../DynamicLanguageProvider";
import Field from "../../Shared/Wizard/Field/Field";
import { generateUrl } from "../../../config";

function MyAccount(props) {
  const auth = useAuth();
  const t = useTranslate();
  const setLanguage = useDynamicLanguage().setLanguage;
  const td = useDynamicLanguage().td;
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const { setLocale } = React.useContext(LocaleHandler);
  const history = useHistory();

  let localStorageUser = localStorage.getItem("user");
  let settings = {};

  if (localStorageUser !== null) {
    settings = JSON.parse(localStorageUser)["settings"];
    localStorageUser = JSON.parse(localStorageUser);
  }

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const [user, setUser] = useState<Object>({});
  const [orgs, setOrgs] = useState<Array<Object>>([]);
  const [accessRolesOptions, setAccessRolesOptions] = useState<Array<Object>>(
    []
  );
  const [allRoleNames, setAllRoleNames] = useState<Array<Object>>([]);

  const [passVerify, setPassVerify] = useState("");

  function handleOrganizationChange(event) {
    if (window.confirm(t("myaccount.organizationchange_warning")) == true) {
      setUser({
        ...user,
        unit: [(event.target as HTMLSelectElement).value],
        unitMaster: (event.target as HTMLSelectElement).value,
      });
    }
  }

  const setEditCustomTextfields = (value, key) => {
    let tempCustomfields = user["customfields"];
    if (tempCustomfields != undefined) {
      if (tempCustomfields.find((item) => item["id"] == key) == undefined) {
        tempCustomfields.push({
          id: key,
          value: value,
        });
      } else {
        (tempCustomfields.find((item) => item["id"] == key) as Object)[
          "value"
        ] = value;
      }
    } else {
      tempCustomfields = [
        {
          id: key,
          value: value,
        },
      ];
    }
    setUser((user) => ({
      ...user,
      customfields: JSON.parse(JSON.stringify(tempCustomfields)),
    }));
  };

  function checkEmail() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/users/userByEmail/") + user["email"],
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          if (res[0].data.status == true) {
            notifyWarning(t("myaccount.email_already_in_use"));
            return;
          } else {
            saveUser();
          }
        })
      );
  }

  function saveUser() {
    if (!!user["password"] && user["password"] != passVerify) {
      notifyWarning(t("myaccount.toast_twarning_passwordmismatch"));
      setUser({ ...user, password: "" });
      setPassVerify("");
      return null;
    }
    if (user["accessRoles"].length == 0) {
      notifyWarning(t("myaccount.toast_warning_oneroleneeded"));
      return undefined;
    }

    axios
      .all([
        axios.single({
          method: "put",
          url: generateUrl("/api/users/me"),
          data: { ...user },
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/accessRoles/actualAccessRole"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setUser(res[0].data);
          let user = JSON.parse(localStorage.getItem("user") as string);
          user.userdata = res[0].data;
          user.userdata.actualAccessRole = res[1].data;
          localStorage.setItem("user", JSON.stringify(user));
          setLocale(res[0].data.language);
          setLanguage(res[0].data.language);
          setPassVerify("");
          notifySuccess(t("myaccount.toast_user_saved"));

          axios
            .all([
              axios.single({
                method: "post",
                url: generateUrl("/api/login/updatejwt"),
                data: { userid: res[0].data._id },

                headers: { "x-access-token": auth.user["token"] },
              }),
            ])
            .then(
              axios.spread((...res2) => {
                let userObject = {
                  id: res2[0].data.userdata.email,
                  auth: res2[0].data.auth,
                  token: res2[0].data.token,
                  userdata: res2[0].data.userdata,
                  settings: res2[0].data.settings,
                };
                localStorage.setItem("user", JSON.stringify(userObject));
                localStorage.removeItem("unauthorized");
                auth.setUser(() => {
                  return userObject;
                });
                history.go(0);
              })
            );
        })
      )
      .catch((err) => console.log(err));
  }

  function fetchData() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/users/me"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/orgelements"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/accessRoles/options"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/accessRoles/allRoleNamesByUser"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setUser(res[0].data);
          setOrgs(res[1].data);
          setAccessRolesOptions(
            res[2].data.map((data) => {
              return { label: data.name, value: data._id, isFixed: false };
            })
          );
          setAllRoleNames(res[3].data);
        })
      )
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="maincontent">
      <SiteAssistant dockedLogo={DiriFaceDocked} featurekey="myaccount" />
      <div className="myaccount-container">
        <div className="pagetitle-container">
          <h1>{t("myaccount.page_name")}</h1>
        </div>
        <Card>
          <Card.Body>
            <h4 className="header-title">{t("myaccount.general.title")}</h4>
            {user["email"] != undefined ? (
              <div>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{t("users.new_name")} *</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder={t("users.new_name")}
                    onChange={(evt) =>
                      setUser({ ...user, name: evt.target.value })
                    }
                    value={user["name"]}
                  ></Form.Control>
                </InputGroup>
                {auth.user?.["userdata"]["actualAccessRole"]["rules"].find(
                  (rule) =>
                    rule.objType == "organization" &&
                    rule.objSubType == "organization"
                )?.actions?.change_own_organization_permanently == "allow" && (
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>{t("users.new_unit")} *</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      as="select"
                      onChange={(event) => handleOrganizationChange(event)}
                      value={user["unitMaster"]}
                    >
                      <option value="">
                        {t("users.organziation_selected")}
                      </option>
                      {orgs.map((item, key) => {
                        return (
                          <option key={key} value={item["_id"]}>
                            {item["name"]}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </InputGroup>
                )}
                <Field
                  type="selectmulti"
                  title={t("users.access_roles") + " *"}
                  value={user["accessRoles"].map((val) => {
                    return {
                      value: val,
                      isFixed:
                        accessRolesOptions.find(
                          (role) => role["value"] == val
                        ) == undefined
                          ? true
                          : false,
                      label:
                        accessRolesOptions.find(
                          (role) => role["value"] == val
                        )?.["label"] != undefined
                          ? td(
                              accessRolesOptions.find(
                                (role) => role["value"] == val
                              )?.["label"]
                            )
                          : td(
                              allRoleNames?.find(
                                (role) => role["value"] == val
                              )?.["label"]
                            ) +
                            " " +
                            t("users.unavailable_access_role_for_org"),
                    };
                  })}
                  onChange={(val) =>
                    setUser({ ...user, accessRoles: val.map((v) => v.value) })
                  }
                  disabled={
                    auth.user?.["userdata"]["actualAccessRole"]["rules"].find(
                      (rule) =>
                        rule.objType == "users" &&
                        rule.objSubType == "accesscontrol"
                    )?.actions?.edit != "allow" ?? true
                  }
                  options={{
                    hasFixedValue: true,
                    selecttext: t("users.selecttext"),
                    choices: accessRolesOptions
                      .map((role) => {
                        return {
                          ...role,
                          label: td(role["label"]),
                        };
                      })
                      .concat(
                        user["accessRoles"]
                          .filter(
                            (role) =>
                              !accessRolesOptions
                                .map((role2) => role2["value"])
                                .includes(role)
                          )
                          .map((role) => {
                            return {
                              value: role,
                              label: td(
                                allRoleNames?.find(
                                  (roleName) => roleName["value"] == role
                                )?.["label"]
                              ),
                              isFixed: true,
                            };
                          })
                      ),
                  }}
                ></Field>

                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{t("users.new_email")} *</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    /* disabled={user["role"] != undefined ? (user["role"][0] != "Admin") : true}  */ placeholder={t(
                      "users.new_email"
                    )}
                    onChange={(evt) =>
                      setUser({ ...user, email: evt.target.value })
                    }
                    value={user["email"]}
                  ></Form.Control>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{t("users.new_password")}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="password"
                    autoComplete="new-password"
                    placeholder={t("users.new_password")}
                    onChange={(evt) =>
                      setUser({ ...user, password: evt.target.value })
                    }
                    value={user["password"]}
                  ></Form.Control>
                </InputGroup>
                {user["password"] != "" ? (
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {t("users.verify_new_password")}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="password"
                      autoComplete="new-password"
                      placeholder={t("users.verify_new_password")}
                      onChange={(evt) => setPassVerify(evt.target.value)}
                      value={passVerify}
                    ></Form.Control>
                  </InputGroup>
                ) : null}
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{t("users.new_mobile")}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder={t("users.new_mobile")}
                    onChange={(evt) =>
                      setUser({ ...user, phone: evt.target.value })
                    }
                    value={user["phone"]}
                  ></Form.Control>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{t("users.language")}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="select"
                    onChange={(evt) => {
                      setUser({
                        ...user,
                        language: (evt.target as HTMLSelectElement).value,
                      });
                    }}
                    value={user["language"]}
                  >
                    <option value="no">{t("users.language_norwegian")}</option>
                    <option value="en">{t("users.language_english")}</option>
                  </Form.Control>
                </InputGroup>
                {settings["customfieldsUser"] !== undefined &&
                  settings["customfieldsUser"].length > 0 &&
                  settings["customfieldsUser"].map((item, key) => {
                    switch (item.type) {
                      case "string":
                        return (
                          <InputGroup className="mb-3" key={key}>
                            <InputGroup.Prepend>
                              <InputGroup.Text>{td(item.key)}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              onChange={(evt) =>
                                setEditCustomTextfields(
                                  evt.target.value,
                                  item.key
                                )
                              }
                              value={
                                user["customfields"] != undefined
                                  ? user["customfields"].find(
                                      (field) => field["id"] == item.key
                                    ) != undefined
                                    ? (
                                        user["customfields"].find(
                                          (field) => field["id"] == item.key
                                        ) as Object
                                      )["value"]
                                    : ""
                                  : ""
                              }
                            />
                          </InputGroup>
                        );
                      case "dropdown":
                        return (
                          <InputGroup className="mb-3" key={key}>
                            <InputGroup.Prepend>
                              <InputGroup.Text>{td(item.key)}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              as="select"
                              onChange={(evt) =>
                                setEditCustomTextfields(
                                  evt.currentTarget.value,
                                  item.key
                                )
                              }
                              value={
                                user["customfields"] != undefined
                                  ? user["customfields"].find(
                                      (field) => field["id"] == item.key
                                    ) != undefined
                                    ? (
                                        user["customfields"].find(
                                          (field) => field["id"] == item.key
                                        ) as Object
                                      )["value"]
                                    : ""
                                  : ""
                              }
                            >
                              (<option value="">{t("users.select")}</option>)
                              {item["options"] != undefined
                                ? item["options"].map((option, optionkey) => {
                                    return (
                                      <option key={optionkey} value={option}>
                                        {td(option)}
                                      </option>
                                    );
                                  })
                                : ""}
                            </Form.Control>
                          </InputGroup>
                        );
                      default:
                        return null;
                    }
                  })}
                <Button onClick={checkEmail}>{t("myaccount.saveBtn")}</Button>
              </div>
            ) : null}
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <h4 className="header-title">
              {t("myaccount.notifications.title")}
            </h4>
            {user["email"] !== undefined && (
              <div>
                <Field
                  title={t("myaccount.notifications.taskassignedtome")}
                  type="checkbox"
                  onChange={(value) =>
                    setUser({
                      ...user,
                      settings: {
                        ...(user["settings"] != undefined
                          ? user["settings"]
                          : {}),
                        notifyTaskAssignment: value,
                      },
                    })
                  }
                  value={
                    user["settings"] != undefined &&
                    user["settings"]["notifyTaskAssignment"] != undefined
                      ? user["settings"]["notifyTaskAssignment"]
                      : false
                  }
                ></Field>
                <Field
                  title={t("myaccount.notifications.ownedtaskchanged")}
                  type="checkbox"
                  onChange={(value) =>
                    setUser({
                      ...user,
                      settings: {
                        ...(user["settings"] != undefined
                          ? user["settings"]
                          : {}),
                        notifyTaskOwnedChange: value,
                      },
                    })
                  }
                  value={
                    user["settings"] != undefined &&
                    user["settings"]["notifyTaskOwnedChange"] != undefined
                      ? user["settings"]["notifyTaskOwnedChange"]
                      : false
                  }
                ></Field>
                <Field
                  title={t("myaccount.notifications.expiredassignedtasks")}
                  type="checkbox"
                  onChange={(value) =>
                    setUser({
                      ...user,
                      settings: {
                        ...(user["settings"] != undefined
                          ? user["settings"]
                          : {}),
                        notifyExpiredAssignedTasks: value,
                      },
                    })
                  }
                  value={
                    user["settings"] != undefined &&
                    user["settings"]["notifyExpiredAssignedTasks"] != undefined
                      ? user["settings"]["notifyExpiredAssignedTasks"]
                      : false
                  }
                ></Field>
                <Field
                  title={t(
                    "myaccount.notifications.nearlyexpiredassignedtasks"
                  )}
                  type="checkbox"
                  onChange={(value) =>
                    setUser({
                      ...user,
                      settings: {
                        ...(user["settings"] != undefined
                          ? user["settings"]
                          : {}),
                        notifyNearlyExpiredAssignedTasks: value,
                      },
                    })
                  }
                  value={
                    user["settings"] != undefined &&
                    user["settings"]["notifyNearlyExpiredAssignedTasks"] !=
                      undefined
                      ? user["settings"]["notifyNearlyExpiredAssignedTasks"]
                      : false
                  }
                ></Field>
                <Field
                  title={t("myaccount.notifications.expiredCreatedTasks")}
                  type="checkbox"
                  onChange={(value) =>
                    setUser({
                      ...user,
                      settings: {
                        ...(user["settings"] != undefined
                          ? user["settings"]
                          : {}),
                        notifyExpiredCreatedTasks: value,
                      },
                    })
                  }
                  value={
                    user["settings"] != undefined &&
                    user["settings"]["notifyExpiredCreatedTasks"] != undefined
                      ? user["settings"]["notifyExpiredCreatedTasks"]
                      : false
                  }
                ></Field>
                <Field
                  title={t(
                    "myaccount.notifications.expiredRiskAssessmentTreatmentPlan"
                  )}
                  type="checkbox"
                  onChange={(value) =>
                    setUser({
                      ...user,
                      settings: {
                        ...(user["settings"] != undefined
                          ? user["settings"]
                          : {}),
                        notifyExpiredRiskAssessmentTreatmentPlan: value,
                      },
                    })
                  }
                  value={
                    user["settings"] != undefined &&
                    user["settings"][
                      "notifyExpiredRiskAssessmentTreatmentPlan"
                    ] != undefined
                      ? user["settings"][
                          "notifyExpiredRiskAssessmentTreatmentPlan"
                        ]
                      : false
                  }
                ></Field>
                <Field
                  title={t("myaccount.notifications.treatmentassignedtome")}
                  type="checkbox"
                  onChange={(value) =>
                    setUser({
                      ...user,
                      settings: {
                        ...(user["settings"] != undefined
                          ? user["settings"]
                          : {}),
                        notifyTreatmentAssignment: value,
                      },
                    })
                  }
                  value={
                    user["settings"] != undefined &&
                    user["settings"]["notifyTreatmentAssignment"] != undefined
                      ? user["settings"]["notifyTreatmentAssignment"]
                      : false
                  }
                ></Field>
                <Field
                  title={t(
                    "myaccount.notifications.assignedtreatmentstatuschanged"
                  )}
                  type="checkbox"
                  onChange={(value) =>
                    setUser({
                      ...user,
                      settings: {
                        ...(user["settings"] != undefined
                          ? user["settings"]
                          : {}),
                        notifyAssignedTreatmentStatusChanged: value,
                      },
                    })
                  }
                  value={
                    user["settings"] != undefined &&
                    user["settings"]["notifyAssignedTreatmentStatusChanged"] !=
                      undefined
                      ? user["settings"]["notifyAssignedTreatmentStatusChanged"]
                      : false
                  }
                ></Field>
                <Field
                  title={t(
                    "myaccount.notifications.treatmentStatusChangedInOwnedSystem"
                  )}
                  type="checkbox"
                  onChange={(value) =>
                    setUser({
                      ...user,
                      settings: {
                        ...(user["settings"] != undefined
                          ? user["settings"]
                          : {}),
                        notifyTreatmentStatusChangedInOwnedSystem: value,
                      },
                    })
                  }
                  value={
                    user["settings"] != undefined &&
                    user["settings"][
                      "notifyTreatmentStatusChangedInOwnedSystem"
                    ] != undefined
                      ? user["settings"][
                          "notifyTreatmentStatusChangedInOwnedSystem"
                        ]
                      : false
                  }
                ></Field>
                <Field
                  title={t("myaccount.notifications.expiredTreatment")}
                  type="checkbox"
                  onChange={(value) =>
                    setUser({
                      ...user,
                      settings: {
                        ...(user["settings"] != undefined
                          ? user["settings"]
                          : {}),
                        notifyExpiredTreatment: value,
                      },
                    })
                  }
                  value={
                    user["settings"] != undefined &&
                    user["settings"]["notifyExpiredTreatment"] != undefined
                      ? user["settings"]["notifyExpiredTreatment"]
                      : false
                  }
                ></Field>
                <Field
                  title={t("myaccount.notifications.nearlyExpiredTreatment")}
                  type="checkbox"
                  onChange={(value) =>
                    setUser({
                      ...user,
                      settings: {
                        ...(user["settings"] != undefined
                          ? user["settings"]
                          : {}),
                        notifyNearlyExpiredTreatment: value,
                      },
                    })
                  }
                  value={
                    user["settings"] != undefined &&
                    user["settings"]["notifyNearlyExpiredTreatment"] !=
                      undefined
                      ? user["settings"]["notifyNearlyExpiredTreatment"]
                      : false
                  }
                ></Field>
                <Field
                  title={t(
                    "myaccount.notifications.expiredTreatmentFromOwnedRiskAssessment"
                  )}
                  type="checkbox"
                  onChange={(value) =>
                    setUser({
                      ...user,
                      settings: {
                        ...(user["settings"] != undefined
                          ? user["settings"]
                          : {}),
                        notifyExpiredTreatmentFromOwnedRiskAssessment: value,
                      },
                    })
                  }
                  value={
                    user["settings"] != undefined &&
                    user["settings"][
                      "notifyExpiredTreatmentFromOwnedRiskAssessment"
                    ] != undefined
                      ? user["settings"][
                          "notifyExpiredTreatmentFromOwnedRiskAssessment"
                        ]
                      : false
                  }
                ></Field>

                <Button onClick={saveUser}>{t("myaccount.saveBtn")}</Button>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default MyAccount;
