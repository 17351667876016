import WizardFieldManipulator from '../../WizardHelpers/WizardFieldManipulator';
import { toast } from 'react-toastify';
import { generateUrl } from '../../../../config';


const GetWizardFieldByReference = WizardFieldManipulator.GetWizardFieldByReference;
const GetWizardValuesByReferenceList = WizardFieldManipulator.GetWizardValuesByReferenceList;

const notifyWarning = (text) => toast(text, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "warning-toast"
  });

async function fetchData(itemId, axios, auth): Promise<any> {
    let results = {
        system: undefined,
        users: [],
        organizations: [],
        systems: []
    };

    await axios.all([
        axios.single({
            method: "get",
            url: generateUrl("/api/cra/systems/") + itemId,
            responseType: "json",
            headers: {'x-access-token': auth.user["token"]}
        }),
        axios.single({
            method: "get",
            url: generateUrl("/api/users/"),
            responseType: "json",
            headers: {'x-access-token': auth.user["token"]}
        }),
        axios.single({
            method: "get",
            url: generateUrl("/api/orgElements/"),
            responseType: "json",
            headers: {'x-access-token': auth.user["token"]}
        }),
        axios.single({
            method: "get",
            url: generateUrl("/api/cra/systems/"),
            responseType: "json",
            headers: {'x-access-token': auth.user["token"]}
        }),
    ])
    .then(axios.spread(async (...res) => {
        results.system = res[0].data;
        results.users = res[1].data;
        results.organizations = res[2].data;
        results.systems = res[3].data;
    }));

    return results;
}

async function SystemDescriptionTemplateOther(t, axios, auth, itemId) {

    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    let data = await fetchData(itemId, axios, auth);

    let tempWizardData: Array<any> = [
        {
            "fields": [
                {
                    "type": "text_title",
                    "title": t("diri.regassistant.types.other.title")
                },
                {
                    "type": "dateselector",
                    "title": t("diri.regassistant.types.other.startdate"),
                    "reference": "startdate"
                },
                {
                    "type":"input",
                    "title":t("diri.regassistant.types.other.systemname") + " *",
                    "reference":"name"
                },
                {
                    "type":"text_normal",
                    "title": t("diri.regassistant.types.other.ledetekst_systemname")
                },
                {
                    id: 3,
                    title: t("diri.regassistant.types.other.system_responsible"),
                    value: "",
                    type: "autocompletewithid",
                    reference:"responsibleperson",
                    options: {
                        list: data.users.map((i, key) => { return { id: key, label: i["name"], value: i["_id"] } }),
                        data: data.users,
                        minlength: 2,
                        noresults: "no matches"
                    }
                },
                {
                    "type":"text_normal",
                    "title": t("diri.regassistant.types.other.ledetekst_responsible")
                },
                {
                    "type":"textarea",
                    "title":t("diri.regassistant.types.other.system_description"),
                    "reference":"description"
                },
                {
                    "type":"text_normal",
                    "title": t("diri.regassistant.types.other.ledetekst_description")
                },
                {
                    "type":"textarea",
                    "title": t("diri.regassistant.types.other.delimitations"),
                    "reference":"limitationsdescription"
                },
                {
                    "type":"text_normal",
                    "title": t("diri.regassistant.types.other.ledetekst_delimitations")
                },
                {
                    "type":"selectmulti",
                    "title":t("diri.regassistant.types.other.implicatedriskassessments"),
                    "reference":"implicatedriskassessments",
                    value: [],
                    options: {
                        choices: data.systems.map((i) => { return {value: i["_id"], label: i["nameReadable"]}})
                    }, 
                }
            ]
        },
        {
            "fields": [
                {
                    "type":"text_title",
                    "title": t("diri.regassistant.types.other.system_participants"),
                    options: {
                        headertag: "h2"
                    }
                },
                {
                    title: t("wizard_title.input.riskassessmentparticipants"),
                    value: [],
                    type: "personselector",
                    reference:"riskassessmentparticipants",
                    options: {
                        users: data.users,
                        organizations: data.organizations,
                        minlength: 2,
                        noresults: t("wizard_title.input.riskassessmentparticipants_noresult"),
                        roleTitle: t("wizard_title.input.riskassessmentparticipants_role"),
                        organizationTitle: t("wizard_title.input.riskassessmentparticipants_organization"),
            
                        deleteButtonText: t("wizard_title.input.riskassessmentparticipants_deletebtn"),
                        addButtonText: t("wizard_title.input.riskassessmentparticipants_addbtn"),
                    }
                }
            ]
        }

    ];
        

    GetWizardFieldByReference(tempWizardData, "startdate").value = data.system.data.startdate;
    GetWizardFieldByReference(tempWizardData, "name").value = data.system.name;
    GetWizardFieldByReference(tempWizardData, "responsibleperson").value = data.system.data.responsibleperson;
    GetWizardFieldByReference(tempWizardData, "description").value = data.system.data.description;
    GetWizardFieldByReference(tempWizardData, "limitationsdescription").value = data.system.data.limitationsdescription;
    GetWizardFieldByReference(tempWizardData, "riskassessmentparticipants").value = data.system.data.riskassessmentparticipants;
    GetWizardFieldByReference(tempWizardData, "implicatedriskassessments").value = data.system.data.implicatedriskassessments;

    return tempWizardData;
}

function Save(t, axios, auth, wizardData, system) {
    
    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    let values = GetWizardValuesByReferenceList(wizardData, [
        "startdate",
        "name",
        "responsibleperson",
        "description",
        "limitationsdescription",
        "riskassessmentparticipants",
        "implicatedriskassessments"            
    ]);

    if(values.name == undefined || values.name == ""){
        notifyWarning(t("diri.regassistant.types.other.toast.warning_missing_system_name"));
        return null;
    }

    system["name"] = values.name;
    system["data"] = values;

    return axios.put(generateUrl('/api/cra/systems/') + system["_id"], {
        system: system
    }, {
        headers: {'x-access-token': auth.user["token"]}
    });

}


export default { systemDescriptionTemplateOther: SystemDescriptionTemplateOther, save: Save}