import * as React from 'react';
import { useHistory } from 'react-router'

import { useAuth } from '../../../../../../Auth';

import { useState, useEffect } from 'react';
import { useAxios } from '../../../../../../AxiosHandler';
import { useTranslate } from 'react-polyglot';

import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';

import { useDynamicLanguage } from '../../../../../../DynamicLanguageProvider';

import {useStripe, PaymentElement, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Button } from 'react-bootstrap';
import Field from '../../../../../Shared/Wizard/Field/Field';

function SetupForm(props) {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const t = useTranslate();
    const history = useHistory();
    const td = useDynamicLanguage().td;
    const elements = useElements() as any;
    const stripe = useStripe();
    const [editCardholderName, setEditCardholderName] = useState("");
    const [editCardholderEmail, setEditCardholderEmail] = useState("");

    const formUrlEncoded = x => Object.keys(x).reduce((p, c) => p + `&${c}=${encodeURIComponent(x[c])}`, '');

    const notifySuccess = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "success-toast"
    });

    const notifyWarning = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "warning-toast"
    });

    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    let userdata = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
        userdata = JSON.parse(localStorageUser)["userdata"];
    }

    function handleAddPaymentMethod(e){
        e.preventDefault();
        if(editCardholderName == ""){
            notifyWarning(t("customeroverview.editCustomerInfo.enter_cardholderName"));
        }
        if(editCardholderEmail == ""){
            notifyWarning(t("customeroverview.editCustomerInfo.enter_cardholderEmail"));
        }
        if (editCardholderEmail !== "") {
            let pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
            if (!pattern.test(editCardholderEmail)) {
                notifyWarning(t("customeroverview.editCustomerInfo.enter_valid_email"));
                return undefined;
            }
        }

        if (!stripe || !elements) {
            return;
        }
            
        stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
              return_url: '',
              payment_method_data: {
                    billing_details: {
                        name: editCardholderName,
                        email: editCardholderEmail
                    }
                },
            },
          })
          .then(function(result) {
            if (result.error) {
                console.log(result.error)
            }
            props.onHide(result);
          });
        
    }

    

    useEffect(() => {
    }, []);

    return (
        <div>
            <div style={{ marginBottom: '20px' }}>
                <Field
                    type="input"
                    title={t("customeroverview.editCustomerInfo.cardholderName")}
                    value={editCardholderName}
                    onChange={val => setEditCardholderName(val)}
                    disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.handle_payment != "allow"}
                ></Field>
                <Field
                    type="input"
                    title={t("customeroverview.editCustomerInfo.cardholderEmail")}
                    value={editCardholderEmail}
                    onChange={val => setEditCardholderEmail(val)}
                    disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.handle_payment != "allow"}
                ></Field>
            </div>
            
            <PaymentElement />
            <Button onClick={(e) => handleAddPaymentMethod(e)}>Submit</Button>
        </div>
    );
}

export default SetupForm;