import React, { useState } from "react";

import { Button, Modal, Spinner, Accordion, Card } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";

import PageTable from "../../Shared/PageTable/PageTable";
import PaginatedTable from "../../Shared/PaginatedTable/PaginatedTable";
import Field from "../../Shared/Wizard/Field/Field";

import { generateUrl } from "../../../config";

import "react-toastify/dist/ReactToastify.css";
import "./Users.scss";

function TransferRightsOwnership(props) {
  const auth = useAuth();
  const t = useTranslate();

  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;

  const userName = t("users.user");
  const unit = t("users.new_unit");
  const role = t("users.new_role");
  const email = t("users.new_email");
  const mobile = t("users.new_mobile");
  const created = t("users.created");
  const lastSeen = t("users.last_seen");

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const [editUser, setEditUser] = useState({
    name: "",
    _id: "",
    unit: [""],
    email: "",
    phone: "",
    role: "",
    orgRole: "",
    language: "",
    customfields: [{}],
  } as any);

  const handleShowUserSelected = (dataLine, evt) => {
    setEditUser(dataLine);
  };

  const formatDate = (date: Date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const dayWithZero = day < 10 ? `0${day}` : day;
    const monthWithZero = month < 10 ? `0${month}` : month;
    return `${dayWithZero}.${monthWithZero}.${year}`;
  };

  const transferList = [
    {
      label: "System owner (via Ownership)",
      value: "system-owner",
    },
    {
      label: "System responsible (via Registration)",
      value: "system-assignment",
    },
    {
      label: "System owner person (via Registration)",
      value: "system-owner-person",
    },
    {
      label: t("users.transfer_right_2"),
      value: "treatment-owner",
    },
    {
      label: t("users.transfer_right_3"),
      value: "treatment-assignment",
    },
    {
      label: t("users.transfer_right_4"),
      value: "survey",
    },
    {
      label: t("users.transfer_right_5"),
      value: "task-owner",
    },
    {
      label: t("users.transfer_right_6"),
      value: "task-assignment",
    },
  ];

  const [selectedRights, setSelectedRights] = useState<string[]>([]);
  const [showUsers, setShowUsers] = useState(false);
  const [showUserSelected, setShowUserSelected] = useState(false);
  const [relatedData, setRelatedData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [hasData, setHasDate] = useState(false);

  const updateRights = (value: string) => {
    if (!selectedRights.some((x) => x === value)) {
      setSelectedRights([...selectedRights, value]);
    } else {
      setSelectedRights(selectedRights.filter((e) => e !== value));
    }
  };

  function getRelatedData(id) {
    setHasDate(false);
    axios
      .post(
        generateUrl("/api/users/get-related-data"),
        {
          currentUserId: props.userSelected,

          newUserId: id,

          listOfObjects: selectedRights,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        setRelatedData(res.data.updateObjects);
        // check if there is any data to transfer since all items return as empty array
        transferList.map((_, index) => {
          if (
            !!res.data.updateObjects[transferList[index].value] &&
            !!res.data.updateObjects[transferList[index].value].length
          ) {
            setHasDate(true);
            return;
          }
        });
      })
      .catch((error) => {
        notifyWarning(t("forgot_password.something_went_wrong"));
      });
  }

  function transferRelatedData(id) {
    setLoading(true);
    axios
      .post(
        generateUrl("/api/users/transfer-related-data"),
        {
          currentUserId: props.userSelected,

          newUserId: id,

          listOfObjects: selectedRights,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.status == 200) {
          props.setShowTransferRightsOwnership(false);
          setShowUserSelected(false);
          setShowUsers(false);
          notifySuccess(t("settings.toast_savesuccess"));
          setEditUser({
            name: "",
            unit: [""],
            email: "",
            phone: "",
            role: "",
            orgRole: "",
            language: "",
            customfields: [{}],
          });
          setSelectedRights([]);
        } else {
          notifyWarning(t("forgot_password.something_went_wrong"));
        }
      })
      .catch((error) => {
        setLoading(false);
        notifyWarning(t("forgot_password.something_went_wrong"));
      });
  }

  return (
    <div className="maincontent">
      <Modal
        show={props.showTransferRightsOwnership}
        onHide={() => {
          props.setShowTransferRightsOwnership(false);
          setEditUser({
            name: "",
            unit: [""],
            email: "",
            phone: "",
            role: "",
            orgRole: "",
            language: "",
            customfields: [{}],
          });
          setSelectedRights([]);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("users.transfer_user")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{"Please select the info you want to transfer:"}</p>
          <Field
            type="indeterminatecheckbox"
            checked={selectedRights.length === transferList.length}
            onChange={() =>
              selectedRights.length === transferList.length
                ? setSelectedRights([])
                : setSelectedRights(transferList.map((e) => e.value))
            }
            parent={false}
            title={"Select all"}
            titlePlacement="end"
          ></Field>
          {transferList.map((e) => (
            <div className="ml-4">
              <Field
                type="indeterminatecheckbox"
                checked={selectedRights.some((x) => x === e.value)}
                onChange={() => updateRights(e.value)}
                parent={false}
                title={e.label}
                titlePlacement="end"
              ></Field>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={() => {
              props.setShowTransferRightsOwnership(false);
              setEditUser({
                name: "",
                unit: [""],
                email: "",
                phone: "",
                role: "",
                orgRole: "",
                language: "",
                customfields: [{}],
              });
            }}
          >
            {t("analysisOverview.cancel")}
          </Button>
          <Button
            disabled={!selectedRights.length}
            onClick={() => {
              setShowUsers(true);
              props.setShowTransferRightsOwnership(false);
            }}
          >
            {t("analysisInstance.nextpage")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showUsers} onHide={() => setShowUsers(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{"Select user for rights transference"}</Modal.Title>
        </Modal.Header>
        <Modal.Body id="transferTable">
          <PageTable
            dataUrl={generateUrl("/api/users/getAllPaged/")}
            options={{
              showFields: [
                { label: userName, field: "name" },
                { label: unit, field: "org" },
                {
                  label: role,
                  field: "roles",
                  override: (dataLine) =>
                    dataLine.roles == ""
                      ? t("users.user_has_not_logged_in")
                      : dataLine.roles,
                },
                { label: email, field: "email" },
                { label: mobile, field: "phone" },
                {
                  label: created,
                  field: "date",
                  override: (dataLine) => formatDate(new Date(dataLine.date)),
                },
                {
                  label: lastSeen,
                  field: "lastLoggedIn",
                  override: (dataLine) =>
                    dataLine.lastLoggedIn
                      ? formatDate(new Date(dataLine.lastLoggedIn))
                      : "",
                },
              ],
            }}
            onLineClick={handleShowUserSelected}
            hidePagination={false}
            hidePagesize={false}
            hideFilter={false}
            hover={true}
            selectedItems={[editUser]}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={() => setShowUsers(false)}
          >
            {t("analysisOverview.cancel")}
          </Button>
          <Button
            disabled={editUser["_id"] === ""}
            onClick={() => {
              setShowUserSelected(true);
              setShowUsers(false);
              getRelatedData(editUser["_id"]);
            }}
          >
            {t("analysisInstance.nextpage")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={showUserSelected}
        onHide={() => setShowUserSelected(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Confirm"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Object selected to be transfered to user: {editUser.name}</p>
          {transferList.length && !!relatedData && hasData ? (
            transferList.map(
              (_, index) =>
                !!relatedData[transferList[index].value] &&
                !!relatedData[transferList[index].value].length && (
                  <div
                    key={transferList[index].value}
                    style={{ marginBottom: 20 }}
                  >
                    <Accordion>
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey={transferList[index].value}
                        >
                          <b>{`${transferList[index].label}`}</b>
                        </Accordion.Toggle>

                        <Accordion.Collapse
                          eventKey={transferList[index].value}
                        >
                          <Card.Body>
                            <PaginatedTable
                              data={relatedData[transferList[index].value]}
                              header={["name", "description"]}
                            />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                )
            )
          ) : (
            <p>No data to transfer!</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={() => {
              setShowUserSelected(false);
              setShowUsers(true);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => transferRelatedData(editUser["_id"])}
            disabled={loading || !hasData}
            className="m-1 loadingBtn"
          >
            {loading && (
              <Spinner
                as="span"
                variant="primary"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            )}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TransferRightsOwnership;
