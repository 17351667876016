import React from 'react';
import { InputGroup, Form } from 'react-bootstrap';

function TextArea(props) {
    const handleChange = (value) => {
        if (props.onChange != undefined) {
            props.onChange(value);
        }
    }

    return(
        <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text>{props.title}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control as="textarea" rows={5} disabled={props.disabled} type={props.type} onChange={(evt) => handleChange(evt.currentTarget.value)} value={props.value != undefined && props.value != "" ? props.value : (props.defaultValue != undefined ? props.defaultValue : "")} />
        </InputGroup>
    );
}

export default TextArea;