import React, { useEffect, useState } from 'react';
import * as _ from "lodash";
import { useAxios } from "../../../../../AxiosHandler";
import moment from 'moment';

import { useTranslate } from 'react-polyglot';
import './TasksBurndown.scss';
import { useAuth } from "../../../../../Auth";
import { Card, Button } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import DatePicker from '../../../../Shared/Wizard/Field/Fieldtypes/DateSelector' 
import { generateUrl } from '../../../../../config';

const TasksBurndown = (props) => {

    const [allTasks, setAllTasks] = useState([{}]);
    const [completedTasks, setCompletedTasks] = useState([{}]);
    const [progressTasks, setProgressTasks] = useState([{}]);
    const [monthLabels, setMonthLabels] = useState([]);
    const [chartOptions, setChartOptions] = useState({});
    const [loading, setLoading] = useState(true);

    const [startDate, setStartDate] = useState(new Date(moment().subtract(11, "months").toDate() ));
    const [endDate, setEndDate] = useState(new Date());

    const t = useTranslate();   
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();

    function handleDateRange(date,direction){
        if (direction != undefined && direction == "from") { setStartDate(date); }
        if (direction != undefined && direction == "to") { setEndDate(date); }
        fetchMonths();
    }

    async function fetchMonths(){
        let startDatepicker = moment(startDate, "YYYY-MM-DD");
        let endDatepicker = moment(endDate, "YYYY-MM-DD").endOf("month");
        let allMonthsInPeriod:any = [];

        while (startDatepicker.isBefore(endDatepicker)) {
            allMonthsInPeriod.push(startDatepicker.format("YYYY-MM"));
            startDatepicker = startDatepicker.add(1, "month");
        };
        setMonthLabels(allMonthsInPeriod);

        return allMonthsInPeriod;
    }

    async function fetchRiskData(months) {
        let returns = {};
        await axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/tasks/responsible"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            })
        ])
        .then(axios.spread((...res) => {
            let closedTasks = res[0].data.filter(item => item["status"]  == "closed");
            let progressTasks = res[0].data.filter(item => item["status"]  == "process");
            let tmpClosedTasks:any =[];
            let tmpProgressTasks:any =[];
            let tmpAllTasks:any =[];

            // for all tasks
            let orderedByAllTasks;
             orderedByAllTasks = _.groupBy(res[0].data, function(element) {
                return element.date.substring(0,7);
            });

            if(orderedByAllTasks != undefined && orderedByAllTasks.length != 0){
                let monthsAdded = 0;
                tmpAllTasks = months.map(month => {
                    monthsAdded += orderedByAllTasks[month] != undefined ? orderedByAllTasks[month].length : 0
                    return monthsAdded;
                });
            }
            returns["allTasks"] = tmpAllTasks;

            // for completed tasks
            let orderedByCompletedTasks;
            orderedByCompletedTasks = _.groupBy(closedTasks, function(element) {
                return element.date.substring(0,7);
            });

            if(orderedByCompletedTasks != undefined && orderedByCompletedTasks.length != 0){
                let monthsAdded = 0;
                tmpClosedTasks = months.map(month => {
                    monthsAdded += orderedByCompletedTasks[month] != undefined ? orderedByCompletedTasks[month].length : 0
                    return monthsAdded;
                });
            }
            returns["tmpClosedTasks"] = tmpClosedTasks;

            // for progress tasks
            let orderedByProgressTasks;
            orderedByProgressTasks = _.groupBy(progressTasks, function(element) {
                return element.date.substring(0,7);
            });

            if(orderedByProgressTasks != undefined && orderedByProgressTasks.length != 0){
                let monthsAdded = 0;
                tmpProgressTasks = months.map(month => {
                    monthsAdded += orderedByProgressTasks[month] != undefined ? orderedByProgressTasks[month].length : 0
                    return monthsAdded;
                });
            }
            returns["tmpProgressTasks"] = tmpProgressTasks.map((item, key) => item + tmpClosedTasks[key]);
       }));
       return returns;
    }

    useEffect(() => {
		let cancelled = false;

        fetchMonths().then((months) => {
            fetchRiskData(months).then(data => {
                if (!cancelled) {
                    setAllTasks(data["allTasks"]);
                    setCompletedTasks(data["tmpClosedTasks"]);
                    setProgressTasks(data["tmpProgressTasks"]);
                    setChartOptions({
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                              boxWidth: 80,
                              fontColor: 'black'
                            },
                            onClick: false
                          },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                          },
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                                    let labelValue = tooltipItem.yLabel;

                                    switch (data.datasets[tooltipItem.datasetIndex].datasetname) {
                                        case "all":
                                            labelValue = tooltipItem.yLabel - data.datasets[1]["data"][tooltipItem.index];
                                            break;
                                        case "progress":
                                            labelValue = tooltipItem.yLabel - data.datasets[0]["data"][tooltipItem.index];
                                            break;
                                        case "completed":
                                            
                                            break;
                                    }

                                    return label + ": " + labelValue;
                                }
                            }
                        }
                    });
                    setLoading(false);
                }
            });
        });
        
        return () => {
            cancelled = true;
        };
    }, []);

    useEffect(() => {
		let cancelled = false;

        fetchMonths().then((months) => {
            fetchRiskData(months).then(data => {
                if (!cancelled) {
                    setAllTasks(data["allTasks"]);
                    setCompletedTasks(data["tmpClosedTasks"]);
                    setProgressTasks(data["tmpProgressTasks"]);
                    setChartOptions({
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                              boxWidth: 80,
                              fontColor: 'black'
                            },
                            onClick: false
                          },
                         scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                           },
                        tooltips: {
                              callbacks: {
                                  label: function(tooltipItem, data) {
                                      let label = data.datasets[tooltipItem.datasetIndex].label || '';
                                      let labelValue = tooltipItem.yLabel;
  
                                      switch (data.datasets[tooltipItem.datasetIndex].datasetname) {
                                          case "all":
                                              labelValue = tooltipItem.yLabel - data.datasets[1]["data"][tooltipItem.index];
                                              break;
                                          case "progress":
                                              labelValue = tooltipItem.yLabel - data.datasets[0]["data"][tooltipItem.index];
                                              break;
                                          case "completed":
                                              
                                              break;
                                      }
  
                                      return label + ": " + labelValue;
                                  }
                              }
                          }
                    });
                    setLoading(false);
                }
            });
        });
        
        return () => {
            cancelled = true;
        };
    }, [startDate, endDate, props.updateTable]);

    return (
        <Card>
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                <h4 className="header-title">{t("dashboard.cards.taskburndown_name")}</h4>
                <p>{t("dashboard.cards.taskburndown_description")}</p>
                <hr />
                <div className="row">
                    <div className='col-md-6'>
                        <DatePicker
                            title={t("dashboard.cards.taskburndown_frommonth")}
                            selected={startDate}
                            onChange={date => handleDateRange(date as Date, "from")}
                            selectsStart
                            minDate={new Date(moment().subtract(36, "months").toDate() )}
                            maxDate={endDate}
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                        />
                    </div>
                    <div className='col-md-6'>
                        <DatePicker
                            title={t("dashboard.cards.taskburndown_tomonth")}
                            selected={endDate}
                            onChange={date => handleDateRange(date as Date, "to")}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                        />
                    </div>
                    {/* <div className='col-md-4'><Button onClick={fetchMonths}>Apply</Button></div> */}
                </div>
                <hr/>
                <Line
                    redraw={false}
                    type = "line"
                    data={{
                        labels: monthLabels,
                        datasets: [
                            {
                                label: t("dashboard.cards.taskburndown_label_completedtasks"),
                                backgroundColor: "#5B8266",
                                fill: true,
                                data: completedTasks,
                                cubicInterpolationMode: "monotone",
                                datasetname: "completed"
                            },
                            {
                                label: t("dashboard.cards.taskburndown_label_progresstasks"),
                                backgroundColor: "#FACD75",
                                fill: true,
                                data: progressTasks,
                                cubicInterpolationMode: "monotone",
                                datasetname: "progress"
                            },
                            {
                                label: t("dashboard.cards.taskburndown_label_alltasks"),
                                backgroundColor: "#d8775a",
                                fill: true,
                                data: allTasks,
                                cubicInterpolationMode: "monotone",
                                datasetname: "all"
                            },
                        ]
                    }}
                    options={chartOptions}
                />
            </Card.Body>
        </Card>
    );
}

export default TasksBurndown;
