import * as React from 'react';
import { Card, Button, InputGroup, Form, Accordion } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../Auth';
import { useAxios } from '../../../AxiosHandler';
import { useTranslate } from 'react-polyglot';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Settings.scss';
import _ from 'lodash'
import './SettingsGeneral.scss';
import './SettingsGeneral.tsx';


import SiteAssistant from "../../Shared/SiteAssistant/SiteAssistant";
import DiriFaceDocked from "../../../Assets/Images/diriassistant/diri.svg";
import SettingsGeneral from './SettingsGeneral';
import SettingsCustomFields from './SettingsCustomFields';
import SettingsTypeNCategory from './SettingsTypeNCategory';
import { generateUrl } from '../../../config';


function Settings() {
    const t = useTranslate();  
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;

    const userHasEditRights = auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "settings" && rule.objSubType == "settings").actions.edit == "allow";
    
    const [inheriting, setInheriting] = useState(true);
    const [org, setOrg] = useState();
    const [orgs, setOrgs] = useState();
    const [occurenceTypes, setOccurenceTypes] = useState([]);
    
    const [orgParents, setOrgParents] = useState([]);
    const [occurenceTypesConnectedOccurences, setOccurenceTypesConnectedOccurences] = useState([]);
    const [consequenceTypes, setConsequenceTypes] = useState([]);
    const [consequenceTypesConnectedConsequences, setConsequenceTypesConnectedConsequences] = useState([]);
    const [consequenceTypesOrgParents, setConsequenceTypesOrgParents] = useState([]);

    const [settingsInherited, setSettingsInherited] = useState();
    const [settings, setSettings] = useState({
        tags: [],
        _id: "",

        org: "",

        gridX: "",
        gridY: "",
        gridXName1: "",
        gridXName2: "",
        gridXName3: "",
        gridXName4: "",
        gridXName5: "",
        gridXName6: "",
        gridYName1: "",
        gridYName2: "",
        gridYName3: "",
        gridYName4: "",
        gridYName5: "",
        gridYName6: "",

        systemvalueKNum: "",
        systemvalueKLevel1: "",
        systemvalueKLevel2: "",
        systemvalueKLevel3: "",
        systemvalueKLevel4: "",

        systemvalueINum: "",
        systemvalueILevel1: "",
        systemvalueILevel2: "",
        systemvalueILevel3: "",
        systemvalueILevel4: "",

        systemvalueTNum: "",
        systemvalueTLevel1: "",
        systemvalueTLevel2: "",
        systemvalueTLevel3: "",
        systemvalueTLevel4: "",

        systemvalueONum: "",
        systemvalueOLevel1: "",
        systemvalueOLevel2: "",
        systemvalueOLevel3: "",
        systemvalueOLevel4: "",

        systemvalueENum: "",
        systemvalueELevel1: "",
        systemvalueELevel2: "",
        systemvalueELevel3: "",
        systemvalueELevel4: "",

        currencySymbolBefore: "",
        currencySymbolAfter: "",

        apiPath: "",

        date: "",
        __v: 0
    });

    function updateLocalstorageSettings(settings) {
        let user = localStorage.getItem("user");
        if (user != null) {
            user = JSON.parse(user);
            if (user != null) {
                user["settings"] = settings.appliedSettings;
                localStorage.setItem("user", JSON.stringify(user));
            }
        }
    }

    async function fetchData() {
        axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/settings/byorg/") + auth.user["userdata"]["unit"][0],
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/orgelements/") + auth.user["userdata"]["unit"][0],
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/occurenceTypes/bysettings"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/orgelements/"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/consequenceTypes/bysettings"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
        ])
        .then(axios.spread((...res) => {
            setSettings(res[0].data.mySettings);
            setSettingsInherited(res[0].data.parentsSettings);
            updateLocalstorageSettings(res[0].data);
            setInheriting(res[1].data[0]["settingsInheritence"]);
            setOrg(res[1].data[0]);
            setOccurenceTypes(res[2].data.occurenceTypes);
            setOccurenceTypesConnectedOccurences(res[2].data.occurences);
            setOrgParents(res[2].data.parentArray)

            setConsequenceTypes(res[4].data.consequenceTypes)
            setConsequenceTypesConnectedConsequences(res[4].data.consequences)
            setConsequenceTypesOrgParents(res[4].data.parentArray)

            setOrgs(res[3].data);
        }));
    }

    useEffect(() => {
        if (auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "settings" && rule.objSubType == "settings").actions.read != "allow") { auth.signout(); }
        fetchData();
    }, []);

    return(
        <div className="maincontent">
            <SiteAssistant
                dockedLogo={DiriFaceDocked}
                featurekey="settings"
            />
            <div className="systems-container settings">
                <div className="pagetitle-container">
                    <h1>{t("settings.page_name")}</h1>
                </div>
                <Accordion>
                    <h3>{t("settings.general")}</h3>
                    <SettingsGeneral
                        settings={settings}
                        settingsInherited={settingsInherited}
                        onSettingsGeneralChanged={() => {
                            fetchData();
                        }}
                        org={org}
                        userHasEditRights={userHasEditRights}
                    >
                    </SettingsGeneral>
                    <hr/>
                    <h3>{t("settings.customfields")}</h3>
                    <SettingsCustomFields
                        settings={settings}
                        settingsInherited={settingsInherited}
                        onSettingsGeneralChanged={() => {
                            fetchData();
                        }}
                        userHasEditRights={userHasEditRights}
                    >
                    </SettingsCustomFields>
                    <hr/>
                    <h3>{t("settings.types")}</h3>
                    <SettingsTypeNCategory
                        settingProps={settings}
                        settingsInherited={settingsInherited}
                        onSettingsGeneralChanged={() => {
                            fetchData();
                        }}
                        occurenceTypes={occurenceTypes}
                        occurenceTypesConnectedOccurences={occurenceTypesConnectedOccurences}
                        orgParents={orgParents}
                        consequenceTypes={consequenceTypes}
                        consequenceTypesConnectedConsequences={consequenceTypesConnectedConsequences}
                        consequenceTypesOrgParents={consequenceTypesOrgParents}
                        org={orgs}
                        userHasEditRights={userHasEditRights}
                    >
                    </SettingsTypeNCategory>
                </Accordion>
            </div>
        </div>
    );
}
 
export default Settings;