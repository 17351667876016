const VATtypes = [
    {
      "country": "Australia",
      "enum": "au_abn",
      "description": "Australian Business Number (AU ABN)",
      "example": "12345678912"
    },
    {
      "country": "Australia",
      "enum": "au_arn",
      "description": "Australian Taxation Office Reference Number",
      "example": "123456789123"
    },
    {
      "country": "Austria",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "ATU12345678"
    },
    {
      "country": "Belgium",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "BE0123456789"
    },
    {
      "country": "Brazil",
      "enum": "br_cnpj",
      "description": "Brazilian CNPJ number",
      "example": "01.234.456/5432-10"
    },
    {
      "country": "Brazil",
      "enum": "br_cpf",
      "description": "Brazilian CPF number",
      "example": "123.456.789-87"
    },
    {
      "country": "Bulgaria",
      "enum": "bg_uic",
      "description": "Bulgaria Unified Identification Code",
      "example": "123456789"
    },
    {
      "country": "Bulgaria",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "BG0123456789"
    },
    {
      "country": "Canada",
      "enum": "ca_bn",
      "description": "Canadian BN",
      "example": "123456789"
    },
    {
      "country": "Canada",
      "enum": "ca_gst_hst",
      "description": "Canadian GST/HST number",
      "example": "123456789RT0002"
    },
    {
      "country": "Canada",
      "enum": "ca_pst_bc",
      "description": "Canadian PST number (British Columbia)",
      "example": "PST-1234-5678"
    },
    {
      "country": "Canada",
      "enum": "ca_pst_mb",
      "description": "Canadian PST number (Manitoba)",
      "example": "123456-7"
    },
    {
      "country": "Canada",
      "enum": "ca_pst_sk",
      "description": "Canadian PST number (Saskatchewan)",
      "example": "1234567"
    },
    {
      "country": "Canada",
      "enum": "ca_qst",
      "description": "Canadian QST number (Québec)",
      "example": "1234567890TQ1234"
    },
    {
      "country": "Chile",
      "enum": "cl_tin",
      "description": "Chilean TIN",
      "example": "12.345.678-K"
    },
    {
      "country": "Croatia",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "HR12345678912"
    },
    {
      "country": "Cyprus",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "CY12345678Z"
    },
    {
      "country": "Czech Republic",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "CZ1234567890"
    },
    {
      "country": "Denmark",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "DK12345678"
    },
    {
      "country": "Estonia",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "EE123456789"
    },
    {
      "country": "European Union",
      "enum": "eu_oss_vat",
      "description": "European One Stop Shop VAT number for non-Union scheme",
      "example": "EU123456789"
    },
    {
      "country": "Finland",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "FI12345678"
    },
    {
      "country": "France",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "FRAB123456789"
    },
    {
      "country": "Georgia",
      "enum": "ge_vat",
      "description": "Georgian VAT",
      "example": "123456789"
    },
    {
      "country": "Germany",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "DE123456789"
    },
    {
      "country": "Greece",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "EL123456789"
    },
    {
      "country": "Hong Kong",
      "enum": "hk_br",
      "description": "Hong Kong BR number",
      "example": "12345678"
    },
    {
      "country": "Hungary",
      "enum": "hu_tin",
      "description": "Hungary tax number (adószám)",
      "example": "12345678-1-23"
    },
    {
      "country": "Hungary",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "HU12345678912"
    },
    {
      "country": "Iceland",
      "enum": "is_vat",
      "description": "Icelandic VAT",
      "example": "123456"
    },
    {
      "country": "India",
      "enum": "in_gst",
      "description": "Indian GST number",
      "example": "12ABCDE3456FGZH"
    },
    {
      "country": "Indonesia",
      "enum": "id_npwp",
      "description": "Indonesian NPWP number",
      "example": "12.345.678.9-012.345"
    },
    {
      "country": "Ireland",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "IE1234567AB"
    },
    {
      "country": "Israel",
      "enum": "il_vat",
      "description": "Israel VAT",
      "example": "000012345"
    },
    {
      "country": "Italy",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "IT12345678912"
    },
    {
      "country": "Japan",
      "enum": "jp_cn",
      "description": "Japanese Corporate Number (*Hōjin Bangō*)",
      "example": "1234567891234"
    },
    {
      "country": "Japan",
      "enum": "jp_rn",
      "description": "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
      "example": "12345"
    },
    {
      "country": "Latvia",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "LV12345678912"
    },
    {
      "country": "Liechtenstein",
      "enum": "li_uid",
      "description": "Liechtensteinian UID number",
      "example": "CHE123456789"
    },
    {
      "country": "Lithuania",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "LT123456789123"
    },
    {
      "country": "Luxembourg",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "LU12345678"
    },
    {
      "country": "Malaysia",
      "enum": "my_frp",
      "description": "Malaysian FRP number",
      "example": "12345678"
    },
    {
      "country": "Malaysia",
      "enum": "my_itn",
      "description": "Malaysian ITN",
      "example": "C 1234567890"
    },
    {
      "country": "Malaysia",
      "enum": "my_sst",
      "description": "Malaysian SST number",
      "example": "A12-3456-78912345"
    },
    {
      "country": "Malta",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "MT12345678"
    },
    {
      "country": "Mexico",
      "enum": "mx_rfc",
      "description": "Mexican RFC number",
      "example": "ABC010203AB9"
    },
    {
      "country": "Netherlands",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "NL123456789B12"
    },
    {
      "country": "New Zealand",
      "enum": "nz_gst",
      "description": "New Zealand GST number",
      "example": "123456789"
    },
    {
      "country": "Norway",
      "enum": "no_vat",
      "description": "Norwegian VAT number",
      "example": "123456789MVA"
    },
    {
      "country": "Poland",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "PL1234567890"
    },
    {
      "country": "Portugal",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "PT123456789"
    },
    {
      "country": "Romania",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "RO1234567891"
    },
    {
      "country": "Russia",
      "enum": "ru_inn",
      "description": "Russian INN",
      "example": "1234567891"
    },
    {
      "country": "Russia",
      "enum": "ru_kpp",
      "description": "Russian KPP",
      "example": "123456789"
    },
    {
      "country": "Saudi Arabia",
      "enum": "sa_vat",
      "description": "Saudi Arabia VAT",
      "example": "123456789012345"
    },
    {
      "country": "Singapore",
      "enum": "sg_gst",
      "description": "Singaporean GST",
      "example": "M12345678X"
    },
    {
      "country": "Singapore",
      "enum": "sg_uen",
      "description": "Singaporean UEN",
      "example": "123456789F"
    },
    {
      "country": "Slovakia",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "SK1234567891"
    },
    {
      "country": "Slovenia",
      "enum": "si_tin",
      "description": "Slovenia tax number (davčna številka)",
      "example": "12345678"
    },
    {
      "country": "Slovenia",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "SI12345678"
    },
    {
      "country": "South Africa",
      "enum": "za_vat",
      "description": "South African VAT number",
      "example": "4123456789"
    },
    {
      "country": "South Korea",
      "enum": "kr_brn",
      "description": "Korean BRN",
      "example": "123-45-67890"
    },
    {
      "country": "Spain",
      "enum": "es_cif",
      "description": "Spanish CIF number",
      "example": "A12345678"
    },
    {
      "country": "Spain",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "ESA1234567Z"
    },
    {
      "country": "Sweden",
      "enum": "eu_vat",
      "description": "European VAT number",
      "example": "SE123456789123"
    },
    {
      "country": "Switzerland",
      "enum": "ch_vat",
      "description": "Switzerland VAT number",
      "example": "CHE-123.456.789 MWST"
    },
    {
      "country": "Taiwan",
      "enum": "tw_vat",
      "description": "Taiwanese VAT",
      "example": "12345678"
    },
    {
      "country": "Thailand",
      "enum": "th_vat",
      "description": "Thai VAT",
      "example": "1234567891234"
    },
    {
      "country": "Ukraine",
      "enum": "ua_vat",
      "description": "Ukrainian VAT",
      "example": "123456789"
    },
    {
      "country": "United Arab Emirates",
      "enum": "ae_trn",
      "description": "United Arab Emirates TRN",
      "example": "123456789012345"
    },
    {
      "country": "United Kingdom",
      "enum": "gb_vat",
      "description": "United Kingdom VAT number",
      "example": "GB123456789"
    },
    {
      "country": "United Kingdom",
      "enum": "eu_vat",
      "description": "Northern Ireland VAT number",
      "example": "XI123456789"
    },
    {
      "country": "United States",
      "enum": "us_ein",
      "description": "United States EIN",
      "example": "12-3456789"
    }
]

export { VATtypes };