import * as React from "react";

import { useTranslate } from "react-polyglot";

import Field from "../../../../../Shared/Wizard/Field/Field";
import { useDynamicLanguage } from "../../../../../../DynamicLanguageProvider";
import { getOperationStatus } from "../../../../../../Assets/Utils/System/system";

function SystemInformationSystem(props) {
  const t = useTranslate();
  const td = useDynamicLanguage().td;

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  function percentageToValue(percentage, maxvalue) {
    return Math.round((maxvalue - 1) * (percentage / 100) + 1);
  }

  return (
    <div className="systemtype">
      <Field
        type="input"
        value={props.system["ownerPersonReadable"]}
        title={t("system.information.owner")}
        disabled={true}
      ></Field>
      <Field
        type="input"
        value={props.system["responsiblePersonReadable"]}
        title={t("system.information.responsibleperson")}
        disabled={true}
      ></Field>
      <Field
        type="input"
        value={props.system["numberofusers"]}
        title={t("system.information.numberofusers")}
        disabled={true}
      ></Field>
      <Field
        type="input"
        value={t(getOperationStatus(props.system["operationStatus"]))}
        title={t("system.information.operationstatus")}
        disabled={true}
      ></Field>
      <Field
        type="input"
        value={
          percentageToValue(
            props.system["availability"],
            settings["systemvalueTNum"]
          ) == 1
            ? td(settings["systemvalueTLevel1"])
            : percentageToValue(
                props.system["availability"],
                settings["systemvalueTNum"]
              ) == 2
            ? td(settings["systemvalueTLevel2"])
            : percentageToValue(
                props.system["availability"],
                settings["systemvalueTNum"]
              ) == 3
            ? td(settings["systemvalueTLevel3"])
            : percentageToValue(
                props.system["availability"],
                settings["systemvalueTNum"]
              ) == 4
            ? td(settings["systemvalueTLevel4"])
            : percentageToValue(
                props.system["availability"],
                settings["systemvalueTNum"]
              ) == 5
            ? td(settings["systemvalueTLevel5"])
            : percentageToValue(
                props.system["availability"],
                settings["systemvalueTNum"]
              ) == 6
            ? td(settings["systemvalueTLevel6"])
            : ""
        }
        title={t("system.information.availability")}
        disabled={true}
      ></Field>
    </div>
  );
}

export default SystemInformationSystem;
