import React, { useState, useEffect, useRef } from "react";

import { InputGroup, Form, Button } from "react-bootstrap";
import { useTranslate } from "react-polyglot";

import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";

import { generateUrl } from "../../../../../config";

function FileUpload(props) {
  const auth = useAuth();
  const t = useTranslate();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;

  const [files, setFiles] = useState<Array<any>>([]);

  const progressbar = useRef<any>();

  const handleChange = (target, fileKey) => {
    if (target.files[0] != undefined) {
      if (files[fileKey].fileId == "") {
        let tempFilename = target.value.split("\\")[
          target.value.split("\\").length - 1
        ];
        tempFilename = tempFilename.split(".").slice(0, -1).join(".");
        saveFile(
          fileKey,
          target.files[0],
          tempFilename,
          target.value
            .split("\\")
            [target.value.split("\\").length - 1].split(".")[
            target.value
              .split("\\")
              [target.value.split("\\").length - 1].split(".").length - 1
          ]
        );
      } else {
        handleDeleteFile("", files[fileKey].fileId);
        let tempFilename = target.value.split("\\")[
          target.value.split("\\").length - 1
        ];
        tempFilename = tempFilename.split(".").slice(0, -1).join(".");
        saveFile(
          fileKey,
          target.files[0],
          tempFilename,
          target.value
            .split("\\")
            [target.value.split("\\").length - 1].split(".")[
            target.value
              .split("\\")
              [target.value.split("\\").length - 1].split(".").length - 1
          ]
        );
      }
    }
  };

  const saveFile = (fileKey, file, filename, filetype) => {
    var reader = new FileReader();
    progressbar.current.style.height = 4 + "px";
    progressbar.current.style.width = 20 + "%";
    try {
      reader.readAsDataURL(file);
      reader.onload = (evt) => {
        //let result = evt.target != null ? evt.target.result : null;
        const formData = new FormData();
        formData.append("fileupload", file);
        formData.append("filename", filename);
        formData.append("filetype", filetype);
        axios
          .post(generateUrl("/api/cra/files/"), formData, {
            headers: { "x-access-token": auth.user["token"] },
            onUploadProgress: (progressEvent) => {
              let percentage =
                (progressEvent.loaded / progressEvent.total) * 100;
              if (percentage < 20) percentage = 20;
              progressbar.current.style.width = percentage + "%";
            },
          })
          .then(function (response) {
            if (response.data["error"] == undefined) {
              let temp = files;
              temp[fileKey] = {
                fileName: response.data["filename"],
                fileId: response.data["_id"],
                fileType: response.data["filetype"],
              };
              setFiles(JSON.parse(JSON.stringify(temp)));

              if (props.onChange != undefined) {
                props.onChange(JSON.parse(JSON.stringify(temp)));
              }

              progressbar.current.style.height = 0 + "px";
              progressbar.current.style.width = 20 + "%";
            } else {
              alert("This file is too large!");
            }
          });
      };
    } catch (err) {
      console.log(err);
    }
  };

  function getFile(fileKey, fileId) {
    progressbar.current.style.height = 4 + "px";
    progressbar.current.style.width = 5 + "%";
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/files/") + fileId,
          responseType: "arraybuffer",
          headers: { "x-access-token": auth.user["token"] },
          onDownloadProgress: (progressEvent) => {
            let percentage = (progressEvent.loaded / progressEvent.total) * 100;
            if (percentage < 5) percentage = 5;
            progressbar.current.style.width = percentage + "%";
          },
        }),
      ])
      .then(
        axios.spread((...res) => {
          let blob = new Blob([res[0].data], { type: "application/*" });
          let objectUrl = URL.createObjectURL(blob);

          var element = document.createElement("a");
          element.setAttribute("href", objectUrl);
          element.setAttribute(
            "download",
            files[fileKey].fileName + "." + files[fileKey].fileType
          );

          element.style.display = "none";
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);

          progressbar.current.style.height = 0 + "px";
          progressbar.current.style.width = 5 + "%";
        })
      );
  }

  function handleDeleteFile(fileKey, fileId) {
    if (fileId == "") {
      let temp = files;
      temp.splice(fileKey, 1);
      setFiles(JSON.parse(JSON.stringify(temp)));
    } else {
      if (
        props.options != undefined &&
        props.options.multiple != undefined &&
        props.options.multiple == true
      ) {
        axios
          .all([
            axios.single({
              method: "delete",
              url: generateUrl("/api/cra/files/") + fileId,
              responseType: "json",
              headers: { "x-access-token": auth.user["token"] },
            }),
          ])
          .then(
            axios.spread((...res) => {
              if (fileKey !== "") {
                let temp = files;
                temp.splice(fileKey, 1);
                setFiles(JSON.parse(JSON.stringify(temp)));

                if (props.onChange != undefined) {
                  props.onChange(JSON.parse(JSON.stringify(temp)));
                }
              }
            })
          );
      } else {
        axios
          .all([
            axios.single({
              method: "delete",
              url: generateUrl("/api/cra/files/") + fileId,
              responseType: "json",
              headers: { "x-access-token": auth.user["token"] },
            }),
          ])
          .then(
            axios.spread((...res) => {
              setFiles([{ fileName: "", fileId: "", fileType: "" }]);

              if (props.onChange != undefined) {
                props.onChange([{ fileName: "", fileId: "", fileType: "" }]);
              }
            })
          );
      }
    }
  }

  useEffect(() => {
    if (
      props.value != undefined &&
      props.value != "" &&
      props.value.fileId != undefined
    ) {
      setFiles([
        {
          fileName: props.value.fileName,
          fileId: props.value.fileId,
          fileType: props.value.fileType,
        },
      ]);
    } else {
      setFiles(
        props.value != undefined && props.value != ""
          ? props.value
          : props.defaultValue != undefined
          ? props.defaultValue
          : props.options == undefined ||
            props.options.multiple == undefined ||
            props.options.multiple == false
          ? [{ fileName: "", fileId: "", fileType: "" }]
          : []
      );
    }
  }, []);

  return (
    <div className="fileuploadfield">
      <h3>{props.title}</h3>
      {files.map((file, key) => (
        <InputGroup key={key} className="mb-3">
          <div
            ref={progressbar}
            className="progressbar"
            style={{
              height: "0px",
              backgroundColor: "#facd75",
              transition: "width 100ms",
              width: "0%",
            }}
          ></div>
          {file.fileName != undefined && file.fileName != "" ? (
            <InputGroup.Prepend className="filenamelabel">
              <InputGroup.Text>
                <span style={{ fontWeight: 600 }}>
                  {file.fileName + "." + file.fileType}
                </span>
              </InputGroup.Text>
            </InputGroup.Prepend>
          ) : (
            ""
          )}
          <Form.File
            name="fileupload"
            id={props.title}
            label={
              file.fileName == ""
                ? t("wizard_title.fileupload.selectfiletext")
                : t("wizard_title.fileupload.changefiletext")
            }
            disabled={props.disabled}
            onChange={(evt) => handleChange(evt.target, key)}
            custom
          />
          {file.fileName != undefined && file.fileName != "" ? (
            <InputGroup.Append
              className="downloadbtn"
              style={{ cursor: "pointer" }}
              onClick={() => getFile(key, file.fileId)}
            >
              <InputGroup.Text>
                <span style={{ fontWeight: 600 }}>
                  {t("wizard_title.fileupload.download")}
                </span>
              </InputGroup.Text>
            </InputGroup.Append>
          ) : (
            ""
          )}
          {((props.options != undefined &&
            props.options.multiple != undefined &&
            props.options.multiple == true) ||
            file.fileName != "") &&
          !props.disabled ? (
            <InputGroup.Append
              className="deletebtn"
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteFile(key, file.fileId)}
            >
              <InputGroup.Text>
                <span style={{ fontWeight: 600 }}>
                  {t("wizard_title.fileupload.delete")}
                </span>
              </InputGroup.Text>
            </InputGroup.Append>
          ) : null}
        </InputGroup>
      ))}
      {props.options != undefined &&
      props.options.multiple != undefined &&
      props.options.multiple == true &&
      !props.disabled ? (
        <div className="newfilebutton">
          <Button
            onClick={() =>
              setFiles([...files, { fileName: "", fileId: "", fileType: "" }])
            }
          >
            {t("wizard_title.fileupload.addfilebtn")}
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default FileUpload;
