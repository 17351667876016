import * as React from "react";

import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Modal, Button } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";

import LocaleHandler from "../../../LocaleHandler";
import PaymentModal from "../Modals/PaymentModal/PaymentModal";
import TextDynamic from "../TextDynamic/TextDynamic";
import LangSwitcher from "./../LangSwitcher/LangSwitcher";

import { generateUrl } from "../../../config";

import "./Shop.scss";
import "react-toastify/dist/ReactToastify.css";

function Shop(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();
  const history = useHistory();
  const { setLocale, locale } = React.useContext(LocaleHandler);

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  let userdata = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
    userdata = JSON.parse(localStorageUser)["userdata"];
  }

  const [allOrganizationRoles, setAllOrganizationRoles] = useState<Array<any>>(
    []
  );
  const [ownedOrgRoleIds, setOwnedOrgRoleIds] = useState<Array<any>>([]);
  const [org, setOrg] = useState<Array<any>>([]);

  const [chosenRole, setChosenRole] = useState({});

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");

  const [showFullContentList, setShowFullContentList] = useState<Array<any>>(
    []
  );
  const [showFullDescription, setShowFullDescription] = useState<Array<any>>(
    []
  );
  const [mainProducts, setMainProducts] = useState<Array<any>>([]);
  const [additionalProducts, setAdditionalProducts] = useState<Array<any>>([]);
  const [mainAnnualProducts, setMainAnnualProducts] = useState<Array<any>>([]);
  const [additionalAnnualProducts, setAdditionalAnnualProducts] = useState<
    Array<any>
  >([]);
  const [togglePriceInterval, setTogglePriceInterval] = useState("month");

  const [loading, setLoading] = useState(true);

  function fetchOrganizationRoles() {
    axios
      .single({
        method: "get",
        url: generateUrl("/api/cra/organizationRoles/byorgid/") + props.orgid,
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        setAllOrganizationRoles(res.data);
      });
  }

  function fetchOwnedRoleIds() {
    axios
      .single({
        method: "get",
        url: generateUrl("/api/orgelements/") + props.orgid,
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        setOwnedOrgRoleIds(res.data[0].orgRoles.map((role) => role.id));
        setOrg(res.data[0]);
      });
  }

  function goToOnboarding() {
    const params = new URLSearchParams(window.location.search);
    history.push("/welcome?lang=" + params.get("lang"));
  }

  function getPurchase(object) {
    /* axios.post(
            "/api/cra/stripe/purchase",
            {
                object: object,
                orgId: props.orgid
            },
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => { */
    setChosenRole(object);
    /* setClientSecret(res.data.client_secret);
            setPaymentIntentId(res.data.paymentIntentId); */
    //console.log(res.data);
    setShowPaymentModal(true);
    /* }); */
  }

  function getProducts() {
    let url =
      props.orgid != "" && auth.user["userdata"] != undefined
        ? generateUrl("/api/cra/stripe/products/byorgid/") + props.orgid
        : generateUrl("/api/cra/stripe/products");
    axios
      .all([
        axios.single({
          method: "get",
          url: url,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then((res) => {
        setMainProducts(
          res[0].data.filter(
            (p) => p.orgRole.mainAbo === true && p.interval == "month"
          )
        );
        setMainAnnualProducts(
          res[0].data.filter(
            (p) => p.orgRole.mainAbo === true && p.interval == "annual"
          )
        );
        setAdditionalProducts(
          res[0].data.filter(
            (p) => p.orgRole.mainAbo != true && p.interval == "month"
          )
        );
        setAdditionalAnnualProducts(
          res[0].data.filter(
            (p) => p.orgRole.mainAbo != true && p.interval == "annual"
          )
        );
        setLoading(false);
      });
  }

  function renderShopItem(product, key) {
    return (
      <div
        key={key}
        className={
          (product?.orgRole?.highestValue ? "shopItem-highestValue" : "") +
          " shopItem-container"
        }
      >
        {product?.orgRole?.highestValue ? (
          <div className="shopItem-highestValue-div">
            <p>{t("shop.highestvalue")}</p>
          </div>
        ) : null}
        <div
          className={
            "shopItem " +
            (ownedOrgRoleIds.includes(product?.orgRole?._id)
              ? "owned-shopItem"
              : "")
          }
        >
          <div>
            <p className="shopItem-price">
              {product?.price / 100 == 0
                ? t("shop.free")
                : product?.price / 100 + " " + product?.priceObject?.currency}
              {product?.price / 100 != 0 ? (
                <span>
                  {" "}
                  {togglePriceInterval == "month"
                    ? t("shop.perMonth")
                    : t("shop.perYear")}
                  *
                </span>
              ) : null}
            </p>
            <p className="shopItem-excludingVAT">*{t("shop.excludingVAT")}</p>
            <p className="shopItem-name">
              <TextDynamic locale={locale} id={product?.orgRole?.name} />
            </p>
            {product?.orgRole?.description != "" ? (
              <div
                onClick={() =>
                  !showFullDescription.includes(product?.orgRole?._id)
                    ? setShowFullDescription([
                        ...showFullDescription,
                        product?.orgRole?._id,
                      ])
                    : setShowFullDescription(
                        showFullDescription.filter(
                          (i) => i != product?.orgRole?._id
                        )
                      )
                }
                className={
                  (!showFullDescription.includes(product?.orgRole?._id)
                    ? "shopItem-shortDesc"
                    : "") + " shopItem-desc"
                }
              >
                <p className="shopItem-descParagraph">
                  <TextDynamic
                    locale={locale}
                    id={product?.orgRole?.description}
                  />
                </p>
              </div>
            ) : null}

            <ul>
              {product?.orgRole?.contentList
                ?.filter((item, idx) =>
                  !showFullContentList.includes(product?.orgRole?._id)
                    ? idx < 5
                    : idx >= 0
                )
                .map((item, key) => {
                  return (
                    <li key={key}>
                      <span className="shopItem-listIcon">
                        <i className="dripicons-checkmark" />
                      </span>
                      <span>
                        <TextDynamic locale={locale} id={item} />
                      </span>
                    </li>
                  );
                })}
              {product?.orgRole?.contentList?.length > 5 ? (
                <li
                  className="shopItem-listExpand"
                  onClick={() =>
                    !showFullContentList.includes(product?.orgRole?._id)
                      ? setShowFullContentList([
                          ...showFullContentList,
                          product?.orgRole?._id,
                        ])
                      : setShowFullContentList(
                          showFullContentList.filter(
                            (i) => i != product?.orgRole?._id
                          )
                        )
                  }
                >
                  {" "}
                  <span className="shopItem-listIcon">
                    <i className="dripicons-checkmark" />
                  </span>
                  <span></span> + {product?.orgRole?.contentList.length - 5}{" "}
                  {t("shop.benefits")}{" "}
                  <i
                    className={
                      !showFullContentList.includes(product?.orgRole?._id)
                        ? "dripicons-chevron-down"
                        : "dripicons-chevron-up"
                    }
                  />
                </li>
              ) : null}
            </ul>
          </div>
        </div>
        <Button
          disabled={
            auth.user["userdata"] == undefined
              ? false
              : ownedOrgRoleIds.includes(product?.orgRole?._id) ||
                org["parent"] == "" ||
                auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
                  (rule) =>
                    rule.objType == "organization" &&
                    rule.objSubType == "organization"
                )?.actions.handle_payment != "allow"
          }
          className={
            (ownedOrgRoleIds.includes(product?.orgRole?._id) ? "" : "") +
            " shopItem-btn"
          }
          onClick={() =>
            auth.user["userdata"] == undefined
              ? goToOnboarding()
              : getPurchase(product)
          }
        >
          {(props.orgid == undefined || props.orgid == "") &&
          auth.user["userdata"] == undefined
            ? t("shop.getStartedForFree")
            : ownedOrgRoleIds.includes(product?.orgRole?._id)
            ? t("shop.owned")
            : t("shop.buy")}
        </Button>
      </div>
    );
  }

  function renderShopContent() {
    return (
      <>
        <div className="shopHeader">
          {props.headless ? null : (
            <div className="shopHeader_head">
              <div className="shopHeader_title">
                <h2>{t("shop.header.title")}</h2>
                <p>{t("shop.header.desc")}</p>
              </div>
              {auth.user["userdata"] == undefined ? (
                <div className="shop_langSwitcher">
                  <LangSwitcher
                    onChange={(newLang) => {
                      history.push("/shop?lang=" + newLang);
                    }}
                  ></LangSwitcher>
                </div>
              ) : null}
            </div>
          )}
          <div
            onClick={() =>
              setTogglePriceInterval(
                togglePriceInterval == "month" ? "annual" : "month"
              )
            }
            className="shopToggleInterval"
          >
            <div
              className={
                "priceMonthly " +
                (togglePriceInterval == "month" ? "priceActive" : "")
              }
            >
              <p>{t("shop.header.month")}</p>
            </div>
            <div
              className={
                "priceAnnual " +
                (togglePriceInterval == "annual" ? "priceActive" : "")
              }
            >
              <p>{t("shop.header.annual")}</p>
            </div>
          </div>
        </div>
        {loading ? (
          <p className="shoploadingtext">{t("shop.loading")}</p>
        ) : null}

        <div className="shopItems">
          {!loading
            ? (togglePriceInterval == "month"
                ? mainProducts
                : mainAnnualProducts
              ).map((product, key) => {
                if (
                  product.orgRole != undefined &&
                  product.orgRole.hidden != true
                ) {
                  return renderShopItem(product, key);
                }
              })
            : null}
        </div>

        {!loading &&
        !props.mainOnly &&
        (togglePriceInterval == "month"
          ? additionalProducts
          : additionalAnnualProducts
        ).length != 0 ? (
          <>
            <br />
            <hr />
            <br />
            <div className="shopHeader">
              <h2>{t("shop.header.additionalServices")}</h2>
              <p>{t("shop.header.additionalServicesDesc")}</p>
            </div>

            <div className="shopItems">
              {(togglePriceInterval == "month"
                ? additionalProducts
                : additionalAnnualProducts
              ).map((product, key) => {
                if (
                  product.orgRole != undefined &&
                  product.orgRole.hidden != true
                ) {
                  return renderShopItem(product, key);
                }
              })}
            </div>
          </>
        ) : null}

        {props.noFooter ? null : auth.user != undefined &&
          auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
            (rule) =>
              rule.objType == "organization" &&
              rule.objSubType == "organization"
          )?.actions.handle_payment != "allow" ? (
          <div className="shop-contactFinanceResponsible">
            <p>{t("shop.contactFinanceResponsible")}</p>
          </div>
        ) : null}
      </>
    );
  }

  useEffect(() => {
    if (props.orgid != "" && auth.user["userdata"] != undefined) {
      fetchOrganizationRoles();
      fetchOwnedRoleIds();
    }
    getProducts();
  }, [props.orgid, props.show]);

  useEffect(() => {
    if (window.location.search != "") {
      const params = new URLSearchParams(window.location.search);
      setLocale(params.get("lang"));
    }
  }, []);

  return (
    <div>
      {props.modal != false ? (
        <Modal
          className="shop"
          show={props.show}
          size={props.size}
          onHide={() => {
            setMainProducts([]);
            if (props.onHide != undefined) props.onHide();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("shop.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{renderShopContent()}</Modal.Body>
          <Modal.Footer>
            <div>
              <Button onClick={() => props.onHide()}>{t("shop.close")}</Button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : (
        <div className="shop">{renderShopContent()}</div>
      )}

      <PaymentModal
        product={chosenRole}
        clientSecret={clientSecret}
        show={showPaymentModal}
        onHide={() => setShowPaymentModal(false)}
        paymentIntentId={paymentIntentId}
        orgid={props.orgid}
        cancelUrl={props.cancelUrl}
        successUrl={props.successUrl}
        successTransferUrl={props.successTransferUrl}
        onPurchaseComplete={props.onPurchaseComplete}
      ></PaymentModal>
    </div>
  );
}

export default Shop;
