import React, { useEffect } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { useDynamicLanguage } from '../../../../../DynamicLanguageProvider';

function TextField(props) {
    const dynamicLanguage = useDynamicLanguage();
    const td = dynamicLanguage.td;

    const handleChange = (value) => {
        if (props.onChange != undefined) {
            props.onChange(value);
        }
    }

    const myRef = React.useRef<any>();

    useEffect(() => {
        if(props.setFocus != undefined){
            myRef.current.focus();
        }
        
    }, [props.setFocus]);

    return(
        <InputGroup className="mb-3">
            {props.title != undefined ?
                <InputGroup.Prepend>
                    <InputGroup.Text>{props.title.length == 24 && !props.title.includes(" ") ? td(props.title) : props.title}</InputGroup.Text>
                </InputGroup.Prepend>
            : null}
            <Form.Control ref={myRef} type={ props?.options?.password ? "password" : "text"} disabled={props.disabled} placeholder={props.placeholder != undefined ? props.placeholder : ""} onChange={(evt) => handleChange(evt.target.value)} value={props.value != undefined && props.value != "" ? props.value : (props.defaultValue != undefined ? props.defaultValue : "")} />
        </InputGroup>
    );
}

export default TextField;