import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { Modal, InputGroup, Form, Button } from "react-bootstrap";
import { Slider } from "@material-ui/core";

import { useAuth } from "../../../../Auth";
import { useAxios } from "../../../../AxiosHandler";

import Field from "../../../Shared/Wizard/Field/Field";
import {
  treatmentTypes,
  treatmentClasses,
} from "../../../../Assets/Constants/Constants";
import { useDynamicLanguage } from "../../../../DynamicLanguageProvider";

import { generateUrl } from "../../../../config";

function CreateTreatmentModal(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();
  let { systemid } = useParams();
  const myRef = React.useRef<any>();
  const td = useDynamicLanguage().td;

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  let userdata = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
    userdata = JSON.parse(localStorageUser)["userdata"];
  }

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const [users, setUsers] = useState([]);

  const [newTreatmentName, setNewTreatmentName] = useState("");
  const [newTreatmentDuetime, setNewTreatmentDuetime] = useState("");
  const [newTreatmentDescription, setNewTreatmentDescription] = useState("");
  const [newTreatmentType, setNewTreatmentType] = useState("");
  const [newTreatmentClass, setNewTreatmentClass] = useState("");
  const [newTreatmentCost, setNewTreatmentCost] = useState("");
  const [newTreatmentOnetimecost, setNewTreatmentOnetimecost] = useState("");
  const [
    newTreatmentDocumentationlink,
    setNewTreatmentDocumentationlink,
  ] = useState("");
  const [newTreatmentResponsible, setNewTreatmentResponsible] = useState("");
  const [
    newTreatmentProbabilityreduction,
    setNewTreatmentProbabilityreduction,
  ] = useState(0);
  const [newTreatmentStatus, setNewTreatmentStatus] = useState("open");
  const [newTreatmentCustomfields, setNewTreatmentCustomfields] = useState([
    {},
  ]);
  const [newTreatmentAccessRules, setNewTreatmentAccessRules] = useState({
    shared: [
      {
        type: "organization",
        actions: [] as any,
      },
    ],
    users: [] as any,
  });

  const [savingTreatment, setSavingTreatment] = useState(false);

  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const [shareWithUser, setShareWithUser] = useState("");
  const [shareWithUsersOptions, setShareWithUsersOptions] = useState<
    Array<any>
  >([]);

  const [chosenSystemId, setChosenSystemId] = useState("");
  const [systemSelectOptions, setSystemSelectOptions] = useState([]);

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [newTreatmentCopyof, setNewTreatmentCopyof] = useState("");
  const [newTreatmentOptions, setNewTreatmentOptions] = useState([{}]);

  const [showPublicRow, setShowPublicRow] = useState(false);
  const [
    showSharePublicWarningModal,
    setShowSharePublicWarningModal,
  ] = useState(false);
  const [sharePublicConfirmed, setSharePublicConfirmed] = useState(false);

  const [
    showTreatmentAccessControlModal,
    setShowTreatmentAccessControlModal,
  ] = useState(false);
  const [
    searchTreatmentTimeoutId,
    setSearchTreatmentTimeoutId,
  ] = useState<any>();

  const handleCloseNewTreatmentModal = () => {
    props.onHide();
    setNewTreatmentCost("");
    setNewTreatmentProbabilityreduction(0);
    setNewTreatmentStatus("open");
    setShowPublicRow(false);
    setNewTreatmentAccessRules({
      shared: [
        /* {
                type: "public",
                actions: [] as any
            }, */
        {
          type: "organization",
          actions: [] as any,
        },
      ],
      users: [] as any,
    });
  };

  const newTreatmentNameChange = (value) => {
    clearTimeout(searchTreatmentTimeoutId);
    if (value.length > 1) {
      const timeOut = setTimeout(() => {
        axios
          .post(
            generateUrl("/api/cra/treatments/search"),
            {
              filter: value,
            },
            {
              headers: { "x-access-token": auth.user["token"] },
            }
          )
          .then(
            function (response) {
              setNewTreatmentOptions(response.data);
              setAutoCompleteOptions(
                response.data.map((item) => {
                  return { id: item._id, label: item.name };
                })
              );
            },
            {
              headers: { "x-access-token": auth.user["token"] },
            }
          );
      }, 500);
      setSearchTreatmentTimeoutId(timeOut);
    }
    setNewTreatmentName(value);
  };

  const setEditCustomTextfields = (value, key) => {
    let tempCustomefields = newTreatmentCustomfields;

    if (tempCustomefields != undefined) {
      if (tempCustomefields.find((item) => item["id"] == key) == undefined) {
        tempCustomefields.push({
          id: key,
          value: value,
        });
      } else {
        (tempCustomefields.find((item) => item["id"] == key) as any)[
          "value"
        ] = value;
      }
    } else {
      tempCustomefields = [
        {
          id: key,
          value: value,
        },
      ];
    }

    setNewTreatmentCustomfields(JSON.parse(JSON.stringify(tempCustomefields)));
  };

  function handleShowSharePublicWarningModal() {
    if (newTreatmentName == "") {
      notifyWarning(t("bowtie.toast_empty_name"));
      return undefined;
    }
    if (newTreatmentName.length < 3) {
      notifyWarning(t("bowtie.toast_length"));
      return undefined;
    }
    setShowSharePublicWarningModal(true);
  }

  const handleKeyDownTreatmentCreate = (event) => {
    if (event.key === "Enter") {
      handleCreateTreatment();
    }
  };

  async function fetchUsers() {
    await axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/users"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setUsers(res[0].data);
          setShareWithUsersOptions(
            res[0].data.map((d) => {
              return { label: d.email, value: d.email };
            })
          );
        })
      );
  }

  async function fetchSystems() {
    await axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systems/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          res[0].data = res[0].data.filter(
            (system) => system.systemType[0] == "orgros"
          );
          setSystemSelectOptions(
            res[0].data.map((system) => {
              return { label: system["nameReadable"], value: system._id };
            })
          );
        })
      );
  }

  const handleCreateTreatment = () => {
    if (newTreatmentName == "") {
      notifyWarning(t("bowtie.toast_empty_name"));
      return undefined;
    }
    if (newTreatmentName.length < 3) {
      notifyWarning(t("bowtie.toast_length"));
      return undefined;
    }
    setSavingTreatment(true);

    let newTreatment: any = {};
    if (props.treatmentType == "cause") {
      newTreatment = {
        newCost: newTreatmentCost,
        newOnetimecost: newTreatmentOnetimecost,
        newProbabilityreduction: newTreatmentProbabilityreduction,
      };
      if (newTreatmentCopyof.length != 0) {
        let fetchedTreatment = newTreatmentOptions.filter(
          (i) => i["_id"] == newTreatmentCopyof
        );
        newTreatment = {
          newCost: fetchedTreatment[0]["cost"],
          newOnetimecost: fetchedTreatment[0]["onetimecost"],
          newProbabilityreduction: fetchedTreatment[0]["probabilityreduction"],
        };
      } else {
        newTreatment = {
          newCost: newTreatmentCost,
          newOnetimecost: newTreatmentOnetimecost,
          newProbabilityreduction: newTreatmentProbabilityreduction,
        };
      }
      axios
        .post(
          generateUrl("/api/cra/treatments/"),
          {
            name: newTreatmentName,
            description: newTreatmentDescription,
            type: newTreatmentType,
            class: newTreatmentClass,
            customfields: newTreatmentCustomfields,
            cause: props.causeId,
            occurence: props.occurenceId,
            responsible: newTreatmentResponsible,
            duetime: newTreatmentDuetime,
            system: systemid,
            status: newTreatmentStatus,
            cost: newTreatment["newCost"],
            onetimecost: newTreatment["newOnetimecost"],
            documentationlink: newTreatmentDocumentationlink,
            accessRules: newTreatmentAccessRules,
            //probabilityreduction: (newTreatmentProbabilityreduction / settings["gridY"]) * 100,
            probabilityreduction: newTreatment["newProbabilityreduction"],
            copyof: newTreatmentCopyof,
            tags: [],
            global: false,
            creator: userdata["_id"],
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then(function (res) {
          if (props.onSave != undefined) props.onSave(res.data);
          setNewTreatmentName("");
          setNewTreatmentDescription("");
          setNewTreatmentType("");
          setNewTreatmentClass("");
          setNewTreatmentOnetimecost("");
          setNewTreatmentDocumentationlink("");
          setNewTreatmentDuetime("");
          notifySuccess(t("bowtie.toast_treatment_created"));
          setSavingTreatment(false);
          if (props.onTreatmentsChanged != undefined) {
            props.onTreatmentsChanged();
          }
          props.onHide();
        });
    } else if (props.treatmentType == "consequence") {
      newTreatment = {
        newCost: newTreatmentCost,
        newOnetimecost: newTreatmentOnetimecost,
        newProbabilityreduction: newTreatmentProbabilityreduction,
      };
      if (newTreatmentCopyof.length != 0) {
        let fetchedTreatment = newTreatmentOptions.filter(
          (i) => i["_id"] == newTreatmentCopyof
        );
        newTreatment = {
          newCost:
            fetchedTreatment[0]["cost"] != undefined
              ? fetchedTreatment[0]["cost"]
              : "",
          newOnetimecost:
            fetchedTreatment[0]["onetimecost"] != undefined
              ? fetchedTreatment[0]["onetimecost"]
              : "",
          newProbabilityreduction:
            fetchedTreatment[0]["probabilityreduction"] != undefined
              ? fetchedTreatment[0]["probabilityreduction"]
              : "",
        };
      } else {
        newTreatment = {
          newCost: newTreatmentCost,
          newOnetimecost: newTreatmentOnetimecost,
          newProbabilityreduction: newTreatmentProbabilityreduction,
        };
      }
      axios
        .post(
          generateUrl("/api/cra/treatments/"),
          {
            name: newTreatmentName,
            description: newTreatmentDescription,
            type: newTreatmentType,
            class: newTreatmentClass,
            customfields: newTreatmentCustomfields,
            consequence: props.consequenceId,
            occurence: props.occurenceId,
            responsible: newTreatmentResponsible,
            duetime: newTreatmentDuetime,
            system: systemid,
            status: newTreatmentStatus,
            cost: newTreatment["newCost"],
            onetimecost: newTreatment["newOnetimecost"],
            documentationlink: newTreatmentDocumentationlink,
            accessRules: newTreatmentAccessRules,
            probabilityreduction: newTreatment["newProbabilityreduction"],
            copyof: newTreatmentCopyof,
            tags: [],
            global: false,
            creator: userdata["_id"],
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then(function (res) {
          if (props.onSave != undefined) props.onSave(res.data);
          setNewTreatmentName("");
          setNewTreatmentDescription("");
          setNewTreatmentType("");
          setNewTreatmentClass("");
          setNewTreatmentOnetimecost("");
          setNewTreatmentDocumentationlink("");
          setNewTreatmentDuetime("");
          notifySuccess(t("bowtie.toast_treatment_created"));
          setSavingTreatment(false);
          if (props.onTreatmentsChanged != undefined) {
            props.onTreatmentsChanged();
          }
          props.onHide();
        });
    } else if (props.treatmentType == "global") {
      newTreatment = {
        newCost: newTreatmentCost,
        newOnetimecost: newTreatmentOnetimecost,
        newProbabilityreduction: newTreatmentProbabilityreduction,
      };
      if (newTreatmentCopyof.length != 0) {
        let fetchedTreatment = newTreatmentOptions.filter(
          (i) => i["_id"] == newTreatmentCopyof
        );
        newTreatment = {
          newCost:
            fetchedTreatment[0]["cost"] != undefined
              ? fetchedTreatment[0]["cost"]
              : "",
          newOnetimecost:
            fetchedTreatment[0]["onetimecost"] != undefined
              ? fetchedTreatment[0]["onetimecost"]
              : "",
          newProbabilityreduction:
            fetchedTreatment[0]["probabilityreduction"] != undefined
              ? fetchedTreatment[0]["probabilityreduction"]
              : "",
        };
      } else {
        newTreatment = {
          newCost: newTreatmentCost,
          newOnetimecost: newTreatmentOnetimecost,
          newProbabilityreduction: newTreatmentProbabilityreduction,
        };
      }

      axios
        .post(
          generateUrl("/api/cra/treatments/"),
          {
            name: newTreatmentName,
            description: newTreatmentDescription,
            type: newTreatmentType,
            class: newTreatmentClass,
            customfields: newTreatmentCustomfields,
            responsible: newTreatmentResponsible,
            duetime: newTreatmentDuetime,
            system: chosenSystemId,
            status: newTreatmentStatus,
            cost: newTreatment["newCost"],
            onetimecost: newTreatment["newOnetimecost"],
            documentationlink: newTreatmentDocumentationlink,
            accessRules: newTreatmentAccessRules,
            probabilityreduction: newTreatment["newProbabilityreduction"],
            copyof: newTreatmentCopyof,
            tags: [],
            global: true,
            creator: userdata["_id"],
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then(function (res) {
          if (props.onSave != undefined) props.onSave(res.data);
          setNewTreatmentName("");
          setNewTreatmentDescription("");
          setNewTreatmentType("");
          setNewTreatmentClass("");
          setNewTreatmentOnetimecost("");
          setNewTreatmentDocumentationlink("");
          setNewTreatmentDuetime("");
          notifySuccess(t("bowtie.toast_treatment_created"));
          setSavingTreatment(false);
          if (props.onTreatmentsChanged != undefined) {
            props.onTreatmentsChanged();
          }
          props.onHide();
        });
    }
    setNewTreatmentCost("");
    setNewTreatmentStatus("open");
    setNewTreatmentAccessRules({
      shared: [
        {
          type: "organization",
          actions: [] as any,
        },
      ],
      users: [] as any,
    });
    setNewTreatmentProbabilityreduction(0);
    setShowSharePublicWarningModal(false);
    setSharePublicConfirmed(false);
  };

  async function addSharedWithUser() {
    let pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    if (!pattern.test(shareWithUser)) {
      notifyWarning(t("treatment.toast_invite_invalid_email"));
      return undefined;
    }
    await axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/users/") + shareWithUser,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          if (
            res[0].data.id != undefined &&
            !newTreatmentAccessRules.users
              .map((u) => u.id)
              .includes(res[0].data.id.toString())
          ) {
            newTreatmentAccessRules.users.push({ ...res[0].data, actions: [] });
          }
          setShareWithUser("");
          setAutocompleteKey(autocompleteKey + 1);
        })
      );
  }

  function handleChangeAction(evt, action, id?: String, type?: String) {
    if (id != undefined) {
      if (evt) {
        setNewTreatmentAccessRules({
          ...newTreatmentAccessRules,
          users: newTreatmentAccessRules.users.map((i) => {
            if (i.id == id) {
              if (!i.actions.includes(action)) {
                i.actions.push(action);
              }
              return i;
            } else {
              return i;
            }
          }),
        });
      } else {
        setNewTreatmentAccessRules({
          ...newTreatmentAccessRules,
          users: newTreatmentAccessRules.users.map((i) => {
            if (i.id == id) {
              i.actions = i.actions.filter((a) => a != action);
              return i;
            } else {
              return i;
            }
          }),
        });
      }
    } else if (type != undefined) {
      if (evt) {
        setNewTreatmentAccessRules({
          ...newTreatmentAccessRules,
          shared: newTreatmentAccessRules.shared.map((i) => {
            if (i.type == type) {
              if (!i.actions.includes(action)) {
                i.actions.push(action);
              }
              return i;
            } else {
              return i;
            }
          }),
        });
      } else {
        setNewTreatmentAccessRules({
          ...newTreatmentAccessRules,
          shared: newTreatmentAccessRules.shared.map((i) => {
            if (i.type == type) {
              i.actions = i.actions.filter((a) => a != action);
              return i;
            } else {
              return i;
            }
          }),
        });
      }
    }
  }

  function isChecked(action, id?: String, type?: String) {
    if (id != undefined) {
      return (
        newTreatmentAccessRules.users
          .find((s) => s.id == id)
          ?.actions.includes(action) ?? false
      );
    } else if (type != undefined) {
      return newTreatmentAccessRules.shared
        .find((s) => s.type == type)
        ?.actions.includes(action);
    }
    return false;
  }

  useEffect(() => {
    fetchSystems();
    fetchUsers();
  }, []);

  return (
    <div>
      <Modal
        className="createTreatmentModal"
        show={props.show}
        onHide={handleCloseNewTreatmentModal}
        onEntered={() => myRef.current.focus()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("bowtie.measure_register")}</Modal.Title>
          <div>
            <span
              style={{ cursor: "pointer", padding: "0 20px" }}
              onClick={() => setShowTreatmentAccessControlModal(true)}
            >
              <i className="dripicons-user" />
              <i
                style={{ fontSize: "10px", position: "absolute" }}
                className="dripicons-lock"
              />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {t("bowtie.measure_name")} *
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Typeahead
                  id="newTreatmentNameInput"
                  onInputChange={newTreatmentNameChange}
                  onChange={(items) => {
                    if (items.length > 0) {
                      if (items[0]["id"] != undefined && items[0]["id"] != "") {
                        let selectedOption = newTreatmentOptions.find(
                          (item) => item["_id"] == items[0]["id"]
                        ) as any;
                        setNewTreatmentCopyof(items[0]["id"]);
                        setNewTreatmentCost(selectedOption["cost"]);
                        setNewTreatmentProbabilityreduction(
                          selectedOption["probabilityreduction"]
                        );
                      } else {
                        setNewTreatmentCopyof("");
                      }
                      newTreatmentNameChange(items[0]["label"]);
                    } else {
                      setNewTreatmentCopyof("");
                    }
                  }}
                  onKeyDown={handleKeyDownTreatmentCreate}
                  ref={myRef}
                  minLength={2}
                  emptyLabel={t("bowtie.no_results")}
                  options={autoCompleteOptions}
                />
              </InputGroup>
              <Field
                type="textarea"
                title={t("treatment.description")}
                value={newTreatmentDescription}
                onChange={(val) => setNewTreatmentDescription(val)}
              ></Field>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{t("treatment.type")}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  onChange={(evt) =>
                    setNewTreatmentType(
                      (evt.target as HTMLSelectElement).selectedOptions[0].value
                    )
                  }
                  value={newTreatmentType}
                >
                  <option value="">{t("users.select")}</option>
                  {treatmentTypes.map((i, key) => (
                    <option key={key} value={i.value}>
                      {t(i.label)}
                    </option>
                  ))}
                </Form.Control>
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{t("treatment.class")}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  onChange={(evt) =>
                    setNewTreatmentClass(
                      (evt.target as HTMLSelectElement).selectedOptions[0].value
                    )
                  }
                  value={newTreatmentClass}
                >
                  <option value="">{t("users.select")}</option>
                  {treatmentClasses.map((i, key) => (
                    <option key={key} value={i.value}>
                      {t(i.label)}
                    </option>
                  ))}
                </Form.Control>
              </InputGroup>
              <Field
                type="input"
                title={t("treatment.documentationlink")}
                value={newTreatmentDocumentationlink}
                onChange={(val) => setNewTreatmentDocumentationlink(val)}
              ></Field>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {t("bowtie.measure_responsible_person")}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Typeahead
                  id="newTreatmentResponsibleInput"
                  onInputChange={(value) => setNewTreatmentResponsible(value)}
                  onKeyDown={handleKeyDownTreatmentCreate}
                  onChange={(items) => {
                    if (items.length > 0) {
                      if (items[0]["id"] != undefined && items[0]["id"] != "") {
                        setNewTreatmentResponsible(items[0]["id"]);
                      } else {
                        setNewTreatmentResponsible("");
                      }
                    } else {
                      setNewTreatmentResponsible("");
                    }
                  }}
                  minLength={2}
                  emptyLabel={t("bowtie.no_results")}
                  options={users.map((user) => {
                    return { id: user["_id"], label: user["name"] };
                  })}
                />
              </InputGroup>
              <Field
                type="dateselector"
                title={t("treatment.duedate")}
                value={newTreatmentDuetime}
                onChange={(val) => setNewTreatmentDuetime(val)}
                showTimeSelect
              ></Field>
              <hr />
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {t("treatment.onetimecost")}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className="priceField">
                  {settings["currencySymbolBefore"] != undefined &&
                  settings["currencySymbolBefore"] != "" ? (
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {settings["currencySymbolBefore"]}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                  ) : (
                    ""
                  )}
                  <Form.Control
                    type="number"
                    onChange={(evt) =>
                      setNewTreatmentOnetimecost(evt.target.value)
                    }
                    value={newTreatmentOnetimecost}
                  />
                  <InputGroup.Append>
                    {settings["currencySymbolAfter"] != undefined &&
                    settings["currencySymbolAfter"] != "" ? (
                      <InputGroup.Text>
                        {settings["currencySymbolAfter"]}
                      </InputGroup.Text>
                    ) : (
                      ""
                    )}
                  </InputGroup.Append>
                </div>
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {t("bowtie.causes_measure_cost")}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className="priceField">
                  {settings["currencySymbolBefore"] != undefined &&
                  settings["currencySymbolBefore"] != "" ? (
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {settings["currencySymbolBefore"]}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                  ) : (
                    ""
                  )}
                  <Form.Control
                    type="number"
                    onKeyDown={handleKeyDownTreatmentCreate}
                    onChange={(evt) => setNewTreatmentCost(evt.target.value)}
                    value={newTreatmentCost}
                  />
                  <InputGroup.Append>
                    {settings["currencySymbolAfter"] != undefined &&
                    settings["currencySymbolAfter"] != "" ? (
                      <InputGroup.Text>
                        {settings["currencySymbolAfter"]}
                      </InputGroup.Text>
                    ) : (
                      ""
                    )}
                  </InputGroup.Append>
                </div>
              </InputGroup>
              <hr />
              <InputGroup className="mb-3">
                <p>{t("bowtie.causes_measure_probability_reduction")}</p>
                <Slider
                  onChange={(event, value) => {
                    setNewTreatmentProbabilityreduction(Number(value));
                  }}
                  defaultValue={0}
                  value={newTreatmentProbabilityreduction}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="off"
                  step={1}
                  min={0}
                  max={100}
                  marks={[
                    {
                      value: 5,
                      label: t("treatment.label_none"),
                    },
                    {
                      value: 25,
                      label: t("treatment.label_low"),
                    },
                    {
                      value: 50,
                      label: t("treatment.label_middel"),
                    },
                    {
                      value: 75,
                      label: t("treatment.label_high"),
                    },
                    {
                      value: 95,
                      label: t("treatment.label_extremely_high"),
                    },
                  ]}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{t("treatment.status")}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  onChange={(evt) =>
                    setNewTreatmentStatus(
                      (evt.target as HTMLSelectElement).selectedOptions[0].value
                    )
                  }
                  value={newTreatmentStatus}
                >
                  <option value="open">{t("treatment.open")}</option>
                  <option value="suggested">{t("treatment.suggested")}</option>
                  <option value="planned">{t("treatment.planned")}</option>
                  <option value="processing">
                    {t("treatment.processing")}
                  </option>
                  <option value="closed">{t("treatment.closed")}</option>
                </Form.Control>
              </InputGroup>
              {settings["customfieldsTreatment"] !== undefined &&
                settings["customfieldsTreatment"].length > 0 &&
                settings["customfieldsTreatment"].map((item, key) => {
                  switch (item.type) {
                    case "string":
                      return (
                        <InputGroup className="mb-3" key={key}>
                          <InputGroup.Prepend>
                            <InputGroup.Text>{td(item.key)}</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            onChange={(evt) =>
                              setEditCustomTextfields(
                                evt.target.value,
                                item.key
                              )
                            }
                            onKeyDown={handleKeyDownTreatmentCreate}
                            defaultValue=""
                          />
                        </InputGroup>
                      );
                    case "dropdown":
                      return (
                        <InputGroup className="mb-3" key={key}>
                          <InputGroup.Prepend>
                            <InputGroup.Text>{td(item.key)}</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            as="select"
                            onKeyDown={handleKeyDownTreatmentCreate}
                            onChange={(evt) =>
                              setEditCustomTextfields(
                                evt.currentTarget.value,
                                item.key
                              )
                            }
                            defaultValue=""
                          >
                            (<option value="">{t("users.select")}</option>)
                            {item["options"] != undefined
                              ? item["options"].map((option, optionkey) => {
                                  return (
                                    <option key={optionkey} value={option}>
                                      {td(option)}
                                    </option>
                                  );
                                })
                              : ""}
                          </Form.Control>
                        </InputGroup>
                      );
                    default:
                      return null;
                  }
                })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={handleCloseNewTreatmentModal}
          >
            {t("system.system_new_cancel")}
          </Button>
          <Button
            disabled={savingTreatment}
            onClick={() => handleCreateTreatment()}
          >
            {t("bowtie.save")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTreatmentAccessControlModal}>
        <Modal.Header>
          <Modal.Title>{t("treatment.access_control")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="accessControl objectAccessControlDiv">
            <div className="row">
              <div className="col-md-5">
                <p>{t("treatment.access_control")}</p>
              </div>
            </div>
            <div>
              <table className="objectAccessControlTable">
                <thead>
                  <tr>
                    <th></th>
                    <th>{t("treatment.read")}</th>
                    <th>{t("treatment.write")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="mainRow">
                    <td>{t("treatment.entire_org")}</td>
                    <td>
                      <Field
                        type="indeterminatecheckbox"
                        checked={isChecked("read", undefined, "organization")}
                        onChange={(evt) =>
                          handleChangeAction(
                            evt,
                            "read",
                            undefined,
                            "organization"
                          )
                        }
                        disabled={false}
                        parent={false}
                        title={""}
                        titlePlacement="start"
                      ></Field>
                    </td>
                    <td>
                      <Field
                        type="indeterminatecheckbox"
                        checked={isChecked("edit", undefined, "organization")}
                        onChange={(evt) => {
                          handleChangeAction(
                            evt,
                            "edit",
                            undefined,
                            "organization"
                          );
                          handleChangeAction(
                            evt,
                            "read",
                            undefined,
                            "organization"
                          );
                        }}
                        disabled={false}
                        parent={false}
                        title={""}
                        titlePlacement="start"
                      ></Field>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="objectAccessControlUsersDiv">
              {newTreatmentAccessRules.users.length != 0 ? (
                <div>
                  <p>{t("treatment.shared_with")}</p>
                  <table className="objectAccessControlTable">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t("treatment.read")}</th>
                        <th>{t("treatment.write")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {newTreatmentAccessRules.users.map((user, key) => {
                        return (
                          <tr className="mainRow">
                            <td>
                              {user.name != "" ? (
                                <p>
                                  {user.name}, <br />
                                  {user.email}
                                </p>
                              ) : (
                                <p>{user.email}</p>
                              )}
                            </td>
                            <td>
                              <Field
                                type="indeterminatecheckbox"
                                checked={isChecked("read", user.id, undefined)}
                                onChange={(evt) =>
                                  handleChangeAction(
                                    evt,
                                    "read",
                                    user.id,
                                    undefined
                                  )
                                }
                                disabled={false}
                                parent={false}
                                title={""}
                                titlePlacement="start"
                              ></Field>
                            </td>
                            <td>
                              <Field
                                type="indeterminatecheckbox"
                                checked={isChecked("edit", user.id, undefined)}
                                onChange={(evt) => {
                                  handleChangeAction(
                                    evt,
                                    "edit",
                                    user.id,
                                    undefined
                                  );
                                  handleChangeAction(
                                    evt,
                                    "read",
                                    user.id,
                                    undefined
                                  );
                                }}
                                disabled={false}
                                parent={false}
                                title={""}
                                titlePlacement="start"
                              ></Field>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
              <div
                key={autocompleteKey}
                className="objectAccessControlAddUsersDiv"
              >
                {/* <Field
                                    type="input"
                                    value={shareWithUser != undefined ? shareWithUser : ""}
                                    title={t("treatment.share_with_user")}
                                    onChange={value => setShareWithUser(value)}
                                    placeholder={t("treatment.enter_email")}
                                ></Field> */}
                <Field
                  type="autocomplete"
                  value={shareWithUser != undefined ? shareWithUser : ""}
                  title={t("treatment.share_with_user")}
                  onChange={(value) => setShareWithUser(value)}
                  //disabled={!treatment.accesses.includes("edit_treatment_owner_and_sharing")}
                  options={{
                    list: shareWithUsersOptions,
                    minlength: 2,
                    noresults: "no matches",
                    placeholder: t("treatment.enter_email"),
                  }}
                ></Field>
                <Button onClick={addSharedWithUser}>
                  {t("treatment.share")}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowTreatmentAccessControlModal(false)}>
            {t("treatment.confirm_access_control")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreateTreatmentModal;
