import HardwareTemplate from "./HardwareTemplate";
import CommunicationTemplate from "./CommunicationTemplate";
import CompanydataTemplate from "./CompanydataTemplate";
import EconomyTemplate from "./EconomyTemplate";
import OtherinfoTemplate from "./OtherinfoTemplate";
import OtherlawinfoTemplate from "./OtherlawinfoTemplate";
import PersoninformationTemplate from "./PersoninformationTemplate";
import UsernamePasswordTemplate from "./UsernamePasswordTemplate";

async function SystemValueTemplate(t, axios, auth, valueType) {
    switch (valueType) {
        case "systemvaluehardware": 
            return HardwareTemplate(t, axios, auth);
        case "systemvaluecommunication": 
            return CommunicationTemplate(t, axios, auth);
        case "systemvaluecompanydata": 
            return CompanydataTemplate(t, axios, auth);
        case "systemvalueeconomy": 
            return EconomyTemplate(t, axios, auth);
        case "systemvalueotherinfo": 
            return OtherinfoTemplate(t, axios, auth);
        case "systemvalueotherlawinfo": 
            return OtherlawinfoTemplate(t, axios, auth);
        case "systemvaluepersoninformation": 
            return PersoninformationTemplate(t, axios, auth);
        case "systemvalueusernamepassword": 
            return UsernamePasswordTemplate(t, axios, auth);
        default:
            return undefined;
    }
}

export default SystemValueTemplate;