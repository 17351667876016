import * as React from "react";

import { useState, useEffect } from "react";
import { Button, Modal, InputGroup, Form } from "react-bootstrap";
import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import DateSelector from "../../Shared/Wizard/Field/Fieldtypes/DateSelector";
import DashboardCard from "../Dashboard/Cards/DashboardCard";
import TaskEditorModal from "../../Shared/Modals/Edit/TaskEditorModal";
import SiteAssistant from "../../Shared/SiteAssistant/SiteAssistant";
import DiriFaceDocked from "../../../Assets/Images/diriassistant/diri.svg";
import TaskDeleteModal from "../../Shared/Modals/Delete/TaskDeleteModal";
import Shop from "../../Shared/Shop/Shop";

import { generateUrl } from "../../../config";

import "react-toastify/dist/ReactToastify.css";
import "./Tasks.scss";

function Treatments() {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const [users, setUsers] = useState([]);

  const [updateTable, setUpdateTable] = useState(0);
  const [editTaskId, setEditTaskId] = useState("");
  const [deleteTaskId, setDeleteTaskId] = useState("");
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);

  const [
    createTaskResponsibleAttachmentAccess,
    setCreateTaskResponsibleAttachmentAccess,
  ] = useState(true);

  const [createTaskName, setCreateTaskName] = useState("");
  const [createTaskDescription, setCreateTaskDescription] = useState("");
  const [createTaskPriority, setCreateTaskPriority] = useState("normal");
  const [createTaskDuetime, setCreateTaskDuetime] = useState("");
  const [createTaskAttachmentType, setCreateTaskAttachmentType] = useState("");
  const [createTaskAttachmentId, setCreateTaskAttachmentId] = useState("");
  const [createTaskAttachmentName, setCreateTaskAttachmentName] = useState("");
  const [createTaskAttachments, setCreateTaskAttachments] = useState<
    Array<any>
  >([]);
  const [
    createTaskAttachmentObjects,
    setCreateTaskAttachmentObjects,
  ] = useState<Array<any>>([]);
  const [createTaskResponsible, setCreateTaskResponsible] = useState(
    auth.user["userdata"]["_id"]
  );
  const [show, setShow] = useState(false);
  const [orgs, setOrgs] = useState<Array<Object>>([{}]);
  const [allSystems, setAllSystems] = useState<Array<Object>>([{}]);
  const [showShop, setShowShop] = useState(false);

  function handleClose() {
    setShowCreateTaskModal(false);
    setCreateTaskAttachmentType("");
    setCreateTaskAttachmentId("");
    setCreateTaskResponsibleAttachmentAccess(true);
    cleanUpForm();
  }

  function cleanUpForm() {
    setCreateTaskName("");
    setCreateTaskDescription("");
    setCreateTaskDuetime("");
    setCreateTaskAttachmentName("");
    setCreateTaskResponsible(auth.user["userdata"]["_id"]);
    setCreateTaskPriority("normal");
  }

  const handleShowEditTaskModal = (dataLine) => {
    setEditTaskId(dataLine["_id"]);
    setShowEditTaskModal(true);
  };

  const handleCreateTask = () => {
    if (createTaskName == "") {
      notifyWarning(t("tasks.toast.empty_name"));
      return undefined;
    }
    if (createTaskDuetime == "" || createTaskDuetime == undefined) {
      notifyWarning(t("tasks.toast.empty_duetime"));
      return undefined;
    }

    axios
      .post(
        generateUrl("/api/cra/tasks"),
        {
          name: createTaskName,
          createdby: auth.user["userdata"]["_id"],
          description: createTaskDescription,
          priority: createTaskPriority,
          attachments: createTaskAttachments,
          responsible: createTaskResponsible,
          status: "open",
          duetime: createTaskDuetime,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        cleanUpForm();
        setUpdateTable(updateTable + 1);
        setShowCreateTaskModal(false);
        setCreateTaskAttachmentType("");
        setCreateTaskAttachmentId("");
        setCreateTaskAttachments([]);
        notifySuccess(t("tasks.toast.task_created"));
      });
  };

  async function fetchOrgData() {
    axios
      .single({
        method: "get",
        url: generateUrl("/api/orgelements"),
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        setOrgs(res.data);
      });
  }

  const fetchData = () => {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/users"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setUsers(res[0].data);
        })
      );
  };

  const fetchTaskAttachmentObjects = (objectType) => {
    if (objectType != "") {
      let method = "";
      let url = "";
      switch (objectType) {
        case "system":
          method = "get";
          url = generateUrl("/api/cra/systems");
          break;
        case "analysistemplate":
          method = "get";
          url = generateUrl("/api/cra/analysisTemplates/byorg/myorg");
          break;
      }
      axios
        .all([
          axios.single({
            method,
            url,
            responseType: "json",
            headers: { "x-access-token": auth.user["token"] },
          }),
        ])
        .then(
          axios.spread((...res) => {
            if (objectType == "system") {
              setAllSystems(res[0].data);
            }
            setCreateTaskAttachmentObjects(res[0].data);
          })
        );
    }
  };

  const fetchAccessStatus = (objectType, objectId, userId) => {
    if (objectType != "" && objectId != "" && userId != "") {
      let method = "";
      let url = "";
      switch (objectType) {
        case "system":
          method = "get";
          url =
            generateUrl("/api/cra/systems/ownership/") +
            objectId +
            "/" +
            userId;
          break;
        case "analysistemplate":
          method = "get";
          url =
            generateUrl("/api/cra/analysisTemplates/ownership/") +
            objectId +
            "/" +
            userId;
          break;
      }
      axios
        .all([
          axios.single({
            method,
            url,
            responseType: "json",
            headers: { "x-access-token": auth.user["token"] },
          }),
        ])
        .then(
          axios.spread((...res) => {
            switch (objectType) {
              case "system":
                /* if (res[0].data.owner != null){
                            if (res[0].data.owner["_id"] == userId) {
                                setCreateTaskResponsibleAttachmentAccess(true);
                                break;
                            }
                        } 
                        if (res[0].data.coowners != undefined && !res[0].data.coowners.includes(null)) {
                            if (res[0].data.coowners.map(item => item["_id"]).includes(userId)) {
                                setCreateTaskResponsibleAttachmentAccess(true);
                                break;
                            }
                        }
                        if (res[0].data.private != true && auth.user["userdata"]["_id"] == userId && auth.user["userdata"]["role"][0] == "Admin") {
                            setCreateTaskResponsibleAttachmentAccess(true);
                            break;
                        } */
                if (res[0].data.systemAccesses.includes("read")) {
                  setCreateTaskResponsibleAttachmentAccess(true);
                  break;
                }
                setCreateTaskResponsibleAttachmentAccess(false);
                break;
              case "analysistemplate":
                /* if (res[0].data.owner != null){
                            if (res[0].data.owner["_id"] == userId) {
                                setCreateTaskResponsibleAttachmentAccess(true);
                                break;
                            }
                        } 
                        if (res[0].data.coowners != undefined && !res[0].data.coowners.includes(null)) {
                            if (res[0].data.coowners.map(item => item["_id"]).includes(userId)) {
                                setCreateTaskResponsibleAttachmentAccess(true);
                                break;
                            }
                        }
                        if (res[0].data.accessibility != undefined && !res[0].data.accessibility.includes(null)) {
                            if (res[0].data.accessibility.map(item => item["_id"]).includes(userId)) {
                                setCreateTaskResponsibleAttachmentAccess(true);
                                break;
                            }
                        }
                        if (res[0].data.private != true && auth.user["userdata"]["_id"] == userId && auth.user["userdata"]["role"][0] == "Admin") {
                            setCreateTaskResponsibleAttachmentAccess(true);
                            break;
                        } */
                if (res[0].data.templateAccesses.includes("read")) {
                  setCreateTaskResponsibleAttachmentAccess(true);
                  break;
                }
                setCreateTaskResponsibleAttachmentAccess(false);
            }
          })
        );
    }
  };

  useEffect(() => {
    fetchData();
    fetchOrgData();
  }, []);

  useEffect(() => {}, [createTaskAttachments]);

  return (
    <div className="maincontent">
      <SiteAssistant dockedLogo={DiriFaceDocked} featurekey="tasks" />
      <div className="systems-container">
        <div className="pagetitle-container">
          <h1>{t("tasks.page_name")}</h1>
        </div>
        {auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
          (rule) => rule.objType == "tasks" && rule.objSubType == "tasks"
        )?.allowed == true ? (
          <Button
            onClick={(evt) => setShowCreateTaskModal(true)}
            disabled={
              //auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "tasks" && rule.objSubType == "tasks")?.allowed == false ||
              auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
                (rule) => rule.objType == "tasks" && rule.objSubType == "tasks"
              )?.actions.create != "allow"
            }
          >
            {t("tasks.new_task_btn")}
          </Button>
        ) : (
          <Button
            className="upgrade-btn"
            onClick={() => {
              setShowShop(true);
            }}
          >
            <i className="dripicons-lock" /> {t("tasks.requires_update")}
          </Button>
        )}
        <hr />
        <div className="row">
          <div className="col col-sm-6">
            <DashboardCard
              updateTable={updateTable}
              onClick={(dataLine) => handleShowEditTaskModal(dataLine)}
              cardType="mytasks"
            ></DashboardCard>
            <DashboardCard
              updateTable={updateTable}
              cardType="tasksburndown"
            ></DashboardCard>
          </div>
          <div className="col col-sm-6">
            <DashboardCard
              updateTable={updateTable}
              onClick={(dataLine) => handleShowEditTaskModal(dataLine)}
              cardType="assignedtasks"
            ></DashboardCard>
            <DashboardCard
              updateTable={updateTable}
              onClick={(dataLine) => handleShowEditTaskModal(dataLine)}
              cardType="closedtasks"
            ></DashboardCard>
          </div>
        </div>
        <Modal show={showCreateTaskModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("tasks.modal_task_create.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("tasks.modal_task_create.fields.name")} *
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder={t(
                  "tasks.modal_task_create.fields.namePlaceholder"
                )}
                onChange={(evt) => setCreateTaskName(evt.target.value)}
                value={createTaskName}
              ></Form.Control>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("tasks.modal_task_create.fields.description")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(evt) =>
                  setCreateTaskDescription(evt.currentTarget.value)
                }
                value={createTaskDescription}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("tasks.modal_task_create.fields.priority")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                onChange={(evt) =>
                  setCreateTaskPriority(evt.currentTarget.value)
                }
                value={createTaskPriority}
              >
                <option value={"verylow"}>
                  {t("tasks.modal_task_create.fields.priorities.verylow")}
                </option>
                <option value={"low"}>
                  {t("tasks.modal_task_create.fields.priorities.low")}
                </option>
                <option value={"normal"}>
                  {t("tasks.modal_task_create.fields.priorities.normal")}
                </option>
                <option value={"high"}>
                  {t("tasks.modal_task_create.fields.priorities.high")}
                </option>
                <option value={"urgent"}>
                  {t("tasks.modal_task_create.fields.priorities.urgent")}
                </option>
              </Form.Control>
            </InputGroup>
            <DateSelector
              title={t("tasks.modal_task_create.fields.duetime") + " *"}
              onChange={(date) => setCreateTaskDuetime(date)}
              selected={createTaskDuetime}
              showTimeSelect
            />
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("tasks.modal_task_create.fields.assignto")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                onChange={(evt) => {
                  setCreateTaskResponsible(
                    (evt.target as HTMLSelectElement).value
                  );
                  fetchAccessStatus(
                    createTaskAttachmentType,
                    createTaskAttachmentId,
                    (evt.target as HTMLSelectElement).value
                  );
                }}
              >
                <option value={auth.user["userdata"]["_id"]}>
                  {t("tasks.modal_task_create.assigntoself")}
                </option>
                {users.map((user, key) => (
                  <option key={key} value={user["_id"]}>
                    {user["name"]}
                  </option>
                ))}
              </Form.Control>
            </InputGroup>
            <div className="attachmentsContainer">
              <p className="attachmentsTitle">{t("tasks.attachments.title")}</p>
              {createTaskAttachments.map((item, key) => (
                <div key={key} className="attachment">
                  <p>
                    {t("tasks.attachments.types." + item["type"])}:{" "}
                    {item["name"]} -{" "}
                    <span
                      className="removebtn"
                      onClick={(evt) => {
                        let temp = createTaskAttachments;
                        temp.splice(key, 1);
                        setCreateTaskAttachments(
                          JSON.parse(JSON.stringify(temp))
                        );
                      }}
                    >
                      {t("tasks.attachments.remove")}
                    </span>
                  </p>
                </div>
              ))}
              <div className="row">
                <div className="col col-sm-12">
                  <div
                    className="input-group"
                    style={{ width: "32%", float: "left" }}
                  >
                    <Form.Control
                      as="select"
                      onChange={(evt) => {
                        setCreateTaskAttachmentType(
                          (evt.target as HTMLSelectElement).value
                        );
                        fetchTaskAttachmentObjects(
                          (evt.target as HTMLSelectElement).value
                        );
                        setCreateTaskAttachmentId("");
                      }}
                    >
                      <option value="">
                        {t("tasks.attachments.types.selecttype")}
                      </option>
                      <option value="system">
                        {t("tasks.attachments.types.system")}
                      </option>
                      <option value="analysistemplate">
                        {t("tasks.attachments.types.analysistemplate")}
                      </option>
                    </Form.Control>
                  </div>
                  <div
                    className="input-group"
                    style={{ width: "32%", float: "left" }}
                  >
                    <Form.Control
                      as="select"
                      disabled={createTaskAttachmentType == ""}
                      value={createTaskAttachmentId}
                      onChange={(evt) => {
                        fetchAccessStatus(
                          createTaskAttachmentType,
                          (evt.target as HTMLSelectElement).value,
                          createTaskResponsible
                        );
                        setCreateTaskAttachmentId(
                          (evt.target as HTMLSelectElement).value
                        );
                        if ((evt.target as HTMLSelectElement).value != "") {
                          let currentName = createTaskAttachmentObjects.filter(
                            (attachmentObject) =>
                              attachmentObject["_id"] ==
                              (evt.target as HTMLSelectElement).value
                          )[0]["name"];
                          let currentId = createTaskAttachmentObjects.filter(
                            (attachmentObject) =>
                              attachmentObject["_id"] ==
                              (evt.target as HTMLSelectElement).value
                          )[0]["_id"];
                          setCreateTaskAttachmentName(
                            currentName != undefined
                              ? currentName
                              : orgs.filter(
                                  (org) =>
                                    org["_id"] ==
                                    allSystems.filter(
                                      (system) =>
                                        system["_id"].toString() == currentId
                                    )[0]["org"]
                                )[0]["name"]
                          );
                        } else {
                          setCreateTaskAttachmentName("");
                        }
                      }}
                    >
                      <option value="">
                        {t("tasks.attachments.selectitem")}
                      </option>
                      {createTaskAttachmentObjects.map((object, objectkey) => (
                        <option key={objectkey} value={object["_id"]}>
                          {object["name"] != undefined
                            ? object["name"]
                            : orgs.filter(
                                (org) => org["_id"] == object["org"]
                              )[0]["name"]}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <Button
                    style={{ marginLeft: "10px", height: "38px", width: "32%" }}
                    disabled={!createTaskResponsibleAttachmentAccess}
                    onClick={(evt) => {
                      if (
                        createTaskAttachmentType != "" &&
                        createTaskAttachmentId != ""
                      ) {
                        setCreateTaskAttachments([
                          ...createTaskAttachments,
                          {
                            type: createTaskAttachmentType,
                            id: createTaskAttachmentId,
                            name: createTaskAttachmentName,
                          },
                        ]);
                      } else {
                        notifyWarning(t("tasks.attachments.fillout"));
                      }
                    }}
                  >
                    {t("tasks.attachments.addbtn")}
                  </Button>
                </div>
              </div>
              {!createTaskResponsibleAttachmentAccess ? (
                <p>{t("tasks.attachments.assignednoaccess")}</p>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel posleft"
              onClick={() => {
                setShowCreateTaskModal(false);
                setCreateTaskAttachmentType("");
                setCreateTaskAttachmentId("");
                setCreateTaskResponsibleAttachmentAccess(true);
              }}
            >
              {t("tasks.modal_task_create.cancelbtn")}
            </Button>
            <Button onClick={handleCreateTask}>
              {t("tasks.modal_task_create.savebtn")}
            </Button>
          </Modal.Footer>
        </Modal>
        <TaskEditorModal
          taskId={editTaskId}
          show={showEditTaskModal}
          onCancel={() => setShowEditTaskModal(false)}
          onHide={() => setShowEditTaskModal(false)}
          onSave={() => {
            setShowEditTaskModal(false);
            setUpdateTable(updateTable + 1);
          }}
          onDeleteClick={(val) => {
            setShowDeleteTaskModal(true);
            setDeleteTaskId(val);
          }}
        ></TaskEditorModal>
        <TaskDeleteModal
          deleteTaskId={deleteTaskId}
          onDeleted={() => {
            setShowDeleteTaskModal(false);
            setShowEditTaskModal(false);
            setUpdateTable(updateTable + 1);
          }}
          onCancel={() => setShowDeleteTaskModal(false)}
          onHide={() => setShowDeleteTaskModal(false)}
          show={showDeleteTaskModal}
        ></TaskDeleteModal>
      </div>

      <Shop
        show={showShop}
        onHide={() => setShowShop(false)}
        size="lg"
        orgid={auth.user["userdata"]["unit"][0]}
        onPurchaseComplete={() => setShowShop(false)}
      />
    </div>
  );
}

export default Treatments;
