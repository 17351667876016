import React from 'react';
import { InputGroup, Form } from 'react-bootstrap';

function NumberField(props) {
    const handleChange = (value) => {
        if (props.onChange != undefined) {
            props.onChange(value);
        }
    }

    return(
        <InputGroup className="mb-3">
            {props.title != undefined ?
                <InputGroup.Prepend>
                    <InputGroup.Text>{props.title}</InputGroup.Text>
                </InputGroup.Prepend>
            : null}
            <Form.Control type="number" disabled={props.disabled} onChange={(evt) => handleChange(evt.target.value)} value={props.value != undefined ? props.value : (props.defaultValue != undefined ? props.defaultValue : 0)} />
        </InputGroup>
    );
}

export default NumberField;