import * as React from 'react';

import './LastEvents.scss';
import { useTranslate } from 'react-polyglot';
import { Card } from 'react-bootstrap';
import PageTable from '../../Shared/PageTable/PageTable';
import * as _ from "lodash";

import SiteAssistant from "../../Shared/SiteAssistant/SiteAssistant";
import DiriFaceDocked from "../../../Assets/Images/diriassistant/diri.svg";
import { useAuth } from '../../../Auth';
import { useAxios } from '../../../AxiosHandler';
import { useState, useEffect, Fragment } from 'react';
import { TemporaryCacheKeys } from 'msal/lib-commonjs/utils/Constants';
import { generateUrl } from '../../../config';

function LastEvents() {
    const t = useTranslate();
    const [allOccurences, setAllOccurences] = useState<any>([]);
    const [allSystemvalues, setAllSystemvalues] = useState<any>([]);
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();

    const [fields, setFields] = useState<any>([]);
    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    const percentageToValue = (percentage, maxvalue) => {
        return Math.round((maxvalue - 1) * (percentage / 100) + 1);
      }

      async function fetchData(){
        await axios.all([
                axios.single({
                    method: "get",
                    url: generateUrl("/api/cra/systemvalues"),
                    responseType: "json",
                    headers: {'x-access-token': auth.user["token"]}
                }),
                axios.single({
                    method: "get",
                    url: generateUrl("/api/cra/treatments"),
                    responseType: "json",
                    headers: {'x-access-token': auth.user["token"]}
                }),
               axios.single({
                    method: "get",
                    url: generateUrl("/api/cra/occurences"),
                    responseType: "json",
                    headers: {'x-access-token': auth.user["token"]}
                }),

            ])
            .then(axios.spread((...res) => {
                setAllSystemvalues(res[0].data)
                setAllOccurences(res[2].data)
            }))
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="maincontent">
            <SiteAssistant
                dockedLogo={DiriFaceDocked}
                featurekey="lastevents"
            />
            <div className="lastevents-container">
                <div className="pagetitle-container">
                    <h1>{t("events.events_page_name")}</h1>
                </div>
                <Card>
                    <Card.Body>
                        <PageTable
                            dataUrl={generateUrl("/api/events/getAllPaged/")}
                            options={{
                                showFields: [
                                    {
                                        label: t("events.table_colnametype"),
                                        field: "type", override: dataLine => dataLine["type"] != undefined ? t(dataLine["type"]) : "",
                                        customFilters: [
                                            { label: t("backend.eventlog.type.analysistemplate"), value: "backend.eventlog.type.analysistemplate" },
                                            { label: t("backend.eventlog.type.cause"), value: "backend.eventlog.type.cause" },
                                            { label: t("backend.eventlog.type.consequence"), value: "backend.eventlog.type.consequence" },
                                            { label: t("backend.eventlog.type.occurrence"), value: "backend.eventlog.type.occurrence" },
                                            { label: t("backend.eventlog.type.organization"), value: "backend.eventlog.type.organization" },
                                            { label: t("backend.eventlog.type.settings"), value: "backend.eventlog.type.settings" },
                                            { label: t("backend.eventlog.type.system"), value: "backend.eventlog.type.system" },
                                            { label: t("backend.eventlog.type.systemassets"), value: "backend.eventlog.type.systemassets" },
                                            { label: t("backend.eventlog.type.task"), value: "backend.eventlog.type.task" },
                                            { label: t("backend.eventlog.type.treatment"), value: "backend.eventlog.type.treatment" },
                                            { label: t("backend.eventlog.type.user"), value: "backend.eventlog.type.user" },
                                        ]
                                    },
                                    {
                                        label: t("events.table_colnamechange"),
                                        field: "comment",  override: dataLine => dataLine["comment"] != undefined ? t(dataLine["comment"]) : "",
                                        customFilters: [
                                            { label: t("backend.eventlog.change.delete"), value: "backend.eventlog.change.delete" },
                                            { label: t("backend.eventlog.change.edit"), value: "backend.eventlog.change.edit" },
                                            { label: t("backend.eventlog.change.new"), value: "backend.eventlog.change.new" },
                                        ]
                                    },
                                    { label: t("events.table_colnamedesc"), field: "description", override: dataLine => dataLine["description"] != undefined ? dataLine["description"].split("|")[1] : "", maxLength: 100},
                                    { label: t("events.table_colnameuser"), field: "username"},
                                    { label: t("events.table_colnameorg"), field: "orgname"},
                                    /*{ label: t("events.table_colname_updatedfield"), field: "updatedField"},*/
                                    { label: t("events.table_colname_valuebefore"), field: "valueBefore", maxLength: 100},
                                    { label: t("events.table_colname_valueafter"), field: "valueAfter", maxLength: 100},
                                    { label: t("events.table_colnametime"), field: "dateReadable"}
                                ],
                                pageSize: 15
                            }}
                            deaultSortDirection={"desc"}
                            defaultSortField={"dateReadable"}
                            hidePagination={false}
                            hidePagesize={false}
                            hideFilter={false}
                            hover={false}
                        />
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}
 
export default LastEvents;