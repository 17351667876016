import React, { useEffect, useState, useRef } from 'react';

import { Card, Button, Modal, Form, InputGroup, Nav, Table} from 'react-bootstrap';
import { useAuth } from '../../../../Auth';
import { useAxios } from '../../../../AxiosHandler';
import { useTranslate } from 'react-polyglot';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "./OrganizationRolesHandler.scss";
import Field from '../../../Shared/Wizard/Field/Field';
import Shop from '../../../Shared/Shop/Shop';

import { useDynamicLanguage } from '../../../../DynamicLanguageProvider';
import { generateUrl } from '../../../../config';

function OrganizationRolesHandler(props) {
    const auth = useAuth();
    const t = useTranslate();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const td = useDynamicLanguage().td;

    const shopRule = auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "shop" && rule.objSubType == "shop");

    const roleObjectStructure = {
        name: "",
        description: "",
        hidden: true,
        default: false,
        price: "",
        annualPrice: "",
        highestValue: false,
        mainAbo: false,
        contentList: [],
        selectedOrgs: [],
        rules: [{
            objId: "", 
            objType: "system", 
            objSubType: "system",
            allowed: false,
            limitations: {
                amount: 0,
                sharedNoOfGuests: 0
            }
        }, {
            objId: "", 
            objType: "system", 
            objSubType: "orgros",
            allowed: false,
            limitations: {
                amount: 0,
                sharedNoOfGuests: 0
            }
        }, {
            objId: "", 
            objType: "system", 
            objSubType: "other",
            allowed: false,
            limitations: {
                amount: 0,
                sharedNoOfGuests: 0
            }
        },
        { 
            objId: "", 
            objType: "users", 
            objSubType: "users",
            allowed: true,
            limitations: {
                amount: 0
            }
        },
        /* { 
            objId: "", 
            objType: "users", 
            objSubType: "accesscontrol",
            allowed: true,
            limitations: {
                amount: 0
            }
        },
        { 
            objId: "", 
            objType: "treatment", 
            objSubType: "treatment",
            allowed: false,
            limitations: {
                amount: 0,
                sharedNoOfGuests: 0
            }
        }, */
        { 
            objId: "", 
            objType: "tasks", 
            objSubType: "tasks",
            allowed: false
        },
        {
            objId: "", 
            objType: "organization", 
            objSubType: "organization",
            allowed: true,
            limitations: {
                amount: 0
            }
        },
        {
            objId: "", 
            objType: "survey", 
            objSubType: "survey",
            allowed: false,
            limitations: {
                amount: 0,
                sharedNoOfGuests: 0
            }
        },
        {
            objId: "", 
            objType: "organization", 
            objSubType: "generate_api_keys",
            allowed: false
        },
        {
            objId: "", 
            objType: "system", 
            objSubType: "risk_assessment_excel_export",
            allowed: false
        },
        {
            objId: "", 
            objType: "system", 
            objSubType: "risk_assessment_report",
            allowed: false
        },
        {
            objId: "", 
            objType: "system", 
            objSubType: "export_all_risk_assessments",
            allowed: false
        }]
    }

    const notifyWarning = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "warning-toast"
    });

    const notifySuccess = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "success-toast"
    });
    
    //const [showNewRoleModal, setShowNewRoleModal] = useState(false);
    const [showEditRoleModal, setShowEditRoleModal] = useState(false);
    const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
    const [editRole, setEditRole] = useState<any>(roleObjectStructure);
    const [deleteRole, setDeleteRole] = useState<any>(roleObjectStructure);
    const [publicOrganizationRoles, setPublicOrganizationRoles] = useState<Array<any>>([]);
    const [hiddenOrganizationRoles, setHiddenOrganizationRoles] = useState<Array<any>>([]);
    const [orgOptions, setOrgOptions] = useState<Array<any>>([]);
    const [shopPreview, setShopPreview] = useState(false);
    const [orgRolesOrder, setOrgRolesOrder] = useState<Array<any>>([]);
    const [orgRolesOrderCopy, setOrgRolesOrderCopy] = useState<Array<any>>([]);

    const [newContentItem, setNewContentItem] = useState("");

    function handleShowEditRoleModal(role = undefined){
        setShowEditRoleModal(true);
        if(role != undefined){
            setEditRole(role);
        }
    }

    function handleShowDeleteRoleModal(role){
        setShowDeleteRoleModal(true);
        setDeleteRole(role)
    }


    function handleHideEditRoleModal(){
        setShowEditRoleModal(false);
        setEditRole(roleObjectStructure)
    }
    function handleHideDeleteRoleModal(){
        setShowDeleteRoleModal(false);
        setDeleteRole(roleObjectStructure)
    }

    function GetAllOrganizationRoles(){
        axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/organizationRoles/"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/organizationRoles/hidden"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/orgelements"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            })
        ]).then((res) => {
            setPublicOrganizationRoles(res[0].data.map((role, idx) => role.shopIdx = idx));
            setOrgRolesOrder(res[0].data.map(idx => { return {id: idx._id.toString(), content: idx}}));
            setOrgRolesOrderCopy(res[0].data.map(idx => { return {id: idx._id.toString(), content: idx}}))
            setHiddenOrganizationRoles(res[1].data);
            setOrgOptions(res[2].data.map(d => { return {label: d.name, value: d._id}}))
        });
    }

    function saveEditedRole(){
        let pattern = new RegExp(/^[0-9]*$/);
        if(!pattern.test(editRole["price"].toString())){ notifyWarning(t("organizationRolesHandler.toast_price_only_numbers_allowed")); return undefined; }
        if(editRole["price"] === "" || editRole["annualPrice"] === "" || editRole["price"] === 0 || editRole["annualPrice"] === 0){ notifyWarning(t("organizationRolesHandler.prices_required")); return undefined; }
        if(editRole["_id"]){
            axios.put(
                generateUrl("/api/cra/organizationRoles/updaterole/") + editRole["_id"],
                {
                    role: editRole
                },
                {
                    headers: {'x-access-token': auth.user["token"]}
                }
            ).then(res => {
                handleHideEditRoleModal();
                GetAllOrganizationRoles();
            });
        } else {
            axios.post(
                generateUrl("/api/cra/organizationRoles"),
                {
                    role: editRole,
                    price: editRole["price"],
                    annualPrice: editRole["annualPrice"],
                    name: editRole["name"]
                },
                {
                    headers: {'x-access-token': auth.user["token"]}
                }
            ).then(res => {
                handleHideEditRoleModal();
                GetAllOrganizationRoles();
            });
        }   
    }

    /* function handleDeleteRole(){
        axios.delete(
            "https://test.diri.ai/api/cra/organizationRoles/" + deleteRole["_id"],
            {},
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            handleHideDeleteRoleModal();
            GetAllOrganizationRoles();
        });
    } */

    function handleEditAllowed(val, objType, objSubType){
        setEditRole({...editRole, rules: editRole["rules"].map(rule => {
            if(rule.objType == objType && rule.objSubType == objSubType){
                return {
                    ...rule,
                    allowed: val
                }
            } else {
                return rule;
            }
        })})
    }
    function handleEditAmount(val, objType, objSubType){
        setEditRole({...editRole, rules: editRole["rules"].map(rule => {
            if(rule.objType == objType && rule.objSubType == objSubType){
                let pattern = new RegExp(/^[0-9]*$/);
                if(val != null && !pattern.test(val.toString())){ 
                    notifyWarning(t("organizationRolesHandler.toast_amount_only_numbers_allowed")); 
                    return rule;
                } else {
                    return {
                        ...rule,
                        limitations: {
                            ...rule.limitations,
                            amount: val != null ? (val != "" ? parseInt(val) : "") : null
                        }
                    }
                }
                
            } else {
                return rule;
            }
        })})
    }

    const reorder = (list, startIdx, endIdx) => {
        const result = Array.from(list);
        const removed = result.splice(startIdx, 1)[0] as any;
        result.splice(endIdx, 0, removed);
      
        return result;
    };

    function onDragEnd(result) {
        if (!result.destination || result.destination.index === result.source.index) {
          return;
        }
    
        let orgRoles = reorder(
            orgRolesOrder,
            result.source.index,
            result.destination.index
        ) as any;
        
        setOrgRolesOrder(orgRoles);
      }

    function OrgRole(role, index) {
        return (
            <Draggable draggableId={role.id} index={index}>
                {provided => (
                    <div 
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps} 
                        index={index}
                        className="orgRolesListItem clickable" 
                        key={role.id}
                    >
                        <p onClick={ () => { handleShowEditRoleModal(role.content) } } >
                            <i className="dripicons-document-edit edit"/> {td(role.content.name)}
                            </p>
                            <span className='orgRolesListItem-draggable'>
                                <i className="dripicons-dots-2"/>
                                <i className="dripicons-dots-2"/>
                                <i className="dripicons-dots-2"/>
                            </span>
                    </div>
                )}
            </Draggable>
        );
    }

    function orgRolesList(){
        return orgRolesOrder.map((role, index) => (
            <div key={role.id}>
                {OrgRole(role, index)}
            </div>
          ));
    }

    function saveOrgRolesOrder(){
        let temp = orgRolesOrder.map((r, idx) => {r.content.shopIdx = idx; return r.content});
        axios.put(
            generateUrl("/api/cra/organizationRoles/saveOrder"),
            {
                orgRoles: temp
            },
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setPublicOrganizationRoles(res.data);
            setOrgRolesOrder(res.data.map(idx => { return {id: idx._id.toString(), content: idx}}));
            setOrgRolesOrderCopy(res.data.map(idx => { return {id: idx._id.toString(), content: idx}}))
            notifySuccess(t("organizationRolesHandler.order_saved"));
        });
    }

    function updateContentItem(value,key){
        editRole["contentList"][key] = value;
        setEditRole(editRole);
    }
   
    useEffect(() => {
        if(shopRule != undefined && shopRule.actions.handle_shop_objects != ""){
            GetAllOrganizationRoles();
        } else {
            auth.signout();
        }
    }, []);

    return (
        <div className="organizationRolesHandler">

            <div className="action-btns">
                {/* <Button onClick={() => setShopPreview(!shopPreview)}>{!shopPreview ? t("organizationRolesHandler.show_shop_preview") : t("organizationRolesHandler.show_edit_view")}</Button> */}
                <Button onClick={() => handleShowEditRoleModal()}>{t("organizationRolesHandler.create_new")}</Button>
            </div>
            
            <div>
                <div>
                    <h2>{t("organizationRolesHandler.visible_in_shop")}</h2>
                    <div className="orgRolesList">
                        <DragDropContext onDragEnd={(data) => onDragEnd(data)}>
                            <Droppable droppableId="list">
                                {provided => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {orgRolesList()}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {JSON.stringify(orgRolesOrder) != JSON.stringify(orgRolesOrderCopy) ?
                            <div className="orgRolesOrderBtns">
                                <Button onClick={() => saveOrgRolesOrder()}>{t("organizationRolesHandler.save_order")}</Button>
                                <Button onClick={() => setOrgRolesOrder(orgRolesOrderCopy)}>{t("organizationRolesHandler.reset_order")}</Button>
                            </div>
                        : null}
                        
                    </div>
                    {/* <Table>
                        <thead>
                            <tr>
                                <th>FEATURES</th>
                                {publicOrganizationRoles.map((role, key) => {
                                    return <th key={key}>{role.name} <i className="dripicons-document-edit edit" onClick={ () => { handleShowEditRoleModal(role) } } /></th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th className="first">RISK ASSESSMENT - IT SYSTEM</th>
                                {publicOrganizationRoles.map((role, key) => {
                                    return <td onClick={() => handleEditAllowed(role._id, !role.rules.find(rule => rule.objType == "system" && rule.objSubType == "system").allowed, "system", "system")} key={key}>{role.rules.find(rule => rule.objType == "system" && rule.objSubType == "system").allowed ? "JA" : "NEI"}</td>
                                })}
                            </tr>
                            <tr>
                                <th className="first">RISK ASSESSMENT - ORGROS</th>
                                {publicOrganizationRoles.map((role, key) => {
                                    return <td  onClick={() => handleEditAllowed(role._id, !role.rules.find(rule => rule.objType == "system" && rule.objSubType == "orgros").allowed, "system", "orgros")} key={key}>{role.rules.find(rule => rule.objType == "system" && rule.objSubType == "orgros").allowed ? "JA" : "NEI"}</td>
                                })}
                            </tr>
                            <tr>
                                <th className="first">RISK ASSESSMENT - OTHER</th>
                                {publicOrganizationRoles.map((role, key) => {
                                    return <td onClick={() => handleEditAllowed(role._id, !role.rules.find(rule => rule.objType == "system" && rule.objSubType == "other").allowed, "system", "other")} key={key}>{role.rules.find(rule => rule.objType == "system" && rule.objSubType == "other").allowed ? "JA" : "NEI"}</td>
                                })}
                            </tr>
                        </tbody>
                    </Table>*/}
                </div>
                <div>
                    <h2>{t("organizationRolesHandler.hidden_in_shop")}</h2>
                    <div className="orgRolesList">
                        {hiddenOrganizationRoles.map((role, key) => {
                            return (
                                <div className="orgRolesListItem clickable" key={key}>
                                    <p onClick={ () => { handleShowEditRoleModal(role) } } ><i className="dripicons-document-edit edit"/> {td(role.name)}</p>
                                    {/* <div>
                                        <i className="dripicons-trash" onClick={evt => {
                                            evt.preventDefault();
                                            evt.stopPropagation();
                                            handleShowDeleteRoleModal(role);
                                        }}/>
                                    </div> */}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            {/* <Modal show={showDeleteRoleModal} onHide={handleHideDeleteRoleModal} className="role_editor_modal">
                <Modal.Header closeButton>
                    <Modal.Title>{t("organizationRolesHandler.delete")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("organizationRolesHandler.delete_text") + " " + deleteRole["name"]}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="cancel posleft" onClick={handleHideDeleteRoleModal}>{t("organizationRolesHandler.cancel")}</Button>
                    <Button onClick={handleDeleteRole}>{t("organizationRolesHandler.delete")}</Button>
                </Modal.Footer>
            </Modal> */}

            <Modal show={showEditRoleModal} onHide={handleHideEditRoleModal} size="lg" className="role_editor_modal">
                <Modal.Header closeButton>
                    <Modal.Title>{editRole["_id"] == undefined ? t("organizationRolesHandler.create") : t("organizationRolesHandler.edit")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editRole != undefined ?
                    
                    <div>
                        <div className="generalContent">
                            <Field
                                type="textlanguage"
                                title={t("organizationRolesHandler.role.name")}
                                value={editRole["name"]}
                                onChange={val => setEditRole({...editRole, name: val})}
                            ></Field>
                            <Field
                                type="checkbox"
                                title={t("organizationRolesHandler.role.mainAbo")}
                                value={editRole["mainAbo"]}
                                onChange={val => setEditRole({...editRole, mainAbo: val})}
                            ></Field>
                            <hr />
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        type="input"
                                        title={t("organizationRolesHandler.role.price")}
                                        value={editRole["price"]}
                                        onChange={val => setEditRole({...editRole, price: val})}
                                    ></Field>
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        type="input"
                                        title={t("organizationRolesHandler.role.annual_price")}
                                        value={editRole["annualPrice"]}
                                        onChange={val => setEditRole({...editRole, annualPrice: val})}
                                    ></Field>
                                </div>
                            </div>
                           
                            <Field
                                type="checkbox"
                                title={t("organizationRolesHandler.role.highestValue")}
                                value={editRole["highestValue"]}
                                onChange={val => setEditRole({...editRole, highestValue: val})}
                            ></Field>
                            <hr />
                            <Field
                                type="textlanguage"
                                title={t("organizationRolesHandler.role.desc")}
                                value={editRole["description"]}
                                onChange={val => setEditRole({...editRole, description: val})}
                            ></Field>
                        </div>
                        <div className="contentList-container">
                            {editRole["contentList"]?.map((item, key) => {
                                return (
                                    <div className="contentList">
                                        <div className="contentListItemField">
                                            <Field
                                                key={key}
                                                type="textlanguage"
                                                title={t("organizationRolesHandler.role.content_item")}
                                                value={item}
                                                onChange={val => updateContentItem(val, key)}
                                            ></Field>
                                        </div>
                                        <div className="contentListItemButton">
                                            <Button onClick={() => setEditRole({...editRole, contentList: editRole["contentList"].filter(i => i != item)})}>{t("organizationRolesHandler.role.remove_content_item")}</Button>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="contentList">
                                <div className="contentListItemField">
                                    <Field
                                        type="textlanguage"
                                        title={t("organizationRolesHandler.role.new_content_item")}
                                        value={newContentItem}
                                        onChange={val => setNewContentItem(val)}
                                    ></Field>
                                </div>
                                <div className="contentListItemButton">
                                    <Button onClick={() => {
                                        setEditRole({...editRole, contentList: [...editRole["contentList"], newContentItem]});
                                        setNewContentItem("");
                                    }}>{t("organizationRolesHandler.role.add_content_item")}</Button>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <Field
                            type="checkbox"
                            title={t("organizationRolesHandler.role.hidden")}
                            value={editRole["hidden"]}
                            onChange={val => setEditRole({...editRole, hidden: val})}
                        ></Field>
                        <Field
                            type="selectmulti"
                            title={t("organizationRolesHandler.role.choose_orgs")}
                            value={editRole?.["selectedOrgs"]?.map(val => { return { value: val, label: orgOptions.find(opt => opt.value == val)?.label ?? val}}) ?? []}
                            //user["accessRoles"].map(val => { return { value: val, isFixed: accessRolesOptions.find(role => role["value"] == val) == undefined ? true : false, label: (accessRolesOptions.find(role => role["value"] == val)?.["label"] != undefined ? (td(accessRolesOptions.find(role => role["value"] == val)?.["label"])) : (td(allRoleNames?.find(role => role["value"] == val)?.["label"]) + " " + t("users.unavailable_access_role_for_org"))) } } )}
                            onChange={val => setEditRole({...editRole, selectedOrgs: val.map(v => v.value)})}
                            //disabled={auth.user?.["userdata"]["actualAccessRole"]["rules"].find(rule => rule.objType == "users" && rule.objSubType == "accesscontrol")?.actions?.edit != "allow" ?? true}
                            options={{
                                selecttext: t("users.selecttext"),
                                choices: orgOptions
                            }}
                        ></Field>
                        <hr />
                        {editRole["rules"] != undefined ?
                            <div className="rules">
                                <p>{t("organizationRolesHandler.role.rules.content")}</p>
                                <p>{t("organizationRolesHandler.role.rules.assessments")}</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <p>{t("organizationRolesHandler.role.rules.system")}</p>
                                        <div>
                                            <div className="rule_checkbox">
                                                <Field
                                                    type="checkbox"
                                                    title={t("organizationRolesHandler.role.rules.allowed")}
                                                    value={editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "system")?.allowed ?? false}
                                                    onChange={val => handleEditAllowed(val, "system", "system")}
                                                ></Field>
                                            </div>
                                            
                                            {editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "system")?.allowed ?
                                                <>
                                                    <div className="rule_checkbox">
                                                        <Field
                                                            type="checkbox"
                                                            title={t("organizationRolesHandler.role.rules.unlimited")}
                                                            value={(editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "system")?.limitations.amount == null) ?? false}
                                                            onChange={val => val == true ? handleEditAmount(null, "system", "system") : handleEditAmount(0, "system", "system")}
                                                        ></Field>
                                                    </div>
                                                    <div className="rule_field">
                                                        {editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "system")?.limitations.amount != null ?
                                                            <Field
                                                                type="input"
                                                                title={t("organizationRolesHandler.role.rules.amount")}
                                                                value={editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "system")?.limitations.amount ?? 0}
                                                                onChange={val => handleEditAmount(val, "system", "system")}
                                                            ></Field>
                                                        : null }
                                                    </div>
                                                </>
                                            : null }
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-4">
                                        <p>{t("organizationRolesHandler.role.rules.orgros")}</p>
                                        <div>
                                            <Field
                                                type="checkbox"
                                                title={t("organizationRolesHandler.role.rules.allowed")}
                                                value={editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "orgros")?.allowed ?? false}
                                                onChange={val => handleEditAllowed(val, "system", "orgros")}
                                            ></Field>
                                            {editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "orgros")?.allowed ?
                                                <div>
                                                    <Field
                                                        type="checkbox"
                                                        title={t("organizationRolesHandler.role.rules.unlimited")}
                                                        value={(editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "orgros")?.limitations.amount == null) ?? false}
                                                        onChange={val => val == true ? handleEditAmount(null, "system", "orgros") : handleEditAmount(0, "system", "orgros")}
                                                    ></Field>
                                                    {editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "orgros")?.limitations.amount != null ?
                                                        <Field
                                                            type="input"
                                                            title={t("organizationRolesHandler.role.rules.amount")}
                                                            value={editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "orgros")?.limitations.amount ?? 0}
                                                            onChange={val => handleEditAmount(val, "system", "orgros")}
                                                        ></Field>
                                                    : null }
                                                </div>
                                            : null }
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <p>{t("organizationRolesHandler.role.rules.other")}</p>
                                        <div>
                                            <Field
                                                type="checkbox"
                                                title={t("organizationRolesHandler.role.rules.allowed")}
                                                value={editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "other")?.allowed ?? false}
                                                onChange={val => handleEditAllowed(val, "system", "other")}
                                            ></Field>
                                            {editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "other")?.allowed ?
                                                <div>
                                                    <Field
                                                        type="checkbox"
                                                        title={t("organizationRolesHandler.role.rules.unlimited")}
                                                        value={(editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "other")?.limitations.amount == null) ?? false}
                                                        onChange={val => val == true ? handleEditAmount(null, "system", "other") : handleEditAmount(0, "system", "other")}
                                                    ></Field>
                                                    {editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "other")?.limitations.amount != null ?
                                                        <Field
                                                            type="input"
                                                            title={t("organizationRolesHandler.role.rules.amount")}
                                                            value={editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "other")?.limitations.amount ?? 0}
                                                            onChange={val => handleEditAmount(val, "system", "other")}
                                                        ></Field>
                                                    : null }
                                                </div>
                                            : null }
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-4">
                                        <p>{t("organizationRolesHandler.role.rules.riskAssessmentExcelExport")}</p>
                                        <Field
                                            type="checkbox"
                                            title={t("organizationRolesHandler.role.rules.allowed")}
                                            value={editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "risk_assessment_excel_export")?.allowed ?? false}
                                            onChange={val => handleEditAllowed(val, "system", "risk_assessment_excel_export")}
                                        ></Field>
                                    </div>
                                    <div className="col-md-4">
                                        <p>{t("organizationRolesHandler.role.rules.riskAssessmentReport")}</p>
                                        <Field
                                            type="checkbox"
                                            title={t("organizationRolesHandler.role.rules.allowed")}
                                            value={editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "risk_assessment_report")?.allowed ?? false}
                                            onChange={val => handleEditAllowed(val, "system", "risk_assessment_report")}
                                        ></Field>
                                    </div>
                                    <div className="col-md-4">
                                        <p>{t("organizationRolesHandler.role.rules.exportAllRiskAssessment")}</p>
                                        <Field
                                            type="checkbox"
                                            title={t("organizationRolesHandler.role.rules.allowed")}
                                            value={editRole["rules"]?.find(rule => rule.objType == "system" && rule.objSubType == "export_all_risk_assessments")?.allowed ?? false}
                                            onChange={val => handleEditAllowed(val, "system", "export_all_risk_assessments")}
                                        ></Field>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>{t("organizationRolesHandler.role.rules.survey")}</p>
                                        <div>
                                            <Field
                                                type="checkbox"
                                                title={t("organizationRolesHandler.role.rules.allowed")}
                                                value={editRole["rules"]?.find(rule => rule.objType == "survey" && rule.objSubType == "survey")?.allowed ?? false}
                                                onChange={val => handleEditAllowed(val, "survey", "survey")}
                                            ></Field>
                                            {editRole["rules"]?.find(rule => rule.objType == "survey" && rule.objSubType == "survey")?.allowed ?
                                                <div>
                                                    <Field
                                                        type="checkbox"
                                                        title={t("organizationRolesHandler.role.rules.unlimited")}
                                                        value={(editRole["rules"]?.find(rule => rule.objType == "survey" && rule.objSubType == "survey")?.limitations.amount == null) ?? false}
                                                        onChange={val => val == true ? handleEditAmount(null, "survey", "survey") : handleEditAmount(0, "survey", "survey")}
                                                    ></Field>
                                                    {editRole["rules"]?.find(rule => rule.objType == "survey" && rule.objSubType == "survey")?.limitations.amount != null ?
                                                        <Field
                                                            type="input"
                                                            title={t("organizationRolesHandler.role.rules.amount")}
                                                            value={editRole["rules"]?.find(rule => rule.objType == "survey" && rule.objSubType == "survey")?.limitations.amount ?? 0}
                                                            onChange={val => handleEditAmount(val, "survey", "survey")}
                                                        ></Field>
                                                    : null }
                                                </div>
                                            : null }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <p>{t("organizationRolesHandler.role.rules.tasks")}</p>
                                        <Field
                                            type="checkbox"
                                            title={t("organizationRolesHandler.role.rules.allowed")}
                                            value={editRole["rules"]?.find(rule => rule.objType == "tasks" && rule.objSubType == "tasks")?.allowed ?? false}
                                            onChange={val => handleEditAllowed(val, "tasks", "tasks")}
                                        ></Field>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>{t("organizationRolesHandler.role.rules.users")}</p>
                                        <div>
                                            {/* <Field
                                                type="checkbox"
                                                title={t("organizationRolesHandler.role.rules.allowed")}
                                                value={editRole["rules"]?.find(rule => rule.objType == "survey" && rule.objSubType == "survey")?.allowed ?? false}
                                                onChange={val => handleEditAllowed(val, "survey", "survey")}
                                            ></Field> */}
                                            <div>
                                                <Field
                                                    type="checkbox"
                                                    title={t("organizationRolesHandler.role.rules.unlimited")}
                                                    value={(editRole["rules"]?.find(rule => rule.objType == "users" && rule.objSubType == "users")?.limitations.amount == null) ?? false}
                                                    onChange={val => val == true ? handleEditAmount(null, "users", "users") : handleEditAmount(0, "users", "users")}
                                                ></Field>
                                                {editRole["rules"]?.find(rule => rule.objType == "users" && rule.objSubType == "users")?.limitations.amount != null ?
                                                    <Field
                                                        type="input"
                                                        title={t("organizationRolesHandler.role.rules.amount")}
                                                        value={editRole["rules"]?.find(rule => rule.objType == "users" && rule.objSubType == "users")?.limitations.amount ?? 0}
                                                        onChange={val => handleEditAmount(val, "users", "users")}
                                                    ></Field>
                                                : null }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>{t("organizationRolesHandler.role.rules.organization")}</p>
                                        <div>
                                            {/* <Field
                                                type="checkbox"
                                                title={t("organizationRolesHandler.role.rules.allowed")}
                                                value={editRole["rules"]?.find(rule => rule.objType == "survey" && rule.objSubType == "survey")?.allowed ?? false}
                                                onChange={val => handleEditAllowed(val, "survey", "survey")}
                                            ></Field> */}
                                            <div>
                                                <Field
                                                    type="checkbox"
                                                    title={t("organizationRolesHandler.role.rules.unlimited")}
                                                    value={(editRole["rules"]?.find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.limitations.amount == null) ?? false}
                                                    onChange={val => val == true ? handleEditAmount(null, "organization", "organization") : handleEditAmount(0, "organization", "organization")}
                                                ></Field>
                                                {editRole["rules"]?.find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.limitations.amount != null ?
                                                    <Field
                                                        type="input"
                                                        title={t("organizationRolesHandler.role.rules.amount")}
                                                        value={editRole["rules"]?.find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.limitations.amount ?? 0}
                                                        onChange={val => handleEditAmount(val, "organization", "organization")}
                                                    ></Field>
                                                : null }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <p>{t("organizationRolesHandler.role.rules.apiKey")}</p>
                                        <Field
                                            type="checkbox"
                                            title={t("organizationRolesHandler.role.rules.allowed")}
                                            value={editRole["rules"]?.find(rule => rule.objType == "organization" && rule.objSubType == "generate_api_keys")?.allowed ?? false}
                                            onChange={val => handleEditAllowed(val, "organization", "generate_api_keys")}
                                        ></Field>
                                    </div>
                                </div>
                            </div>
                        : null }
                    </div>
                    : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="cancel posleft" onClick={handleHideEditRoleModal}>{t("organizationRolesHandler.cancel")}</Button>
                    <Button onClick={saveEditedRole}>{t("organizationRolesHandler.save")}</Button>
                </Modal.Footer>
            </Modal>

            {/* <Shop
                show={shopPreview}
                onHide={() => setShopPreview(false)}
                disabled={true}
                size="lg"
                orgid={""}
            /> */}
        </div>
    );
}
 
export default OrganizationRolesHandler;