import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";

import { useAuth } from "../../../../Auth";
import { useAxios } from "../../../../AxiosHandler";

import Field from "../../../Shared/Wizard/Field/Field";
import { countryCodes } from "../../../../Assets/Constants/CountryCodes";
import { VATtypes } from "../../../../Assets/Constants/VATtypes";
import { useDynamicLanguage } from "../../../../DynamicLanguageProvider";

import { generateUrl } from "../../../../config";

import "../../Shop/Shop.scss";
import "./PaymentModal.scss";

function PaymentModal(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();
  let { systemid } = useParams();
  const myRef = React.useRef<any>();
  const td = useDynamicLanguage().td;

  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState<any>("");
  const [lookup_key, setLookup_key] = useState<any>(null);
  const [loading, setloading] = useState(false);

  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerCountryCode, setCustomerCountryCode] = useState({
    value: "",
    label: "",
  });
  const [customerPostalCode, setCustomerPostalCode] = useState("");
  const [customerStreetAddress, setCustomerStreetAddress] = useState("");
  const [customerCity, setCustomerCity] = useState("");
  const [customerState, setCustomerState] = useState("");
  const [customerDesc, setCustomerDesc] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(""); //card or invoice
  const [foundCustomerName, setFoundCustomerName] = useState("");
  const [foundCustomerEmail, setFoundCustomerEmail] = useState("");
  const [foundCustomerCountryCode, setFoundCustomerCountryCode] = useState({
    value: "",
    label: "",
  });
  const [foundCustomerPostalCode, setFoundCustomerPostalCode] = useState("");
  const [foundCustomerStreetAddress, setFoundCustomerStreetAddress] = useState(
    ""
  );
  const [foundCustomerCity, setFoundCustomerCity] = useState("");
  const [foundCustomerState, setFoundCustomerState] = useState("");
  const [foundCustomerDesc, setFoundCustomerDesc] = useState("");

  const [vatid, setVatid] = useState("");
  const [vattype, setVattype] = useState("");

  const [showPayerInfoModal, setShowPayerInfoModal] = useState(false);

  const formUrlEncoded = (x) =>
    Object.keys(x).reduce(
      (p, c) => p + `&${c}=${encodeURIComponent(x[c])}`,
      ""
    );

  /* const options = {
        // passing the client secret obtained from the server
        clientSecret: props.clientSecret
    }; */

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  let userdata = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
    userdata = JSON.parse(localStorageUser)["userdata"];
  }

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const handleShowPayerInfoModal = (type) => {
    setPaymentMethod(type);
    setShowPayerInfoModal(true);
  };
  const handleClosePayerInfoModal = () => {
    setPaymentMethod("");
    setShowPayerInfoModal(false);
    setCustomerName("");
    setCustomerEmail("");
  };

  /*  const SuccessDisplay = ({ sessionId }) => {
        return (
            <section>
                <div className="product Box-root">
                    <div className="description Box-root">
                        <h3>Subscription to starter plan successful!</h3>
                    </div>
                </div>
                <form action="/create-portal-session" method="POST">
                    <input
                        type="hidden"
                        id="session-id"
                        name="session_id"
                        value={sessionId}
                    />
                    <button id="checkout-and-portal-button" type="submit">
                        Manage your billing information
                    </button>
                </form>
            </section>
        );
    }; */

  /* const Message = ({ message }) => (
        <section>
            <p>{message}</p>
        </section>
    ); */

  /* function handleCheckOut(){
        axios.single({
            method: "post",
            url: "/api/cra/stripe/create-checkout-session",
            data: {
                lookup_key: props.product.lookup_key
            },
            headers: {'x-access-token': auth.user["token"] }
        });
    } */
  function handleCheckOut() {
    setloading(true);
    axios
      .post(
        generateUrl("/api/cra/stripe/paymentIntentCard"),
        {
          object: props.product,
          orgId: props.orgid,
          payerName: customerName == "" ? foundCustomerName : customerName,
          payerEmail: customerEmail == "" ? foundCustomerEmail : customerEmail,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        axios
          .single({
            method: "post",
            url: generateUrl("/api/cra/stripe/create-checkout-session"),
            data: formUrlEncoded({
              lookup_key: props.product.lookup_key,
              paymentIntentId: res.data.paymentIntentId,
              payerName: customerName == "" ? foundCustomerName : customerName,
              payerEmail:
                customerEmail == "" ? foundCustomerEmail : customerEmail,
              orgId: props.orgid,
              userId: auth.user["userdata"]["_id"].toString(),
              orgRoleId: props.product.orgRole._id.toString(),
              redirect_uri: "http://localhost:3000/",
              cancel_url: props.cancelUrl != undefined ? props.cancelUrl : "",
              success_url:
                props.successUrl != undefined ? props.successUrl : "",
            }),
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          })
          .then((res) => {
            setloading(false);
            window.location = res.data.url;
            handleClosePayerInfoModal();
          });
      });
  }

  function handleCheckOutBankTransfer() {
    if (customerEmail !== "") {
      let pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      if (!pattern.test(customerEmail)) {
        notifyWarning(t("shop.paymentmodal.enter_valid_email"));
        return undefined;
      }
    }
    if (
      !countryCodes
        .map((c) => c["iso_3166-2"].split(":")[1])
        .includes(customerCountryCode.value) &&
      !countryCodes
        .map((c) => c["iso_3166-2"].split(":")[1])
        .includes(foundCustomerCountryCode.value)
    ) {
      notifyWarning(t("shop.paymentmodal.enter_valid_country_code"));
      return undefined;
    }

    /* if(vatid == "" || vattype == ""){
            notifyWarning(t("shop.paymentmodal.missing_vat_info"));
            return undefined;
        } */

    setloading(true);
    axios
      .post(
        generateUrl("/api/cra/stripe/paymentIntentBankTransfer"),
        {
          object: props.product,
          orgid: props.orgid,
          lookup_key: props.product.lookup_key,
          payerName: customerName == "" ? foundCustomerName : customerName,
          payerEmail: customerEmail == "" ? foundCustomerEmail : customerEmail,
          payerCountryCode:
            customerCountryCode.value == ""
              ? foundCustomerCountryCode.value
              : customerCountryCode.value,
          payerPostalCode:
            customerPostalCode == ""
              ? foundCustomerPostalCode
              : customerPostalCode,
          payerStreetAddress:
            customerStreetAddress == ""
              ? foundCustomerStreetAddress
              : customerStreetAddress,
          payerCity: customerCity == "" ? foundCustomerCity : customerCity,
          payerDesc: customerDesc == "" ? foundCustomerDesc : customerDesc,
          vatid: vatid,
          vattype: vattype,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        setloading(false);
        if (res.data.foundError == true) {
          notifyWarning(
            res.data.error.code
              ? t("shop.paymentmodal.errors." + res.data?.error?.code)
              : res.data.error?.raw?.message ?? "Unknown error"
          );
        } else {
          if (
            props.successTransferUrl == undefined ||
            props.successTransferUrl == ""
          ) {
            window.location =
              res.data.paymentIntent?.next_action
                ?.display_bank_transfer_instructions?.hosted_instructions_url ??
              "#";
          } else {
            window.location = props.successTransferUrl;
          }
          handleClosePayerInfoModal();
          props.onPurchaseComplete();
          props.onHide();
        }
      });
  }

  function fetchCustomer() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/stripe/customerByOrg/") + props.orgid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/orgelements/") + props.orgid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then((res) => {
        //let name = res[0].data?.name.substring(res[0].data.name.indexOf(') ') + 1) ?? "";
        setFoundCustomerName(res[0].data?.name ?? "");
        setFoundCustomerEmail(res[0].data?.email ?? "");
        setFoundCustomerCountryCode({
          value: res[0].data?.address?.country,
          label:
            countryCodes?.find(
              (c) =>
                c["iso_3166-2"].split(":")[1] == res[0].data?.address?.country
            )?.["name"] ?? "",
        });
        setFoundCustomerPostalCode(res[0].data?.address?.postal_code);
        setFoundCustomerStreetAddress(res[0].data?.address?.line1);
        setFoundCustomerCity(res[0].data?.address?.city);
        setFoundCustomerState(res[0].data?.address?.state);
        setFoundCustomerDesc(res[0].data?.description);
        setVatid(res[1].data[0].vatid);
        setVattype(res[1].data[0].vattype);
        //setFoundCustomer(res[0].data)
      });
  }

  /*  useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setSuccess(true);
            setSessionId(query.get('session_id'));
        }

        if (query.get('canceled')) {
            setSuccess(false);
            setMessage(
            "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, [sessionId]); */

  useEffect(() => {
    if (props.orgid != undefined && props.orgid != "") {
      fetchCustomer();
    }
  }, [props.orgid]);

  return (
    <div>
      <Modal className="shop" show={props.show} onHide={props.onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t("shop.paymentmodal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="shopItems">
            {props.orgid != "" && props.orgid != undefined ? (
              <div className="shopItem-container">
                <div className="shopItem">
                  <div className="description">
                    <h3>{td(props?.product?.orgRole?.name)}</h3>
                    <p className="shopItem-price">
                      {props?.product?.price / 100 +
                        " " +
                        props?.product?.priceObject?.currency}{" "}
                      <span>{t("shop.paymentmodal.month")}</span>
                    </p>
                    <p className="shopItem-excludingVAT">
                      *{t("shop.excludingVAT")}
                    </p>
                    <p className="shopItem-name">
                      {td(props?.product?.orgRole?.name)}
                    </p>
                    {props?.product?.orgRole?.description != "" ? (
                      <div className="shopItem-desc">
                        <p className="shopItem-descParagraph">
                          {td(props?.product?.orgRole?.description)}
                        </p>
                      </div>
                    ) : null}

                    <ul>
                      {props?.product?.orgRole?.contentList?.map(
                        (item, key) => {
                          return (
                            <li key={key}>
                              <span className="shopItem-listIcon">
                                <i className="dripicons-checkmark" />
                              </span>
                              <span>{td(item)}</span>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
                {/* <form action="/api/cra/stripe/create-checkout-session" method="POST"> */}
                {/* Add a hidden field with the lookup_key of your Price */}
                {/* <input type="hidden" name="lookup_key" value={props.product.lookup_key} /> */}
                <Button
                  disabled={loading}
                  onClick={() => handleCheckOut()}
                  //onClick={() => handleShowPayerInfoModal("card")}
                  className="btn btn-primary shopItem-btn"
                  id="checkout-and-portal-button" /* type="submit" */
                >
                  {t("shop.paymentmodal.checkout_card")}
                </Button>
                <Button
                  disabled={loading}
                  // onClick={() => handleCheckOutBankTransfer()}
                  onClick={() => handleShowPayerInfoModal("invoice")}
                  className="btn btn-primary shopItem-btn"
                  id="checkout-and-portal-button" /* type="submit" */
                >
                  {t("shop.paymentmodal.checkout_banktransfer")}
                </Button>
                {/* </form> */}

                {/* {props.clientSecret != ""  && props.clientSecret != undefined?
                                    <Elements stripe={stripePromise} options={options}>
                                        <PaymentElement />
                                    </Elements>
                                : null} */}
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>
            {t("shop.paymentmodal.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="payer_info_modal"
        show={showPayerInfoModal}
        onHide={() => setShowPayerInfoModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("shop.paymentmodal.payer_info")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="payer_info_title">
            {t("shop.paymentmodal.organisation_information")}
          </p>
          <Field
            type="input"
            title={t("shop.paymentmodal.organisation_name")}
            value={customerName}
            onChange={(val) => setCustomerName(val)}
            placeholder={foundCustomerName}
          ></Field>
          <Field
            type="input"
            title={t("shop.paymentmodal.payer_email")}
            value={customerEmail}
            onChange={(val) => setCustomerEmail(val)}
            placeholder={foundCustomerEmail}
          ></Field>
          <div className="">
            <Field
              type="select"
              //disabled={vattype != ""}
              title={t("customeroverview.general.vattype")}
              value={vattype}
              onChange={(val) => setVattype(val.value)}
              options={{
                choosetext: t("customeroverview.general.choose"),
                choices: VATtypes.map((type) => {
                  return {
                    label: type.country + " (" + type.description + ")",
                    value: type.enum,
                  };
                }),
              }}
            ></Field>
            <Field
              type="input"
              disabled={vattype == "" || vattype == undefined}
              title={t("customeroverview.general.vatid")}
              value={vatid}
              onChange={(val) => setVatid(val)}
              placeholder={
                vattype != "" && vattype != undefined
                  ? "Ex.: " +
                      VATtypes?.find((type) => type.enum == vattype)?.example ??
                    ""
                  : ""
              }
            ></Field>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Field
                type="input"
                title={t("shop.paymentmodal.payer_desc")}
                value={customerDesc}
                onChange={(val) => setCustomerDesc(val)}
                placeholder={foundCustomerDesc}
              ></Field>
            </div>
          </div>
          <hr />
          <p className="payer_info_title">
            {t("shop.paymentmodal.organisation_address_information")}
          </p>
          <div className="row">
            <div className="col-md-12">
              <Field
                type="autocomplete"
                value={
                  customerCountryCode.label != undefined
                    ? customerCountryCode.label
                    : ""
                }
                title={t("shop.paymentmodal.payer_country")}
                onChange={(value) =>
                  setCustomerCountryCode({
                    value:
                      countryCodes
                        .find((c) => c.name == value)
                        ?.["iso_3166-2"]?.split(":")[1] ?? "",
                    label: value,
                  })
                }
                options={{
                  list: countryCodes.map((c) => {
                    return {
                      value: c["iso_3166-2"].split(":")[1],
                      label: c.name,
                    };
                  }),
                  minlength: 2,
                  noresults: "no matches",
                  placeholder: foundCustomerCountryCode?.["label"] ?? "",
                }}
              ></Field>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Field
                type="input"
                title={t("shop.paymentmodal.payer_street_address")}
                value={customerStreetAddress}
                onChange={(val) => setCustomerStreetAddress(val)}
                placeholder={foundCustomerStreetAddress}
              ></Field>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Field
                type="input"
                title={t("shop.paymentmodal.payer_city")}
                value={customerCity}
                onChange={(val) => setCustomerCity(val)}
                placeholder={foundCustomerCity}
              ></Field>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <Field
                type="input"
                title={t("shop.paymentmodal.payer_state")}
                value={customerState}
                onChange={(val) => setCustomerState(val)}
                placeholder={foundCustomerState}
              ></Field>
            </div>
            <div className="col-md-4">
              <Field
                type="input"
                title={t("shop.paymentmodal.payer_postal_code")}
                value={customerPostalCode}
                onChange={(val) => setCustomerPostalCode(val)}
                placeholder={foundCustomerPostalCode}
              ></Field>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} onClick={handleClosePayerInfoModal}>
            {" "}
            {t("shop.paymentmodal.cancel")}{" "}
          </Button>
          <Button
            disabled={
              (customerEmail == "" &&
                customerName == "" &&
                (foundCustomerEmail == "" || foundCustomerName == "")) ||
              (foundCustomerEmail == "" &&
                foundCustomerName == "" &&
                (customerEmail == "" || customerName == "")) ||
              loading
            }
            onClick={() =>
              paymentMethod == "card"
                ? handleCheckOut()
                : paymentMethod == "invoice"
                ? handleCheckOutBankTransfer()
                : null
            }
          >
            {" "}
            {paymentMethod == "card"
              ? t("shop.paymentmodal.continue")
              : t("shop.paymentmodal.complete")}{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PaymentModal;
