import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useAxios } from "../../../../../AxiosHandler";

import { useTranslate } from 'react-polyglot';
import './SensitivepoDistribution.scss';
import { useAuth } from "../../../../../Auth";
import { Card, Table } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { Button } from 'react-bootstrap';
import Field from '../../../../Shared/Wizard/Field/Field';

import _ from "lodash";

const SensitivepoDistribution = (props) => {
    const history = useHistory();

    const [sensitivepoDoughnutNo, setSensitivepoDoughnutNo] = useState(0);
    const [sensitivepoDoughnutYes, setSensitivepoDoughnutYes] = useState(0);
    const [sensitivepoDoughnutYesSens, setSensitivepoDoughnutYesSens] = useState(0);

    const [chartOptions, setChartOptions] = useState({});

    const [selectedOption, setSelectedOption] = useState("system");
    
    const [hoverElements, setHoverElements] = useState([]);

    const [loading, setLoading] = useState(true);

    const t = useTranslate();

    let selectTitle = t("dashboard.cards.drop_down_select_title");
    let selectOptions = [
        {
            "label": t("dashboard.cards.choice_system"),
            "value": "system"
        },
        {
            "label": t("dashboard.cards.choice_orgros"),
            "value": "orgros"
        },
        {
            "label": t("dashboard.cards.choice_other"),
            "value": "other"
        },
    ]

    const labelNo = t("dashboard.cards.sensitivepo.label_no");
    const labelYes = t("dashboard.cards.sensitivepo.label_yes");
    const labelYesSens = t("dashboard.cards.sensitivepo.label_yessens");

    function handleSelectInput(value){
        setLoading(true);
        setSelectedOption(value);
    }

    async function processData(res, type) {
        let data = {
            tempSensitivepoDoughnutNo: 0,
            tempSensitivepoDoughnutYes: 0,
            tempSensitivepoDoughnutYesSens: 0,
            tempSensitivepoDoughnutNoSystems: [] as Array<any>,
            tempSensitivepoDoughnutYesSystems: [] as Array<any>,
            tempSensitivepoDoughnutYesSensSystems: [] as Array<any>,
        }

        let tempSensitivepoDoughnutNo = 0;
        let tempSensitivepoDoughnutYes = 0;
        let tempSensitivepoDoughnutYesSens = 0;
        let tempSensitivepoDoughnutNoSystems: Array<any> = [];
        let tempSensitivepoDoughnutYesSystems: Array<any> =  [];
        let tempSensitivepoDoughnutYesSensSystems: Array<any> = [];

        let selectOptionsValues = selectOptions.map(option => option.value);

        if (type == "system"){
            res[0].data = res[0].data.filter(res => res.systemType.toString().toLowerCase().includes(type) || !selectOptionsValues.includes(res.systemType.toString().toLowerCase()));
        } else {
            res[0].data = res[0].data.filter(res => res.systemType.toString().toLowerCase() == type);
        }
        
        res[0].data.filter(s => s.accessRule != "adminprivate").forEach((system, key) => {
            let haspo = system.haspo;
            let hasSensitivepo = system.hassensitivepo;

            if (hasSensitivepo) {
                tempSensitivepoDoughnutYesSensSystems.push(system);
                tempSensitivepoDoughnutYesSens += 1;
            } else if (haspo) {
                tempSensitivepoDoughnutYes += 1;
                tempSensitivepoDoughnutYesSystems.push(system);
            } else {
                tempSensitivepoDoughnutNo += 1;
                tempSensitivepoDoughnutNoSystems.push(system);
            }
        });

        data.tempSensitivepoDoughnutNo = tempSensitivepoDoughnutNo;
        data.tempSensitivepoDoughnutYes = tempSensitivepoDoughnutYes;
        data.tempSensitivepoDoughnutYesSens = tempSensitivepoDoughnutYesSens;
        data.tempSensitivepoDoughnutNoSystems = tempSensitivepoDoughnutNoSystems;
        data.tempSensitivepoDoughnutYesSystems = tempSensitivepoDoughnutYesSystems;
        data.tempSensitivepoDoughnutYesSensSystems = tempSensitivepoDoughnutYesSensSystems;

        setSensitivepoDoughnutNo(data["tempSensitivepoDoughnutNo"]);
        setSensitivepoDoughnutYes(data["tempSensitivepoDoughnutYes"]);
        setSensitivepoDoughnutYesSens(data["tempSensitivepoDoughnutYesSens"]);
        setLoading(false);
        setChartOptions({
            legend: {
                position: "bottom"
            },
            onClick: function(evt, data2) {
                if (data2.length > 0) {
                    switch (data2[0]._index) {
                        case 0: setHoverElements(_.orderBy(data["tempSensitivepoDoughnutNoSystems"], ["desc"]) ); break;
                        case 1: setHoverElements(_.orderBy(data["tempSensitivepoDoughnutYesSystems"], ["desc"]) ); break;
                        case 2: setHoverElements(_.orderBy(data["tempSensitivepoDoughnutYesSensSystems"], ["desc"]) ); break;
                    }
                }
            },
        });
    }
    
    useEffect(() => {
        Promise.all([
            props.requestData("systems"),
            props.requestData("systemvalues"),
            props.requestData("orgelements"),
        ]).then(async (data) => {
            await processData(data, selectedOption);
            setLoading(false);
        });
    }, [selectedOption]);

    return (
        <Card data-satarget="riskoverview">
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                <h4 className="header-title">{t("dashboard.cards.sensitivepodistribution_name")}</h4>
                <p>{t("dashboard.cards.sensitivepo.information")}</p>
                <Field
                    type="select"
                    title={ selectTitle }
                    onChange={ value => handleSelectInput(value.value) }
                    options={{
                        "choices": selectOptions
                    }}
                > 
                </Field>
                <hr/>
                <div className={"clickable"}>
                    <Doughnut
                        redraw={true}
                        data={{
                            datasets: [
                                {
                                    data: [sensitivepoDoughnutNo, sensitivepoDoughnutYes, sensitivepoDoughnutYesSens],
                                    backgroundColor: [
                                        "rgb(91, 130, 102, 1)",
                                        "rgb(216, 119, 90, 1)",
                                        "#993742",
                                    ]
                                }
                            ],
                            labels:[
                                labelNo,
                                labelYes,
                                labelYesSens,
                            ]
                        }}
                        options={chartOptions}
                    />
                </div>
                {hoverElements != undefined && hoverElements.length > 0 ?
                    <div className="chartDetails">
                        <div>
                            <h4 className="header-title">{t("system.matrix_details_title")}</h4>
                            <Button onClick={() => {
                                setHoverElements([]);
                            }}>{t("system.matrix_details_closebtn")}</Button>
                            <Table striped hover className="chartdetailstable">
                                <thead>
                                    <tr>
                                        <th>{t("system_description.system_name")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        hoverElements.map((system, key) => {
                                            return(
                                                <tr className="clickable" key={key} onClick={() => { history.push("/system/" + system["_id"]) }}>
                                                    <td>{ system["nameReadable"] }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                : null}
            </Card.Body>
        </Card>
    );
}

export default SensitivepoDistribution;
