export const getOperationStatus = (operationStatus: string) => {
  if (operationStatus == "Ukjent") {
    return "system_description.system_wizard.status.unknown";
  } else if (operationStatus == "Utvikling") {
    return "system_description.system_wizard.status.indevelopment";
  } else if (operationStatus == "Test") {
    return "system_description.system_wizard.status.test";
  } else if (operationStatus == "Pilot") {
    return "system_description.system_wizard.status.pilot";
  } else if (operationStatus == "Produksjon") {
    return "system_description.system_wizard.status.production";
  } else if (operationStatus == "Under utfasing") {
    return "system_description.system_wizard.status.phasingout";
  } else if (operationStatus == "faset ut") {
    return "system_description.system_wizard.status.phasedout";
  } else if (operationStatus == "Under oppbevaring") {
    return "system_description.system_wizard.status.storage";
  } else if (operationStatus == "fjernet") {
    return "system_description.system_wizard.status.discontinued";
  }
};
