import { generateUrl } from "../../../../config";
import WizardFieldManipulator from "../../WizardHelpers/WizardFieldManipulator";

const GetWizardFieldByReference =
  WizardFieldManipulator.GetWizardFieldByReference;
const GetWizardValuesByReferenceList =
  WizardFieldManipulator.GetWizardValuesByReferenceList;

async function fetchData(itemId, axios, auth): Promise<any> {
  let results = {
    system: undefined,
    users: [],
    organizations: [],
  };

  await axios
    .all([
      axios.single({
        method: "get",
        url: generateUrl("/api/cra/systems/") + itemId,
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      }),
      axios.single({
        method: "get",
        url: generateUrl("/api/users/"),
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      }),
      axios.single({
        method: "get",
        url: generateUrl("/api/orgElements/"),
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      }),
    ])
    .then(
      axios.spread(async (...res) => {
        results.system = res[0].data;
        results.users = res[1].data;
        results.organizations = res[2].data;
      })
    );

  return results;
}

async function SystemDescriptionTemplateOrgros(t, axios, auth, itemId) {
  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  let data = await fetchData(itemId, axios, auth);

  let tempWizardData: Array<any> = [
    {
      fields: [
        {
          type: "text_title",
          title: t("diri.regassistant.types.orgros.title"),
        },
        {
          type: "select",
          title: t("diri.regassistant.types.orgros.org") + " *",
          disabled: true,
          options: {
            typeLoad: "Org",
            choosetext: t("diri.regassistant.types.orgros.org_select"),
          },
          reference: "selectedorg",
        },
        {
          type: "dateselector",
          title: t("diri.regassistant.types.orgros.startdate"),
          reference: "startdate",
        },
        {
          title: t("diri.regassistant.types.orgros.orgname"),
          value: "",
          type: "autocompletewithid",
          reference: "name",
          options: {
            list: data.organizations.map((i, key) => {
              return { id: key, value: i["name"], label: i["name"] };
            }),
            data: data.organizations,
            minlength: 2,
            noresults: "no matches",
          },
        },
        {
          id: 3,
          title: t("diri.regassistant.types.orgros.system_responsible"),
          value: "",
          type: "autocompletewithid",
          reference: "responsibleperson",
          options: {
            list: data.users.map((i, key) => {
              return { id: key, label: i["name"], value: i["name"] };
            }),
            data: data.users,
            minlength: 2,
            noresults: "no matches",
          },
        },
        {
          type: "input",
          title: t("diri.regassistant.types.orgros.industrycode"),
          reference: "industrycode",
        },
        {
          type: "select",
          title: t("diri.regassistant.types.orgros.industryssb"),
          reference: "industryssb",
          options: {
            typeLoad: "Manual",
            choices: [
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.agriculture"
                ),
                value: "agriculture",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.mining"
                ),
                value: "mining",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.industry"
                ),
                value: "industry",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.supplyservices"
                ),
                value: "supplyservices",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.construction"
                ),
                value: "construction",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.retail"
                ),
                value: "retail",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.transportstorage"
                ),
                value: "transportstorage",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.accomodationfood"
                ),
                value: "accomodationfood",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.infcom"
                ),
                value: "infcom",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.financialinsurance"
                ),
                value: "financialinsurance",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.realestate"
                ),
                value: "realestate",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.technicalservices"
                ),
                value: "technicalservices",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.businessservices"
                ),
                value: "businessservices",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.publicadministration"
                ),
                value: "publicadministration",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.teaching"
                ),
                value: "teaching",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.healthsocialwork"
                ),
                value: "healthsocialwork",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.cultural"
                ),
                value: "cultural",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.otherservices"
                ),
                value: "otherservices",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.paidprivatehouseholdwork"
                ),
                value: "paidprivatehouseholdwork",
              },
              {
                label: t(
                  "diri.regassistant.types.orgros.industryssbselections.internationalorgs"
                ),
                value: "internationalorgs",
              },
            ],
          },
        },
        {
          type: "input",
          title: t("diri.regassistant.types.orgros.employeescount"),
          reference: "employeescount",
        },
        {
          type: "textarea",
          title: t("diri.regassistant.types.orgros.orgdesc"),
          reference: "orgdesc",
        },
        {
          type: "textarea",
          title: t("diri.regassistant.types.orgros.securityrequirements"),
          reference: "securityrequirements",
        },
        {
          type: "text_normal",
          title: t("diri.regassistant.types.orgros.securityrequirements_desc"),
        },
        {
          type: "textarea",
          title: t("diri.regassistant.types.orgros.importantdeliveries"),
          reference: "importantdeliveries",
        },
        {
          type: "text_normal",
          title: t("diri.regassistant.types.orgros.importantdeliveries_desc"),
        },
      ],
    },
    {
      fields: [
        {
          type: "text_title",
          title: t(
            "system_description.system_wizard.new_system.system_participants"
          ),
          options: {
            headertag: "h2",
          },
        },
        {
          title: t("wizard_title.input.riskassessmentparticipants"),
          value: [],
          type: "personselector",
          reference: "riskassessmentparticipants",
          options: {
            users: data.users,
            organizations: data.organizations,
            minlength: 2,
            noresults: t(
              "wizard_title.input.riskassessmentparticipants_noresult"
            ),
            roleTitle: t("wizard_title.input.riskassessmentparticipants_role"),
            organizationTitle: t(
              "wizard_title.input.riskassessmentparticipants_organization"
            ),

            deleteButtonText: t(
              "wizard_title.input.riskassessmentparticipants_deletebtn"
            ),
            addButtonText: t(
              "wizard_title.input.riskassessmentparticipants_addbtn"
            ),
          },
        },
      ],
    },
  ];
  GetWizardFieldByReference(tempWizardData, "name").value = data.system.name;

  /* GetWizardFieldByReference(tempWizardData, "itsystemscreator").value = data.system.data?.itsystemscreator ?? undefined;
    GetWizardFieldByReference(tempWizardData, "concernscreator").value = data.system.data?.concernscreator ?? undefined;
    GetWizardFieldByReference(tempWizardData, "valuescreator").value = data.system.data?.valuescreator ?? undefined; */

  GetWizardFieldByReference(tempWizardData, "selectedorg").value =
    data.system.org;

  GetWizardFieldByReference(tempWizardData, "startdate").value =
    data.system.data?.startdate ?? undefined;
  GetWizardFieldByReference(tempWizardData, "industrycode").value =
    data.system.data?.industrycode ?? undefined;
  GetWizardFieldByReference(tempWizardData, "industryssb").value =
    data.system.data?.industryssb ?? undefined;
  GetWizardFieldByReference(tempWizardData, "employeescount").value =
    data.system.data?.employeescount ?? undefined;
  GetWizardFieldByReference(tempWizardData, "orgdesc").value =
    data.system.data?.orgdesc ?? undefined;
  GetWizardFieldByReference(tempWizardData, "securityrequirements").value =
    data.system.data?.securityrequirements ?? undefined;
  GetWizardFieldByReference(tempWizardData, "importantdeliveries").value =
    data.system.data?.importantdeliveries ?? undefined;
  GetWizardFieldByReference(
    tempWizardData,
    "riskassessmentparticipants"
  ).value = data.system.data?.riskassessmentparticipants ?? undefined;

  GetWizardFieldByReference(tempWizardData, "responsibleperson").value =
    data.system.data?.responsibleperson ?? undefined;

  return tempWizardData;
}

function Save(axios, auth, wizardData, system) {
  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  let values = GetWizardValuesByReferenceList(wizardData, [
    "name",
    "selectedorg",
    "itsystemscreator",
    "concernscreator",
    "valuescreator",
    "startdate",
    "industrycode",
    "industryssb",
    "employeescount",
    "orgdesc",
    "securityrequirements",
    "importantdeliveries",
    "riskassessmentparticipants",
    "responsibleperson",
  ]);

  system["name"] = values.name;
  system["alias"] = values.name;

  system["data"] = values;

  return axios.put(
    generateUrl("/api/cra/systems/") + system["_id"],
    {
      system: system,
    },
    {
      headers: { "x-access-token": auth.user["token"] },
    }
  );
}

export default {
  systemDescriptionTemplateOrgros: SystemDescriptionTemplateOrgros,
  save: Save,
};
