import React, { useEffect, useState } from "react";
import { useTranslate } from "react-polyglot";

import { Button } from "react-bootstrap";

import Field from "../Field/Field";

import "./Page.scss";

const isEqual = require("react-fast-compare");

function Page(props) {
  const t = useTranslate();
  const { data } = props;
  const [page, setPage] = useState(data);

  const handleFieldChange = (fieldkey, value) => {
    let tempdata = page;
    tempdata["fields"][fieldkey]["value"] = value;
    setPage(tempdata);
    if (props.onChange != undefined) {
      props.onChange(tempdata);
    }
  };

  useEffect(() => {
    setPage(props.data);
  }, []);

  return page != undefined ? (
    <div className={props.className}>
      {page["title"] != undefined ? (
        <h4 className="header-title"> {page["title"]} </h4>
      ) : (
        ""
      )}
      {page["text"] != undefined ? (
        <p>{page["text"] != undefined ? page["text"] : ""}</p>
      ) : (
        ""
      )}
      {page["fields"] != undefined
        ? page["fields"].map((field, fieldkey) => {
            return (
              <Field
                key={fieldkey}
                value={field["value"]}
                hidden={field["hidden"]}
                disabled={
                  props.disableEditing != true ? field["disabled"] : true
                }
                type={field["type"]}
                title={field["title"]}
                options={field["options"]}
                onChange={(value) => handleFieldChange(fieldkey, value)}
              ></Field>
            );
          })
        : ""}
      <div className="pagebuttons">
        {props.totalpages == props.activePage + 1 ? (
          props.options != undefined &&
          props.options.disableComplete != undefined &&
          props.options.disableComplete ? (
            ""
          ) : (
            <Button onClick={props.onCompleteClick} className="nextbtn">
              {props.options != undefined &&
              props.options.completeBtnText != undefined
                ? props.options.completeBtnText
                : t("analysisInstance.complete")}
            </Button>
          )
        ) : (
          ""
        )}
        {props.totalpages != props.activePage + 1 ? (
          <Button onClick={props.onNextClick} className="nextbtn">
            {props.options != undefined &&
            props.options.nextBtnText != undefined
              ? props.options.nextBtnText
              : t("analysisInstance.nextpage")}
          </Button>
        ) : (
          ""
        )}
        <Button onClick={props.onPreviousClick} className="prevbtn">
          {props.options != undefined && props.options.prevBtnText != undefined
            ? props.options.prevBtnText
            : t("analysisInstance.prevpage")}
        </Button>
      </div>
    </div>
  ) : (
    <div></div>
  );
}

export default React.memo(Page, isEqual);
