import React, { useRef } from "react";

import { useLocation, useHistory } from "react-router-dom";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";
import { Card } from "react-bootstrap";
import { Formik, Form, Field, useField, FieldHookConfig } from "formik";
import * as yup from "yup";

import ButtonComponent from "../../Shared/Button/Button";

import { useAuth } from "../../../Auth";

import "react-toastify/dist/ReactToastify.css";
import "../Login/Login.scss";

import CRALogo from "../../../Assets/Images/diri-logo.svg";

function ResetPassword() {
  const auth = useAuth();
  const search = useLocation().search;
  const history = useHistory();
  const t = useTranslate();

  const ref = useRef<any>(null);

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyAlert = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "alert-toast",
    });

  const FormSchema = yup.object().shape({
    pass: yup
      .string()
      .min(12, t("forgot_password.password_validation_1"))
      .matches(/[0-9]/, t("forgot_password.password_validation_2"))
      .matches(/[a-z]/, t("forgot_password.password_validation_3"))
      .matches(/[A-Z]/, t("forgot_password.password_validation_4"))
      .matches(/[^\w]/, t("forgot_password.password_validation_5")),
    confirm: yup
      .string()
      .oneOf([yup.ref("pass"), ""], t("forgot_password.password_must_match")),
  });

  const [loading, setLoading] = React.useState(false);

  async function resetPassword(password) {
    const token = new URLSearchParams(search).get("token");
    const email = new URLSearchParams(search).get("email");

    if (password && token && email) {
      let result = await auth.confirmPasswordReset(password, email, token);

      if (result !== undefined) {
        notifySuccess(t("forgot_password.reset_successfully"));
        history.push("/login");
      } else {
        notifyAlert(t("forgot_password.something_went_wrong"));
      }
    } else {
      notifyAlert(t("forgot_password.password_required"));
    }
    setLoading(false);
  }

  return (
    <div className="logincontainer">
      <div className="row justify-content-center">
        <div className="col-lg-5">
          <Card>
            <div className="card-header pt-4 pb-4 text-center login-header">
              <a href="/">
                <span>
                  <img className="logo" src={CRALogo} alt="" width="200" />
                </span>
              </a>
            </div>
            <Card.Body>
              <div className="welcometext">
                <h4>{t("forgot_password.set_a_new_password")}</h4>
                <p>{t("forgot_password.please_enter_new_password")}</p>
              </div>
              <div className="loginform">
                <Formik
                  innerRef={ref}
                  initialValues={{
                    pass: "",
                    confirm: "",
                  }}
                  validationSchema={FormSchema}
                  onSubmit={(values) => {
                    setLoading(true);
                    resetPassword(values.pass);
                  }}
                >
                  {({ errors }) => (
                    <Form>
                      <div
                        className={`form-group ${errors.pass ? "error" : ""}`}
                      >
                        <label>Password</label>
                        <Field
                          type="password"
                          name="pass"
                          className="form-control"
                        />
                        {errors.pass && <p>{errors.pass}</p>}
                      </div>
                      <div
                        className={`form-group ${
                          errors.confirm ? "error" : ""
                        }`}
                      >
                        <label>Password Confirm</label>
                        <Field
                          type="password"
                          name="confirm"
                          className="form-control"
                        />
                        {errors.confirm && <p>{errors.confirm}</p>}
                      </div>
                      <div className="buttonbox">
                        <ButtonComponent
                          type="submit"
                          text={t("forgot_password.set_new_password")}
                          loading={loading}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
