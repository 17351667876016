import WizardFieldManipulator from "../../WizardHelpers/WizardFieldManipulator";
import { toast } from "react-toastify";
import { generateUrl } from "../../../../config";

const GetWizardFieldByReference =
  WizardFieldManipulator.GetWizardFieldByReference;
const GetWizardValuesByReferenceList =
  WizardFieldManipulator.GetWizardValuesByReferenceList;

const notifyWarning = (text) =>
  toast(text, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "warning-toast",
  });

function valueToPercentage(value, maxvalue) {
  return ((value - 1) / (maxvalue - 1)) * 100;
}

function percentageToValue(percentage, maxvalue) {
  return Math.round((maxvalue - 1) * (percentage / 100) + 1);
}

async function fetchData(itemId, axios, auth): Promise<any> {
  let results = {
    system: undefined,
    users: [],
    organizations: [],
    tags: [],
  };

  await axios
    .all([
      axios.single({
        method: "get",
        url: generateUrl("/api/cra/systems/") + itemId,
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      }),
      axios.single({
        method: "get",
        url: generateUrl("/api/users/"),
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      }),
      axios.single({
        method: "get",
        url: generateUrl("/api/orgElements/"),
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      }),
      axios.single({
        method: "get",
        url: generateUrl("/api/cra/tags/"),
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      }),
    ])
    .then(
      axios.spread(async (...res) => {
        results.system = res[0].data;
        results.users = res[1].data;
        results.organizations = res[2].data;
        results.tags = res[3].data;
      })
    );

  return results;
}

async function SystemDescriptionTemplateSystem(t, axios, auth, itemId) {
  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  let data = await fetchData(itemId, axios, auth);

  let tempWizardData: Array<any> = [
    {
      fields: [
        {
          title: t("wizard_title.step_a"),
          type: "text_title",
          options: {
            headertag: "h2",
          },
        },
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.title_description"
          ),
        },
        {
          type: "dateselector",
          title: t("system_description.system_wizard.new_system.date_title"),
          value: "",
          reference: "date",
          disabled: true,
        },
        {
          title: t("wizard_title.input.name") + " *",
          value: "",
          type: "input",
          reference: "name",
        },
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_systemnavn"
          ),
        },
        /* {
                    "type":"input",
                    "title": t("system_description.system_wizard.new_system.system_nickname"),
                    "reference":"alias"
                },
                {
                    "type":"text_normal",
                    "title": t("system_description.system_wizard.new_system.ledetekst_system_nickname")
                }, */
        //Tag selection field is generated
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_system_tags"
          ),
        },
        {
          type: "select",
          title: t(
            "system_description.system_wizard.new_system.system_importance"
          ),
          reference: "importance",
          options: {
            typeLoad: "Manual",
            choosetext: t(
              "system_description.system_wizard.importance.choose_text"
            ),
            choices: [
              {
                label: t(
                  "system_description.system_wizard.importance.extremely_important"
                ),
                value: "extremely",
              },
              {
                label: t(
                  "system_description.system_wizard.importance.very_important"
                ),
                value: "very",
              },
              {
                label: t(
                  "system_description.system_wizard.importance.moderately_important"
                ),
                value: "moderately",
              },
              {
                label: t(
                  "system_description.system_wizard.importance.slightly_important"
                ),
                value: "slightly",
              },
              {
                label: t(
                  "system_description.system_wizard.importance.unimportant_important"
                ),
                value: "unimportant",
              },
            ],
          },
        },
        {
          title: t("wizard_title.input.description"),
          value: "",
          type: "textarea",
          reference: "description",
        },
        {
          type: "text_normal",
          title: t("system_description.system_wizard.new_system.purpose"),
        },
        {
          type: "textarea",
          title: t("system_description.system_wizard.new_system.limitations"),
          reference: "limitationsdescription",
        },
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_limitations"
          ),
        },
      ],
    },
    {
      fields: [
        {
          type: "text_title",
          title: t(
            "system_description.system_wizard.new_system.ownership_title"
          ),
          options: {
            headertag: "h2",
          },
        },
        //Ownerorg-field is generated
        /* {
                    title: t("wizard_title.input.system_owner_orgUnit"),
                    value: "",
                    type: "input",
                    reference:"ownerorg"
                }, */
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_section"
          ),
        },
        //ownersection-field is generated
        /* {
                    title: t("wizard_title.input.system_owner_section"),
                    value: "",
                    type: "input",
                    reference:"ownersection"
                }, */
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_subsection"
          ),
        },
        // Owner Person Field is generated (4)
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_owner"
          ),
        },
        // Responsible Person Field is generated (6)
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_responsible"
          ),
        },
        {
          type: "textarea",
          title: t("system_description.system_wizard.new_system.provider"),
          reference: "providerinfo",
        },
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_provider"
          ),
        },
      ],
    },
    {
      fields: [
        {
          type: "text_title",
          title: t(
            "system_description.system_wizard.new_system.system_properties"
          ),
          options: {
            headertag: "h2",
          },
        },
        {
          title: t("wizard_title.select.optional_status"),
          value: "",
          type: "select",
          reference: "operationstatus",
          options: {
            typeLoad: "Manual",
            choosetext: t("wizard_title.select.choose_text"),
            choices: [
              {
                label: t("system_description.system_wizard.status.unknown"),
                value: "Ukjent",
              },
              {
                label: t(
                  "system_description.system_wizard.status.indevelopment"
                ),
                value: "Utvikling",
              },
              {
                label: t("system_description.system_wizard.status.test"),
                value: "Test",
              },
              {
                label: t("system_description.system_wizard.status.pilot"),
                value: "Pilot",
              },
              {
                label: t("system_description.system_wizard.status.production"),
                value: "Produksjon",
              },
              {
                label: t("system_description.system_wizard.status.storage"),
                value: "Under oppbevaring",
              },
              {
                label: t("system_description.system_wizard.status.phasingout"),
                value: "Under utfasing",
              },
              {
                label: t("system_description.system_wizard.status.phasedout"),
                value: "faset ut",
              },
              {
                label: t(
                  "system_description.system_wizard.status.discontinued"
                ),
                value: "fjernet",
              },
            ],
          },
        },
        {
          type: "text_normal",
          title: t("system_description.system_wizard.status.ledetekst"),
        },
        {
          type: "divider",
        },
        {
          title: t("wizard_title.input.numberofusers"),
          value: "",
          reference: "numberofusers",
          type: "number",
        },
        {
          type: "number",
          title: t("wizard_title.input.numberofusersfull"),
          reference: "maxnumberofusers",
        },
        {
          type: "text_normal",
          title: t("system_description.system_wizard.n_users.ledetekst"),
        },
        {
          type: "divider",
        },
        {
          type: "selectmulti",
          title: t("system_description.system_wizard.users.title"),
          options: {
            typeLoad: "Manual",
            choices: [
              {
                label: t("system_description.system_wizard.users.employees"),
                value: "Ansatte",
              },
              {
                label: t("system_description.system_wizard.users.visitors"),
                value: "Besøkende",
              },
              {
                label: t("system_description.system_wizard.users.volunteers"),
                value: "Frivillige",
              },
              {
                label: t("system_description.system_wizard.users.consultants"),
                value: "Konsulenter",
              },
              {
                label: t("system_description.system_wizard.users.customers"),
                value: "Kunder",
              },
              {
                label: t("system_description.system_wizard.users.suppliers"),
                value: "Leverandører",
              },
              {
                label: t("system_description.system_wizard.users.students"),
                value: "Studenter",
              },
              {
                label: t("system_description.system_wizard.users.public"),
                value: "Systemet er offentlig",
              },
            ],
          },
          reference: "userscategory",
        },
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_hvem"
          ),
        },
        {
          type: "selectmulti",
          title: t("system_description.system_wizard.access.title"),
          options: {
            typeLoad: "Manual",
            choices: [
              {
                label: t("system_description.system_wizard.access.internet"),
                value: "Via internet",
              },
              {
                label: t("system_description.system_wizard.access.remote"),
                value: "Fjerntilgang via VPN eller lignende",
              },
              {
                label: t("system_description.system_wizard.access.local"),
                value: "Lokal tilgang (internt)",
              },
            ],
          },
          reference: "systemaccessmethod",
        },
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_access"
          ),
        },
        {
          title: t("wizard_title.select.hosting"),
          value: "",
          type: "select",
          reference: "category",
          options: {
            choosetext: t("wizard_title.select.category_choosetext"),
            choices: [
              {
                label: t("system_description.system_wizard.hosting.internally"),
                value: "Internt",
              },
              {
                label: t("system_description.system_wizard.hosting.externally"),
                value: "Externt",
              },
              {
                label: t("system_description.system_wizard.hosting.cloud"),
                value: "Skybasert",
              },
              {
                label: t("system_description.system_wizard.hosting.hybrid"),
                value: "Hybrid",
              },
            ],
          },
        },
        {
          type: "text_normal",
          title: t("system_description.system_wizard.hosting.ledetekst"),
        },
        {
          type: "divider",
        },
        {
          type: "bool",
          title: t("system_description.system_wizard.availability.periods"),
          reference: "specialavailabilityperiod",
        },
        {
          type: "text_normal",
          title: t("system_description.system_wizard.availability.ledetekst"),
        },
        // System Availability Field is generated (15)
        {
          type: "textarea",
          title: t("system_description.system_wizard.availability.reasoning"),
          reference: "availabilityreasoning",
        },
        {
          type: "divider",
        },
        {
          type: "select",
          title: t("system_description.system_wizard.OS.operatings"),
          options: {
            typeLoad: "Manual",
            choices: [
              {
                label: t(
                  "system_description.system_wizard.operatingsystem.select"
                ),
                value: "",
              },
              {
                label: "Windows",
                value: "Windows",
              },
              {
                label: "Linux",
                value: "Linux",
              },
              {
                label: "Unix",
                value: "Unix",
              },
              {
                label: t("system_description.system_wizard.OS.other"),
                value: "Annet",
              },
            ],
          },
          reference: "operatingsystem",
        },
        {
          type: "input",
          title: t("system_description.system_wizard.OS.version"),
          reference: "operatingsystemversion",
        },
        {
          type: "text_normal",
          title: t("system_description.system_wizard.OS.ledetekst"),
        },
        {
          type: "divider",
        },
        {
          type: "input",
          title: t("system_description.system_wizard.OS.doc"),
          reference: "systemdocumentationlink",
        },
        {
          type: "input",
          title: t("system_description.system_wizard.OS.login"),
          reference: "systemloginlink",
        },
        /*{
                    title: t("wizard_title.checkbox.publiclaw"),
                    value: false,
                    type: "checkbox",
                },*/
      ],
    },
    {
      fields: [
        {
          type: "text_title",
          title: t(
            "system_description.system_wizard.new_system.system_participants"
          ),
          options: {
            headertag: "h2",
          },
        },
      ],
    },
  ];

  if (settings["customfieldsSystem"].length > 0) {
    tempWizardData.push({
      fields: [
        ...settings["customfieldsSystem"].map((field, key) => {
          return {
            title: field.key,
            value: "",
            type:
              field.type == "string"
                ? "input"
                : field.type == "dropdown"
                ? "select"
                : "",
            options:
              field.type == "dropdown"
                ? {
                    choosetext: " Gjør et valg",
                    choices: field.options.map((option, key) => {
                      return { label: option, value: key };
                    }),
                  }
                : undefined,
          };
        }),
      ],
    });
  }

  tempWizardData[1]["fields"].splice(1, 0, {
    id: 6,
    title: t("wizard_title.input.system_owner_orgUnit"),
    value: "",
    type: "autocompletewithid",
    reference: "ownerorg",
    options: {
      list: data.organizations.map((i, key) => {
        return { id: key, value: i["name"], label: i["name"] };
      }),
      data: data.organizations,
      minlength: 2,
      noresults: "no matches",
    },
  });

  tempWizardData[1]["fields"].splice(3, 0, {
    id: 7,
    title: t("wizard_title.input.system_owner_section"),
    value: "",
    type: "autocompletewithid",
    reference: "ownersection",
    options: {
      list: data.organizations.map((i, key) => {
        return { id: key, value: i["name"], label: i["name"] };
      }),
      data: data.organizations,
      minlength: 2,
      noresults: "no matches",
    },
  });

  tempWizardData[1]["fields"].splice(5, 0, {
    id: 3,
    title: t("wizard_title.input.system_owner"),
    value: "",
    type: "autocompletewithid",
    reference: "ownerperson",
    options: {
      list: data.users.map((i, key) => {
        return { id: key, value: i["_id"], label: i["name"] };
      }),
      data: data.users,
      minlength: 2,
      noresults: "no matches",
    },
  });

  tempWizardData[3]["fields"].splice(1, 0, {
    id: 5,
    title: t("wizard_title.input.riskassessmentparticipants"),
    value: [],
    type: "personselector",
    reference: "riskassessmentparticipants",
    options: {
      users: data.users,
      organizations: data.organizations,
      minlength: 2,
      noresults: t("wizard_title.input.riskassessmentparticipants_noresult"),
      roleTitle: t("wizard_title.input.riskassessmentparticipants_role"),
      organizationTitle: t(
        "wizard_title.input.riskassessmentparticipants_organization"
      ),

      deleteButtonText: t(
        "wizard_title.input.riskassessmentparticipants_deletebtn"
      ),
      addButtonText: t("wizard_title.input.riskassessmentparticipants_addbtn"),
    },
  });

  tempWizardData[1]["fields"].splice(7, 0, {
    id: 4,
    title: t("wizard_title.input.responsible"),
    value: "",
    type: "autocompletewithid",
    reference: "responsible",
    options: {
      list: data.users.map((i, key) => {
        return { id: key, value: i["_id"], label: i["name"] };
      }),
      data: data.users,
      minlength: 2,
      noresults: "no matches",
    },
  });

  tempWizardData[0]["fields"].splice(5, 0, {
    id: 8,
    title: t("wizard_title.input.tags"),
    value: [],
    type: "tagsfield",
    reference: "tags",
    options: {
      existingAliasData: "",
      objectType: "system",
      org: "",
    },
  });

  tempWizardData[2]["fields"].splice(15, 0, {
    id: 3,
    title: t("wizard_title.input.system_availability"),
    value: "",
    type: "select",
    reference: "availability",
    options: {
      choices: [
        settings["systemvalueTNum"] > 0
          ? { label: settings["systemvalueTLevel1"], value: 1 }
          : undefined,
        settings["systemvalueTNum"] > 1
          ? { label: settings["systemvalueTLevel2"], value: 2 }
          : undefined,
        settings["systemvalueTNum"] > 2
          ? { label: settings["systemvalueTLevel3"], value: 3 }
          : undefined,
        settings["systemvalueTNum"] > 3
          ? { label: settings["systemvalueTLevel4"], value: 4 }
          : undefined,
        settings["systemvalueTNum"] > 4
          ? { label: settings["systemvalueTLevel5"], value: 5 }
          : undefined,
        settings["systemvalueTNum"] > 5
          ? { label: settings["systemvalueTLevel6"], value: 6 }
          : undefined,
      ].filter((i) => i != undefined),
    },
  });

  GetWizardFieldByReference(tempWizardData, "name").value = data.system.name;
  GetWizardFieldByReference(tempWizardData, "date").value = data.system.date;
  /* GetWizardFieldByReference(tempWizardData, "alias").value = data.system.alias; */
  GetWizardFieldByReference(tempWizardData, "description").value =
    data.system.description;
  GetWizardFieldByReference(tempWizardData, "limitationsdescription").value =
    data.system.limitationsdescription;
  GetWizardFieldByReference(tempWizardData, "ownerorg").value =
    data.system.ownerOrg;
  GetWizardFieldByReference(tempWizardData, "ownersection").value =
    data.system.ownerSection;
  GetWizardFieldByReference(tempWizardData, "ownerperson").value =
    data.system.ownerPerson;
  GetWizardFieldByReference(tempWizardData, "responsible").value =
    data.system.responsiblePerson;
  GetWizardFieldByReference(tempWizardData, "providerinfo").value =
    data.system.providerinfo;
  GetWizardFieldByReference(tempWizardData, "operationstatus").value = {
    value:
      data.system.operationStatus != undefined &&
      data.system.operationStatus != null
        ? data.system.operationStatus.toString()
        : "",
    label: "",
  };
  GetWizardFieldByReference(tempWizardData, "systemaccessmethod").value =
    data.system.systemaccessmethod.map((i) => {
      return {
        value: i.value,
        label: GetWizardFieldByReference(
          tempWizardData,
          "systemaccessmethod"
        ).options.choices.find((idx) => idx.value == i.value).label,
      };
    });
  GetWizardFieldByReference(tempWizardData, "numberofusers").value =
    data.system.numberofusers;
  GetWizardFieldByReference(tempWizardData, "maxnumberofusers").value =
    data.system.maxnumberofusers;
  GetWizardFieldByReference(tempWizardData, "userscategory").value =
    data.system.userscategory.map((i) => {
      return {
        value: i.value,
        label: GetWizardFieldByReference(
          tempWizardData,
          "userscategory"
        ).options.choices.find((idx) => idx.value == i.value).label,
      };
    });
  GetWizardFieldByReference(tempWizardData, "category").value = {
    value: data.system.category != null ? data.system.category : "",
    label: "",
  };
  GetWizardFieldByReference(tempWizardData, "specialavailabilityperiod").value =
    data.system.specialavailabilityperiod;
  GetWizardFieldByReference(tempWizardData, "availability").value = {
    value: percentageToValue(
      data.system.availability,
      settings["systemvalueTNum"]
    ),
    label: "",
  };
  GetWizardFieldByReference(tempWizardData, "availabilityreasoning").value =
    data.system.availabilityreasoning;
  GetWizardFieldByReference(tempWizardData, "operatingsystem").value = {
    value:
      data.system.operatingsystem != null ? data.system.operatingsystem : "",
    label: "",
  };
  GetWizardFieldByReference(tempWizardData, "operatingsystemversion").value =
    data.system.operatingsystemversion;
  GetWizardFieldByReference(tempWizardData, "systemdocumentationlink").value =
    decodeURIComponent(data.system.systemdocumentationlink);
  GetWizardFieldByReference(tempWizardData, "systemloginlink").value =
    decodeURIComponent(data.system.systemloginlink);
  GetWizardFieldByReference(
    tempWizardData,
    "riskassessmentparticipants"
  ).value = data.system.riskassessmentparticipants;
  GetWizardFieldByReference(tempWizardData, "importance").value =
    data.system.importance;

  GetWizardFieldByReference(tempWizardData, "tags").value = data.system.tags;
  GetWizardFieldByReference(tempWizardData, "tags").options.existingAliasData =
    data.system.alias;
  GetWizardFieldByReference(tempWizardData, "tags").options.org =
    auth.user["userdata"]["unit"][0];

  settings["customfieldsSystem"].map((field, key) => {
    if (data.system.customfields != undefined) {
      if (
        data.system.customfields.find((item) => item["id"] == field.key) !=
        undefined
      ) {
        (
          tempWizardData[4]["fields"].find(
            (item) => item["title"] == field.key
          ) as any
        )["value"] = data.system.customfields.find(
          (item) => item["id"] == field.key
        )["value"];
      }
    }
    return null;
  });

  return tempWizardData;
}

function Save(t, axios, auth, wizardData, system) {
  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  let values = GetWizardValuesByReferenceList(wizardData, [
    "name",
    //"alias",
    "description",
    "limitationsdescription",
    "ownerorg",
    "ownersection",
    "ownerperson",
    "responsible",
    "providerinfo",
    "operationstatus",
    "numberofusers",
    "maxnumberofusers",
    "userscategory",
    "systemaccessmethod",
    "category",
    "specialavailabilityperiod",
    "availability",
    "availabilityreasoning",
    "operatingsystem",
    "operatingsystemversion",
    "systemdocumentationlink",
    "systemloginlink",
    "riskassessmentparticipants",
    "tags",
    "importance",
  ]);

  if (values.name == undefined || values.name == "") {
    notifyWarning(
      t("diri.regassistant.types.other.toast.warning_missing_system_name")
    );
    return null;
  }

  system["name"] = values.name;
  system["alias"] = undefined;
  system["description"] = values.description;
  system["limitationsdescription"] = values.limitationsdescription;
  system["tags"] = values.tags;
  system["importance"] = values.importance;

  system["ownerOrg"] = values.ownerorg;
  system["ownerSection"] = values.ownersection;
  system["ownerPerson"] = values.ownerperson;
  system["responsiblePerson"] = values.responsible;
  system["providerinfo"] = values.providerinfo;

  system["operationStatus"] = values.operationstatus;
  system["numberofusers"] = values.numberofusers;
  system["maxnumberofusers"] = values.maxnumberofusers;
  system["userscategory"] = values.userscategory;
  system["systemaccessmethod"] = values.systemaccessmethod;
  system["category"] = values.category;
  system["specialavailabilityperiod"] = values.specialavailabilityperiod;
  system["availability"] = valueToPercentage(
    values.availability,
    settings["systemvalueTNum"]
  );
  system["availabilityreasoning"] = values.availabilityreasoning;
  system["operatingsystem"] = values.operatingsystem;
  system["operatingsystemversion"] = values.operatingsystemversion;
  system["systemdocumentationlink"] = encodeURIComponent(
    values.systemdocumentationlink
  );
  system["systemloginlink"] = encodeURIComponent(values.systemloginlink);

  system["riskassessmentparticipants"] = values.riskassessmentparticipants;

  if (settings["customfieldsSystem"].length > 0) {
    wizardData[4]["fields"].map((field, key) => {
      if (system["customfields"] != undefined) {
        if (
          system["customfields"].find((item) => item["id"] == field["title"]) ==
          undefined
        ) {
          system["customfields"].push({
            id: field["title"],
            value: field["value"],
          });
        } else {
          system["customfields"].find((item) => item["id"] == field["title"])[
            "value"
          ] = field["value"];
        }
      }
      return null;
    });
  }

  return axios.put(
    generateUrl("/api/cra/systems/") + system["_id"],
    {
      system: system,
    },
    {
      headers: { "x-access-token": auth.user["token"] },
    }
  );
}

export default {
  systemDescriptionTemplateSystem: SystemDescriptionTemplateSystem,
  save: Save,
};
