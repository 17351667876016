import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import { useAxios } from "../../../../../AxiosHandler";

import { useTranslate } from 'react-polyglot';
import './AssignedTasks.scss';
import { Card } from 'react-bootstrap';
import { useAuth } from "../../../../../Auth";
import PageItems from '../../../../Shared/PageItems/PageItems';
import { generateUrl } from '../../../../../config';

const AssignedTasks = (props) => {
    const [loading, setLoading] = useState(true);
    const [updateTable, setUpdateTable] = useState(0);

    const t = useTranslate();   
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();
    const history = useHistory();

    const renderTaskItem = (task) => {
        return(
            <div onClick={evt => props.onClick != undefined ? props.onClick(task) : history.push("/tasks")}>
                <div className="taskitemtextarea">
                    <h3 className="taskitemtitle">{task.name}</h3>
                    <p className="taskitemlineone">{t("tasks.assignedto")}: {task.responsibleName}</p>
                    <p className="taskitemlinetwo">{t("tasks.modal_task_create.fields.statuses." + task.status)}</p>
                    <p className="taskitemlinerightone">{t("tasks.modal_task_create.fields.priorities." + task.priority)} {t("tasks.modal_task_create.fields.priority").toLowerCase()}</p>
                    <p className={"taskitemlinerighttwo " + ((new Date(task.duetime) < new Date()) ? "overdue" : "")}>{t("tasks.modal_task_create.fields.duetime")}: {task.duetimeReadable}</p>
                </div>
            </div>
        );
    }
    
    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        setUpdateTable(props.updateTable);
    }, [props.updateTable]);

    return (
        <Card className="systemslistcard">
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                <h4 className="header-title">{t("dashboard.cards.assignedtasks_name")}</h4>
                <p>{t("dashboard.cards.assignedtasks_description")}</p>
                <hr/>
                <PageItems
                    dataUrl={generateUrl("/api/cra/tasks/created/getAllPaged/") + "notclosed"}
                    updateTable={updateTable}
                    renderItem={renderTaskItem}
                    hidePagination={false}
                    defaultSortField="duetime"
                    defaultSortDirection="asc"
                ></PageItems>
            </Card.Body>
        </Card>
    );
}

export default AssignedTasks;
