import React, { useEffect, useState } from 'react';
import {
    useHistory
  } from "react-router-dom";
import { useAxios } from "../../../../../AxiosHandler";
import { Card, InputGroup, Form, Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { useTranslate } from 'react-polyglot';

import './KITDistribution.scss';
import { useAuth } from "../../../../../Auth";
import Field from '../../../../Shared/Wizard/Field/Field';
import { useDynamicLanguage } from '../../../../../DynamicLanguageProvider';

const KITDistribution = (props) => {
    const t = useTranslate();   
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();
    const history = useHistory();
    const td = useDynamicLanguage().td;

    const [selectedKit, setSelectedKit] = useState("k");
    const [dataOfChart, setDataOfChart] = useState([{}]);
    const [dataOfLabels, setDataOfLabels] = useState([]);

    const [kitSystems, setKitSystems] = useState([]);
    const [selectedSystems, setSelectedSystems] = useState<Array<any>>([]);

    const [selectedOption, setSelectedOption] = useState("system");

    const [loading, setLoading] = useState(true);

    let selectTitle = t("dashboard.cards.drop_down_select_title");
    let selectOptions = [
        {
            "label": t("dashboard.cards.choice_system"),
            "value": "system"
        },
        {
            "label": t("dashboard.cards.choice_orgros"),
            "value": "orgros"
        },
        {
            "label": t("dashboard.cards.choice_other"),
            "value": "other"
        },
    ]

    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    function handleSelectInput(value){
        setLoading(true);
        setSelectedOption(value);
    }

    function handleSelectedKitCategory(selectedKit){
        
        setSelectedKit(selectedKit);
        fetchLabels(selectedKit);
    }

    function fetchLabels(kit){
        switch(kit){
            case "k" :
                let numbersOfK: number = settings["systemvalueKNum"];
                let levelsOfK = [] as any;

                if(numbersOfK != 0){
                    for(let i=0; i<=numbersOfK; i++){
                        levelsOfK.push(settings["systemvalueKLevel" + i])
                    }
                    setDataOfLabels(levelsOfK.filter(i=> {return i!= null}));
                }
                break;

            case "i" :
                let numbersOfI: number = settings["systemvalueINum"];
                let levelsOfI = [] as any;

                if(numbersOfI != 0){
                    for(let i=0; i<=numbersOfI; i++){
                        levelsOfI.push(settings["systemvalueILevel" + i])
                    }
                    setDataOfLabels(levelsOfI.filter(i=> {return i!= null}));
                }
                break;

            case "t" :
                let numbersOfT: number = settings["systemvalueTNum"];
                let levelsOfT = [] as any;

                if(numbersOfT != 0){
                    for(let i=0; i<=numbersOfT; i++){
                        levelsOfT.push(settings["systemvalueTLevel" + i])
                    }
                    setDataOfLabels(levelsOfT.filter(i=> {return i!= null}));
                };
                break;
        }
    }

    function fetchSystemsByKit(value) {
        setSelectedSystems(kitSystems[value]);
    }

    function valueToPercentage(value, maxvalue) {
        return ((value - 1) / (maxvalue - 1) * 100);
    }

    function percentageToValue(percentage, maxvalue) {
        return Math.round(((maxvalue - 1) * (percentage / 100)) + 1);
    }

    async function processData(res, selectedKit, type) {
        let systemValues = res[0].data;

        let selectOptionsValues = selectOptions.map(option => option.value);
        if (type == "system"){
            res[1].data = res[1].data.filter(res => res.systemType.toString().toLowerCase().includes(type) || !selectOptionsValues.includes(res.systemType.toString().toLowerCase()));
        } else {
            res[1].data = res[1].data.filter(res => res.systemType.toString().toLowerCase() == type);
        }
        let allSystems = res[1].data;

        let allResults:any = dataOfLabels.map(i => 0);

        let tempKitSystems:any = [];

        allSystems.forEach(system => {
            let systemKit = Math.max(...(systemValues.filter(item => item["system"] == system["_id"]).map(i=> i[selectedKit])))
            if (systemKit == null) systemKit = 0;

            let value = percentageToValue(systemKit, dataOfLabels.length);
            if (value != Infinity && value != -Infinity) {
                value = value - 1;
                allResults[value] += 1;
                if (tempKitSystems[value] == undefined) tempKitSystems[value] = [];
                tempKitSystems[value].push(system);
            }
        });

        setKitSystems(tempKitSystems);

        setDataOfChart(allResults);
    }

    const data = {
        labels: dataOfLabels.map(label => td(label)),
        datasets: [
            {
                backgroundColor: [
                    "rgb(91, 130, 102, 1)",
                    "rgb(250, 205, 117, 1)",
                    "rgb(216, 119, 90, 1)",
                    "rgb(153, 55, 66, 1)",
                ],
                data:dataOfChart
            }
        ]
    }
    
    useEffect(() => {
        fetchLabels("k");
        setLoading(false);
    }, []);

    useEffect(() => {
        Promise.all([
            props.requestData("systemvalues"),
            props.requestData("systems")
        ]).then(async (data) => {
            await processData(data, selectedKit, selectedOption);
            setLoading(false);
        });
    }, [dataOfLabels, selectedOption]);

    return (
        <Card>
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                 <h4 className="header-title">{t("dashboard.cards.kitdistribution_name")}</h4>
                <p>{t("dashboard.cards.kitdistribution_description")}</p>
                <Field
                    type="select"
                    title={ selectTitle }
                    onChange={ value => handleSelectInput(value.value) }
                    options={{
                        "choices": selectOptions
                    }}
                > 
                </Field>
                <hr />
                <div>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>{t("dashboard.cards.kit")}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" 
                         onChange={evt => handleSelectedKitCategory((evt.target as HTMLSelectElement).selectedOptions[0].value)} 
                         value={selectedKit}>
                                <option value="k">{t("system_asset.system_confidential")}</option>
                                <option value="i">{t("system_asset.system_integrity")}</option>
                                <option value="t">{t("system_asset.system_availability")}</option>
                        </Form.Control>
                    </InputGroup>
                </div>
                <div>
                    <Bar
                        data={data}
                        options={{
                            events: ["click"],
                            onClick: (evt, data, test) => {
                                if (data != undefined && data[0] != undefined) {
                                    fetchSystemsByKit(data[0]._index);
                                }
                            },
                            title: {
                                display:true,
                                fontSize:20
                            },
                            legend: {
                                display: false,
                                position:'right'
                            },
                            scales: {
                                yAxes: [{
                                    stacked: true,
                                    ticks: {
                                        suggestedMin: 0,
                                        stepSize: 1
                                    },
                                }]
                            }
                        }}
                    />
                </div>
                {selectedSystems != undefined && selectedSystems.length > 0 ?
                    <div className="chartDetails">
                        <div>
                            <h4 className="header-title">{t("dashboard.cards.kitdistribution.details.title")}</h4>
                            <Table striped hover className="chartdetailstable">
                                <thead>
                                    <tr>
                                        <th>{t("dashboard.cards.kitdistribution.details.system")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        selectedSystems.map((element, key) => {
                                            return(
                                                <tr key={key} style={{cursor: "pointer"}} onClick={() => {
                                                    history.push("/system/" + element._id);
                                                    window.scrollTo(0, 0);
                                                }}>
                                                    <td>{ element["nameReadable"] }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                : null}
            </Card.Body>
        </Card>
    );
}

export default KITDistribution;
