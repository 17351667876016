import React, { useState, useEffect } from 'react';
import RiskOverview from './RiskOverview/RiskOverview';
import SystemsList from './SystemsList/SystemsList';
import TreatmentBurndown from './TreatmentBurndown/TreatmentBurndown';
import TreatmentList from './TreatmentList/TreatmentList';
import TopVulnerabilities from './TopVulnerabilities/TopVulnerabilities';
import MyTasks from './MyTasks/MyTasks';
import ClosedTasks from './ClosedTasks/ClosedTasks';
import AssignedTasks from './AssignedTasks/AssignedTasks';
import TasksBurndown from './TasksBurndown/TasksBurndown';
import TopConsequences from './TopConsequences/TopConsequences';
import KITDistribution from './KITDistribution/KITDistribution';

import SensitivepoDistribution from './SensitivepoDistribution/SensitivepoDistribution';
import SystemStatuses from './SystemStatuses/SystemStatuses';
import PublicLawOverview from './PublicLawOverview/PublicLawOverview';
import TopVulnerabilitiesCauses from './TopVulnerabilitiesCatetory/TopVulnerabilitiesCauses';
import TreatmentStatus from './TreatmentStatus/TreatmentStatus';
import MissingData from './MissingData/MissingData';
import SystemAssetsOverview from './SystemAssetsOverview/SystemAssetsOverview';
import RiskMatrixCard from './RiskMatrixCard/RiskMatrixCard';
import SystemStatusesBarChart from './SystemStatusesBarChart/SystemStatusesBarChart';
import Importance from './Importance/Importance';

const DashboardCard = (props) => {
    const [updateTable, setUpdateTable] = useState(0);

    useEffect(() => {
        setUpdateTable(props.updateTable);
    }, [props.updateTable]);

    switch (props.cardType) {
        case "riskoverview":
            return(
                <div>
                    <RiskOverview
                        requestData={props.requestData}
                    ></RiskOverview>
                </div>
            );
        case "systemslist":
            return(
                <div>
                    <SystemsList
                        requestData={props.requestData}
                    ></SystemsList>
                </div>
            );
        case "treatmentburndown":
            return(
                <div>
                    <TreatmentBurndown
                        requestData={props.requestData}
                    ></TreatmentBurndown>
                </div>
            );
        case "treatmentlist":
            return(
                <div>
                    <TreatmentList></TreatmentList>
                </div>
            );
        case "treatmentstatus":
            return(
                <div>
                    <TreatmentStatus
                        requestData={props.requestData}
                    ></TreatmentStatus>
                </div>
            );
        case "topvulnerabilities":
            return(
                <div>
                    <TopVulnerabilities
                        requestData={props.requestData}
                    ></TopVulnerabilities>
                </div>
            );
        case "mytasks":
            return(
                <div>
                    <MyTasks
                        updateTable={updateTable}
                        onClick={props.onClick}
                    ></MyTasks>
                </div>
            );
        case "assignedtasks":
            return(
                <div>
                    <AssignedTasks
                        updateTable={updateTable}
                        onClick={props.onClick}
                    ></AssignedTasks>
                </div>
            );
        case "closedtasks":
            return(
                <div>
                    <ClosedTasks
                        updateTable={updateTable}
                        onClick={props.onClick}
                    ></ClosedTasks>
                </div>
            );
        case "tasksburndown":
            return(
                <div>
                    <TasksBurndown updateTable={updateTable}></TasksBurndown>
                </div>
            );
        case "topconsequences":
            return(
                <div>
                    <TopConsequences
                        requestData={props.requestData}
                    ></TopConsequences>
                </div>
            );
        case "kitdistribution":
        return(
            <div>
                <KITDistribution
                    requestData={props.requestData}
                ></KITDistribution>
            </div>
        );
        case "sensitivepodistribution":
            return(
                <div>
                    <SensitivepoDistribution
                        requestData={props.requestData}
                    ></SensitivepoDistribution>
                </div>
            );
        case "systemstatuses":
            return(
                <div>
                    <SystemStatusesBarChart
                        requestData={props.requestData}
                    ></SystemStatusesBarChart>
                </div>
            );
        case "publiclawoverview":
            return(
                <div>
                    <PublicLawOverview
                        requestData={props.requestData}
                    ></PublicLawOverview>
                </div>
            );
        case "topvulnerabilities_causes":
            return(
                <div>
                    <TopVulnerabilitiesCauses
                        requestData={props.requestData}
                    ></TopVulnerabilitiesCauses>
                </div>
            );    
        case "topvulnerabilities_consequence":
            return(
                <div>
                    <TopVulnerabilitiesCauses
                        requestData={props.requestData}
                    ></TopVulnerabilitiesCauses>
                </div>
            );  
        case "missingdata":
            return(
                <div>
                    <MissingData
                        requestData={props.requestData}
                    ></MissingData>
                </div>
            );     
        case "systemassetsoverview":
            return(
                <div>
                    <SystemAssetsOverview
                        requestData={props.requestData}
                    ></SystemAssetsOverview>
                </div>
            );     
        case "riskmatrixcard":
            return(
                <div>
                    <RiskMatrixCard
                        requestData={props.requestData}
                    ></RiskMatrixCard>
                </div>
            );
        case "systemstatusesbarchart":
            return(
                <div>
                    <SystemStatusesBarChart
                        requestData={props.requestData}
                    ></SystemStatusesBarChart>
                </div>
            );     
        case "importance":
            return(
                <div>
                    <Importance
                        requestData={props.requestData}
                    ></Importance>
                </div>
            )     
    }
    return(null);
}

export default DashboardCard;
