import * as React from "react";
import { useState } from "react";

import { Modal, Button } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import { useAxios } from "../../../../AxiosHandler";
import { useAuth } from "../../../../Auth";

import PageTable from "../../PageTable/PageTable";
import {
  treatmentTypes,
  treatmentClasses,
} from "../../../../Assets/Constants/Constants";
import Field from "../../../Shared/Wizard/Field/Field";

import { generateUrl } from "../../../../config";

import "react-toastify/dist/ReactToastify.css";

import "./TreatmentSelectorModal.scss";

function TreatmentSelectorModal(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();

  const [selectedTreatment, setSelectedTreatment] = useState(false);
  const [selectedTreatments, setSelectedTreatments] = useState<any>([]);
  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  let userdata = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
    userdata = JSON.parse(localStorageUser)["userdata"];
  }

  const treatmentName = t("treatment.name");
  const treatmentStatus = t("treatment.status");
  const treatmentBowtie = t("treatment.bowtie");
  const treatmentResponsible = t("treatment.responsible");
  const treatmentType = t("treatment.type");
  const treatmentClass = t("treatment.class");

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const handleAddTreatmentConnection = (treatment, isLast) => {
    if (props.copy == true) {
      if (props.parentItemType == "cause") {
        axios
          .post(
            generateUrl("/api/cra/treatments/"),
            {
              name: treatment["name"],
              description: treatment["description"],
              type: treatment["type"],
              class: treatment["class"],
              customfields: treatment["customfields"],
              cause: [props.parentItemId],
              consequence: [],
              occurence:
                props.occurenceId != undefined
                  ? [props.occurenceId]
                  : [...treatment["occurence"]],
              responsible: treatment["responsible"],
              system:
                props.systemid != undefined
                  ? props.systemid
                  : treatment["system"],
              status: "open",
              cost: treatment["cost"],
              onetimecost: treatment["onetimecost"],
              documentationlink: treatment["documentationlink"],
              probabilityreduction: treatment["probabilityreduction"],
              duetime: treatment["duetime"],
              copyof: treatment,
              tags: treatment["tags"],
              global: treatment["global"],
              creator: userdata["_id"],
            },
            {
              headers: { "x-access-token": auth.user["token"] },
            }
          )
          .then((res) => {
            if (props.onSave != undefined) props.onSave();

            if (isLast) {
              notifySuccess(t("treatments.toast.treatment_copied"));
            }
            if (props.onChange != undefined) {
              props.onChange(res.data._id);
            }
            setSelectedTreatment(false);
          });
      } else if (props.parentItemType == "consequence") {
        axios
          .post(
            generateUrl("/api/cra/treatments/"),
            {
              name: treatment["name"],
              description: treatment["description"],
              type: treatment["type"],
              class: treatment["class"],
              customfields: treatment["customfields"],
              cause: [],
              consequence: [props.parentItemId],
              occurence:
                props.occurenceId != undefined
                  ? [props.occurenceId]
                  : [...treatment["occurence"]],
              responsible: treatment["responsible"],
              system:
                props.systemid != undefined
                  ? props.systemid
                  : treatment["system"],
              status: "open",
              cost: treatment["cost"],
              onetimecost: treatment["onetimecost"],
              documentationlink: treatment["documentationlink"],
              probabilityreduction: treatment["probabilityreduction"],
              duetime: treatment["duetime"],
              copyof: treatment,
              tags: treatment["tags"],
              global: treatment["global"],
              creator: userdata["_id"],
            },
            {
              headers: { "x-access-token": auth.user["token"] },
            }
          )
          .then((res) => {
            if (props.onSave != undefined) props.onSave();
            if (isLast) {
              notifySuccess(t("treatments.toast.treatment_copied"));
            }

            if (props.onChange != undefined) {
              props.onChange(res.data._id);
            }
            setSelectedTreatment(false);
          });
      }
    } else {
      if (props.parentItemType == "cause") {
        axios
          .put(
            generateUrl("/api/cra/treatments/") + treatment["_id"],
            {
              name: treatment["name"],
              cause: [...treatment["cause"], props.parentItemId],
            },
            {
              headers: { "x-access-token": auth.user["token"] },
            }
          )
          .then((res) => {
            if (props.onSave != undefined) props.onSave();
            if (isLast) {
              notifySuccess(t("treatments.toast.treatment_edited"));
            }

            if (props.onChange != undefined) {
              props.onChange(treatment["_id"]);
            }
            setSelectedTreatment(false);
          });
      } else if (props.parentItemType == "consequence") {
        axios
          .put(
            generateUrl("/api/cra/treatments/") + treatment["_id"],
            {
              name: treatment["name"],
              consequence: [...treatment["consequence"], props.parentItemId],
            },
            {
              headers: { "x-access-token": auth.user["token"] },
            }
          )
          .then((res) => {
            if (props.onSave != undefined) props.onSave();
            if (isLast) {
              notifySuccess(t("treatments.toast.treatment_edited"));
            }

            if (props.onChange != undefined) {
              props.onChange(treatment["_id"]);
            }
            setSelectedTreatment(false);
          });
      }
    }
  };

  function handleConfirmTreatment() {
    if (selectedTreatments.length == undefined) {
      notifyWarning(t("treatments.toast.no_treatment_selected"));
      return;
    }
    selectedTreatments.map((e, i) =>
      handleAddTreatmentConnection(e, i === selectedTreatments.length - 1)
    );
  }

  const addOrRemove = (treatment: any) => {
    !!selectedTreatments.filter((e) => e["_id"] === treatment["_id"]).length
      ? setSelectedTreatments(
          selectedTreatments.filter((e) => e["_id"] !== treatment["_id"])
        )
      : setSelectedTreatments([...selectedTreatments, treatment]);
  };

  return (
    <div>
      <Modal
        show={props.show}
        size={props.size}
        onHide={() => {
          if (props.onHide != undefined) props.onHide();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.title || t("treatments.modal_treatment_edit.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="treatmentsTable">
          {!selectedTreatment ? (
            <PageTable
              dataUrl={
                props.url || generateUrl("/api/cra/treatments/getAllPaged/")
              }
              options={{
                showFields: [
                  { label: treatmentName, field: "name" },
                  {
                    label: treatmentResponsible,
                    field: "responsibleReadable",
                  },
                  {
                    label: treatmentType,
                    field: "type",
                    override: (dataline) =>
                      dataline["type"] != undefined && dataline["type"] != ""
                        ? t("treatment.types." + dataline["type"])
                        : "",
                    customFilters: treatmentTypes.map((i) => {
                      return { ...i, label: t(i.label) };
                    }),
                  },
                  {
                    label: treatmentClass,
                    field: "class",
                    override: (dataline) =>
                      dataline["class"] != undefined && dataline["class"] != ""
                        ? t("treatment.classes." + dataline["class"])
                        : "",
                    customFilters: treatmentClasses.map((i) => {
                      return { ...i, label: t(i.label) };
                    }),
                  },
                  {
                    label: treatmentStatus,
                    field: "status",
                    override: (dataLine) =>
                      t("treatment." + dataLine["status"]),
                    customFilters: [
                      { label: t("treatment.open"), value: "open" },
                      { label: t("treatment.suggested"), value: "suggested" },
                      { label: t("treatment.planned"), value: "planned" },
                      {
                        label: t("treatment.processing"),
                        value: "processing",
                      },
                      { label: t("treatment.closed"), value: "closed" },
                    ],
                  },
                  {
                    label: t("treatment.systems_affected"),
                    field: "affectedSystems",
                    override: (dataLine) => {
                      if (dataLine["affectedSystems"] == "deleted") {
                        return t(
                          "treatments.modal_treatment_edit.deleted_system"
                        );
                      } else if (dataLine["affectedSystems"] == "no_access") {
                        return t("treatments.no_system_access");
                      } else {
                        return dataLine["affectedSystems"];
                      }
                    },
                  },
                  { label: treatmentBowtie, field: "bowtie" },
                ],
                pageSize: 15,
              }}
              dataBefore={(dataLine) => (
                <div className="checkbox">
                  <Field
                    type="indeterminatecheckbox"
                    checked={
                      !!selectedTreatments.filter(
                        (e) => e["_id"] === dataLine["_id"]
                      ).length
                    }
                    onChange={() => addOrRemove(dataLine)}
                    parent={false}
                    titlePlacement="end"
                  ></Field>
                </div>
              )}
              onLineClick={(dataLine) => {
                addOrRemove(dataLine);
              }}
              hidePagination={false}
              hidePagesize={false}
              hideFilter={false}
              hover={true}
              selectedItems={selectedTreatments}
            />
          ) : (
            <React.Fragment>
              <h4>
                {t(
                  "treatments.modal_treatmentselector.selected_treatment_text"
                ) +
                  " " +
                  selectedTreatments.map(function (item) {
                    return item["name"];
                  })}
              </h4>
              <Button
                onClick={() => {
                  setSelectedTreatment(false);
                }}
              >
                {t("treatments.modal_treatmentselector.reselectbtn")}
              </Button>
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={() => {
              if (props.onCancel != undefined) props.onCancel();
            }}
          >
            {t("treatments.modal_treatmentselector.cancelbtn")}
          </Button>
          <Button
            onClick={() => {
              setSelectedTreatment(true);
              if (selectedTreatment) {
                handleConfirmTreatment();
              }
            }}
          >
            {props.copy != true
              ? t("treatments.modal_treatmentselector.confirmbtn")
              : t("treatments.modal_treatmentselector.copybtn")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TreatmentSelectorModal;
