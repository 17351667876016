import React, { useEffect, useState } from "react";

import "./Wizard.scss";
import Page from "./Page/Page";

const isEqual = require("react-fast-compare");

function useForceUpdate() {
  const [forcevalue, setForceValue] = useState(0); // integer state
  return () => setForceValue(forcevalue + 1); // update the state to force render
}

function Wizard(props) {
  const [activePage, setActivePage] = useState(0);
  const [pages, setPages] = useState([]);

  const forceUpdate = useForceUpdate();

  const handleComplete = () => {
    if (props.onComplete != undefined) {
      props.onComplete(props.data);
    }
  };

  const handlePageChange = (direction) => {
    switch (direction) {
      case "forward":
        setActivePage(activePage + 1);
        if (props.onPagechange != undefined) {
          props.onPagechange(props.data);
        }
        if (document.getElementsByClassName("modal show")[0] != undefined) {
          document.getElementsByClassName("modal show")[0].scrollTo(0, 0);
        }
        break;

      case "backward":
        setActivePage(activePage - 1);
        if (!!props.setPage) {
          props.setPage(activePage - 1);
        }
        if (props.onPagechange != undefined) {
          props.onPagechange(props.data);
        }
        break;
    }
  };

  const handleFieldChange = (key, data: any) => {
    let temp: any[] = props.data;
    temp[key] = data;
    setPages(CheckFieldFilters(props.data));
    if (props.onChange != undefined) {
      props.onChange(temp);
    }
    forceUpdate();
  };

  function CheckFieldFilters(pages) {
    if (pages != undefined && pages.length > 0) {
      pages.forEach((page) => {
        page["fields"].forEach((item) => {
          if (
            item != undefined &&
            item["options"] != undefined &&
            item["options"]["filters"] != undefined
          ) {
            item["options"]["filters"].forEach((filter) => {
              //get content of filters
              let targetfield = {};
              pages.forEach((searchPage) => {
                searchPage["fields"].forEach((searchField) => {
                  //get content of fields
                  if (searchField["id"] == filter["id"]) {
                    targetfield = searchField;
                  }
                });
              });

              switch (filter.if) {
                case "notequals":
                  switch (filter.action) {
                    case "hide":
                      if (targetfield["value"] !== filter["value"]) {
                        item["hidden"] = true;
                      } else {
                        item["hidden"] = false;
                      }
                      break;
                    case "disable":
                      if (targetfield["value"] == filter["value"]) {
                        item["disabled"] = true;
                      } else {
                        item["disabled"] = false;
                      }
                      break;
                  }
                  break;

                case "equals":
                  switch (filter.action) {
                    case "hide":
                      if (targetfield["value"] == filter["value"]) {
                        item["hidden"] = true;
                      } else {
                        item["hidden"] = false;
                      }
                      break;
                    case "disable":
                      if (targetfield["value"] == filter["value"]) {
                        item["disabled"] = true;
                      } else {
                        item["disabled"] = false;
                      }
                      break;
                  }
                  break;
              }
            });
          }
        });
      });
    }

    return pages;
  }

  const handleIdConnection = (fieldkey, valueID) => {
    if (props.handleIdConnection != undefined) {
      props.handleIdConnection(fieldkey, valueID);
    }
  };

  useEffect(() => {
    setPages(CheckFieldFilters(props.data));
  }, []);

  useEffect(() => {
    forceUpdate();
  }, [props.forceUpdate]);

  return (
    <div className="wizard">
      {pages != undefined
        ? pages.map((page, key) => {
            return (
              <Page
                key={key}
                data={page}
                totalpages={props.data.length}
                activePage={activePage}
                disableEditing={
                  props.options != undefined
                    ? props.options.disableEditing
                    : false
                }
                onNextClick={() => handlePageChange("forward")}
                onCompleteClick={handleComplete}
                onPreviousClick={() => handlePageChange("backward")}
                onChange={(data) => handleFieldChange(key, data)}
                className={
                  "page" + (activePage == key ? " visible" : " hidden")
                }
                options={props.options}
                handleIdConnection={handleIdConnection}
              ></Page>
            );
          })
        : ""}
    </div>
  );
}

export default React.memo(Wizard, isEqual);
