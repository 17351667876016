import * as React from "react";

import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import { useAuth } from "../../../../Auth";
import { useAxios } from "../../../../AxiosHandler";

import Wizard from "../../Wizard/Wizard";
import WizardFieldManipulator from "../../WizardHelpers/WizardFieldManipulator";

import { generateUrl } from "../../../../config";

function SystemRegTemplate(props) {
  const t = useTranslate();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const auth = useAuth();

  const history = useHistory();

  const GetWizardFieldByReference =
    WizardFieldManipulator.GetWizardFieldByReference;
  const GetWizardValuesByReferenceList =
    WizardFieldManipulator.GetWizardValuesByReferenceList;

  const [forceUpdate, setForceUpdate] = useState(0);
  // const [page, setPage] = useState(0);

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  function getSystemvalueOptionsByKIT(kit) {
    let options = Array();
    if (settings["systemvalue" + kit + "Num"] != undefined) {
      for (
        let i = 1;
        i <= parseInt(settings["systemvalue" + kit + "Num"]);
        i++
      ) {
        options.push({
          label: settings["systemvalue" + kit + "Level" + i],
          value: i,
        });
      }
      return options;
    }
  }

  async function fetchUsers(): Promise<any> {
    await axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/users/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/orgElements/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread(async (...res) => {
          let tempWizardData = template;

          tempWizardData[0]["fields"].splice(5, 0, {
            id: 8,
            title: t("wizard_title.input.tags"),
            value: [],
            type: "tagsfield",
            reference: "tags",
            options: {
              existingAliasData: "",
              objectType: "system",
              org: auth.user["userdata"]["unit"][0],
            },
          });

          tempWizardData[3]["fields"].splice(1, 0, {
            id: 5,
            title: t("wizard_title.input.riskassessmentparticipants"),
            value: [],
            type: "personselector",
            reference: "riskassessmentparticipants",
            options: {
              users: res[0].data,
              organizations: res[1].data,
              minlength: 2,
              noresults: t(
                "wizard_title.input.riskassessmentparticipants_noresult"
              ),
              roleTitle: t(
                "wizard_title.input.riskassessmentparticipants_role"
              ),
              organizationTitle: t(
                "wizard_title.input.riskassessmentparticipants_organization"
              ),

              deleteButtonText: t(
                "wizard_title.input.riskassessmentparticipants_deletebtn"
              ),
              addButtonText: t(
                "wizard_title.input.riskassessmentparticipants_addbtn"
              ),
            },
          });

          tempWizardData[1]["fields"].splice(1, 0, {
            id: 3,
            title: t("wizard_title.input.system_owner_orgUnit"),
            value: "",
            type: "autocompletewithid",
            reference: "ownerorg",
            options: {
              list: res[1].data.map((i, key) => {
                return { id: key, value: i["name"], label: i["name"] };
              }),
              data: res[1].data,
              minlength: 2,
              noresults: "no matches",
            },
          });

          tempWizardData[1]["fields"].splice(3, 0, {
            id: 4,
            title: t("wizard_title.input.system_owner"),
            value: "",
            type: "autocompletewithid",
            reference: "ownerperson",
            options: {
              list: res[0].data.map((i, key) => {
                return { id: key, value: i["_id"], label: i["name"] };
              }),
              data: res[0].data,
              minlength: 2,
              noresults: "no matches",
            },
          });
          tempWizardData[1]["fields"].splice(5, 0, {
            id: 6,
            title: t("system_description.system_wizard.new_system.responsible"),
            value: "",
            type: "autocompletewithid",
            reference: "responsible",
            options: {
              list: res[0].data.map((i, key) => {
                return { id: key, value: i["_id"], label: i["name"] };
              }),
              data: res[0].data,
              minlength: 2,
              noresults: "no matches",
            },
          });

          if (settings["customfieldsSystem"].length > 0) {
            tempWizardData.push({
              fields: [
                ...settings["customfieldsSystem"].map((field, key) => {
                  return {
                    title: field.key,
                    value: "",
                    type:
                      field.type == "string"
                        ? "input"
                        : field.type == "dropdown"
                        ? "select"
                        : "",
                    options:
                      field.type == "dropdown"
                        ? {
                            choosetext: " Gjør et valg",
                            choices: field.options.map((option, key) => {
                              return { label: option, value: key };
                            }),
                          }
                        : undefined,
                  };
                }),
              ],
            });
          }

          setTemplate(tempWizardData);
          setForceUpdate(forceUpdate + 1);
        })
      );
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const [template, setTemplate] = useState<any>([
    {
      fields: [
        {
          type: "text_title",
          title: t("system_description.system_wizard.new_system.title"),
        },
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.title_description"
          ),
        },
        {
          type: "dateselector",
          title: t("system_description.system_wizard.new_system.date_title"),
          value: new Date(),
          disabled: true,
        },
        {
          type: "input",
          title:
            t("system_description.system_wizard.new_system.systemname") + " *",
          reference: "name",
        },
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_systemnavn"
          ),
        },
        /* {
                    "type":"input",
                    "title": t("system_description.system_wizard.new_system.system_nickname"),
                    "reference":"alias"
                },
                {
                    "type":"text_normal",
                    "title": t("system_description.system_wizard.new_system.ledetekst_system_nickname")
                }, */
        //Tag selection field is generated
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_system_tags"
          ),
        },
        {
          type: "select",
          title: t(
            "system_description.system_wizard.new_system.system_importance"
          ),
          reference: "importance",
          options: {
            typeLoad: "Manual",
            choosetext: t(
              "system_description.system_wizard.importance.choose_text"
            ),
            choices: [
              {
                label: t(
                  "system_description.system_wizard.importance.extremely_important"
                ),
                value: "extremely",
              },
              {
                label: t(
                  "system_description.system_wizard.importance.very_important"
                ),
                value: "very",
              },
              {
                label: t(
                  "system_description.system_wizard.importance.moderately_important"
                ),
                value: "moderately",
              },
              {
                label: t(
                  "system_description.system_wizard.importance.slightly_important"
                ),
                value: "slightly",
              },
              {
                label: t(
                  "system_description.system_wizard.importance.unimportant_important"
                ),
                value: "unimportant",
              },
            ],
          },
        },
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_system_importance"
          ),
        },
        {
          type: "textarea",
          title: t(
            "system_description.system_wizard.new_system.system_description"
          ),
          reference: "description",
        },
        {
          type: "text_normal",
          title: t("system_description.system_wizard.new_system.purpose"),
        },
        {
          type: "textarea",
          title: t("system_description.system_wizard.new_system.limitations"),
          reference: "limitationsdescription",
        },
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_limitations"
          ),
        },
      ],
    },
    {
      fields: [
        {
          type: "text_title",
          title: t(
            "system_description.system_wizard.new_system.ownership_title"
          ),
        },
        //Ownerorg-field is generated
        /* {
                    "type":"input",
                    "title": t("wizard_title.input.system_owner_orgUnit"),
                    "reference":"ownerorg"
                }, */
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_section"
          ),
        },
        //Ownerperson-field is generated
        /* {
                    "type":"input",
                    "title":t("wizard_title.input.system_owner"),
                    "reference":"ownerperson"
                }, */
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_owner"
          ),
        },
        //Responsible-field is generated
        /* {
                    "type":"input",
                    "title": t("system_description.system_wizard.new_system.responsible"),
                    "reference":"responsible"
                }, */
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_responsible"
          ),
        },
        {
          type: "textarea",
          title: t("system_description.system_wizard.new_system.provider"),
          reference: "providerinfo",
        },
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_provider"
          ),
        },
      ],
    },
    {
      fields: [
        {
          type: "text_title",
          title: t(
            "system_description.system_wizard.new_system.system_properties"
          ),
        },
        {
          type: "select",
          title: t("wizard_title.select.optional_status"),
          reference: "operationstatus",
          options: {
            typeLoad: "Manual",
            choosetext: t("wizard_title.select.choose_text"),
            choices: [
              {
                label: t("system_description.system_wizard.status.unknown"),
                value: "Ukjent",
              },
              {
                label: t(
                  "system_description.system_wizard.status.indevelopment"
                ),
                value: "Utvikling",
              },
              {
                label: t("system_description.system_wizard.status.test"),
                value: "Test",
              },
              {
                label: t("system_description.system_wizard.status.pilot"),
                value: "Pilot",
              },
              {
                label: t("system_description.system_wizard.status.production"),
                value: "Produksjon",
              },
              {
                label: t("system_description.system_wizard.status.phasingout"),
                value: "Under utfasing",
              },
              {
                label: t("system_description.system_wizard.status.phasedout"),
                value: "faset ut",
              },
              {
                label: t("system_description.system_wizard.status.storage"),
                value: "Under oppbevaring",
              },
              {
                label: t(
                  "system_description.system_wizard.status.discontinued"
                ),
                value: "fjernet",
              },
            ],
          },
        },
        {
          type: "text_normal",
          title: t("system_description.system_wizard.status.ledetekst"),
        },
        {
          type: "divider",
        },
        {
          type: "input",
          title: t("wizard_title.input.numberofusers"),
          reference: "numberofusers",
        },
        {
          type: "input",
          title: t("wizard_title.input.numberofusersfull"),
          reference: "maxnumberofusers",
        },
        {
          type: "text_normal",
          title: t("system_description.system_wizard.n_users.ledetekst"),
        },
        {
          type: "divider",
        },
        {
          type: "selectmulti",
          title: t("system_description.system_wizard.users.title"),
          options: {
            typeLoad: "Manual",
            choices: [
              {
                label: t("system_description.system_wizard.users.employees"),
                value: "Ansatte",
              },
              {
                label: t("system_description.system_wizard.users.visitors"),
                value: "Besøkende",
              },
              {
                label: t("system_description.system_wizard.users.volunteers"),
                value: "Frivillige",
              },
              {
                label: t("system_description.system_wizard.users.consultants"),
                value: "Konsulenter",
              },
              {
                label: t("system_description.system_wizard.users.customers"),
                value: "Kunder",
              },
              {
                label: t("system_description.system_wizard.users.suppliers"),
                value: "Leverandører",
              },
              {
                label: t("system_description.system_wizard.users.students"),
                value: "Studenter",
              },
              {
                label: t("system_description.system_wizard.users.public"),
                value: "Systemet er offentlig",
              },
            ],
          },
          reference: "userscategory",
        },
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_hvem"
          ),
        },
        {
          type: "selectmulti",
          title: t("system_description.system_wizard.access.title"),
          options: {
            typeLoad: "Manual",
            choices: [
              {
                label: t("system_description.system_wizard.access.internet"),
                value: "Via internet",
              },
              {
                label: t("system_description.system_wizard.access.remote"),
                value: "Fjerntilgang via VPN eller lignende",
              },
              {
                label: t("system_description.system_wizard.access.local"),
                value: "Lokal tilgang (internt)",
              },
            ],
          },
          reference: "systemaccessmethod",
        },
        {
          type: "text_normal",
          title: t(
            "system_description.system_wizard.new_system.ledetekst_access"
          ),
        },
        {
          type: "select",
          title: t("system_description.system_wizard.hosting.hosting"),
          options: {
            typeLoad: "Manual",
            choices: [
              {
                label: t("system_description.system_wizard.hosting.internally"),
                value: "Internt",
              },
              {
                label: t("system_description.system_wizard.hosting.externally"),
                value: "Externt",
              },
              {
                label: t("system_description.system_wizard.hosting.cloud"),
                value: "Skybasert",
              },
              {
                label: t("system_description.system_wizard.hosting.hybrid"),
                value: "Hybrid",
              },
            ],
          },
          reference: "category",
        },
        {
          type: "text_normal",
          title: t("system_description.system_wizard.hosting.ledetekst"),
        },
        {
          type: "divider",
        },
        {
          type: "bool",
          title: t("system_description.system_wizard.availability.periods"),
          reference: "specialavailabilityperiod",
        },
        {
          type: "text_normal",
          title: t("system_description.system_wizard.availability.ledetekst"),
        },
        {
          type: "select",
          title: t(
            "system_description.system_wizard.availability.availability"
          ),
          options: {
            typeLoad: "Manual",
            choices: getSystemvalueOptionsByKIT("T"),
          },
          reference: "availability",
        },
        {
          type: "textarea",
          title: t("system_description.system_wizard.availability.reasoning"),
          reference: "availabilityreasoning",
        },
        {
          type: "divider",
        },
        {
          type: "select",
          title: t("system_description.system_wizard.OS.operatings"),
          options: {
            typeLoad: "Manual",
            choices: [
              {
                label: "Windows",
                value: "Windows",
              },
              {
                label: "Linux",
                value: "Linux",
              },
              {
                label: "Unix",
                value: "Unix",
              },
              {
                label: t("system_description.system_wizard.OS.other"),
                value: "Annet",
              },
            ],
          },
          reference: "operatingsystem",
        },
        {
          type: "input",
          title: t("system_description.system_wizard.OS.version"),
          reference: "operatingsystemversion",
        },
        {
          type: "text_normal",
          title: t("system_description.system_wizard.OS.ledetekst"),
        },
        {
          type: "divider",
        },
        {
          type: "input",
          title: t("system_description.system_wizard.OS.doc"),
          reference: "systemdocumentationlink",
        },
        {
          type: "input",
          title: t("system_description.system_wizard.OS.login"),
          reference: "systemloginlink",
        },
      ],
    },
    {
      fields: [
        {
          type: "text_title",
          title: t(
            "system_description.system_wizard.new_system.system_participants"
          ),
          options: {
            headertag: "h2",
          },
        },
      ],
    },
  ]);

  function valueToPercentage(value, maxvalue) {
    let isNull =
      value == undefined || value == null || value.length == 0
        ? 1
        : parseInt(value);
    return ((isNull - 1) / (maxvalue - 1)) * 100;
  }

  const handleWizardComplete = (data) => {
    let values = GetWizardValuesByReferenceList(data, [
      "name",
      //"alias",
      "description",
      "limitationsdescription",
      "ownerorg",
      "ownerperson",
      "responsible",
      "providerinfo",
      "operationstatus",
      "numberofusers",
      "maxnumberofusers",
      "userscategory",
      "systemaccessmethod",
      "category",
      "specialavailabilityperiod",
      "availability",
      "availabilityreasoning",
      "operatingsystem",
      "operatingsystemversion",
      "systemdocumentationlink",
      "systemloginlink",
      "riskassessmentparticipants",
      "tags",
      "importance",
    ]);

    if (values.name == undefined || values.name == "") {
      notifyWarning(
        t("diri.regassistant.types.system.toast.warning_missingSystemName")
      );
      return null;
    }

    axios
      .post(
        generateUrl("/api/cra/systems/"),
        {
          name: values.name,
          //alias: values.alias,
          description: values.description,
          limitationsdescription: values.limitationsdescription,
          ownerOrg: values.ownerorg,
          ownerPerson: values.ownerperson,
          responsiblePerson: values.responsible,
          providerinfo: values.providerinfo,
          operationStatus: values.operationstatus,
          numberofusers:
            values.numberofusers != "" && values.numberofusers != undefined
              ? values.numberofusers
              : 0,
          maxnumberofusers:
            values.maxnumberofusers != "" &&
            values.maxnumberofusers != undefined
              ? values.maxnumberofusers
              : 0,
          userscategory: values.userscategory,
          systemaccessmethod: values.systemaccessmethod,
          category: values.category,
          specialavailabilityperiod: values.specialavailabilityperiod,
          availability:
            values.availability != undefined
              ? valueToPercentage(
                  Number(values.availability),
                  settings["systemvalueTNum"]
                )
              : "",
          availabilityreasoning: values.availabilityreasoning,
          operatingsystem: values.operatingsystem,
          operatingsystemversion: values.operatingsystemversion,
          systemdocumentationlink: values.systemdocumentationlink,
          systemloginlink: values.systemloginlink,
          org: auth.user["userdata"]["unit"][0],
          riskassessmentparticipants: values.riskassessmentparticipants,
          tags: values.tags,
          text: "",
          systemType: ["system"],
          importance: values.importance,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        if (props.regCompleted != undefined) props.regCompleted(response.data);
        history.push("/system/" + response.data._id);
      });
  };

  return (
    <Wizard
      data={template}
      forceUpdate={forceUpdate}
      onComplete={(data) => {
        handleWizardComplete(data);
      }}
      setPage={(e) => {
        props.setPage(e);
      }}
    ></Wizard>
  );
}

export default SystemRegTemplate;
