import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useAuth } from "../../../Auth";
import { useTranslate } from "react-polyglot";
import { useMsalAuth } from "../../../AuthProvider";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./Login.scss";
import CRALogo from "../../../Assets/Images/diri-logo.svg";
import MSLogoSignin from "../../../Assets/Images/ms-symbollockup_signin_light.png";

import { Button, Card, Form } from "react-bootstrap";
import LocaleHandler from "../../../LocaleHandler";
import { useDynamicLanguage } from "../../../DynamicLanguageProvider";

function Login() {
  const auth = useAuth();
  const msal = useMsalAuth();
  const history = useHistory();
  const t = useTranslate();
  const loadTexts = useDynamicLanguage().loadTexts;
  const { setLocale } = React.useContext(LocaleHandler);

  const notifyAlert = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "alert-toast",
    });

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(true);

  const updateUsername = (event) => setUsername(event.target.value);
  const updatePassword = (event) => setPassword(event.target.value);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  async function login() {
    let result = await auth.signin(username, password);
    if (result != null && result["id"] == "") {
      notifyAlert(t("login.toast_wrongusernamepassword"));
    } else {
      setLocale(result["userdata"]["language"]);
      loadTexts(result);
      history.push("/");
    }
  }

  function loginMsal() {
    msal.login();
  }

  const forgotPassword = () => {
    history.push("/forgot-password");
  };

  console.log("here");
  useEffect(() => {
    setTimeout(() => {
      if (!!sessionStorage["msal.idtoken"]) {
        setLoading(true);

        msal.checkMsalAuth().then(async (result) => {
          if (!!result) {
            history.push("/");
          }
        });
      } else {
        setLoading(false);
      }
    }, 500);
  }, []);
  // }, [history.location]);

  return loading ? (
    <p style={{ textAlign: "center", marginTop: "100px" }}></p>
  ) : (
    <div className="logincontainer">
      <div className="row justify-content-center">
        <div className="col-lg-5">
          <Card>
            <div className="card-header pt-4 pb-4 text-center login-header">
              <a href="/">
                <span>
                  <img className="logo" src={CRALogo} alt="" width="200" />
                </span>
              </a>
            </div>
            <Card.Body>
              <div className="welcometext">
                <h4>{t("login.page_name")}</h4>
                <p>{t("login.login_info")}</p>
              </div>
              <div className="loginform">
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>E-postadresse</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={t("login.email_info")}
                      value={username}
                      onKeyDown={handleKeyDown}
                      onChange={updateUsername}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>{t("login.password")}</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("login.password_info")}
                      value={password}
                      onKeyDown={handleKeyDown}
                      onChange={updatePassword}
                    />
                  </Form.Group>
                  <p className="forgotPassword" onClick={forgotPassword}>
                    {t("forgot_password.forgot_password")}
                  </p>
                </Form>
                <div className="buttonbox">
                  <Button onClick={login}>{t("login.page_name")}</Button>

                  <Button className="msloginbtn" onClick={loginMsal}>
                    <img alt="Microsoft Login" src={MSLogoSignin}></img>
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Login;
