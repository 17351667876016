import React, { useEffect, useState } from 'react';
import { useAxios } from "../../../../../AxiosHandler";
import { useAuth } from "../../../../../Auth";
import { useTranslate } from 'react-polyglot';

import {
    useHistory
  } from "react-router-dom";

import { Card, Table, Button } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';


function Importance(props){
    const t = useTranslate();  
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();
    const history = useHistory();

    const [dataOfChart, setDataOfChart] = useState<any>([]);
    const [dataOfChartSystems, setDataOfChartSystems] = useState<any>([]);
    const [dataOfLabels, setDataOfLabels] = useState<any>([]);
    const [selectedSystems, setSelectedSystems] = useState([]);
    const [loading, setLoading] = useState(true);

    function fetchChosenSystems(value) {
        setSelectedSystems(dataOfChartSystems[value]);
    }

    const data = {
        labels: dataOfLabels,
        datasets: [
            {
                backgroundColor: [
                    "rgb(91, 130, 102, 1)",
                    "rgb(250, 205, 117, 1)",
                    "rgb(216, 119, 90, 1)",
                    "rgb(153, 55, 66, 1)",
                    "rgb(0, 0, 0, 1)",
                ],
                data: dataOfChart
            }
        ]
    }

    async function processData(res) {
        setDataOfChart([
            res[0].data.filter(system => system.importance == "unimportant").length,
            res[0].data.filter(system => system.importance == "slightly").length,
            res[0].data.filter(system => system.importance == "moderately").length,
            res[0].data.filter(system => system.importance == "very").length,
            res[0].data.filter(system => system.importance == "extremely").length
        ]);

        setDataOfChartSystems([
            res[0].data.filter(system => system.importance == "unimportant"),
            res[0].data.filter(system => system.importance == "slightly"),
            res[0].data.filter(system => system.importance == "moderately"),
            res[0].data.filter(system => system.importance == "very"),
            res[0].data.filter(system => system.importance == "extremely")
        ]);
        
        setDataOfLabels([
            t("dashboard.cards.importance.unimportant_important"),
            t("dashboard.cards.importance.slightly_important"),
            t("dashboard.cards.importance.moderately_important"),
            t("dashboard.cards.importance.very_important"),
            t("dashboard.cards.importance.extremely_important"),
        ]);
    }

    useEffect(() => {
        Promise.all([
            props.requestData("systems"),
        ]).then(async (data) => {
            await processData(data);
            setLoading(false);
        });
    }, []);


    return(
        <Card>
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                <h4 className="header-title">{t("dashboard.cards.importance.name")}</h4>
                <p>{t("dashboard.cards.importance.description")}</p>
                <hr />
                <div>
                    <Bar
                        data={data}
                        options={{
                            onClick: (evt, data) => {
                                if (data != undefined && data[0] != undefined) {
                                    fetchChosenSystems(data[0]._index);
                                }
                            },
                            title: {
                                display:true,
                                fontSize:20
                            },
                            legend: {
                                display: false,
                                position:'right'
                            },
                            scales: {
                                yAxes: [{
                                    stacked: true,
                                    ticks: {
                                        suggestedMin: 0,
                                        stepSize: 1
                                    },
                                }]
                            },
                        }}
                    />
                </div>
                {selectedSystems != undefined && selectedSystems.length > 0 ?
                    <div className="chartDetails">
                        <div>
                            <h4 className="header-title">{t("dashboard.cards.importance.details.title")}</h4>
                            <Button onClick={() => {
                                setSelectedSystems([]);
                            }}>{t("dashboard.cards.importance.details.close")}</Button>
                            <Table striped hover className="chartdetailstable">
                                <thead>
                                    <tr>
                                        <th>{t("dashboard.cards.importance.details.itsystem")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        selectedSystems.map((element, key) => {
                                            return(
                                                <tr key={key} style={{cursor: "pointer"}} onClick={() => {
                                                    history.push("/system/" + element["_id"]);
                                                    window.scrollTo(0, 0);
                                                }}>
                                                    <td>{ element["nameReadable"] }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                : null}
            </Card.Body>
        </Card>
    )

}

export default Importance;