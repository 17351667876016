/*async function fetchData(axios, auth): Promise<any> {
    let results = {
        users: [],
        organizations: []
    };

    await axios.all([
        axios.single({
            method: "get",
            url: "https://test.diri.ai/api/users/",
            responseType: "json",
            headers: {'x-access-token': auth.user["token"]}
        }),
        axios.single({
			method: "get",
			url: "https://test.diri.ai/api/orgElements/",
			responseType: "json",
			headers: {'x-access-token': auth.user["token"]}
      })
    ])
    .then(axios.spread(async (...res) => {
        results.users = res[0].data;
        results.organizations = res[1].data;
    }));

    return results;
}*/

async function HardwareTemplate(t, axios, auth) {

    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    //let data = await fetchData(axios, auth);

    let tempWizardData: Array<any> = [
		{
			fields: [
				{
					type: "text_title",
					title: t("wizard_title.assets.hardware.title")
				},
				{
					type: "text_normal",
					title: t("wizard_title.assets.hardware.desc")
				},
				{
					id: 6,
					type: "bool",
					title: t("wizard_title.assets.hardware.question") + " *",
					value: "",
					reference: "createitembool"
				},
				{
					type: "textarea",
					title: t("wizard_title.assets.reason_not_relevant"),
					options: {
						filters: [
							{
								if: "notequals",
								id: 6,
								value: false,
								action: "hide",
							}
						]
					},
					reference: "reasoning_not"
				},
				{
					type: "text_normal",
					title: t("wizard_title.assets.reason_not_relevant_desc"),
					options: {
						filters: [
							{
								if: "notequals",
								id: 6,
								value: false,
								action: "hide",
							}
						]
					}
				},
				{
					/* REFERENCED BY POSITION FROM ValueEvaluation.tsx */
					type: "selectmulti",
					title: t("wizard_title.assets.hardware.question2"),
					reference: "components",
					options: {
						typeLoad: "Manual",
						choices: [
							{
								label: t("wizard_title.assets.hardware.assets.storage"),
								value: "Datalagringsressurser"
							},
							{
								label: t("wizard_title.assets.hardware.assets.hosting"),
								value: "Hosting-muligheter"
							},
							{
								label: t("wizard_title.assets.hardware.assets.computing"),
								value: "Regneressurser"
							},
							{
								label: t("wizard_title.assets.hardware.assets.servers"),
								value: "Servere"
							},
							{
								label: t("wizard_title.assets.hardware.assets.network"),
								value: "Nettverk"
							},
							{
								label: t("wizard_title.assets.hardware.assets.portable"),
								value: "Bærbart datautstyr"
							},
							{
								label: t("wizard_title.assets.hardware.assets.other"),
								value: "Annet"
							}
						],
						filters: [
							{
								if: "notequals",
								id: 6,
								value: true,
								action: "hide",
							}
						]
					}
				},
				{
					type: "text_normal",
					title: t("wizard_title.assets.hardware.deschelptext"),
					options: {
						filters: [
							{
								if: "notequals",
								id: 6,
								value: true,
								action: "hide",
							}
						]
					}
				},
				{
					type: "textarea",
					title: t("wizard_title.assets.hardware.desc2"),
					options: {
						filters: [
							{
								if: "notequals",
								id: 6,
								value: true,
								action: "hide",
							}
						]
					},
					reference: "description"
				},
				{
					type: "divider",
					options: {
						filters: [
							{
								if: "notequals",
								id: 6,
								value: true,
								action: "hide",
							}
						]
					}
				},
				{
					/* REFERENCED BY POSITION FROM ValueEvaluation.tsx */
					type: "number",
					title: t("wizard_title.assets.hardware.costestimate"),
					options: {
						filters: [
							{
								if: "notequals",
								id: 6,
								value: true,
								action: "hide",
							}
						]
					},
					reference: "costestimate"
				},
				{
					type: "text_normal",
					title: t("wizard_title.assets.hardware.costhelp"),
					options: {
						filters: [
							{
								if: "notequals",
								id: 6,
								value: true,
								action: "hide",
							}
						]
					}
				},
				{
					type: "textarea",
					title: t("wizard_title.assets.hardware.reason"),
					options: {
						filters: [
							{
								if: "notequals",
								id: 6,
								value: true,
								action: "hide",
							}
						]
					},
					reference: "reasoning"
				}
			]
		}
  	];
    
    return tempWizardData;
}

export default HardwareTemplate;