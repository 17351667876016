import React, { useEffect, useState } from "react";

import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Card,
  Button,
  Row,
  Col,
  ListGroup,
  Modal,
  InputGroup,
  Form,
} from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";

import "../../Systems";
import BowtieCauseList from "./BowtieCauseList/BowtieCauseList";
import BowtieConsequenceList from "./BowtieConsequenceList/BowtieConsequenceList";
import SiteAssistant from "../../../../Shared/SiteAssistant/SiteAssistant";
import PageTable from "../../../../Shared/PageTable/PageTable";
import Field from "../../../../Shared/Wizard/Field/Field";
import DiriFaceDocked from "../../../../../Assets/Images/diriassistant/diri.svg";

import { useDynamicLanguage } from "../../../../../DynamicLanguageProvider";
import { generateUrl } from "../../../../../config";

function Bowtie() {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  let history = useHistory();
  let location = useLocation();
  const t = useTranslate();
  const td = useDynamicLanguage().td;
  const myRef = React.useRef<any>();

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const [nomenu, setNomenu] = useState("false");

  const [showNewOccurenceModal, setShowNewOccurenceModal] = useState(false);
  const [showExistingOccurenceModal, setShowExistingOccurenceModal] =
    useState(false);
  const [showDeleteOccurenceModal, setShowDeleteOccurenceModal] =
    useState(false);
  const [showEditOccurenceModal, setShowEditOccurenceModal] = useState(false);

  const [showNewOccurenceTypeModal, setShowNewOccurenceTypeModal] =
    useState(false);

  let { systemid } = useParams();

  const [system, setSystem] = useState([{}]);

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);

  const [deleteOccurence, setDeleteOccurence] = useState("");

  const [editOccurence, setEditOccurence] = useState({});

  const [occurence, setOccurence] = useState("");
  const [occurences, setOccurences] = useState([]);
  const [occurenceTypes, setOccurenceTypes] = useState<Array<any>>([]);

  const [allCauses, setAllCauses] = useState([]);
  const [allConsequences, setAllConsequences] = useState([]);
  const [allTreatments, setAllTreatments] = useState([]);

  const [newOccurenceName, setNewOccurenceName] = useState("");
  const [newOccurenceDescription, setNewOccurenceDescription] = useState("");
  const [newOccurenceType, setNewOccurenceType] = useState("");
  const [newOccurenceCopyof, setNewOccurenceCopyof] = useState("");

  const [newOccurenceTypeName, setNewOccurenceTypeName] = useState("");
  const [newOccurenceTypeConfirm, setNewOccurenceTypeConfirm] = useState(false);

  const [lockOrder, setLockOrder] = useState(
    localStorage.getItem("lockorder") === "false" ? false : true
  );

  const [causeConDragging, setCauseConDragging] = useState(false);
  const [consequenceConDragging, setConsequenceConDragging] = useState(false);
  const [draggingItem, setDraggingItem] = useState();

  const [assistantkey, setAssistantkey] = useState("");

  const [showCauseModal, setShowCauseModal] = useState(false);
  const [showConsequenceModal, setShowConsequenceModal] = useState(false);

  const setEditOccurenceName = (value) => {
    setEditOccurence((editOccurence) => ({ ...editOccurence, name: value }));
  };

  const selectOccurence = (evt) => {
    if (evt.target.attributes["data-occurenceid"] != undefined) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      history.replace(window.location.pathname, {});
      setOccurence(evt.target.attributes["data-occurenceid"].value);
      fetchConsequences(evt.target.attributes["data-occurenceid"].value);
    }
  };

  function percentageToValue(percentage, maxvalue) {
    return (maxvalue - 1) * (percentage / 100) + 1;
  }

  const handleKeyDownEventCreate = (event) => {
    if (event.key === "Enter") {
      handleCreateOccurence();
    }
  };

  const handleKeyDownEventEdit = (event) => {
    if (event.key === "Enter") {
      handleEditOccurence();
    }
  };

  const handleBackSystems = () => {
    history.push("/system/" + system["_id"]);
  };

  const handleCloseNewOccurenceModal = () => setShowNewOccurenceModal(false);
  const handleCloseExistingOccurenceModal = () =>
    setShowExistingOccurenceModal(false);
  const handleShowNewOccurenceModal = () => setShowNewOccurenceModal(true);
  const handleShowExistingOccurenceModal = () =>
    setShowExistingOccurenceModal(true);
  const handleCloseDeleteOccurenceModal = () =>
    setShowDeleteOccurenceModal(false);
  const handleShowDeleteOccurenceModal = (evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    setDeleteOccurence(evt.target.getAttribute("data-occurenceid"));
    setShowDeleteOccurenceModal(true);
  };
  const handleCloseEditOccurenceModal = () => setShowEditOccurenceModal(false);
  const handleShowEditOccurenceModal = (evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    setEditOccurence(
      occurences.filter(
        (item) => item["_id"] == evt.target.getAttribute("data-occurenceid")
      )[0]
    );
    setShowEditOccurenceModal(true);
  };

  const newOccurenceNameChange = (value) => {
    if (value.length > 1) {
      axios
        .post(
          generateUrl("/api/cra/occurences/search"),
          {
            filter: value,
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then(
          function (response) {
            setAutoCompleteOptions(
              response.data.map((item) => {
                return { id: item._id, label: item.name };
              })
            );
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        );
    }

    setNewOccurenceName(value);
  };

  const handleCreateOccurence = () => {
    axios
      .post(
        generateUrl("/api/cra/occurences/"),
        {
          name: newOccurenceName,
          description: newOccurenceDescription,
          type: newOccurenceType,
          system: systemid,
          tags: [],
          text: "",
          copyof: newOccurenceCopyof,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchData();
        notifySuccess(t("bowtie.toast_event_created"));
        setShowNewOccurenceModal(false);
        setOccurence(response["data"]["_id"]);
        fetchConsequences(response["data"]["_id"]);
      });
  };

  const handleCreateOccurenceType = () => {
    if (newOccurenceTypeName == "") {
      notifyWarning(t("bowtie.toast_empty_name"));
      return undefined;
    }
    if (newOccurenceTypeName.length < 3) {
      notifyWarning(t("bowtie.toast_length"));
      return undefined;
    }

    axios
      .post(
        generateUrl("/api/cra/occurencetypes/"),
        {
          name: newOccurenceTypeName,
          grouping: "",
          tags: [],
          status: "requested",
          systemId: systemid,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchData();
        notifySuccess(t("bowtie.toast_event_created"));
        setShowNewOccurenceTypeModal(false);
        setNewOccurenceTypeName("");
      });
  };

  const handleCopyExistingOccurence = (occurence) => {
    axios
      .post(
        generateUrl("/api/cra/occurences/"),
        {
          name: occurence["name"],
          description: occurence["description"],
          type: occurence["type"],
          system: systemid,
          tags: [],
          text: "",
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchData();
        setShowExistingOccurenceModal(false);
        notifySuccess(t("bowtie.toast_event_created"));
        setOccurence(response["data"]["_id"]);
        fetchConsequences(response["data"]["_id"]);
      });
  };

  const handleDeleteOccurence = (evt) => {
    axios
      .delete(
        generateUrl("/api/cra/occurences/") + deleteOccurence,
        {
          _id: deleteOccurence,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchData();
        notifySuccess(t("bowtie.toast_event_deleted"));
        fetchConsequences(occurence);
        setShowDeleteOccurenceModal(false);
      });
  };

  const handleEditOccurence = () => {
    axios
      .put(
        generateUrl("/api/cra/occurences/") + editOccurence["_id"],
        {
          name: editOccurence["name"],
          description: editOccurence["description"],
          type: editOccurence["type"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchData();
        fetchConsequences(occurence);
        setShowEditOccurenceModal(false);
      });
  };

  function fetchConsequences(occurenceid) {
    axios
      .single({
        method: "get",
        url: generateUrl("/api/cra/consequences/bysystem/") + systemid,
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        res.data.forEach((consequence) => {
          consequence["consequenceValue"] = percentageToValue(
            consequence["consequenceValue"],
            settings["gridY"]
          );
          if (
            (consequence.occurences == undefined ||
              consequence.occurences.length == 0) &&
            consequence.occurence != ""
          ) {
            consequence.occurences = [
              {
                value: consequence.occurence,
                label: occurences.find((i) => i["_id"] == consequence.occurence)
                  ? ["name"]
                  : "",
              },
            ];
          }
        });
        setAllConsequences(res.data);
      });
  }

  function handleDragStart(data) {
    switch (data.source.droppableId.split(".")[0]) {
      case "cause":
        setCauseConDragging(true);
        setDraggingItem(
          allCauses.find(
            (i) => i["_id"] == data.source.droppableId.split(".")[1]
          )
        );
        break;
      case "consequence":
        setConsequenceConDragging(true);
        setDraggingItem(
          allConsequences.find(
            (i) => i["_id"] == data.source.droppableId.split(".")[1]
          )
        );
        break;
    }
  }

  function handleDragEnd(data) {
    switch (data.source.droppableId.split(".")[0]) {
      case "cause":
        setCauseConDragging(false);
        if (
          data.destination != undefined &&
          data.destination.droppableId.split(".")[0] == "occurence"
        ) {
          axios
            .post(
              generateUrl("/api/cra/causes/createconnection"),
              {
                systemId: systemid,
                causeId: data.source.droppableId.split(".")[1],
                occurenceId: data.destination.droppableId.split(".")[2],
              },
              {
                headers: { "x-access-token": auth.user["token"] },
              }
            )
            .then(function (response) {
              fetchData();
            });
        }
        break;
      case "consequence":
        setConsequenceConDragging(false);
        if (
          data.destination != undefined &&
          data.destination.droppableId.split(".")[0] == "occurence"
        ) {
          axios
            .post(
              generateUrl("/api/cra/consequences/createconnection"),
              {
                systemId: systemid,
                consequenceId: data.source.droppableId.split(".")[1],
                occurenceId: data.destination.droppableId.split(".")[2],
              },
              {
                headers: { "x-access-token": auth.user["token"] },
              }
            )
            .then(function (response) {
              fetchData();
            });
        }
        break;
    }
    setDraggingItem(undefined);
  }

  function hasNonLinkedItems() {
    let nolink = false;
    allCauses.forEach(function (item) {
      if (item["occurence"] == "" && item["occurences"] == undefined)
        nolink = true;
      if (
        item["occurences"] != undefined &&
        (item["occurences"] as Array<any>).length == 0
      )
        nolink = true;
    });
    allConsequences.forEach(function (item) {
      if (item["occurence"] == "" && item["occurences"] == undefined)
        nolink = true;
      if (
        item["occurences"] != undefined &&
        (item["occurences"] as Array<any>).length == 0
      )
        nolink = true;
    });
    return nolink;
  }

  async function fetchData() {
    await axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systems/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/occurences/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/causes/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/consequences/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/treatments/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/occurencetypes/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setSystem(res[0].data);
          let occurences = res[1].data.filter(
            (item) => item["system"] == res[0].data["_id"]
          );
          occurences = occurences.map((occurence) => {
            return {
              ...occurence,
              occurenceTypeName:
                res[5].data.find((i) => i._id == occurence.type)?.name ?? "",
            };
          });
          setOccurences(occurences);

          setOccurenceTypes(res[5].data);
          res[2].data.forEach((cause) => {
            cause["probability"] = percentageToValue(
              cause["probability"],
              settings["gridY"]
            );
            if (
              (cause.occurences == undefined || cause.occurences.length == 0) &&
              cause.occurence != ""
            ) {
              cause.occurences = [
                {
                  value: cause.occurence,
                  label:
                    occurences.find((i) => i._id == cause.occurence)?.name ??
                    "",
                },
              ];
            }
          });
          res[3].data.forEach((consequence) => {
            consequence["consequenceValue"] = percentageToValue(
              consequence["consequenceValue"],
              settings["gridY"]
            );
            if (
              (consequence.occurences == undefined ||
                consequence.occurences.length == 0) &&
              consequence.occurence != ""
            ) {
              consequence.occurences = [
                {
                  value: consequence.occurence,
                  label: occurences.find((i) => i._id == consequence.occurence)
                    ?.name,
                },
              ];
            }
          });
          res[4].data.forEach((treatment) => {
            treatment["oldvalue"] = treatment.probabilityreduction;
            treatment["probabilityreduction"] =
              Math.round(
                (treatment["probabilityreduction"] / 100) *
                  (treatment["cause"] == undefined
                    ? settings["gridX"]
                    : settings["gridY"]) *
                  10
              ) / 10;
          });

          setAllCauses(res[2].data);
          setAllConsequences(res[3].data);
          setAllTreatments(res[4].data);

          if (location["search"] != undefined && location["search"] != "") {
            if (occurence == "") {
              setOccurence(location["search"].split("=")[1]);
              fetchConsequences(location["search"].split("=")[1]);
            }
          }
          setAssistantkey("bowtie");
        })
      );
  }

  useEffect(() => {
    localStorage.setItem("lockorder", lockOrder.toString());
  }, [lockOrder]);

  useEffect(() => {
    if (auth.user["userdata"] == undefined) {
      window.location.href = "/login";
    }

    fetchData();
    setOccurence("");
    fetchConsequences("");

    if (location["search"].split("?")[1] != undefined) {
      setNomenu(
        (location["search"].split("?")[1].split("=")[1] == "true").toString()
      );
    }
  }, []);

  return (
    <div className={nomenu == "true" ? "maincontent fullwidth" : "maincontent"}>
      {nomenu == "true" ? null : assistantkey != "" ? (
        <SiteAssistant dockedLogo={DiriFaceDocked} featurekey={assistantkey} />
      ) : null}
      <div className="systems-container">
        <div className="pagetitle-container">
          <h1>{t("bowtie.system_page_name")}</h1>
          <Button className="newsystemBtn backbtn" onClick={handleBackSystems}>
            {/* t("bowtie.back") */ t("system.go_to_assessment")}
          </Button>
        </div>
        <Card>
          <Card.Body className="d-flex">
            <h2 className="header-title">
              {system["name"]} - {t("bowtie.bowtie_title")}
            </h2>

            <Tooltip
              title={`${t("bowtie.bowtie_info_1")} ${t(
                "bowtie.bowtie_info_2"
              )}`}
              placement="right"
              className="ml-2"
            >
              <i className="dripicons-information" />
            </Tooltip>
          </Card.Body>
        </Card>
        {hasNonLinkedItems() ? (
          <Card className="warningcard">
            <Card.Body>
              <React.Fragment>
                <i className="dripicons-link-broken" />
                <p>
                  <strong>
                    {t("bowtie.bowtie_info_nolinks1")}
                    <br />
                    {t("bowtie.bowtie_info_nolinks2")}
                  </strong>
                </p>
              </React.Fragment>
            </Card.Body>
          </Card>
        ) : null}
        <div className="bowtie">
          <DragDropContext
            onDragEnd={(data) =>
              system["accesses"].includes("edit") ? handleDragEnd(data) : null
            }
            onDragStart={(data) =>
              system["accesses"].includes("edit") ? handleDragStart(data) : null
            }
          >
            <Row>
              <Col sm={4}>
                <Card>
                  <Card.Body>
                    <div className="list" data-satarget="causes">
                      <BowtieCauseList
                        allTreatments={allTreatments}
                        allOccurences={occurences}
                        occurenceId={occurence}
                        onCausesChanged={() => {
                          fetchData();
                        }}
                        system={system}
                        allCauses={allCauses}
                        showCauseModal={showCauseModal}
                      ></BowtieCauseList>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="events-card">
                  <Card.Body>
                    <div className="list" data-satarget="occurences">
                      <div className="listheader">
                        <h3>{t("bowtie.event_title")}</h3>
                        <Button
                          className="newOccurenceBtn btn-with-icon"
                          disabled={
                            !system["accesses"]?.includes("edit") ?? true
                          }
                          onClick={handleShowNewOccurenceModal}
                        >
                          <i className="dripicons-plus" />
                          {t("bowtie.event_new")}
                        </Button>
                        <Button
                          className="newOccurenceBtn btn-with-icon"
                          disabled={
                            !system["accesses"]?.includes("edit") ?? true
                          }
                          style={{ marginLeft: "10px" }}
                          onClick={handleShowExistingOccurenceModal}
                        >
                          <i className="dripicons-plus" />
                          {t("bowtie.occurence_existing")}
                        </Button>
                        <div
                          className="newOccurenceBtn lockbutton"
                          // disabled={
                          //   !system["accesses"]?.includes("edit") ?? true
                          // }
                          onClick={() => setLockOrder(!lockOrder)}
                        >
                          {lockOrder ? (
                            <Tooltip
                              title={t("bowtie.occurence_orderunlock")}
                              placement="top"
                            >
                              <div>
                                <i className={"dripicons-lock"} />
                                <i className={"dripicons-lock-open hover"} />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={t("bowtie.occurence_orderlock")}
                              placement="top"
                            >
                              <div>
                                <i className={"dripicons-lock-open"} />
                                <i className={"dripicons-lock hover"} />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <ListGroup>
                        <ListGroup.Item
                          className={
                            occurence == ""
                              ? "events-selector selected selector"
                              : "events-selector selector"
                          }
                          data-occurenceid=""
                          data-occurencename="Alle hendelser"
                          onClick={selectOccurence}
                        >
                          <p
                            data-satarget="occurences_all"
                            className="event-all"
                            data-occurenceid=""
                            data-occurencename="Alle hendelser"
                          >
                            {t("bowtie.event_all")}
                          </p>
                          <div
                            className="details-container"
                            data-occurenceid=""
                            data-occurencename="Alle hendelser"
                          >
                            <div
                              className="detail-value"
                              data-occurenceid=""
                              data-occurencename="Alle hendelser"
                            >
                              <p>Causes</p>
                              <p className="causes-count count">
                                {allCauses.length}
                              </p>
                            </div>
                            <div
                              className="detail-value"
                              data-occurenceid=""
                              data-occurencename="Alle hendelser"
                            >
                              <p>Consequences</p>
                              <p className="consequences-count count">
                                {allConsequences.length}
                              </p>
                            </div>
                          </div>
                        </ListGroup.Item>
                        {allCauses.filter(
                          (item) =>
                            item["occurences"] == undefined ||
                            (item["occurences"] as Array<any>).length == 0
                        ).length > 0 ||
                        allConsequences.filter(
                          (item) =>
                            item["occurences"] == undefined ||
                            (item["occurences"] as Array<any>).length == 0
                        ).length > 0 ? (
                          <ListGroup.Item
                            className={
                              occurence == "999999"
                                ? "selected selector"
                                : "selector"
                            }
                            data-occurenceid="999999"
                            data-occurencename="Alle hendelser"
                            onClick={selectOccurence}
                          >
                            <p
                              data-satarget="occurences_without"
                              className="title"
                              data-occurenceid="999999"
                              data-occurencename="Alle hendelser"
                            >
                              {t("bowtie.without_attachment")}
                            </p>
                            <div
                              className="treatmentstatuscontainer"
                              data-occurenceid="999999"
                              data-occurencename="Alle hendelser"
                            >
                              <div
                                className="statusindicator"
                                style={
                                  allCauses.filter(
                                    (item) =>
                                      item["occurences"] == undefined ||
                                      (item["occurences"] as Array<any>)
                                        .length == 0
                                  ).length > 0
                                    ? {
                                        backgroundColor: "#ff5c5c",
                                        color: "white",
                                      }
                                    : {}
                                }
                                data-occurenceid="999999"
                                data-occurencename="Alle hendelser"
                              >
                                {
                                  allCauses.filter(
                                    (item) =>
                                      item["occurences"] == undefined ||
                                      (item["occurences"] as Array<any>)
                                        .length == 0
                                  ).length
                                }
                              </div>
                              <div
                                className="statusindicator"
                                style={
                                  allConsequences.filter(
                                    (item) =>
                                      item["occurences"] == undefined ||
                                      (item["occurences"] as Array<any>)
                                        .length == 0
                                  ).length > 0
                                    ? {
                                        backgroundColor: "#ff5c5c",
                                        color: "white",
                                      }
                                    : {}
                                }
                                data-occurenceid="999999"
                                data-occurencename="Alle hendelser"
                              >
                                {
                                  allConsequences.filter(
                                    (item) =>
                                      item["occurences"] == undefined ||
                                      (item["occurences"] as Array<any>)
                                        .length == 0
                                  ).length
                                }
                              </div>
                            </div>
                          </ListGroup.Item>
                        ) : null}
                        <hr />
                        <div data-satarget="occurences_specific"></div>
                        {!lockOrder
                          ? occurences
                              .filter((i) => i["_id"] == occurence)
                              .map((item, key) => {
                                return (
                                  <ListGroup.Item
                                    key={key}
                                    className={
                                      occurence == item["_id"]
                                        ? "selected occurenceitem"
                                        : "occurenceitem"
                                    }
                                    data-occurenceid={item["_id"]}
                                    data-occurencename={item["name"]}
                                    onClick={selectOccurence}
                                  >
                                    <div className="text-center occurance-container">
                                      <p
                                        className="occurence-title"
                                        data-occurenceid={item["_id"]}
                                        data-occurencename={item["name"]}
                                      >
                                        {td(
                                          occurenceTypes.find(
                                            (i) => i._id == item["type"]
                                          )?.name
                                        ) ?? ""}
                                      </p>
                                      <p
                                        className="mb-0 occurence-description"
                                        data-occurenceid={item["_id"]}
                                        data-occurencename={item["name"]}
                                      >
                                        {item["name"]}
                                      </p>
                                    </div>

                                    <div
                                      className="treatmentstatuscontainer events-treatments"
                                      data-occurenceid={item["_id"]}
                                      data-occurencename={item["name"]}
                                    >
                                      {!!allCauses.filter(
                                        (cause) =>
                                          cause["occurences"] != undefined &&
                                          (cause["occurences"] as Array<any>)
                                            .map((o) => o.value)
                                            .includes(item["_id"])
                                      ).length && (
                                        <div
                                          className="statusindicator"
                                          data-occurenceid={item["_id"]}
                                          data-occurencename={item["name"]}
                                        >
                                          {t("bowtie.causes_title")}:{" "}
                                          {
                                            allCauses.filter(
                                              (cause) =>
                                                cause["occurences"] !=
                                                  undefined &&
                                                (
                                                  cause[
                                                    "occurences"
                                                  ] as Array<any>
                                                )
                                                  .map((o) => o.value)
                                                  .includes(item["_id"])
                                            ).length
                                          }
                                        </div>
                                      )}
                                      {!!allConsequences.length && (
                                        <div
                                          className="statusindicator"
                                          data-occurenceid={item["_id"]}
                                          data-occurencename={item["name"]}
                                        >
                                          {t("bowtie.consequence_title")}:{" "}
                                          {
                                            allConsequences.filter(
                                              (consequence) =>
                                                consequence["occurences"] !=
                                                  undefined &&
                                                (
                                                  consequence[
                                                    "occurences"
                                                  ] as Array<any>
                                                )
                                                  .map((o) => o.value)
                                                  .includes(item["_id"])
                                            ).length
                                          }
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className="operationscontainer events-options"
                                      data-occurenceid={item["_id"]}
                                      data-occurencename={item["name"]}
                                    >
                                      <i
                                        className="dripicons-pencil tableOperationBtn"
                                        data-occurenceid={item["_id"]}
                                        data-occurencename={item["name"]}
                                        onClick={handleShowEditOccurenceModal}
                                      />
                                      <i
                                        className="dripicons-trash tableOperationBtn"
                                        data-occurenceid={item["_id"]}
                                        data-occurencename={item["name"]}
                                        onClick={(e) =>
                                          system["accesses"].includes("edit")
                                            ? handleShowDeleteOccurenceModal(e)
                                            : null
                                        }
                                      />
                                    </div>
                                    <Droppable
                                      droppableId={
                                        "occurence.middle." + item["_id"]
                                      }
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                          style={{
                                            position: "absolute",
                                            left: "0px",
                                            top: "0px",
                                            height: "120px",
                                            pointerEvents:
                                              (causeConDragging ||
                                                consequenceConDragging) &&
                                              draggingItem != undefined &&
                                              !(
                                                (draggingItem as any)[
                                                  "occurences"
                                                ] as Array<any>
                                              )
                                                .map((i) => i.value)
                                                .includes(item["_id"])
                                                ? "all"
                                                : "none",
                                            zIndex: 100,
                                            backgroundColor:
                                              (causeConDragging ||
                                                consequenceConDragging) &&
                                              draggingItem != undefined &&
                                              !(
                                                (draggingItem as any)[
                                                  "occurences"
                                                ] as Array<any>
                                              )
                                                .map((i) => i.value)
                                                .includes(item["_id"])
                                                ? "rgba(255, 255, 255, 0.5)"
                                                : "transparent",
                                            border:
                                              (causeConDragging ||
                                                consequenceConDragging) &&
                                              draggingItem != undefined &&
                                              !(
                                                (draggingItem as any)[
                                                  "occurences"
                                                ] as Array<any>
                                              )
                                                .map((i) => i.value)
                                                .includes(item["_id"])
                                                ? "3px dashed #00e600"
                                                : "none",
                                            width: "100%",
                                            clear: "both",
                                          }}
                                        >
                                          {(causeConDragging ||
                                            consequenceConDragging) &&
                                          draggingItem != undefined &&
                                          !(
                                            (draggingItem as any)[
                                              "occurences"
                                            ] as Array<any>
                                          )
                                            .map((i) => i.value)
                                            .includes(item["_id"]) ? (
                                            <div
                                              style={{
                                                height: "120px",
                                                width: "100%",
                                              }}
                                            ></div>
                                          ) : null}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </ListGroup.Item>
                                );
                              })
                          : null}
                        {occurences
                          .filter((i) => i["_id"] != occurence || lockOrder)
                          .map((item, key) => {
                            return (
                              <ListGroup.Item
                                key={key}
                                className={
                                  occurence == item["_id"]
                                    ? "selected occurenceitem"
                                    : "occurenceitem"
                                }
                                data-occurenceid={item["_id"]}
                                data-occurencename={item["name"]}
                                onClick={selectOccurence}
                              >
                                <div className="text-center occurance-container">
                                  <p
                                    className="occurence-title"
                                    data-occurenceid={item["_id"]}
                                    data-occurencename={item["name"]}
                                  >
                                    {td(
                                      occurenceTypes.find(
                                        (i) => i._id == item["type"]
                                      )?.name
                                    ) ?? ""}
                                  </p>
                                  <p
                                    className="mb-0 occurence-description"
                                    data-occurenceid={item["_id"]}
                                    data-occurencename={item["name"]}
                                  >
                                    {item["name"]}
                                  </p>
                                </div>

                                <div
                                  className="treatmentstatuscontainer events-treatments"
                                  data-occurenceid={item["_id"]}
                                  data-occurencename={item["name"]}
                                >
                                  {!!allCauses.filter(
                                    (cause) =>
                                      cause["occurences"] != undefined &&
                                      (cause["occurences"] as Array<any>)
                                        .map((o) => o.value)
                                        .includes(item["_id"])
                                  ).length ? (
                                    <div
                                      className="statusindicator causes-value"
                                      data-occurenceid={item["_id"]}
                                      data-occurencename={item["name"]}
                                    >
                                      {t("bowtie.causes_title")}:{" "}
                                      {
                                        allCauses.filter(
                                          (cause) =>
                                            cause["occurences"] != undefined &&
                                            (cause["occurences"] as Array<any>)
                                              .map((o) => o.value)
                                              .includes(item["_id"])
                                        ).length
                                      }
                                    </div>
                                  ) : (
                                    <Button
                                      className="add-new btn-with-icon"
                                      disabled={
                                        !system["accesses"]?.includes("edit") ??
                                        true
                                      }
                                      onClick={() => {
                                        setShowCauseModal(true);
                                        setOccurence(item["_id"]);
                                      }}
                                    >
                                      <i className="dripicons-plus" />
                                      Add cause
                                    </Button>
                                  )}
                                  {!!allConsequences.filter(
                                    (consequence) =>
                                      consequence["occurences"] != undefined &&
                                      (consequence["occurences"] as Array<any>)
                                        .map((o) => o.value)
                                        .includes(item["_id"])
                                  ).length ? (
                                    <div
                                      className="statusindicator consequences-value"
                                      data-occurenceid={item["_id"]}
                                      data-occurencename={item["name"]}
                                    >
                                      {t("bowtie.consequence_title")}:{" "}
                                      {
                                        allConsequences.filter(
                                          (consequence) =>
                                            consequence["occurences"] !=
                                              undefined &&
                                            (
                                              consequence[
                                                "occurences"
                                              ] as Array<any>
                                            )
                                              .map((o) => o.value)
                                              .includes(item["_id"])
                                        ).length
                                      }
                                    </div>
                                  ) : (
                                    <Button
                                      className="add-new btn-with-icon"
                                      disabled={
                                        !system["accesses"]?.includes("edit") ??
                                        true
                                      }
                                      onClick={() => {
                                        setShowConsequenceModal(true);
                                        setOccurence(item["_id"]);
                                      }}
                                    >
                                      <i className="dripicons-plus" />
                                      Add consequence
                                    </Button>
                                  )}
                                </div>
                                <div
                                  className="operationscontainer events-options"
                                  data-occurenceid={item["_id"]}
                                  data-occurencename={item["name"]}
                                >
                                  <i
                                    className="dripicons-pencil tableOperationBtn"
                                    data-occurenceid={item["_id"]}
                                    data-occurencename={item["name"]}
                                    onClick={handleShowEditOccurenceModal}
                                  />
                                  <i
                                    className="dripicons-trash tableOperationBtn"
                                    data-occurenceid={item["_id"]}
                                    data-occurencename={item["name"]}
                                    onClick={(e) =>
                                      system["accesses"].includes("edit")
                                        ? handleShowDeleteOccurenceModal(e)
                                        : null
                                    }
                                  />
                                </div>
                                <Droppable
                                  droppableId={
                                    "occurence.middle." + item["_id"]
                                  }
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={{
                                        position: "absolute",
                                        left: "0px",
                                        top: "0px",
                                        height: "120px",
                                        pointerEvents:
                                          (causeConDragging ||
                                            consequenceConDragging) &&
                                          draggingItem != undefined &&
                                          !(
                                            (draggingItem as any)[
                                              "occurences"
                                            ] as Array<any>
                                          )
                                            .map((i) => i.value)
                                            .includes(item["_id"])
                                            ? "all"
                                            : "none",
                                        zIndex: 100,
                                        backgroundColor:
                                          (causeConDragging ||
                                            consequenceConDragging) &&
                                          draggingItem != undefined &&
                                          !(
                                            (draggingItem as any)[
                                              "occurences"
                                            ] as Array<any>
                                          )
                                            .map((i) => i.value)
                                            .includes(item["_id"])
                                            ? "rgba(255, 255, 255, 0.5)"
                                            : "transparent",
                                        border:
                                          (causeConDragging ||
                                            consequenceConDragging) &&
                                          draggingItem != undefined &&
                                          !(
                                            (draggingItem as any)[
                                              "occurences"
                                            ] as Array<any>
                                          )
                                            .map((i) => i.value)
                                            .includes(item["_id"])
                                            ? "3px dashed #00e600"
                                            : "none",
                                        width: "100%",
                                        clear: "both",
                                      }}
                                    >
                                      {(causeConDragging ||
                                        consequenceConDragging) &&
                                      draggingItem != undefined &&
                                      !(
                                        (draggingItem as any)[
                                          "occurences"
                                        ] as Array<any>
                                      )
                                        .map((i) => i.value)
                                        .includes(item["_id"]) ? (
                                        <div
                                          style={{
                                            height: "120px",
                                            width: "100%",
                                          }}
                                        ></div>
                                      ) : null}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </ListGroup.Item>
                            );
                          })}
                      </ListGroup>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card>
                  <Card.Body>
                    <div className="list" data-satarget="consequences">
                      <BowtieConsequenceList
                        treatmentType="consequence"
                        allTreatments={allTreatments}
                        allOccurences={occurences}
                        occurenceId={occurence}
                        onConsequencesChanged={() => {
                          fetchData();
                        }}
                        nomenu={nomenu}
                        system={system}
                        showConsequenceModal={showConsequenceModal}
                      ></BowtieConsequenceList>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </DragDropContext>
        </div>
        <Modal
          show={showExistingOccurenceModal}
          size="lg"
          onHide={handleCloseExistingOccurenceModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("bowtie.event_existing_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PageTable
              dataUrl={generateUrl("/api/cra/occurences/getAllPaged/")}
              options={{
                pageSize: 10,
                showFields: [
                  {
                    label: t("bowtie.consequences.existing.field.type"),
                    field: "typeReadable",
                  },
                  {
                    label: t("bowtie.consequences.existing.field.description"),
                    field: "name",
                  },
                  {
                    label: t("bowtie.consequences.existing.field.system"),
                    field: "systemReadable",
                  },
                  {
                    label: t("bowtie.consequences.existing.field.orgelement"),
                    field: "orgelement",
                  },
                ],
              }}
              dataAfter={(dataLine) => (
                <Button
                  style={{ width: "130px" }}
                  onClick={() => handleCopyExistingOccurence(dataLine)}
                >
                  {t("bowtie.consequences.existing.copy")}
                </Button>
              )}
              loadingText={t("systems.loading")}
              hover={false}
              hidePagination={false}
              hideFilter={false}
              defaultSortField="name"
              defaultSortDirection="asc"
            ></PageTable>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseExistingOccurenceModal}>
              {t("diri.closebtn")}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showNewOccurenceModal}
          onHide={handleCloseNewOccurenceModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("bowtie.event_new_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("bowtie.event_new_type")} *
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                onChange={(evt) =>
                  setNewOccurenceType(
                    (evt.target as HTMLSelectElement).selectedOptions[0].value
                  )
                }
                value={newOccurenceType}
              >
                <option value="">{t("bowtie.event_new_type_choose")}</option>
                {occurenceTypes.map((item, key) => (
                  <option key={key} value={item._id}>
                    {td(item.name)}
                  </option>
                ))}
              </Form.Control>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("bowtie.event_new_description")}
                </InputGroup.Text>
              </InputGroup.Prepend>

              <Typeahead
                id="newOccurenceNameInput"
                onInputChange={newOccurenceNameChange}
                onKeyDown={handleKeyDownEventCreate}
                onChange={(items) => {
                  if (items.length > 0) {
                    if (items[0]["id"] != undefined && items[0]["id"] != "") {
                      setNewOccurenceCopyof(items[0]["id"]);
                    } else {
                      setNewOccurenceCopyof("");
                    }
                    newOccurenceNameChange(items[0]["label"]);
                  } else {
                    setNewOccurenceCopyof("");
                  }
                }}
                minLength={2}
                emptyLabel={t("bowtie.no_results")}
                options={autoCompleteOptions}
              />
            </InputGroup>

            <p>{t("bowtie.event_occurencetype_notfound_text")}</p>
            <a
              className="textlink"
              onClick={() => setShowNewOccurenceTypeModal(true)}
            >
              {t("bowtie.event_occurencetype_notfound_click")}
            </a>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel posleft"
              onClick={handleCloseNewOccurenceModal}
            >
              {t("system.system_new_cancel")}
            </Button>
            <Button
              disabled={newOccurenceType == ""}
              onClick={handleCreateOccurence}
            >
              {t("bowtie.save")}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showDeleteOccurenceModal}
          onHide={handleCloseDeleteOccurenceModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("bowtie.event_delete")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t("bowtie.causes_delete_info")}</p>
            <p>{t("bowtie.event_delete_info")}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel posleft"
              onClick={handleCloseDeleteOccurenceModal}
            >
              {t("bowtie.cancel")}
            </Button>
            <Button onClick={handleDeleteOccurence}>
              {t("bowtie.delete")}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showEditOccurenceModal}
          onHide={handleCloseEditOccurenceModal}
          onEntered={() => myRef.current.focus()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("bowtie.event_edit")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("bowtie.event_new_type")} *
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled={!system["accesses"]?.includes("edit") ?? true}
                as="select"
                onChange={(evt) => {
                  let value = (evt.target as HTMLSelectElement)
                    .selectedOptions[0].value;
                  setEditOccurence((editOccurence) => ({
                    ...editOccurence,
                    type: value,
                  }));
                }}
                value={editOccurence["type"]}
              >
                <option value="">{t("bowtie.event_new_type_choose")}</option>
                {occurenceTypes.map((item, key) => (
                  <option key={key} value={item._id}>
                    {td(item.name)}
                  </option>
                ))}
              </Form.Control>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("bowtie.event_new_description")}
                  {/* {t("bowtie.event_new_name")} */}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled={!system["accesses"]?.includes("edit") ?? true}
                ref={myRef}
                onKeyDown={handleKeyDownEventEdit}
                onChange={(evt) => setEditOccurenceName(evt.target.value)}
                value={editOccurence["name"]}
              />
            </InputGroup>

            {system["accesses"]?.includes("edit") ?? false ? (
              <div>
                <p>{t("bowtie.event_occurencetype_notfound_text")}</p>
                <a
                  className="textlink"
                  onClick={() => setShowNewOccurenceTypeModal(true)}
                >
                  {t("bowtie.event_occurencetype_notfound_click")}
                </a>
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel posleft"
              onClick={handleCloseEditOccurenceModal}
            >
              {t("system.system_new_cancel")}
            </Button>
            <Button
              disabled={
                editOccurence["type"] == "" ||
                editOccurence["type"] == undefined ||
                (!system["accesses"]?.includes("edit") ?? true)
              }
              onClick={handleEditOccurence}
            >
              {t("bowtie.save")}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showNewOccurenceTypeModal}
          onHide={() => setShowNewOccurenceTypeModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("bowtie.eventtype.new_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="alert red">{t("bowtie.eventtype.disclaimer01")}</p>
            <p>{t("bowtie.eventtype.disclaimer02")}</p>
            <div className="widecheckbox">
              <Field
                type="checkbox"
                title={t("bowtie.eventtype.disclaimer03")}
                value={newOccurenceTypeConfirm}
                onChange={(value) => setNewOccurenceTypeConfirm(value)}
              ></Field>
            </div>
            <div style={{ height: "80px" }}>
              <Field
                type="textlanguage"
                title={t("bowtie.eventtype.name") + " *"}
                disabled={!newOccurenceTypeConfirm}
                value={newOccurenceTypeName}
                onChange={(value) => setNewOccurenceTypeName(value)}
              ></Field>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel posleft"
              onClick={() => setShowNewOccurenceTypeModal(false)}
            >
              {t("system.system_new_cancel")}
            </Button>
            <Button
              disabled={!newOccurenceTypeConfirm}
              onClick={handleCreateOccurenceType}
            >
              {t("bowtie.save")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Bowtie;
