import * as React from "react";

import { useState } from "react";
import { Card, Button, Modal } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";
import moment from "moment";

import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";

import PageTable from "../../Shared/PageTable/PageTable";
import SiteAssistant from "../../Shared/SiteAssistant/SiteAssistant";
import DiriFaceDocked from "../../../Assets/Images/diriassistant/diri.svg";
import TreatmentEditorModal from "../../Shared/Modals/Edit/TreatmentEditorModal";
import CreateTreatmentModal from "../../Shared/Modals/Create/CreateTreatmentModal";
import FilterSelector from "../../Shared/FilterSelector/FilterSelector";

import { generateUrl } from "../../../config";

import "react-toastify/dist/ReactToastify.css";
import "./Treatments.scss";

function Treatments() {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();

  const localStorageUser = localStorage.getItem("user");
  let settings = {};

  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  const [editTreatmentId, setEditTreatmentId] = useState();
  const [showEditTreatmentModal, setShowEditTreatmentModal] = useState(false);
  const [deleteTreatment, setDeleteTreatment] = useState({});
  const [showDeleteTreatmentModal, setShowDeleteTreatmentModal] = useState(
    false
  );
  const [editTreatmentSystemId, setEditTreatmentSystemId] = useState();
  const [
    showAddGlobalTreatmentModal,
    setShowAddGlobalTreatmentModal,
  ] = useState(false);
  const [treatmentsFilter, setTreatmentsFilter] = useState([]);

  const [updateTable, setUpdateTable] = useState(0);
  const removeTreatment = t("treatment.delete");
  const treatmentName = t("treatment.name");
  const treatmentCost = t("treatment.cost");
  const treatmentOnetimeCost = t("treatment.onetimecost");
  const treatmentStatus = t("treatment.status");
  const treatmentResponsible = t("treatment.responsible");

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const handleShowEditTreatmentModal = (dataLine) => {
    setEditTreatmentId(dataLine["_id"]);
    setEditTreatmentSystemId(dataLine["system"]);
    setShowEditTreatmentModal(true);
  };

  const handleShowAddGlobalTreatmentModal = () => {
    setShowAddGlobalTreatmentModal(true);
  };

  const handleShowDeleteTreatmentModal = (dataLine, evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    setDeleteTreatment(dataLine._id);
    setShowDeleteTreatmentModal(true);
  };

  const handleDeleteTreatment = () => {
    axios
      .delete(
        generateUrl("/api/cra/treatments/") + deleteTreatment,
        {
          name: deleteTreatment,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        notifySuccess(t("bowtie.toast_treatment_deleted"));
        setShowDeleteTreatmentModal(false);
        setUpdateTable(updateTable + 1);
      });
  };

  return (
    <div className="maincontent">
      <SiteAssistant dockedLogo={DiriFaceDocked} featurekey="treatments" />
      <div
        className="systems-container"
        key={generateUrl("/api/cra/treatments/getAllPaged/")}
      >
        <div className="pagetitle-container">
          <h1>{t("treatment.page_name")}</h1>
        </div>
        <Card>
          <Card.Body>
            <Button
              onClick={handleShowAddGlobalTreatmentModal}
              disabled={
                auth.user["userdata"]?.["actualAccessRole"]?.["rules"]?.find(
                  (rule) =>
                    rule.objType == "treatment" &&
                    rule.objSubType == "treatment"
                )?.actions.create != "allow"
              }
            >
              {t("treatment.add_treatment")}
            </Button>
            <br />
            <br />
            <FilterSelector
              filtersUrl={generateUrl("/api/cra/filters/bytype/")}
              filterType="treatments"
              fields={[
                {
                  label: treatmentResponsible,
                  value: "responsibleReadable",
                  type: "string",
                },
                {
                  label: treatmentOnetimeCost,
                  value: "onetimecost",
                  type: "string",
                },
                { label: treatmentCost, value: "cost", type: "string" },
                {
                  label: treatmentStatus,
                  value: "status",
                  type: "select",
                  choices: [
                    { label: t("treatment.open"), value: "open" },
                    { label: t("treatment.suggested"), value: "suggested" },
                    { label: t("treatment.planned"), value: "planned" },
                    { label: t("treatment.processing"), value: "processing" },
                    { label: t("treatment.closed"), value: "closed" },
                  ],
                },
                { label: t("treatment.created"), value: "date", type: "date" },
                {
                  label: t("treatment.duedate"),
                  value: "duetime",
                  type: "date",
                },
                {
                  label: t("treatment.isSharedWithOrg"),
                  value: "isSharedWithOrg",
                  type: "select",
                  choices: [
                    { label: t("treatment.yes"), value: true },
                    { label: t("treatment.no"), value: false },
                  ],
                },
                {
                  label: t("treatment.isTreatmentOwner"),
                  value: "isTreatmentOwner",
                  type: "select",
                  choices: [
                    { label: t("treatment.yes"), value: true },
                    { label: t("treatment.no"), value: false },
                  ],
                },
                {
                  label: t("treatment.sharedWithMe"),
                  value: "sharedWithMe",
                  type: "select",
                  choices: [
                    { label: t("treatment.yes"), value: true },
                    { label: t("treatment.no"), value: false },
                  ],
                },
                {
                  label: t("treatment.isOrgTreatment"),
                  value: "isOrgTreatment",
                  type: "select",
                  choices: [
                    { label: t("treatment.yes"), value: true },
                    { label: t("treatment.no"), value: false },
                  ],
                },
                {
                  label: t("treatment.isTreatmentResponsible"),
                  value: "isTreatmentResponsible",
                  type: "select",
                  choices: [
                    { label: t("treatment.yes"), value: true },
                    { label: t("treatment.no"), value: false },
                  ],
                },
                {
                  label: t("treatment.missingOwner"),
                  value: "missingOwner",
                  type: "select",
                  choices: [
                    { label: t("treatment.yes"), value: true },
                    { label: t("treatment.no"), value: false },
                  ],
                },
                {
                  label: t("treatment.currentOrgIsOwnerOrg"),
                  value: "currentOrgIsOwnerOrg",
                  type: "select",
                  choices: [
                    { label: t("treatment.yes"), value: true },
                    { label: t("treatment.no"), value: false },
                  ],
                },
              ]}
              onChange={(filter) => {
                setTreatmentsFilter(filter.filters);
              }}
            ></FilterSelector>
            <PageTable
              dataUrl={generateUrl("/api/cra/treatments/getAllPaged/")}
              options={{
                showFields: [
                  { label: treatmentName, field: "name" },
                  { label: treatmentResponsible, field: "responsibleReadable" },
                  {
                    label: treatmentOnetimeCost,
                    field: "onetimecost",
                    override: (dataLine) =>
                      dataLine["onetimecost"] != undefined &&
                      dataLine["onetimecost"] != ""
                        ? settings["currencySymbolBefore"] +
                          dataLine["onetimecost"] +
                          settings["currencySymbolAfter"]
                        : "",
                  },
                  {
                    label: treatmentCost,
                    field: "cost",
                    override: (dataLine) =>
                      dataLine["cost"] != undefined && dataLine["cost"] != ""
                        ? settings["currencySymbolBefore"] +
                          dataLine["cost"] +
                          settings["currencySymbolAfter"]
                        : "",
                  },
                  {
                    label: treatmentStatus,
                    field: "status",
                    override: (dataLine) =>
                      t("treatment." + dataLine["status"]),
                    customFilters: [
                      { label: t("treatment.open"), value: "open" },
                      { label: t("treatment.suggested"), value: "suggested" },
                      { label: t("treatment.planned"), value: "planned" },
                      { label: t("treatment.processing"), value: "processing" },
                      { label: t("treatment.closed"), value: "closed" },
                    ],
                  },
                  {
                    label: t("treatment.systems_affected"),
                    field: "affectedSystems",
                    override: (dataLine) => {
                      if (dataLine["affectedSystems"] == "deleted") {
                        return t(
                          "treatments.modal_treatment_edit.deleted_system"
                        );
                      } else if (dataLine["affectedSystems"] == "no_access") {
                        return t("treatments.no_system_access");
                      } else {
                        return dataLine["affectedSystems"];
                      }
                    },
                  },
                  {
                    label: t("treatment.created"),
                    field: "dateReadable",
                    override: (dataLine) => {
                      let date = new Date(
                        new Date(dataLine["date"]).getTime() +
                          new Date(dataLine["date"]).getTimezoneOffset() / 60
                      ).getTime();

                      let dateYear = new Date(date).getFullYear();
                      let dateMonth = new Date(date).getMonth() + 1;
                      let dateDay = new Date(date).getDate();
                      let dateHour = new Date(date).getHours();
                      let dateMinute = new Date(date).getMinutes();

                      return (
                        dateYear +
                        "-" +
                        (dateMonth.toString().length == 1
                          ? "0" + dateMonth
                          : dateMonth) +
                        "-" +
                        (dateDay.toString().length == 1
                          ? "0" + dateDay
                          : dateDay) +
                        " " +
                        (dateHour.toString().length == 1
                          ? "0" + dateHour
                          : dateHour) +
                        ":" +
                        (dateMinute.toString().length == 1
                          ? "0" + dateMinute
                          : dateMinute)
                      );
                    },
                  },
                  {
                    label: t("treatment.duedate"),
                    field: "duetime",
                    override: (dataLine) =>
                      dataLine["duetime"] != undefined
                        ? moment(dataLine["duetime"]).format("yyyy-MM-DD") +
                          " " +
                          moment(dataLine["duetime"]).format("HH:mm")
                        : "",
                    disableFilter: true,
                  },
                ],
                pageSize: 15,
              }}
              dataAfter={(dataLine) => (
                <Button
                  disabled={!dataLine.accesses.includes("delete")}
                  onClick={(evt) =>
                    handleShowDeleteTreatmentModal(dataLine, evt)
                  }
                >
                  {" "}
                  Delete{" "}
                </Button>
              )}
              onLineClick={handleShowEditTreatmentModal}
              hidePagination={false}
              hidePagesize={false}
              hideFilter={false}
              updateTable={updateTable}
              hover={true}
              defaultSortField="name"
              defaultSortDirection="asc"
              permaFilters={treatmentsFilter}
            />
          </Card.Body>
        </Card>
        <CreateTreatmentModal
          show={showAddGlobalTreatmentModal}
          onHide={() => {
            setShowAddGlobalTreatmentModal(false);
          }}
          treatmentType="global"
          onSave={() => {
            setUpdateTable(updateTable + 1);
          }}
        ></CreateTreatmentModal>
        <TreatmentEditorModal
          show={showEditTreatmentModal}
          onSave={() => {
            setShowEditTreatmentModal(false);
            setUpdateTable(updateTable + 1);
          }}
          onHide={() => {
            setShowEditTreatmentModal(false);
            setEditTreatmentId(undefined);
          }}
          onCancel={() => {
            setShowEditTreatmentModal(false);
            setEditTreatmentId(undefined);
          }}
          treatmentId={editTreatmentId}
          systemid={editTreatmentSystemId}
        ></TreatmentEditorModal>
        <Modal
          show={showDeleteTreatmentModal}
          onHide={() => setShowDeleteTreatmentModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("bowtie.delete_measure")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t("bowtie.delete_info")}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel posleft"
              onClick={() => setShowDeleteTreatmentModal(false)}
            >
              {t("bowtie.cancel")}
            </Button>
            <Button onClick={handleDeleteTreatment}>{removeTreatment}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Treatments;
