import React from 'react';

function TextTitle(props) {
    return(
        props.options != undefined && props.options.headertag != undefined ?
            React.createElement(props.options.headertag, {}, props.text)
        :
            <h3>{props.text}</h3>
    );
}

export default TextTitle;