import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { I18n } from "react-polyglot";
import languages from "./Assets/Language/Langloader";

import MainMenu from "./Components/Shared/MainMenu/MainMenu";
import Dashboard from "./Components/Pages/Dashboard/Dashboard";
import LastEvents from "./Components/Pages/LastEvents/LastEvents";
import Users from "./Components/Pages/Users/Users";
import Organisation from "./Components/Pages/Organisation/Organisation";
import Filearchive from "./Components/Pages/Filearchive/Filearchive";
import Login from "./Components/Pages/Login/Login";
import ForgotPassword from "./Components/Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Components/Pages/ResetPassword/ResetPassword";
import PrivateRoute from "./PrivateRoute";
import { ProvideAuth } from "./Auth";
import Systems from "./Components/Pages/Systems/Systems";
import Import from "./Components/Pages/Systems/Import/Import";
import System from "./Components/Pages/Systems/System/System";
import Bowtie from "./Components/Pages/Systems/System/Bowtie/Bowtie";
import ValueEvaluation from "./Components/Pages/Systems/System/ValueEvaluation/ValueEvaluation";
import SystemDescription from "./Components/Pages/Systems/System/SystemDescription/SystemDescription";
import Treatments from "./Components/Pages/Treatments/Treatments";
import Tasks from "./Components/Pages/Tasks/Tasks";
import Newuser from "./Components/Pages/Newuser/Newuser";
import Settings from "./Components/Pages/Settings/Settings";
import LocaleHandler from "./LocaleHandler";
import AnalysisOverview from "./Components/Pages/Analysis/Overview/AnalysisOverview";
import Analysis from "./Components/Pages/Analysis/AnalysisProgress/Analysis";
import AnalysisEditor from "./Components/Pages/Analysis/AnalysisEditor/AnalysisEditor";
import { ProvideMsalAuth } from "./AuthProvider";
import MyAccount from "./Components/Pages/MyAccount/MyAccount";
import { useAxios } from "./AxiosHandler";
import Actionplan from "./Components/Pages/Systems/System/Actionplan/Actionplan";
import Complete from "./Components/Pages/Systems/System/Complete/Complete";
import Processes from "./Components/Pages/Processes/Processes";
import VersionManager from "./Components/Shared/VersionManager/VersionManager";
import RiskRegistry from "./Components/Pages/RiskRegistry/RiskRegistry";
import ValueRegistry from "./Components/Pages/ValueRegistry/ValueRegistry";
import SystemSummary from "./Components/Pages/Systems/System/SystemSummary/SystemSummary";
import ShopPage from "./Components/Pages/ShopPage/ShopPage";
import { ProvideDynamicLanguage } from "./DynamicLanguageProvider";
import Onboarding from "./Components/Pages/Onboarding/Onboarding";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const reCaptchaProps = {
  reCaptchaKey: "6Lfzw8YlAAAAALqD3IAmsYBzN01XUc9-RkeBf82w",
};

export default function CRA(props) {
  let localeTemp = "en";
  if (localStorage.getItem("user") != null) {
    let localUser = localStorage.getItem("user");
    if (localUser != null) {
      localUser = JSON.parse(localUser);
      if (localUser != null) {
        if (localUser["userdata"]["language"] != undefined) {
          localeTemp = localUser["userdata"]["language"].toLowerCase();
        }
        if (localUser["userdata"]["actualAccessRole"] == undefined) {
          localStorage.clear();
        }
      }
    }
  }
  let languageTemp: any = {};
  languageTemp = languages(localeTemp);

  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;

  const [locale, setLocale] = React.useState(localeTemp);
  const [messages, setMessages] = React.useState(languageTemp);
  const [menuIsOpen, setMenuIsOpen] = React.useState(
    document.documentElement.clientWidth < 1100 ? false : true
  );

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let urllang = params.get("lang");

    if (localStorage.getItem("user") != null) {
      let localUser = localStorage.getItem("user");
      if (localUser != null) {
        localUser = JSON.parse(localUser);
        if (localUser != null) {
          if (localUser["userdata"]["language"] != undefined) {
            if (!urllang) {
              setLocale(localUser["userdata"]["language"].toLowerCase());
            } else {
              setLocale(urllang);
            }
          }
        }
      }
    }
    setMessages(languages(locale));
  }, [locale]);

  return (
    <LocaleHandler.Provider value={{ locale, setLocale }}>
      <ProvideAuth>
        <ProvideMsalAuth>
          <I18n locale={locale} messages={messages}>
            <ProvideDynamicLanguage locale={locale}>
              <GoogleReCaptchaProvider {...reCaptchaProps}>
                <React.Fragment>
                  <Router>
                    <div className={menuIsOpen ? "openMenu" : "closedMenu"}>
                      <ToastContainer />
                      <MainMenu
                        isOpen={(val) => {
                          setMenuIsOpen(val);
                        }}
                      />
                      <Switch>
                        <PrivateRoute exact path="/" component={Dashboard} />
                        <Route path="/newuser">
                          <Newuser />
                        </Route>
                        <Route path="/login">
                          <Login />
                        </Route>
                        <Route path="/forgot-password">
                          <ForgotPassword />
                        </Route>
                        <Route path="/reset-password">
                          <ResetPassword />
                        </Route>
                        <Route path="/shop">
                          <ShopPage />
                        </Route>
                        <Route path="/loggingin">
                          <p
                            style={{ textAlign: "center", marginTop: "100px" }}
                          >
                            Logging in ..
                          </p>
                        </Route>
                        <PrivateRoute path="/events" component={LastEvents} />
                        <PrivateRoute path="/systems" component={Systems} />
                        <PrivateRoute
                          path="/import/systems"
                          component={Import}
                        />
                        <PrivateRoute
                          path="/system/systemdesc/:systemid"
                          component={SystemDescription}
                        />
                        <PrivateRoute
                          path="/system/valueeval/:systemid"
                          component={ValueEvaluation}
                        />
                        <PrivateRoute
                          path="/system/bowtie/:systemid"
                          component={Bowtie}
                        />
                        <PrivateRoute
                          path="/system/actionplan/:systemid"
                          component={Actionplan}
                        />
                        <PrivateRoute
                          path="/system/complete/:systemid"
                          component={Complete}
                        />
                        <PrivateRoute
                          path="/system/systemsummary/:systemid"
                          component={SystemSummary}
                        />
                        <PrivateRoute
                          path="/system/:systemid"
                          component={System}
                        />
                        <PrivateRoute path="/users" component={Users} />
                        <PrivateRoute
                          path="/organisation"
                          component={Organisation}
                        />
                        <PrivateRoute path="/files" component={Filearchive} />
                        <PrivateRoute path="/tasks" component={Tasks} />
                        <PrivateRoute
                          path="/treatments"
                          component={Treatments}
                        />
                        {/* <PrivateRoute path="/processes" component={Processes} /> */}
                        <PrivateRoute
                          path="/valueregistry"
                          component={ValueRegistry}
                        />
                        <PrivateRoute path="/settings" component={Settings} />
                        <PrivateRoute
                          path="/analysislist"
                          component={AnalysisOverview}
                        />
                        <PrivateRoute
                          path="/analysiseditor"
                          component={AnalysisEditor}
                        />
                        <PrivateRoute path="/analysis" component={Analysis} />
                        <PrivateRoute path="/myaccount" component={MyAccount} />
                        <PrivateRoute
                          path="/riskregistry"
                          component={RiskRegistry}
                        />
                        <Route path="/welcome" component={Onboarding} />
                        {/* <PrivateRoute path="/shop" component={ShopPage} /> */}
                      </Switch>
                    </div>
                  </Router>
                  <VersionManager></VersionManager>
                </React.Fragment>
              </GoogleReCaptchaProvider>
            </ProvideDynamicLanguage>
          </I18n>
        </ProvideMsalAuth>
      </ProvideAuth>
    </LocaleHandler.Provider>
  );
}
