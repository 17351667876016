import React, { useEffect } from 'react';
import { InputGroup } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

function AutocompleteWithId(props) {
    const handleChange = (value) => {
        if (props.onChange != undefined) {
            props.onChange(value);
        }
    }

    useEffect(() => {

    }, [props.options.list]);

    return(
        <InputGroup className="mb-3 autocomplete">
            <InputGroup.Prepend>
                <InputGroup.Text>{props.title}</InputGroup.Text>
            </InputGroup.Prepend>
            <Typeahead
                id={"autocompleterwithid-" + props.title}
                disabled={props.disabled}
                onInputChange={val => {handleChange(val)}}
                onChange={items => {
                    if (items.length > 0) {
                        handleChange(items[0]["value"]);
                    }
                }}
                defaultInputValue={props.value != undefined && props.value != "" && props.options.data.filter(item => item["_id"] == props.value)[0] ? 
                    props.options.data.filter(item => item["_id"] == props.value)[0].name : 
                    (props.defaultValue != undefined && props.options.data.filter(item => item["_id"] == props.defaultValue)[0] != undefined ? 
                        props.options.data.filter(item => item["_id"] == props.defaultValue)[0].name : 
                        props.value != undefined ? 
                            props.value : 
                            "")}
                minLength={props.options != undefined && props.options.minlength != undefined ? props.options.minlength : 2}
                emptyLabel={props.options != undefined && props.options.noresults != undefined ? props.options.noresults : ""}
                options={props.options != undefined ? props.options.list : []}
            />
        </InputGroup>
    );
}

export default AutocompleteWithId;