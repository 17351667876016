import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { Card, Button, Modal, InputGroup, Form } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";

import "../../Systems";
import PageTable from "../../../../Shared/PageTable/PageTable";
import SiteAssistant from "../../../../Shared/SiteAssistant/SiteAssistant";
import DiriFaceDocked from "../../../../../Assets/Images/diriassistant/diri.svg";
import Field from "../../../../Shared/Wizard/Field/Field";
import Wizard from "../../../../Shared/Wizard/Wizard";
import WizardFieldManipulator from "../../../../Shared/WizardHelpers/WizardFieldManipulator";
import SystemValueTemplate from "../../../../Shared/WizardTemplates/SystemValues/SystemValueTemplate";
import { useDynamicLanguage } from "../../../../../DynamicLanguageProvider";

import { generateUrl } from "../../../../../config";

import "react-toastify/dist/ReactToastify.css";
import "./ValueEvaluation.scss";

function ValueEvaluation(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  let history = useHistory();
  const t = useTranslate();
  const myRef = React.useRef<any>();
  const td = useDynamicLanguage().td;

  const [updateTable, setUpdateTable] = useState(0);

  const GetWizardFieldByReference =
    WizardFieldManipulator.GetWizardFieldByReference;
  const GetWizardValuesByReferenceList =
    WizardFieldManipulator.GetWizardValuesByReferenceList;

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  let { systemid } = useParams();

  const assetName = t("system_asset.asset_name");
  const valueType = t("system_asset.system_value_type");
  const confidentiality = t("system_asset.system_confidential");
  const integrity = t("system_asset.system_integrity");
  const availability = t("system_asset.system_availability");
  const reputation = t("system_asset.system_reputation");
  const economic = t("system_asset.system_economic");

  const [showNewModal, setShowNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [system, setSystem] = useState([{}]);
  const [systemvalues, setSystemvalues] = useState([{}]);

  const [newSystemvalueName, setNewSystemvalueName] = useState("");
  const [newSystemvalueDescription, setNewSystemvalueDescription] = useState(
    ""
  );
  const [newSystemvalueReasoning, setNewSystemvalueReasoning] = useState("");
  const [newSystemvalueSensitivepo, setNewSystemvalueSensitivepo] = useState(
    false
  );
  const [newSystemValueAvailability, setNewSystemValueAvailability] = useState(
    0
  );
  const [newSystemvalueK, setNewSystemvalueK] = useState("");
  const [newSystemvalueI, setNewSystemvalueI] = useState("");
  const [newSystemvalueT, setNewSystemvalueT] = useState("");
  const [newSystemvalueO, setNewSystemvalueO] = useState("");
  const [newSystemvalueE, setNewSystemvalueE] = useState("");

  const [newSystemValueType, setNewSystemValueType] = useState("");

  const [editSystemvalue, setEditSystemvalue] = useState<any>();

  const [deleteSystemvalue, setDeleteSystemvalue] = useState({});

  const [assistantGotoKey, setAssistantGotoKey] = useState("");

  const customValueTypes = [
    "systemvaluecommunication",
    "systemvaluecompanydata",
    "systemvalueeconomy",
    "systemvaluehardware",
    "systemvalueotherinfo",
    "systemvalueotherlawinfo",
    "systemvaluepersoninformation",
    "systemvalueusernamepassword",
  ];

  const customValueTypesMappings = {
    Kommunikasjonsdata: "systemvaluecommunication",
    Virksomhetsdata: "systemvaluecompanydata",
    "Økonomiske og finansielle data": "systemvalueeconomy",
    "Infrastruktur og hardware": "systemvaluehardware",
    "Annen verdifull informasjon i systemet": "systemvalueotherinfo",
    "Andre verdier i systemet": "systemvalueotherinfo",
    "Informasjon underlagt annet lovverk": "systemvalueotherlawinfo",
    Personopplysninger: "systemvaluepersoninformation",
    "Brukerdatabase med passord": "systemvalueusernamepassword",

    "Communication data": "systemvaluecommunication",
    "Information subject to other legislation": "systemvalueotherlawinfo",
    "Financial information": "systemvalueeconomy",
    "Business information": "systemvaluecompanydata",
    "Other assets in the system": "systemvalueotherinfo",
    "Infrastructure and Hardware": "systemvaluehardware",
    "Personal identifiable information": "systemvaluepersoninformation",
    "Username and password": "systemvalueusernamepassword",
  };

  const handleKeyDownCreate = (event) => {
    if (event.key === "Enter") {
      handleCreateSystemvalue();
    }
  };

  const handleKeyDownEdit = (event) => {
    if (event.key === "Enter") {
      handleEditSystemvalue();
    }
  };

  const handleBackSystems = (evt) => {
    history.push("/system/" + system["_id"]);
    //history.goBack();
  };

  function cleanNewForm() {
    setNewSystemvalueDescription("");
    setNewSystemvalueReasoning("");
  }

  function valueToPercentage(value, maxvalue) {
    let isNull =
      value == undefined || value == null || value.length == 0
        ? 1
        : parseInt(value);
    return ((isNull - 1) / (maxvalue - 1)) * 100;
  }

  const percentageToValue = (percentage, maxvalue) => {
    return Math.round((maxvalue - 1) * (percentage / 100) + 1);
  };

  const handleStartEditingSystem = (dataLine) => {
    if (
      !customValueTypes.includes(dataLine["valueType"]) &&
      customValueTypesMappings[dataLine["valueType"]] != undefined
    ) {
      dataLine["valueType"] = customValueTypesMappings[dataLine["valueType"]];
    }

    let confidentialityField = GetWizardFieldByReference(
      dataLine["regdata"],
      "confidentiality"
    );
    if (
      confidentialityField != undefined &&
      confidentialityField.options != undefined &&
      confidentialityField.options.choices != undefined
    ) {
      confidentialityField.options.choices = getSystemvalueOptionsByKIT("K");
    }

    let integrityField = GetWizardFieldByReference(
      dataLine["regdata"],
      "integrity"
    );
    if (
      integrityField != undefined &&
      integrityField.options != undefined &&
      integrityField.options.choices != undefined
    ) {
      integrityField.options.choices = getSystemvalueOptionsByKIT("I");
    }

    let values = GetWizardValuesByReferenceList(dataLine["regdata"], [
      "description",
      "reasoning",
      "createitembool",
      "reasoning_not",
      "components",
      "costestimate",
      "confidentiality",
      "integrity",
      "communication_data",
      "business_data",
      "financial_data",
      "title",
      "othercategory",
      "OtherLaws",
      "select_otherlaws",
      "sensitivepo",
      "personal_information",
      "ssn",
      "personal_information_category",
      "consent",
      "legalauthority",
      "datatransferred",
      "data_processor_agreement",
      "data_processor_agreement_reference",
      "data_processor_agreement_expiration",
      "accounts_and_passwords_in_database",
    ]);

    // Getting values by location in wizard template due to missing references in existing data.
    if (
      dataLine["valueType"] == "systemvaluehardware" &&
      values["components"] == undefined
    )
      values["components"] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][5]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][3]?.value ?? undefined;
    if (
      dataLine["valueType"] == "systemvaluehardware" &&
      values["costestimate"] == undefined
    )
      values["costestimate"] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][9]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][7]?.value ?? undefined;

    if (
      dataLine["valueType"] == "systemvaluecommunication" &&
      values["communication_data"] == undefined
    )
      values["communication_data"] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][5]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][3]?.value ?? undefined;

    if (
      dataLine["valueType"] == "systemvaluecompanydata" &&
      values["business_data"] == undefined
    )
      values["business_data"] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][5]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][3]?.value ?? undefined;

    if (
      dataLine["valueType"] == "systemvalueeconomy" &&
      values["financial_data"] == undefined
    )
      values["financial_data"] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][5]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][3]?.value ?? undefined;

    if (
      dataLine["valueType"] == "systemvalueotherinfo" &&
      values["othercategory"] == undefined
    )
      values["othercategory"] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][6]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][4]?.value ?? undefined;

    if (
      dataLine["valueType"] == "systemvalueotherlawinfo" &&
      values["select_otherlaws"] == undefined
    )
      values["select_otherlaws"] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][5]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][3]?.value ?? undefined;

    if (
      dataLine["valueType"] == "systemvaluepersoninformation" &&
      values["personal_information"] == undefined
    )
      values["personal_information"] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][5]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][3]?.value ?? undefined;
    if (
      dataLine["valueType"] == "systemvaluepersoninformation" &&
      values["ssn"] == undefined
    )
      values["ssn"] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][6]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][4]?.value ?? undefined;
    if (
      dataLine["valueType"] == "systemvaluepersoninformation" &&
      values["personal_information_category"] == undefined
    )
      values[
        "personal_information_category"
      ] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][9]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][7]?.value ?? undefined;
    if (
      dataLine["valueType"] == "systemvaluepersoninformation" &&
      values["consent"] == undefined
    )
      values["consent"] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][15]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][13]?.value ?? undefined;
    if (
      dataLine["valueType"] == "systemvaluepersoninformation" &&
      values["legalauthority"] == undefined
    )
      values["legalauthority"] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][16]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][14]?.value ?? undefined;
    if (
      dataLine["valueType"] == "systemvaluepersoninformation" &&
      values["datatransferred"] == undefined
    )
      values["datatransferred"] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][17]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][15]?.value ?? undefined;
    if (
      dataLine["valueType"] == "systemvaluepersoninformation" &&
      values["data_processor_agreement"] == undefined
    )
      values["data_processor_agreement"] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][19]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][17]?.value ?? undefined;
    if (
      dataLine["valueType"] == "systemvaluepersoninformation" &&
      values["data_processor_agreement_reference"] == undefined
    )
      values[
        "data_processor_agreement_reference"
      ] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][20]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][18]?.value ?? undefined;
    if (
      dataLine["valueType"] == "systemvaluepersoninformation" &&
      values["data_processor_agreement_expiration"] == undefined
    )
      values[
        "data_processor_agreement_expiration"
      ] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][21]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][19]?.value ?? undefined;

    if (
      dataLine["valueType"] == "systemvalueusernamepassword" &&
      values["accounts_and_passwords_in_database"] == undefined
    )
      values[
        "accounts_and_passwords_in_database"
      ] = WizardFieldManipulator.HasReference(
        dataLine["regdata"],
        "reasoning_not"
      )
        ? dataLine["regdata"][0]["fields"][6]?.value ?? undefined
        : dataLine["regdata"][0]["fields"][4]?.value ?? undefined;

    SystemValueTemplate(t, axios, auth, dataLine["valueType"]).then(
      (newTemplate) => {
        if (newTemplate != undefined) {
          WizardFieldManipulator.SetWizardValuesByReferenceValues(
            values,
            newTemplate
          );
          dataLine["regdata"] = newTemplate;
        }

        if (!system["accesses"].includes("edit")) {
          for (const data of dataLine["regdata"]) {
            data.fields = data.fields.map((field) => {
              return { ...field, disabled: true };
            });
          }
        }

        setEditSystemvalue(dataLine);
        setShowEditModal(true);
      }
    );
  };

  const handleStartDeleteSystem = (dataLine, evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    //setDeleteSystemvalue(systemvalues.filter(item => item["_id"] == dataLine["_id"])[0]);
    setDeleteSystemvalue(dataLine);
    setShowDeleteModal(true);
  };

  function handleAssetLevelOptions(asset) {
    switch (asset) {
      case "confidentiality":
        let optionsK = Array();
        if (settings["systemvalueKNum"] != undefined) {
          for (let i = 1; i <= parseInt(settings["systemvalueKNum"]); i++) {
            optionsK.push(
              <option key={asset + i} value={i}>
                {td(settings["systemvalueKLevel" + i])}
              </option>
            );
          }
          return optionsK;
        }

      case "integrity":
        let optionsI = Array();
        if (settings["systemvalueINum"] != undefined) {
          for (let i = 1; i <= parseInt(settings["systemvalueINum"]); i++) {
            optionsI.push(
              <option key={asset + i} value={i}>
                {td(settings["systemvalueILevel" + i])}
              </option>
            );
          }
          return optionsI;
        }

      case "availability":
        let optionsT = Array();
        if (settings["systemvalueTNum"] != undefined) {
          for (let i = 1; i <= parseInt(settings["systemvalueTNum"]); i++) {
            optionsT.push(
              <option key={asset + i} value={i}>
                {td(settings["systemvalueTLevel" + i])}
              </option>
            );
          }
          return optionsT;
        }

      case "reputation":
        let optionsO = Array();
        if (settings["systemvalueONum"] != undefined) {
          for (let i = 1; i <= parseInt(settings["systemvalueONum"]); i++) {
            optionsO.push(
              <option key={asset + i} value={i}>
                {settings["systemvalueOLevel" + i]}
              </option>
            );
          }
          return optionsO;
        }

      case "economic":
        let optionsE = Array();
        if (settings["systemvalueENum"] != undefined) {
          for (let i = 1; i <= parseInt(settings["systemvalueENum"]); i++) {
            optionsE.push(
              <option key={asset + i} value={i}>
                {settings["systemvalueELevel" + i]}
              </option>
            );
          }
          return optionsE;
        }
    }
  }

  function getSystemvalueOptionsByKIT(kit) {
    let options = Array();
    if (settings["systemvalue" + kit + "Num"] != undefined) {
      for (
        let i = 1;
        i <= parseInt(settings["systemvalue" + kit + "Num"]);
        i++
      ) {
        options.push({
          label: settings["systemvalue" + kit + "Level" + i],
          value: i,
        });
      }
      return options;
    }
  }

  const handleEditSystemvalue = () => {
    if (editSystemvalue["name"] == "") {
      notifyWarning(t("bowtie.toast_empty_name"));
      return undefined;
    }
    if (editSystemvalue["name"].length < 3) {
      notifyWarning(t("bowtie.toast_length"));
      return undefined;
    }
    //if (editSystemvalue["valueType"] == "") { notifyWarning(t("system_asset.toast_assettype")); return undefined; }

    if (
      editSystemvalue["regdata"] != undefined &&
      editSystemvalue["regdata"].length > 0
    ) {
      let values = GetWizardValuesByReferenceList(editSystemvalue["regdata"], [
        "title",
        "description",
        "reasoning",
        "confidentiality",
        "integrity",
        "sensitivepo",
        "createitembool",
      ]);

      axios
        .put(
          generateUrl("/api/cra/systemvalues/") + editSystemvalue["_id"],
          {
            name:
              values.title != undefined
                ? values.title
                : editSystemvalue["name"],
            valueType: editSystemvalue["valueType"],
            system: system["_id"],
            description:
              values.description != undefined ? values.description : "",
            reasoning: values.reasoning != undefined ? values.reasoning : "",
            sensitivepo:
              values.sensitivepo != undefined
                ? values.sensitivepo != ""
                  ? values.sensitivepo
                  : false
                : editSystemvalue["sensitivepo"] != null
                ? editSystemvalue["sensitivepo"]
                : false,
            relevant: values.createitembool,
            k:
              values.confidentiality != undefined
                ? valueToPercentage(
                    Number(values.confidentiality),
                    settings["systemvalueKNum"]
                  )
                : "",
            i:
              values.integrity != undefined
                ? valueToPercentage(
                    Number(values.integrity),
                    settings["systemvalueINum"]
                  )
                : "",
            t: Number(editSystemvalue["t"] != null ? editSystemvalue["t"] : 0),
            o: Number(
              editSystemvalue["o"] != null
                ? valueToPercentage(
                    editSystemvalue["o"],
                    settings["systemvalueONum"]
                  )
                : 0
            ),
            e: Number(
              editSystemvalue["e"] != null
                ? valueToPercentage(
                    editSystemvalue["e"],
                    settings["systemvalueENum"]
                  )
                : 0
            ),
            regdata: editSystemvalue["regdata"],
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then(function (response) {
          fetchData();
          setShowEditModal(false);
          notifySuccess(t("system_asset.toast_systemvaluesaved"));
          setUpdateTable(updateTable + 1);
        });
    } else {
      axios
        .put(
          generateUrl("/api/cra/systemvalues/") + editSystemvalue["_id"],
          {
            name: editSystemvalue["name"],
            valueType: editSystemvalue["valueType"],
            system: system["_id"],
            description: editSystemvalue["description"],
            reasoning: editSystemvalue["reasoning"],
            sensitivepo: editSystemvalue["sensitivepo"],
            relevant: editSystemvalue["relevant"],
            k: Number(editSystemvalue["k"] != null ? editSystemvalue["k"] : 0),
            i: Number(editSystemvalue["i"] != null ? editSystemvalue["i"] : 0),
            t: Number(editSystemvalue["t"] != null ? editSystemvalue["t"] : 0),
            o: Number(editSystemvalue["o"] != null ? editSystemvalue["o"] : 0),
            e: Number(editSystemvalue["e"] != null ? editSystemvalue["e"] : 0),
            regdata: editSystemvalue["regdata"],
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then(function (response) {
          fetchData();
          setShowEditModal(false);
          notifySuccess(t("system_asset.toast_systemvaluesaved"));
          setUpdateTable(updateTable + 1);
        });
    }
  };

  const handleCreateSystemvalue = () => {
    if (newSystemvalueName == "") {
      notifyWarning(t("bowtie.toast_empty_name"));
      return undefined;
    }
    if (
      systemvalues.filter((item) => item["name"] == newSystemvalueName)
        .length != 0
    ) {
      notifyWarning(t("bowtie.toast_duplicated"));
      return undefined;
    }
    if (newSystemvalueName.length < 3) {
      notifyWarning(t("bowtie.toast_length"));
      return undefined;
    }
    if (newSystemValueType == "") {
      notifyWarning(t("system_asset.toast_assettype"));
      return undefined;
    }

    axios
      .post(
        generateUrl("/api/cra/systemvalues/"),
        {
          createitem: true,
          name: newSystemvalueName,
          valueType: newSystemValueType,
          system: system["_id"],
          description: newSystemvalueDescription,
          reasoning: newSystemvalueReasoning,
          //sensitivepo: newSystemvalueSensitivepo,
          k: valueToPercentage(newSystemvalueK, settings["systemvalueKNum"]),
          i: valueToPercentage(newSystemvalueI, settings["systemvalueINum"]),
          t: valueToPercentage(newSystemvalueT, settings["systemvalueTNum"]),
          o: valueToPercentage(newSystemvalueO, settings["systemvalueONum"]),
          e: valueToPercentage(newSystemvalueE, settings["systemvalueENum"]),
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchData();
        cleanNewForm();
        setShowNewModal(false);
        notifySuccess(t("system_asset.toast_systemvaluecreated"));
        setUpdateTable(updateTable + 1);
      });
  };

  const handleDeleteSystemvalue = () => {
    axios
      .delete(
        generateUrl("/api/cra/systemvalues/") + deleteSystemvalue["_id"],
        {
          id: deleteSystemvalue["_id"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchData();
        setShowDeleteModal(false);
        notifySuccess(t("system_asset.toast_systemvaluedeleted"));
        setUpdateTable(updateTable + 1);
      });
  };

  async function fetchData() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systems/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systemvalues/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          res[1].data.forEach((asset) => {
            asset["k"] = percentageToValue(
              asset["k"],
              settings["systemvalueKNum"]
            );
            asset["i"] = percentageToValue(
              asset["i"],
              settings["systemvalueINum"]
            );
            asset["t"] = percentageToValue(
              asset["t"],
              settings["systemvalueTNum"]
            );
            asset["o"] = percentageToValue(
              asset["o"],
              settings["systemvalueONum"]
            );
            asset["e"] = percentageToValue(
              asset["e"],
              settings["systemvalueENum"]
            );
            asset["sensitivepo"] =
              asset["sensitivepo"] == null || asset["sensitivepo"] == undefined
                ? false
                : asset["sensitivepo"];
          });
          setSystem(res[0].data);
          if (res[0].data["availability"] != undefined) {
            setNewSystemvalueT(
              percentageToValue(
                res[0].data["availability"],
                settings["systemvalueTNum"]
              ).toString()
            );
          }
          setSystemvalues(res[1].data);
        })
      );
  }

  async function handleChangeSystemValue(event) {
    event.persist();
    setEditSystemvalue((editSystemvalue) => ({
      ...editSystemvalue,
      valueType: (event.target as HTMLSelectElement).value,
    }));
  }

  useEffect(() => {
    if (auth.user["userdata"] == undefined) {
      window.location.href = "/login";
    }
    fetchData();
  }, []);

  return (
    <div className="maincontent">
      {system["accesses"]?.includes("edit") ?? false ? (
        <SiteAssistant
          dockedLogo={DiriFaceDocked}
          featurekey="systemvalues"
          systemid={systemid}
          system={system}
          gotoKey={assistantGotoKey}
          gotoKeyFinished={() => {
            setAssistantGotoKey("");
          }}
          regCompleted={() => {
            fetchData();
            setUpdateTable(updateTable + 1);
          }}
        />
      ) : null}

      <div className="systems-container">
        <div className="pagetitle-container">
          <h1>{t("system_asset.system_page_name")}</h1>
          <Button className="newsystemBtn backbtn" onClick={handleBackSystems}>
            {t("system.go_to_assessment") /* t("system_asset.system_back") */}
          </Button>
        </div>
        <Card>
          <Card.Body>
            <h4 className="header-title">
              {system["nameReadable"]} - {t("system_asset.system_title")}
            </h4>
            <p>{t("system_asset.system_info")}</p>
            <Button
              className="newsystemBtn"
              disabled={!system["accesses"]?.includes("edit") ?? true}
              onClick={(evt) => setAssistantGotoKey("0")}
            >
              {t("system_asset.system_new_evaluation")}
            </Button>
            <PageTable
              dataUrl={"/api/cra/systemvalues/getAllPaged/" + systemid}
              options={{
                showFields: [
                  { label: assetName, field: "name" },
                  {
                    label: valueType,
                    field: "valueType",
                    override: (dataLine) =>
                      customValueTypes.includes(dataLine["valueType"])
                        ? t("system_asset.type." + dataLine["valueType"])
                        : customValueTypesMappings[dataLine["valueType"]] !=
                          undefined
                        ? t(
                            "system_asset.type." +
                              customValueTypesMappings[dataLine["valueType"]]
                          )
                        : dataLine["valueType"],
                  },
                  {
                    label: confidentiality,
                    field: "k",
                    override: (dataLine) =>
                      dataLine["relevant"] != false
                        ? td(
                            settings[
                              "systemvalueKLevel" +
                                percentageToValue(
                                  dataLine["k"],
                                  settings["systemvalueKNum"]
                                )
                            ]
                          )
                        : t("system_asset.not_relevant_value"),
                  },
                  {
                    label: integrity,
                    field: "i",
                    override: (dataLine) =>
                      dataLine["relevant"] != false
                        ? td(
                            settings[
                              "systemvalueILevel" +
                                percentageToValue(
                                  dataLine["i"],
                                  settings["systemvalueINum"]
                                )
                            ]
                          )
                        : t("system_asset.not_relevant_value"),
                  },
                  {
                    label: availability,
                    field: "t",
                    override: (dataLine) =>
                      dataLine["relevant"] != false
                        ? td(
                            settings[
                              "systemvalueTLevel" +
                                percentageToValue(
                                  dataLine["t"],
                                  settings["systemvalueTNum"]
                                )
                            ]
                          )
                        : t("system_asset.not_relevant_value"),
                  },
                  //{ label: reputation, field: "o", override: dataLine => settings["systemvalueOLevel" + percentageToValue(dataLine["o"], settings["systemvalueONum"])]},
                  //{ label: economic, field: "e", override: dataLine => settings["systemvalueELevel" + percentageToValue(dataLine["e"], settings["systemvalueENum"])]},
                ],
              }}
              dataAfter={(dataLine) =>
                dataLine["affectedConsequences"] ? (
                  <Tooltip
                    title={t("system_asset.asset_in_use")}
                    placement="top"
                  >
                    <span>
                      <Button
                        style={
                          dataLine["affectedConsequences"]
                            ? { pointerEvents: "none" }
                            : {}
                        }
                        disabled={
                          dataLine["affectedConsequences"] ||
                          (!system["accesses"]?.includes("edit") ?? true)
                        }
                        onClick={(evt) =>
                          handleStartDeleteSystem(dataLine, evt)
                        }
                      >
                        {" "}
                        {t("system_asset.delete")}{" "}
                      </Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Button
                    disabled={!system["accesses"]?.includes("edit") ?? true}
                    onClick={(evt) => handleStartDeleteSystem(dataLine, evt)}
                  >
                    {" "}
                    {t("system_asset.delete")}{" "}
                  </Button>
                )
              }
              onLineClick={(dataLine) => handleStartEditingSystem(dataLine)}
              hidePagination={false}
              hidePagesize={false}
              hideFilter={false}
              updateTable={updateTable}
              hover={true}
            />
          </Card.Body>
        </Card>
        <Modal
          show={showNewModal}
          onHide={() => setShowNewModal(false)}
          onEntered={() => myRef.current.focus()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("system_wizard.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("system_wizard.value_name")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                ref={myRef}
                onKeyDown={handleKeyDownCreate}
                onChange={(evt) => setNewSystemvalueName(evt.target.value)}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("system_wizard.value_type")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                onKeyDown={handleKeyDownCreate}
                onChange={(evt) =>
                  setNewSystemValueType((evt.target as HTMLSelectElement).value)
                }
              >
                <option value="">{t("system_asset.choose")}</option>
                {settings["valueType"] != undefined
                  ? settings["valueType"].map((option, optionkey) => {
                      return (
                        <option key={optionkey} value={option}>
                          {option}
                        </option>
                      );
                    })
                  : ""}
              </Form.Control>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("system_wizard.value_description")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="textarea"
                type="text"
                disabled={props.disabled}
                onKeyDown={handleKeyDownCreate}
                onChange={(evt) =>
                  setNewSystemvalueDescription(evt.currentTarget.value)
                }
                value={newSystemvalueDescription}
              />
            </InputGroup>
            <hr />
            {/*<h3>{t("system_wizard.sensitivepo")}</h3>
                        <p>
                            {t("systemvalues.new_wizard.subtitle.sensitivepo")}<br/>
                            <em>
                            {t("systemvalues.new_wizard.explaination.sensitivepo")}
                            </em>
                        </p>
                        <InputGroup className="mb-3">
                            <Field
                                type="bool"
                                title={t("system_wizard.sensitivepo")}
                                value={newSystemvalueSensitivepo}
                                onChange={(value) => setNewSystemvalueSensitivepo(value)}
                            ></Field>
                        </InputGroup>
                        <hr />*/}
            <h3>{t("system_wizard.confidential")}</h3>
            <p>
              {t("systemvalues.new_wizard.subtitle.confidential")}
              <br />
              <em>{t("systemvalues.new_wizard.explaination.confidential")}</em>
            </p>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("system_wizard.confidential")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                onKeyDown={handleKeyDownCreate}
                onChange={(evt) =>
                  setNewSystemvalueK((evt.target as HTMLSelectElement).value)
                }
              >
                <option value="">
                  {t("systemvalues.new_wizard.selectvalue")}
                </option>
                {handleAssetLevelOptions("confidentiality")}
              </Form.Control>
            </InputGroup>
            <hr />
            <h3>{t("system_wizard.integrity")}</h3>
            <p>
              {t("systemvalues.new_wizard.subtitle.integrity")}
              <br />
              <em>{t("systemvalues.new_wizard.explaination.integrity")}</em>
            </p>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("system_wizard.integrity")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                onKeyDown={handleKeyDownCreate}
                onChange={(evt) =>
                  setNewSystemvalueI((evt.target as HTMLSelectElement).value)
                }
              >
                <option value="">
                  {t("systemvalues.new_wizard.selectvalue")}
                </option>
                {handleAssetLevelOptions("integrity")}
              </Form.Control>
            </InputGroup>
            <hr />
            <h3>{t("system_wizard.availability")}</h3>
            <p>
              {t("systemvalues.new_wizard.subtitle.availability")}
              <br />
              <em>{t("systemvalues.new_wizard.explaination.availability")}</em>
            </p>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("system_wizard.availability")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                value={newSystemvalueT}
                onKeyDown={handleKeyDownCreate}
                onChange={(evt) =>
                  setNewSystemvalueT((evt.target as HTMLSelectElement).value)
                }
              >
                <option value="">
                  {t("systemvalues.new_wizard.selectvalue")}
                </option>
                {handleAssetLevelOptions("availability")}
              </Form.Control>
            </InputGroup>
            <hr />
            {/*<h3>{t("system_wizard.reputation")}</h3>
                        <p>
                            Kan verdien påvirke din bedrifts omdømme?<br/>
                            <em>
                                Forklaring: Her vurderes det om verdien kan ha stor påvirkning på omdømme. F.eks. faktureringsinformasjon på avveie vil svekke bedriftens omdømme og ansikt utad.
                            </em>
                        </p>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>{t("system_wizard.reputation")}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control as="select" onKeyDown={handleKeyDownCreate} onChange={evt => setNewSystemvalueO((evt.target as HTMLSelectElement).value)}>
                                <option value="">{t("system_asset.choose")}</option>
                                { handleAssetLevelOptions("reputation")}
                            </Form.Control>
                        </InputGroup>
                        <hr/>
                        <h3>{t("system_wizard.economic")}</h3>
                        <p>
                            Hvor viktig er verdien økonomisk?<br/>
                            <em>
                                Forklaring: Her vurderes den økonomiske påvirkningen av verdien. F.eks. manglende faktureringsinformasjon kan gjøre at bedriften ikke får utsendt faktura, som igjen fører til reduserte inntekter.
                            </em>
                        </p>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>{t("system_wizard.economic")}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control as="select" onKeyDown={handleKeyDownCreate} onChange={evt => setNewSystemvalueE((evt.target as HTMLSelectElement).value)}>
                                <option value="">{t("system_asset.choose")}</option>
                                { handleAssetLevelOptions("economic")}
                            </Form.Control>
                        </InputGroup>
                        <hr/>*/}
            <h3>{t("system_wizard.reasoning")}</h3>
            <p>
              {t("systemvalues.new_wizard.subtitle.reasoning")}
              <br />
              <em>{t("systemvalues.new_wizard.explaination.reasoning")}</em>
            </p>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("system_wizard.reasoning")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="textarea"
                type="text"
                disabled={props.disabled}
                onKeyDown={handleKeyDownCreate}
                onChange={(evt) =>
                  setNewSystemvalueReasoning(evt.currentTarget.value)
                }
                value={newSystemvalueReasoning}
              />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel posleft"
              onClick={() => setShowNewModal(false)}
            >
              {t("system_wizard.closed")}
            </Button>
            <Button onClick={handleCreateSystemvalue}>
              {t("system_wizard.save")}
            </Button>
          </Modal.Footer>
        </Modal>
        {editSystemvalue != undefined ? (
          <Modal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            size={
              editSystemvalue != undefined &&
              editSystemvalue["regdata"] != undefined &&
              editSystemvalue["regdata"].length > 0
                ? "lg"
                : undefined
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {t("system_wizard.modify_value")} - {editSystemvalue["name"]}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {editSystemvalue["regdata"] == undefined ||
              editSystemvalue["regdata"].length == 0 ||
              GetWizardValuesByReferenceList(editSystemvalue["regdata"], [
                "title",
              ]).title == undefined ? (
                <Field
                  type="input"
                  title={t("system_wizard.value_name")}
                  value={editSystemvalue["name"]}
                  onChange={(value) =>
                    setEditSystemvalue((editSystemvalue) => ({
                      ...editSystemvalue,
                      name: value,
                    }))
                  }
                  disabled={!system["accesses"].includes("edit")}
                ></Field>
              ) : null}
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {t("system_wizard.value_type")}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  disabled
                  as="select"
                  onKeyDown={handleKeyDownEdit}
                  onChange={(evt) => {
                    handleChangeSystemValue(evt);
                  }}
                  value={editSystemvalue["valueType"]}
                >
                  <option value="">
                    {t("system_asset.chooseSystemvalueType")}
                  </option>
                  <option value="systemvaluecommunication">
                    {t("system_asset.type.systemvaluecommunication")}
                  </option>
                  <option value="systemvaluecompanydata">
                    {t("system_asset.type.systemvaluecompanydata")}
                  </option>
                  <option value="systemvalueeconomy">
                    {t("system_asset.type.systemvalueeconomy")}
                  </option>
                  <option value="systemvaluehardware">
                    {t("system_asset.type.systemvaluehardware")}
                  </option>
                  <option value="systemvalueotherinfo">
                    {t("system_asset.type.systemvalueotherinfo")}
                  </option>
                  <option value="systemvalueotherlawinfo">
                    {t("system_asset.type.systemvalueotherlawinfo")}
                  </option>
                  <option value="systemvaluepersoninformation">
                    {t("system_asset.type.systemvaluepersoninformation")}
                  </option>
                  <option value="systemvalueusernamepassword">
                    {t("system_asset.type.systemvalueusernamepassword")}
                  </option>
                  {settings["valueType"] != undefined
                    ? settings["valueType"].map((option, optionkey) => {
                        return (
                          <option key={optionkey} value={option}>
                            {option}
                          </option>
                        );
                      })
                    : ""}
                </Form.Control>
              </InputGroup>
              <Field
                type="select"
                title={t("system_wizard.availability")}
                value={percentageToValue(
                  editSystemvalue["t"],
                  settings["systemvalueTNum"]
                )}
                onChange={(value) =>
                  setEditSystemvalue((editSystemvalue) => ({
                    ...editSystemvalue,
                    t: valueToPercentage(
                      value.value,
                      settings["systemvalueTNum"]
                    ),
                  }))
                }
                options={{
                  choosetext: t("system_asset.choose"),
                  choices: getSystemvalueOptionsByKIT("T"),
                }}
                disabled={!system["accesses"].includes("edit")}
              ></Field>
              {editSystemvalue["regdata"] == undefined ||
              editSystemvalue["regdata"].length == 0 ? (
                <React.Fragment>
                  <Field
                    type="textarea"
                    title={t("system_wizard.value_description")}
                    value={editSystemvalue["description"]}
                    onChange={(value) =>
                      setEditSystemvalue((editSystemvalue) => ({
                        ...editSystemvalue,
                        description: value,
                      }))
                    }
                    disabled={!system["accesses"].includes("edit")}
                  ></Field>
                  <Field
                    type="bool"
                    title={t("system_wizard.sensitivepo")}
                    value={
                      editSystemvalue["sensitivepo"] == null ||
                      editSystemvalue["sensitivepo"] == undefined
                        ? false
                        : editSystemvalue["sensitivepo"]
                    }
                    onChange={(value) =>
                      setEditSystemvalue((editSystemvalue) => ({
                        ...editSystemvalue,
                        sensitivepo: value,
                      }))
                    }
                    disabled={!system["accesses"].includes("edit")}
                  ></Field>
                  <hr />
                  <Field
                    type="select"
                    title={t("system_wizard.confidential")}
                    value={percentageToValue(
                      editSystemvalue["k"],
                      settings["systemvalueKNum"]
                    )}
                    onChange={(value) =>
                      setEditSystemvalue((editSystemvalue) => ({
                        ...editSystemvalue,
                        k: valueToPercentage(
                          value.value,
                          settings["systemvalueKNum"]
                        ),
                      }))
                    }
                    options={{
                      choosetext: t("system_asset.choose"),
                      choices: getSystemvalueOptionsByKIT("K"),
                    }}
                    disabled={!system["accesses"].includes("edit")}
                  ></Field>
                  <Field
                    type="select"
                    title={t("system_wizard.integrity")}
                    value={percentageToValue(
                      editSystemvalue["i"],
                      settings["systemvalueINum"]
                    )}
                    onChange={(value) =>
                      setEditSystemvalue((editSystemvalue) => ({
                        ...editSystemvalue,
                        i: valueToPercentage(
                          value.value,
                          settings["systemvalueINum"]
                        ),
                      }))
                    }
                    options={{
                      choosetext: t("system_asset.choose"),
                      choices: getSystemvalueOptionsByKIT("I"),
                    }}
                    disabled={!system["accesses"].includes("edit")}
                  ></Field>
                  {/*<Field
                                        type="select"
                                        title={t("system_wizard.availability")}
                                        value={percentageToValue(editSystemvalue["t"], settings["systemvalueTNum"])}
                                        onChange={value => setEditSystemvalue(editSystemvalue => ({...editSystemvalue, t: valueToPercentage(value.value, settings["systemvalueTNum"])}))}
                                        options={{
                                            choosetext: t("system_asset.choose"),
                                            choices: getSystemvalueOptionsByKIT("T")
                                        }}
                                    ></Field>
                                    <Field
                                        type="select"
                                        title={t("system_wizard.reputation")}
                                        value={percentageToValue(editSystemvalue["o"], settings["systemvalueONum"])}
                                        onChange={value => setEditSystemvalue(editSystemvalue => ({...editSystemvalue, o: valueToPercentage(value.value, settings["systemvalueONum"])}))}
                                        options={{
                                            choosetext: t("system_asset.choose"),
                                            choices: getSystemvalueOptionsByKIT("O")
                                        }}
                                    ></Field>
                                    <Field
                                        type="select"
                                        title={t("system_wizard.economic")}
                                        value={percentageToValue(editSystemvalue["e"], settings["systemvalueENum"])}
                                        onChange={value => setEditSystemvalue(editSystemvalue => ({...editSystemvalue, e: valueToPercentage(value.value, settings["systemvalueENum"])}))}
                                        options={{
                                            choosetext: t("system_asset.choose"),
                                            choices: getSystemvalueOptionsByKIT("E")
                                        }}
                                    ></Field>*/}
                  <Field
                    type="textarea"
                    title="Begrunnelse"
                    value={editSystemvalue["reasoning"]}
                    onChange={(value) =>
                      setEditSystemvalue((editSystemvalue) => ({
                        ...editSystemvalue,
                        reasoning: value,
                      }))
                    }
                    disabled={!system["accesses"].includes("edit")}
                  ></Field>
                </React.Fragment>
              ) : null}
              {editSystemvalue["regdata"] != undefined ? (
                <Wizard
                  data={editSystemvalue["regdata"]}
                  options={{
                    disableComplete: true,
                  }}
                  onChange={(data) =>
                    setEditSystemvalue((editSystemvalue) => ({
                      ...editSystemvalue,
                      regdata: data,
                    }))
                  }
                ></Wizard>
              ) : null}
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="cancel posleft"
                onClick={() => setShowEditModal(false)}
              >
                {t("system_wizard.closed")}
              </Button>
              <Button
                disabled={!system["accesses"].includes("edit")}
                onClick={handleEditSystemvalue}
              >
                {t("system_wizard.save")}
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {t("system_wizard.delete_title")} - {deleteSystemvalue["name"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t("system_wizard.delete_info")}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel posleft"
              onClick={() => setShowDeleteModal(false)}
            >
              {t("system_wizard.cancel")}
            </Button>
            <Button
              disabled={!system["accesses"]?.includes("edit") ?? true}
              onClick={handleDeleteSystemvalue}
            >
              {t("system_wizard.delete")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default ValueEvaluation;
