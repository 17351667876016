import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../Auth";
import { useTranslate } from 'react-polyglot';
import { useAxios } from '../../../AxiosHandler';

import 'react-toastify/dist/ReactToastify.css';

import './Onboarding.scss';
import CRALogo from "../../../Assets/Images/diri-logo.svg";
import MSFlag from "../../../Assets/Images/msflag.png";

import {
    Button,
    Card,
    Form,
    Modal,
    InputGroup
} from 'react-bootstrap';
import LocaleHandler from "../../../LocaleHandler";
import Field from "../../Shared/Wizard/Field/Field";
import { useMsalAuth } from "../../../AuthProvider";

import tags from "../../../Assets/tags.json";
import Shop from "../../Shared/Shop/Shop";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";
import LangSwitcher from "../../Shared/LangSwitcher/LangSwitcher";
import { generateUrl } from "../../../config";

function Onboarding(props) {
    const auth = useAuth();
    const history = useHistory();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const msal = useMsalAuth();
    const t = useTranslate();
    const { setLocale, locale } = React.useContext(LocaleHandler);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const notifyWarning = (text) => toast(text, {
        position: "bottom-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: "warning-toast"
    });

    const [stage, setStage] = useState("login");
    const [usertype, setUsertype] = useState("");

    const [accountToken, setAccountToken] = useState("");

    const [msalToken, setMsalToken] = useState("");

    const [captchaToken, setCaptchaToken] = useState("");
    
    const [msalId, setMsalId] = useState("");
    const [email, setEmail] = useState("");
    const [organisationName, setOrganisationName] = useState("");
    const [organisationAreas, setOrganisationAreas] = useState([]);
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [organisationTenant, setOrganisationTenant] = useState("");

    const SSB_options = [
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.agriculture"),
          "value": "agriculture"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.mining"),
          "value": "mining"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.industry"),
          "value": "industry"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.supplyservices"),
          "value": "supplyservices"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.construction"),
          "value": "construction"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.retail"),
          "value": "retail"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.transportstorage"),
          "value": "transportstorage"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.accomodationfood"),
          "value": "accomodationfood"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.infcom"),
          "value": "infcom"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.financialinsurance"),
          "value": "financialinsurance"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.realestate"),
          "value": "realestate"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.technicalservices"),
          "value": "technicalservices"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.businessservices"),
          "value": "businessservices"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.publicadministration"),
          "value": "publicadministration"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.teaching"),
          "value": "teaching"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.healthsocialwork"),
          "value": "healthsocialwork"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.cultural"),
          "value": "cultural"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.otherservices"),
          "value": "otherservices"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.paidprivatehouseholdwork"),
          "value": "paidprivatehouseholdwork"
        },
        {
          "label": t("diri.regassistant.types.orgros.industryssbselections.internationalorgs"),
          "value": "internationalorgs"
        }
    ]

    function loginMsal() {
        msal.login();
    }
    
    function logoutMsal() {
		sessionStorage.clear();
		localStorage.clear();
		msal.deleteAllCookies();
        setMsalToken("");
    }

    function createAccountRequest(token = captchaToken) {
        axios.post(generateUrl('/api/cra/onboarding/accountrequest'), 
            {
                userType: usertype,
                userMsalId: msalId,
                userName: name,
                userEmail: email,
                userPhone: phone,
                userPassword: password,
                userPasswordConfirm: passwordConfirm,
                organisationName: organisationName,
                organisationAreas: organisationAreas,
                captchaToken: token,
                lang: locale,
                organisationTenant: organisationTenant
            },{}
        ).then(function (response) {
            if (response.data == "captcha_error") {
                if (executeRecaptcha != undefined) {
                    executeRecaptcha().then(token => {
                        setCaptchaToken(token);
                        createAccountRequest(token);
                    });
                }
                return;
            }
            if (response.data == "passwordMismatch_error") {
                notifyWarning(t("onboarding.toasts.passwordmismatch"));
                return;
            }

            setStage("verify");
        });
    }

    useEffect(() => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
      
        executeRecaptcha().then(token => {
            setCaptchaToken(token);
        });
    }, [executeRecaptcha]);

    useEffect(() => {
        if (props.location.search != "") {
            const params = new URLSearchParams(props.location.search);
            setLocale(params.get("lang"));

            let token = params.get("token");
            if (token != undefined && token != "") {
                setStage("preparing");
                setAccountToken(token);

                // Send request to create account and user by using the token.
                // Remember to check if the token is valid and not expired on the server.

                axios.post(generateUrl('/api/cra/onboarding/createaccount'),
                    { token: token },{}
                ).then(function (response) {
                    if (response.data == "token_error") {
                        setStage("tokenerror");
                        return;
                    }

                    let userObject = {
                        id: response.data.userdata.email,
                        auth: response.data.auth,
                        token: response.data.token,
                        userdata: response.data.userdata,
                        settings: response.data.settings
                    };
                    localStorage.setItem('user', JSON.stringify(userObject));
                    localStorage.removeItem("unauthorized");

                    auth.setUser(() => { return userObject });

                    // All is OK. Account is created and logged in. Proceed to shop.
                    setStage("shop");
                });
            }
        }
    }, []);

    useEffect(() => {
        if (props.location.search != "") {
            const params = new URLSearchParams(props.location.search);

            let success = params.get("success");
            let transfer = params.get("transfer");

            if (success) {
                if (transfer) {
                    setStage("transfer");
                } else {
                    setStage("success");
                }
            }
        }
    }, [props.location.search, stage]);

    useEffect(() => {
        if (sessionStorage["msal.idtoken"] != undefined) {
            setMsalToken(sessionStorage["msal.idtoken"]);
        }
    }, [sessionStorage["msal.idtoken"]]);

    useEffect(() => {
        if (msalToken != undefined && msalToken != "") {
            axios.post("/api/loginMsal/getUser",
				{},
				{
					headers: {'Authorization': "Bearer " + sessionStorage["msal.idtoken"]}
				}
			)
			.then(function (response) {
                setEmail(response.data.email);
                setName(response.data.name);
                setMsalId(response.data.msalId);
                setOrganisationTenant(response.data.organisationTenant);
                console.log(response.data);
			});
        }
    }, [msalToken]);

    return (
        <div className="onboardingcontainer">
            <div className="cardcontainer">
                <Card>
                    <div className="card-header pt-4 pb-4 text-center onboarding-header">
                        <LangSwitcher
                            onChange={(newLang) => {
                                const params = new URLSearchParams(props.location.search);
                                let token = params.get("token");

                                if (token != undefined && token != "") {
                                    props.history.push("/welcome?lang=" + newLang + "&token=" + token);
                                } else {
                                    props.history.push("/welcome?lang=" + newLang);
                                }
                            }}
                        ></LangSwitcher>
                        <a href="/welcome">
                            <span><img className="logo" src={CRALogo} alt="" width="200" /></span>
                        </a>
                    </div>
                    <Card.Body>
                        <div className="welcometext">
                            { stage == "login" ?
                                <>
                                    <h1>{t("onboarding.title")}</h1>
                                    <hr />
                                    { msalToken == "" ?
                                        <>
                                            <Button className="msloginbtn" onClick={loginMsal}><img width="100px" src={MSFlag} />{t("onboarding.msloginbtn")}</Button>
                                            <p>{t("onboarding.or")}</p>
                                            <div className="emailcontainer">
                                                <Field
                                                    type="input"
                                                    title={t("onboarding.email")}
                                                    value={email}
                                                    onChange={(val) => setEmail(val)}
                                                ></Field>
                                            </div>
                                        </>
                                    :
                                        <div className="loggedinas">
                                            <h4>{t("onboarding.loggedin_ms")}<span onClick={logoutMsal}>{t("onboarding.logout_ms")}</span></h4>
                                        </div>
                                    }
                                    <Button
                                        disabled={msalToken == "" && !(email.includes("@") && email.includes("."))}
                                        onClick={() => {
                                            setStage("details");

                                            if (msalToken != "") {
                                                setUsertype("msal");
                                            } else if (email.includes("@") && email.includes(".")) {
                                                setUsertype("email");
                                            }
                                        }}
                                    >{t("onboarding.startbtn")}</Button>
                                </>
                            : null }
                            { stage == "details" ?
                                <>
                                    <h1>{t("onboarding.createuser")}</h1>
                                    <hr />
                                    <div className="detailscontainer">
                                        <p>{t("onboarding.details.section_organisation")}</p>
                                        <Field
                                            type="input"
                                            title={t("onboarding.details.organisationName")}
                                            value={organisationName}
                                            onChange={(val) => setOrganisationName(val)}
                                        ></Field>
                                        <Field
                                            type="selectmulti"
                                            title={t("onboarding.details.organisationAreas")}
                                            value={organisationAreas}
                                            onChange={(val) => setOrganisationAreas(val)}
                                            options={{
                                                choosetext: t("onboarding.details.choose_areas"),
                                                choices: SSB_options
                                            }}
                                        ></Field>
                                        {/*<Field
                                            type="autocomplete"
                                            title={t("onboarding.details.organisationCountry")}
                                            value={organisationCountry}
                                            onChange={(val) => setOrganisationCountry(val)}
                                            options={{
                                                list: [
                                                    { label: t("onboarding.details.countries.no"), value: "NO" },
                                                ],
                                                minlength: 2,
                                                noresults: "no matches",
                                            }}
                                        ></Field>*/}
                                        <hr />
                                        <p>{t("onboarding.details.section_you")}</p>
                                        <Field
                                            type="input"
                                            title={t("onboarding.details.name")}
                                            value={name}
                                            onChange={(val) => setName(val)}
                                            disabled={usertype == "msal"}
                                        ></Field>
                                        { usertype == "msal" ?
                                            <p className="fieldinfo">{t("onboarding.details.msal_locked")}</p>
                                        : null }
                                        <Field
                                            type="input"
                                            title={t("onboarding.details.email")}
                                            value={email}
                                            onChange={(val) => setEmail(val)}
                                            disabled={usertype == "msal"}
                                        ></Field>
                                        { usertype == "msal" ?
                                            <p className="fieldinfo">{t("onboarding.details.msal_locked")}</p>
                                        : null }
                                        <Field
                                            type="input"
                                            title={t("onboarding.details.phone")}
                                            value={phone}
                                            onChange={(val) => setPhone(val)}
                                        ></Field>
                                        { usertype == "email" ?
                                            <>
                                                <Field
                                                    type="input"
                                                    title={t("onboarding.details.password")}
                                                    value={password}
                                                    onChange={(val) => setPassword(val)}
                                                    options={{
                                                        password: true,
                                                    }}
                                                ></Field>
                                                <Field
                                                    type="input"
                                                    title={t("onboarding.details.passwordconfirm")}
                                                    value={passwordConfirm}
                                                    onChange={(val) => setPasswordConfirm(val)}
                                                    options={{
                                                        password: true,
                                                    }}
                                                ></Field>
                                            </>
                                        : null }
                                        {/*<GoogleReCaptcha
                                            onVerify={(token) => setCaptchaToken(token)}
                                        ></GoogleReCaptcha>*/}
                                    </div>
                                    <div className="buttonscontainer">
                                        <Button
                                            onClick={() => {
                                                setStage("login");
                                            }}
                                        >{t("onboarding.backbtn")}</Button>
                                        <Button
                                            disabled={
                                                false
                                            }
                                            onClick={() => {
                                                createAccountRequest();
                                            }}
                                        >{t("onboarding.details.registeruser")}</Button>
                                    </div>
                                </>
                            : null }
                            { stage == "verify" ?
                                <>
                                    <h1>{t("onboarding.verify.title")}</h1>
                                    <hr />
                                    <p style={{
                                        color: "#5d646c"
                                    }}>{t("onboarding.verify.info")}</p>
                                </>
                            : null }
                            { stage == "preparing" ?
                                <>
                                    <h1>{t("onboarding.preparing.title")}</h1>
                                    <hr />
                                </>
                            : null }
                            { stage == "tokenerror" ?
                                <>
                                    <h1>{t("onboarding.tokenerror.title")}</h1>
                                    <hr />
                                    <p>{t("onboarding.tokenerror.description")}</p>
                                </>
                            : null }
                            { stage == "shop" ?
                                <>
                                    <h1>{t("onboarding.shop.title")}</h1>
                                    <hr />
                                    <p
                                        style={{
                                            color: "#5d646c"
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: t("onboarding.shop.info").replaceAll("\n", "<br />")
                                        }}
                                    ></p>
                                    {/*
                                    
                                        User must select a package from the shop to finalise the account.

                                        Can be a trial period or a full package.
                                    
                                    */}
                                    <Shop
                                        modal={false}
                                        orgid={auth.user["userdata"].unit[0]}
                                        headless
                                        mainOnly
                                        cancelUrl={`${window.location.origin}/welcome?lang=${locale}&token=${accountToken}`}
                                        successUrl={`${window.location.origin}/welcome?lang=${locale}&token=${accountToken}&success=true`}
                                        successTransferUrl={`${window.location.origin}/welcome?lang=${locale}&token=${accountToken}&success=true&transfer=true`}
                                        onPurchaseComplete={(data) => {
                                            setStage("finished");
                                        }}
                                    />
                                </>
                            : null }
                            { stage == "success" ?
                                <>
                                    <h1>{t("onboarding.success.title")}</h1>
                                    <hr />
                                    <p>{t("onboarding.success.description")}</p>
                                    <div className="">
                                        <Button
                                            onClick={() => {
                                                history.push(`/login`);
                                            }}
                                        >{t("onboarding.gotologinbtn")}</Button>
                                    </div>
                                </>
                            : null }
                            { stage == "transfer" ?
                                <>
                                    <h1>{t("onboarding.transfer.trialStartedTitle")}</h1>
                                    <hr />
                                    <p>{t("onboarding.transfer.trialStartedDescription")}</p>
                                    <div className="">
                                        <Button
                                            onClick={() => {
                                                history.push(`/login`);
                                            }}
                                        >{t("onboarding.gotologinbtn")}</Button>
                                    </div>
                                </>
                            : null }
                            <p className="termstext" dangerouslySetInnerHTML={{ __html: t("onboarding.terms") }}></p>
                            <p className="termstext bottom" dangerouslySetInnerHTML={{ __html: t("recaptcha_twoline_links") }}></p>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

export default Onboarding;