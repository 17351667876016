import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { useTranslate } from "react-polyglot";

import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";

import FilterCreator from "./FilterCreator/FilterCreator";
import FilterEditor from "./FilterEditor/FilterEditor";
import { useDynamicLanguage } from "../../../DynamicLanguageProvider";

import { generateUrl } from "../../../config";

import "./FilterSelector.scss";

function FilterSelector(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();
  const history = useHistory();
  const td = useDynamicLanguage().td;

  const [filters, setFilters] = useState<Array<any>>([]);
  const [selected, setSelected] = useState(filters?.[0]?._id ?? "");

  const [showCreateNew, setShowCreateNew] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [editFilter, setEditFilter] = useState();

  function uniqBy(a, key) {
    let seen = new Set();
    return a.filter((item) => {
      let k = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }

  async function fetchData() {
    axios
      .single({
        method: "get",
        url: props.filtersUrl + props.filterType,
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        let results = uniqBy(res.data, (it) => it.filters[0].field);
        setFilters(results);

        const params = new URLSearchParams(window.location.search);
        if (params.has("filter")) {
          let filter = params.get("filter");
          if (results.find((i) => i["_id"] == filter) != undefined) {
            setSelected(filter as string);
            if (props.onChange != undefined) {
              props.onChange(results.find((i) => i["_id"] == filter));
            }
          }
        }
        if (props.onLoaded != undefined) {
          props.onLoaded(results);
        }
      });
  }

  function deletefilter(id) {
    axios
      .delete(
        generateUrl("/api/cra/filters/") + id,
        {
          id: id,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        fetchData();
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="filterselector">
      {props.disallowAll ? null : (
        <Badge
          pill
          className={selected == "" ? "selected" : ""}
          onClick={(evt) => {
            if (props.onChange != undefined) {
              props.onChange({ filters: [] });
            }
            setSelected("");
            history.push({
              pathname: "",
              search: "",
            });
          }}
        >
          {t("filters.filterselector.showallitems")}
        </Badge>
      )}

      {filters.map((filter, key) => (
        <Badge
          key={key}
          className={
            filter._id ==
            selected /* || (filter.defaultActive == true && selected == "")  */
              ? "selected"
              : ""
          }
          pill
          onClick={(_) => {
            if (props.onChange != undefined) {
              props.onChange(filter);
            }
            setSelected(filter._id);
            history.push({
              pathname: "",
              search: "?filter=" + filter._id,
            });
          }}
        >
          {td(filter.name)}
          {props.onEditClick != undefined ? (
            <i
              className="dripicons-brush"
              style={{ marginLeft: "14px" }}
              onClick={(evt) => {
                props.onEditClick();
                evt.stopPropagation();
                evt.preventDefault();

                setShowEdit(true);
                setEditFilter(filter);
              }}
            />
          ) : null}
          {props.onDeleteClick != undefined ? (
            <i
              className="dripicons-trash"
              style={{ marginLeft: "6px" }}
              onClick={(evt) => {
                props.onDeleteClick();
                evt.stopPropagation();
                evt.preventDefault();

                let confirmed = window.confirm(
                  t("filters.filterselector.confirmdelete")
                );
                if (confirmed) {
                  deletefilter(filter["_id"]);
                }
              }}
            />
          ) : null}
        </Badge>
      ))}
      {props.onAddClick != undefined ? (
        <Badge
          pill
          style={{ borderStyle: "dashed" }}
          onClick={(evt) => {
            setShowCreateNew(true);
          }}
        >
          + {props.addFilterText != undefined ? props.addFilterText : ""}
        </Badge>
      ) : null}
      <FilterCreator
        filterType={props.filterType}
        show={showCreateNew}
        fields={props.fields != undefined ? props.fields : undefined}
        onClose={() => setShowCreateNew(false)}
        onHide={() => setShowCreateNew(false)}
        onCreated={() => fetchData()}
      ></FilterCreator>
      <FilterEditor
        filterType={props.filterType}
        filter={editFilter}
        show={showEdit}
        fields={props.fields != undefined ? props.fields : undefined}
        onClose={() => setShowEdit(false)}
        onHide={() => setShowEdit(false)}
        onEdited={() => fetchData()}
      ></FilterEditor>
    </div>
  );
}

export default FilterSelector;
