/*async function fetchData(axios, auth): Promise<any> {
    let results = {
        users: [],
        organizations: []
    };

    await axios.all([
        axios.single({
            method: "get",
            url: "https://test.diri.ai/api/users/",
            responseType: "json",
            headers: {'x-access-token': auth.user["token"]}
        }),
        axios.single({
			method: "get",
			url: "https://test.diri.ai/api/orgElements/",
			responseType: "json",
			headers: {'x-access-token': auth.user["token"]}
      })
    ])
    .then(axios.spread(async (...res) => {
        results.users = res[0].data;
        results.organizations = res[1].data;
    }));

    return results;
}*/

let localStorageUser = localStorage.getItem("user");
let settings = {};
if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
}

function getSystemvalueOptionsByKIT(kit){
    let options = Array();
    if(settings["systemvalue" + kit + "Num"] != undefined){
        for (let i = 1; i <= parseInt(settings["systemvalue" + kit + "Num"]); i++) {
            options.push({
                label: settings["systemvalue" + kit + "Level" + i],
                value: i
            });
        }
        return options;
    }
}

async function PersoninformationTemplate(t, axios, auth) {
    if(localStorageUser == null){
        localStorageUser = localStorage.getItem("user");
    }
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    //let data = await fetchData(axios, auth);

    let tempWizardData: Array<any> = [
        {
            fields: [
                {
                    type: "text_title",
                    title: t("wizard_title.assets.pii.title")
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.pii.desc")
                },
                {
                    id: 2,
                    type: "bool",
                    title: t("wizard_title.assets.pii.question") + " *",
                    value: "",
                    reference: "createitembool"
                },
                {
                    type: "textarea",
                    title: t("wizard_title.assets.reason_not_relevant"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: false,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "reasoning_not"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.reason_not_relevant_desc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: false,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "selectmulti",
                    title: t("wizard_title.assets.pii.question2"),
                    options: {
                        typeLoad: "Manual",
                        choices: [
                            {
                                label: t("wizard_title.assets.pii.assets.name"),
                                value: "Navn"
                            },
                            {
                                label: t("wizard_title.assets.pii.assets.address"),
                                value: "Adresse"
                            },
                            {
                                label: t("wizard_title.assets.pii.assets.phone"),
                                value: "Telefonnummer"
                            },
                            {
                                label: t("wizard_title.assets.pii.assets.email"),
                                value: "E-postadresse"
                            },
                            {
                                label: t("wizard_title.assets.pii.assets.pictures"),
                                value: "Bilder av personer"
                            },
                            {
                                label: t("wizard_title.assets.pii.assets.recordings"),
                                value: "Lydopptak"
                            },
                            {
                                label: t("wizard_title.assets.pii.assets.ipadr"),
                                value: "IP-adresser som kan knyttes til person"
                            },
                            {
                                label: t("wizard_title.assets.pii.assets.behav"),
                                value: "Adferdsdata"
                            },
                            {
                                label: t("wizard_title.assets.pii.assets.punish"),
                                value: "Straffedommer"
                            }
                        ],
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "personal_information"
                },
                {
                    type: "checkbox",
                    title: t("wizard_title.assets.pii.question3"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "ssn"
                },
                {
                    type: "bool",
                    title: t("wizard_title.assets.pii.question4"),
                    id: 3,
                    options: {
                        /*typeLoad: "Manual",
                        choices: [
                            {
                                label: "Nei",
                                value: false
                            },
                            {
                                label: "Ja",
                                value: true
                            }
                        ],*/
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "sensitivepo"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.pii.desc2"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "selectmulti",
                    title: t("wizard_title.assets.pii.question5"),
                    options: {
                        typeLoad: "Manual",
                        choices: [
                            {
                                label: t("wizard_title.assets.pii.scpii.race"),
                                value: "rasemessig eller etnisk opprinnelse"
                            },
                            {
                                label: t("wizard_title.assets.pii.scpii.politic"),
                                value: "politisk oppfatning"
                            },
                            {
                                label: t("wizard_title.assets.pii.scpii.religion"),
                                value: "religion"
                            },
                            {
                                label: t("wizard_title.assets.pii.scpii.philo"),
                                value: "filosofisk overbevisning"
                            },
                            {
                                label: t("wizard_title.assets.pii.scpii.union"),
                                value: "fagforeningsmedlemskap"
                            },
                            {
                                label: t("wizard_title.assets.pii.scpii.gen"),
                                value: "genetiske opplysninger"
                            },
                            {
                                label: t("wizard_title.assets.pii.scpii.biom"),
                                value: "biometriske opplysninger med det formål å entydig identifisere noen"
                            },
                            {
                                label: t("wizard_title.assets.pii.scpii.health"),
                                value: "helseopplysninger"
                            },
                            {
                                label: t("wizard_title.assets.pii.scpii.sexr"),
                                value: "seksuelle forhold"
                            },
                            {
                                label: t("wizard_title.assets.pii.scpii.sexp"),
                                value: "seksuell legning"
                            }
                        ],
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            },
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "personal_information_category"
                },
                {
                    type: "textarea",
                    title: t("wizard_title.assets.pii.desc3"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            },
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "description"
                },
                {
                    type: "divider",
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            },
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "text_title",
                    title: t("wizard_title.assets.pii.dataproc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            },
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.pii.dataproc2"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            },
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.pii.dataproc3"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            },
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "bool",
                    title: t("wizard_title.assets.pii.dataproc4"),
                    value: "",
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            },
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "consent"
                },
                {
                    type: "input",
                    title: t("wizard_title.assets.pii.dataproc5"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            },
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "legalauthority"
                },
                {
                    type: "select",
                    title: t("wizard_title.assets.pii.dataproc6"),
                    options: {
                        typeLoad: "Manual",
                        choices: [
                            {
                                label: t("wizard_title.assets.pii.dataproc6alt.no"),
                                value: "Ingen dataoverføring"
                            },
                            {
                                label: t("wizard_title.assets.pii.dataproc6alt.yesint"),
                                value: "Ja, til annen intern databehandler"
                            },
                            {
                                label: t("wizard_title.assets.pii.dataproc6alt.yesex"),
                                value: "Ja, til annen ekstern databehandler"
                            },
                            {
                                label: t("wizard_title.assets.pii.dataproc6alt.yesboth"),
                                value: "Ja, til både intern og ekstern databehandler"
                            },
                            {
                                label: t("wizard_title.assets.pii.dataproc6alt.dontknow"),
                                value: "Vet ikke"
                            }
                        ],
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            },
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "datatransferred"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.pii.dataproc7"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            },
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "select",
                    title: t("wizard_title.assets.pii.dataproc8"),
                    options: {
                        typeLoad: "Manual",
                        choices: [
                            {
                                label: t("wizard_title.assets.pii.dataproc8alt.yes"),
                                value: "Ja"
                            },
                            {
                                label: t("wizard_title.assets.pii.dataproc8alt.no"),
                                value: "Nei"
                            },
                            {
                                label: t("wizard_title.assets.pii.dataproc8alt.dontknow"),
                                value: "Vet ikke"
                            }
                        ],
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            },
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "data_processor_agreement"
                },
                {
                    type: "input",
                    title: t("wizard_title.assets.pii.dataproc9"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            },
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "data_processor_agreement_reference"
                },
                {
                    type: "dateselector",
                    title: t("wizard_title.assets.pii.dataproc90"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            },
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "data_processor_agreement_expiration"
                },
                {
                    type: "divider",
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "text_title",
                    title: "Verdivurdering",
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "select",
                    title: t("wizard_title.assets.pii.conf"),
                    options: {
                        typeLoad: "Manual",
                        choices: getSystemvalueOptionsByKIT("K"),
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "confidentiality"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.pii.confdesc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "select",
                    title: t("wizard_title.assets.pii.integrity"),
                    options: {
                        typeLoad: "Manual",
                        choices: getSystemvalueOptionsByKIT("I"),
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "integrity"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.pii.integritydesc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "textarea",
                    title: t("wizard_title.assets.pii.reason"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "reasoning"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.pii.reasondesc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 2,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                }
            ]
        }
    ]

    return tempWizardData;
}

export default PersoninformationTemplate;
