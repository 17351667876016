import React, { useState, useEffect } from "react";

import { InputGroup } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { useTranslate } from "react-polyglot";

import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";

import { generateUrl } from "../../../../../config";

function TagsField(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();

  const [tags, setTags] = useState<any>([]);
  const [chosenTags, setChosenTags] = useState<any>(
    props.value != undefined && props.value != ""
      ? props.value
      : props.defaultValue != undefined
      ? props.defaultValue
      : []
  );

  function handleChange(value) {
    setChosenTags(JSON.parse(JSON.stringify(value)));
    if (value != null) {
      let newTags = value.filter((i) => i.__isNew__ == true)[0];
      if (newTags != undefined) {
        createTag(newTags.label);
      }
    }

    if (props.onChange != undefined) {
      props.onChange(value != null ? value : []);
    }
  }

  async function fetchData(): Promise<any> {
    await axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/tags/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread(async (...res) => {
          let fetchedTags = res[0].data
            .filter((data) =>
              data.objectType.includes(props.options.objectType)
            )
            .map((i) => {
              return { value: i["_id"], label: i["name"] };
            });
          setTags(fetchedTags);
          if (
            props.options != undefined &&
            props.options.existingAliasData != undefined &&
            props.options.existingAliasData != ""
          ) {
            let aliasTag = fetchedTags.filter(
              (tag) => tag.label == props.options.existingAliasData
            );
            if (aliasTag.length == 0 || aliasTag == undefined) {
              createTag(props.options.existingAliasData);
            } else {
              if (
                !chosenTags
                  .map((tag) => tag != undefined && tag.label)
                  .includes(aliasTag[0].label)
              ) {
                let tempTags = chosenTags;
                tempTags.push({
                  label: aliasTag[0].label,
                  value: aliasTag[0].value,
                });
                setChosenTags(JSON.parse(JSON.stringify(tempTags)));
              }
            }
          }
        })
      );
  }

  function createTag(newTag) {
    axios
      .post(
        generateUrl("/api/cra/tags/"),
        {
          org: props.options.org,
          name: newTag,
          objectType: props.options.objectType,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (res) {
        let tempTags = chosenTags;
        tempTags.push({ label: res.data.name, value: res.data._id });
        setTags([...tags, { label: res.data.name, value: res.data._id }]);
        setChosenTags(JSON.parse(JSON.stringify(tempTags)));
        if (props.onChange != undefined) {
          props.onChange(tempTags != null ? tempTags : []);
        }
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setChosenTags(
      props.value != undefined && props.value != ""
        ? props.value
        : props.defaultValue != undefined
        ? props.defaultValue
        : []
    );
  }, [props.value]);

  return (
    <InputGroup className="mb-3">
      <InputGroup.Prepend>
        <InputGroup.Text>{props.title}</InputGroup.Text>
      </InputGroup.Prepend>
      <CreatableSelect
        className={"multiselect " + (props.disabled ? "disabled" : "")}
        closeMenuOnSelect={false}
        isDisabled={props.disabled}
        value={chosenTags}
        isMulti
        onChange={(vals) => handleChange(vals)}
        options={tags}
        noOptionsMessage={(value) =>
          t("fields.tagsfield.no_options_message") + " " + value.inputValue
        }
        placeholder={
          props.options != undefined && props.options.choosetext != undefined
            ? props.options.choosetext
            : t("fields.tagsfield.placeholder")
        }
        formatCreateLabel={(value) =>
          t("fields.tagsfield.create_tag") + " " + value
        }
      />
    </InputGroup>
  );
}

export default TagsField;
