import React, { useEffect, useState } from 'react';

import { useAxios } from "../../../../../AxiosHandler";
import { useTranslate } from 'react-polyglot';
import { useAuth } from "../../../../../Auth";
import { useHistory } from "react-router-dom";

import { Card, Table } from 'react-bootstrap';
import { HorizontalBar } from 'react-chartjs-2';

function SystemAssetsOverview(props){
    const t = useTranslate();
    const history = useHistory();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();

    const [valuetypesLength, setValuetypesLength] = useState<Array<any>>([]);
    const [valuetypesData, setValuetypesData] = useState<Array<any>>([]);
    const [selectedAssets, setSelectedAssets] = useState<Array<any>>([]);

    const customValueTypes = [
        t("system_asset.type.systemvaluecommunication"),
        t("system_asset.type.systemvaluecompanydata"),
        t("system_asset.type.systemvalueeconomy"),
        t("system_asset.type.systemvaluehardware"),
        t("system_asset.type.systemvalueotherinfo"),
        t("system_asset.type.systemvalueotherlawinfo"),
        t("system_asset.type.systemvaluepersoninformation"),
        t("system_asset.type.systemvalueusernamepassword")
    ]

    const data = {
        labels: customValueTypes,
        datasets: [
            {
                label: t("system_asset.type." + customValueTypes),
                backgroundColor: "rgb(250, 205, 117, 1)",
                borderWidth: 1,
                data: valuetypesLength,
                objects: valuetypesData
          },
        ],
    };

    const options = {
        onClick: function(event, elements) {
            if (elements.length > 0) {
                handleChartClick(event, elements);
        }},
        legend: {
            display: false
        },
        scales: {
            xAxes: [{
            stacked: true
            }],
            yAxes: [{
            stacked: true
            }],
        },
        tooltips: {
            mode: 'label',
            callbacks: {
                label: function(tooltipItem, data) {
                    return data['datasets'][0]['data'][tooltipItem['index']];
                }
            }
        }
    }

    function handleChartClick(evt, elements) {
        setSelectedAssets(valuetypesData[elements[0]._index]);
    }

    async function processData(res) {
        let tmpLength = [] as any;
        let tmpData = [] as any;

        tmpLength.push(res[0].data.filter(i => i.valueType == "systemvaluecommunication").length);
        tmpLength.push(res[0].data.filter(i => i.valueType == "systemvaluecompanydata").length);
        tmpLength.push(res[0].data.filter(i => i.valueType == "systemvalueeconomy").length);
        tmpLength.push(res[0].data.filter(i => i.valueType == "systemvaluehardware").length);
        tmpLength.push(res[0].data.filter(i => i.valueType == "systemvalueotherinfo").length);
        tmpLength.push(res[0].data.filter(i => i.valueType == "systemvalueotherlawinfo").length);
        tmpLength.push(res[0].data.filter(i => i.valueType == "systemvaluepersoninformation").length);
        tmpLength.push(res[0].data.filter(i => i.valueType == "systemvalueusernamepassword").length);
        setValuetypesLength(tmpLength);

        tmpData.push(res[0].data.filter(i => i.valueType == "systemvaluecommunication"));
        tmpData.push(res[0].data.filter(i => i.valueType == "systemvaluecompanydata"));
        tmpData.push(res[0].data.filter(i => i.valueType == "systemvalueeconomy"));
        tmpData.push(res[0].data.filter(i => i.valueType == "systemvaluehardware"));
        tmpData.push(res[0].data.filter(i => i.valueType == "systemvalueotherinfo"));
        tmpData.push(res[0].data.filter(i => i.valueType == "systemvalueotherlawinfo"));
        tmpData.push(res[0].data.filter(i => i.valueType == "systemvaluepersoninformation"));
        tmpData.push(res[0].data.filter(i => i.valueType == "systemvalueusernamepassword"));
        setValuetypesData(tmpData);
    }

    useEffect(() => {
        if (auth.user["userdata"] == undefined) { window.location.href = "/login"; }
        Promise.all([
            props.requestData("systemvalues/bysystemaccess")
        ]).then(async (data) => {
            await processData(data);
        });
    }, []);

    return (
        <Card>
            <Card.Body>
                <h4 className="header-title">{t("dashboard.cards.systemassetsoverview.title")}</h4>
                <p>{t("dashboard.cards.systemassetsoverview.information")}</p>
                <hr /> 

                <div>
                    <HorizontalBar
                        data={data}
                        options={options}
                    />                
                </div>
                {selectedAssets != undefined && selectedAssets.length > 0 ?
                    <div className="chartDetails">
                        <div>
                            <h4 className="header-title">{t("dashboard.cards.systemassetsoverview.details.title")}</h4>
                            <Table striped hover className="chartdetailstable">
                                <thead>
                                    <tr>
                                        <th>{t("dashboard.cards.systemassetsoverview.details.system")}</th>
                                        <th>{t("dashboard.cards.systemassetsoverview.details.name")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        selectedAssets.map((element, key) => {
                                            return(
                                                <tr key={key} style={{cursor: "pointer"}} onClick={() => {
                                                    history.push("/system/valueeval/" + element.system);
                                                    window.scrollTo(0, 0);
                                                }}>
                                                    <td>{ element["systemName"] }</td>
                                                    <td>{ element["name"] }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                : null}

            </Card.Body>
        </Card>
    );
}

export default SystemAssetsOverview;