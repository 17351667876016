import * as React from "react";
import { useHistory } from "react-router-dom";

import "./RiskRegistry.scss";
import { Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";
import { useTranslate } from "react-polyglot";
import "react-toastify/dist/ReactToastify.css";

import PageTable from "../../Shared/PageTable/PageTable";

import SiteAssistant from "../../Shared/SiteAssistant/SiteAssistant";
import DiriFaceDocked from "../../../Assets/Images/diriassistant/diri.svg";
import { generateUrl } from "../../../config";

function RiskRegistry() {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();
  const history = useHistory();

  const labelLow = t("treatment.label_low");
  const labelMiddel = t("treatment.label_middel");
  const labelHigh = t("treatment.label_high");
  const labelExtremelyHigh = t("treatment.label_extremely_high");

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  function percentageToValue(percentage, maxvalue) {
    return Math.round((maxvalue - 1) * (percentage / 100) + 1);
  }

  function getText(value, itemx, itemy) {
    if (itemx > 75 || itemy > 75) return labelExtremelyHigh;
    if (value > 5000) return labelExtremelyHigh;
    if (value > 2000 && value <= 5000) return labelHigh;
    if (value > 1000 && value <= 2000) return labelMiddel;
    if (value <= 1000) return labelLow;

    /* switch (value) {
            case 1: return labelLow;
            case 2: return labelMiddel;
            case 3: return labelHigh;
            case 4: return labelExtremelyHigh;
        } */
  }

  function getRisk(riskitem) {
    /* let causeProb = (riskitem["cause"]["probability"] * riskitem["causeReduction"].reduce((acc, val) => ((100 - val) / 100) * acc, 1));
        let conseqProb = (riskitem["consequence"]["consequenceValue"] * riskitem["consequenceReduction"].reduce((acc, val) => ((100 - val) / 100) * acc, 1)); */

    /* let itemXCalc = riskitem["consequence"]["consequenceValue"] * (riskitem["consequenceReduction"].reduce((acc, val) => ((100 - val) / 100) * acc, 1));
        let itemYCalc = riskitem["cause"]["probability"] * (riskitem["causeReduction"].reduce((acc, val) => ((100 - val) / 100) * acc, 1));
 */
    /* let itemX = percentageToValue(itemXCalc, settings["gridX"]);
        let itemY = percentageToValue(itemYCalc, settings["gridY"]); */

    let itemXPercent =
      riskitem["consequence"]["consequenceValue"] *
      riskitem["consequenceReduction"].reduce(
        (acc, val) => ((100 - val) / 100) * acc,
        1
      );
    let itemYPercent =
      riskitem["cause"]["probability"] *
      riskitem["causeReduction"].reduce(
        (acc, val) => ((100 - val) / 100) * acc,
        1
      );

    if (itemYPercent < 1) itemYPercent = 1;
    if (itemXPercent < 1) itemXPercent = 1;

    /* if (causeProb < 0) {causeProb = 0};
        if (conseqProb < 0) {conseqProb = 0}; */

    let score = itemXPercent * itemYPercent;
    //let score = causeProb + conseqProb;

    return getText(score, itemXPercent, itemYPercent);
    //return getText(percentageToValue((score/2) + 1, 4));
  }

  function getSystemTypeText(systemtype) {
    let systemtypes: Array<string> = ["system", "orgros", "other"];

    let pattern = /system/;

    if (
      systemtypes.map((i) => i.toLowerCase()).includes(systemtype.toLowerCase())
    ) {
      return t("systems.systems_types." + systemtype.toLowerCase());
    } else if (pattern.test(systemtype.toLowerCase())) {
      return t("systems.systems_types.system");
    } else {
      return systemtype;
    }
  }

  return (
    <div className="maincontent">
      <SiteAssistant dockedLogo={DiriFaceDocked} featurekey="riskregistry" />
      <div className="systems-container">
        <div className="pagetitle-container">
          <h1>{t("riskregistry.page_name")}</h1>
        </div>
        <Card>
          <Card.Body>
            <PageTable
              dataUrl={generateUrl("/api/cra/riskitems/orgrisk/getAllPaged/")}
              options={{
                showFields: [
                  {
                    label: t("riskregistry.risk"),
                    field: "risk",
                    override: (lineItem) => getRisk(lineItem),
                    disableFilter: true,
                  },
                  {
                    label: t("riskregistry.system_type"),
                    field: "systemType",
                    override: (dataLine) =>
                      dataLine["systemType"] != undefined &&
                      dataLine["systemType"][0] != undefined
                        ? getSystemTypeText(dataLine["systemType"][0])
                        : "",
                    customFilters: [
                      {
                        label: t("systems.systems_types.system"),
                        value: "system",
                      },
                      {
                        label: t("systems.systems_types.orgros"),
                        value: "orgros",
                      },
                      {
                        label: t("systems.systems_types.other"),
                        value: "other",
                      },
                    ],
                  },
                  { label: t("riskregistry.system_name"), field: "systemName" },
                  { label: t("riskregistry.cause_name"), field: "causeName" },
                  {
                    label: t("riskregistry.occurrence_name"),
                    field: "occurenceName",
                  },
                  {
                    label: t("riskregistry.consequence_name"),
                    field: "consequenceName",
                  },
                  { label: t("riskregistry.threats"), field: "threats" },
                  { label: t("riskregistry.values"), field: "values" },
                  {
                    label: t("riskregistry.responsible"),
                    field: "responsible",
                  },
                ],
                pageSize: 25,
              }}
              defaultSortField="risk"
              defaultSortDir="defaultSortDirection"
              onLineClick={(dataLine) =>
                history.push("/system/" + dataLine.systemId)
              }
              hidePagination={false}
              hidePagesize={false}
              hideFilter={false}
              hover={true}
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default RiskRegistry;
