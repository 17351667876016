import * as React from 'react';

import { useEffect, useState } from 'react';

function CostFormatter(props) {

    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    function currencySplitter(number) {
        if(number != null) {
            let numberarray = number.toString().split("");
            numberarray.splice(-3, 0, " ");
            numberarray.splice(-7, 0, " ");
            numberarray.splice(-11, 0, " ");
            numberarray.splice(-15, 0, " ");
            return numberarray.join("");
        } else {
            return "N/A"
        }
    }

    useEffect(() => {
        
    }, []);

    return (
        <span>
            { props.children !== undefined && props.children !== "" ?
                (settings["currencySymbolBefore"] != undefined && settings["currencySymbolBefore"] != "" ? settings["currencySymbolBefore"] : "") +
                currencySplitter(props.children) +
                (settings["currencySymbolAfter"] != undefined && settings["currencySymbolAfter"] != "" ? settings["currencySymbolAfter"] : "")
            : null }
        </span>
    );
}
 
export default CostFormatter;