import * as React from 'react';
import { useHistory } from 'react-router'

import { useAuth } from '../../../../../Auth';

import { useState, useEffect } from 'react';
import { useAxios } from '../../../../../AxiosHandler';
import { Modal, Button, Table, Card } from 'react-bootstrap';
import { useTranslate } from 'react-polyglot';

import 'react-toastify/dist/ReactToastify.css';
import './CustomerOverview.scss';
import "../../../../Shared/Shop/Shop.scss";

import { toast } from 'react-toastify';

import { useDynamicLanguage } from '../../../../../DynamicLanguageProvider';
import Field from '../../../../Shared/Wizard/Field/Field';
import PageTable from '../../../../Shared/PageTable/PageTable';

import {Elements, PaymentElement } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import SetupForm from './SetupForm/SetupForm';
import { VATtypes } from '../../../../../Assets/Constants/VATtypes';
import { generateUrl } from '../../../../../config';
const stripePromise = loadStripe('pk_test_51LGjMzIdjUyQ0KQkwO3NL7pjQ9Y5zc17Snpk8PgXlY8e4CMyPJqK12TpvDXGYcx9g3wsROMHkkvbBnZY261gWlxa00ANztf19e');

function CustomerOverview(props) {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const t = useTranslate();
    const history = useHistory();
    const td = useDynamicLanguage().td;

    const notifySuccess = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "success-toast"
    });

    const notifyWarning = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "warning-toast"
    });

    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    let userdata = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
        userdata = JSON.parse(localStorageUser)["userdata"];
    }

    const [updateTable, setUpdateTable] = useState(0);

    const [clientSecret, setClientSecret] = useState("");
    const [setupIntentId, setSetupIntentId] = useState("");
    const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);
    const [updatePaymentMethodsList, setUpdatePaymentMethodsList] = useState(0);

    const [showSubDetailsModal, setShowSubDetailsModal] = useState(false);
    const [subDetailsObj, setSubDetailsObj] = useState({});
    const [loadingSavePaymentInterval, setLoadingSavePaymentInterval] = useState(false);

    const options = {
        clientSecret: clientSecret
    };


    const [allOrganizationRoles, setAllOrganizationRoles] = useState<Array<any>>([]);
    const [org, setOrg] = useState<Array<any>>([]);

    const [showCustomerInfoModal, setShowCustomerInfoModal] = useState(false);
    const [customerInfoSub, setCustomerInfoSub] = useState(undefined) as any;
    const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true);

    const [editCustomerName, setEditCustomerName] = useState("");
    const [editCustomerEmail, setEditCustomerEmail] = useState("");

    const [showInvoicesModal, setShowInvoicesModal] = useState(false);
    const [subIdForInvoices, setSubIdForInvoices] = useState("");

    const [showCancelSubscriptionWarningModal, setShowCancelSubscriptionWarningModal] = useState(false);

    const [hasSubs, setHasSubs] = useState({})

    const [vatid, setVatid] = useState("");
    const [vattype, setVattype] = useState("");

    function handleShowInvoicesModal(subid){
        setSubIdForInvoices(subid);
        setShowInvoicesModal(true);
    }
    function handleShowSubDetailsModal(sub){
        console.log(sub)
        setSubDetailsObj(sub)
        setShowSubDetailsModal(true);
    }

    function handleShowCustomerInformationModal(sub){
        setCustomerInfoSub(sub);
        //setEditCustomerName(sub.customer.name.substring(sub.customer.name.indexOf(') ') + 1));
        setEditCustomerName(sub.customer.name);
        setEditCustomerEmail(sub.customer.email);
        setShowCustomerInfoModal(true);
    }
    function handleCloseCustomerInformationModal(){
        setCustomerInfoSub({});
        setShowCustomerInfoModal(false);
    }

    function handleShowCancelSubscriptionWarningModal(subid){

    }

    function fetchData() {
        axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/organizationRoles/"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/orgelements/") +  auth.user["userdata"]["unit"][0],
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/stripe/hasSubs/") +  auth.user["userdata"]["unit"][0],
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
        ]).then((res) => {
            setAllOrganizationRoles(res[0].data);
            setOrg(res[1].data[0]);
            setVatid(res[1].data[0].vatid);
            setVattype(res[1].data[0].vattype);
            setLoadingPaymentMethods(false);
            setHasSubs(res[2].data);
        });
    }

    function printDate(d){
        if(d){
            let date = new Date(new Date(d).getTime() + new Date(d).getTimezoneOffset()/60).getTime();

            let dateYear = new Date(date).getFullYear();
            let dateMonth = new Date(date).getMonth() + 1;
            let dateDay = new Date(date).getDate();
            let dateHour = new Date(date).getHours();
            let dateMinute = new Date(date).getMinutes();

            return dateYear + "-" + ((dateMonth.toString().length == 1) ? ("0" + dateMonth) : dateMonth) + "-" + ((dateDay.toString().length == 1) ? ("0" + dateDay) : dateDay);
        } else {
            return "";
        }
        
    }

    function cancelSubscription(subId){
        axios.post(
            generateUrl("/api/cra/stripe/cancel-subscription"),
            {
                subId: subId
            },{
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setUpdateTable(updateTable + 1);
            setShowSubDetailsModal(false);
            fetchData();
            notifySuccess(t("customeroverview.toasts.subCanceled"));
        });
    }
    function reactivateSubscription(subId){
        axios.post(
            generateUrl("/api/cra/stripe/reactivate-subscription"),
            {
                subId: subId
            },{
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setUpdateTable(updateTable + 1);
            setShowSubDetailsModal(false);
            fetchData();
            notifySuccess(t("customeroverview.toasts.subReactivated"));
        });
    }

    /* function removeReceivedByDiri(roleid){
        axios.put(
            "https://test.diri.ai/api/orgelements/removeReceivedByDiri/byorg/" + auth.user["userdata"]["unit"][0],
            {
                roleid: roleid
            },{
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            fetchData();
            notifySuccess("Subscription canceled");
        });
    } */

    function updateCustomerInfo(customerId){
        if(customerId != undefined){
            axios.put(
                generateUrl("/api/cra/stripe/saveCustomerInfo"),
                {
                    customerid: customerId,
                    customeremail: editCustomerEmail,
                    customername: editCustomerName
                },{
                    headers: {'x-access-token': auth.user["token"]}
                }
            ).then(res => {
                fetchData();
                handleCloseCustomerInformationModal();
                setShowSubDetailsModal(false);
                notifySuccess(t("customeroverview.toasts.customerInformationChanged"));
            });
        }
    }

    function detachPaymentMethod(method, sub){
        if(method.id != undefined){
            axios.put(
                generateUrl("/api/cra/stripe/detachPaymentMethod"),
                {
                    methodid: method.id,
                    subid: sub.id
                },{
                    headers: {'x-access-token': auth.user["token"]}
                }
            ).then(res => {
                fetchData();
                handleCloseCustomerInformationModal();
                notifySuccess(t("customeroverview.toasts.customerInformationChanged"));
            });
        }
    }

    function updatePaymentMethods(result){
        if(result.setupIntent.status != "succeeded") return;
        setShowAddPaymentMethodModal(false);
        setLoadingPaymentMethods(true);
        axios.post(
            generateUrl("/api/cra/stripe/updatePaymentMethod"),
            {
                paymentMethodId: result.setupIntent.payment_method,
                customerId: customerInfoSub["customer"]?.id,
                subscriptionId: customerInfoSub["subscription"]?.id,
            },{
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            
            fetchData();
            setUpdatePaymentMethodsList(updatePaymentMethodsList + 1);
            
        });
    }

    function createSetupIntent(){
        axios.post(
            generateUrl("/api/cra/stripe/createSetupIntent"),
            {
                paymentMethodType: "card",
                customerid: customerInfoSub["customer"]?.id
            },{
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setClientSecret(res.data.client_secret);
            setSetupIntentId(res.data.id);
            setShowAddPaymentMethodModal(true);
            /* fetchData();
            notifySuccess("Subscription canceled"); */
        });
    }

    function saveVatid(){
        axios.put(generateUrl('/api/cra/stripe/vatid/') + auth.user["userdata"]["unit"][0], {
            vatid: vatid,
            vattype: vattype
        }, {
            headers: {'x-access-token': auth.user["token"]}
        }).then(function (response) {
            if(response.data.foundError === true){
                notifyWarning(response.data?.error?.raw?.code == "tax_id_invalid" ? t("customeroverview.toasts.tax_id_invalid") : "Oops, something went wrong. Please try again later.");
            } else {
                notifySuccess(t("customeroverview.toasts.generalChangesSaved"));
            }
            
            fetchData();
        });
    }

    function savePaymentInterval(interval){
        setLoadingSavePaymentInterval(true);
        axios.put(
            generateUrl("/api/cra/stripe/changeSubscriptionInterval/") + interval,
            {
                subId: subDetailsObj?.["subscription"]?.["id"],
                orgId: subDetailsObj?.["orgId"],
                orgRole: subDetailsObj?.["orgRole"]
            },{
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setUpdateTable(updateTable + 1);
            fetchData();
            setShowSubDetailsModal(false);
            notifySuccess(t("customeroverview.toasts.paymentIntervalSaved"));
            setLoadingSavePaymentInterval(false);
        });
    }

    function cancelChangePaymentInterval(scheduleid){
        setLoadingSavePaymentInterval(true);
        axios.put(
            generateUrl("/api/cra/stripe/cancelChangeSubscriptionInterval/") + scheduleid,
            {
                subId: subDetailsObj?.["subscription"]?.["id"],
                orgId: subDetailsObj?.["orgId"],
                orgRole: subDetailsObj?.["orgRole"]
            },{
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setUpdateTable(updateTable + 1);
            fetchData();
            setShowSubDetailsModal(false);
            notifySuccess(t("customeroverview.toasts.paymentIntervalCanceled"));
            setLoadingSavePaymentInterval(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="customerOverview-container">
            <div className="pagetitle-container">
                <h1>{t("organzation.customer_relationship")}</h1>
            </div>
            <Card className="customeroverview-general">
                <Card.Body>
                    <h4 className="header-title">{t("customeroverview.general.title")}</h4>
                    <div className="customeroverview-general-fields">
                        <Field
                            type="select"
                            disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.edit != "allow"}
                            title={t("customeroverview.general.vattype")}
                            value={vattype}
                            onChange={val => setVattype(val.value)}
                            options={{
                                choosetext: t("customeroverview.general.choose"),
                                choices: VATtypes.map(type => { return {label: type.country + " (" + type.description + ")", value: type.enum}})
                            }}
                        ></Field>
                        <Field
                            type="input"
                            disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.edit != "allow" || vattype == ""}
                            title={t("customeroverview.general.vatid")}
                            value={vatid}
                            onChange={val => setVatid(val)}
                            placeholder={vattype != "" ? "Ex.: " + VATtypes?.find(type => type.enum == vattype)?.example ?? "" : ""}
                        ></Field>
                    </div>
                    
                    <Button onClick={() => saveVatid()}>{t("customeroverview.general.save")}</Button>
                </Card.Body>
            </Card>
                <Card>
                    <Card.Body>
                        
                        <h4 className="header-title">{t("customeroverview.active.title")}</h4>
                        
                        {hasSubs?.["activeSubs"] == true ?
                            <>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="total-active-price">
                                        <p>{t("customeroverview.active.totalMonthlyCost") + " " + hasSubs?.["totalActivePrice"] / 100 + " " + hasSubs?.["currency"]}*</p>
                                        <p>*{t("shop.excludingVAT")}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="total-active-price">
                                        <p>{t("customeroverview.active.totalAnnualCost") + " " + hasSubs?.["totalAnnualActivePrice"] / 100 + " " + hasSubs?.["annualCurrency"]}*</p>
                                        <p>*{t("shop.excludingVAT")}</p>
                                    </div>
                                </div>
                            </div>
                                
                                
                                <PageTable
                                    dataUrl={generateUrl("/api/cra/stripe/getAllPaged/subscriptions/byorg/active/") + auth.user["userdata"]["unit"][0]}
                                    options={{
                                        showFields: [
                                            { label: t("customeroverview.table.name"), field: "name"},
                                            { label: t("customeroverview.table.price"), field: "price", override: dataLine => dataLine["price"] == "receivedByDiri" ? t("customeroverview.table.receivedByDiri") : dataLine["price"] == "trial" ? t("customeroverview.table.trial") : (dataLine["price"] + " " + ((dataLine["subscription"]["plan"]["interval"] == "month") ? t("shop.perMonth") : t("shop.perYear")) + " (" + t("shop.excludingVAT") + ")") },
                                            //{ label: t("customeroverview.table.status"), field: "status", override: dataLine => !dataLine["subscription"]["cancel_at_period_end"] ? t("customeroverview.table.statuses." + dataLine["status"]) : t("customeroverview.table.statuses.cancelAtPeriodEnd") },
                                            { label: t("customeroverview.table.status"), field: "status", override: dataLine => t("customeroverview.table.statuses." + dataLine["status"])},
                                            { label: t("customeroverview.table.startedDate"), field: "startedDate"},
                                            { label: t("customeroverview.table.nextPayment"), field: "nextPayment", override: dataLine => (dataLine["specialTrial"] || dataLine["subscription"]["cancel_at_period_end"]) ? t("customeroverview.table.cancelsAt") + " " + dataLine["nextPayment"] : dataLine["nextPayment"]},
                                            { label: t("customeroverview.table.startedByUser"), field: "startedByUser", override: dataLine => dataLine["startedByUser"] == "receivedByDiri" ? t("customeroverview.table.receivedByDiri") : dataLine["startedByUser"]},
                                            { label: t("customeroverview.table.defaultPaymentMethod"), field: "defaultPaymentMethod", override: dataLine => dataLine["defaultPaymentMethod"] == "invoicing" ? t("customeroverview.table.invoicing") : dataLine["defaultPaymentMethod"]},
                                        ]
                                    }}
                                    dataAfter={ dataLine => !dataLine["specialTrial"] ? 
                                        <>
                                            <Button onClick={() => handleShowSubDetailsModal(dataLine)}>{t("customeroverview.table.showSubDetails")}</Button>
                                            <br />
                                            <Button onClick={() => handleShowInvoicesModal(dataLine.subscription.id)}>{t("customeroverview.table.showInvoices")}</Button>
                                            
                                        </> 
                                    :
                                        null
                                    }
                                    updateTable={updateTable}
                                    hidePagination= {false}
                                    hidePagesize= {false}
                                    hideFilter= {false}
                                    hover={true}
                                />
                            </>
                        : 
                            <p>{t("customeroverview.nothingToShow")}</p>
                        }
                
                        <h4 className="header-title">{t("customeroverview.canceled.title")}</h4>
                        {hasSubs?.["canceledSubs"] == true ?
                            <PageTable
                                dataUrl={generateUrl("/api/cra/stripe/getAllPaged/subscriptions/byorg/canceled/") + auth.user["userdata"]["unit"][0]}
                                options={{
                                    showFields: [
                                        { label: t("customeroverview.table.name"), field: "name"},
                                        { label: t("customeroverview.table.price"), field: "price", override: dataLine => dataLine["price"] == "receivedByDiri" ? t("customeroverview.table.receivedByDiri") : dataLine["price"] == "trial" ? t("customeroverview.table.trial") : (dataLine["price"] + " " + ((dataLine["subscription"]["plan"]["interval"] == "month") ? t("shop.perMonth") : t("shop.perYear")) + " (" + t("shop.excludingVAT") + ")")},
                                        { label: t("customeroverview.table.status"), field: "status", override: dataLine => t("customeroverview.table.statuses." + dataLine["status"]) },
                                        { label: t("customeroverview.table.startedDate"), field: "startedDate"},
                                        { label: t("customeroverview.table.nextPayment"), field: "nextPayment"},
                                        { label: t("customeroverview.table.startedByUser"), field: "startedByUser"},
                                        { label: t("customeroverview.table.defaultPaymentMethod"), field: "defaultPaymentMethod", override: dataLine => dataLine["defaultPaymentMethod"] == "invoicing" ? t("customeroverview.table.invoicing") : dataLine["defaultPaymentMethod"]},
                                    ]
                                }}
                                dataAfter={dataLine =>  !dataLine["specialTrial"] ? <>
                                    <Button onClick={() => handleShowCustomerInformationModal(dataLine)}>{t("customeroverview.table.showCustomerInfo")}</Button>
                                    <Button onClick={() => handleShowInvoicesModal(dataLine.subscription.id)}>{t("customeroverview.table.showInvoices")}</Button>
                                </> : null }
                                hidePagination= {false}
                                hidePagesize= {false}
                                hideFilter= {false}
                                hover={true}
                            />
                        : 
                            <p>{t("customeroverview.nothingToShow")}</p>
                        }
                    </Card.Body>
                </Card>
            <Card>
                <Card.Body>
                    <h4 className="header-title">{t("customeroverview.purchase_history.title")}</h4>
                    {hasSubs?.["shopEvents"] == true ?
                        <PageTable
                            dataUrl={generateUrl("/api/cra/shopEvents/getAllPaged/byOrgId/") + auth.user["userdata"]["unit"][0]}
                            options={{
                                showFields: [
                                    { label: t("customeroverview.table.type"), field: "type", override: dataLine => t("customeroverview.table.shopEventTypes." + dataLine["type"])},
                                    { label: t("customeroverview.table.name"), field: "itemName"},
                                    { label: t("customeroverview.table.price"), field: "itemPrice", override: dataLine => dataLine["type"] == "addedByDiri" || dataLine["type"] == "removeAddedByDiri" ? "---"  : dataLine["itemPrice"] + (dataLine["currency"] != undefined ? " " + dataLine["currency"] : "") + " (" + t("shop.excludingVAT") + ")"},
                                    { label: t("customeroverview.table.user"), field: "userName"},
                                    { label: t("customeroverview.table.date"), field: "dateReadable"},
                                ]
                            }}
                            hidePagination= {false}
                            hidePagesize= {false}
                            hideFilter= {false}
                            hover={true}
                        />
                    :
                        <p>{t("customeroverview.nothingToShow")}</p>
                    }
                </Card.Body>
            </Card>

            <Modal show={showCustomerInfoModal} onHide={handleCloseCustomerInformationModal}>
                {customerInfoSub != undefined ?
                    <div>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("customeroverview.editCustomerInfo.title") + " " + td(customerInfoSub["orgRole"]?.name)}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Field
                                type="input"
                                title={t("customeroverview.editCustomerInfo.customerName")}
                                value={editCustomerName}
                                onChange={val => setEditCustomerName(val)}
                                disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.handle_payment != "allow"}
                            ></Field>
                            <Field
                                type="input"
                                title={t("customeroverview.editCustomerInfo.customerEmail")}
                                value={editCustomerEmail}
                                onChange={val => setEditCustomerEmail(val)}
                                disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.handle_payment != "allow"}
                            ></Field>
                            <div key={updatePaymentMethodsList}>
                                {loadingPaymentMethods ? 
                                    <div>
                                        <p>{t("customeroverview.loading")}</p>
                                    </div>
                                : 
                                    <div className="paymentMethods">
                                        {
                                            customerInfoSub?.subscription?.collection_method == "send_invoice" ? 
                                                <div className="paymentMethod">
                                                    <p>{t("customeroverview.editCustomerInfo.invoice_payment")}</p>
                                                </div>
                                            : customerInfoSub?.["paymentMethods"]?.["data"]?.map((method, key) => {
                                                if(method.type == "card"){
                                                    return(
                                                        <div key={key} className="paymentMethod">
                                                            <div className="cardDetails">
                                                                <div className="cardDetails_brand_no">
                                                                    {/* <p className="card_brand">{method.card.brand}</p> */}
                                                                    <p className="card_no">{method.card.brand} **** {method.card.last4}</p>
                                                                </div>
                                                                <div className="cardDetails_exp">
                                                                    <p className="card_exp">{t("customeroverview.editCustomerInfo.expires") + " " + method.card.exp_month} / {method.card.exp_year}</p>
                                                                </div>
                                                                <div className="card_name">
                                                                    <p >{t("customeroverview.editCustomerInfo.cardholderName") + " " + method.billing_details.name}</p>
                                                                    <p >{t("customeroverview.editCustomerInfo.cardholderEmail") + " " + method.billing_details.email}</p>
                                                                </div>
                                                            </div>
                                                            {customerInfoSub?.status != "active" ? 
                                                                <div className="paymentMethodDelete">
                                                                    <i className="dripicons-trash" onClick={() => detachPaymentMethod(method, customerInfoSub.subscription)}/>
                                                                </div>
                                                            : null }
                                                            
                                                            
                                                            {/* <div>
                                                                <Button 
                                                                    onClick={() => detachPaymentMethod(method)}
                                                                    disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.handle_payment != "allow"}
                                                                >{t("customeroverview.editCustomerInfo.detachPaymentMethod")}</Button>
                                                            </div> */}
                                                        </div>
                                                    )
                                                }
                                                
                                            }) ?? null
                                        }
                                        { customerInfoSub?.subscription?.collection_method == "send_invoice" ? 
                                            null 
                                        :
                                            <Button 
                                                onClick={() => createSetupIntent()}
                                                disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.handle_payment != "allow"}
                                            >{t("customeroverview.editCustomerInfo.replacePaymentMethod")}</Button>
                                        }
                                    </div>
                                }
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="cancel posleft" onClick={handleCloseCustomerInformationModal}>{t("customeroverview.editCustomerInfo.cancel")}</Button>
                            <Button 
                                onClick={() => updateCustomerInfo(customerInfoSub["customer"]?.id ?? undefined)}
                                disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.handle_payment != "allow"}
                            >{t("customeroverview.editCustomerInfo.save")}</Button>
                        </Modal.Footer>
                    </div>
                : null}
            </Modal>
            <Modal show={showAddPaymentMethodModal} onHide={() => setShowAddPaymentMethodModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Elements stripe={stripePromise} options={options}>
                    <SetupForm
                        clientSecret={clientSecret}
                        setupIntentId={setupIntentId}
                        onHide={(result) => {updatePaymentMethods(result)}}
                    />
                </Elements>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="cancel" onClick={() => setShowAddPaymentMethodModal(false)}>{t("customeroverview.editCustomerInfo.cancel")}</Button>  
                </Modal.Footer>
            </Modal>
            <Modal className="invoiceModal" show={showInvoicesModal} size="lg" onHide={() => {setSubIdForInvoices(""); setShowInvoicesModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <PageTable
                            dataUrl={generateUrl("/api/cra/stripe/getAllPaged/invoices/bysubscription/") + subIdForInvoices}
                            options={{
                                showFields: [
                                    { label: t("customeroverview.table.status"), field: "status", override: dataLine => t("customeroverview.table.statuses." + dataLine["status"]) },
                                    { label: t("customeroverview.table.period_start"), field: "period_start_readable"},
                                    { label: t("customeroverview.table.period_end"), field: "period_end_readable"},
                                    { label: t("customeroverview.table.paid_date"), field: "paid_date_readable"},
                                    { label: t("customeroverview.table.subtotal"), field: "subtotal_readable", override: dataLine => dataLine["subtotal_readable"] + " " + dataLine["currency"] + " (" + t("shop.excludingVAT") + ")" },
                                ]
                            }}
                            dataAfter={dataLine => <>
                                {dataLine.receipt == undefined ?
                                    <Button disabled={dataLine.invoice_pdf == null}><a href={dataLine.invoice_pdf}>{t("customeroverview.table.downloadInvoice")}</a></Button>
                                : 
                                    <Button disabled={dataLine.receipt == undefined}><a href={dataLine.receipt} target="_blank">{t("customeroverview.table.openReceipt")}</a></Button>
                                }
                            </> }
                            hidePagination= {false}
                            hidePagesize= {false}
                            hideFilter= {false}
                            hover={true}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {setSubIdForInvoices(""); setShowInvoicesModal(false)}}>{t("customeroverview.subscriptionDetails.close")}</Button>   
                </Modal.Footer>
            </Modal>
            <Modal className="subscriptionDetails" show={showSubDetailsModal} size="lg" onHide={() => {setShowSubDetailsModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("customeroverview.subscriptionDetails.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="shop">
                                <div className="shopItems">
                                    <div className="shopItem-container">
                                        <div className="shopItem">
                                            <div className="description">
                                                <h3>{td(subDetailsObj?.["orgRole"]?.["name"])}</h3>
                                                <p className="shopItem-price">{(subDetailsObj?.["price"])} <span>{subDetailsObj?.["subscription"]?.["plan"]?.["interval"] == "year" ? t("shop.perYear") : t("shop.perMonth")}*</span></p>
                                                <p className="shopItem-excludingVAT">*{t("shop.excludingVAT")}</p>
                                                <p className="shopItem-name">{td(subDetailsObj?.["orgRole"]?.["name"])}</p>
                                                {subDetailsObj?.["orgRole"]?.["description"] != "" ?
                                                    <div className="shopItem-desc">
                                                        <p className="shopItem-descParagraph">{td(subDetailsObj?.["orgRole"]?.["description"])}</p>
                                                    </div>
                                                : null}
                                                
                                                <ul>
                                                    {subDetailsObj?.["orgRole"]?.["contentList"]?.map((item, key) => {
                                                        return <li key={key}><span className="shopItem-listIcon"><i className="dripicons-checkmark" /></span><span>{td(item)}</span></li>
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="subDetailsBtns">
                                <div className="row">
                                    {subDetailsObj?.["schedule"] != undefined && subDetailsObj?.["futurePrice"] != undefined ?
                                        <div>
                                            <p>{t("customeroverview.subscriptionDetails.subWillUpdate")} {subDetailsObj?.["futurePrice"]?.["recurring"]?.["interval"] == "year" ? t("customeroverview.subscriptionDetails.annualPayment") : subDetailsObj?.["futurePrice"]?.["recurring"]?.["interval"] == "month" ? t("customeroverview.subscriptionDetails.monthlyPayment") : subDetailsObj?.["futurePrice"]?.["recurring"]?.["interval"]}, {printDate((subDetailsObj?.["schedule"]?.["phases"][1].start_date) * 1000)}.</p>
                                        </div>
                                    : null}
                                    {subDetailsObj?.["schedule"] == undefined || subDetailsObj?.["futurePrice"] == undefined ?
                                        <Button 
                                            disabled={loadingSavePaymentInterval || auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.handle_payment != "allow"} 
                                            onClick={() => 
                                                savePaymentInterval((subDetailsObj?.["subscription"]?.["plan"]?.["interval"] == "month") ? "year" : "month")}
                                        >
                                            {subDetailsObj?.["subscription"]?.["plan"]?.["interval"] == "year" ? t("customeroverview.subscriptionDetails.changeMonthlyPayment") : t("customeroverview.subscriptionDetails.changeAnnualPayment")}
                                        </Button>
                                    :
                                        <Button 
                                            disabled={loadingSavePaymentInterval || auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.handle_payment != "allow"}
                                            onClick={() => cancelChangePaymentInterval(subDetailsObj?.["schedule"]?.["id"])}
                                        >
                                            {t("customeroverview.subscriptionDetails.cancelChangeInterval") }
                                        </Button>
                                    }
                                </div>
                                <div className="row">
                                    <Button onClick={() => handleShowCustomerInformationModal(subDetailsObj)}>{t("customeroverview.table.showCustomerInfo")}</Button>
                                </div>
                                <div className="row cancelBtnRow">
                                    {subDetailsObj?.["subscription"]?.["cancel_at_period_end"] != true ?
                                        <Button 
                                            onClick={() => setShowCancelSubscriptionWarningModal(true)}
                                            disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.handle_payment != "allow"}
                                        >{t("customeroverview.table.cancel")}</Button>
                                    : 
                                        <Button 
                                            onClick={() => reactivateSubscription(subDetailsObj?.["subscription"]?.id)}
                                            disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.handle_payment != "allow"}
                                        >{t("customeroverview.table.reactivate")}</Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {setShowSubDetailsModal(false); setSubDetailsObj({});}}>{t("customeroverview.subscriptionDetails.close")}</Button>       
                </Modal.Footer>
            </Modal>
            <Modal show={showCancelSubscriptionWarningModal} onHide={() => {setShowCancelSubscriptionWarningModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("customeroverview.subscriptionDetails.cancel_subscription_warning.title")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("customeroverview.subscriptionDetails.cancel_subscription_warning.disclaimer01")}</p>
                    <p>{t("customeroverview.subscriptionDetails.cancel_subscription_warning.disclaimer02")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        className="cancel posleft"
                        onClick={() => setShowCancelSubscriptionWarningModal(false)}
                    >{t("customeroverview.subscriptionDetails.keep_subscription")}</Button>
                    <Button 
                        onClick={() => {cancelSubscription(subDetailsObj?.["subscription"]?.id); setShowCancelSubscriptionWarningModal(false);}}
                        disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.handle_payment != "allow"}
                    >{t("customeroverview.table.cancel")}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CustomerOverview;