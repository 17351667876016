import * as React from "react";

import { useEffect, useState } from "react";
import { Modal, InputGroup, Form, Button } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import { useAuth } from "../../../../Auth";
import { useAxios } from "../../../../AxiosHandler";

import DateSelector from "../../Wizard/Field/Fieldtypes/DateSelector";

import { generateUrl } from "../../../../config";

import "react-toastify/dist/ReactToastify.css";

function TaskEditorModal(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();

  const [users, setUsers] = useState<Array<any>>();

  const [show, setShow] = useState(
    props.show != undefined ? props.show : false
  );

  const [editTask, setEditTask] = useState<any>();
  const [editTaskAttachmentType, setEditTaskAttachmentType] = useState("");
  const [editTaskAttachmentId, setEditTaskAttachmentId] = useState("");
  const [editTaskAttachmentName, setEditTaskAttachmentName] = useState("");

  const [
    createTaskAttachmentObjects,
    setCreateTaskAttachmentObjects,
  ] = useState<Array<any>>([]);
  const [
    createTaskResponsibleAttachmentAccess,
    setCreateTaskResponsibleAttachmentAccess,
  ] = useState(true);
  const [orgs, setOrgs] = useState<Array<Object>>([{}]);
  const [allSystems, setAllSystems] = useState<Array<Object>>([{}]);

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const handleEditTask = () => {
    if (editTask["name"] == "") {
      notifyWarning(t("tasks.toast.empty_name"));
      return undefined;
    }
    if (editTask["duetime"] == "" || editTask["duetime"] == undefined) {
      notifyWarning(t("tasks.toast.empty_duetime"));
      return undefined;
    }

    axios
      .put(
        generateUrl("/api/cra/tasks/") + editTask["_id"],
        {
          name: editTask["name"],
          createdby: editTask["createdby"],
          description: editTask["description"],
          priority: editTask["priority"],
          attachments: editTask["attachments"],
          responsible: editTask["responsible"],
          status: editTask["status"],
          duetime: editTask["duetime"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        if (props.onSave != undefined) props.onSave();
        notifySuccess(t("tasks.toast.task_edited"));
      });
  };

  const fetchUsers = () => {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/users"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setUsers(res[0].data);
        })
      );
  };

  const fetchTask = () => {
    if (props.taskId != undefined) {
      axios
        .all([
          axios.single({
            method: "get",
            url: generateUrl("/api/cra/tasks/") + props.taskId,
            responseType: "json",
            headers: { "x-access-token": auth.user["token"] },
          }),
        ])
        .then(
          axios.spread((...res) => {
            if (res[0].data != undefined) {
              setEditTask(res[0].data);
            }
          })
        )
        .catch((err) => console.log(err));
    }
  };

  async function fetchOrgData() {
    axios
      .single({
        method: "get",
        url: generateUrl("/api/orgelements"),
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        setOrgs(res.data);
      });
  }

  const fetchTaskAttachmentObjects = (objectType) => {
    if (objectType != "") {
      let method = "";
      let url = "";
      switch (objectType) {
        case "system":
          method = "get";
          url = generateUrl("/api/cra/systems");
          break;
        case "analysistemplate":
          method = "get";
          url = generateUrl("/api/cra/analysisTemplates/byorg/myorg");
          break;
      }
      axios
        .all([
          axios.single({
            method,
            url,
            responseType: "json",
            headers: { "x-access-token": auth.user["token"] },
          }),
        ])
        .then(
          axios.spread((...res) => {
            if (objectType == "system") {
              setAllSystems(res[0].data);
            }
            setCreateTaskAttachmentObjects(res[0].data);
          })
        );
    }
  };

  const fetchAccessStatus = (objectType, objectId, userId) => {
    if (objectType != "" && objectId != "" && userId != "") {
      let method = "";
      let url = "";
      switch (objectType) {
        case "system":
          method = "get";
          url =
            generateUrl("/api/cra/systems/ownership/") +
            objectId +
            "/" +
            userId;
          break;
        case "analysistemplate":
          method = "get";
          url =
            generateUrl("/api/cra/analysisTemplates/ownership/") +
            objectId +
            "/" +
            userId;
          break;
      }
      axios
        .all([
          axios.single({
            method,
            url,
            responseType: "json",
            headers: { "x-access-token": auth.user["token"] },
          }),
        ])
        .then(
          axios.spread((...res) => {
            switch (objectType) {
              case "system":
                /* if (res[0].data.owner != null){
                            if (res[0].data.owner["_id"] == userId) {
                                setCreateTaskResponsibleAttachmentAccess(true);
                                break;
                            }
                        } 
                        if (res[0].data.coowners != undefined && !res[0].data.coowners.includes(null)) {
                            if (res[0].data.coowners.map(item => item["_id"]).includes(userId)) {
                                setCreateTaskResponsibleAttachmentAccess(true);
                                break;
                            }
                        }
                        if (res[0].data.private != true && auth.user["userdata"]["_id"] == userId && auth.user["userdata"]["role"][0] == "Admin") {
                            setCreateTaskResponsibleAttachmentAccess(true);
                            break;
                        } */
                if (res[0].data.systemAccesses.includes("read")) {
                  setCreateTaskResponsibleAttachmentAccess(true);
                  break;
                }
                setCreateTaskResponsibleAttachmentAccess(false);
                break;
              case "analysistemplate":
                /* if (res[0].data.owner != null){
                            if (res[0].data.owner["_id"] == userId) {
                                setCreateTaskResponsibleAttachmentAccess(true);
                                break;
                            }
                        } 
                        if (res[0].data.coowners != undefined && !res[0].data.coowners.includes(null)) {
                            if (res[0].data.coowners.map(item => item["_id"]).includes(userId)) {
                                setCreateTaskResponsibleAttachmentAccess(true);
                                break;
                            }
                        }
                        if (res[0].data.accessibility != undefined && !res[0].data.accessibility.includes(null)) {
                            if (res[0].data.accessibility.map(item => item["_id"]).includes(userId)) {
                                setCreateTaskResponsibleAttachmentAccess(true);
                                break;
                            }
                        }
                        if (res[0].data.private != true && auth.user["userdata"]["_id"] == userId && auth.user["userdata"]["role"][0] == "Admin") {
                            setCreateTaskResponsibleAttachmentAccess(true);
                            break;
                        } */
                if (res[0].data.templateAccesses.includes("read")) {
                  setCreateTaskResponsibleAttachmentAccess(true);
                  break;
                }
                setCreateTaskResponsibleAttachmentAccess(false);
            }
          })
        );
    }
  };

  useEffect(() => {
    fetchTask();
    fetchUsers();
    fetchOrgData();
  }, []);

  useEffect(() => {
    fetchTask();
  }, [props.taskId, props.show]);

  return (
    <div>
      {editTask != undefined ? (
        <Modal
          className="taskEditorModal"
          show={props.show}
          onHide={() => {
            if (props.onHide != undefined) props.onHide();
            setEditTaskAttachmentType("");
            setEditTaskAttachmentId("");
            setCreateTaskResponsibleAttachmentAccess(true);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("tasks.modal_task_edit.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("tasks.modal_task_create.fields.name")} *
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                disabled={!editTask["accesses"]?.includes("edit") ?? true}
                placeholder={t(
                  "tasks.modal_task_create.fields.namePlaceholder"
                )}
                onChange={(evt) =>
                  setEditTask({ ...editTask, name: evt.target.value })
                }
                value={editTask["name"] != undefined ? editTask["name"] : ""}
              ></Form.Control>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("tasks.modal_task_create.fields.description")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="textarea"
                rows={3}
                disabled={!editTask["accesses"]?.includes("edit") ?? true}
                onChange={(evt) =>
                  setEditTask({
                    ...editTask,
                    description: (evt.target as HTMLInputElement).value,
                  })
                }
                value={editTask["description"]}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("tasks.modal_task_create.fields.priority")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                disabled={!editTask["accesses"]?.includes("edit") ?? true}
                onChange={(evt) =>
                  setEditTask({
                    ...editTask,
                    priority: evt.currentTarget.value,
                  })
                }
                value={editTask["priority"]}
              >
                <option value={"verylow"}>
                  {t("tasks.modal_task_create.fields.priorities.verylow")}
                </option>
                <option value={"low"}>
                  {t("tasks.modal_task_create.fields.priorities.low")}
                </option>
                <option value={"normal"}>
                  {t("tasks.modal_task_create.fields.priorities.normal")}
                </option>
                <option value={"high"}>
                  {t("tasks.modal_task_create.fields.priorities.high")}
                </option>
                <option value={"urgent"}>
                  {t("tasks.modal_task_create.fields.priorities.urgent")}
                </option>
              </Form.Control>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("tasks.modal_task_create.fields.duetime")} *
                </InputGroup.Text>
              </InputGroup.Prepend>
              <DateSelector
                onChange={(date) => setEditTask({ ...editTask, duetime: date })}
                value={editTask["duetime"]}
                showTimeSelect
                disabled={!editTask["accesses"]?.includes("edit") ?? true}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("tasks.modal_task_create.fields.assignto")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                onChange={(evt) => {
                  setEditTask({
                    ...editTask,
                    responsible: (evt.target as HTMLSelectElement).value,
                  });
                  fetchAccessStatus(
                    editTaskAttachmentType,
                    editTaskAttachmentId,
                    (evt.target as HTMLSelectElement).value
                  );
                }}
                value={editTask["responsible"]}
                disabled={!editTask["accesses"]?.includes("edit") ?? true}
              >
                <option value={auth.user["userdata"]["_id"]}>
                  {t("tasks.modal_task_create.assigntoself")}
                </option>
                {users != undefined
                  ? users.map((user, key) => (
                      <option key={key} value={user["_id"]}>
                        {user["name"]}
                      </option>
                    ))
                  : null}
              </Form.Control>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {t("tasks.modal_task_create.fields.status")}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                disabled={!editTask["accesses"]?.includes("edit") ?? true}
                onChange={(evt) =>
                  setEditTask({
                    ...editTask,
                    status: (evt.target as HTMLSelectElement).value,
                  })
                }
                value={editTask["status"]}
              >
                <option value={"open"}>
                  {t("tasks.modal_task_create.fields.statuses.open")}
                </option>
                <option value={"process"}>
                  {t("tasks.modal_task_create.fields.statuses.process")}
                </option>
                <option value={"closed"}>
                  {t("tasks.modal_task_create.fields.statuses.closed")}
                </option>
              </Form.Control>
            </InputGroup>
            <div className="attachmentsContainer">
              <p className="attachmentsTitle">{t("tasks.attachments.title")}</p>
              {editTask["attachments"] != undefined
                ? editTask["attachments"].map((item, key) => (
                    <div key={key} className="attachment">
                      <p>
                        {t("tasks.attachments.types." + item["type"])}:{" "}
                        {item["name"]} -{" "}
                        <span
                          className="removebtn"
                          onClick={(evt) => {
                            let temp = editTask["attachments"];
                            temp.splice(key, 1);
                            setEditTask({ ...editTask, attachments: temp });
                          }}
                        >
                          {t("tasks.attachments.remove")}
                        </span>
                      </p>
                    </div>
                  ))
                : null}
              <div className="row">
                <div className="col col-sm-12">
                  <div
                    className="input-group"
                    style={{ width: "32%", float: "left" }}
                  >
                    <Form.Control
                      as="select"
                      onChange={(evt) => {
                        setEditTaskAttachmentType(
                          (evt.target as HTMLSelectElement).value
                        );
                        fetchTaskAttachmentObjects(
                          (evt.target as HTMLSelectElement).value
                        );
                        setEditTaskAttachmentId("");
                      }}
                      disabled={!editTask["accesses"]?.includes("edit") ?? true}
                    >
                      <option value="">
                        {t("tasks.attachments.types.selecttype")}
                      </option>
                      <option value="system">
                        {t("tasks.attachments.types.system")}
                      </option>
                      <option value="analysistemplate">
                        {t("tasks.attachments.types.analysistemplate")}
                      </option>
                    </Form.Control>
                  </div>
                  <div
                    className="input-group"
                    style={{ width: "32%", float: "left" }}
                  >
                    <Form.Control
                      as="select"
                      disabled={
                        editTaskAttachmentType == "" ||
                        (!editTask["accesses"]?.includes("edit") ?? true)
                      }
                      value={editTaskAttachmentId}
                      onChange={(evt) => {
                        fetchAccessStatus(
                          editTaskAttachmentType,
                          (evt.target as HTMLSelectElement).value,
                          editTask["responsible"]
                        );
                        setEditTaskAttachmentId(
                          (evt.target as HTMLSelectElement).value
                        );
                        if ((evt.target as HTMLSelectElement).value != "") {
                          let currentName = createTaskAttachmentObjects.filter(
                            (attachmentObject) =>
                              attachmentObject["_id"] ==
                              (evt.target as HTMLSelectElement).value
                          )[0]["name"];
                          let currentId = createTaskAttachmentObjects.filter(
                            (attachmentObject) =>
                              attachmentObject["_id"] ==
                              (evt.target as HTMLSelectElement).value
                          )[0]["_id"];
                          setEditTaskAttachmentName(
                            currentName != undefined
                              ? currentName
                              : orgs.filter(
                                  (org) =>
                                    org["_id"] ==
                                    allSystems.filter(
                                      (system) =>
                                        system["_id"].toString() == currentId
                                    )[0]["org"]
                                )[0]["name"]
                          );
                        } else {
                          setEditTaskAttachmentName("");
                        }
                      }}
                    >
                      <option value="">
                        {t("tasks.attachments.selectitem")}
                      </option>
                      {createTaskAttachmentObjects.map((object, objectkey) => (
                        <option key={objectkey} value={object["_id"]}>
                          {object["name"] != undefined
                            ? object["name"]
                            : orgs.filter(
                                (org) => org["_id"] == object["org"]
                              )[0]["name"]}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <Button
                    style={{ marginLeft: "10px", height: "38px", width: "32%" }}
                    disabled={
                      !createTaskResponsibleAttachmentAccess ||
                      (!editTask["accesses"]?.includes("edit") ?? true)
                    }
                    onClick={(evt) => {
                      if (
                        editTaskAttachmentType != "" &&
                        editTaskAttachmentId != ""
                      ) {
                        setEditTask({
                          ...editTask,
                          attachments: [
                            ...editTask["attachments"],
                            {
                              type: editTaskAttachmentType,
                              id: editTaskAttachmentId,
                              name: editTaskAttachmentName,
                            },
                          ],
                        });
                      } else {
                        notifyWarning(t("tasks.attachments.fillout"));
                      }
                    }}
                  >
                    {t("tasks.attachments.addbtn")}
                  </Button>
                </div>
              </div>
              {!createTaskResponsibleAttachmentAccess ? (
                <p>{t("tasks.attachments.assignednoaccess")}</p>
              ) : null}
            </div>
            <hr />
            <Button
              onClick={() =>
                props.onDeleteClick != undefined
                  ? props.onDeleteClick(editTask["_id"])
                  : null
              }
              disabled={!editTask["accesses"]?.includes("delete") ?? true}
            >
              {t("tasks.modal_task_edit.deletebtn")}
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel posleft"
              onClick={() => {
                if (props.onCancel != undefined) props.onCancel();
                setEditTaskAttachmentType("");
                setEditTaskAttachmentId("");
                setCreateTaskResponsibleAttachmentAccess(true);
              }}
            >
              {t("tasks.modal_task_create.cancelbtn")}
            </Button>
            <Button
              onClick={handleEditTask}
              disabled={!editTask["accesses"]?.includes("edit") ?? true}
            >
              {t("tasks.modal_task_edit.savebtn")}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
}

export default TaskEditorModal;
