import React, { useEffect, useState } from "react";
import { Card, Button, Table } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { Doughnut } from "react-chartjs-2";

import {
  treatmentTypes,
  treatmentClasses,
} from "../../../../../Assets/Constants/Constants";
import { useDynamicLanguage } from "../../../../../DynamicLanguageProvider";

function TreatmentsMatrix(props) {
  const t = useTranslate();
  const td = useDynamicLanguage().td;

  const [expandedTreatmentMatrix, setExpandedTreatmentMatrix] = useState(false);

  const treatmentMatrixDougnutCutOut = 70;

  function DrawDoughnut(dataFunction) {
    return (
      <Doughnut
        redraw={false}
        data={{
          datasets: [
            {
              data: [
                "open",
                "suggested",
                "planned",
                "processing",
                "closed",
              ].map(dataFunction),
              backgroundColor: [
                "rgb(200, 200, 200, 1)", //Grey
                "rgb(153, 55, 66, 1)", //Red
                "rgb(216, 119, 90, 1)", //Orange
                "rgb(250, 205, 117, 1)", //Yellow
                "rgb(91, 130, 102, 1)", //Green
              ],
            },
          ],
          labels: ["Realized", "All"],
        }}
        options={{
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
          cutoutPercentage: treatmentMatrixDougnutCutOut,
        }}
      />
    );
  }

  return (
    <div className="treatmentsmatrixcontainer">
      <Card>
        <Card.Body>
          <h4 className="header-title">
            {t("system.treatments_matrix.title")}
          </h4>
          <hr />
          <p>{t("system.treatments_matrix.description")}</p>

          <Table
            bordered
            className={
              expandedTreatmentMatrix == false
                ? "treatmentsMatrix treatmentsMatrixDiriBg"
                : "treatmentsMatrix"
            }
          >
            <thead>
              <tr>
                <th></th>
                <th className="treatmentsMatrixTopHeader">
                  {t(treatmentClasses[0].label)}
                </th>
                <th className="treatmentsMatrixTopHeader">
                  {t(treatmentClasses[1].label)}
                </th>
                <th className="treatmentsMatrixTopHeader">
                  {t(treatmentClasses[2].label)}
                </th>
                <th className="treatmentsMatrixTopHeader">
                  {t(treatmentClasses[3].label)}
                </th>
                <th className="treatmentsMatrixTopHeader">
                  {t("treatment.undefined")}
                </th>
                <th className="treatmentsMatrixTopHeader">
                  {t("treatment.summary")}
                </th>
              </tr>
            </thead>

            <tbody>
              {treatmentTypes.map((treatmentType, typekey) => {
                return (
                  <tr
                    key={typekey}
                    className={
                      expandedTreatmentMatrix == false
                        ? "hiddenTreatmentMatrix"
                        : ""
                    }
                  >
                    <th>{t(treatmentType.label)}</th>

                    {/* All treatments with class and type */}
                    {treatmentClasses.map((treatmentClass, classkey) => {
                      return (
                        <td
                          key={classkey}
                          className={
                            props.treatments.filter(
                              (t) =>
                                t["type"] == treatmentType.value &&
                                t["class"] == treatmentClass.value
                            ).length == 0
                              ? "emptyCell"
                              : ""
                          }
                          onClick={() =>
                            props.setSelectedTreatments(
                              props.treatments.filter(
                                (t) =>
                                  t["type"] == treatmentType.value &&
                                  t["class"] == treatmentClass.value
                              )
                            )
                          }
                        >
                          {props.treatments.filter(
                            (t) =>
                              t["type"] == treatmentType.value &&
                              t["class"] == treatmentClass.value
                          ).length != 0 ? (
                            <div>
                              <p>
                                {
                                  props.treatments.filter(
                                    (t) =>
                                      t["type"] == treatmentType.value &&
                                      t["class"] == treatmentClass.value &&
                                      t["status"] == "closed"
                                  ).length
                                }
                                /
                                {
                                  props.treatments.filter(
                                    (t) =>
                                      t["type"] == treatmentType.value &&
                                      t["class"] == treatmentClass.value
                                  ).length
                                }
                              </p>
                              <div>
                                {expandedTreatmentMatrix == true
                                  ? DrawDoughnut(
                                      (status) =>
                                        props.treatments.filter(
                                          (t) =>
                                            t["type"] == treatmentType.value &&
                                            t["class"] ==
                                              treatmentClass.value &&
                                            t["status"] == status
                                        ).length
                                    )
                                  : null}
                              </div>
                            </div>
                          ) : (
                            <p className="treatmentsMatrixEmpty">
                              <i className="dripicons-wrong" />
                            </p>
                          )}
                        </td>
                      );
                    })}

                    {/* All treatments with type, but not class */}
                    <td
                      className={
                        props.treatments.filter(
                          (t) =>
                            t["type"] == treatmentType.value &&
                            (t["class"] == undefined || t["class"] == "")
                        ).length == 0
                          ? "emptyCell"
                          : ""
                      }
                      onClick={() =>
                        props.setSelectedTreatments(
                          props.treatments.filter(
                            (t) =>
                              t["type"] == treatmentType.value &&
                              (t["class"] == undefined || t["class"] == "")
                          )
                        )
                      }
                    >
                      {props.treatments.filter(
                        (t) =>
                          t["type"] == treatmentType.value &&
                          (t["class"] == undefined || t["class"] == "")
                      ).length != 0 ? (
                        <div>
                          <p>
                            {
                              props.treatments.filter(
                                (t) =>
                                  t["type"] == treatmentType.value &&
                                  (t["class"] == undefined ||
                                    t["class"] == "") &&
                                  t["status"] == "closed"
                              ).length
                            }
                            /
                            {
                              props.treatments.filter(
                                (t) =>
                                  t["type"] == treatmentType.value &&
                                  (t["class"] == undefined || t["class"] == "")
                              ).length
                            }
                          </p>
                          <div>
                            {expandedTreatmentMatrix == true
                              ? DrawDoughnut(
                                  (status) =>
                                    props.treatments.filter(
                                      (t) =>
                                        t["type"] == treatmentType.value &&
                                        (t["class"] == undefined ||
                                          t["class"] == "") &&
                                        t["status"] == status
                                    ).length
                                )
                              : null}
                          </div>
                        </div>
                      ) : (
                        <p className="treatmentsMatrixEmpty">
                          <i className="dripicons-wrong" />
                        </p>
                      )}
                    </td>

                    {/* All treatments with type (summary) */}
                    <td
                      className={
                        props.treatments.filter(
                          (t) => t["type"] == treatmentType.value
                        ).length == 0
                          ? "emptyCell"
                          : ""
                      }
                      onClick={() =>
                        props.setSelectedTreatments(
                          props.treatments.filter(
                            (t) => t["type"] == treatmentType.value
                          )
                        )
                      }
                    >
                      {props.treatments.filter(
                        (t) => t["type"] == treatmentType.value
                      ).length != 0 ? (
                        <div>
                          <p>
                            {
                              props.treatments.filter(
                                (t) =>
                                  t["type"] == treatmentType.value &&
                                  t["status"] == "closed"
                              ).length
                            }
                            /
                            {
                              props.treatments.filter(
                                (t) => t["type"] == treatmentType.value
                              ).length
                            }
                          </p>
                          <div>
                            {expandedTreatmentMatrix == true
                              ? DrawDoughnut(
                                  (status) =>
                                    props.treatments.filter(
                                      (t) =>
                                        t["type"] == treatmentType.value &&
                                        t["status"] == status
                                    ).length
                                )
                              : null}
                          </div>
                        </div>
                      ) : (
                        <p className="treatmentsMatrixEmpty">
                          <i className="dripicons-wrong" />
                        </p>
                      )}
                    </td>
                  </tr>
                );
              })}

              <tr
                className={
                  expandedTreatmentMatrix == false
                    ? "hiddenTreatmentMatrix"
                    : ""
                }
              >
                <th>{t("treatment.undefined")}</th>
                {treatmentClasses.map((treatmentClass, classkey) => {
                  {
                    /* All treatments with class, but not type */
                  }
                  return (
                    <td
                      key={classkey}
                      className={
                        props.treatments.filter(
                          (t) =>
                            (t["type"] == undefined || t["type"] == "") &&
                            t["class"] == treatmentClass.value
                        ).length == 0
                          ? "emptyCell"
                          : ""
                      }
                      onClick={() =>
                        props.setSelectedTreatments(
                          props.treatments.filter(
                            (t) =>
                              (t["type"] == undefined || t["type"] == "") &&
                              t["class"] == treatmentClass.value
                          )
                        )
                      }
                    >
                      {props.treatments.filter(
                        (t) =>
                          (t["type"] == undefined || t["type"] == "") &&
                          t["class"] == treatmentClass.value
                      ).length != 0 ? (
                        <div>
                          <p>
                            {
                              props.treatments.filter(
                                (t) =>
                                  (t["type"] == undefined || t["type"] == "") &&
                                  t["class"] == treatmentClass.value &&
                                  t["status"] == "closed"
                              ).length
                            }
                            /
                            {
                              props.treatments.filter(
                                (t) =>
                                  (t["type"] == undefined || t["type"] == "") &&
                                  t["class"] == treatmentClass.value
                              ).length
                            }
                          </p>
                          <div>
                            {expandedTreatmentMatrix == true
                              ? DrawDoughnut(
                                  (status) =>
                                    props.treatments.filter(
                                      (t) =>
                                        (t["type"] == undefined ||
                                          t["type"] == "") &&
                                        t["class"] == treatmentClass.value &&
                                        t["status"] == status
                                    ).length
                                )
                              : null}
                          </div>
                        </div>
                      ) : (
                        <p className="treatmentsMatrixEmpty">
                          <i className="dripicons-wrong" />
                        </p>
                      )}
                    </td>
                  );
                })}

                {/* All treatments with neither type nor class */}
                <td
                  className={
                    props.treatments.filter(
                      (t) =>
                        (t["type"] == undefined || t["type"] == "") &&
                        (t["class"] == undefined || t["class"] == "")
                    ).length == 0
                      ? "emptyCell"
                      : ""
                  }
                  onClick={() =>
                    props.setSelectedTreatments(
                      props.treatments.filter(
                        (t) =>
                          (t["type"] == undefined || t["type"] == "") &&
                          (t["class"] == undefined || t["class"] == "")
                      )
                    )
                  }
                >
                  {props.treatments.filter(
                    (t) =>
                      (t["type"] == undefined || t["type"] == "") &&
                      (t["class"] == undefined || t["class"] == "")
                  ).length != 0 ? (
                    <div>
                      <p>
                        {
                          props.treatments.filter(
                            (t) =>
                              (t["type"] == undefined || t["type"] == "") &&
                              (t["class"] == undefined || t["class"] == "") &&
                              t["status"] == "closed"
                          ).length
                        }
                        /
                        {
                          props.treatments.filter(
                            (t) =>
                              (t["type"] == undefined || t["type"] == "") &&
                              (t["class"] == undefined || t["class"] == "")
                          ).length
                        }
                      </p>
                      <div>
                        {expandedTreatmentMatrix == true
                          ? DrawDoughnut(
                              (status) =>
                                props.treatments.filter(
                                  (t) =>
                                    (t["type"] == undefined ||
                                      t["type"] == "") &&
                                    (t["class"] == undefined ||
                                      t["class"] == "") &&
                                    t["status"] == status
                                ).length
                            )
                          : null}
                      </div>
                    </div>
                  ) : (
                    <p className="treatmentsMatrixEmpty">
                      <i className="dripicons-wrong" />
                    </p>
                  )}
                </td>

                {/* All treatments without type(summary) */}
                <td
                  className={
                    props.treatments.filter(
                      (t) => t["type"] == undefined || t["type"] == ""
                    ).length == 0
                      ? "emptycell"
                      : ""
                  }
                  onClick={() =>
                    props.setSelectedTreatments(
                      props.treatments.filter(
                        (t) => t["type"] == undefined || t["type"] == ""
                      )
                    )
                  }
                >
                  {props.treatments.filter(
                    (t) => t["type"] == undefined || t["type"] == ""
                  ).length != 0 ? (
                    <div>
                      <p>
                        {
                          props.treatments.filter(
                            (t) =>
                              (t["type"] == undefined || t["type"] == "") &&
                              t["status"] == "closed"
                          ).length
                        }
                        /
                        {
                          props.treatments.filter(
                            (t) => t["type"] == undefined || t["type"] == ""
                          ).length
                        }
                      </p>
                      <div>
                        {expandedTreatmentMatrix == true
                          ? DrawDoughnut(
                              (status) =>
                                props.treatments.filter(
                                  (t) =>
                                    (t["type"] == undefined ||
                                      t["type"] == "") &&
                                    t["status"] == status
                                ).length
                            )
                          : null}
                      </div>
                    </div>
                  ) : (
                    <p className="treatmentsMatrixEmpty">
                      <i className="dripicons-wrong" />
                    </p>
                  )}
                </td>
              </tr>
              <tr>
                <th>{t("treatment.summary")}</th>
                {treatmentClasses.map((treatmentClass, classkey) => {
                  {
                    /* All treatments with class (summary) */
                  }
                  return (
                    <td
                      key={classkey}
                      className={
                        props.treatments.filter(
                          (t) => t["class"] == treatmentClass.value
                        ).length == 0
                          ? "emptyCell"
                          : ""
                      }
                      onClick={() =>
                        props.setSelectedTreatments(
                          props.treatments.filter(
                            (t) => t["class"] == treatmentClass.value
                          )
                        )
                      }
                    >
                      {props.treatments.filter(
                        (t) => t["class"] == treatmentClass.value
                      ).length != 0 ? (
                        <div>
                          <p>
                            {
                              props.treatments.filter(
                                (t) =>
                                  t["class"] == treatmentClass.value &&
                                  t["status"] == "closed"
                              ).length
                            }
                            /
                            {
                              props.treatments.filter(
                                (t) => t["class"] == treatmentClass.value
                              ).length
                            }
                          </p>
                          <div>
                            {DrawDoughnut(
                              (status) =>
                                props.treatments.filter(
                                  (t) =>
                                    t["class"] == treatmentClass.value &&
                                    t["status"] == status
                                ).length
                            )}
                          </div>
                        </div>
                      ) : (
                        <p className="treatmentsMatrixEmpty">
                          <i className="dripicons-wrong" />
                        </p>
                      )}
                    </td>
                  );
                })}

                {/* All treatments without class (summary) */}
                <td
                  className={
                    props.treatments.filter(
                      (t) => t["class"] == undefined || t["class"] == ""
                    ).length == 0
                      ? "emptyCell"
                      : ""
                  }
                  onClick={() =>
                    props.setSelectedTreatments(
                      props.treatments.filter(
                        (t) => t["class"] == undefined || t["class"] == ""
                      )
                    )
                  }
                >
                  {props.treatments.filter(
                    (t) => t["class"] == undefined || t["class"] == ""
                  ).length != 0 ? (
                    <div>
                      <p>
                        {
                          props.treatments.filter(
                            (t) =>
                              (t["class"] == undefined || t["class"] == "") &&
                              t["status"] == "closed"
                          ).length
                        }
                        /
                        {
                          props.treatments.filter(
                            (t) => t["class"] == undefined || t["class"] == ""
                          ).length
                        }
                      </p>
                      <div>
                        {DrawDoughnut(
                          (status) =>
                            props.treatments.filter(
                              (t) =>
                                (t["class"] == undefined || t["class"] == "") &&
                                t["status"] == status
                            ).length
                        )}
                      </div>
                    </div>
                  ) : (
                    <p className="treatmentsMatrixEmpty">
                      <i className="dripicons-wrong" />
                    </p>
                  )}
                </td>

                {/* All treatments (summary) */}
                <td
                  className={props.treatments.length == 0 ? "emptyCell" : ""}
                  onClick={() => props.setSelectedTreatments(props.treatments)}
                >
                  {props.treatments.length != 0 ? (
                    <div>
                      <p>
                        {
                          props.treatments.filter(
                            (t) => t["status"] == "closed"
                          ).length
                        }
                        /{props.treatments.length}
                      </p>
                      <div>
                        {DrawDoughnut(
                          (status) =>
                            props.treatments.filter(
                              (t) => t["status"] == status
                            ).length
                        )}
                      </div>
                    </div>
                  ) : (
                    <p className="treatmentsMatrixEmpty">
                      <i className="dripicons-wrong" />
                    </p>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
          <Button
            className="expandTreatmentMatrixBtn no-print"
            onClick={() => setExpandedTreatmentMatrix(!expandedTreatmentMatrix)}
          >
            {!expandedTreatmentMatrix
              ? t("system.treatments_matrix.expand_table")
              : t("system.treatments_matrix.contract_table")}
          </Button>
          {props.selectedTreatments != undefined &&
          props.selectedTreatments.length > 0 ? (
            <div className="chartDetails">
              <div>
                <h4 className="header-title">
                  {t("system.treatments_matrix.table.title")}
                </h4>
                <Button
                  onClick={() => props.setSelectedTreatments([])}
                  className="primary-btn no-print"
                >
                  {t("system.treatments_matrix.close_details")}
                </Button>
                <Table striped hover className="chartdetailstable">
                  <thead>
                    <tr>
                      <th>
                        {t("system.treatments_matrix.table.treatment_name")}
                      </th>
                      <th>
                        {t("system.treatments_matrix.table.treatment_status")}
                      </th>
                      <th>
                        {t("system.treatments_matrix.table.treatment_cause")}
                      </th>
                      <th>
                        {t(
                          "system.treatments_matrix.table.treatment_consequence"
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.selectedTreatments.map((element, key) => {
                      return (
                        <tr
                          onClick={(e) =>
                            props.handleShowEditTreatmentModal(e, element)
                          }
                          key={key}
                        >
                          <td>{element["name"]}</td>
                          <td>{t("treatment." + element["status"])}</td>
                          <td>
                            {props.allCauses
                              .filter((c) =>
                                (element["cause"] as any).includes(c._id)
                              )
                              .map((c) => c.name)
                              .join(", ")}
                          </td>
                          <td>
                            {props.allConsequences
                              .filter((c) =>
                                (element["consequence"] as any).includes(c._id)
                              )
                              .map((c) =>
                                c.name != "" && c.name != undefined
                                  ? td(c.name)
                                  : props.allConsequenceTypes
                                      .filter((type) => type._id == c.category)
                                      .map((type) => td(type.name))
                              )
                              .join(", ")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : null}
        </Card.Body>
      </Card>
    </div>
  );
}

export default TreatmentsMatrix;
