import * as React from "react";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import { useAuth } from "../../../../Auth";
import { useAxios } from "../../../../AxiosHandler";

import Wizard from "../../Wizard/Wizard";
import WizardFieldManipulator from "../../WizardHelpers/WizardFieldManipulator";
import { useDynamicLanguage } from "../../../../DynamicLanguageProvider";

import { generateUrl } from "../../../../config";

function OrgrosRegTemplate(props) {
  const t = useTranslate();
  const td = useDynamicLanguage().td;
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const auth = useAuth();

  const history = useHistory();

  const GetWizardFieldByReference =
    WizardFieldManipulator.GetWizardFieldByReference;
  const GetWizardValuesByReferenceList =
    WizardFieldManipulator.GetWizardValuesByReferenceList;

  const [template, setTemplate] = useState<any>([]);

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const customValueTypesMappings = {
    Kommunikasjonsdata: "systemvaluecommunication",
    Virksomhetsdata: "systemvaluecompanydata",
    "Økonomiske og finansielle data": "systemvalueeconomy",
    "Infrastruktur og hardware": "systemvaluehardware",
    "Annen verdifull informasjon i systemet": "systemvalueotherinfo",
    "Andre verdier i systemet": "systemvalueotherinfo",
    "Informasjon underlagt annet lovverk": "systemvalueotherlawinfo",
    Personopplysninger: "systemvaluepersoninformation",
    "Brukerdatabase med passord": "systemvalueusernamepassword",

    "Communication data": "systemvaluecommunication",
    "Information subject to other legislation": "systemvalueotherlawinfo",
    "Financial information": "systemvalueeconomy",
    "Business information": "systemvaluecompanydata",
    "Other assets in the system": "systemvalueotherinfo",
    "Infrastructure and Hardware": "systemvaluehardware",
    "Personal identifiable information": "systemvaluepersoninformation",
    "Username and password": "systemvalueusernamepassword",
  };

  function getSystemvalueOptionsByKIT(kit) {
    let options = Array();
    if (settings["systemvalue" + kit + "Num"] != undefined) {
      for (
        let i = 1;
        i <= parseInt(settings["systemvalue" + kit + "Num"]);
        i++
      ) {
        options.push({
          label: settings["systemvalue" + kit + "Level" + i],
          value: i,
        });
      }
      return options;
    }
  }

  function getOccurenceTypes() {
    return props.occurenceOptions.map((option) => {
      return { value: option._id, label: td(option.name) };
    });
  }

  async function fetchUsers() {
    await axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/users/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/orgElements/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          console.log(res[1].data);
          setTemplate([
            {
              fields: [
                {
                  type: "text_title",
                  title: t("diri.regassistant.types.orgros.title"),
                },
                {
                  type: "select",
                  title: t("diri.regassistant.types.orgros.org") + " *",
                  options: {
                    typeLoad: "Org",
                    choosetext: t("diri.regassistant.types.orgros.org_select"),
                  },
                  value: props.selectedOrgId,
                  reference: "selectedorg",
                },
                {
                  type: "dateselector",
                  title: t("diri.regassistant.types.orgros.startdate"),
                  reference: "startdate",
                },
                {
                  title: t("diri.regassistant.types.orgros.orgname"),
                  value: "",
                  type: "autocompletewithid",
                  reference: "name",
                  options: {
                    list: res[1].data.map((i, key) => {
                      return { id: key, value: i["name"], label: i["name"] };
                    }),
                    data: res[1].data,
                    minlength: 2,
                    noresults: "no matches",
                  },
                },
                {
                  id: 3,
                  title: t("diri.regassistant.types.orgros.system_responsible"),
                  value: "",
                  type: "autocompletewithid",
                  reference: "responsibleperson",
                  options: {
                    list: res[0].data.map((i, key) => {
                      return { id: key, label: i["name"], value: i["_id"] };
                    }),
                    data: res[0].data,
                    minlength: 2,
                    noresults: "no matches",
                  },
                },
                /* {
                  "type":"text_normal",
                  "title": t("diri.regassistant.types.orgros.ledetekst_responsible")
                }, */
                /* {
                  "type": "input",
                  "title": t("diri.regassistant.types.orgros.orgname"),
                  "reference":"name"
                }, */
                {
                  type: "input",
                  title: t("diri.regassistant.types.orgros.industrycode"),
                  reference: "industrycode",
                },
                {
                  type: "select",
                  title: t("diri.regassistant.types.orgros.industryssb"),
                  reference: "industryssb",
                  value: {
                    label: t(
                      "diri.regassistant.types.orgros.industryssbselections.agriculture"
                    ),
                    value: "agriculture",
                  },
                  options: {
                    typeLoad: "Manual",
                    choices: [
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.agriculture"
                        ),
                        value: "agriculture",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.mining"
                        ),
                        value: "mining",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.industry"
                        ),
                        value: "industry",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.supplyservices"
                        ),
                        value: "supplyservices",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.construction"
                        ),
                        value: "construction",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.retail"
                        ),
                        value: "retail",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.transportstorage"
                        ),
                        value: "transportstorage",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.accomodationfood"
                        ),
                        value: "accomodationfood",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.infcom"
                        ),
                        value: "infcom",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.financialinsurance"
                        ),
                        value: "financialinsurance",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.realestate"
                        ),
                        value: "realestate",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.technicalservices"
                        ),
                        value: "technicalservices",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.businessservices"
                        ),
                        value: "businessservices",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.publicadministration"
                        ),
                        value: "publicadministration",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.teaching"
                        ),
                        value: "teaching",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.healthsocialwork"
                        ),
                        value: "healthsocialwork",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.cultural"
                        ),
                        value: "cultural",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.otherservices"
                        ),
                        value: "otherservices",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.paidprivatehouseholdwork"
                        ),
                        value: "paidprivatehouseholdwork",
                      },
                      {
                        label: t(
                          "diri.regassistant.types.orgros.industryssbselections.internationalorgs"
                        ),
                        value: "internationalorgs",
                      },
                    ],
                  },
                },
                {
                  type: "input",
                  title: t("diri.regassistant.types.orgros.employeescount"),
                  reference: "employeescount",
                },
                {
                  type: "textarea",
                  title: t("diri.regassistant.types.orgros.orgdesc"),
                  reference: "orgdesc",
                },
                {
                  type: "textarea",
                  title: t(
                    "diri.regassistant.types.orgros.securityrequirements"
                  ),
                  reference: "securityrequirements",
                },
                {
                  type: "text_normal",
                  title: t(
                    "diri.regassistant.types.orgros.securityrequirements_desc"
                  ),
                },
                {
                  type: "textarea",
                  title: t(
                    "diri.regassistant.types.orgros.importantdeliveries"
                  ),
                  reference: "importantdeliveries",
                },
                {
                  type: "text_normal",
                  title: t(
                    "diri.regassistant.types.orgros.importantdeliveries_desc"
                  ),
                },
                {
                  type: "divider",
                },
                {
                  type: "text_title",
                  title: t("diri.regassistant.types.orgros.itsystems"),
                  options: {
                    headertag: "h4",
                  },
                },
                {
                  type: "objectcreator",
                  title: t("diri.regassistant.types.orgros.itsystems"),
                  reference: "itsystemscreator",
                  options: {
                    addButtonText: t(
                      "diri.regassistant.types.orgros.input.systems.addbtn"
                    ),
                    deleteButtonText: t(
                      "diri.regassistant.types.orgros.input.systems.deletebtn"
                    ),
                    choosetext:
                      t("diri.regassistant.types.orgros.input.systems.choose") +
                      " *",
                    unavailableText: t(
                      "diri.regassistant.types.orgros.input.systems.unavailable"
                    ),
                    placeholderTitle:
                      t(
                        "diri.regassistant.types.orgros.input.systems.placeholder_title"
                      ) + " *",
                    placeholderComment: t(
                      "diri.regassistant.types.orgros.input.systems.placeholder_comment"
                    ),
                    objectType: "system",
                    addBtnDisabled:
                      auth.user["userdata"]["actualAccessRole"]["rules"].find(
                        (rule) =>
                          rule.objType == "system" &&
                          rule.objSubType == "system"
                      ).actions.create != "allow",
                    types: [
                      {
                        value: "system",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.system"
                        ),
                        types: /* getSystemTypes() */ [
                          /* {value: "test", label: "test"} */
                        ],
                      },
                      {
                        value: "occurence",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.occurence"
                        ),
                        types: getOccurenceTypes(),
                      },
                      {
                        value: "systemvalue",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.systemvalue"
                        ),
                        types: [
                          {
                            value:
                              customValueTypesMappings[
                                "systemvaluecommunication"
                              ],
                            label: t(
                              "system_asset.type.systemvaluecommunication"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvaluecompanydata"
                              ],
                            label: t(
                              "system_asset.type.systemvaluecompanydata"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings["systemvalueeconomy"],
                            label: t("system_asset.type.systemvalueeconomy"),
                          },
                          {
                            value:
                              customValueTypesMappings["systemvaluehardware"],
                            label: t("system_asset.type.systemvaluehardware"),
                          },
                          {
                            value:
                              customValueTypesMappings["systemvalueotherinfo"],
                            label: t("system_asset.type.systemvalueotherinfo"),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvalueotherlawinfo"
                              ],
                            label: t(
                              "system_asset.type.systemvalueotherlawinfo"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvaluepersoninformation"
                              ],
                            label: t(
                              "system_asset.type.systemvaluepersoninformation"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvalueusernamepassword"
                              ],
                            label: t(
                              "system_asset.type.systemvalueusernamepassword"
                            ),
                          },
                        ],
                      },
                      {
                        value: "treatment",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.treatment"
                        ),
                        types: /* getTreatmentTypes() */ [
                          /* {value: "test", label: "test"} */
                        ],
                      },
                    ],
                  },
                },
                {
                  type: "text_normal",
                  title: t("diri.regassistant.types.orgros.itsystems_desc"),
                },
                {
                  type: "divider",
                },
                {
                  type: "text_title",
                  title: t("diri.regassistant.types.orgros.concerns"),
                  options: {
                    headertag: "h4",
                  },
                },
                {
                  type: "objectcreator",
                  title: t("diri.regassistant.types.orgros.concerns"),
                  reference: "concernscreator",
                  options: {
                    addButtonText: t(
                      "diri.regassistant.types.orgros.input.systems.addbtn"
                    ),
                    deleteButtonText: t(
                      "diri.regassistant.types.orgros.input.systems.deletebtn"
                    ),
                    choosetext:
                      t("diri.regassistant.types.orgros.input.systems.choose") +
                      " *",
                    unavailableText: t(
                      "diri.regassistant.types.orgros.input.systems.unavailable"
                    ),
                    placeholderTitle:
                      t(
                        "diri.regassistant.types.orgros.input.systems.placeholder_title"
                      ) + " *",
                    placeholderComment: t(
                      "diri.regassistant.types.orgros.input.systems.placeholder_comment"
                    ),
                    objectType: "occurence",
                    types: [
                      {
                        value: "system",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.system"
                        ),
                        types: /* getSystemTypes() */ [
                          /* {value: "test", label: "test"} */
                        ],
                      },
                      {
                        value: "occurence",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.occurence"
                        ),
                        types: getOccurenceTypes(),
                      },
                      {
                        value: "systemvalue",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.systemvalue"
                        ),
                        types: [
                          {
                            value:
                              customValueTypesMappings[
                                "systemvaluecommunication"
                              ],
                            label: t(
                              "system_asset.type.systemvaluecommunication"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvaluecompanydata"
                              ],
                            label: t(
                              "system_asset.type.systemvaluecompanydata"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings["systemvalueeconomy"],
                            label: t("system_asset.type.systemvalueeconomy"),
                          },
                          {
                            value:
                              customValueTypesMappings["systemvaluehardware"],
                            label: t("system_asset.type.systemvaluehardware"),
                          },
                          {
                            value:
                              customValueTypesMappings["systemvalueotherinfo"],
                            label: t("system_asset.type.systemvalueotherinfo"),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvalueotherlawinfo"
                              ],
                            label: t(
                              "system_asset.type.systemvalueotherlawinfo"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvaluepersoninformation"
                              ],
                            label: t(
                              "system_asset.type.systemvaluepersoninformation"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvalueusernamepassword"
                              ],
                            label: t(
                              "system_asset.type.systemvalueusernamepassword"
                            ),
                          },
                        ],
                      },
                      {
                        value: "treatment",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.treatment"
                        ),
                        types: /* getTreatmentTypes() */ [
                          /* {value: "test", label: "test"} */
                        ],
                      },
                    ],
                  },
                },
                {
                  type: "text_normal",
                  title: t("diri.regassistant.types.orgros.concerns_desc"),
                },
                {
                  type: "divider",
                },
                {
                  type: "text_title",
                  title: t("diri.regassistant.types.orgros.values"),
                  options: {
                    headertag: "h4",
                  },
                },
                {
                  type: "objectcreator",
                  title: t("diri.regassistant.types.orgros.values"),
                  reference: "valuescreator",
                  options: {
                    addButtonText: t(
                      "diri.regassistant.types.orgros.input.systems.addbtn"
                    ),
                    deleteButtonText: t(
                      "diri.regassistant.types.orgros.input.systems.deletebtn"
                    ),
                    choosetext:
                      t("diri.regassistant.types.orgros.input.systems.choose") +
                      " *",
                    unavailableText: t(
                      "diri.regassistant.types.orgros.input.systems.unavailable"
                    ),
                    placeholderTitle:
                      t(
                        "diri.regassistant.types.orgros.input.systems.placeholder_title"
                      ) + " *",
                    placeholderComment: t(
                      "diri.regassistant.types.orgros.input.systems.placeholder_comment"
                    ),
                    objectType: "systemvalue",
                    types: [
                      {
                        value: "system",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.system"
                        ),
                        types: /* getSystemTypes() */ [
                          /* {value: "test", label: "test"} */
                        ],
                      },
                      {
                        value: "occurence",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.occurence"
                        ),
                        types: getOccurenceTypes(),
                      },
                      {
                        value: "systemvalue",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.systemvalue"
                        ),
                        types: [
                          {
                            value:
                              customValueTypesMappings[
                                "systemvaluecommunication"
                              ],
                            label: t(
                              "system_asset.type.systemvaluecommunication"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvaluecompanydata"
                              ],
                            label: t(
                              "system_asset.type.systemvaluecompanydata"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings["systemvalueeconomy"],
                            label: t("system_asset.type.systemvalueeconomy"),
                          },
                          {
                            value:
                              customValueTypesMappings["systemvaluehardware"],
                            label: t("system_asset.type.systemvaluehardware"),
                          },
                          {
                            value:
                              customValueTypesMappings["systemvalueotherinfo"],
                            label: t("system_asset.type.systemvalueotherinfo"),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvalueotherlawinfo"
                              ],
                            label: t(
                              "system_asset.type.systemvalueotherlawinfo"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvaluepersoninformation"
                              ],
                            label: t(
                              "system_asset.type.systemvaluepersoninformation"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvalueusernamepassword"
                              ],
                            label: t(
                              "system_asset.type.systemvalueusernamepassword"
                            ),
                          },
                        ],
                      },
                      {
                        value: "treatment",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.treatment"
                        ),
                        types: /* getTreatmentTypes() */ [
                          /* {value: "test", label: "test"} */
                        ],
                      },
                    ],
                  },
                },
                {
                  type: "text_normal",
                  title: t("diri.regassistant.types.orgros.values_desc"),
                },
                {
                  type: "divider",
                },
                {
                  type: "text_title",
                  title: t("diri.regassistant.types.orgros.securitytreatments"),
                  options: {
                    headertag: "h4",
                  },
                },
                {
                  type: "objectcreator",
                  title: t("diri.regassistant.types.orgros.values"),
                  reference: "treatmentscreator",
                  options: {
                    addButtonText: t(
                      "diri.regassistant.types.orgros.input.systems.addbtn"
                    ),
                    deleteButtonText: t(
                      "diri.regassistant.types.orgros.input.systems.deletebtn"
                    ),
                    choosetext:
                      t("diri.regassistant.types.orgros.input.systems.choose") +
                      " *",
                    unavailableText: t(
                      "diri.regassistant.types.orgros.input.systems.unavailable"
                    ),
                    placeholderTitle:
                      t(
                        "diri.regassistant.types.orgros.input.systems.placeholder_title"
                      ) + " *",
                    placeholderComment: t(
                      "diri.regassistant.types.orgros.input.systems.placeholder_comment"
                    ),
                    objectType: "treatment",
                    types: [
                      {
                        value: "system",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.system"
                        ),
                        types: /* getSystemTypes() */ [
                          /* {value: "test", label: "test"} */
                        ],
                      },
                      {
                        value: "occurence",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.occurence"
                        ),
                        types: getOccurenceTypes(),
                      },
                      {
                        value: "systemvalue",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.systemvalue"
                        ),
                        types: [
                          {
                            value:
                              customValueTypesMappings[
                                "systemvaluecommunication"
                              ],
                            label: t(
                              "system_asset.type.systemvaluecommunication"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvaluecompanydata"
                              ],
                            label: t(
                              "system_asset.type.systemvaluecompanydata"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings["systemvalueeconomy"],
                            label: t("system_asset.type.systemvalueeconomy"),
                          },
                          {
                            value:
                              customValueTypesMappings["systemvaluehardware"],
                            label: t("system_asset.type.systemvaluehardware"),
                          },
                          {
                            value:
                              customValueTypesMappings["systemvalueotherinfo"],
                            label: t("system_asset.type.systemvalueotherinfo"),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvalueotherlawinfo"
                              ],
                            label: t(
                              "system_asset.type.systemvalueotherlawinfo"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvaluepersoninformation"
                              ],
                            label: t(
                              "system_asset.type.systemvaluepersoninformation"
                            ),
                          },
                          {
                            value:
                              customValueTypesMappings[
                                "systemvalueusernamepassword"
                              ],
                            label: t(
                              "system_asset.type.systemvalueusernamepassword"
                            ),
                          },
                        ],
                      },
                      {
                        value: "treatment",
                        label: t(
                          "diri.regassistant.types.orgros.input.systems.types.treatment"
                        ),
                        types: /* getTreatmentTypes() */ [
                          /* {value: "test", label: "test"} */
                        ],
                      },
                    ],
                  },
                },

                {
                  type: "text_normal",
                  title: t(
                    "diri.regassistant.types.orgros.securitytreatments_desc"
                  ),
                },
              ],
            },
            {
              fields: [
                {
                  type: "text_title",
                  title: t(
                    "system_description.system_wizard.new_system.system_participants"
                  ),
                  options: {
                    headertag: "h2",
                  },
                },
                {
                  title: t("wizard_title.input.riskassessmentparticipants"),
                  value: [],
                  type: "personselector",
                  reference: "riskassessmentparticipants",
                  options: {
                    users: res[0].data,
                    organizations: res[1].data,
                    minlength: 2,
                    noresults: t(
                      "wizard_title.input.riskassessmentparticipants_noresult"
                    ),
                    roleTitle: t(
                      "wizard_title.input.riskassessmentparticipants_role"
                    ),
                    organizationTitle: t(
                      "wizard_title.input.riskassessmentparticipants_organization"
                    ),

                    deleteButtonText: t(
                      "wizard_title.input.riskassessmentparticipants_deletebtn"
                    ),
                    addButtonText: t(
                      "wizard_title.input.riskassessmentparticipants_addbtn"
                    ),
                  },
                },
              ],
            },
          ]);
        })
      );
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  /* function getTreatmentTypes() {
      let optionsArray = [] as any;
      props.treatmentOptions.map(option => optionsArray.push({value: option.type, label: option.type}));
      return optionsArray;
    } 
    function getValueTypes() {
      let optionsArray = [] as any;
      props.valueOptions.map(option => optionsArray.push({value: option, label: option}));
      return optionsArray;
    } */

  function createObjects(objects, systemId, org) {
    let creates: Array<any> = [];
    objects.forEach((value) => {
      switch (value.type) {
        case "process":
        case "theme-gdpr":
        case "system":
          creates.push(
            axios.single({
              method: "post",
              url: generateUrl("/api/cra/systems/"),
              responseType: "json",
              data: {
                name: value.title,
                description: value.comment,
                systemType: [value.type],
                org: org,
              },
              headers: { "x-access-token": auth.user["token"] },
            })
          );
          break;
        case "occurence":
          creates.push(
            axios.single({
              method: "post",
              url: generateUrl("/api/cra/occurences/"),
              responseType: "json",
              data: {
                name: value.title,
                description: value.comment,
                system: systemId,
                type: props.occurenceOptions.find(
                  (option) => option._id == value.typeOfType
                )?._id,
              },
              headers: { "x-access-token": auth.user["token"] },
            })
          );
          break;
        case "theme":
          break;
        case "systemvalue":
          creates.push(
            axios.single({
              method: "post",
              url: generateUrl("/api/cra/systemvalues/"),
              responseType: "json",
              data: {
                createitem: true,
                name: value.title,
                valueType:
                  customValueTypesMappings[value.typeOfType] != undefined
                    ? customValueTypesMappings[value.typeOfType]
                    : value.typeOfType,
                system: systemId,
                description: value.comment,
                reasoning: "",
                sensitivepo: false,
                k: 0,
                i: 0,
                t: 0,

                regdata: [
                  {
                    fields: [
                      {
                        type: "text_title",
                        title: t("wizard_title.assets.otherdata.title"),
                      },
                      {
                        type: "text_normal",
                        title: t("wizard_title.assets.otherdata.desc"),
                      },
                      {
                        id: 8,
                        type: "bool",
                        title: t("wizard_title.assets.otherdata.question"),
                        value: true,
                        reference: "createitembool",
                      },
                      {
                        type: "input",
                        title: t("wizard_title.assets.otherdata.asset"),
                        options: {
                          filters: [
                            {
                              if: "notequals",
                              id: 8,
                              value: true,
                              action: "hide",
                            },
                          ],
                        },
                        reference: "title",
                        value: value.title,
                      },
                      {
                        type: "select",
                        title: t("wizard_title.assets.otherdata.question2"),
                        options: {
                          typeLoad: "Manual",
                          choices: [
                            {
                              label: t("wizard_title.assets.other"),
                              value: "Annet",
                            },
                            {
                              label: t("wizard_title.assets.rnd"),
                              value: "Forskning og utvikling",
                            },
                            {
                              label: t("wizard_title.assets.iprights"),
                              value: "Intellektuell eiendom og åndsverk",
                            },
                            {
                              label: t("wizard_title.assets.software"),
                              value: "Software",
                            },
                            {
                              label: t("wizard_title.assets.network"),
                              value: "Network",
                            },
                            {
                              label: t("wizard_title.assets.personnel"),
                              value: "Personnel",
                            },
                            {
                              label: t("wizard_title.assets.bp"),
                              value: "Business Process",
                            },
                            {
                              label: t("wizard_title.assets.site"),
                              value: "Website",
                            },
                            {
                              label: t("wizard_title.assets.org"),
                              value: "Organization",
                            },
                            {
                              label: t("wizard_title.assets.law.title"),
                              value: "Annet lovverk",
                            },
                            {
                              label: t("wizard_title.assets.hardware.title"),
                              value: "Infrastruktur og maskinvare",
                            },
                            {
                              label: t("wizard_title.assets.pii.title"),
                              value: "Personopplysninger",
                            },
                            {
                              label: t("wizard_title.assets.companydata.title"),
                              value: "Virksomhetsdata",
                            },
                            {
                              label: t(
                                "wizard_title.assets.financialdata.title"
                              ),
                              value: "Økonomisk informasjon",
                            },
                          ],
                          filters: [
                            {
                              if: "notequals",
                              id: 8,
                              value: true,
                              action: "hide",
                            },
                          ],
                        },
                      },
                      {
                        type: "textarea",
                        title: t("wizard_title.assets.otherdata.desc2"),
                        options: {
                          filters: [
                            {
                              if: "notequals",
                              id: 8,
                              value: true,
                              action: "hide",
                            },
                          ],
                        },
                        reference: "description",
                        value: value.comment,
                      },
                      {
                        type: "text_normal",
                        title: t("wizard_title.assets.otherdata.deschelptext"),
                        options: {
                          filters: [
                            {
                              if: "notequals",
                              id: 8,
                              value: true,
                              action: "hide",
                            },
                          ],
                        },
                      },
                      {
                        type: "select",
                        title: t("wizard_title.assets.otherdata.conf"),
                        options: {
                          typeLoad: "Manual",
                          choices: getSystemvalueOptionsByKIT("K"),
                          filters: [
                            {
                              if: "notequals",
                              id: 8,
                              value: true,
                              action: "hide",
                            },
                          ],
                        },
                        reference: "confidentiality",
                      },
                      {
                        type: "text_normal",
                        title: t("wizard_title.assets.otherdata.confdesc"),
                        options: {
                          filters: [
                            {
                              if: "notequals",
                              id: 8,
                              value: true,
                              action: "hide",
                            },
                          ],
                        },
                      },
                      {
                        type: "select",
                        title: t("wizard_title.assets.otherdata.integrity"),
                        options: {
                          typeLoad: "Manual",
                          choices: getSystemvalueOptionsByKIT("I"),
                          filters: [
                            {
                              if: "notequals",
                              id: 8,
                              value: true,
                              action: "hide",
                            },
                          ],
                        },
                        reference: "integrity",
                      },
                      {
                        type: "text_normal",
                        title: t("wizard_title.assets.otherdata.integritydesc"),
                        options: {
                          filters: [
                            {
                              if: "notequals",
                              id: 8,
                              value: true,
                              action: "hide",
                            },
                          ],
                        },
                      },
                      {
                        type: "textarea",
                        title: t("wizard_title.assets.otherdata.reason"),
                        options: {
                          filters: [
                            {
                              if: "notequals",
                              id: 8,
                              value: true,
                              action: "hide",
                            },
                          ],
                        },
                        reference: "reasoning",
                      },
                      {
                        type: "text_normal",
                        title: t("wizard_title.assets.otherdata.reasondesc"),
                        options: {
                          filters: [
                            {
                              if: "notequals",
                              id: 8,
                              value: true,
                              action: "hide",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
              headers: { "x-access-token": auth.user["token"] },
            })
          );
          break;

        case "treatment":
          creates.push(
            axios.single({
              method: "post",
              url: generateUrl("/api/cra/treatments/"),
              responseType: "json",
              data: {
                name: value.title,
                description: value.comment,
                system: systemId,
                status: "open",
              },
              headers: { "x-access-token": auth.user["token"] },
            })
          );
          break;
      }
    });

    return creates;
  }

  const handleWizardComplete = (data) => {
    let values = GetWizardValuesByReferenceList(data, [
      "name",
      "selectedorg",
      "itsystemscreator",
      "concernscreator",
      "valuescreator",
      "treatmentscreator",
      "startdate",
      "industrycode",
      "industryssb",
      "employeescount",
      "orgdesc",
      "securityrequirements",
      "importantdeliveries",
      "riskassessmentparticipants",
      "responsibleperson",
    ]);

    if (values.selectedorg == undefined || values.selectedorg == "") {
      notifyWarning(
        t("diri.regassistant.types.orgros.toast.warning_missingOrg")
      );
      return null;
    }

    if (
      values.itsystemscreator != undefined &&
      values.itsystemscreator.length > 0
    ) {
      for (let i = 0; i < values.itsystemscreator.length; i++) {
        if (
          values.itsystemscreator[i].title != "" &&
          values.itsystemscreator[i].type == ""
        ) {
          notifyWarning(
            t(
              "diri.regassistant.types.orgros.toast.warning_missingSystemType"
            ) +
              " " +
              values.itsystemscreator[i].title
          );
          return null;
        }

        if (values.itsystemscreator[i].type == "system") {
          if (
            values.itsystemscreator[i].title == "" ||
            values.itsystemscreator[i].title == undefined
          ) {
            notifyWarning(
              t(
                "diri.regassistant.types.orgros.toast.warning_missingSystemInfo"
              )
            );
            return null;
          }
        }

        if (values.itsystemscreator[i].type == "occurence") {
          if (
            values.itsystemscreator[i].title == "" ||
            values.itsystemscreator[i].title == undefined ||
            values.itsystemscreator[i].typeOfType == "" ||
            values.itsystemscreator[i].typeOfType == undefined
          ) {
            notifyWarning(
              t("diri.regassistant.types.orgros.toast.warning_missingEventType")
            );
            return null;
          }
        }

        if (values.itsystemscreator[i].type == "systemvalue") {
          if (
            values.itsystemscreator[i].title == "" ||
            values.itsystemscreator[i].title == undefined ||
            values.itsystemscreator[i].typeOfType == "" ||
            values.itsystemscreator[i].typeOfType == undefined
          ) {
            notifyWarning(
              t(
                "diri.regassistant.types.orgros.toast.warning_missingSystemvalueInfo"
              )
            );
            return null;
          }
        }

        if (values.itsystemscreator[i].type == "treatment") {
          if (
            values.itsystemscreator[i].title == "" ||
            values.itsystemscreator[i].title == undefined
          ) {
            notifyWarning(
              t(
                "diri.regassistant.types.orgros.toast.warning_missingTreatmentInfo"
              )
            );
            return null;
          }
        }
      }
    }

    if (
      values.concernscreator != undefined &&
      values.concernscreator.length > 0
    ) {
      for (let i = 0; i < values.concernscreator.length; i++) {
        if (
          values.concernscreator[i].title != "" &&
          values.concernscreator[i].type == ""
        ) {
          notifyWarning(
            t(
              "diri.regassistant.types.orgros.toast.warning_missingSystemType"
            ) +
              " " +
              values.concernscreator[i].title
          );
          return null;
        }

        if (values.concernscreator[i].type == "system") {
          if (
            values.concernscreator[i].title == "" ||
            values.concernscreator[i].title == undefined
          ) {
            notifyWarning(
              t(
                "diri.regassistant.types.orgros.toast.warning_missingSystemInfo"
              )
            );
            return null;
          }
        }

        if (values.concernscreator[i].type == "occurence") {
          if (
            values.concernscreator[i].title == "" ||
            values.concernscreator[i].title == undefined ||
            values.concernscreator[i].typeOfType == "" ||
            values.concernscreator[i].typeOfType == undefined
          ) {
            notifyWarning(
              t("diri.regassistant.types.orgros.toast.warning_missingEventType")
            );
            return null;
          }
        }

        if (values.concernscreator[i].type == "systemvalue") {
          if (
            values.concernscreator[i].title == "" ||
            values.concernscreator[i].title == undefined ||
            values.concernscreator[i].typeOfType == "" ||
            values.concernscreator[i].typeOfType == undefined
          ) {
            notifyWarning(
              t(
                "diri.regassistant.types.orgros.toast.warning_missingSystemvalueInfo"
              )
            );
            return null;
          }
        }

        if (values.concernscreator[i].type == "treatment") {
          if (
            values.concernscreator[i].title == "" ||
            values.concernscreator[i].title == undefined
          ) {
            notifyWarning(
              t(
                "diri.regassistant.types.orgros.toast.warning_missingTreatmentInfo"
              )
            );
            return null;
          }
        }
      }
    }

    if (values.valuescreator != undefined && values.valuescreator.length > 0) {
      for (let i = 0; i < values.valuescreator.length; i++) {
        if (
          values.valuescreator[i].title != "" &&
          values.valuescreator[i].type == ""
        ) {
          notifyWarning(
            t(
              "diri.regassistant.types.orgros.toast.warning_missingSystemType"
            ) +
              " " +
              values.valuescreator[i].title
          );
          return null;
        }

        if (values.valuescreator[i].type == "system") {
          if (
            values.valuescreator[i].title == "" ||
            values.valuescreator[i].title == undefined
          ) {
            notifyWarning(
              t(
                "diri.regassistant.types.orgros.toast.warning_missingSystemInfo"
              )
            );
            return null;
          }
        }

        if (values.valuescreator[i].type == "occurence") {
          if (
            values.valuescreator[i].title == "" ||
            values.valuescreator[i].title == undefined ||
            values.valuescreator[i].typeOfType == "" ||
            values.valuescreator[i].typeOfType == undefined
          ) {
            notifyWarning(
              t("diri.regassistant.types.orgros.toast.warning_missingEventType")
            );
            return null;
          }
        }

        if (values.valuescreator[i].type == "systemvalue") {
          if (
            values.valuescreator[i].title == "" ||
            values.valuescreator[i].title == undefined ||
            values.valuescreator[i].typeOfType == "" ||
            values.valuescreator[i].typeOfType == undefined
          ) {
            notifyWarning(
              t(
                "diri.regassistant.types.orgros.toast.warning_missingSystemvalueInfo"
              )
            );
            return null;
          }
        }

        if (values.valuescreator[i].type == "treatment") {
          if (
            values.valuescreator[i].title == "" ||
            values.valuescreator[i].title == undefined
          ) {
            notifyWarning(
              t(
                "diri.regassistant.types.orgros.toast.warning_missingTreatmentInfo"
              )
            );
            return null;
          }
        }
      }
    }

    if (
      values.treatmentscreator != undefined &&
      values.treatmentscreator.length > 0
    ) {
      for (let i = 0; i < values.treatmentscreator.length; i++) {
        if (
          values.treatmentscreator[i].title != "" &&
          values.treatmentscreator[i].type == ""
        ) {
          notifyWarning(
            t(
              "diri.regassistant.types.orgros.toast.warning_missingSystemType"
            ) +
              " " +
              values.treatmentscreator[i].title
          );
          return null;
        }

        if (values.treatmentscreator[i].type == "system") {
          if (
            values.treatmentscreator[i].title == "" ||
            values.treatmentscreator[i].title == undefined
          ) {
            notifyWarning(
              t(
                "diri.regassistant.types.orgros.toast.warning_missingSystemInfo"
              )
            );
            return null;
          }
        }

        if (values.treatmentscreator[i].type == "occurence") {
          if (
            values.treatmentscreator[i].title == "" ||
            values.treatmentscreator[i].title == undefined ||
            values.treatmentscreator[i].typeOfType == "" ||
            values.treatmentscreator[i].typeOfType == undefined
          ) {
            notifyWarning(
              t("diri.regassistant.types.orgros.toast.warning_missingEventType")
            );
            return null;
          }
        }

        if (values.treatmentscreator[i].type == "systemvalue") {
          if (
            values.treatmentscreator[i].title == "" ||
            values.treatmentscreator[i].title == undefined ||
            values.treatmentscreator[i].typeOfType == "" ||
            values.treatmentscreator[i].typeOfType == undefined
          ) {
            notifyWarning(
              t(
                "diri.regassistant.types.orgros.toast.warning_missingSystemvalueInfo"
              )
            );
            return null;
          }
        }

        if (values.treatmentscreator[i].type == "treatment") {
          if (
            values.treatmentscreator[i].title == "" ||
            values.treatmentscreator[i].title == undefined
          ) {
            notifyWarning(
              t(
                "diri.regassistant.types.orgros.toast.warning_missingTreatmentInfo"
              )
            );
            return null;
          }
        }
      }
    }

    axios
      .all([
        axios.single({
          method: "post",
          url: generateUrl("/api/orgelements/addros/") + values.selectedorg,
          responseType: "json",
          data: {
            name: values.name,
            alias: values.name,
            data: values,
          },
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          let creates: Array<any> = [];

          if (values.itsystemscreator != undefined)
            creates.push(
              ...createObjects(
                values.itsystemscreator,
                res[0].data._id,
                values.selectedorg
              )
            );
          if (values.concernscreator != undefined)
            creates.push(
              ...createObjects(
                values.concernscreator,
                res[0].data._id,
                values.selectedorg
              )
            );
          if (values.valuescreator != undefined)
            creates.push(
              ...createObjects(
                values.valuescreator,
                res[0].data._id,
                values.selectedorg
              )
            );
          if (values.treatmentscreator != undefined)
            creates.push(
              ...createObjects(
                values.treatmentscreator,
                res[0].data._id,
                values.selectedorg
              )
            );
          if (creates.length > 0) {
            axios.all(creates).then(
              axios.spread((...res2) => {
                if (props.regCompleted != undefined)
                  props.regCompleted(res[0].data);
                history.push("/system/" + res[0].data._id);
              })
            );
          } else {
            if (props.regCompleted != undefined)
              props.regCompleted(res[0].data);
            history.push("/system/" + res[0].data._id);
          }
        })
      );
  };

  return template.length > 0 ? (
    <Wizard
      data={JSON.parse(JSON.stringify(template))}
      onComplete={(data) => {
        handleWizardComplete(data);
        //if (props.regCompleted != undefined) props.regCompleted(data);
      }}
      setPage={(e) => {
        props.setPage(e);
      }}
    ></Wizard>
  ) : null;
}

export default OrgrosRegTemplate;
