import React, { useEffect, useState } from 'react';
import { useAxios } from "../../../../../AxiosHandler";
import { useTranslate } from 'react-polyglot';
import { useAuth } from "../../../../../Auth";
import { useHistory } from "react-router-dom";

import { Card, Table, Button } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import Field from '../../../../Shared/Wizard/Field/Field';

function SystemStatusesBarChart(props){
    const t = useTranslate();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();
    const history = useHistory();

    const [selectedOption, setSelectedOption] = useState("all");
    const [loading, setLoading] = useState(true);
    const [selectedAssets, setSelectedAssets] = useState<Array<any>>([]);

    const [systemStatusesLength, setSystemStatusesLength] = useState<Array<any>>([]);
    const [systemStatusesData, setSystemStatusesData] = useState<Array<any>>([]);

    let selectTitle = t("dashboard.cards.drop_down_select_title");
    let selectOptions = [
        {
            "label": t("dashboard.cards.choice_all"),
            "value": "all"
        },
        {
            "label": t("dashboard.cards.choice_system"),
            "value": "system"
        },
        {
            "label": t("dashboard.cards.choice_orgros"),
            "value": "orgros"
        },
        {
            "label": t("dashboard.cards.choice_other"),
            "value": "other"
        },
    ]

    const labels = [
        t("dashboard.cards.systemstatusesbarchart.registered"),
        t("dashboard.cards.systemstatusesbarchart.assetevaluated"),
        t("dashboard.cards.systemstatusesbarchart.riskevaluated"),
        t("dashboard.cards.systemstatusesbarchart.planned"),
        t("dashboard.cards.systemstatusesbarchart.completed"),
        t("dashboard.cards.systemstatusesbarchart.needsrevision")
    ]

    const colors = [
        "#c8c8c8",
        "#993742",
        "#d8775a",
        "#facd75",
        "#5b8266",
        "purple",
    ]

    const data = {
        labels: labels,
        datasets: [
            {
                backgroundColor: colors,
                borderWidth: 1,
                data: systemStatusesLength,
                objects: systemStatusesData
          },
        ],
    };

    const options = {
        title:{
            display:true,
            fontSize:20
        },
        legend:{
            display: false,
            position:'right'
        },
        scales: {
            yAxes: [{
                stacked: true,
                ticks: {
                    suggestedMin: 0,
                    stepSize: 1
                },
            }]
        },
        tooltips: {
            mode: 'label',
            callbacks: {
                label: function(tooltipItem, data) {
                    return data['datasets'][0]['data'][tooltipItem['index']];
                }
            }
        },
        onClick: function(event, elements) {
            if (elements.length > 0) {
                handleChartClick(event, elements);
        }},
    }


    function handleSelectInput(value){
        setLoading(true);
        setSelectedOption(value);
    }

    function handleChartClick(evt, elements) {
        setSelectedAssets(systemStatusesData[elements[0]._index]);
    }

    async function processData(res, type){
        if (type == "all") {
            res[0].data = res[0].data;
        } else if (type == "system") {
            res[0].data = res[0].data.filter(res => res.systemType.toString().toLowerCase().includes(type));
        } else {
            res[0].data = res[0].data.filter(res => res.systemType.toString().toLowerCase() == type);
        }

        let systemValues = res[1].data;
        let occurences = res[2].data;

        let tmpLength = [] as any;
        let tmpData = [] as any;

        tmpLength.push(res[0].data.filter(res => (res.stepscompleted != undefined && res.stepscompleted.step1 == true) || (res.stepscompleted == undefined)).length);
        tmpLength.push(res[0].data.filter(res => (res.stepscompleted != undefined && res.stepscompleted.step2 == true) || (res.stepscompleted == undefined && systemValues.map(value => value.system).includes(res._id))).length);
        tmpLength.push(res[0].data.filter(res => (res.stepscompleted != undefined && res.stepscompleted.step3 == true) || (res.stepscompleted == undefined && occurences.map(occurence => occurence["system"]).includes(res["_id"]))).length);
        tmpLength.push(res[0].data.filter(res => (res.stepscompleted != undefined && res.stepscompleted.step4 == true) || (res.stepscompleted == undefined && res["actionplan"] != undefined && Object.keys(res["actionplan"]).length > 0 && res["actionplan"] != null && res["actionplan"] != "")).length);
        tmpLength.push(res[0].data.filter(res => res.actionplan != undefined && res.actionplan.finishedvaliduntil != "" && new Date(res.actionplan.finishedvaliduntil) > new Date()).length);
        tmpLength.push(res[0].data.filter(res => res.actionplan != undefined && res.actionplan.finishedvaliduntil != "" && new Date(res.actionplan.finishedvaliduntil) < new Date()).length);

        tmpData.push(res[0].data.filter(res => (res.stepscompleted != undefined && res.stepscompleted.step1 == true) || (res.stepscompleted == undefined)));
        tmpData.push(res[0].data.filter(res => (res.stepscompleted != undefined && res.stepscompleted.step2 == true) || (res.stepscompleted == undefined && systemValues.map(value => value.system).includes(res._id))));
        tmpData.push(res[0].data.filter(res => (res.stepscompleted != undefined && res.stepscompleted.step3 == true) || (res.stepscompleted == undefined && occurences.map(occurence => occurence["system"]).includes(res["_id"]))));
        tmpData.push(res[0].data.filter(res => (res.stepscompleted != undefined && res.stepscompleted.step4 == true) || (res.stepscompleted == undefined && res["actionplan"] != undefined && Object.keys(res["actionplan"]).length > 0 && res["actionplan"] != null && res["actionplan"] != "")));
        tmpData.push(res[0].data.filter(res => res.actionplan != undefined && res.actionplan.finishedvaliduntil != "" && new Date(res.actionplan.finishedvaliduntil) > new Date()));
        tmpData.push(res[0].data.filter(res => res.actionplan != undefined && res.actionplan.finishedvaliduntil != "" && new Date(res.actionplan.finishedvaliduntil) < new Date()));

        setSystemStatusesLength(tmpLength);
        setSystemStatusesData(tmpData);
    }

    useEffect(() => {
        Promise.all([
            props.requestData("systems"),
            props.requestData("systemvalues"),
            props.requestData("occurences")
        ]).then(async (data) => {
            await processData(data, selectedOption);
            setLoading(false);
        });
    }, [selectedOption]);


    return(
        <Card>
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                <h4>{t("dashboard.cards.systemstatusesbarchart.title")}</h4>
                <p>{t("dashboard.cards.systemstatusesbarchart.description")}</p>
                <Field
                    type="select"
                    title={ selectTitle }
                    onChange={ value => handleSelectInput(value.value) }
                    options={{
                        "choices": selectOptions
                    }}
                > 
                </Field>
                <hr />
                <div>
                    <Bar
                        data={data}
                        options={options}
                    />
                </div>
                {selectedAssets != undefined && selectedAssets.length > 0 ?
                    <div className="chartDetails">
                        <div>
                            <h4 className="header-title">{t("dashboard.cards.systemstatusesbarchart.details.title")}</h4>
                            <Button onClick={() => {
                                setSelectedAssets([]);
                            }}>{t("system.matrix_details_closebtn")}</Button>
                            <Table striped hover className="chartdetailstable">
                                <thead>
                                    <tr>
                                        <th>{t("dashboard.cards.systemstatusesbarchart.details.system")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        selectedAssets.map((element, key) => {
                                            return(
                                                <tr key={key} style={{cursor: "pointer"}} onClick={() => {
                                                    history.push("/system/" + element._id);
                                                    window.scrollTo(0, 0);
                                                }}>
                                                    <td>{ element["nameReadable"] }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                : null}
            </Card.Body>
        </Card>
    )

}

export default SystemStatusesBarChart;