/*async function fetchData(axios, auth): Promise<any> {
    let results = {
        users: [],
        organizations: []
    };

    await axios.all([
        axios.single({
            method: "get",
            url: "https://test.diri.ai/api/users/",
            responseType: "json",
            headers: {'x-access-token': auth.user["token"]}
        }),
        axios.single({
			method: "get",
			url: "https://test.diri.ai/api/orgElements/",
			responseType: "json",
			headers: {'x-access-token': auth.user["token"]}
      })
    ])
    .then(axios.spread(async (...res) => {
        results.users = res[0].data;
        results.organizations = res[1].data;
    }));

    return results;
}*/

let localStorageUser = localStorage.getItem("user");
let settings = {};
if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
}

function getSystemvalueOptionsByKIT(kit){
    let options = Array();
    if(settings["systemvalue" + kit + "Num"] != undefined){
        for (let i = 1; i <= parseInt(settings["systemvalue" + kit + "Num"]); i++) {
            options.push({
                label: settings["systemvalue" + kit + "Level" + i],
                value: i
            });
        }
        return options;
    }
}

async function CompanydataTemplate(t, axios, auth) {
    if(localStorageUser == null){
        localStorageUser = localStorage.getItem("user");
    }
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    //let data = await fetchData(axios, auth);

    let tempWizardData: Array<any> = [
        {
            fields: [
                {
                    type: "text_title",
                    title: t("wizard_title.assets.companydata.title")
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.companydata.desc")
                },
                {
                    id: 5,
                    type: "bool",
                    title: t("wizard_title.assets.companydata.question") + " *",
                    value: "",
                    reference: "createitembool"
                },
                {
                    type: "textarea",
                    title: t("wizard_title.assets.reason_not_relevant"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 5,
                                value: false,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "reasoning_not"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.reason_not_relevant_desc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 5,
                                value: false,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "selectmulti",
                    title: t("wizard_title.assets.companydata.question2"),
                    options: {
                        typeLoad: "Manual",
                        choices: [
                            {
                                label: t("wizard_title.assets.companydata.assets.avtaler"),
                                value: "Avtaler"
                            },
                            {
                                label: t("wizard_title.assets.companydata.assets.databases"),
                                value: "Databaser"
                            },
                            {
                                label: t("wizard_title.assets.companydata.assets.secrets"),
                                value: "Forretningshemmeligheter"
                            },
                            {
                                label: t("wizard_title.assets.companydata.assets.research"),
                                value: "Forskningsdata"
                            },
                            {
                                label: t("wizard_title.assets.companydata.assets.referater"),
                                value: "Møtereferater"
                            },
                            {
                                label: t("wizard_title.assets.companydata.assets.training"),
                                value: "Opplæringsinformasjon"
                            },
                            {
                                label: t("wizard_title.assets.companydata.assets.case"),
                                value: "Saksdokumenter"
                            },
                            {
                                label: t("wizard_title.assets.companydata.assets.strategy"),
                                value: "Strategier"
                            },
                            {
                                label: t("wizard_title.assets.companydata.assets.customerdata"),
                                value: "Kundedata"
                            },
                            {
                                label: t("wizard_title.assets.companydata.assets.other"),
                                value: "Annet"
                            }
                        ],
                        filters: [
                            {
                                if: "notequals",
                                id: 5,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "business_data"
                },
                {
                    type: "textarea",
                    title: t("wizard_title.assets.companydata.desc2"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 5,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "description"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.companydata.deschelptext"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 5,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "select",
                    title: t("wizard_title.assets.companydata.conf"),
                    options: {
                        typeLoad: "Manual",
                        choices: getSystemvalueOptionsByKIT("K"),
                        filters: [
                            {
                                if: "notequals",
                                id: 5,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "confidentiality"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.companydata.confdesc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 5,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "select",
                    title: t("wizard_title.assets.companydata.integrity"),
                    options: {
                        typeLoad: "Manual",
                        choices: getSystemvalueOptionsByKIT("I"),
                        filters: [
                            {
                                if: "notequals",
                                id: 5,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "integrity"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.companydata.integritydesc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 5,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "textarea",
                    title: t("wizard_title.assets.companydata.reason"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 5,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "reasoning"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.companydata.reasondesc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 5,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                }
            ]
        }
    ]

    return tempWizardData;
}

export default CompanydataTemplate;
