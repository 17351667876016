import React, { useEffect, useState } from 'react';
import { useAxios } from "../../../../../AxiosHandler";

import { useTranslate } from 'react-polyglot';
import './PublicLawOverview.scss';
import { useAuth } from "../../../../../Auth";
import { Card } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';

const PublicLawOverview = (props) => {
    const [nodata, setNodata] = useState(0);
    const [publicLaw, setPublicLaw] = useState(0);
    const [nonPublicLaw, setNonPublicLaw] = useState(0);
 
    const [chartOptions, setChartOptions] = useState({});

    const [loading, setLoading] = useState(true);

    const t = useTranslate();

    const labelNodata = t("dashboard.nodata");
    const labePublicLaw = t("dashboard.publiclaw");
    const labelNonPublicLaw = t("dashboard.nonpubliclaw");
 
    async function processData(res) {
        let returns = {
            tempNodata: 0,
            tempPublicLaw: 0,
            tempNonPublicLaw: 0,
        }

        let tempNodata = 0;
        let tempPublicLaw = 0;
        let tempNonPublicLaw = 0;

        res[0].data.filter(s => s.accessRule != "adminprivate").forEach((item, key) => {
            if (item["publicLaw"] == undefined) {
                tempNodata += 1;
            }
            if (item["publicLaw"] == true) {
                tempPublicLaw += 1;
            }
            if (item["publicLaw"] == false) {
                tempNonPublicLaw += 1;
            }
        });

        returns.tempNodata = tempNodata;
        returns.tempPublicLaw = tempPublicLaw;
        returns.tempNonPublicLaw = tempNonPublicLaw;

        setNodata(returns["tempNodata"]);
        setPublicLaw(returns["tempPublicLaw"]);
        setNonPublicLaw(returns["tempNonPublicLaw"]);

        setLoading(false);
        setChartOptions({
            legend: {
                position: "left"
            }
        });
    }
    
    useEffect(() => {
        Promise.all([
            props.requestData("systems")
        ]).then(async (data) => {
            await processData(data);
            setLoading(false);
        });
    }, []);

    return (
        <Card data-satarget="riskoverview">
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                <h4 className="header-title">{t("dashboard.publiclaw")}</h4>
                <p>{t("dashboard.publiclaw_information")}</p>
                <hr/>
                <Doughnut
                    redraw={true}
                    data={{
                        datasets: [
                            {
                                data: [nodata, publicLaw, nonPublicLaw],
                                backgroundColor: [
                                    "rgb(200, 200, 200, 1)",
                                    "rgb(216, 119, 90, 1)",
                                    "rgb(91, 130, 102, 1)",
                                ]
                            }
                        ],
                        labels:[
                            labelNodata,
                            labePublicLaw,
                            labelNonPublicLaw,
                        ]
                    }}
                    options={chartOptions}
                />
            </Card.Body>
        </Card>
    );
}

export default PublicLawOverview;
