function GetWizardFieldByReference(wizard, reference):any {
    let foundField = undefined;
    wizard.forEach(page => {
        page["fields"].forEach(field => {
            if (field["reference"] == reference) foundField = field;
        });
    });
    return foundField;
}

function GetWizardValuesByReferenceList(wizard, referenceList):any {
    let foundValues: any = {};
    wizard.forEach(page => {
        page["fields"].forEach(field => {
            if (referenceList.includes(field["reference"])){
                if (field["type"] == "select") {
                    if (field["value"] != undefined) {
                        foundValues[field["reference"]] = field.value != undefined && field.value.value != undefined ? field.value.value : field.value;
                    }
                } else if (field["type"] == "selectmulti") {
                    if (field["value"] != undefined && field["value"].length > 0) {
                        foundValues[field["reference"]] = field.value;
                    }
                } else {
                    foundValues[field["reference"]] = field.value;
                }
            }
        });
    });
    return foundValues;
}

function SetFieldValueByReference(wizard, reference, value) {
    wizard.forEach(page => {
        page["fields"].forEach(field => {
            if (field["reference"] == reference) {
                if (field["type"] == "select") {
                    field.value != undefined ? field.value.value = value : field.value = value;
                } else if (field["type"] == "selectmulti") {
                    if(value != undefined && typeof value != "string"){
                        field.value = value.map(v => {
                            let label = field.options.choices.find(c => c.value == v.value).label;
                            return { label: label, value: v.value};
                        });
                    }
                    
                } else {
                    field.value = value;
                }
            }
        });
    });
}

function SetWizardValuesByReferenceValues(referenceValues, wizard) {
    for (const [key, value] of Object.entries(referenceValues)) {
        SetFieldValueByReference(wizard, key, value);
    }
    
    return wizard;
}

function HasReference(wizard, reference):boolean {
    let foundValue = false;
    wizard.forEach(page => {
        page["fields"].forEach(field => {
            if (reference == field["reference"]){
                foundValue = true;
            }
        });
    });
    return foundValue;
}

export default {
    GetWizardFieldByReference: GetWizardFieldByReference,
    GetWizardValuesByReferenceList: GetWizardValuesByReferenceList,
    SetWizardValuesByReferenceValues: SetWizardValuesByReferenceValues,
    HasReference: HasReference,
};