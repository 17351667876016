import * as React from "react";

import { useEffect, useState } from "react";
import { useTranslate } from "react-polyglot";
import { Accordion, Button } from "react-bootstrap";

import { useAxios } from "../../../../AxiosHandler";
import { useAuth } from "../../../../Auth";

import RegTemplate from "../../RegTemplates/RegTemplate";

import { generateUrl } from "../../../../config";

import "../RegAssistant/RegAssistant.scss";

function SystemValueRegAssistant(props) {
  const t = useTranslate();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const auth = useAuth();

  const [regState, setRegState] = useState("select");
  const [selectedType, setSelectedType] = useState("");

  const [systemvalues, setSystemvalues] = useState<Array<any>>([]);

  const [systemvaluesUntouched, setSystemvaluesUntouched] = useState<
    Array<any>
  >([]);
  const [systemvaluesHasvalue, setSystemvaluesHasvalue] = useState<Array<any>>(
    []
  );
  const [systemvaluesNotrelevant, setSystemvaluesNotrelevant] = useState<
    Array<any>
  >([]);

  const categories = [
    {
      name: t("wizard_title.assets.usernamepw.title"),
      description: t("wizard_title.assets.catdesc.passw"),
      key: "systemvalueusernamepassword",
    },
    {
      name: t("wizard_title.assets.communcationdata.title"),
      description: t("wizard_title.assets.catdesc.comdata"),
      key: "systemvaluecommunication",
    },
    {
      name: t("wizard_title.assets.law.title"),
      description: t("wizard_title.assets.catdesc.law"),
      key: "systemvalueotherlawinfo",
    },
    {
      name: t("wizard_title.assets.pii.title"),
      description: t("wizard_title.assets.catdesc.pol"),
      key: "systemvaluepersoninformation",
    },
    {
      name: t("wizard_title.assets.companydata.title"),
      description: t("wizard_title.assets.catdesc.companydata"),
      key: "systemvaluecompanydata",
    },
    {
      name: t("wizard_title.assets.otherdata.title"),
      description: t("wizard_title.assets.catdesc.otherinfo"),
      key: "systemvalueotherinfo",
    },
    {
      name: t("wizard_title.assets.financialdata.title"),
      description: t("wizard_title.assets.catdesc.finan"),
      key: "systemvalueeconomy",
    },
    {
      name: t("wizard_title.assets.hardware.title"),
      description: t("wizard_title.assets.catdesc.hardware"),
      key: "systemvaluehardware",
    },
  ];

  const customValueTypes = [
    "systemvaluecommunication",
    "systemvaluecompanydata",
    "systemvalueeconomy",
    "systemvaluehardware",
    "systemvalueotherinfo",
    "systemvalueotherlawinfo",
    "systemvaluepersoninformation",
    "systemvalueusernamepassword",
  ];

  const customValueTypesMappings = {
    Kommunikasjonsdata: "systemvaluecommunication",
    Virksomhetsdata: "systemvaluecompanydata",
    "Økonomiske og finansielle data": "systemvalueeconomy",
    "Infrastruktur og hardware": "systemvaluehardware",
    "Annen verdifull informasjon i systemet": "systemvalueotherinfo",
    "Informasjon underlagt annet lovverk": "systemvalueotherlawinfo",
    Personopplysninger: "systemvaluepersoninformation",
    "Brukerdatabase med passord": "systemvalueusernamepassword",
  };

  function fetchData() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systemvalues/bysystem/") + props.systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systems/") + props.systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          for (let i = 0; i < res[0].data.length; i++) {
            let dataLine = res[0].data[i];
            if (
              !customValueTypes.includes(dataLine["valueType"]) &&
              customValueTypesMappings[dataLine["valueType"]] != undefined
            ) {
              dataLine["valueType"] =
                customValueTypesMappings[dataLine["valueType"]];
            }
          }
          setSystemvalues(res[0].data);
          setSystemvaluesUntouched(
            categories.filter(
              (category) =>
                !res[0].data.some((i) => i["valueType"] == category["key"]) &&
                !res[1].data["notRelevantValues"].includes(category["key"])
            )
          );
          setSystemvaluesHasvalue(
            categories.filter((category) =>
              res[0].data.some((i) => i["valueType"] == category["key"])
            )
          );
          setSystemvaluesNotrelevant(
            categories.filter(
              (category) =>
                !res[0].data.some((i) => i["valueType"] == category["key"]) &&
                res[1].data["notRelevantValues"].includes(category["key"])
            )
          );
        })
      )
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="regassistant">
      {regState == "select" ? (
        <div>
          <Accordion defaultActiveKey="0">
            {systemvaluesUntouched.length > 0 ? (
              <div className="categorygroup withoutvalues">
                <Accordion.Toggle className="clickable" as={"div"} eventKey="0">
                  <h4 className="header-title">
                    {t("system.systemvalues.withoutvalues.title")}
                  </h4>
                  <hr />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <React.Fragment>
                    {systemvaluesUntouched.map((category, key) => (
                      <div
                        className="valuetype"
                        key={key}
                        onClick={(evt) => {
                          setSelectedType(category["key"]);
                          setRegState("register");
                        }}
                      >
                        <div className="texts">
                          <h3>{category["name"]}</h3>
                          <p>{category["description"]}</p>
                        </div>
                        <div className="arrow">
                          <i className="dripicons-chevron-right" />
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                </Accordion.Collapse>
              </div>
            ) : null}
          </Accordion>
          <Accordion defaultActiveKey="1">
            {systemvaluesHasvalue.length > 0 ? (
              <div className="categorygroup withvalues">
                <Accordion.Toggle className="clickable" as={"div"} eventKey="1">
                  <h4 className="header-title">
                    {t("system.systemvalues.withvalues.title")}
                  </h4>
                  <hr />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <React.Fragment>
                    {systemvaluesHasvalue.map((category, key) => (
                      <div
                        className="valuetype"
                        key={key}
                        onClick={(evt) => {
                          setSelectedType(category["key"]);
                          setRegState("register");
                        }}
                      >
                        <div className="texts">
                          <h3>{category["name"]}</h3>
                          <p>{category["description"]}</p>
                        </div>
                        <div className="arrow">
                          <i className="dripicons-chevron-right" />
                        </div>
                        <div className="amount">
                          {
                            systemvalues.filter(
                              (i) => i["valueType"] == category["key"]
                            ).length
                          }
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                </Accordion.Collapse>
              </div>
            ) : null}
          </Accordion>
          <Accordion defaultActiveKey="2">
            {systemvaluesNotrelevant.length > 0 ? (
              <div className="categorygroup notrelevant">
                <Accordion.Toggle className="clickable" as={"div"} eventKey="2">
                  <h4 className="header-title">
                    {t("system.systemvalues.notrelevant.title")}
                  </h4>
                  <hr />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <React.Fragment>
                    {systemvaluesNotrelevant.map((category, key) => (
                      <div
                        className="valuetype"
                        key={key}
                        onClick={(evt) => {
                          setSelectedType(category["key"]);
                          setRegState("register");
                        }}
                      >
                        <div className="texts">
                          <h3>{category["name"]}</h3>
                          <p>{category["description"]}</p>
                        </div>
                        <div className="arrow">
                          <i className="dripicons-chevron-right" />
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                </Accordion.Collapse>
              </div>
            ) : null}
          </Accordion>
        </div>
      ) : null}
      {regState == "register" ? (
        <div className="registercontainer">
          <Button
            onClick={() => setRegState("select")}
            style={{ marginBottom: "10px" }}
          >
            {t("system.systemvalues.dirihelper.backbtn")}
          </Button>
          <RegTemplate
            regType={selectedType}
            regCompleted={(data) => {
              setRegState("select");
              fetchData();
              if (props.regCompleted != undefined) props.regCompleted(data);
            }}
            systemid={props.systemid}
            system={props.system}
            setPage={(e) => {
              props.setPage(e);
            }}
          ></RegTemplate>
        </div>
      ) : null}
    </div>
  );
}

export default SystemValueRegAssistant;
