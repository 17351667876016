import React, { useState, useEffect } from "react";

import { useTranslate } from "react-polyglot";
import { Card } from "react-bootstrap";

import { useAuth } from "../../../../../Auth";

import RiskMatrixChart from "../../../../Shared/RiskMatrixChart/RiskMatrixChart";

function RiskMatrixCard(props) {
  const t = useTranslate();
  const auth = useAuth();

  const [loading, setLoading] = useState(true);
  const [treatments, setTreatments] = useState<any>([]);

  function processData(res) {
    let tempTreatments = [] as any;

    res[0].data.map((system) =>
      system["actionplan"] != undefined
        ? system["actionplan"]["treatments"].length != 0
          ? system["actionplan"]["treatments"].map((treatment) =>
              tempTreatments.push(treatment)
            )
          : null
        : null
    );

    // tempTreatments = tempTreatments.filter(
    //   (treatment) =>
    //     treatment != undefined &&
    //     typeof treatment != "string" &&
    //     treatment.status == "closed"
    // );
    setTreatments(tempTreatments);
    setLoading(false);
  }

  useEffect(() => {
    if (auth.user["userdata"] == undefined) {
      window.location.href = "/login";
    }
    Promise.all([props.requestData("systems")]).then(async (data) => {
      await processData(data);
      setLoading(false);
    });
  }, []);

  return (
    <Card>
      <Card.Body>
        <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
          <p>{t("dashboard.cards.loadingtext")}</p>
        </div>
        <h4 className="header-title">
          {t("dashboard.cards.riskmatrixcard.title")}
        </h4>
        <p>{t("dashboard.cards.riskmatrixcard.information")}</p>
        <hr />
        <div>
          <RiskMatrixChart
            fromDashboard={true}
            defaultValue={2}
            treatmentsCompleted={treatments}
            // treatmentsCompleted={
            //   system["actionplan"] != undefined
            //     ? system["actionplan"]["treatments"]
            //     : []
            // }
          ></RiskMatrixChart>
        </div>
      </Card.Body>
    </Card>
  );
}

export default RiskMatrixCard;
