import * as React from 'react';
import { useTranslate } from 'react-polyglot';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../Auth';
import { useAxios } from '../../../AxiosHandler';
import {
    useHistory
  } from "react-router-dom";

import { toast } from 'react-toastify';
import Shop from '../../Shared/Shop/Shop';
import './ShopPage.scss';


function ShopPage() {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const t = useTranslate();
    let history = useHistory();


    useEffect(() => {
    }, []);

    return(
        <div className={(auth.user["userdata"] != undefined ? "maincontent" : "") + " shop-page"}>
            <Shop
                modal={false}
                orgid={auth.user["userdata"] != undefined ? auth.user["userdata"]["unit"][0] : ""}
                mainOnly
                noFooter
            />
        </div>
    );
}


export default ShopPage;