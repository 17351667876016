import React, { useEffect, useState } from 'react';
import { useAxios } from "../../../../../AxiosHandler";

import { useTranslate } from 'react-polyglot';
import './TopVulnerabilities.scss';
import { useAuth } from "../../../../../Auth";
import { Card, Modal, InputGroup, Form } from 'react-bootstrap';
import { HorizontalBar } from 'react-chartjs-2';
import _ from 'lodash';
import PageTable from '../../../../Shared/PageTable/PageTable';
import { generateUrl } from '../../../../../config';

const TopVulnerabilities = (props) => {
    const t = useTranslate();   
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();
    const [allCauses, setAllCauses] = useState([{}]);
    const [allOccurences, setAllOccurences] = useState([{}]);
    const [topCauses, setTopCauses] = useState([{}]);
    const [labels, setLabels] = useState([{}]);
    const [colors, setColors] = useState([{}]);
    const [loading, setLoading] = useState(true);
    const [selectedOccurence, setSelectedOccurence] = useState<Object>();
    const [selectedCause, setSelectedCause] = useState({});
    const [selectedCauseProb, setSelectedCauseProb] = useState();

    const [updateTable, setUpdateTable] = useState(0);

    const label_top5 = t("dashboard.cards.top5causes");
    const [showCauseModal, setShowCauseModal] = useState(false);
    const handleCloseCauseModal = () => setShowCauseModal(false);

    const treatmentName = t("treatment.name");
    const treatmentCost = t("treatment.cost");
    const treatmentStatus = t("treatment.status");
    const treatmentResponsible = t("treatment.responsible");
    const probabilityReduction = t("treatment.probability_reduction");
    const treatmentTime = t("treatment.time");

    const localStorageUser = localStorage.getItem("user");
    let settings = {};

    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    function valueToPercentage(value, maxvalue) {
        return ((value - 1) / (maxvalue - 1) * 100);
    }

    function percentageToValue(percentage, maxvalue) {
        return ((maxvalue - 1) * (percentage / 100)) + 1;
    }
  
    function handleChartClick(event, elements){
        setShowCauseModal(true);

        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        let tmpSelectedCause = topCauses[elements[0]._index];
        
        if (tmpSelectedCause["_id"] != selectedCause["_id"]) {
            let tmpOccurence:any = allOccurences.find(i => i != undefined && i["_id"] == tmpSelectedCause["occurence"]);

            setSelectedOccurence(tmpOccurence);
            setSelectedCause(tmpSelectedCause);
            let tmpProbability:any;

            if(tmpSelectedCause["probability"] != undefined){
                tmpProbability = Math.round(percentageToValue(tmpSelectedCause["probability"], settings["gridY"]));
            }

            switch(tmpProbability){
                case 0:
                    setSelectedCauseProb(settings["gridYName1"]);
                    break;
                case 1:
                    setSelectedCauseProb(settings["gridYName1"]);
                    break;
                case 2:
                    setSelectedCauseProb(settings["gridYName2"]);
                    break;
                case 3:
                    setSelectedCauseProb(settings["gridYName3"]); 
                    break;
                case 4:
                    setSelectedCauseProb(settings["gridYName4"]);
                    break;
                case 5:
                    setSelectedCauseProb(settings["gridYName5"]);
                    break;
                case 6:
                    setSelectedCauseProb(settings["gridYName6"]);
                    break;
            }
        }
    }

    const data = {
            labels:labels,
            datasets: [
                {
                    label: label_top5,
                    backgroundColor: colors,
                    borderWidth: 1,
                    data: topCauses.map(item => item["probability"]),
                    objects:topCauses
                },
            ],
        };
      
        const options = {
            onClick: function(event, elements) {
                if (elements.length > 0) {
                    handleChartClick(event, elements);
            }},
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                stacked: true
                }],
                yAxes: [{
                stacked: true
                }],
            },
            tooltips: {
                mode: 'label',
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return data['datasets'][0]['data'][tooltipItem['index']];
                        }
                    }
            }
    }

    async function processData(res) {
        setAllCauses(res[0].data);
        setAllOccurences(res[2].data);

        let allCauses = res[0].data;
        let treatments = res[1].data;
        let tempCauses = [{}];

        allCauses.map((item,key) =>{
            let probability = (item["probability"] - treatments.filter(
                treatment => treatment["cause"] == item["_id"]
            ).map(
                treatment => treatment["probabilityreduction"]
            ).reduce(
                (total, number) => total + number, 0
            ))

            tempCauses.push({
                ...item,
                id: item["_id"],
                // name: item["name"],
                probability : probability > 0 ? probability : 0,
            });

            let topFive =_.orderBy(tempCauses, ['probability'],['desc']).slice(1,6);
            let tmpLabels = Array<String>();
            let tmpColors = Array<String>();

            if(topFive != undefined) {
                topFive.map((item,key) =>{
                    tmpLabels.push(item["name"] as String);
                    if(item["probability"] >= 0 && item["probability"] <= 25) {
                        tmpColors.push("#5B8266")
                    } else if(item["probability"] >= 26 && item["probability"] <= 50){
                        tmpColors.push("#FACD75")
                    } else if(item["probability"] >= 51 && item["probability"] <= 75){
                        tmpColors.push("#D8775A")
                    } else if(item["probability"] >= 76 && item["probability"] <= 100){
                        tmpColors.push("#993742")
                    }                    
                })
            }
            setLabels(tmpLabels);
            setTopCauses(topFive);
            setColors(tmpColors);
        })
    }
    
    useEffect(() => {
        Promise.all([
            props.requestData("causes"),
            props.requestData("treatments"),
            props.requestData("occurences")
        ]).then(async (data) => {
            await processData(data);
            setLoading(false);
        });
    }, []);

    return (
        <Card>
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                <h4 className="header-title">{t("dashboard.cards.topvulnerabilities_name")}</h4>
                <p>{t("dashboard.cards.topvulnerabilities_description")}</p>
                <hr />
                <div>
                    <HorizontalBar
                        data={data}
                        options={options}
                    />                
                </div>
                    <Modal show={showCauseModal} onHide={handleCloseCauseModal} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("bowtie.cause")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="treatments">
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{t("bowtie.name")}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control readOnly disabled value={selectedCause != undefined ? selectedCause["name"] : ""}/>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{t("bowtie.event_new_name")}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control readOnly disabled value={selectedOccurence != undefined ? selectedOccurence["name"] : ""}/>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{t("bowtie.causes_probability")}</InputGroup.Text>
                                </InputGroup.Prepend>
                                    <Form.Control readOnly disabled value={selectedCauseProb != undefined ? selectedCauseProb: ""}/>
                            </InputGroup>
                            <hr />
                            {
                                selectedCause["_id"] != undefined ? 
                                <div>
                                    <PageTable
                                        dataUrl={generateUrl("/api/cra/treatments/getAllPaged/bycause/") + selectedCause["_id"]}
                                        options={{
                                                showFields: [
                                                    { label: treatmentName, field: "name"},
                                                    { label: treatmentResponsible, field: "responsible"},
                                                    { label: probabilityReduction, field: "probabilityreduction", override: dataLine => dataLine["probabilityreduction"] != undefined ? 
                                                       dataLine["probabilityreduction"] == 0 ? t("treatment.label_none"):
                                                       dataLine["probabilityreduction"] > 0 && dataLine["probabilityreduction"] < 45 ? t("treatment.label_low") : 
                                                       dataLine["probabilityreduction"] >= 45 && dataLine["probabilityreduction"] < 70 ? t("treatment.label_middel") : 
                                                       dataLine["probabilityreduction"] >= 70 && dataLine["probabilityreduction"] < 95 ? t("treatment.label_high") : 
                                                       dataLine["probabilityreduction"] >= 95 ? t("treatment.label_extremely_high") : ""
                                                        : ""
                                                    },
                                                    { label: treatmentCost, field: "cost"},
                                                    { label: treatmentStatus, field: "status"},
                                                    { label: treatmentTime, field: "dateReadable"}
                                                ],
                                                pageSize: 15
                                            }}
                                            // dataBefore={(dataLine) => <p>remember this thing</p>}
                                            // dataAfter={(dataLine) => <Button onClick ={(evt) => handleShowDeleteTreatmentModal(dataLine,evt)}> Delete </Button>}
                                            // onLineClick= {handleShowEditTreatmentModal}
                                            hidePagination= {false}
                                            hidePagesize= {false}
                                            hideFilter= {false}
                                            updateTable={updateTable}
                                            hover={true} 
                                    />
                                </div> : null  
                            }                            
                        </Modal.Body>
                        <Modal.Footer/>
                    </Modal>
            </Card.Body>
        </Card>
    );
}

export default TopVulnerabilities;
