import SystemDescriptionTemplateSystem from "./Types/SystemDescriptionTemplateSystem";
import SystemDescriptionTemplateOrgros from "./Types/SystemDescriptionTemplateOrgros";
import SystemDescriptionTemplateOther from "./Types/SystemDescriptionTemplateOther";

async function SystemDescriptionTemplate(
  t,
  type,
  axios,
  auth,
  itemId
): Promise<Array<any>> {
  switch (type) {
    case "orgros":
      return await SystemDescriptionTemplateOrgros.systemDescriptionTemplateOrgros(
        t,
        axios,
        auth,
        itemId
      );
    case "other":
      return await SystemDescriptionTemplateOther.systemDescriptionTemplateOther(
        t,
        axios,
        auth,
        itemId
      );
    default:
      return await SystemDescriptionTemplateSystem.systemDescriptionTemplateSystem(
        t,
        axios,
        auth,
        itemId
      );
  }
}

function SaveSystemDescriptionTemplate(
  t,
  axios,
  auth,
  type,
  wizardData,
  system
) {
  switch (type) {
    case "orgros":
      return SystemDescriptionTemplateOrgros.save(
        axios,
        auth,
        wizardData,
        system
      );
    case "other":
      return SystemDescriptionTemplateOther.save(
        t,
        axios,
        auth,
        wizardData,
        system
      );
    default:
      return SystemDescriptionTemplateSystem.save(
        t,
        axios,
        auth,
        wizardData,
        system
      );
  }
}

export default {
  getSystemDescriptionTemplate: SystemDescriptionTemplate,
  save: SaveSystemDescriptionTemplate,
};
