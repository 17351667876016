import React, { useEffect, useState } from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

function PersonSelector(props) {

    const handleChange = (value) => {
        if (props.onChange != undefined) {
            props.onChange(value);
        }
    }

    useEffect(() => {
        
    }, [props.options.users]);

    return(
        <div className="personselector">
            {props.value != undefined ?
                <div>
                    {props.value.map((value, key) =>
                        <div className="row" key={key}>
                            <div className="col col-md-4" style={{ marginBottom: "0px" }}>
                            <InputGroup className="mb-3 autocomplete">
                                <Typeahead
                                    id={"autocompleter-" + props.name + key}
                                    disabled={props.disabled}
                                    onInputChange={(evt) => {
                                        let temp = props.value;
                                        temp[key].name = evt;
                                        handleChange(temp);
                                    }}
                                    onChange={items => {
                                        if (items.length > 0) {
                                            let temp = props.value;
                                            temp[key].name = items[0]["label"];
                                            temp[key].userrole = items[0]["userrole"];
                                            temp[key].userid = items[0]["userid"];
                                            temp[key].organizationid = items[0]["organizationid"];
                                            temp[key].organizationname = (props.options?.organizations.find( i => i._id == items[0]["organizationid"] )?.name ?? "");
                                            handleChange(temp);
                                        }
                                    }}
                                    defaultInputValue={value.name}
                                    minLength={props.options != undefined && props.options.minlength != undefined ? props.options.minlength : 2}
                                    emptyLabel={props.options != undefined && props.options.noresults != undefined ? props.options.noresults : ""}
                                    options={props.options != undefined ? props.options.users.map((i, key) => { return { id: key, label: i["name"], userid: i["_id"], organizationid: i["unit"][0], userrole: i["role"][0] } }) : []}
                                    placeholder={props.title}
                                />
                            </InputGroup>
                            </div>
                            <div className="col col-md-3" style={{ marginBottom: "0px" }}>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        disabled={props.disabled}
                                        onChange={(evt) => {
                                            let temp = props.value;
                                            temp[key].userrole = evt.target.value;
                                            handleChange(temp);
                                        }}
                                        placeholder={props.options?.roleTitle ?? ""}
                                        value={value.userrole}
                                    />
                                </InputGroup>
                            </div>
                            <div className="col col-md-4" style={{ marginBottom: "0px" }}>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        disabled={props.disabled}
                                        onChange={(evt) => {
                                            let temp = props.value;
                                            temp[key].organizationname = evt.target.value;
                                            handleChange(temp);
                                        }}
                                        placeholder={props.options?.organizationTitle ?? ""}
                                        value={value.organizationname}
                                    />
                                </InputGroup>
                            </div>
                            <div className="col col-md-1" style={{ marginBottom: "0px" }}>
                            <Button
                                disabled={props.disabled}
                                onClick={ () => {
                                    let temp = props.value;
                                    temp.splice(key, 1);
                                    handleChange(temp);
                                } }
                            >{props.options?.deleteButtonText ?? ""}</Button>
                            </div>
                        </div>
                    )}
                </div>
            : null}
            <Button
                disabled={props.disabled}
                onClick={ () => {
                    handleChange([...(props.value != undefined ? props.value : []), {name: "", userrole: "", organizationname: "", userid: "", organizationid: ""}]);
                } }
                className="align-left"
            >{props.options?.addButtonText ?? ""}</Button>
        </div>
    );
}

export default PersonSelector;