/*async function fetchData(axios, auth): Promise<any> {
    let results = {
        users: [],
        organizations: []
    };

    await axios.all([
        axios.single({
            method: "get",
            url: "https://test.diri.ai/api/users/",
            responseType: "json",
            headers: {'x-access-token': auth.user["token"]}
        }),
        axios.single({
			method: "get",
			url: "https://test.diri.ai/api/orgElements/",
			responseType: "json",
			headers: {'x-access-token': auth.user["token"]}
      })
    ])
    .then(axios.spread(async (...res) => {
        results.users = res[0].data;
        results.organizations = res[1].data;
    }));

    return results;
}*/

let localStorageUser = localStorage.getItem("user");
let settings = {};
if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
}

function getSystemvalueOptionsByKIT(kit){
    let options = Array();
    if(settings["systemvalue" + kit + "Num"] != undefined){
        for (let i = 1; i <= parseInt(settings["systemvalue" + kit + "Num"]); i++) {
            options.push({
                label: settings["systemvalue" + kit + "Level" + i],
                value: i
            });
        }
        return options;
    }
}

async function EconomyTemplate(t, axios, auth) {
    if(localStorageUser == null){
        localStorageUser = localStorage.getItem("user");
    }
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    //let data = await fetchData(axios, auth);

    let tempWizardData: Array<any> = [
        {
            fields: [
                {
                    type: "text_title",
                    title: t("wizard_title.assets.financialdata.title")
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.financialdata.desc")
                },
                {
                    id: 3,
                    type: "bool",
                    title: t("wizard_title.assets.financialdata.question") + " *",
                    value: "",
                    reference: "createitembool"
                },
                {
                    type: "textarea",
                    title: t("wizard_title.assets.reason_not_relevant"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 3,
                                value: false,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "reasoning_not"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.reason_not_relevant_desc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 3,
                                value: false,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "selectmulti",
                    title: t("wizard_title.assets.financialdata.question2"),
                    options: {
                        typeLoad: "Manual",
                        choices: [
                            {
                                label: t("wizard_title.assets.financialdata.assets.budget"),
                                value: "Budsjett"
                            },
                            {
                                label: t("wizard_title.assets.financialdata.assets.stockmarket"),
                                value: "Børssensitiv informasjon"
                            },
                            {
                                label: t("wizard_title.assets.financialdata.assets.invoice"),
                                value: "Faktureringsinformasjon"
                            },
                            {
                                label: t("wizard_title.assets.financialdata.assets.customer"),
                                value: "Kunderegister"
                            },
                            {
                                label: t("wizard_title.assets.financialdata.assets.accounting"),
                                value: "Regnskap"
                            },
                            {
                                label: t("wizard_title.assets.financialdata.assets.payout"),
                                value: "Utbetalingsinformasjon"
                            },
                            {
                                label: t("wizard_title.assets.financialdata.assets.analysis"),
                                value: "Økonomiske analyser"
                            },
                            {
                                label: t("wizard_title.assets.financialdata.assets.other"),
                                value: "Annet"
                            }
                        ],
                        filters: [
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "financial_data"
                },
                {
                    type: "textarea",
                    title: t("wizard_title.assets.financialdata.desc2"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "description"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.financialdata.deschelptext"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "select",
                    title: t("wizard_title.assets.financialdata.conf"),
                    options: {
                        typeLoad: "Manual",
                        choices: getSystemvalueOptionsByKIT("K"),
                        filters: [
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "confidentiality"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.financialdata.confdesc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "select",
                    title: t("wizard_title.assets.financialdata.integrity"),
                    options: {
                        typeLoad: "Manual",
                        choices: getSystemvalueOptionsByKIT("I"),
                        filters: [
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "integrity"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.financialdata.integritydesc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "textarea",
                    title: t("wizard_title.assets.financialdata.reason"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "reasoning"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.financialdata.reasondesc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 3,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                }
            ]
        }
    ]

    return tempWizardData;
}

export default EconomyTemplate;
