import React, { useState, useEffect, useRef } from "react";

import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";

import { generateUrl } from "../../../../../config";

function Bowtie(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;

  let iframeRef = useRef(null);

  const [systemid, setSystemid] = useState("");

  const handleCreateSystem = () => {
    axios
      .post(
        generateUrl("/api/cra/systems/"),
        {
          name: "Bowtie",
          detached: true,
          tags: [],
          text: "",
          org: auth.user["userdata"]["unit"][0],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        setSystemid(response.data["_id"]);
        if (props.onChange != undefined) {
          props.onChange(response.data["_id"]);
        }
      });
  };

  useEffect(() => {
    if (
      (props.value != undefined && props.value != ""
        ? props.value
        : props.defaultValue != undefined
        ? props.defaultValue
        : "") == ""
    ) {
      handleCreateSystem();
    } else {
      setSystemid(
        props.value != undefined && props.value != ""
          ? props.value
          : props.defaultValue != undefined
          ? props.defaultValue
          : ""
      );
    }
  });

  return (
    <iframe
      title={"framefor|" + systemid}
      style={
        props.disabled != undefined && props.disabled == true
          ? { pointerEvents: "none" }
          : undefined
      }
      ref={iframeRef}
      src={systemid != "" ? "/system/bowtie/" + systemid + "?nomenu=true" : ""}
    ></iframe>
  );
}

export default Bowtie;
