import * as React from "react";

import { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import {
  Modal,
  InputGroup,
  Form,
  Button,
  Accordion,
  Card,
} from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { Slider } from "@material-ui/core";

import { useAxios } from "../../../../AxiosHandler";
import { useAuth } from "../../../../Auth";

import Field from "../../Wizard/Field/Field";
import {
  treatmentTypes,
  treatmentClasses,
} from "../../../../Assets/Constants/Constants";
import { useDynamicLanguage } from "../../../../DynamicLanguageProvider";

import { generateUrl } from "../../../../config";

import "react-toastify/dist/ReactToastify.css";
import "./TreatmentEditorModal.scss";

function TreatmentEditorModal(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();
  const td = useDynamicLanguage().td;

  const [users, setUsers] = useState<Array<any>>([]);

  const [editTreatment, setEditTreatment] = useState<any>();
  const [connections, setConnections] = useState<any>();
  const [consequenceTypes, setConsequenceTypes] = useState<any>([]);
  const [showAllConnections, setShowAllConnections] = useState(false);
  const [showPublicRow, setShowPublicRow] = useState(false);
  const [shareWithUser, setShareWithUser] = useState("");
  const [shareWithUsersOptions, setShareWithUsersOptions] = useState<
    Array<any>
  >([]);
  const [autocompleteKey, setAutocompleteKey] = useState(0);

  const [
    showSharePublicWarningModal,
    setShowSharePublicWarningModal,
  ] = useState(false);
  const [sharePublicConfirmed, setSharePublicConfirmed] = useState(false);
  const [treatmentIsPublic, setTreatmentIsPublic] = useState(false);

  const [
    showTreatmentAccessControlModal,
    setShowTreatmentAccessControlModal,
  ] = useState(false);

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  let userdata = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
    userdata = JSON.parse(localStorageUser)["userdata"];
  }

  const labelNone = t("treatment.label_none");
  const labelLow = t("treatment.label_low");
  const labelMiddel = t("treatment.label_middel");
  const labelHigh = t("treatment.label_high");
  const labelExtremelyHigh = t("treatment.label_extremely_high");

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  function handleShowSharePublicWarningModal() {
    if (editTreatment["name"] == "") {
      notifyWarning(t("bowtie.toast_empty_name"));
      return undefined;
    }
    setShowSharePublicWarningModal(true);
  }

  const setEditCustomTextfields = (value, key) => {
    let tempCustomefields = editTreatment["customfields"];

    if (tempCustomefields != undefined) {
      if (tempCustomefields.find((item) => item["id"] == key) == undefined) {
        tempCustomefields.push({
          id: key,
          value: value,
        });
      } else {
        tempCustomefields.find((item) => item["id"] == key)["value"] = value;
      }
    } else {
      tempCustomefields = [
        {
          id: key,
          value: value,
        },
      ];
    }

    setEditTreatment((editTreatment) => ({
      ...editTreatment,
      customfields: JSON.parse(JSON.stringify(tempCustomefields)),
    }));
  };

  const handleEditTreatment = () => {
    if (editTreatment["name"] == "") {
      notifyWarning(t("bowtie.toast_empty_name"));
      return undefined;
    }
    axios
      .put(
        generateUrl("/api/cra/treatments/") + editTreatment["_id"],
        {
          name: editTreatment["name"],
          description: editTreatment["description"],
          type: editTreatment["type"],
          class: editTreatment["class"],
          customfields: editTreatment["customfields"],
          responsible: editTreatment["responsible"],
          occurence: editTreatment["occurence"],
          status: editTreatment["status"],
          cost: editTreatment["cost"],
          onetimecost: editTreatment["onetimecost"],
          documentationlink: editTreatment["documentationlink"],
          probabilityreduction: editTreatment["probabilityreduction"],
          duetime: editTreatment["duetime"],
          accessRules: editTreatment["accessRules"],
          owner: editTreatment["owner"],
          tags: [],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        if (props.onSave != undefined) props.onSave(res.data);
        setEditTreatment(undefined);
        setUsers([]);
        notifySuccess(t("treatments.toast.treatment_edited"));
        setConnections(undefined);
        setShowPublicRow(false);
        setShowSharePublicWarningModal(false);
        setSharePublicConfirmed(false);
        setTreatmentIsPublic(false);
      });
  };

  const fetchUsers = () => {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/users"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "post",
          url: generateUrl("/api/users/names"),
          data:
            editTreatment != undefined &&
            editTreatment["responsible"].length == 24
              ? [editTreatment["responsible"]]
              : [],
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          let users =
            editTreatment != undefined ? [...res[0].data, ...res[1].data] : [];
          setUsers(users);
        })
      );
  };

  const fetchTreatment = () => {
    if (props.treatmentId != undefined) {
      axios
        .all([
          axios.single({
            method: "get",
            url: generateUrl("/api/cra/treatments/") + props.treatmentId,
            responseType: "json",
            headers: { "x-access-token": auth.user["token"] },
          }),
          axios.single({
            method: "get",
            url:
              generateUrl("/api/cra/treatments/getconnections/") +
              props.treatmentId,
            responseType: "json",
            headers: { "x-access-token": auth.user["token"] },
          }),
          axios.single({
            method: "get",
            url:
              props.systemid != undefined
                ? generateUrl("/api/cra/consequencetypes/bysystem/") +
                  props.systemid
                : generateUrl("/api/cra/consequencetypes/"),
            responseType: "json",
            headers: { "x-access-token": auth.user["token"] },
          }),
          axios.single({
            method: "get",
            url: generateUrl("/api/cra/systems/"),
            responseType: "json",
            headers: { "x-access-token": auth.user["token"] },
          }),
        ])
        .then(
          axios.spread(async (...res) => {
            if (res[0].data != undefined) {
              res[3].data = res[3].data.filter(
                (system) => system.systemType[0] == "orgros"
              );
              setSystemSelectOptions(
                res[3].data.map((system) => {
                  return { label: system.nameReadable, value: system._id };
                })
              );
              let treatment = res[0].data;
              treatment["parents"] = [];
              treatment["cause"].forEach((element) => {
                treatment["parents"].push(element);
              });
              treatment["consequence"].forEach((element) => {
                treatment["parents"].push(element);
              });
              setConnections(res[1].data);
              if (props.systemid != undefined) {
                setConsequenceTypes(res[2].data);
              }

              await axios
                .all([
                  axios.single({
                    method: "get",
                    url: generateUrl("/api/users"),
                    responseType: "json",
                    headers: { "x-access-token": auth.user["token"] },
                  }),
                  axios.single({
                    method: "post",
                    url: generateUrl("/api/users/names"),
                    data:
                      treatment != undefined &&
                      treatment["responsible"] != undefined &&
                      treatment["responsible"].length == 24
                        ? [treatment["responsible"]]
                        : [],
                    responseType: "json",
                    headers: { "x-access-token": auth.user["token"] },
                  }),
                ])
                .then(
                  axios.spread((...res) => {
                    let users =
                      treatment != undefined
                        ? [...res[0].data, ...res[1].data]
                        : [];

                    if (treatment.accessRules == undefined) {
                      treatment.accessRules = {
                        shared: [
                          /* {
                                    type: "public",
                                    actions: [] as any
                                }, */
                          {
                            type: "organization",
                            actions: [] as any,
                          },
                        ],
                        users: [],
                      };
                    }
                    /* if(treatment.accessRules.shared.find(i => i.type == "public").actions.includes("read")){
                            setShowPublicRow(true);
                            setTreatmentIsPublic(true);
                        } */
                    setUsers(users);
                    setShareWithUsersOptions(
                      res[0].data.map((d) => {
                        return { label: d.email, value: d.email };
                      })
                    );
                    setEditTreatment(treatment);
                    //setConnections(res[1].data);
                  })
                );
            }
          })
        )
        .catch((err) => console.log(err));
    }
  };

  function handleChangeAction(evt, action, id?: String, type?: String) {
    if (id != undefined) {
      if (evt) {
        setEditTreatment({
          ...editTreatment,
          accessRules: {
            ...editTreatment.accessRules,
            users: editTreatment.accessRules.users.map((i) => {
              if (i.id == id) {
                if (!i.actions.includes(action)) {
                  i.actions.push(action);
                }
                return i;
              } else {
                return i;
              }
            }),
          },
        });
      } else {
        setEditTreatment({
          ...editTreatment,
          accessRules: {
            ...editTreatment.accessRules,
            users: editTreatment.accessRules.users.map((i) => {
              if (i.id == id) {
                i.actions = i.actions.filter((a) => a != action);
                return i;
              } else {
                return i;
              }
            }),
          },
        });
      }
    } else if (type != undefined) {
      if (evt) {
        setEditTreatment({
          ...editTreatment,
          accessRules: {
            ...editTreatment.accessRules,
            shared: editTreatment.accessRules.shared.map((i) => {
              if (i.type == type) {
                if (!i.actions.includes(action)) {
                  i.actions.push(action);
                }
                return i;
              } else {
                return i;
              }
            }),
          },
        });
      } else {
        setEditTreatment({
          ...editTreatment,
          accessRules: {
            ...editTreatment.accessRules,
            shared: editTreatment.accessRules.shared.map((i) => {
              if (i.type == type) {
                i.actions = i.actions.filter((a) => a != action);
                return i;
              } else {
                return i;
              }
            }),
          },
        });
      }
    }
  }

  async function addSharedWithUser() {
    let pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    if (!pattern.test(shareWithUser)) {
      notifyWarning(t("treatment.toast_invite_invalid_email"));
      return undefined;
    }
    await axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/users/") + shareWithUser,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          if (
            res[0].data.id != undefined &&
            !editTreatment.accessRules.users
              .map((u) => u.id)
              .includes(res[0].data.id.toString())
          ) {
            editTreatment.accessRules.users.push({
              ...res[0].data,
              actions: [],
            });
          }
          setShareWithUser("");
          setAutocompleteKey(autocompleteKey + 1);
        })
      );
  }

  function isChecked(action, id?: String, type?: String) {
    if (id != undefined) {
      return (
        editTreatment?.accessRules?.users
          .find((s) => s.id == id)
          ?.actions.includes(action) ?? false
      );
    } else if (type != undefined) {
      return (
        editTreatment?.accessRules?.shared
          .find((s) => s.type == type)
          ?.actions.includes(action) ?? false
      );
    }
    return false;
  }

  useEffect(() => {
    fetchTreatment();
    //fetchUsers();
  }, [props.treatmentId, props.show]);

  const [systemSelectOptions, setSystemSelectOptions] = useState([]);

  return (
    <div>
      {editTreatment != undefined && users != undefined ? (
        <Modal
          show={props.show}
          onHide={() => {
            if (props.onHide != undefined) props.onHide();
            setEditTreatment(undefined);
            setUsers([]);
            setConnections(undefined);
            setShowPublicRow(false);
            setTreatmentIsPublic(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t("treatments.modal_treatment_edit.title")}
            </Modal.Title>
            <div>
              <span
                style={{ cursor: "pointer", padding: "0 20px" }}
                onClick={() => setShowTreatmentAccessControlModal(true)}
              >
                <i className="dripicons-user" />
                <i
                  style={{ fontSize: "10px", position: "absolute" }}
                  className="dripicons-lock"
                />
              </span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                {connections != undefined && connections.length > 1 ? (
                  <div>
                    <h5 style={{ color: "red" }}>
                      {t(
                        "treatments.modal_treatment_edit.multiple_parents_warning"
                      )}
                    </h5>
                    <p>
                      {t(
                        "treatments.modal_treatment_edit.multiple_parents_explaination"
                      )}
                    </p>
                    <hr />
                  </div>
                ) : null}
                {connections != undefined && connections.length == 1 ? (
                  <h5>
                    {t(
                      "treatments.modal_treatment_edit.single_parent_connectedto"
                    )}
                  </h5>
                ) : null}

                <Accordion className="treatment-editor-modal-accordion">
                  {connections != undefined && connections.length > 0
                    ? connections
                        .filter((item, idx) =>
                          !showAllConnections ? idx < 30 : idx >= 0
                        )
                        .map((system, key) => {
                          return (
                            <Card key={key}>
                              <Accordion.Toggle
                                className="clickable"
                                eventKey={key.toString()}
                                as={Card.Header}
                              >
                                {system?.systemReadable == "deleted"
                                  ? t(
                                      "treatments.modal_treatment_edit.deleted_system"
                                    )
                                  : system?.systemReadable ??
                                    t(
                                      "treatments.modal_treatment_edit.no_system_access"
                                    )}{" "}
                                <span>
                                  (
                                  {system.causes.length +
                                    system.consequences.length}
                                  )
                                </span>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={key.toString()}>
                                <div>
                                  <div>
                                    {system.causes.length != 0 ? (
                                      <h6>
                                        {t(
                                          "treatments.modal_treatment_edit.causes"
                                        )}
                                      </h6>
                                    ) : null}
                                    {system.causes != undefined
                                      ? system.causes.map((cause, key) => {
                                          return (
                                            <p key={key}>
                                              {cause?.name ??
                                                t(
                                                  "treatments.modal_treatment_edit.no_cause_access"
                                                )}
                                            </p>
                                          );
                                        })
                                      : null}
                                  </div>
                                  <div>
                                    {system.consequences.length != 0 ? (
                                      <h6>
                                        {t(
                                          "treatments.modal_treatment_edit.consequences"
                                        )}
                                      </h6>
                                    ) : null}
                                    {system.consequences != undefined
                                      ? system.consequences.map(
                                          (consequence, key) => {
                                            return (
                                              <p key={key}>
                                                {consequence.name != undefined
                                                  ? consequence.name != ""
                                                    ? consequence.name
                                                    : td(
                                                        consequenceTypes.filter(
                                                          (c) =>
                                                            c._id.toString() ==
                                                            consequence.category
                                                        )[0]?.name
                                                      ) ?? ""
                                                  : t(
                                                      "treatments.modal_treatment_edit.no_consequence_access"
                                                    )}
                                              </p>
                                            );
                                          }
                                        )
                                      : null}
                                  </div>
                                  {system.consequences.length == 0 &&
                                  system.causes.length == 0 ? (
                                    <p>
                                      {t(
                                        "treatments.modal_treatment_edit.no_connected_cause_consequence"
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              </Accordion.Collapse>
                            </Card>
                          );
                        })
                    : null}
                </Accordion>
                {connections.length >= 30 ? (
                  <div>
                    <Button
                      onClick={() => {
                        showAllConnections == false
                          ? setShowAllConnections(true)
                          : setShowAllConnections(false);
                      }}
                    >
                      {showAllConnections == false
                        ? t(
                            "treatments.modal_treatment_edit.showallconnections"
                          )
                        : t("treatments.modal_treatment_edit.hideconnections")}
                    </Button>

                    <h5>
                      {t(
                        "treatments.modal_treatment_edit.multiple_parents_connectedto"
                      )}
                    </h5>
                    <Accordion className="treatment-editor-modal-accordion">
                      {connections != undefined && connections.length > 0
                        ? connections
                            .filter((item, idx) =>
                              !showAllConnections ? idx < 30 : idx >= 0
                            )
                            .map((system, key) => {
                              return (
                                <Card>
                                  <Accordion.Toggle
                                    className="clickable"
                                    eventKey={key.toString()}
                                    as={Card.Header}
                                  >
                                    {system?.systemReadable ??
                                      t(
                                        "treatments.modal_treatment_edit.no_system_access"
                                      )}{" "}
                                    <span>
                                      (
                                      {system.causes.length +
                                        system.consequences.length}
                                      )
                                    </span>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={key.toString()}>
                                    <div>
                                      <div>
                                        {system.causes.length != 0 ? (
                                          <h6>
                                            {t(
                                              "treatments.modal_treatment_edit.causes"
                                            )}
                                          </h6>
                                        ) : null}
                                        {system.causes != undefined
                                          ? system.causes.map((cause) => {
                                              return (
                                                <p>
                                                  {cause?.name ??
                                                    t(
                                                      "treatments.modal_treatment_edit.no_cause_access"
                                                    )}
                                                </p>
                                              );
                                            })
                                          : null}
                                      </div>
                                      <div>
                                        {system.consequences.length != 0 ? (
                                          <h6>
                                            {t(
                                              "treatments.modal_treatment_edit.consequences"
                                            )}
                                          </h6>
                                        ) : null}
                                        {system.consequences != undefined
                                          ? system.consequences.map(
                                              (consequence) => {
                                                return (
                                                  <p>
                                                    {consequence.name !=
                                                    undefined
                                                      ? consequence.name != ""
                                                        ? consequence.name
                                                        : td(
                                                            consequenceTypes.filter(
                                                              (c) =>
                                                                c._id.toString() ==
                                                                consequence.category
                                                            )[0]?.name
                                                          ) ?? ""
                                                      : t(
                                                          "treatments.modal_treatment_edit.no_consequence_access"
                                                        )}
                                                  </p>
                                                );
                                              }
                                            )
                                          : null}
                                      </div>
                                      {system.consequences.length == 0 &&
                                      system.causes.length == 0 ? (
                                        <p>
                                          {t(
                                            "treatments.modal_treatment_edit.no_connected_cause_consequence"
                                          )}
                                        </p>
                                      ) : null}
                                    </div>
                                  </Accordion.Collapse>
                                </Card>
                              );
                            })
                        : null}
                      {connections.length >= 30 ? (
                        <Button
                          onClick={() => {
                            showAllConnections == false
                              ? setShowAllConnections(true)
                              : setShowAllConnections(false);
                          }}
                        >
                          {showAllConnections == false
                            ? t(
                                "treatments.modal_treatment_edit.showallconnections"
                              )
                            : t(
                                "treatments.modal_treatment_edit.hideconnections"
                              )}
                        </Button>
                      ) : null}
                    </Accordion>
                    <hr />
                  </div>
                ) : null}
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{t("treatment.name")} *</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
                    disabled={!editTreatment.accesses.includes("edit")}
                    onChange={(evt) =>
                      setEditTreatment({
                        ...editTreatment,
                        name: evt.target.value,
                      })
                    }
                    value={
                      editTreatment["name"] != undefined
                        ? editTreatment["name"]
                        : ""
                    }
                  />
                </InputGroup>
                {/* {editTreatment["global"] == true ? 
                                    <Field
                                        title={t("treatment.choose_orgros") + " *"}
                                        type="select"
                                        value={editTreatment["system"]}
                                        options={{
                                            "choices": systemSelectOptions
                                        }}
                                        disabled
                                        //onChange={val => setEditTreatment({ ...editTreatment, system: val.value })}
                                    >
                                    </Field> 
                                : null} */}
                <Field
                  type="textarea"
                  title={t("treatment.description")}
                  value={
                    editTreatment["description"] != undefined
                      ? editTreatment["description"]
                      : ""
                  }
                  onChange={(val) =>
                    setEditTreatment({ ...editTreatment, description: val })
                  }
                  disabled={!editTreatment.accesses.includes("edit")}
                  //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
                ></Field>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{t("treatment.type")}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="select"
                    onChange={(evt) =>
                      setEditTreatment({
                        ...editTreatment,
                        type: (evt.target as HTMLSelectElement)
                          .selectedOptions[0].value,
                      })
                    }
                    value={editTreatment["type"]}
                    disabled={!editTreatment.accesses.includes("edit")}
                    //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
                  >
                    <option value="">{t("users.select")}</option>
                    {treatmentTypes.map((i, key) => (
                      <option key={key} value={i.value}>
                        {t(i.label)}
                      </option>
                    ))}
                  </Form.Control>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{t("treatment.class")}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="select"
                    onChange={(evt) =>
                      setEditTreatment({
                        ...editTreatment,
                        class: (evt.target as HTMLSelectElement)
                          .selectedOptions[0].value,
                      })
                    }
                    value={editTreatment["class"]}
                    disabled={!editTreatment.accesses.includes("edit")}
                    //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
                  >
                    <option value="">{t("users.select")}</option>
                    {treatmentClasses.map((i, classKey) => (
                      <option key={classKey} value={i.value}>
                        {t(i.label)}
                      </option>
                    ))}
                  </Form.Control>
                </InputGroup>
                <Field
                  type="input"
                  title={t("treatment.documentationlink")}
                  value={
                    editTreatment["documentationlink"] != undefined
                      ? editTreatment["documentationlink"]
                      : ""
                  }
                  onChange={(val) =>
                    setEditTreatment({
                      ...editTreatment,
                      documentationlink: val,
                    })
                  }
                  disabled={!editTreatment.accesses.includes("edit")}
                  //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
                ></Field>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      {t("treatment.responsible")}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  {users.length > 0 && props.show ? (
                    <Typeahead
                      key={editTreatment["_id"]}
                      id="edittreatmentresponsible"
                      onInputChange={(value) =>
                        setEditTreatment({
                          ...editTreatment,
                          responsible: value,
                        })
                      }
                      onChange={(items) => {
                        if (items.length > 0) {
                          if (
                            items[0]["id"] != undefined &&
                            items[0]["id"] != ""
                          ) {
                            setEditTreatment({
                              ...editTreatment,
                              responsible: items[0]["id"],
                            });
                          } else {
                            setEditTreatment({
                              ...editTreatment,
                              responsible: "",
                            });
                          }
                        } else {
                          setEditTreatment({
                            ...editTreatment,
                            responsible: "",
                          });
                        }
                      }}
                      minLength={2}
                      emptyLabel={t("treatment.no_results")}
                      defaultInputValue={
                        users.filter(
                          (user) => user["_id"] == editTreatment["responsible"]
                        ).length > 0
                          ? users.filter(
                              (user) =>
                                user["_id"] == editTreatment["responsible"]
                            )[0]["name"]
                          : editTreatment["responsible"]
                      }
                      options={users.map((user) => {
                        return { id: user["_id"], label: user["name"] };
                      })}
                      disabled={!editTreatment.accesses.includes("edit")}
                      //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
                    />
                  ) : null}
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{t("treatment.owner")}</InputGroup.Text>
                  </InputGroup.Prepend>
                  {users.length > 0 && props.show ? (
                    <Typeahead
                      disabled={
                        !editTreatment.accesses.includes(
                          "edit_treatment_owner_and_sharing"
                        )
                      }
                      key={editTreatment["_id"]}
                      id="edittreatmentowner"
                      onInputChange={(value) =>
                        setEditTreatment({ ...editTreatment, owner: value })
                      }
                      onChange={(items) => {
                        if (items.length > 0) {
                          if (
                            items[0]["id"] != undefined &&
                            items[0]["id"] != ""
                          ) {
                            setEditTreatment({
                              ...editTreatment,
                              owner: items[0]["id"],
                            });
                          } else {
                            setEditTreatment({ ...editTreatment, owner: "" });
                          }
                        } else {
                          setEditTreatment({ ...editTreatment, owner: "" });
                        }
                      }}
                      minLength={2}
                      emptyLabel={t("treatment.no_results")}
                      defaultInputValue={
                        users.filter(
                          (user) => user["_id"] == editTreatment["owner"]
                        ).length > 0
                          ? users.filter(
                              (user) => user["_id"] == editTreatment["owner"]
                            )[0]["name"]
                          : editTreatment["owner"]
                      }
                      options={users.map((user) => {
                        return { id: user["_id"], label: user["name"] };
                      })}
                    />
                  ) : null}
                </InputGroup>
                <Field
                  key={editTreatment["_id"]}
                  type="dateselector"
                  title={t("treatment.duedate")}
                  value={
                    editTreatment["duetime"] != undefined
                      ? editTreatment["duetime"]
                      : ""
                  }
                  onChange={(val) =>
                    setEditTreatment({ ...editTreatment, duetime: val })
                  }
                  showTimeSelect
                  disabled={!editTreatment.accesses.includes("edit")}
                  //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
                ></Field>
                <hr />
                <div>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {t("treatment.onetimecost")}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className="priceField">
                      {settings["currencySymbolBefore"] != undefined &&
                      settings["currencySymbolBefore"] != "" ? (
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            {settings["currencySymbolBefore"]}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                      ) : (
                        ""
                      )}
                      <Form.Control
                        disabled={!editTreatment.accesses.includes("edit")}
                        //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
                        type="number"
                        onChange={(evt) =>
                          setEditTreatment({
                            ...editTreatment,
                            onetimecost: evt.target.value,
                          })
                        }
                        value={
                          editTreatment["onetimecost"] != undefined
                            ? editTreatment["onetimecost"]
                            : ""
                        }
                      />
                      <InputGroup.Append>
                        {settings["currencySymbolAfter"] != undefined &&
                        settings["currencySymbolAfter"] != "" ? (
                          <InputGroup.Text>
                            {settings["currencySymbolAfter"]}
                          </InputGroup.Text>
                        ) : (
                          ""
                        )}
                      </InputGroup.Append>
                    </div>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>{t("treatment.cost")}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <div className="priceField">
                      {settings["currencySymbolBefore"] != undefined &&
                      settings["currencySymbolBefore"] != "" ? (
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            {settings["currencySymbolBefore"]}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                      ) : (
                        ""
                      )}
                      <Form.Control
                        disabled={!editTreatment.accesses.includes("edit")}
                        //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
                        type="number"
                        onChange={(evt) =>
                          setEditTreatment({
                            ...editTreatment,
                            cost: evt.target.value,
                          })
                        }
                        value={
                          editTreatment["cost"] != undefined
                            ? editTreatment["cost"]
                            : ""
                        }
                      />
                      <InputGroup.Append>
                        {settings["currencySymbolAfter"] != undefined &&
                        settings["currencySymbolAfter"] != "" ? (
                          <InputGroup.Text>
                            {settings["currencySymbolAfter"]}
                          </InputGroup.Text>
                        ) : (
                          ""
                        )}
                      </InputGroup.Append>
                    </div>
                  </InputGroup>
                </div>
                <hr />
                <InputGroup className="mb-3">
                  <p>{t("treatment.probability_reduction")}</p>
                  <Slider
                    onChange={(event, value) => {
                      setEditTreatment({
                        ...editTreatment,
                        probabilityreduction: Number(value),
                      });
                    }}
                    value={
                      editTreatment["probabilityreduction"] != undefined
                        ? Number(editTreatment["probabilityreduction"])
                        : 0
                    }
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="off"
                    step={1}
                    min={0}
                    max={100}
                    marks={[
                      {
                        value: 5,
                        label: labelNone,
                      },
                      {
                        value: 25,
                        label: labelLow,
                      },
                      {
                        value: 50,
                        label: labelMiddel,
                      },
                      {
                        value: 75,
                        label: labelHigh,
                      },
                      {
                        value: 95,
                        label: labelExtremelyHigh,
                      },
                    ]}
                    disabled={!editTreatment.accesses.includes("edit")}
                    //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{t("treatment.status")}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="select"
                    onChange={(evt) =>
                      setEditTreatment({
                        ...editTreatment,
                        status: (evt.target as HTMLSelectElement)
                          .selectedOptions[0].value,
                      })
                    }
                    value={editTreatment["status"]}
                    disabled={!editTreatment.accesses.includes("edit")}
                    //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
                  >
                    <option value="open">{t("treatment.open")}</option>
                    <option value="suggested">
                      {t("treatment.suggested")}
                    </option>
                    <option value="planned">{t("treatment.planned")}</option>
                    <option value="processing">
                      {t("treatment.processing")}
                    </option>
                    <option value="closed">{t("treatment.closed")}</option>
                  </Form.Control>
                </InputGroup>
                {settings["customfieldsTreatment"] != undefined &&
                settings["customfieldsTreatment"].length > 0
                  ? settings["customfieldsTreatment"].map((item, key) => {
                      switch (item.type) {
                        case "string":
                          return (
                            <InputGroup className="mb-3" key={key}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {td(item.key)}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control
                                onChange={(evt) =>
                                  setEditCustomTextfields(
                                    evt.target.value,
                                    item.key
                                  )
                                }
                                value={
                                  editTreatment["customfields"] != undefined
                                    ? editTreatment["customfields"].find(
                                        (field) => field["id"] == item.key
                                      ) != undefined
                                      ? editTreatment["customfields"].find(
                                          (field) => field["id"] == item.key
                                        )["value"]
                                      : ""
                                    : ""
                                }
                                disabled={
                                  !editTreatment.accesses.includes("edit")
                                }
                                //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
                              />
                            </InputGroup>
                          );

                        case "dropdown":
                          return (
                            <InputGroup className="mb-3" key={key}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {td(item.key)}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control
                                disabled={
                                  !editTreatment.accesses.includes("edit")
                                }
                                //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
                                as="select"
                                onChange={(evt) =>
                                  setEditCustomTextfields(
                                    evt.currentTarget.value,
                                    item.key
                                  )
                                }
                                value={
                                  editTreatment["customfields"] != undefined
                                    ? editTreatment["customfields"].find(
                                        (field) => field["id"] == item.key
                                      ) != undefined
                                      ? editTreatment["customfields"].find(
                                          (field) => field["id"] == item.key
                                        )["value"]
                                      : ""
                                    : ""
                                }
                              >
                                (<option value="">{t("users.select")}</option>)
                                {item["options"] != undefined
                                  ? item["options"].map((option, optionkey) => {
                                      return (
                                        <option key={optionkey} value={option}>
                                          {td(option)}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </Form.Control>
                            </InputGroup>
                          );
                        default:
                          return null;
                      }
                    })
                  : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel posleft"
              onClick={() => {
                if (props.onCancel != undefined) props.onCancel();
                setEditTreatment(undefined);
                setUsers([]);
                setConnections(undefined);
              }}
            >
              {t("treatments.modal_treatment_create.cancelbtn")}
            </Button>
            <Button
              disabled={
                !editTreatment.accesses.includes("edit") &&
                !editTreatment.accesses.includes(
                  "edit_treatment_owner_and_sharing"
                )
              }
              //disabled={editTreatment["global"] == true && userdata["_id"] != editTreatment["responsible"] && userdata["_id"] != editTreatment["creator"]}
              //onClick={(editTreatment.accessRules.shared.find(s => s.type == "public")?.actions.includes("read") ?? true) && !treatmentIsPublic ? handleShowSharePublicWarningModal : handleEditTreatment}>
              onClick={handleEditTreatment}
            >
              {t("treatments.modal_treatment_edit.savebtn")}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      {/* <Modal show={showSharePublicWarningModal} onHide={() => {setShowSharePublicWarningModal(false); setSharePublicConfirmed(false);}}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("treatment.confirm_save")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("treatment.share_public_warning")}</p>
                    <div className="widecheckbox">
                        <Field
                            type="checkbox"
                            title={t("treatment.confirm_share_public")}
                            value={sharePublicConfirmed}
                            onChange={val => setSharePublicConfirmed(val)}
                            disabled={false}
                        ></Field>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="cancel posleft" onClick={() => setShowSharePublicWarningModal(false)}>{t("treatment.cancel")}</Button>
                    <Button disabled={!sharePublicConfirmed} onClick={handleEditTreatment}>{t("bowtie.save")}</Button>
                </Modal.Footer>
            </Modal> */}
      <Modal show={showTreatmentAccessControlModal}>
        <Modal.Header>
          <Modal.Title>{t("treatment.access_control")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editTreatment != undefined && users != undefined ? (
            <div className={"accessControl objectAccessControlDiv"}>
              <div className={"row"}>
                <div className="col-md-5">
                  <p>{t("treatment.access_control")}</p>
                </div>
                {/* <div className="col-md-7">
                                    <FormGroup>
                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                <Grid item>{t("treatment.make_public")}</Grid>
                                                <Grid item>
                                                    <Switch
                                                        size="small"
                                                        disabled={!editTreatment.accesses.includes("edit_treatment_owner_and_sharing")}
                                                        checked={showPublicRow}
                                                        onChange={() => setShowPublicRow(!showPublicRow)}
                                                        color="primary"
                                                        className={"toggleswitch"}
                                                    />
                                                </Grid>
                                            </Grid>
                                    </FormGroup>
                                </div> */}
              </div>
              <div className="objectAccessControlTableParentDiv">
                <div
                  className={
                    !editTreatment.accesses.includes(
                      "edit_treatment_owner_and_sharing"
                    )
                      ? "disabled"
                      : ""
                  }
                ></div>
                <table className={"objectAccessControlTable "}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>{t("treatment.read")}</th>
                      <th>{t("treatment.write")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr className={"mainRow " + (!showPublicRow ? "hidden" : "")}>
                                            <td>{t("treatment.public_outside_org")}</td>
                                            <td>
                                                <Field
                                                    type="indeterminatecheckbox"
                                                    checked={isChecked("read", undefined, "public")}
                                                    onChange={(evt) => handleChangeAction(evt, "read", undefined, "public")}
                                                    disabled={!editTreatment.accesses.includes("edit_treatment_owner_and_sharing")}
                                                    parent={false}
                                                    title={""}
                                                    titlePlacement="start"
                                                ></Field>
                                            </td>
                                            <td></td>
                                        </tr> */}
                    <tr className="mainRow">
                      <td>{t("treatment.entire_org")}</td>
                      <td>
                        <Field
                          type="indeterminatecheckbox"
                          checked={isChecked("read", undefined, "organization")}
                          onChange={(evt) =>
                            handleChangeAction(
                              evt,
                              "read",
                              undefined,
                              "organization"
                            )
                          }
                          disabled={
                            !editTreatment.accesses.includes(
                              "edit_treatment_owner_and_sharing"
                            )
                          }
                          parent={false}
                          title={""}
                          titlePlacement="start"
                        ></Field>
                      </td>
                      <td>
                        <Field
                          type="indeterminatecheckbox"
                          checked={isChecked("edit", undefined, "organization")}
                          onChange={(evt) => {
                            handleChangeAction(
                              evt,
                              "edit",
                              undefined,
                              "organization"
                            );
                            handleChangeAction(
                              evt,
                              "read",
                              undefined,
                              "organization"
                            );
                          }}
                          disabled={
                            !editTreatment.accesses.includes(
                              "edit_treatment_owner_and_sharing"
                            )
                          }
                          parent={false}
                          title={""}
                          titlePlacement="start"
                        ></Field>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="objectAccessControlUsersDiv">
                {editTreatment.accessRules.users.length != 0 ? (
                  <div>
                    <p>{t("treatment.shared_with")}</p>
                    <div className="objectAccessControlTableParentDiv">
                      <div
                        className={
                          !editTreatment.accesses.includes(
                            "edit_treatment_owner_and_sharing"
                          )
                            ? "disabled"
                            : ""
                        }
                      ></div>

                      <table className={"objectAccessControlTable"}>
                        <thead>
                          <tr>
                            <th></th>
                            <th>{t("treatment.read")}</th>
                            <th>{t("treatment.write")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {editTreatment.accessRules.users.map((user, key) => {
                            return (
                              <tr key={key} className="mainRow">
                                <td>
                                  {user.name != "" ? (
                                    <p>
                                      {user.name}, <br />
                                      {user.email}
                                    </p>
                                  ) : (
                                    <p>{user.email}</p>
                                  )}
                                </td>
                                <td>
                                  <Field
                                    type="indeterminatecheckbox"
                                    checked={isChecked(
                                      "read",
                                      user.id,
                                      undefined
                                    )}
                                    onChange={(evt) =>
                                      handleChangeAction(
                                        evt,
                                        "read",
                                        user.id,
                                        undefined
                                      )
                                    }
                                    disabled={
                                      !editTreatment.accesses.includes(
                                        "edit_treatment_owner_and_sharing"
                                      )
                                    }
                                    parent={false}
                                    title={""}
                                    titlePlacement="start"
                                  ></Field>
                                </td>
                                <td>
                                  <Field
                                    type="indeterminatecheckbox"
                                    checked={isChecked(
                                      "edit",
                                      user.id,
                                      undefined
                                    )}
                                    onChange={(evt) => {
                                      handleChangeAction(
                                        evt,
                                        "edit",
                                        user.id,
                                        undefined
                                      );
                                      handleChangeAction(
                                        evt,
                                        "read",
                                        user.id,
                                        undefined
                                      );
                                    }}
                                    disabled={
                                      !editTreatment.accesses.includes(
                                        "edit_treatment_owner_and_sharing"
                                      )
                                    }
                                    parent={false}
                                    title={""}
                                    titlePlacement="start"
                                  ></Field>
                                </td>
                                {editTreatment.accesses.includes(
                                  "edit_treatment_owner_and_sharing"
                                ) ? (
                                  <td>
                                    <Button
                                      onClick={() =>
                                        setEditTreatment({
                                          ...editTreatment,
                                          accessRules: {
                                            ...editTreatment.accessRules,
                                            users: editTreatment.accessRules.users.filter(
                                              (u) => u.id != user.id
                                            ),
                                          },
                                        })
                                      }
                                    >
                                      Remove
                                    </Button>
                                  </td>
                                ) : null}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}
                <div
                  key={autocompleteKey}
                  className="objectAccessControlAddUsersDiv"
                >
                  {/* <Field
                                        type="input"
                                        value={shareWithUser != undefined ? shareWithUser : ""}
                                        title={t("treatment.share_with_user")}
                                        onChange={value => setShareWithUser(value)}
                                        disabled={!editTreatment.accesses.includes("edit_treatment_owner_and_sharing")}
                                        placeholder={t("treatment.enter_email")}
                                    ></Field> */}
                  <Field
                    type="autocomplete"
                    value={shareWithUser != undefined ? shareWithUser : ""}
                    title={t("treatment.share_with_user")}
                    onChange={(value) => setShareWithUser(value)}
                    disabled={
                      !editTreatment.accesses.includes(
                        "edit_treatment_owner_and_sharing"
                      )
                    }
                    options={{
                      list: shareWithUsersOptions,
                      minlength: 2,
                      noresults: "no matches",
                      placeholder: t("treatment.enter_email"),
                    }}
                  ></Field>
                  <Button
                    onClick={addSharedWithUser}
                    disabled={
                      !editTreatment.accesses.includes(
                        "edit_treatment_owner_and_sharing"
                      )
                    }
                  >
                    {t("treatment.share")}
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowTreatmentAccessControlModal(false)}>
            {editTreatment != undefined &&
            editTreatment.accesses.includes("edit_treatment_owner_and_sharing")
              ? t("treatment.confirm_access_control")
              : t("treatment.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TreatmentEditorModal;
