import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import { useAxios } from "../../../../../AxiosHandler";

import { useTranslate } from 'react-polyglot';
import './SystemsList.scss';
import { Card } from 'react-bootstrap';
import { useAuth } from "../../../../../Auth";
import { Doughnut } from 'react-chartjs-2';
import PageItems from '../../../../Shared/PageItems/PageItems';
import { generateUrl } from '../../../../../config';

const SystemsList = (props) => {
    const [riskData, setRiskData] = useState([{}]);

    const [loading, setLoading] = useState(true);

    const t = useTranslate();   
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();
    const history = useHistory();

    const labelLow = t("treatment.label_low");
    const labelMiddel = t("treatment.label_middel");
    const labelHigh = t("treatment.label_high");
    const labelExtremelyHigh = t("treatment.label_extremely_high");

    let selectOptions = [
        {
            "label": t("dashboard.cards.choice_orgros"),
            "value": "orgros"
        },
    ]
  
    function getPropsInObject(theObject, targetProp) {
        var result = [];

        if (theObject instanceof Array) {
            for (var i = 0; i < theObject.length; i++) {
                var arrayElem = theObject[i];
                if (arrayElem instanceof Object || arrayElem instanceof Array) {
                    result = result.concat(getPropsInObject(arrayElem, targetProp));
                }
            }
        } else {
            for (var prop in theObject) {
                var objProp = theObject[prop];
                if (prop == targetProp) {
                    return theObject[prop];
                }
                if (objProp instanceof Object || objProp instanceof Array) {
                    result = result.concat(getPropsInObject(objProp, targetProp));
                }
            }
        }
        return result;
    }

    function getSystemTypeText(systemtype) {
        let systemtypes: Array<string> = [
            "system",
            "orgros",
            "other"
        ];

        let pattern = /system/;

        if (systemtypes.map(i => i.toLowerCase()).includes(systemtype.toLowerCase())) {
            return t("systems.systems_types." + systemtype.toLowerCase());
        } else if(pattern.test(systemtype.toLowerCase())) {
            return t("systems.systems_types.system");
        } else {
            return systemtype;
        }
    }

    // Function checking if point (x, y) is above the curve (y = -x + maxvalue)
    function aboveCurve(x, y, maxvalue) {
        let curve = -x + maxvalue;
        return y >= curve;
    }

    async function processData(res) {
        let returns = {};
        let systemRiskItems = [{}];
        res[0].data.map(riskitem => {
            let systemRiskItem = systemRiskItems.find(item => item["id"] == riskitem.occurence.system);
            if (systemRiskItem != undefined) {
                systemRiskItem["riskitems"].push({
                    occurence: riskitem.occurence,
                    cause: riskitem.cause,
                    causeReduction: riskitem.causeReduction,
                    consequence: riskitem.consequence,
                    consequenceReduction: riskitem.consequenceReduction
                });
            } else {
                systemRiskItems.push({
                    id: riskitem.occurence.system,
                    riskitems: [{
                        occurence: riskitem.occurence,
                        cause: riskitem.cause,
                        causeReduction: riskitem.causeReduction,
                        consequence: riskitem.consequence,
                        consequenceReduction: riskitem.consequenceReduction
                    }]
    
                });
            }
            return null;
        });

        returns["systemRiskItems"] = systemRiskItems;
        setRiskData(returns["systemRiskItems"]);
        setLoading(false);
    }

    const renderSystemItem = (system) => {
        let tempSystemsDoughnutLow = 0;
        let tempSystemsDoughnutMedium = 0;
        let tempSystemsDoughnutHigh = 0;
        let tempSystemsDoughnutVeryHigh = 0;

        //system = system.filter(res => res.systemType.toString().toLowerCase() == "system" || !selectOptionsValues.includes(res.systemType.toString().toLowerCase()));
        if(riskData.find(item => item["id"] == system["_id"]) != undefined) {
            let filteredData = riskData.filter(item => item["id"] == system["_id"]);
            
            filteredData.forEach((riskitems) =>{
                let riskItems = getPropsInObject(riskitems,"riskitems")
                riskItems.forEach((riskitem, key) =>{
                    let causeProb = (riskitem["cause"]["probability"] * riskitem["causeReduction"].reduce((acc, val) => ((100 - val) / 100) * acc, 1));
                    let conseqProb = (riskitem["consequence"]["consequenceValue"] * riskitem["consequenceReduction"].reduce((acc, val) => ((100 - val) / 100) * acc, 1));

                    if (causeProb < 1) {causeProb = 1};
                    if (conseqProb < 1) {conseqProb = 1};

                    if (aboveCurve(conseqProb, causeProb, 0) && !aboveCurve(conseqProb, causeProb, 70)) {
                        tempSystemsDoughnutLow += 1;
                    } else if (aboveCurve(conseqProb, causeProb, 70) && !aboveCurve(conseqProb, causeProb, 110)) {
                        tempSystemsDoughnutMedium += 1;
                    } else if (aboveCurve(conseqProb, causeProb, 110) && !aboveCurve(conseqProb, causeProb, 150)) {
                        tempSystemsDoughnutHigh += 1;
                    } else if (aboveCurve(conseqProb, causeProb, 150)) {
                        tempSystemsDoughnutVeryHigh += 1;
                    }

                    // let score = causeProb + conseqProb;

                    // if (score >= 0 && score <= 50) {
                    //     tempSystemsDoughnutLow += 1;
                    // }
                    // if (score > 50 && score <= 100) {
                    //     tempSystemsDoughnutMedium += 1;
                    // }
                    // if (score > 100 && score <= 150) {
                    //     tempSystemsDoughnutHigh += 1;
                    // }
                    // if (score > 150 && score <= 200) {
                    //     tempSystemsDoughnutVeryHigh += 1;
                    // }
                })
            })
        }
    

        //if (system["private"] != true) {
            return(
                <div className={system["accessRule"] == "adminprivate" ? "adminprivate" : ""} onClick={(evt) => system["accessRule"] != "adminprivate" ? history.push("/system/" + system["_id"]) : null }>
                    <div className="systemitemtextarea">
                        <h3 className="systemitemtitle">{system.name}</h3>
                        <p className="systemitemlineone">{system.systemType != undefined && system.systemType[0] != undefined && system["accessRule"] != "adminprivate" ? getSystemTypeText(system.systemType[0]) : ""}</p>
                        <p className="systemitemlinetwo">{system["accessRule"] != "adminprivate" ? system.ownerPersonReadable : ""}</p>
                    </div>
                    {system["accessRule"] == "adminprivate" ?
                        <div>
                            <p>{t("dashboard.cards.systemslist_private")}</p>
                        </div>
                    : null}
                    <div className="systemitemrisk">
                        <div style={{"width": "120px"}}>
                            <Doughnut
                                redraw={false}
                                data={{
                                    datasets: [
                                        {
                                            data: [
                                                tempSystemsDoughnutLow, 
                                                tempSystemsDoughnutMedium, 
                                                tempSystemsDoughnutHigh,
                                                tempSystemsDoughnutVeryHigh
                                            ],
                                            backgroundColor: [
                                                "rgb(91, 130, 102, 1)",
                                                "rgb(250, 205, 117, 1)",
                                                "rgb(216, 119, 90, 1)",
                                                "rgb(153, 55, 66, 1)",
                                            ]
                                        }
                                    ],
                                    labels: [
                                        labelLow,
                                        labelMiddel,
                                        labelHigh,
                                        labelExtremelyHigh,
                                    ]
                                }}
                                options={{
                                    legend: {
                                        display: false
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            );
        /*} else {
            return null;
        }*/
    
    }
    
    useEffect(() => {
        Promise.all([
            props.requestData("riskitems/orgrisk")
        ]).then(async (data) => {
            await processData(data);
            setLoading(false);
        });
    }, []);

    return (
        <Card className="systemslistcard">
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                <h4 className="header-title" data-satarget="systemslist">{t("dashboard.cards.systemslist_name")}</h4>
                <p>{t("dashboard.cards.systemslist_description")}</p>
                <hr/>
                <PageItems
                    dataUrl={generateUrl("/api/cra/systems/getAllPaged/")}
                    updateTable={true}
                    renderItem={renderSystemItem}
                    hidePagination={false}
                    // exclusiveFilters={selectOptions.map(option => {return {field: "systemType", value: option.value}})}
                    exclusiveFilters={[{
                        field: "isPrivate", 
                        value: "true"
                    }, {
                        field: "isPublic", 
                        value: "true"
                    }]}
                ></PageItems>
            </Card.Body>
        </Card>
    );
}

export default SystemsList;
