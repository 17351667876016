import * as React from 'react';
import {
    useHistory
  } from "react-router-dom";

import { toast } from 'react-toastify';

import './AnalysisOverview.scss';
import { Card, Button, Modal, Accordion, InputGroup, Form, Table, FormGroup } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../../Auth';
import { useAxios } from '../../../../AxiosHandler';
import { useTranslate } from 'react-polyglot';
import PageTable from '../../../Shared/PageTable/PageTable';
import Field from '../../../Shared/Wizard/Field/Field';
import { Tooltip, Grid, Switch } from '@material-ui/core';

import SiteAssistant from "../../../Shared/SiteAssistant/SiteAssistant";
import DiriFaceDocked from "../../../../Assets/Images/diriassistant/diri.svg";
import Shop from '../../../Shared/Shop/Shop';
import { generateUrl } from '../../../../config';

function AnalysisOverview() {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    let history = useHistory();
    const t = useTranslate();

    const [analysisTemplates, setAnalysisTemplates] = useState<any>([{}]);
    const [analysisInstancesTemplateId, setAnalysisInstancesTemplateId] = useState("");

    const [updateTable, setUpdateTable] = useState(0);

    const [orgs, setOrgs] = useState<Array<any>>([]);

    const [deleteAnalysisInstance, setDeleteAnalysisInstance] = useState(null);
    const [showDeleteAnalysisInstanceModal, setShowDeleteAnalysisInstanceModal] = useState(false);

    const [deleteAnalysisTemplate, setDeleteAnalysisTemplate] = useState(null);
    const [showDeleteAnalysisTemplateModal, setShowDeleteAnalysisTemplateModal] = useState(false);

    const [showTemplateConfigModal, setShowTemplateConfigModal] = useState(false);
    const [analysisTemplateConfiguring, setAnalysisTemplateConfiguring] = useState<any>(null);

    const [showOwnerchangeModal, setShowOwnerchangeModal] = useState(false);
    const [newcoowner, setNewcoowner] = useState("");
    const [ownerchangeEmail, setOwnerchangeEmail] = useState("");
    const [ownerchangeVerifyEmail, setOwnerchangeVerifyEmail] = useState("");
    const [ownerchangeAnalysisTemplateId, setOwnerchangeAnalysisTemplateId] = useState("");

    const [currentAnalysisTemplateOwner, setCurrentAnalysisTemplateOwner] = useState<any>(null);

    const [privateChecked, setPrivateChecked] = useState(false);
    const [sharedAccessibility, setSharedAccessibility] = useState([]);

    const [countedSurveys, setCountedSurveys] = useState({})

    const [dataUrl, setDataUrl] = useState(generateUrl("api/cra/analysisInstances/byTemplate/getAllPaged/undefined"));

    const [showShop, setShowShop] = useState(false)

    //Sharing:
    const [shareWithUser, setShareWithUser] = useState("");
    const [activeSharedWithRows, setActiveSharedWithRows] = useState<Array<any>>([]);
    const [shareWithUsersOptions, setShareWithUsersOptions] = useState([]);
    const [autocompleteKey, setAutocompleteKey] = useState(0);
    

    const notifySuccess = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "success-toast"
    });

    const notifyWarning = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "warning-toast"
    });

    function createAnalysisInstance(templateId) {
        axios.post(generateUrl('/api/cra/analysisInstances/'), {
            org: auth.user["userdata"]["unit"][0],
            template: templateId,
            status: "analysisInstance.status_progress"
        }, {
            headers: {'x-access-token': auth.user["token"]}
        })
        .then(function (response) {
            history.push("/analysis?analysis=" + response.data["_id"] + "&template=" + templateId);
        });
    }

    function getCurrentAnalysisOwnership(analysisId) {
        setCurrentAnalysisTemplateOwner(null);
        axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/analysisTemplates/ownership/") + analysisId,
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            })
        ])
        .then(axios.spread((...res) => {
            setCurrentAnalysisTemplateOwner(res[0]["data"]["owner"]);
        }));
    }

    function handleShowDeleteAnalysisTemplateModal(evt, dataLine) {
        evt.stopPropagation();
        evt.nativeEvent.stopImmediatePropagation();

        setDeleteAnalysisTemplate(dataLine._id);
        setShowDeleteAnalysisTemplateModal(true);
    }

    function handleDeleteAnalysisTemplate() {
        axios.all([
            axios.single({
                method: "delete",
                url: generateUrl("/api/cra/analysisTemplates/") + (deleteAnalysisTemplate as any),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            })
        ])
        .then(axios.spread((...res) => {
            fetchData();
            setShowDeleteAnalysisTemplateModal(false);
        }));
    }

    function handleShowDeleteAnalysisInstanceModal(evt, dataLine) {
        evt.stopPropagation();
        evt.nativeEvent.stopImmediatePropagation();

        setDeleteAnalysisInstance(dataLine._id);
        setShowDeleteAnalysisInstanceModal(true);
    }

    function handleDeleteAnalysisInstance() {
        axios.all([
            axios.single({
                method: "delete",
                url: generateUrl("/api/cra/analysisInstances/") + (deleteAnalysisInstance as any),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            })
        ])
        .then(axios.spread((...res) => {
            fetchData();
            setUpdateTable(updateTable + 1);
            setShowDeleteAnalysisInstanceModal(false);
        }));
    }

    function handleOpenTemplate(templateId) {
        if (templateId != analysisInstancesTemplateId) {
            setDataUrl(generateUrl("api/cra/analysisInstances/byTemplate/getAllPaged/") + templateId);
            setAnalysisInstancesTemplateId(templateId);
            if (analysisTemplates.find(template => template["_id"] == templateId)["accessRule"] != "accessibility") {
                getCurrentAnalysisOwnership(templateId);
            }
            if (analysisTemplates != undefined) {
                if (analysisTemplates.find(template => template["_id"] == templateId)["private"] != undefined) {
                    setPrivateChecked(analysisTemplates.find(template => template["_id"] == templateId)["private"]);
                } else {
                    setPrivateChecked(false);
                }
            }
        }
    }

    async function fetchData() {
        axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/analysisTemplates/byorg/") + auth.user["userdata"]["unit"][0],
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/orgelements"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/users/"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            })
        ])
        .then(axios.spread((...res) => {
            //setAnalysisTemplates(res[0].data.filter(template => template["accessRule"] != "adminprivate"));
            setAnalysisTemplates(res[0].data);
            setOrgs(res[1]["data"]);
            setShareWithUsersOptions(res[2].data.map(d => {return {label: d.email, value: d.email}}))
            setUpdateTable(updateTable + 1);
        }));
    }

    function togglePrivate(evt) {
        let quickToggle = !privateChecked;
        setPrivateChecked(!privateChecked);

        axios.put(generateUrl('/api/cra/analysisTemplates/changeprivate/') + analysisInstancesTemplateId, {
            private: quickToggle
        }, {
            headers: {'x-access-token': auth.user["token"]}
        })
        .then(function (response) {
            notifySuccess(t("system.toast_privatechanged"));
            history.push("/analysislist");
        });
    }

    function removeCoowner(oldcoowner) {
        axios.put(generateUrl('/api/cra/analysisTemplates/removecoowner/') + analysisInstancesTemplateId, {
            oldcoowner
        }, {
            headers: {'x-access-token': auth.user["token"]}
        })
        .then(function (response) {
            fetchData();
            setAnalysisTemplateConfiguring({...analysisTemplateConfiguring, coowners: response.data["coowners"]});
            notifySuccess(t("system.toast_coownerremoved"));
        });
    }

    function addCoowner() {
        axios.put(generateUrl('/api/cra/analysisTemplates/addcoowner/') + analysisInstancesTemplateId, {
            newcoowner: newcoowner
        }, {
            headers: {'x-access-token': auth.user["token"]}
        })
        .then(function (response) {
            if (response == undefined) {
                notifyWarning(t("system.toast_coownernotfound"));
            } else {
                fetchData();
                setAnalysisTemplateConfiguring({...analysisTemplateConfiguring, coowners: response.data["coowners"]});
                notifySuccess(t("system.toast_coowneradded"));
            }
            setNewcoowner("");
        });
    }

    function changeSharing(value) {
        axios.put(generateUrl('/api/cra/analysisTemplates/changesharing/') + analysisInstancesTemplateId, {
            sharing: value
        }, {
            headers: {'x-access-token': auth.user["token"]}
        })
        .then(function (response) {
            fetchData();
            setAnalysisTemplateConfiguring({...analysisTemplateConfiguring, sharing: value});
            notifySuccess(t("system.toast_sharingchanged"));
        });
    }

    function changeAccessibility(value) {
        axios.put(generateUrl('/api/cra/analysisTemplates/changeaccessibility/') + analysisInstancesTemplateId, {
            accessibility: analysisTemplateConfiguring.accessibility
        }, {
            headers: {'x-access-token': auth.user["token"]}
        })
        .then(function (response) {
            fetchData();
            setAnalysisTemplateConfiguring({...analysisTemplateConfiguring, accessibility: value});
            notifySuccess(t("analysisOverview.ownership.accessibilitychanged"));
        });
    }

    function handleOwnerchangeAnalysisTemplate() {
        if (ownerchangeEmail == ownerchangeVerifyEmail) {
            axios.put(generateUrl('/api/cra/analysisTemplates/changeowner/') + ownerchangeAnalysisTemplateId, {
                newOwnerEmail: ownerchangeEmail
            }, {
                headers: {'x-access-token': auth.user["token"]}
            })
            .then(function (response) {
                if (response != undefined) {
                    notifySuccess(t("analysisOverview.ownership.toast_templateownerchanged"));
                    setShowOwnerchangeModal(false);
                    if (
                        analysisTemplates.find(item => item["_id"] == ownerchangeAnalysisTemplateId).accesses.includes("edit_ownership_and_sharing") ||
                        (auth.user["userdata"]["email"] == ownerchangeEmail) ||
                        (((analysisTemplates.find(item => item["_id"] == ownerchangeAnalysisTemplateId) as any)["coowners"] as Array<string>).includes((auth.user["userdata"]["email"] as string)))
                    ) {
                        fetchData();
                        setAnalysisTemplateConfiguring({...analysisTemplateConfiguring, owner: ownerchangeEmail});
                    } else {
                        history.push("/analysislist");
                    }
                } else {
                    notifyWarning(t("analysisOverview.ownership.toast_templateownerchangeerror"));
                }
            });
        } else {
            notifyWarning(t("analysisOverview.ownership.toast_templateownerverifyerror"));
        }
    }

    function handleChangeAction(evt, action, id?:String, type?:String){
        if(id != undefined){
            if(evt){
                setAnalysisTemplateConfiguring({
                    ...analysisTemplateConfiguring,
                    accessRules: {
                        ...analysisTemplateConfiguring.accessRules,
                        users: analysisTemplateConfiguring.accessRules.users.map(i => {
                            if(i.id == id){
                                if(!i.actions.includes(action)){
                                    i.actions.push(action);
                                }
                                return i;
                            } else {
                                return i;
                            }
                        })
                    }
                    
                })
                
            } else {
                setAnalysisTemplateConfiguring({
                    ...analysisTemplateConfiguring,
                    accessRules: {
                        ...analysisTemplateConfiguring.accessRules,
                        users: analysisTemplateConfiguring.accessRules.users.map(i => {
                            if(i.id == id){
                                i.actions = i.actions.filter(a => a != action);
                                return i;
                            } else {
                                return i;
                            }
                        })
                    }
                })
            }
        }
        
    }

    async function addSharedWithUser(){
        let pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        if (!pattern.test(shareWithUser)) { 
            notifyWarning(t("system.toast_invite_invalid_email")); 
            return undefined;
        }
        await axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/users/") + shareWithUser,
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
        ]).then(axios.spread((...res) => {
            if(res[0].data.id != undefined && !analysisTemplateConfiguring.accessRules.users.map(u => u.id).includes(res[0].data.id.toString())){
                analysisTemplateConfiguring.accessRules.users.push({...res[0].data, actions: []});
                saveAccessRules(analysisTemplateConfiguring["_id"]);
            }
            setShareWithUser("");
            setAutocompleteKey(autocompleteKey + 1);
        }))
    }

    function isChecked(action, id?:String, type?:String) {
        if(id != undefined){
            return analysisTemplateConfiguring.accessRules?.users.find(s => s.id == id)?.actions.includes(action) ?? false;
        } else if (type != undefined){
            return analysisTemplateConfiguring.accessRules?.shared.find(s => s.type == type)?.actions.includes(action) ?? false;
        }
        return false;
    }

    function saveAccessRules(surveyId, userid = undefined){
        axios.put(generateUrl("/api/cra/analysisTemplates/updateAccessRules/") + surveyId, {
            accessRules: userid == undefined ? analysisTemplateConfiguring.accessRules : {...analysisTemplateConfiguring.accessRules, users: analysisTemplateConfiguring.accessRules.users.filter(user => user.id != userid)}
        }, {
            headers: {'x-access-token': auth.user["token"]}
        }).then((res) => {
            setAnalysisTemplateConfiguring({...analysisTemplateConfiguring, accessRules: res.data.accessRules})
            setShareWithUser("");
            setAutocompleteKey(autocompleteKey + 1);
            notifySuccess(t("analysisOverview.ownership.toast_accessruleschanged"));
            fetchData();
        });
    }

    async function getCountedSurveyObjects() {
        axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/analysisTemplates/countedObjects"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            })
        ]).then((res) => {
            console.log(res[0].data)
            setCountedSurveys(res[0].data);
        });
    }

    useEffect(() => {
        fetchData();
        getCountedSurveyObjects();
    }, []);

    return(
        <div className="maincontent">
            <SiteAssistant
                dockedLogo={DiriFaceDocked}
                featurekey="analysisoverview"
            />
            <div className="systems-container">
                <div className="pagetitle-container">
                    <h1>{t("analysisOverview.page_name")}</h1>
                </div>
                <Card>
                    <Card.Body>
                        <h4 className="floatleft header-title">{t("analysisOverview.title")}</h4>
                        <div className='newAnalysisTemplateBtn'>
                            {auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "survey" && rule.objSubType == "survey")?.allowed == true ?
                                countedSurveys["countedSurveys"] == undefined || countedSurveys["countedSurveys"] > 0 ?
                                    <Button 
                                        disabled={
                                            auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "survey" && rule.objSubType == "survey")?.actions.create != "allow" || 
                                            (countedSurveys["countedSurveys"] != undefined && countedSurveys["countedSurveys"] <= 0)
                                        }
                                        //className="newAnalysisTemplateBtn" 
                                        onClick={() => history.push("/analysiseditor")}
                                    >{t("analysisOverview.template_new")}</Button>
                                : 
                                    <>
                                        <Tooltip title={t("analysisOverview.limit_exceeded")} placement="bottom" >
                                            <div className="lockedElement-help">
                                                <span><i className="dripicons-question" /></span>
                                            </div>
                                        </Tooltip>
                                        <Button 
                                            className="upgrade-btn" 
                                            onClick={() => { setShowShop(true) }}
                                        ><i className="dripicons-lock" /> {t("analysisOverview.requires_update")}</Button>
                                    </>
                                    
                                :
                                    <>
                                        <Tooltip title={t("analysisOverview.not_allowed")} placement="bottom" >
                                            <div className="lockedElement-help">
                                                <span><i className="dripicons-question" /></span>
                                            </div>
                                        </Tooltip>
                                        <Button 
                                            className="upgrade-btn" 
                                            onClick={() => { setShowShop(true) }}
                                        ><i className="dripicons-lock" /> {t("analysisOverview.requires_update")}</Button>
                                    </>
                                }
                            {/* <p>{t("analysisOverview.remaining") + " " + countedSurveys["countedSurveys"]} / {auth.user["userdata"]["actualAccessRole"]["rules"].find(rule => rule.objType == "survey" && rule.objSubType == "survey")?.limitations?.amount}</p> */}
                        </div>
                    </Card.Body>
                </Card>
                <Accordion>
                    {analysisTemplates.map((analysisTemplate, key) => {
                        if(analysisTemplate.accessRules == undefined){
                            analysisTemplate.accessRules = {
                                users: []
                            }
                        }
                        return(
                        <Card key={key}>
                            <Accordion.Toggle className="clickable" onClick={() => handleOpenTemplate(analysisTemplate["_id"])} as={Card.Header} eventKey={key.toString()}>
                                {analysisTemplate["accessRule"] != "accessibility" && analysisTemplate["accessRule"] != "adminprivate" ?
                                    <div className="editTemplateConfig">
                                        <span style={{cursor: "pointer"}} onClick={(evt) => {
                                            evt.stopPropagation();
                                            evt.preventDefault();
                                            handleOpenTemplate(analysisTemplate["_id"]);
                                            setAnalysisTemplateConfiguring(analysisTemplate);
                                            setShowTemplateConfigModal(true);
                                        }}><i className="dripicons-user" /><i style={{ fontSize: "10px", position: "absolute" }} className="dripicons-lock" /></span>
                                        {analysisTemplate["accesses"]?.includes("delete") ?
                                            <span style={{cursor: "pointer", marginLeft: "26px"}} onClick={(evt) => {
                                                if(analysisTemplate["accesses"].includes("delete")){
                                                    evt.stopPropagation();
                                                    evt.preventDefault();
                                                    handleShowDeleteAnalysisTemplateModal(evt, analysisTemplate);
                                                }
                                                
                                            }}><i className="dripicons-trash" /></span>
                                        : null}
                                        
                                    </div>
                                : null}
                                <h4 className="header-title">{analysisTemplate["name"]}</h4>
                                <p>{analysisTemplate["description"]}</p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={key.toString()}>
                                <Card.Body>
                                    <div className="analysiscontainer">
                                        <Button disabled={!analysisTemplate["accesses"]?.includes("answer_survey")} className="newAnalysisInstanceBtn" onClick={() => createAnalysisInstance(analysisTemplate["_id"])}>{t("analysisOverview.instance_new")}</Button>
                                        {analysisTemplate["accessRule"] != "accessibility" && analysisTemplate["accessRule"] != "adminprivate" ?
                                            <Button disabled={!analysisTemplate["accesses"]?.includes("edit")} className="editAnalysisBtn" onClick={() => history.push("/analysiseditor?templateId=" + analysisTemplate["_id"])}>{t("analysisOverview.edit_template")}</Button>
                                        : null}
                                        <br />
                                        <br />
                                        {analysisTemplate["_id"] == analysisInstancesTemplateId ?
                                            <PageTable
                                                dataUrl={dataUrl}
                                                options={{
                                                    showFields: [
                                                        { label: t("analysisOverview.tableheading_startdate"), field: "dateReadable"},
                                                        { label: t("analysisOverview.tableheading_status"), field: "status", override: dataLine => dataLine["status"] != undefined ? t(dataLine["status"]) : ""},
                                                    ],
                                                    pageSize: 5
                                                }}
                                                updateTable={updateTable}
                                                deaultSortDirection={"asc"}
                                                defaultSortField={"dateReadable"}
                                                hidePagination={false}
                                                hidePagesize={false}
                                                hideFilter={false}
                                                hover={true}
                                                dataAfter={(dataLine) =>
                                                    (analysisTemplate["accessRule"] != "accessibility" && analysisTemplate["accessRule"] != "adminprivate") || dataLine["createdBy"] == auth.user["userdata"]["_id"] ?
                                                        <Button disabled={!analysisTemplate["accesses"]?.includes("delete") && !analysisTemplate["accesses"]?.includes("answer_survey")} onClick={(evt) => handleShowDeleteAnalysisInstanceModal(evt, dataLine)}>{t("analysisOverview.delete")}</Button>
                                                    : null
                                                }
                                                onLineClick={(line) => history.push("/analysis?analysis=" + line["_id"] + "&template=" + line["template"])}
                                            ></PageTable>
                                        : null}
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )})}
                </Accordion>
                <Modal show={showDeleteAnalysisInstanceModal} onHide={() => setShowDeleteAnalysisInstanceModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("analysisOverview.delete_analysis")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{t("analysisOverview.delete_analysis_info")}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="cancel posleft" onClick={() => setShowDeleteAnalysisInstanceModal(false)}>
                            {t("analysisOverview.cancel")}
                        </Button>
                        <Button onClick={() => handleDeleteAnalysisInstance()}>
                            {t("analysisOverview.delete_analysis")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showDeleteAnalysisTemplateModal} onHide={() => setShowDeleteAnalysisTemplateModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("analysisOverview.delete_analysistemplate")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{t("analysisOverview.delete_analysistemplate_info")}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="cancel posleft" onClick={() => setShowDeleteAnalysisTemplateModal(false)}>
                            {t("analysisOverview.cancel")}
                        </Button>
                        <Button onClick={() => handleDeleteAnalysisTemplate()}>
                            {t("analysisOverview.delete_analysis")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showOwnerchangeModal} onHide={() => setShowOwnerchangeModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("analysisOverview.ownership.ownerchange_title")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{t("analysisOverview.ownership.ownerchange_alert")}</p>
                        <p>{t("analysisOverview.ownership.ownerchange_detail")}</p>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>{t("system.ownerchange_emailfield")}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="email" onChange={evt => setOwnerchangeEmail((evt.target as HTMLInputElement).value)} value={ownerchangeEmail} ></Form.Control>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>{t("system.ownerchange_emailfieldverify")}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="email" onChange={evt => setOwnerchangeVerifyEmail((evt.target as HTMLInputElement).value)} value={ownerchangeVerifyEmail} ></Form.Control>
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="cancel posleft" onClick={() => setShowOwnerchangeModal(false)}>
                            {t("system.ownerchange_abort")}
                        </Button>
                        <Button onClick={handleOwnerchangeAnalysisTemplate}>
                            {t("system.ownerchange_change")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal size="lg" show={showTemplateConfigModal} onHide={() => setShowTemplateConfigModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {analysisTemplateConfiguring != null ?
                            <div className="ownershipcontainer">
                                <h4 className="header-title">{t("analysisOverview.ownership.title")}</h4>
                                <div className="riskMatrixToggle">
                                    <FormGroup>
                                        <Tooltip
                                            title={
                                                analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing") && (analysisTemplateConfiguring["private"] == undefined || !analysisTemplateConfiguring["private"]) ?
                                                    t("system.ownership_private_tooltip_lockoutwarning")
                                                :
                                                    (!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing") ?
                                                        t("system.ownership_private_tooltip_locked")
                                                    :
                                                        t("system.ownership_private_tooltip"))
                                            }
                                            placement="top"
                                        >
                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <Switch
                                                        size="small"
                                                        disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")}
                                                        checked={privateChecked}
                                                        onChange={togglePrivate}
                                                        color="primary"
                                                        className={"toggleswitch"}
                                                    />
                                                </Grid>
                                                <Grid item>{t("system.ownership_private")}</Grid>
                                            </Grid>
                                        </Tooltip>
                                    </FormGroup>
                                </div>
                                <p>{t("analysisOverview.ownership.description")}</p>
                                <div className="row">
                                    <div className="col col-lg-6">
                                        <div className="ownercontainer">
                                            <p><span style={{fontWeight: 600}}>{t("system.ownership_owner")}:</span> {currentAnalysisTemplateOwner != null ? currentAnalysisTemplateOwner["email"] : ""}</p>
                                            {analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing") ?
                                                <Button disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")} onClick={() => { setShowOwnerchangeModal(true); setOwnerchangeAnalysisTemplateId(analysisTemplateConfiguring["_id"]) }}>{t("system.ownership_ownerchangebtn")}</Button>
                                            : null}
                                        </div>
                                        <div className = {"accessControl objectAccessControlDiv"}>
                                            <div className="objectAccessControlUsersDiv">
                                                <div>
                                                    {analysisTemplateConfiguring.accessRules?.users.length != 0 ?
                                                        <div>
                                                            <p>{t("system.shared_with")}</p>
                                                            <div className="objectAccessControlTableParentDiv">
                                                                <div className={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing") ? "disabled" : ""}>
                                                                
                                                                </div>
                                                                <table className={"objectAccessControlTable systemObjectAccessControlTable"}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th></th>
                                                                            <th>{t("analysisOverview.ownership.read")}</th>
                                                                            <th>{t("analysisOverview.ownership.edit")}</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    {analysisTemplateConfiguring.accessRules?.users.filter(user => user.accepted == true).map((user, key) => { return (
                                                                        <React.Fragment>
                                                                            <tbody>
                                                                                <tr key={key} className="mainRow">
                                                                                    <td className="clickable" onClick={() => {!activeSharedWithRows.includes(user.id) ? setActiveSharedWithRows([...activeSharedWithRows, user.id]) : setActiveSharedWithRows(activeSharedWithRows.filter(item => item != user.id))}}><i className={!activeSharedWithRows.includes(user.id) ? "dripicons-chevron-down" : "dripicons-chevron-up"} /></td>
                                                                                    <td>{user.name != "" ? <p>{user.name}, <br/>{user.email}</p> : <p>{user.email}</p>}</td>
                                                                                    <td>
                                                                                        <Field
                                                                                            type="indeterminatecheckbox"
                                                                                            checked={isChecked("read", user.id, undefined)}
                                                                                            onChange={(evt) => { 
                                                                                                if(evt) {
                                                                                                    handleChangeAction(evt, "read", user.id, undefined)
                                                                                                } else {
                                                                                                    handleChangeAction(evt, "read", user.id, undefined);
                                                                                                    handleChangeAction(evt, "edit", user.id, undefined);
                                                                                                    handleChangeAction(evt, "answer_survey", user.id, undefined);
                                                                                                    handleChangeAction(evt, "edit_ownership_and_sharing", user.id, undefined);
                                                                                                    handleChangeAction(evt, "delete", user.id, undefined);
                                                                                                } }}
                                                                                            disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")}
                                                                                            parent={false}
                                                                                            title={""}
                                                                                            titlePlacement="start"
                                                                                        ></Field>
                                                                                    </td>
                                                                                    <td>
                                                                                        <Field
                                                                                            type="indeterminatecheckbox"
                                                                                            checked={isChecked("edit", user.id, undefined)}
                                                                                            onChange={(evt) => { 
                                                                                                if(evt) {
                                                                                                    handleChangeAction(evt, "read", user.id, undefined);
                                                                                                    handleChangeAction(evt, "edit", user.id, undefined);
                                                                                                } else {
                                                                                                    handleChangeAction(evt, "edit", user.id, undefined);
                                                                                                    handleChangeAction(evt, "answer_survey", user.id, undefined);
                                                                                                    handleChangeAction(evt, "edit_ownership_and_sharing", user.id, undefined);
                                                                                                    handleChangeAction(evt, "delete", user.id, undefined);

                                                                                                }}}
                                                                                            disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")}
                                                                                            parent={false}
                                                                                            title={""}
                                                                                            titlePlacement="start"
                                                                                        ></Field>
                                                                                    </td>
                                                                                    <td>
                                                                                    {analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing") ?
                                                                                        <Button onClick={() => {setAnalysisTemplateConfiguring({
                                                                                            ...analysisTemplateConfiguring,
                                                                                            accessRules: {
                                                                                                ...analysisTemplateConfiguring.accessRules,
                                                                                                users: analysisTemplateConfiguring.accessRules.users.filter(u => u.id != user.id)
                                                                                            }
                                                                                        }); saveAccessRules(analysisTemplateConfiguring["_id"], user.id);}}>{t("analysisOverview.ownership.remove")}</Button>
                                                                                    : null}
                                                                                    </td>
                                                                                    
                                                                                </tr>
                                                                            </tbody>
                                                                            <tbody className={"systemObjectActionsRows " + (activeSharedWithRows.includes(user.id) ? "" : "hidden")}>
                                                                                <tr>
                                                                                    <td colSpan={2}><p>{t("accessControl.table.survey.answer_survey")}</p></td>
                                                                                    <td colSpan={3}>
                                                                                        <Field
                                                                                            type="indeterminatecheckbox"
                                                                                            checked={isChecked("answer_survey", user.id, undefined)}
                                                                                            onChange={(evt) => handleChangeAction(evt, "answer_survey", user.id, undefined)}
                                                                                            disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")}
                                                                                            parent={false}
                                                                                            title={t("accessControl.table.allow")}
                                                                                            titlePlacement="end"
                                                                                        ></Field>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    
                                                                                    <td colSpan={2}><p>{t("accessControl.table.survey.edit_ownership_and_sharing")}</p></td>
                                                                                    <td colSpan={3}>
                                                                                        <Field
                                                                                            type="indeterminatecheckbox"
                                                                                            checked={isChecked("edit_ownership_and_sharing", user.id, undefined)}
                                                                                            onChange={(evt) => handleChangeAction(evt, "edit_ownership_and_sharing", user.id, undefined)}
                                                                                            disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")}
                                                                                            parent={false}
                                                                                            title={t("accessControl.table.allow")}
                                                                                            titlePlacement="end"
                                                                                        ></Field>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                
                                                                                    <td colSpan={2}><p>{t("accessControl.table.survey.delete")}</p></td>
                                                                                    <td colSpan={3}>
                                                                                        <Field
                                                                                            type="indeterminatecheckbox"
                                                                                            checked={isChecked("delete", user.id, undefined)}
                                                                                            onChange={(evt) => handleChangeAction(evt, "delete", user.id, undefined)}
                                                                                            disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")}
                                                                                            parent={false}
                                                                                            title={t("accessControl.table.allow")}
                                                                                            titlePlacement="end"
                                                                                        ></Field>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </React.Fragment>
                                                                    )} )}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    : null }
                                                </div> 
                                                <div key={autocompleteKey} className="objectAccessControlAddUsersDiv">
                                                    <Field
                                                        type="autocomplete"
                                                        value={shareWithUser != undefined ? shareWithUser : ""}
                                                        title={t("analysisOverview.ownership.share_with_user")}
                                                        onChange={value => setShareWithUser(value)}
                                                        disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")}
                                                        options = {{
                                                            list: shareWithUsersOptions,
                                                            minlength: 2,
                                                            noresults: "no matches",
                                                            placeholder: t("analysisOverview.ownership.enter_email")
                                                        }}
                                                    ></Field>
                                                    <Button 
                                                        onClick={addSharedWithUser}
                                                        disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")}
                                                    >{t("analysisOverview.ownership.share")}</Button>
                                                </div>
                                                {analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing") ?
                                                <div>
                                                    <br />
                                                    <Button disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")} onClick={() => saveAccessRules(analysisTemplateConfiguring["_id"])} className="editAnalysisBtn">
                                                        {t("analysisOverview.ownership.save_user_accesses")}
                                                    </Button>
                                                </div>
                                                : null }
                                            </div>
                                            
                                            {/* <p style={{fontWeight: 600}}>{t("system.ownership_coowners")}</p>
                                            <Table striped size="sm">
                                                <tbody>
                                                    {analysisTemplateConfiguring["coowners"] != undefined ? analysisTemplateConfiguring["coowners"].map((coowner, key) => { return (
                                                        <tr key={key}>
                                                            <td>{coowner["email"]}</td>
                                                            {analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing") ?
                                                                <td style={{width: "102px"}}><Button onClick={() => removeCoowner(coowner["_id"])}>{t("system.ownership_deletecoownerbtn")}</Button></td>
                                                            : null}
                                                        </tr>
                                                    )}) : null}
                                                </tbody>
                                            </Table>
                                            {analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")?
                                                <div>
                                                    <InputGroup className="mb-3" style={{width: "calc(100% - 140px)", float: "left"}}>
                                                        <Form.Control disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")} style={{marginLeft: "0px"}} type="email" placeholder={t("system.ownership_addcoownerplaceholder")} onChange={evt => setNewcoowner(evt.target.value)} value={newcoowner} ></Form.Control>
                                                    </InputGroup>
                                                    <Button disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")} style={{width: "140px", height: "38px"}} onClick={addCoowner}>{t("system.ownership_addcoownerbtn")}</Button>
                                                </div>
                                            : null} */}
                                        </div>
                                    </div>
                                    {!privateChecked ?
                                        <div className="col col-lg-6">
                                            <InputGroup className="mb-3">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>{t("system.ownership_sharing")}</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control 
                                                    disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")}
                                                    as="select" 
                                                    onChange={evt => changeSharing((evt.target as HTMLSelectElement).value)} 
                                                    value={analysisTemplateConfiguring["sharing"]} 
                                                >
                                                    <option value="none">{t("system.ownership_sharing_none")}</option>
                                                    <option value="org">{t("system.ownership_sharing_org")}</option>
                                                    {/* <option value="public">{t("system.ownership_sharing_public")}</option> */}
                                                </Form.Control>
                                            </InputGroup>
                                            {analysisTemplateConfiguring["sharing"] == "org" ? 
                                                <div>
                                                    <Field
                                                        type="selectmulti"
                                                        title={t("analysisOverview.ownership.accessibility_choose_orgs")}
                                                        value={analysisTemplateConfiguring.accessibility.map(val => { return { value: val, label: orgs.find(o => o["_id"].toString() == val) != undefined ? orgs.find(o => o["_id"].toString() == val).name : t("analysisOverview.ownership.accessibility_share_with_all") } } ) }
                                                        onChange={val => setAnalysisTemplateConfiguring({...analysisTemplateConfiguring, accessibility: [...val.map(v => v.value)]})}
                                                        disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")}
                                                        options={{
                                                            selecttext: t("users.selecttext"),
                                                            choices: [
                                                                {
                                                                    value: "all",
                                                                    label: t("analysisOverview.ownership.accessibility_share_with_all")
                                                                }, 
                                                                ...orgs.map(org => {
                                                                return {
                                                                    value: org["_id"].toString(),
                                                                    label: org["name"]
                                                                }
                                                            })]
                                                        }}
                                                    ></Field>
                                                    <br />
                                                    {analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing") ?
                                                        <Button disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")} className="editAnalysisBtn" onClick={() => changeAccessibility(analysisTemplateConfiguring.accessibility)}>
                                                            {t("analysisOverview.ownership.accessibility_save_chosen_orgs")}
                                                        </Button>
                                                    : null}
                                                    
                                                </div>
                                            : null }
                                                {/* <Tooltip
                                                    title={t("analysisOverview.ownership.accessibility_tooltip")}
                                                    placement="top"
                                                > */}
                                                
                                                    {/* <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>{t("analysisOverview.ownership.accessibility")}</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control 
                                                            disabled={!analysisTemplateConfiguring.accesses.includes("edit_ownership_and_sharing")}
                                                            as="select" 
                                                            onChange={evt => changeAccessibility((evt.target as HTMLSelectElement).value)} 
                                                            value={analysisTemplateConfiguring["accessibility"]} 
                                                        >
                                                            <option value="none">{t("analysisOverview.ownership.accessibility_owners")}</option> 
                                                            <option value="all">{t("analysisOverview.ownership.accessibility_all")}</option>
                                                            {orgs.map((org, orgKey) => 
                                                                <option key={orgKey} value={org["_id"]}>{org["name"]}</option>
                                                            )}
                                                        </Form.Control>
                                                    </InputGroup> */}
                                                {/* </Tooltip> */}

                                            
                                            
                                        </div>
                                    : null }
                                </div>
                            </div>
                        : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setShowTemplateConfigModal(false)}>
                            {t("analysisOverview.configmodal_close")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <Shop 
                show={showShop}
                onHide={() => setShowShop(false)}
                size="lg"
                orgid={auth.user["userdata"]["unit"][0]}
                onPurchaseComplete={() => setShowShop(false)}
            />
        </div>
    );
}

export default AnalysisOverview;