import React, { useEffect, useState } from 'react';
import { useAxios } from "../../../../../AxiosHandler";

import { useTranslate } from 'react-polyglot';
import './TopVulnerabilitiesCauses.scss';
import { useAuth } from "../../../../../Auth";
import { Card, Modal, InputGroup, Form } from 'react-bootstrap';
import { HorizontalBar } from 'react-chartjs-2';
import _ from 'lodash';
import PageTable from '../../../../Shared/PageTable/PageTable';
import { group } from 'console';
import Field from '../../../../Shared/Wizard/Field/Field';
import { useDynamicLanguage } from '../../../../../DynamicLanguageProvider';

const TopVulnerabilitiesCauses = (props) => {
    const t = useTranslate();   
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();
    const td = useDynamicLanguage().td;

    const [allVulnerabilities, setAllVulnerabilities] = useState([{}]);
    const [topVulnerabilities, setTopVulnerabilities] = useState([{}]);
    const [labels, setLabels] = useState([{}]);
    const [colors, setColors] = useState([{}]);
    const [loading, setLoading] = useState(true);
    const [allOccurences, setAllOccurences] = useState([{}]);

    const [selectedOption, setSelectedOption] = useState("all");

    const label_top5 = t("dashboard.cards.top5vulnerability_cause");
    const [showCauseModal, setShowCauseModal] = useState(false);
    const handleCloseCauseModal = () => setShowCauseModal(false);

    const treatmentName = t("treatment.name");
    const treatmentCost = t("treatment.cost");
    const treatmentStatus = t("treatment.status");
    const treatmentResponsible = t("treatment.responsible");
    const probabilityReduction = t("treatment.probability_reduction");
    const treatmentTime = t("treatment.time");

    let selectTitle = t("dashboard.cards.drop_down_select_title");
    let selectOptions = [
        {
            "label": t("dashboard.cards.choice_all"),
            "value": "all"
        },
        {
            "label": t("dashboard.cards.choice_system"),
            "value": "system"
        },
        {
            "label": t("dashboard.cards.choice_orgros"),
            "value": "orgros"
        },
        {
            "label": t("dashboard.cards.choice_other"),
            "value": "other"
        },
    ]

    const localStorageUser = localStorage.getItem("user");
    let settings = {};

    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    function handleSelectInput(value){
        setLoading(true);
        setSelectedOption(value);
    }

    function valueToPercentage(value, maxvalue) {
        return ((value - 1) / (maxvalue - 1) * 100);
    }

    function percentageToValue(percentage, maxvalue) {
        return ((maxvalue - 1) * (percentage / 100)) + 1;
    }

    const data = {
            labels: labels.map(label => td(label)),
            datasets: [
                {
                    label: label_top5,
                    backgroundColor: colors,
                    borderWidth: 1,
                    data: topVulnerabilities.map(item => item["probability"]),
                    objects:topVulnerabilities
                },
            ],
        };
      
        const options = {
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                stacked: true
                }],
                yAxes: [{
                stacked: true
                }],
            },
            tooltips: {
                mode: 'label',
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return data['datasets'][0]['data'][tooltipItem['index']];
                        }
                    }
            }
    }

    async function processData(res, type) {
        let systems = res[1].data;

        if (type == "all") {
            systems = res[1].data;
            systems = systems.map(system => system._id);
        } else if (type == "system") {
            systems = res[1].data.filter(res => res.systemType.toString().toLowerCase().includes(type));
            systems = systems.map(system => system._id);
        } else {
            systems = res[1].data.filter(res => res.systemType.toString().toLowerCase() == type);
            systems = systems.map(system => system._id);
        }

        res[0].data = res[0].data.filter(res => systems.includes(res.system));

        let result:any = [];
        const average = (a, b, i, self) => a + b["probability"] / self.length;
        result = Object.values(
            res[0].data.reduce((acc, elem, i, self) => (
                (acc[elem["category"]] = acc[elem["category"]] || {
                    ["category"]: elem["category"],
                    ["probability"]: self.filter((x) => x["category"] === elem["category"]).reduce(average, 0),
                }),acc),{})

            );
        
        let topFive =_.orderBy(result, ['probability'],['desc']).slice(0,5);
        let tmpLabels = Array<String>();
        let tmpColors = Array<String>();

        if(topFive != undefined) {
            topFive.map((item,key) =>{
                tmpLabels.push(item["category"] as String);
                if(item["probability"] >= 0 && item["probability"] <= 25) {
                    tmpColors.push("#5B8266")
                } else if(item["probability"] >= 26 && item["probability"] <= 50){
                    tmpColors.push("#FACD75")
                } else if(item["probability"] >= 51 && item["probability"] <= 75){
                    tmpColors.push("#D8775A")
                } else if(item["probability"] >= 76 && item["probability"] <= 100){
                    tmpColors.push("#993742")
                }                                   
            })
        }

        setLabels(tmpLabels);
        setTopVulnerabilities(topFive);
        setColors(tmpColors);
    }
    
    useEffect(() => {
        Promise.all([
            props.requestData("causes"),
            props.requestData("systems"),
        ]).then(async (data) => {
            await processData(data, selectedOption);
            setLoading(false);
        });
    }, [selectedOption]);

    return (
        <Card>
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                <h4 className="header-title">{t("dashboard.cards.topvulnerability_cause_name")}</h4>
                <p>{t("dashboard.cards.topvulnerability_cause_description")}</p>
                <Field
                    type="select"
                    title={ selectTitle }
                    onChange={ value => handleSelectInput(value.value) }
                    options={{
                        "choices": selectOptions
                    }}
                > 
                </Field>
                <hr />
                <div>
                    <HorizontalBar
                        data={data}
                        options={options}
                    />                
                </div>
            </Card.Body>
        </Card>
    );
}

export default TopVulnerabilitiesCauses;
