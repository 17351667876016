import React from "react";

import "./TreeOverview.scss";
import { Button, ListGroup, Accordion } from "react-bootstrap";

function TreeOverview(props) {
  function handleEditButtonClick(element) {
    if (props.onEditClick != undefined) {
      props.onEditClick(element);
    }
  }

  function handleAddElementClick(element) {
    if (props.onAddElementClick != undefined) {
      props.onAddElementClick(element);
    }
  }
  function handleDisableNewButton(element) {
    if (props.disabledAddNewOrganization != undefined) {
      return props.disabledAddNewOrganization(element);
    } else {
      return false;
    }
  }

  function DrawElement(elements, element, key) {
    const disableAddNew = handleDisableNewButton(element);

    return (
      <ListGroup key={key}>
        <ListGroup.Item
          className={
            (key.toString().length == 1 || key.toString().length == 2
              ? "outeritem"
              : "inneritem") +
            " " +
            (key.toString().length == 2 ? "seconditem" : "")
          }
          style={
            props.accentColor != undefined
              ? { borderLeft: "10px solid transparent" }
              : { borderLeft: "6px solid #51be82" }
          }
        >
          <Accordion>
            <div className="accordionheader">
              <Accordion.Toggle
                style={{
                  borderLeft:
                    props.accentColor != undefined
                      ? "6px solid " + props.accentColor
                      : "6px solid #51be82",
                  backgroundColor:
                    props.backgroundColor != undefined
                      ? props.backgroundColor
                      : "#f4fbff",
                }}
                eventKey="0"
                className={
                  (props.hideNewButton != undefined &&
                    props.hideNewButton == true &&
                    elements.filter((item) => item["parent"] == element["_id"])
                      .length > 0) ||
                  props.hideNewButton == undefined ||
                  props.hideNewButton == false
                    ? "clickable"
                    : ""
                }
              >
                {props.elementName != undefined
                  ? props.elementName(element)
                  : key}
                <div className="editbuttons">
                  {props.editIcon == undefined &&
                  (props.hideEditButton == undefined ||
                    props.hideEditButton == false) ? (
                    <i
                      onMouseEnter={(evt) => {
                        (evt.target as any).style.color =
                          props.accentColor != undefined
                            ? props.accentColor
                            : "#51be82";
                      }}
                      onMouseOut={(evt) => {
                        (evt.target as any).style.color = "black";
                      }}
                      className="dripicons-document-edit edit"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditButtonClick(element);
                      }}
                    />
                  ) : props.editIcon != undefined ? (
                    props.editIcon(element)
                  ) : (
                    ""
                  )}
                  {props.customButtons != undefined
                    ? props.customButtons(element)
                    : ""}
                </div>
                {(props.hideNewButton != undefined &&
                  props.hideNewButton == true &&
                  elements.filter((item) => item["parent"] == element["_id"])
                    .length > 0) ||
                props.hideNewButton == undefined ||
                props.hideNewButton == false ? (
                  <i className="dripicons-chevron-down chevron" />
                ) : (
                  ""
                )}
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="0">
              <div>
                {elements
                  .filter((item) => item["parent"] == element["_id"])
                  .map((child, key2) => {
                    return DrawElement(elements, child, key + "" + key2);
                  })}
                {props.hideNewButton == undefined ||
                props.hideNewButton == false ? (
                  <ListGroup
                    className={
                      (key.toString().length == 1 || key.toString().length == 2
                        ? "outeritem"
                        : "inneritem") +
                      " " +
                      (key.toString().length == 2 ? "seconditem" : "")
                    }
                  >
                    <ListGroup.Item
                      style={
                        key.toString().length != 1 || key.toString().length == 2
                          ? props.accentColor != undefined
                            ? { borderLeft: "10px solid transparent" }
                            : { borderLeft: "6px solid #51be82" }
                          : undefined
                      }
                      onClick={() => {
                        handleAddElementClick(element);
                      }}
                      disabled={disableAddNew}
                    >
                      <Button
                        className="newelementBtn"
                        style={
                          props.accentColor != undefined
                            ? {
                                borderLeft: "6px solid " + props.accentColor,
                                color: props.accentColor,
                              }
                            : {
                                borderLeft: "6px solid #51be82",
                                color: "#51be82",
                              }
                        }
                        onClick={() => {
                          handleAddElementClick(element);
                        }}
                        disabled={disableAddNew}
                      >
                        <i className="dripicons-plus" />{" "}
                        {props.addElementText == undefined
                          ? "Legg til"
                          : props.addElementText(element)}
                      </Button>
                    </ListGroup.Item>
                  </ListGroup>
                ) : (
                  ""
                )}
              </div>
            </Accordion.Collapse>
          </Accordion>
        </ListGroup.Item>
      </ListGroup>
    );
  }

  return (
    <div className="treeoverview">
      {props.data != undefined
        ? //Recursively drawing elements starting with top level parents
          props.data
            .filter(
              (item) =>
                item["parent"] == "" ||
                props.data
                  .map((item2) => String(item2["_id"]))
                  .indexOf(String(item["parent"])) == -1
            )
            .map((child, key) => {
              return DrawElement(props.data, child, key);
            })
        : ""}
    </div>
  );
}

export default TreeOverview;
