import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  ListGroup,
  Modal,
  InputGroup,
  Form,
  Button,
  FormGroup,
  Accordion,
  Spinner,
} from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";
import { Grid, Switch, Tooltip } from "@material-ui/core";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Doughnut } from "react-chartjs-2";

import { useAuth } from "../../../../../../Auth";
import { useAxios } from "../../../../../../AxiosHandler";

import BowtieTreatmentHandler from "../BowtieTreatmentHandler/BowtieTreatmentHandler";
import Field from "../../../../../Shared/Wizard/Field/Field";
import PageTable from "../../../../../Shared/PageTable/PageTable";
import TreatmentEditorModal from "../../../../../Shared/Modals/Edit/TreatmentEditorModal";
import { useDynamicLanguage } from "../../../../../../DynamicLanguageProvider";

import { generateUrl } from "../../../../../../config";

import "./BowtieConsequenceList.scss";

function BowtieConsequenceList(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();
  const td = useDynamicLanguage().td;
  const history = useHistory();
  let { systemid } = useParams();
  const myRef = React.useRef<any>();

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const [copyingChecked, setCopyingChecked] = useState(false);

  const [showDeleteConsequenceModal, setShowDeleteConsequenceModal] =
    useState(false);
  const [showEditConsequenceModal, setShowEditConsequenceModal] =
    useState(false);
  const [showNewConsequenceModal, setShowNewConsequenceModal] = useState(false);
  const [showExistingConsequenceModal, setShowExistingConsequenceModal] =
    useState(false);
  const [deleteConsequence, setDeleteConsequence] = useState("");
  const [editConsequence, setEditConsequence] = useState({});

  const [newConsequenceName, setNewConsequenceName] = useState("");
  const [newConsequenceDescription, setNewConsequenceDescription] =
    useState("");

  const [newConsequenceCostMin, setNewConsequenceCostMin] = useState("");
  const [newConsequenceCostMax, setNewConsequenceCostMax] = useState("");
  const [newConsequenceCostAvg, setNewConsequenceCostAvg] = useState("");
  const [newConsequenceSystemvalues, setNewConsequenceSystemvalues] =
    useState<any>([]);
  const [newConsequenceConsequenceValue, setNewConsequenceConsequenceValue] =
    useState("2");

  const [tempConsequenceType, setTempConsequenceType] = useState("");

  const [newConsequenceType, setNewConsequenceType] = useState("");
  const [newConsequenceTypeName, setNewConsequenceTypeName] = useState("");
  const [newConsequenceTypeConfirm, setNewConsequenceTypeConfirm] =
    useState(false);

  const [systemvalues, setSystemvalues] = useState<Array<any>>([]);
  const [users, setUsers] = useState<Array<any>>([]);
  const [allConsequenceTypes, setAllConsequenceTypes] = useState<Array<any>>(
    []
  );
  const [existingConsequence, setExistingConsequence] = useState<Array<any>>(
    []
  );

  const [consequences, setConsequences] = useState([]);
  const [showLoadingConsequences, setShowLoadingConsequences] = useState(false);

  const [editTreatmentId, setEditTreatmentId] = useState();
  const [showEditTreatmentModal, setShowEditTreatmentModal] = useState(false);
  const [showNewConsequenceTypeModal, setShowNewConsequenceTypeModal] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const handleShowEditTreatmentModal = (object) => {
    setEditTreatmentId(object["_id"]);
    setShowEditTreatmentModal(true);
  };

  const labelNone = t("treatment.label_none");
  const labelLow = t("treatment.label_low");
  const labelMiddel = t("treatment.label_middel");
  const labelHigh = t("treatment.label_high");
  const labelExtremelyHigh = t("treatment.label_extremely_high");

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (!!localStorageUser) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  const handleCloseExistingConsequenceModal = () => {
    setCopyingChecked(false);
    fetchConsequences(props.occurenceId);
    setShowExistingConsequenceModal(false);
    if (!!props.onConsequencesChanged) {
      props.onConsequencesChanged();
    }
  };

  const handleShowNewConsequenceModal = () => setShowNewConsequenceModal(true);
  const handleShowExistingConsequenceModal = () =>
    setShowExistingConsequenceModal(true);
  const handleCloseDeleteConsequenceModal = () =>
    setShowDeleteConsequenceModal(false);
  const handleShowDeleteConsequenceModal = (evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    setDeleteConsequence(evt.target.getAttribute("data-consequenceid"));
    setShowDeleteConsequenceModal(true);
  };
  const handleCloseNewConsequenceModal = () => {
    setNewConsequenceType("");
    setNewConsequenceConsequenceValue("2");
    setNewConsequenceCostAvg("");
    setNewConsequenceCostMax("");
    setNewConsequenceCostMin("");
    setNewConsequenceDescription("");
    setNewConsequenceName("");
    setNewConsequenceSystemvalues([]);
    setShowNewConsequenceModal(false);
  };
  const handleCloseEditConsequenceModal = () =>
    setShowEditConsequenceModal(false);

  const handleShowEditConsequenceModal = (evt) => {
    if (evt.target.getAttribute("data-consequenceid") != undefined) {
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
      let currentConsequence = consequences.filter(
        (item) => item["_id"] == evt.target.getAttribute("data-consequenceid")
      )[0];
      if (
        currentConsequence["category"] != "" &&
        currentConsequence["category"] != undefined &&
        allConsequenceTypes.filter(
          (c) => c._id == currentConsequence["category"]
        ).length == 0
      ) {
        setTempConsequenceType(currentConsequence["category"]);
      } else {
        setTempConsequenceType("");
      }

      setEditConsequence(
        consequences.filter(
          (item) => item["_id"] == evt.target.getAttribute("data-consequenceid")
        )[0]
      );
      setShowEditConsequenceModal(true);
    }
  };

  const setEditConsequenceName = (value) => {
    setEditConsequence((editConsequence) => ({
      ...editConsequence,
      name: value,
    }));
  };

  const setEditConsequenceOccurence = (value) => {
    setEditConsequence((editConsequence) => ({
      ...editConsequence,
      occurence: value,
    }));
  };

  const setEditConsequenceOccurences = (value) => {
    if (value.length == 0) {
      setEditConsequenceOccurence("");
    }
    setEditConsequence((editConsequence) => ({
      ...editConsequence,
      occurences: value,
    }));
  };

  const setEditConsequenceSystemvalue = (key, value) => {
    let temp = editConsequence;
    editConsequence["systemvalue"][key]["id"] = value;
    setEditConsequence(JSON.parse(JSON.stringify(temp)));
  };

  const setEditConsequenceSystemvalueKit = (key, value) => {
    let temp = editConsequence;
    editConsequence["systemvalue"][key]["kit"] = value;
    setEditConsequence(JSON.parse(JSON.stringify(temp)));
  };

  const setEditConsequenceConsequenceValue = (value) => {
    setEditConsequence((editConsequence) => ({
      ...editConsequence,
      consequenceValue: value,
    }));
  };

  const setEditConsequenceDescription = (value) => {
    setEditConsequence((editConsequence) => ({
      ...editConsequence,
      description: value,
    }));
  };

  const setEditConsequenceCostMin = (value) => {
    setEditConsequence((editConsequence) => ({
      ...editConsequence,
      costMin: value,
    }));
  };

  const setEditConsequenceCostMax = (value) => {
    setEditConsequence((editConsequence) => ({
      ...editConsequence,
      costMax: value,
    }));
  };

  const setEditConsequenceCostAvg = (value) => {
    setEditConsequence((editConsequence) => ({
      ...editConsequence,
      costAvg: value,
    }));
  };

  const newConsequenceNameChange = (evt) => {
    setNewConsequenceName(evt.target.value);
  };

  function valueToPercentage(value, maxvalue) {
    return ((value - 1) / (maxvalue - 1)) * 100;
  }

  function percentageToValue(percentage, maxvalue) {
    return Math.round((maxvalue - 1) * (percentage / 100) + 1);
  }

  function fetchConsequences(occurenceid) {
    setConsequences([]);
    setShowLoadingConsequences(true);
    axios
      .single({
        method: "get",
        url: generateUrl("/api/cra/consequences/bysystem/") + systemid,
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        res.data.forEach((consequence) => {
          consequence["consequenceValue"] = percentageToValue(
            consequence["consequenceValue"],
            settings["gridY"]
          );
          if (
            (consequence.occurences == undefined ||
              consequence.occurences.length == 0) &&
            consequence.occurence != ""
          ) {
            consequence.occurences = [
              {
                value: consequence.occurence,
                label: props.allOccurences.find(
                  (i) => i._id == consequence.occurence
                )?.name,
              },
            ];
          }
        });
        if (occurenceid != 0 && occurenceid != 999999) {
          setConsequences(
            res.data.filter((item) =>
              item.occurences.map((o) => o.value).includes(occurenceid)
            )
          );
        }
        if (occurenceid == 0) {
          setConsequences(res.data);
        }
        if (occurenceid == 999999) {
          setConsequences(
            res.data.filter((item) => item.occurences.length == 0)
          );
        }
        setShowLoadingConsequences(false);
      });
  }

  const changeNewConsequenceSystemvalue = (key, value) => {
    let temp = newConsequenceSystemvalues;
    (temp[key]["id"] as any) = value;
    setNewConsequenceSystemvalues(JSON.parse(JSON.stringify(temp)));
  };

  const changeNewConsequenceSystemvalueKit = (key, value) => {
    let temp = newConsequenceSystemvalues;
    (temp[key]["kit"] as any) = value;
    setNewConsequenceSystemvalues(JSON.parse(JSON.stringify(temp)));
  };

  const addNewConsequenceSystemvalue = () => {
    let temp = newConsequenceSystemvalues;
    (temp as Array<any>).push({ id: "", kit: "" });
    setNewConsequenceSystemvalues(JSON.parse(JSON.stringify(temp)));
  };

  const deleteNewConsequenceSystemvalue = (key) => {
    let temp = newConsequenceSystemvalues;
    temp.splice(key, 1);
    setNewConsequenceSystemvalues(JSON.parse(JSON.stringify(temp)));
  };

  const addEditConsequenceSystemvalue = () => {
    let temp = editConsequence;
    temp["systemvalue"].push({ id: "", kit: "" });
    setEditConsequence(JSON.parse(JSON.stringify(temp)));
  };

  const deleteEditConsequenceSystemvalue = (key) => {
    let temp = editConsequence;
    temp["systemvalue"].splice(key, 1);
    setEditConsequence(JSON.parse(JSON.stringify(temp)));
  };

  const handleKeyDownConsequenceCreate = (event) => {
    if (event.key === "Enter") {
      handleCreateConsequence();
    }
  };

  const handleKeyDownConsequenceEdit = (event) => {
    if (event.key === "Enter") {
      handleEditConsequence();
    }
  };

  const newConsequenceConsequenceValueChange = (evt) => {
    setNewConsequenceConsequenceValue(evt.target.value);
  };

  const handleCreateConsequenceType = () => {
    if (newConsequenceTypeName == "") {
      notifyWarning(t("bowtie.toast_empty_name"));
      return undefined;
    }
    if (newConsequenceTypeName.length < 3) {
      notifyWarning(t("bowtie.toast_length"));
      return undefined;
    }

    axios
      .post(
        generateUrl("/api/cra/consequencetypes/"),
        {
          name: newConsequenceTypeName,
          grouping: "",
          tags: [],
          status: "requested",
          systemId: systemid,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        fetchConsequenceTypes();
        notifySuccess(t("bowtie.toast_consequence_created"));
        setShowNewConsequenceTypeModal(false);
        setNewConsequenceTypeName("");
      });
  };

  const handleCreateConsequence = () => {
    if (!newConsequenceType) {
      notifyWarning(t("bowtie.toast_empty_consequencetype"));
      return undefined;
    }
    axios
      .post(
        generateUrl("/api/cra/consequences/"),
        {
          name: newConsequenceName,
          category: newConsequenceType,
          system: systemid,
          occurence:
            props.occurenceId != "999999" && props.occurenceId != ""
              ? props.occurenceId
              : "",
          occurences:
            props.occurenceId != "999999" && props.occurenceId != ""
              ? [
                  {
                    value: props.occurenceId,
                    label: props.allOccurences.find(
                      (i) => i._id == props.occurenceId
                    )?.name,
                  },
                ]
              : [],
          costMin: newConsequenceCostMin,
          costMax: newConsequenceCostMax,
          costAvg: newConsequenceCostAvg,
          systemvalue: newConsequenceSystemvalues,
          consequenceValue: valueToPercentage(
            newConsequenceConsequenceValue,
            settings["gridY"]
          ),
          description: newConsequenceDescription,
          tags: [],
          text: "",
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchConsequences(props.occurenceId);
        notifySuccess(t("bowtie.toast_consequence_created"));
        setShowNewConsequenceModal(false);
        setNewConsequenceConsequenceValue("2");
        setNewConsequenceCostAvg("");
        setNewConsequenceCostMax("");
        setNewConsequenceCostMin("");
        setNewConsequenceDescription("");
        setNewConsequenceName("");
        setNewConsequenceSystemvalues([]);

        if (props.onConsequencesChanged != undefined) {
          props.onConsequencesChanged();
        }
      });
  };

  const handleDeleteConsequence = (evt) => {
    axios
      .delete(
        generateUrl("/api/cra/consequences/") + deleteConsequence,
        {
          _id: deleteConsequence,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchConsequences(props.occurenceId);
        notifySuccess(t("bowtie.toast_consequence_deleted"));
        setShowDeleteConsequenceModal(false);
        if (props.onConsequencesChanged != undefined) {
          props.onConsequencesChanged();
        }
      });
  };

  const handleEditConsequence = () => {
    if (
      editConsequence["category"] == "" ||
      editConsequence["category"] == undefined
    ) {
      notifyWarning(t("bowtie.toast_empty_consequencetype"));
      return undefined;
    }

    axios
      .put(
        generateUrl("/api/cra/consequences/") + editConsequence["_id"],
        {
          name: editConsequence["name"],
          category: editConsequence["category"],
          occurence: editConsequence["occurence"],
          occurences: editConsequence["occurences"],
          systemvalue: editConsequence["systemvalue"],
          consequenceValue: valueToPercentage(
            editConsequence["consequenceValue"],
            settings["gridY"]
          ),
          costMin: editConsequence["costMin"],
          costMax: editConsequence["costMax"],
          costAvg: editConsequence["costAvg"],
          description: editConsequence["description"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchConsequences(props.occurenceId);
        setShowEditConsequenceModal(false);
        if (props.onConsequencesChanged != undefined) {
          props.onConsequencesChanged();
        }
      });
  };

  function handleAttachExisting(consequence) {
    if (
      (consequence.occurences == undefined ||
        consequence.occurences.length == 0) &&
      consequence.occurence != ""
    ) {
      consequence.occurences = [
        {
          value: consequence.occurence,
          label: props.allOccurences.find((i) => i._id == consequence.occurence)
            ?.name,
        },
      ];
    }

    axios
      .put(
        generateUrl("/api/cra/consequences/") + consequence["_id"],
        {
          name: consequence["name"],
          category:
            consequence["category"] != undefined ? consequence["category"] : "",
          occurence: consequence["occurence"],
          occurences: [
            ...consequence["occurences"].filter(
              (o) => o.value != props.occurenceId
            ),
            {
              value: props.occurenceId,
              label: props.allOccurences.find((o) => o._id == props.occurenceId)
                ?.name,
            },
          ],
          systemvalue: consequence["systemvalue"],
          consequenceValue: consequence["consequenceValue"],
          costMin: consequence["costMin"],
          costMax: consequence["costMax"],
          costAvg: consequence["costAvg"],
          description: consequence["description"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchConsequences(props.occurenceId);
        handleCloseExistingConsequenceModal();
        if (props.onConsequencesChanged != undefined) {
          props.onConsequencesChanged();
        }
      });
  }

  const getLastAddedConsequence = (consequence) => {
    return existingConsequence.length
      ? existingConsequence[existingConsequence.length - 1]["_id"]
      : consequence["_id"];
  };

  const isConsequenceSelected = (id) => {
    return existingConsequence.some((e) => e["_id"] === id);
  };

  const addOrRemove = (consequence: any) => {
    if (!isConsequenceSelected(consequence["_id"]))
      setExistingConsequence([...existingConsequence, consequence]);
  };

  console.log(existingConsequence);

  function handleCopyExisting(consequence, withTreatments) {
    axios
      .post(
        generateUrl("/api/cra/consequences/"),
        {
          name: consequence["name"],
          category:
            consequence["category"] != undefined ? consequence["category"] : "",
          system: systemid,
          occurence: props.occurenceId,
          occurences: [
            {
              value: props.occurenceId,
              label: props.allOccurences.find((o) => o._id == props.occurenceId)
                ?.name,
            },
          ],
          systemvalue: [],
          consequenceValue: consequence["consequenceValue"],
          costMin: consequence["costMin"],
          costMax: consequence["costMax"],
          costAvg: consequence["costAvg"],
          description: consequence["description"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (res) {
        notifySuccess(t("bowtie.toast_consequence_created"));
        addOrRemove({
          ...consequence,
          newConsequence: res.data["_id"],
          treatmentCopied: withTreatments,
        });
        setLoading(false);

        if (withTreatments) {
          handleAddTreatmentConnection(
            consequence.treatments.map((e) => e),
            res.data["_id"]
          );
        }
      });
  }

  const handleAddTreatmentConnection = (selectedTreatments, consequenceId) => {
    setLoading(true);
    axios
      .post(
        generateUrl("/api/cra/treatments/addToConsequence"),
        {
          treatments: selectedTreatments,
          consequences: [consequenceId],
          system: systemid,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((_) => {
        setLoading(false);
        notifySuccess(t("treatments.toast.treatment_edited"));
      });
  };

  function fetchTreatmentsByConsequence(consequence) {
    axios
      .single({
        method: "get",
        url:
          generateUrl("/api/cra/treatments/byconsequence/") +
          consequence["_id"],
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        if (!!res.data.length) {
          handleAddTreatmentConnection(
            res.data.map((e) => e["_id"]),
            existingConsequence.filter(
              (e) => e["_id"] === consequence["_id"]
            )[0].newConsequence
          );

          const updateExistingConsequence = [...existingConsequence];
          const idx = updateExistingConsequence.findIndex(
            (e) => e["_id"] === consequence["_id"]
          );
          updateExistingConsequence[idx].treatmentCopied = true;
          addOrRemove(updateExistingConsequence);
        }
      });
  }

  async function fetchSystemvalues() {
    await axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systemvalues/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setSystemvalues(res[0].data);
        })
      );
  }

  async function fetchUsers() {
    axios
      .single({
        method: "post",
        url: generateUrl("/api/users/names"),
        data: props.allTreatments
          .map((i) => i.responsible)
          .filter((i) => i?.length == 24 ?? null),
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        setUsers(res.data);
      });
  }

  async function fetchConsequenceTypes() {
    axios
      .single({
        method: "get",
        url: generateUrl("/api/cra/consequencetypes/bysystem/") + systemid,
        responseType: "json",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        setAllConsequenceTypes(res.data);
      });
  }

  function toggleCopyingChecked(evt) {
    setCopyingChecked(!copyingChecked);
  }

  function readableProbability(probability) {
    if (probability <= 5) {
      return labelNone;
    } else if (probability > 5 && probability <= 25) {
      return labelLow;
    } else if (probability > 25 && probability <= 50) {
      return labelMiddel;
    } else if (probability > 50 && probability <= 75) {
      return labelHigh;
    } else if (probability > 75) {
      return labelExtremelyHigh;
    }
  }

  useEffect(() => {
    fetchUsers();
  }, [props.allTreatments]);

  useEffect(() => {
    fetchConsequences(props.occurenceId);
    fetchSystemvalues();
  }, [props.occurenceId, props.allOccurences]);

  useEffect(() => {
    fetchConsequenceTypes();
  }, []);

  useEffect(() => {
    if (props.showConsequenceModal) handleShowNewConsequenceModal();
  }, [props.showConsequenceModal]);

  return (
    <div className="bowtieconsequencelist">
      <div className="listheader">
        <h3>{t("bowtie.consequence_title")}</h3>
        <Button
          className="newConsequenceBtn btn-with-icon"
          disabled={!props.system["accesses"]?.includes("edit") ?? true}
          onClick={handleShowNewConsequenceModal}
        >
          <i className="dripicons-plus" />
          {t("bowtie.consequence_new")}
        </Button>

        <Button
          className="newConsequenceBtn btn-with-icon"
          disabled={
            !props.system["accesses"]?.includes("edit") ||
            (props.occurenceId !== 999999 && !props.occurenceId)
          }
          style={{ marginLeft: "10px" }}
          onClick={handleShowExistingConsequenceModal}
        >
          <i className="dripicons-plus" />
          {t("bowtie.consequence_existing")}
        </Button>
      </div>
      <ListGroup>
        {consequences.map((item, key) => {
          let probability =
            item["consequenceValue"] -
            props.allTreatments
              .filter(
                (treatment) =>
                  treatment["consequence"].includes(item["_id"]) &&
                  treatment["status"] == "closed"
              )
              .map((treatment) => treatment["probabilityreduction"])
              .reduce((total, number) => total + number, 0);
          return (
            <div className="bowtie-list-item" key={key}>
              <ListGroup.Item
                key={key}
                data-consequenceid={item["_id"]}
                onClick={handleShowEditConsequenceModal}
              >
                <p className="title" data-consequenceid={item["_id"]}>
                  {item["name"] == undefined || item["name"] == ""
                    ? td(
                        allConsequenceTypes.find(
                          (i) => i._id == item["category"]
                        )?.name
                      ) ?? item["category"]
                    : item["name"]}
                </p>
                <div
                  className="treatmentstatuscontainer"
                  data-consequenceid={item["_id"]}
                >
                  <div
                    className="statusindicator "
                    data-consequenceid={item["_id"]}
                  >
                    <div
                      className={"statusindicator"}
                      data-causeid={item["_id"]}
                    >
                      {settings["gridX"] == 3 ? (
                        <div>
                          {" "}
                          {Math.round(probability) <= 0 ? (
                            <div>
                              <span className="probability1">&nbsp;</span>
                              <span>{td(settings["gridXName1"])}</span>
                            </div>
                          ) : Math.round(probability) == 1 ? (
                            <div>
                              <span className="probability1">&nbsp;</span>
                              <span>{td(settings["gridXName1"])}</span>
                            </div>
                          ) : Math.round(probability) == 2 ? (
                            <div>
                              <span className="probability3">&nbsp;</span>
                              <span>{td(settings["gridXName2"])}</span>
                            </div>
                          ) : Math.round(probability) == 3 ? (
                            <div>
                              <span className="probability6">&nbsp;</span>
                              <span>{td(settings["gridXName3"])}</span>
                            </div>
                          ) : null}{" "}
                        </div>
                      ) : settings["gridX"] == 4 ? (
                        <div>
                          {" "}
                          {Math.round(probability) <= 0 ? (
                            <div>
                              <span className="probability1">&nbsp;</span>
                              <span>{td(settings["gridXName1"])}</span>
                            </div>
                          ) : Math.round(probability) == 1 ? (
                            <div>
                              <span className="probability1">&nbsp;</span>
                              <span>{td(settings["gridXName1"])}</span>
                            </div>
                          ) : Math.round(probability) == 2 ? (
                            <div>
                              <span className="probability3">&nbsp;</span>
                              <span>{td(settings["gridXName2"])}</span>
                            </div>
                          ) : Math.round(probability) == 3 ? (
                            <div>
                              <span className="probability4">&nbsp;</span>
                              <span>{td(settings["gridXName3"])}</span>
                            </div>
                          ) : Math.round(probability) == 4 ? (
                            <div>
                              <span className="probability6">&nbsp;</span>
                              <span>{td(settings["gridXName4"])}</span>
                            </div>
                          ) : null}{" "}
                        </div>
                      ) : settings["gridX"] == 5 ? (
                        <div>
                          {" "}
                          {Math.round(probability) <= 0 ? (
                            <div>
                              <span className="probability1">&nbsp;</span>
                              <span>{td(settings["gridXName1"])}</span>
                            </div>
                          ) : Math.round(probability) == 1 ? (
                            <div>
                              <span className="probability1">&nbsp;</span>
                              <span>{td(settings["gridXName1"])}</span>
                            </div>
                          ) : Math.round(probability) == 2 ? (
                            <div>
                              <span className="probability3">&nbsp;</span>
                              <span>{td(settings["gridXName2"])}</span>
                            </div>
                          ) : Math.round(probability) == 3 ? (
                            <div>
                              <span className="probability4">&nbsp;</span>
                              <span>{td(settings["gridXName3"])}</span>
                            </div>
                          ) : Math.round(probability) == 4 ? (
                            <div>
                              <span className="probability5">&nbsp;</span>
                              <span>{td(settings["gridXName4"])}</span>
                            </div>
                          ) : Math.round(probability) == 5 ? (
                            <div>
                              <span className="probability6">&nbsp;</span>
                              <span>{td(settings["gridXName5"])}</span>
                            </div>
                          ) : null}{" "}
                        </div>
                      ) : settings["gridX"] == 6 ? (
                        <div>
                          {" "}
                          {Math.round(probability) <= 0 ? (
                            <div>
                              <span className="probability1">&nbsp;</span>
                              <span>{td(settings["gridXName1"])}</span>
                            </div>
                          ) : Math.round(probability) == 1 ? (
                            <div>
                              <span className="probability1">&nbsp;</span>
                              <span>{td(settings["gridXName1"])}</span>
                            </div>
                          ) : Math.round(probability) == 2 ? (
                            <div>
                              <span className="probability2">&nbsp;</span>
                              <span>{td(settings["gridXName2"])}</span>
                            </div>
                          ) : Math.round(probability) == 3 ? (
                            <div>
                              <span className="probability3">&nbsp;</span>
                              <span>{td(settings["gridXName3"])}</span>
                            </div>
                          ) : Math.round(probability) == 4 ? (
                            <div>
                              <span className="probability4">&nbsp;</span>
                              <span>{td(settings["gridXName4"])}</span>
                            </div>
                          ) : Math.round(probability) == 5 ? (
                            <div>
                              <span className="probability5">&nbsp;</span>
                              <span>{td(settings["gridXName5"])}</span>
                            </div>
                          ) : Math.round(probability) == 6 ? (
                            <div>
                              <span className="probability6">&nbsp;</span>
                              <span>{td(settings["gridXName6"])}</span>
                            </div>
                          ) : null}{" "}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div
                  className="treatmentcountcontainer"
                  data-consequenceid={item["_id"]}
                >
                  <div className="measure" data-consequenceid={item["_id"]}>
                    <div
                      className={
                        "ongoing " +
                        (props.allTreatments.filter(
                          (treatment) =>
                            treatment["consequence"] == item["_id"] &&
                            treatment["status"] == "processing"
                        ).length == 0
                          ? "zero"
                          : "")
                      }
                      data-consequenceid={item["_id"]}
                    >
                      <p>
                        {
                          props.allTreatments.filter(
                            (treatment) =>
                              treatment["consequence"] == item["_id"] &&
                              treatment["status"] == "processing"
                          ).length
                        }
                        /
                        {
                          props.allTreatments.filter((treatment) =>
                            treatment["consequence"].includes(item["_id"])
                          ).length
                        }
                      </p>
                      <div style={{ width: "70px" }}>
                        <Doughnut
                          redraw={false}
                          data={
                            props.allTreatments.filter(
                              (treatment) =>
                                treatment["consequence"] == item["_id"] &&
                                treatment["status"] == "processing"
                            ).length != 0
                              ? {
                                  datasets: [
                                    {
                                      data: [
                                        props.allTreatments.filter(
                                          (treatment) =>
                                            treatment["consequence"] ==
                                              item["_id"] &&
                                            treatment["status"] == "processing"
                                        ).length,
                                        props.allTreatments.filter(
                                          (treatment) =>
                                            treatment["consequence"].includes(
                                              item["_id"]
                                            )
                                        ).length -
                                          props.allTreatments.filter(
                                            (treatment) =>
                                              treatment["consequence"] ==
                                                item["_id"] &&
                                              treatment["status"] ==
                                                "processing"
                                          ).length,
                                      ],
                                      backgroundColor: [
                                        "rgb(91, 130, 102, 1)",
                                        "rgb(200, 200, 200, 1)",
                                      ],
                                    },
                                  ],
                                  labels: [
                                    "labelRegistered",
                                    "labelAssetevaluated",
                                  ],
                                }
                              : {
                                  datasets: [
                                    {
                                      data: [0, 1],
                                      backgroundColor: [
                                        "rgb(91, 130, 102, 1)",
                                        "rgb(200, 200, 200, 1)",
                                      ],
                                    },
                                  ],
                                  labels: [
                                    "labelRegistered",
                                    "labelAssetevaluated",
                                  ],
                                }
                          }
                          options={{
                            legend: {
                              display: false,
                            },

                            tooltips: {
                              enabled: false,
                            },
                            cutoutPercentage: 60,
                          }}
                        />
                      </div>
                    </div>
                    <p>
                      {t("dashboard.measures_ongoing_o")}
                      <br />
                      {t("dashboard.measures_ongoing_m")}
                    </p>
                  </div>
                  <div className="measure" data-consequenceid={item["_id"]}>
                    <div className="closed" data-consequenceid={item["_id"]}>
                      <p>
                        {
                          props.allTreatments.filter(
                            (treatment) =>
                              treatment["consequence"].includes(item["_id"]) &&
                              treatment["status"] == "closed"
                          ).length
                        }
                        /
                        {
                          props.allTreatments.filter((treatment) =>
                            treatment["consequence"].includes(item["_id"])
                          ).length
                        }
                      </p>
                      <div style={{ width: "70px" }}>
                        <Doughnut
                          redraw={false}
                          data={
                            props.allTreatments.filter(
                              (treatment) =>
                                treatment["consequence"].includes(
                                  item["_id"]
                                ) && treatment["status"] == "closed"
                            ).length != 0
                              ? {
                                  datasets: [
                                    {
                                      data: [
                                        props.allTreatments.filter(
                                          (treatment) =>
                                            treatment["consequence"].includes(
                                              item["_id"]
                                            ) && treatment["status"] == "closed"
                                        ).length,
                                        props.allTreatments.filter(
                                          (treatment) =>
                                            treatment["consequence"].includes(
                                              item["_id"]
                                            )
                                        ).length -
                                          props.allTreatments.filter(
                                            (treatment) =>
                                              treatment["consequence"].includes(
                                                item["_id"]
                                              ) &&
                                              treatment["status"] == "closed"
                                          ).length,
                                      ],
                                      backgroundColor: [
                                        "rgb(91, 130, 102, 1)",
                                        "rgb(200, 200, 200, 1)",
                                      ],
                                    },
                                  ],
                                  labels: [
                                    "labelRegistered",
                                    "labelAssetevaluated",
                                  ],
                                }
                              : {
                                  datasets: [
                                    {
                                      data: [0, 1],
                                      backgroundColor: [
                                        "rgb(91, 130, 102, 1)",
                                        "rgb(200, 200, 200, 1)",
                                      ],
                                    },
                                  ],
                                  labels: [
                                    "labelRegistered",
                                    "labelAssetevaluated",
                                  ],
                                }
                          }
                          options={{
                            legend: {
                              display: false,
                            },

                            tooltips: {
                              enabled: false,
                            },
                            cutoutPercentage: 60,
                          }}
                        />
                      </div>
                    </div>
                    <p>
                      {t("dashboard.measures_realized_r")}
                      <br />
                      {t("dashboard.measures_realized_m")}
                    </p>
                  </div>
                </div>
                <div
                  className="operationscontainer"
                  data-consequenceid={item["_id"]}
                >
                  <i
                    className="dripicons-trash tableOperationBtn"
                    data-consequenceid={item["_id"]}
                    onClick={(e) =>
                      props.system["accesses"].includes("edit")
                        ? handleShowDeleteConsequenceModal(e)
                        : null
                    }
                  />
                </div>
                <div className="chainconnector consequenceconnector">
                  <div className="chain-icon">
                    <Droppable droppableId={"consequence." + item["_id"]}>
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            position: "absolute",
                            left: "5px",
                            top: "5px",
                          }}
                        >
                          <Draggable
                            key={"consequence." + key}
                            draggableId={"consequence." + item["_id"]}
                            index={key}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className=""
                              >
                                {item["occurences"] == undefined ||
                                (item["occurences"] as Array<any>).length ==
                                  0 ? (
                                  <i className="dripicons-link-broken" />
                                ) : (item["occurences"] as Array<any>).length >
                                  1 ? (
                                  <React.Fragment>
                                    <i
                                      className="dripicons-link"
                                      style={{
                                        position: "absolute",
                                        left: "-2px",
                                        top: "13px",
                                        fontSize: "16px",
                                        zIndex: 101,
                                      }}
                                    />
                                    <i className="dripicons-link" />
                                  </React.Fragment>
                                ) : (
                                  <i className="dripicons-link" />
                                )}
                              </div>
                            )}
                          </Draggable>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                  <div className="chain-counter">
                    <p>{(item["occurences"] as Array<any>).length}</p>
                  </div>
                </div>
              </ListGroup.Item>
              <Accordion>
                <Accordion.Toggle eventKey="0" aria-label="expand">
                  <i className="dripicons-chevron-down" />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <div>
                    <p>
                      {t("bowtie.consequences.category")}:{" "}
                      {item["category"] != undefined
                        ? td(
                            allConsequenceTypes.find(
                              (i) => i._id == item["category"]
                            )?.name
                          ) ?? item["category"]
                        : null}
                    </p>
                    <p>
                      {t("bowtie.consequences.exposed_system_asset")}:{" "}
                      {(item["systemvalue"] as Array<any>)
                        .map((value) =>
                          systemvalues.find(
                            (systemvalue) => systemvalue._id == value.id
                          )
                        )
                        .map((value) => value?.name ?? "")
                        .join(", ")}
                    </p>
                    <p>
                      {t("bowtie.consequences.costAvg")}:{" "}
                      {item["costAvg"] != undefined
                        ? settings["currencySymbolBefore"] +
                          item["costAvg"] +
                          settings["currencySymbolAfter"]
                        : ""}
                    </p>
                    <hr />

                    {props.allTreatments != undefined
                      ? props.allTreatments
                          .filter(
                            (treatment) =>
                              treatment != null &&
                              treatment["consequence"].includes(item["_id"])
                          )
                          .map((treatment, i) => (
                            <>
                              <p className="treatments-title">
                                {t("bowtie.treatments.treatment")}:
                              </p>
                              <div
                                className="treatments-list"
                                key={i}
                                onClick={() =>
                                  handleShowEditTreatmentModal(treatment)
                                }
                              >
                                <div className="treatments-list-top-row">
                                  <p className="treatment-title">
                                    {t("bowtie.name")}: {treatment.name}
                                  </p>
                                  <p>
                                    {t(
                                      "bowtie.causes_measure_probability_reduction"
                                    )}
                                    : {readableProbability(treatment.oldvalue)}
                                  </p>
                                </div>
                                <p>
                                  {t("bowtie.measure_responsible")}:{" "}
                                  {treatment.responsible != undefined
                                    ? users.find(
                                        (user) =>
                                          user._id == treatment.responsible
                                      )?.name ?? treatment.responsible
                                    : treatment.responsible}
                                </p>
                                <p>
                                  {t("bowtie.status")}:{" "}
                                  {t("treatment." + treatment.status)}
                                </p>
                              </div>
                            </>
                          ))
                      : null}
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </div>
          );
        })}
      </ListGroup>
      {showLoadingConsequences ? (
        <h5 className="loading-text">{t("bowtie.consequence_load")} ..</h5>
      ) : (
        ""
      )}
      <Modal
        show={showEditConsequenceModal}
        onHide={handleCloseEditConsequenceModal}
        size="lg"
        onEntered={() => myRef.current.focus()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("bowtie.consequence_edit")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="treatments">
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>{t("bowtie.category")} *</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              disabled={!props.system["accesses"]?.includes("edit") ?? true}
              ref={myRef}
              as="select"
              onChange={(evt) => {
                let value = (evt.target as HTMLSelectElement).selectedOptions[0]
                  .value;
                setEditConsequence((editConsequence) => ({
                  ...editConsequence,
                  category: value,
                }));
              }}
              value={editConsequence["category"]}
            >
              <option value="">{t("bowtie.category_choose")}</option>
              {allConsequenceTypes.map((item, key) => (
                <option key={key} value={item._id}>
                  {td(item.name)}
                </option>
              ))}
              {tempConsequenceType != "" ? (
                <option disabled value={tempConsequenceType}>
                  {td(tempConsequenceType)}
                </option>
              ) : null}
            </Form.Control>
          </InputGroup>

          {props.system["accesses"]?.includes("edit") ?? false ? (
            <div>
              <p>{t("bowtie.consequence_consequencetype_notfound_text")}</p>
              <a
                className="textlink"
                onClick={() => setShowNewConsequenceTypeModal(true)}
              >
                {t("bowtie.consequence_consequencetype_notfound_click")}
              </a>
            </div>
          ) : null}
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>{t("bowtie.name")}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              disabled={!props.system["accesses"]?.includes("edit") ?? true}
              onKeyDown={handleKeyDownConsequenceEdit}
              onChange={(evt) => setEditConsequenceName(evt.target.value)}
              value={editConsequence["name"]}
            />
          </InputGroup>
          <Field
            disabled={!props.system["accesses"]?.includes("edit") ?? true}
            type="selectmulti"
            title={t("bowtie.event_new_name_consequence")}
            value={
              editConsequence["occurences"] != undefined
                ? editConsequence["occurences"].map((occ) => {
                    return {
                      value: occ.value,
                      label:
                        props.allOccurences.find(
                          (allOcc) => allOcc._id == occ.value
                        ) != undefined
                          ? props.allOccurences.find(
                              (allOcc) => allOcc._id == occ.value
                            ).name != "" &&
                            props.allOccurences.find(
                              (allOcc) => allOcc._id == occ.value
                            ).name != undefined
                            ? props.allOccurences.find(
                                (allOcc) => allOcc._id == occ.value
                              ).name
                            : td(
                                props.allOccurences.find(
                                  (allOcc) => allOcc._id == occ.value
                                ).occurenceTypeName
                              )
                          : "",
                    };
                  })
                : []
            }
            options={{
              choices: props.allOccurences.map((item) => {
                return {
                  label:
                    item.name != "" && item.name != undefined
                      ? item.name
                      : td(item.occurenceTypeName),
                  value: item._id,
                };
              }),
            }}
            onChange={(value) => setEditConsequenceOccurences(value)}
          ></Field>
          <hr />
          {props.nomenu == "true" ? null : (
            <div>
              {editConsequence["systemvalue"] != null
                ? editConsequence["systemvalue"].map(
                    (systemvalue, systemvalueKey) => (
                      <InputGroup
                        key={systemvalueKey}
                        className={
                          props.nomenu == "true"
                            ? "mb-3 systemvalueedit hidden"
                            : "mb-3 systemvalueedit"
                        }
                      >
                        <div>
                          <div>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {t("bowtie.systemvalue")}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              disabled={
                                !props.system["accesses"]?.includes("edit") ??
                                true
                              }
                              as="select"
                              onKeyDown={handleKeyDownConsequenceEdit}
                              onChange={(evt) =>
                                setEditConsequenceSystemvalue(
                                  systemvalueKey,
                                  (evt.target as HTMLSelectElement)
                                    .selectedOptions[0].value
                                )
                              }
                              value={systemvalue["id"]}
                            >
                              <option value="">
                                {t("bowtie.systemvalue_tie")}
                              </option>
                              {systemvalues.map((item, key) => {
                                return (
                                  <option key={key} value={item["_id"]}>
                                    {item["name"]}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                          <div>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {t("bowtie.systemvalue_kit")}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputGroup.Append>
                              <Form.Control
                                disabled={
                                  !props.system["accesses"]?.includes("edit") ??
                                  true
                                }
                                as="select"
                                onKeyDown={handleKeyDownConsequenceEdit}
                                onChange={(evt) =>
                                  setEditConsequenceSystemvalueKit(
                                    systemvalueKey,
                                    (evt.target as HTMLSelectElement)
                                      .selectedOptions[0].value
                                  )
                                }
                                value={systemvalue["kit"]}
                              >
                                <option value="">
                                  {t("bowtie.systemvalue_tie_kit")}
                                </option>
                                <option value="k">
                                  {t("system_asset.system_confidential")}
                                </option>
                                <option value="i">
                                  {t("system_asset.system_integrity")}
                                </option>
                                <option value="t">
                                  {t("system_asset.system_availability")}
                                </option>
                              </Form.Control>
                            </InputGroup.Append>
                          </div>
                          <div>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {t("bowtie.systemvalue_kitlevel")}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputGroup.Append>
                              <p>
                                {systemvalue["kit"] == "k" &&
                                systemvalues.find(
                                  (sv) => sv._id == systemvalue.id
                                ) != undefined
                                  ? td(
                                      settings[
                                        "systemvalueKLevel" +
                                          percentageToValue(
                                            systemvalues.find(
                                              (sv) => sv._id == systemvalue.id
                                            ).k,
                                            settings["systemvalueKNum"]
                                          )
                                      ]
                                    )
                                  : systemvalue["kit"] == "i" &&
                                    systemvalues.find(
                                      (sv) => sv._id == systemvalue.id
                                    ) != undefined
                                  ? td(
                                      settings[
                                        "systemvalueILevel" +
                                          percentageToValue(
                                            systemvalues.find(
                                              (sv) => sv._id == systemvalue.id
                                            ).i,
                                            settings["systemvalueINum"]
                                          )
                                      ]
                                    )
                                  : systemvalue["kit"] == "t" &&
                                    systemvalues.find(
                                      (sv) => sv._id == systemvalue.id
                                    ) != undefined
                                  ? td(
                                      settings[
                                        "systemvalueTLevel" +
                                          percentageToValue(
                                            systemvalues.find(
                                              (sv) => sv._id == systemvalue.id
                                            ).t,
                                            settings["systemvalueTNum"]
                                          )
                                      ]
                                    )
                                  : t("bowtie.systemvalue_kitnotselected")}
                              </p>
                            </InputGroup.Append>
                          </div>
                          <div>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {t("bowtie.systemvalue_delete")}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputGroup.Append>
                              <Button
                                disabled={
                                  !props.system["accesses"]?.includes("edit") ??
                                  true
                                }
                                onClick={(evt) =>
                                  deleteEditConsequenceSystemvalue(
                                    systemvalueKey
                                  )
                                }
                              >
                                {t(
                                  "bowtie.consequence_edit_delete_systemvalue_btn"
                                )}
                              </Button>
                            </InputGroup.Append>
                          </div>
                        </div>
                      </InputGroup>
                    )
                  )
                : null}
              <Button
                disabled={!props.system["accesses"]?.includes("edit") ?? true}
                onClick={addEditConsequenceSystemvalue}
              >
                {t("bowtie.consequence_edit_add_systemvalue_btn")}
              </Button>
              <hr />
            </div>
          )}
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                {t("bowtie.consequence_new_level")}
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              disabled={!props.system["accesses"]?.includes("edit") ?? true}
              as="select"
              onKeyDown={handleKeyDownConsequenceEdit}
              onChange={(evt) =>
                setEditConsequenceConsequenceValue(
                  (evt.target as HTMLSelectElement).value
                )
              }
              value={Math.round(editConsequence["consequenceValue"]).toString()}
            >
              {Number(settings["gridX"]) > 0 ? (
                <option value="1">{td(settings["gridXName1"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridX"]) > 1 ? (
                <option value="2">{td(settings["gridXName2"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridX"]) > 2 ? (
                <option value="3">{td(settings["gridXName3"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridX"]) > 3 ? (
                <option value="4">{td(settings["gridXName4"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridX"]) > 4 ? (
                <option value="5">{td(settings["gridXName5"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridX"]) > 5 ? (
                <option value="6">{td(settings["gridXName6"])}</option>
              ) : (
                ""
              )}
            </Form.Control>
          </InputGroup>
          <InputGroup className="mb-3">
            <Field
              disabled={!props.system["accesses"]?.includes("edit") ?? true}
              type="textarea"
              title={t("bowtie.description")}
              value={editConsequence["description"]}
              onChange={(value) => setEditConsequenceDescription(value)}
            ></Field>
          </InputGroup>
          <hr />
          <h4>{t("bowtie.costs")}</h4>
          <div className="row">
            <div className="col col-sm-4">
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{t("bowtie.costmin")}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  disabled={!props.system["accesses"]?.includes("edit") ?? true}
                  type="number"
                  onChange={(evt) =>
                    setEditConsequenceCostMin(evt.target.value)
                  }
                  value={editConsequence["costMin"]}
                />
              </InputGroup>
            </div>
            <div className="col col-sm-4">
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{t("bowtie.costmax")}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  disabled={!props.system["accesses"]?.includes("edit") ?? true}
                  type="number"
                  onChange={(evt) =>
                    setEditConsequenceCostMax(evt.target.value)
                  }
                  value={editConsequence["costMax"]}
                />
              </InputGroup>
            </div>
            <div className="col col-sm-4">
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{t("bowtie.costavg")}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  disabled={!props.system["accesses"]?.includes("edit") ?? true}
                  type="number"
                  onChange={(evt) =>
                    setEditConsequenceCostAvg(evt.target.value)
                  }
                  value={editConsequence["costAvg"]}
                />
              </InputGroup>
            </div>
          </div>
          <hr />
          <BowtieTreatmentHandler
            system={props.system}
            treatmentType="consequence"
            onTreatmentsChanged={() => {
              if (props.onConsequencesChanged != undefined) {
                props.onConsequencesChanged();
              }
            }}
            occurenceId={props.occurenceId}
            consequenceId={editConsequence["_id"]}
          ></BowtieTreatmentHandler>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={handleCloseEditConsequenceModal}
          >
            {t("system.system_new_cancel")}
          </Button>
          <Button
            disabled={!props.system["accesses"]?.includes("edit") ?? true}
            onClick={handleEditConsequence}
          >
            {t("bowtie.save")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeleteConsequenceModal}
        onHide={handleCloseDeleteConsequenceModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("bowtie.consequence_delete")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("bowtie.consequence_delete_info")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={handleCloseDeleteConsequenceModal}
          >
            {t("bowtie.cancel")}
          </Button>
          <Button onClick={handleDeleteConsequence}>
            {t("bowtie.delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showNewConsequenceModal}
        onHide={handleCloseNewConsequenceModal}
        size="lg"
        onEntered={() => myRef.current.focus()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("bowtie.consequence_register")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>{t("bowtie.category")} *</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              ref={myRef}
              as="select"
              onChange={(evt) =>
                setNewConsequenceType(
                  (evt.target as HTMLSelectElement).selectedOptions[0].value
                )
              }
              value={newConsequenceType}
            >
              <option value="">{t("bowtie.category_choose")}</option>
              {allConsequenceTypes.map((item, key) => (
                <option key={key} value={item._id}>
                  {td(item.name)}
                </option>
              ))}
            </Form.Control>
          </InputGroup>
          <p>{t("bowtie.consequence_consequencetype_notfound_text")}</p>
          <a
            className="textlink"
            onClick={() => setShowNewConsequenceTypeModal(true)}
          >
            {t("bowtie.consequence_consequencetype_notfound_click")}
          </a>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                {t("bowtie.consequence_new_name")}
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              onKeyDown={newConsequenceNameChange}
              onChange={newConsequenceNameChange}
            />
          </InputGroup>
          <div className="row">
            <div
              className={
                props.nomenu == "true" ? "col-sm-12 hidden" : "col-sm-12"
              }
            >
              <hr />
              {newConsequenceSystemvalues.map((systemvalue, systemvalueKey) => (
                <InputGroup
                  key={systemvalueKey}
                  className={
                    props.nomenu == "true"
                      ? "mb-3 systemvalueedit hidden"
                      : "mb-3 systemvalueedit"
                  }
                >
                  <div>
                    <div>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          {t("bowtie.systemvalue")}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        as="select"
                        onKeyDown={handleKeyDownConsequenceCreate}
                        onChange={(evt) =>
                          changeNewConsequenceSystemvalue(
                            systemvalueKey,
                            (evt.target as HTMLSelectElement).selectedOptions[0]
                              .value
                          )
                        }
                        value={systemvalue["id"]}
                      >
                        <option value="">{t("bowtie.systemvalue_tie")}</option>
                        {systemvalues.map((item, key) => {
                          return (
                            <option key={key} value={item["_id"]}>
                              {item["name"]}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </div>
                    <div>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          {t("bowtie.systemvalue_kit")}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <InputGroup.Append>
                        <Form.Control
                          as="select"
                          onKeyDown={handleKeyDownConsequenceCreate}
                          onChange={(evt) =>
                            changeNewConsequenceSystemvalueKit(
                              systemvalueKey,
                              (evt.target as HTMLSelectElement)
                                .selectedOptions[0].value
                            )
                          }
                          value={systemvalue["kit"]}
                        >
                          <option value="">
                            {t("bowtie.systemvalue_tie_kit")}
                          </option>
                          <option value="k">
                            {t("system_asset.system_confidential")}
                          </option>
                          <option value="i">
                            {t("system_asset.system_integrity")}
                          </option>
                          <option value="t">
                            {t("system_asset.system_availability")}
                          </option>
                        </Form.Control>
                      </InputGroup.Append>
                    </div>
                    <div>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          {t("bowtie.systemvalue_kitlevel")}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <InputGroup.Append>
                        <p>
                          {systemvalue["kit"] == "k" &&
                          systemvalues.find((sv) => sv._id == systemvalue.id) !=
                            undefined
                            ? td(
                                settings[
                                  "systemvalueKLevel" +
                                    percentageToValue(
                                      systemvalues.find(
                                        (sv) => sv._id == systemvalue.id
                                      ).k,
                                      settings["systemvalueKNum"]
                                    )
                                ]
                              )
                            : systemvalue["kit"] == "i" &&
                              systemvalues.find(
                                (sv) => sv._id == systemvalue.id
                              ) != undefined
                            ? td(
                                settings[
                                  "systemvalueILevel" +
                                    percentageToValue(
                                      systemvalues.find(
                                        (sv) => sv._id == systemvalue.id
                                      ).i,
                                      settings["systemvalueINum"]
                                    )
                                ]
                              )
                            : systemvalue["kit"] == "t" &&
                              systemvalues.find(
                                (sv) => sv._id == systemvalue.id
                              ) != undefined
                            ? td(
                                settings[
                                  "systemvalueTLevel" +
                                    percentageToValue(
                                      systemvalues.find(
                                        (sv) => sv._id == systemvalue.id
                                      ).t,
                                      settings["systemvalueTNum"]
                                    )
                                ]
                              )
                            : t("bowtie.systemvalue_kitnotselected")}
                        </p>
                      </InputGroup.Append>
                    </div>
                    <div>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          {t("bowtie.systemvalue_delete")}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <InputGroup.Append>
                        <Button
                          onClick={(evt) =>
                            deleteNewConsequenceSystemvalue(systemvalueKey)
                          }
                        >
                          {t("bowtie.consequence_edit_delete_systemvalue_btn")}
                        </Button>
                      </InputGroup.Append>
                    </div>
                  </div>
                </InputGroup>
              ))}
              <Button onClick={addNewConsequenceSystemvalue}>
                {t("bowtie.event_new_systemvalue_add")}
              </Button>
              <hr />
            </div>
          </div>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                {t("bowtie.consequence_new_level")}
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              as="select"
              onKeyDown={handleKeyDownConsequenceCreate}
              onChange={newConsequenceConsequenceValueChange}
              value={newConsequenceConsequenceValue}
            >
              {Number(settings["gridX"]) > 0 ? (
                <option value="1">{td(settings["gridXName1"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridX"]) > 1 ? (
                <option value="2">{td(settings["gridXName2"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridX"]) > 2 ? (
                <option value="3">{td(settings["gridXName3"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridX"]) > 3 ? (
                <option value="4">{td(settings["gridXName4"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridX"]) > 4 ? (
                <option value="5">{td(settings["gridXName5"])}</option>
              ) : (
                ""
              )}
              {Number(settings["gridX"]) > 5 ? (
                <option value="6">{td(settings["gridXName6"])}</option>
              ) : (
                ""
              )}
            </Form.Control>
          </InputGroup>
          <InputGroup className="mb-3">
            <Field
              type="textarea"
              title={t("bowtie.description")}
              value={newConsequenceDescription}
              onChange={(value) => setNewConsequenceDescription(value)}
            ></Field>
          </InputGroup>
          <hr />
          <h4>{t("bowtie.costs")}</h4>
          <div className="row">
            <div className="col col-sm-4">
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{t("bowtie.costmin")}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="number"
                  style={{ flex: "unset" }}
                  onChange={(evt) => setNewConsequenceCostMin(evt.target.value)}
                  value={newConsequenceCostMin}
                />
              </InputGroup>
            </div>
            <div className="col col-sm-4">
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{t("bowtie.costmax")}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="number"
                  style={{ flex: "unset" }}
                  onChange={(evt) => setNewConsequenceCostMax(evt.target.value)}
                  value={newConsequenceCostMax}
                />
              </InputGroup>
            </div>
            <div className="col col-sm-4">
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{t("bowtie.costavg")}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="number"
                  style={{ flex: "unset" }}
                  onChange={(evt) => setNewConsequenceCostAvg(evt.target.value)}
                  value={newConsequenceCostAvg}
                />
              </InputGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={handleCloseNewConsequenceModal}
          >
            {t("system.system_new_cancel")}
          </Button>
          <Button onClick={handleCreateConsequence}>{t("bowtie.save")}</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showExistingConsequenceModal}
        size="lg"
        onHide={handleCloseExistingConsequenceModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("bowtie.consequence_existing")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="riskMatrixToggle"
            style={{
              float: "none",
              marginTop: "0px",
              width: "fit-content",
            }}
          >
            <FormGroup>
              <Tooltip
                title={t("bowtie.consequence_existing_toggle_tooltip")}
                placement="top"
              >
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    {t("bowtie.consequence_existing_toggle_link")}
                  </Grid>
                  <Grid item>
                    <Switch
                      size="small"
                      checked={copyingChecked}
                      onChange={toggleCopyingChecked}
                      color="primary"
                      className={"toggleswitch"}
                    />
                  </Grid>
                  <Grid item>
                    {t("bowtie.consequence_existing_toggle_copy")}
                  </Grid>
                </Grid>
              </Tooltip>
            </FormGroup>
          </div>
          <div
            id="consequenceTable"
            key={
              !copyingChecked
                ? generateUrl("/api/cra/consequences/getAllPaged/bysystem/") +
                  systemid
                : generateUrl(
                    "/api/cra/consequences/getAllPaged/withTreatments"
                  )
            }
          >
            <PageTable
              dataUrl={
                !copyingChecked
                  ? generateUrl("/api/cra/consequences/getAllPaged/bysystem/") +
                    systemid
                  : generateUrl(
                      "/api/cra/consequences/getAllPaged/withTreatments"
                    )
              }
              options={{
                pageSize: 10,
                showFields: [
                  {
                    label: t("bowtie.consequences.existing.field.consequence"),
                    field: "consequence",
                  },
                  {
                    label: t("bowtie.consequences.existing.field.system"),
                    field: "systemReadable",
                  },
                  {
                    label: t("bowtie.consequences.existing.field.occurence"),
                    field: "occurenceReadable",
                  },
                  {
                    label: t("bowtie.consequences.existing.field.description"),
                    field: "description",
                  },
                ],
              }}
              dataAfter={(dataLine) =>
                !copyingChecked ? (
                  <Button
                    style={{ width: "130px" }}
                    onClick={() => handleAttachExisting(dataLine)}
                  >
                    {t("bowtie.causes.existing.attach")}
                  </Button>
                ) : (
                  <div className="d-flex ml-auto">
                    {!isConsequenceSelected(dataLine["_id"]) && (
                      <Button
                        className="m-1"
                        onClick={() => handleCopyExisting(dataLine, false)}
                        disabled={loading}
                      >
                        {loading &&
                          getLastAddedConsequence(dataLine["_id"]) ===
                            dataLine["_id"] && (
                            <Spinner
                              as="span"
                              variant="primary"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              animation="border"
                            />
                          )}
                        {t("bowtie.causes.existing.copy")}
                      </Button>
                    )}
                    {dataLine.treatments &&
                      (isConsequenceSelected(dataLine["_id"])
                        ? !existingConsequence.filter(
                            (e) => e["_id"] === dataLine["_id"]
                          )[0].treatmentCopied
                        : true) && (
                        <Button
                          className="m-1"
                          onClick={() => {
                            if (!isConsequenceSelected(dataLine["_id"])) {
                              handleCopyExisting(dataLine, true);
                            } else {
                              fetchTreatmentsByConsequence(dataLine);
                            }
                          }}
                          disabled={loading}
                        >
                          {loading &&
                            getLastAddedConsequence(dataLine["_id"]) ===
                              dataLine["_id"] && (
                              <Spinner
                                as="span"
                                variant="primary"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                              />
                            )}
                          {isConsequenceSelected(dataLine["_id"]) &&
                          !existingConsequence.filter(
                            (e) => e["_id"] === dataLine["_id"]
                          )[0].treatmentCopied
                            ? t("bowtie.add_treatments")
                            : `${t("bowtie.causes.existing.copy")} ${t(
                                "bowtie.with_treatments"
                              )}`}
                        </Button>
                      )}
                  </div>
                )
              }
              loadingText={t("systems.loading")}
              hover={false}
              hidePagination={false}
              hideFilter={false}
              defaultSortField="name"
              defaultSortDirection="asc"
              selectedItems={existingConsequence}
            ></PageTable>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseExistingConsequenceModal}>
            {t("diri.closebtn")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showNewConsequenceTypeModal}
        onHide={() => setShowNewConsequenceTypeModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("bowtie.consequencetype.new_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="alert red">
            {t("bowtie.consequencetype.disclaimer01")}
          </p>
          <p>{t("bowtie.consequencetype.disclaimer02")}</p>
          <div className="widecheckbox">
            <Field
              type="checkbox"
              title={t("bowtie.consequencetype.disclaimer03")}
              value={newConsequenceTypeConfirm}
              onChange={(value) => setNewConsequenceTypeConfirm(value)}
            ></Field>
          </div>
          <div style={{ height: "80px" }}>
            <Field
              title={t("bowtie.consequencetype.name") + " *"}
              type="textlanguage"
              value={newConsequenceTypeName}
              onChange={(value) => setNewConsequenceTypeName(value)}
              options={{
                category: "settings.typencategory.consequence",
              }}
              disabled={!newConsequenceTypeConfirm}
            ></Field>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={() => setShowNewConsequenceTypeModal(false)}
          >
            {t("bowtie.cancel")}
          </Button>
          <Button
            disabled={!newConsequenceTypeConfirm}
            onClick={handleCreateConsequenceType}
          >
            {t("bowtie.save")}
          </Button>
        </Modal.Footer>
      </Modal>
      <TreatmentEditorModal
        show={showEditTreatmentModal}
        onSave={() => {
          setShowEditTreatmentModal(false);
          fetchConsequences(props.occurenceId);
          if (props.onConsequencesChanged != undefined) {
            props.onConsequencesChanged();
          }
        }}
        onHide={() => {
          setShowEditTreatmentModal(false);
          setEditTreatmentId(undefined);
        }}
        onCancel={() => {
          setShowEditTreatmentModal(false);
          setEditTreatmentId(undefined);
        }}
        treatmentId={editTreatmentId}
      ></TreatmentEditorModal>
    </div>
  );
}

export default BowtieConsequenceList;
