import React, { useEffect, useState } from "react";

import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";

import { useDynamicLanguage } from "../../../DynamicLanguageProvider";

import { generateUrl } from "../../../config";

function TextDynamic(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  let userdata = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
    userdata = JSON.parse(localStorageUser)["userdata"];
  }

  const [language, setLanguage] = useState(useDynamicLanguage().language);
  const [dynamicText, setDynamicText] = useState("");
  const [loading, setLoading] = useState(false);

  async function asynctd(textId) {
    setLoading(true);
    setDynamicText(textId);

    if (textId != undefined && textId.length == 24 && !textId.includes(" ")) {
      axios
        .all([
          axios.single({
            method: "get",
            url: generateUrl("/api/cra/languages/public/") + textId,
            responseType: "json",
            headers: { "x-access-token": auth.user["token"] },
          }),
        ])
        .then(
          axios.spread((...res) => {
            let foundText = res[0].data;
            setDynamicText(
              foundText?.texts[language] ??
                foundText?.term ??
                "No matching text"
            );
            setLoading(false);
          })
        );
    } else {
      setDynamicText(textId);
      setLoading(false);
    }
  }

  useEffect(() => {
    asynctd(props.id);
  }, []);

  useEffect(() => {
    if (props.locale != undefined) {
      setLanguage(props.locale);
    }
  }, [props.locale]);

  useEffect(() => {
    asynctd(props.id);
  }, [language]);

  return <>{loading ? "Loading..." : dynamicText}</>;
}

export default TextDynamic;
