import React, { useState, useEffect, useContext, createContext } from "react";

import { useAxios } from './AxiosHandler';
import { useAuth } from "./Auth";
import { generateUrl } from "./config";

const dynamicLanguageContext = createContext({
	language: "",
	texts: [] as Array<any>,
    td: (textId) => "",
    hastd: (textId) => true as boolean,
    loadTexts: (user?) => {},
    setLanguage: (language) => {}
});

export function ProvideDynamicLanguage({ children, locale }) {
	const td = useProvideDynamicLanguage(locale);
	return <dynamicLanguageContext.Provider value={td}>{children}</dynamicLanguageContext.Provider>;
}

export const useDynamicLanguage = () => {
  	return useContext(dynamicLanguageContext);
};

function useProvideDynamicLanguage(locale) {
	const auth = useAuth();
    const axiosHandler = useAxios();
	const axios = axiosHandler.axios;

    const localStorageUser = localStorage.getItem("user");
    let user = {};
    if (localStorageUser != null) {
        user = JSON.parse(localStorageUser);
    }
    
	const [language, setLanguage] = useState(locale);
	const [texts, setTexts] = useState<Array<any>>([]);
    const [textsLoaded, setTextsLoaded] = useState(false);

	function td(textId) {
        if (textsLoaded) {
            if (textId != undefined && textId.length == 24 && !textId.includes(" ")) {
                let foundText = texts.find(text => text._id.toString() === textId);
                return foundText?.texts[language] ?? (foundText?.term ?? "No matching text");
            } else {
                if(texts.find(text => text.oldValue != undefined && text.oldValue == textId) != undefined){
                    let foundText = texts.find(text => text.oldValue === textId);
                    return foundText?.texts[language] ?? (foundText?.term ?? "No matching text");
                } else {
                    return textId; 
                }
            }
        } else {
            console.log("No texts loaded");
            return "";
        }
	}

	function hastd(textId) {
        let foundText = texts.find(text => text._id === textId);
        return foundText?.texts[language] != undefined;
	}

    function loadTexts(user = auth.user) {
        if (user["token"] != undefined) {
            axios.all([
                axios.single({
                    method: "get",
                    url: generateUrl("/api/cra/languages/"),
                    responseType: "json",
                    headers: {'x-access-token': user["token"]}
                })
            ])
            .then(axios.spread((...res) => {
                setTextsLoaded(true);
                setTexts(res[0].data);
                setLanguage(user["userdata"]["language"]);
            }));
        }
    }

    useEffect(() => {
        loadTexts(user);
    }, [localStorageUser]);

    useEffect(() => {
        loadTexts(user);
    }, []);

	return {
		language,
        texts,
        td,
        hastd,
        loadTexts,
        setLanguage
	};
}