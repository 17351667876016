import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useAxios } from "../../../../../AxiosHandler";

import { useTranslate } from 'react-polyglot';
import './RiskOverview.scss';
import { useAuth } from "../../../../../Auth";
import { Card, Table } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { Button } from 'react-bootstrap';

import _ from "lodash";
import Field from '../../../../Shared/Wizard/Field/Field';

const RiskOverview = (props) => {
    const history = useHistory();

    const [systemsDoughnutLow, setSystemsDoughnutLow] = useState(0);
    const [systemsDoughnutMedium, setSystemsDoughnutMedium] = useState(0);
    const [systemsDoughnutHigh, setSystemsDoughnutHigh] = useState(0);
    const [systemsDoughnutVeryHigh, setSystemsDoughnutVeryHigh] = useState(0);

    const [selectedOption, setSelectedOption] = useState("all");
    
    const [hoverElements, setHoverElements] = useState([]);

    const [riskData, setRiskData] = useState<Object>();

    const [chartOptions, setChartOptions] = useState({});

    const [loading, setLoading] = useState(true);

    const t = useTranslate();   
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();

    let selectTitle = t("dashboard.cards.drop_down_select_title");
    let selectOptions = [
        {
            "label": t("dashboard.cards.choice_all"),
            "value": "all"
        },
        {
            "label": t("dashboard.cards.choice_system"),
            "value": "system"
        },
        {
            "label": t("dashboard.cards.choice_orgros"),
            "value": "orgros"
        },
        {
            "label": t("dashboard.cards.choice_other"),
            "value": "other"
        },
    ]

    const labelLow = t("treatment.label_low");
    const labelMiddel = t("treatment.label_middel");
    const labelHigh = t("treatment.label_high");
    const labelExtremelyHigh = t("treatment.label_extremely_high");

    function handleSelectInput(value){
        setLoading(true);
        setSelectedOption(value);
    }

    // Function checking if point (x, y) is above the curve (y = -x + maxvalue)
    function aboveCurve(x, y, maxvalue) {
        let curve = -x + maxvalue;
        return y >= curve;
    }
    
    async function processData(res2, type) {
        let returns: any = {
            tempSystemsDoughnutLow: 0,
            tempSystemsDoughnutMedium: 0,
            tempSystemsDoughnutHigh: 0,
            tempSystemsDoughnutVeryHigh: 0,
            tempSystemsDoughnutLowSystems: [],
            tempSystemsDoughnutMediumSystems: [],
            tempSystemsDoughnutHighSystems: [],
            tempSystemsDoughnutVeryHighSystems: []
        }

        if (type == "all") {
            res2[0].data = res2[0].data;
        } else if (type == "system") {
            res2[0].data = res2[0].data.filter(res => res.system.systemType.toString().toLowerCase().includes(type));
        } else {
            res2[0].data = res2[0].data.filter(res => res.system.systemType.toString().toLowerCase() == type);
        }
        
        let tempSystemsDoughnutLow = 0;
        let tempSystemsDoughnutMedium = 0;
        let tempSystemsDoughnutHigh = 0;
        let tempSystemsDoughnutVeryHigh = 0;

        let tempSystemsDoughnutLowSystems: Array<any> = [];
        let tempSystemsDoughnutMediumSystems: Array<any> = [];
        let tempSystemsDoughnutHighSystems: Array<any> = [];
        let tempSystemsDoughnutVeryHighSystems: Array<any> = [];

        res2[0].data.forEach((riskitem, key) => {
            /* let causeProb = (riskitem["cause"]["probability"] * riskitem["causeReduction"].reduce((acc, val) => ((100 - val) / 100) * acc, 1));
            let conseqProb = (riskitem["consequence"]["consequenceValue"] * riskitem["consequenceReduction"].reduce((acc, val) => ((100 - val) / 100) * acc, 1)); */
            let itemXPercent = riskitem["consequence"]["consequenceValue"] * (riskitem["consequenceReduction"].reduce((acc, val) => ((100 - val) / 100) * acc, 1));
            let itemYPercent = riskitem["cause"]["probability"] * (riskitem["causeReduction"].reduce((acc, val) => ((100 - val) / 100) * acc, 1));
            /* if (causeProb < 0) {causeProb = 0};
            if (conseqProb < 0) {conseqProb = 0}; */
            if (itemXPercent < 1) {itemXPercent = 1};
            if (itemYPercent < 1) {itemYPercent = 1};

            if (aboveCurve(itemXPercent, itemYPercent, 0) && !aboveCurve(itemXPercent, itemYPercent, 70)) {
                tempSystemsDoughnutLow += 1;
                if (!tempSystemsDoughnutLowSystems.map(s => s._id).includes(riskitem.system._id)) {
                    tempSystemsDoughnutLowSystems.push({ ...riskitem.system, count: 1 });
                } else {
                    let tempSystem = tempSystemsDoughnutLowSystems.find(s => s._id == riskitem.system._id);
                    let tempSystems = tempSystemsDoughnutLowSystems.filter(s => s._id != riskitem.system._id);
                    tempSystem.count ++;
                    tempSystems.push(tempSystem);
                    tempSystemsDoughnutLowSystems = JSON.parse(JSON.stringify(tempSystems));
                }
            } else if (aboveCurve(itemXPercent, itemYPercent, 70) && !aboveCurve(itemXPercent, itemYPercent, 110)) {
                tempSystemsDoughnutMedium += 1;
                if (!tempSystemsDoughnutMediumSystems.map(s => s._id).includes(riskitem.system._id)) {
                    tempSystemsDoughnutMediumSystems.push({ ...riskitem.system, count: 1 });
                } else {
                    let tempSystem = tempSystemsDoughnutMediumSystems.find(s => s._id == riskitem.system._id);
                    let tempSystems = tempSystemsDoughnutMediumSystems.filter(s => s._id != riskitem.system._id);
                    tempSystem.count ++;
                    tempSystems.push(tempSystem);
                    tempSystemsDoughnutMediumSystems = JSON.parse(JSON.stringify(tempSystems));
                }
            } else if (aboveCurve(itemXPercent, itemYPercent, 110) && !aboveCurve(itemXPercent, itemYPercent, 150)) {
                tempSystemsDoughnutHigh += 1;
                if (!tempSystemsDoughnutHighSystems.map(s => s._id).includes(riskitem.system._id)) {
                    tempSystemsDoughnutHighSystems.push({ ...riskitem.system, count: 1 });
                } else {
                    let tempSystem = tempSystemsDoughnutHighSystems.find(s => s._id == riskitem.system._id);
                    let tempSystems = tempSystemsDoughnutHighSystems.filter(s => s._id != riskitem.system._id);
                    tempSystem.count ++;
                    tempSystems.push(tempSystem);
                    tempSystemsDoughnutHighSystems = JSON.parse(JSON.stringify(tempSystems));
                }
            } else if (aboveCurve(itemXPercent, itemYPercent, 150)) {
                tempSystemsDoughnutVeryHigh += 1;
                if (!tempSystemsDoughnutVeryHighSystems.map(s => s._id).includes(riskitem.system._id)) {
                    tempSystemsDoughnutVeryHighSystems.push({ ...riskitem.system, count: 1 });
                } else {
                    let tempSystem = tempSystemsDoughnutVeryHighSystems.find(s => s._id == riskitem.system._id);
                    let tempSystems = tempSystemsDoughnutVeryHighSystems.filter(s => s._id != riskitem.system._id);
                    tempSystem.count ++;
                    tempSystems.push(tempSystem);
                    tempSystemsDoughnutVeryHighSystems = JSON.parse(JSON.stringify(tempSystems));
                }
            }
        });
        returns.tempSystemsDoughnutLow = tempSystemsDoughnutLow;
        returns.tempSystemsDoughnutMedium = tempSystemsDoughnutMedium;
        returns.tempSystemsDoughnutHigh = tempSystemsDoughnutHigh;
        returns.tempSystemsDoughnutVeryHigh = tempSystemsDoughnutVeryHigh;

        returns.tempSystemsDoughnutLowSystems = tempSystemsDoughnutLowSystems;
        returns.tempSystemsDoughnutMediumSystems = tempSystemsDoughnutMediumSystems;
        returns.tempSystemsDoughnutHighSystems = tempSystemsDoughnutHighSystems;
        returns.tempSystemsDoughnutVeryHighSystems = tempSystemsDoughnutVeryHighSystems;

        setRiskData(returns);
        setSystemsDoughnutLow(returns["tempSystemsDoughnutLow"]);
        setSystemsDoughnutMedium(returns["tempSystemsDoughnutMedium"]);
        setSystemsDoughnutHigh(returns["tempSystemsDoughnutHigh"]);
        setSystemsDoughnutVeryHigh(returns["tempSystemsDoughnutVeryHigh"]);
        setLoading(false);
        setChartOptions({
            legend: {
                position: "left"
            },
            onClick: function(evt, data2) {
                if (data2.length > 0) {
                    switch (data2[0]._index) {
                        case 0: setHoverElements(_.orderBy(returns["tempSystemsDoughnutLowSystems"], ["count"], ["desc"]) ); break;
                        case 1: setHoverElements(_.orderBy(returns["tempSystemsDoughnutMediumSystems"], ["count"], ["desc"]) ); break;
                        case 2: setHoverElements(_.orderBy(returns["tempSystemsDoughnutHighSystems"], ["count"], ["desc"]) ); break;
                        case 3: setHoverElements(_.orderBy(returns["tempSystemsDoughnutVeryHighSystems"], ["count"], ["desc"]) ); break;
                    }
                }
            },
        });
    }

    useEffect(() => {
        Promise.all([
            props.requestData("riskitems/orgrisk")
        ]).then(async (data) => {
            await processData(data, selectedOption);
            setLoading(false);
        });
    }, [selectedOption]);

    return (
        <Card data-satarget="riskoverview">
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                <h4 className="header-title">{t("dashboard.cards.riskoverview_name")}</h4>
                <p>{t("dashboard.company_risk_information")}</p>

                <Field
                    type="select"
                    title={ selectTitle }
                    onChange={ value => handleSelectInput(value.value) }
                    options={{
                        "choices": selectOptions
                    }}
                > 
                </Field>

                <hr/>
                <div className={"clickable"}>
                    <Doughnut
                        redraw={true}
                        data={{
                            datasets: [
                                {
                                    data: [systemsDoughnutLow, systemsDoughnutMedium, systemsDoughnutHigh, systemsDoughnutVeryHigh],
                                    backgroundColor: [
                                        "rgb(91, 130, 102, 1)",
                                        "rgb(250, 205, 117, 1)",
                                        "rgb(216, 119, 90, 1)",
                                        "rgb(153, 55, 66, 1)",
                                    ]
                                }
                            ],
                            labels:[
                                labelLow,
                                labelMiddel,
                                labelHigh,
                                labelExtremelyHigh,
                            ]
                        }}
                        options={chartOptions}
                    />
                </div>
                {hoverElements != undefined && hoverElements.length > 0 ?
                    <div className="chartDetails">
                        <div>
                            <h4 className="header-title">{t("system.matrix_details_title")}</h4>
                            <Button onClick={() => {
                                setHoverElements([]);
                            }}>{t("system.matrix_details_closebtn")}</Button>
                            <Table striped hover className="chartdetailstable">
                                <thead>
                                    <tr>
                                        <th>{t("system_description.system_name")}</th>
                                        <th>{t("system_description.system_count")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        hoverElements.map((system, key) => {
                                            return(
                                                <tr className="clickable" key={key} onClick={() => { history.push("/system/" + system["_id"]) }}>
                                                    <td>{ system["nameReadable"] }</td>
                                                    <td>{ system["count"] }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                : null}
            </Card.Body>
        </Card>
    );
}

export default RiskOverview;
