import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../Auth";
import { useTranslate } from 'react-polyglot';
import { useAxios } from '../../../AxiosHandler';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Newuser.scss';
import CRALogo from "../../../Assets/Images/diri-logo.svg";

import {
    Button,
    Card,
    Form,
    Modal,
    InputGroup
} from 'react-bootstrap';
import { generateUrl } from "../../../config";

function Newuser() {
    const auth = useAuth();
    const history = useHistory();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const t = useTranslate();

    const [show, setShow] = useState(false);
    const [neworgname, setNeworgname] = useState("");
    
    const myRef = React.useRef<any>();

    const notifySuccess = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "success-toast"
    });

    const notifyWarning = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "warning-toast"
    });

    const handleCheckInvite = () => {
        axios.post(generateUrl('/api/orgelements/checkinvite'), {}, {
            headers: {'x-access-token': localStorage.getItem("user") != undefined && JSON.parse(localStorage.getItem("user") as string)["token"] != undefined ? JSON.parse(localStorage.getItem("user") as string)["token"] : "token not found"}
        })
        .then(function (response) {
            if (response.data.invite != "noinvite") {
                notifySuccess(t("newuser.toast_orgcreated"));
                notifySuccess(t("newuser.toast_orginvitefound"));
                notifySuccess(t("newuser.toast_relogin"));
    
                localStorage.clear();
                history.push("/login");
            }
        });
    };

    const handleCreateOrg = () => {
        if (neworgname == "") { notifyWarning(t("newuser.toast_orgname_empty")); return undefined; }

        axios.post(generateUrl('/api/orgelements/createneworg'), {
            neworgname,
        }, {
            headers: {'x-access-token': JSON.parse(localStorage.getItem("user") as string)["token"]}
        })
        .then(function (response) {
            notifySuccess(t("newuser.toast_orgcreated"));
            notifySuccess(t("newuser.toast_relogin"));

            localStorage.clear();
            history.push("/login");
        });
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            handleCreateOrg();
        }
    }

    const logout = () => {
        auth.signout();
        window.location.href = "/login";
    }

    useEffect(() => {
        handleCheckInvite();
    }, []);

    return (
        <div className="newusercontainer">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <Card>
                        <div className="card-header pt-4 pb-4 text-center newuser-header">
                            <a href="/">
                                <span><img className="logo" src={CRALogo} alt="" width="200" /></span>
                            </a>
                        </div>
                        <Card.Body>
                            <div className="welcometext">
                                <h4>{t("newuser.title")}</h4>
                                <p>{t("newuser.welcometext")}</p>
                            </div>
                            <br />
                            <Button onClick={() => logout()}>{t("newuser.logout_btn")}</Button>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default Newuser;