import React from "react";

import { Button, Modal } from "react-bootstrap";

import releaseNotes from "../../../Assets/Constants/releaseNotes";

import "./VersionNumber.scss";

function VersionNumber(props) {
  return (
    <div className="maincontent">
      <Modal
        show={props.showVersionNumber}
        onHide={() => {
          props.setShowVersionNumber(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Version History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {releaseNotes.map(({ versionNumber, versionDate, notes }) => (
            <div className="version-container" key={versionNumber}>
              <div className="version-details">
                <p className="version-number">{versionNumber}</p>

                <p className="version-date">{versionDate}</p>
              </div>

              {notes.map((note, index) => (
                <p
                  className="version-description"
                  key={`${versionNumber}_${index}`}
                >
                  {note}
                </p>
              ))}
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default VersionNumber;
