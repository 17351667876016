import React, { useEffect, useState } from 'react';
import { useAxios } from "../../../../../AxiosHandler";
import { Card } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { useTranslate } from 'react-polyglot';

//import './TreatmentStatus.scss';
import { useAuth } from "../../../../../Auth";

const TreatmentStatus = (props) => {
    const t = useTranslate();   
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const auth = useAuth();

    const [loading, setLoading] = useState(true);

    const [openTreatments, setOpenTreatments] = useState(0);
    const [suggestedTreatments, setSuggestedTreatments] = useState(0);
    const [plannedTreatments, setPlannedTreatments] = useState(0);
    const [processTreatments, setProcessTreatments] = useState(0);
    const [closedTreatments, setClosedTreatments] = useState(0);

    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    async function processData(res){
        setOpenTreatments(res[0].data.filter(item => item["status"] == "open").length);
        setSuggestedTreatments(res[0].data.filter(item => item["status"] == "suggested").length);
        setPlannedTreatments(res[0].data.filter(item => item["status"] == "planned").length);
        setProcessTreatments(res[0].data.filter(item => item["status"] == "processing").length);
        setClosedTreatments(res[0].data.filter(item => item["status"] == "closed").length);
    }

    useEffect(() => {
        Promise.all([
            props.requestData("treatments")
        ]).then(async (data) => {
            await processData(data);
            setLoading(false);
        });
    }, []);

    return (
        <Card>
            <Card.Body>
                <div className={"cardloadingcontainer" + (loading ? "" : " hidden")}>
                    <p>{t("dashboard.cards.loadingtext")}</p>
                </div>
                 <h4 className="header-title">{t("dashboard.cards.treatmentstatus_name")}</h4>
                <p>{t("dashboard.cards.treatmentstatus.description")}</p>
                <hr />
                <div className="treatmentcountcontainerdashboard">
                    <div>
                        <div className="closed">
                            {closedTreatments}
                        /
                            {openTreatments + suggestedTreatments + plannedTreatments + processTreatments + closedTreatments}
                        </div>
                        {t("dashboard.measures_realized_r")}<br/>{t("dashboard.measures_realized_m")}
                    </div>
                </div>
                <div>
                    <Bar
                        data={{
                            labels: [
                                t("dashboard.cards.treatmentstatus.open"),
                                t("dashboard.cards.treatmentstatus.suggested"),
                                t("dashboard.cards.treatmentstatus.planned"),
                                t("dashboard.cards.treatmentstatus.processing"),
                                t("dashboard.cards.treatmentstatus.closed")],
                            datasets: [
                                {
                                    backgroundColor: [
                                        "#c8c8c8",
                                        "#993742",
                                        "#d8775a",
                                        "#facd75",
                                        "#5b8266",
                                    ],
                                    data: [
                                        openTreatments,
                                        suggestedTreatments,
                                        plannedTreatments,
                                        processTreatments,
                                        closedTreatments
                                    ]
                                }
                            ]
                        }}
                        options={{
                            title:{
                                display:true,
                                fontSize:20
                            },
                            legend:{
                                display: false,
                                position:'right'
                            },
                            scales: {
                                yAxes: [{
                                    stacked: true,
                                    ticks: {
                                        suggestedMin: 0,
                                        stepSize: 1
                                    },
                                }]
                            }
                        }}
                    />
                </div>
     
            </Card.Body>
        </Card>
    );
}

export default TreatmentStatus;
