import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";

import "./PageItems.scss";

function PageItems(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const [data, setData] = useState([{}]);
  const [currentPageNumber, setCurrentPage] = useState(0);
  const [pageData, setPageData] = useState([{}]);
  const [pageSize, setPageSize] = useState(5);
  const [selectedOption, setSelectedOption] = useState(0);
  const [dataUrl] = useState(props.dataUrl);

  const showItemsRange = [5, 10, 15, 20];

  const setPageSizeChanged = (value) => {
    let newPageSize = parseInt(value);
    let newCurrentPageNumber = 0;
    setPageSize(value);
    setSelectedOption(value);
    fetchData(newCurrentPageNumber, newPageSize).then((data) => {
      setData(data["data"]);
      setPageData(data["pageData"]);
      setCurrentPage(newCurrentPageNumber);
      setSelectedOption(newPageSize);
    });
  };

  function setPagination(pageData) {
    const { pagestotal } = pageData;
    const next = () => {
      let nextPage = Math.min(currentPageNumber + 1, pagestotal);
      setCurrentPage(nextPage);
      fetchData(nextPage, pageSize).then((data) => {
        setData(data["data"]);
        setPageData(data["pageData"]);
        setCurrentPage(nextPage);
        setSelectedOption(pageSize);
      });
    };

    const prev = () => {
      let prePage = currentPageNumber - 1;
      setCurrentPage(prePage);
      fetchData(prePage, pageSize).then((data) => {
        setData(data["data"]);
        setPageData(data["pageData"]);
        setCurrentPage(prePage);
        setSelectedOption(pageSize);
      });
    };

    const first = () => {
      setCurrentPage(0);
      fetchData(0, pageSize).then((data) => {
        setData(data["data"]);
        setPageData(data["pageData"]);
        setCurrentPage(data["currentPageNumber"]);
        setSelectedOption(pageSize);
      });
    };

    const last = () => {
      setCurrentPage(pagestotal - 1);
      fetchData(pagestotal - 1, pageSize).then((data) => {
        setData(data["data"]);
        setPageData(data["pageData"]);
        setCurrentPage(data["currentPageNumber"]);
        setSelectedOption(pageSize);
      });
    };

    return (
      <div className="pagination">
        <Pagination>
          <Pagination.First onClick={first} disabled={currentPageNumber == 0} />
          <Pagination.Prev onClick={prev} disabled={currentPageNumber == 0} />
          <Pagination.Item active>
            {currentPageNumber + 1} / {pagestotal}
          </Pagination.Item>
          <Pagination.Next
            onClick={next}
            disabled={currentPageNumber + 1 == pagestotal}
          />
          <Pagination.Last
            onClick={last}
            disabled={currentPageNumber + 1 == pagestotal}
          />
        </Pagination>
        <select
          className="mdb-select md-form"
          onChange={(e) => setPageSizeChanged(e.target.value)}
          value={selectedOption}
        >
          {showItemsRange != undefined
            ? showItemsRange.map((size) => {
                return (
                  <option key={size} value={size}>
                    {" "}
                    {size}{" "}
                  </option>
                );
              })
            : ""}
        </select>
      </div>
    );
  }

  async function fetchData(currentPageNumber = 0, pageSize = 5) {
    let returns = {};
    await axios
      .single({
        method: "post",
        url: dataUrl,
        responseType: "json",
        data: {
          pagenumber: currentPageNumber,
          pagesize: pageSize,
          sorting:
            props.defaultSortField != undefined
              ? {
                  field: props.defaultSortField,
                  order: props.defaultSortDirection,
                }
              : undefined,
          filters: props.filters != undefined ? props.filters : [],
          exclusiveFilters:
            props.exclusiveFilters != undefined ? props.exclusiveFilters : [],
        },
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        returns = {
          data: res["data"]["pagedata"],
          pageData: res["data"],
          currentPageNumber,
        };
      });
    return returns;
  }

  useEffect(() => {
    let cancelled = false;

    fetchData(currentPageNumber, pageSize).then((data) => {
      if (!cancelled) {
        setData(data["data"]);
        setPageData(data["pageData"]);
        setCurrentPage(data["currentPageNumber"]);
        setSelectedOption(pageSize);
      }
    });

    return () => {
      cancelled = true;
    };
  }, [props.dataUrl]);

  useEffect(() => {
    let cancelled = false;

    fetchData(currentPageNumber, pageSize).then((data) => {
      if (!cancelled) {
        setData(data["data"]);
        setPageData(data["pageData"]);
        setCurrentPage(data["currentPageNumber"]);
        setSelectedOption(pageSize);
      }
    });

    return () => {
      cancelled = true;
    };
  }, [props.updateTable]);

  return (
    <div className="pageitems">
      {!data.length ? (
        <p className="text-center">
          {props.noDataLabel || "No data available in the table"}
        </p>
      ) : (
        <>
          {data.map((item, key) => (
            <div key={key} className="pageitem">
              {props.renderItem(item)}
            </div>
          ))}
          {props.hidePagination == undefined || props.hidePagination == true ? (
            ""
          ) : (
            <div> {setPagination(pageData)} </div>
          )}
        </>
      )}
    </div>
  );
}

export default PageItems;
