/*async function fetchData(axios, auth): Promise<any> {
    let results = {
        users: [],
        organizations: []
    };

    await axios.all([
        axios.single({
            method: "get",
            url: "https://test.diri.ai/api/users/",
            responseType: "json",
            headers: {'x-access-token': auth.user["token"]}
        }),
        axios.single({
			method: "get",
			url: "https://test.diri.ai/api/orgElements/",
			responseType: "json",
			headers: {'x-access-token': auth.user["token"]}
      })
    ])
    .then(axios.spread(async (...res) => {
        results.users = res[0].data;
        results.organizations = res[1].data;
    }));

    return results;
}*/

let localStorageUser = localStorage.getItem("user");
let settings = {};
if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
}

function getSystemvalueOptionsByKIT(kit){
    let options = Array();
    if(settings["systemvalue" + kit + "Num"] != undefined){
        for (let i = 1; i <= parseInt(settings["systemvalue" + kit + "Num"]); i++) {
            options.push({
                label: settings["systemvalue" + kit + "Level" + i],
                value: i
            });
        }
        return options;
    }
}

async function OtherinfoTemplate(t, axios, auth) {
    if(localStorageUser == null){
        localStorageUser = localStorage.getItem("user");
    }
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    //let data = await fetchData(axios, auth);

    let tempWizardData: Array<any> = [
        {
            fields: [
                {
                    type: "text_title",
                    title: t("wizard_title.assets.otherdata.title")
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.otherdata.desc")
                },
                {
                    id: 8,
                    type: "bool",
                    title: t("wizard_title.assets.otherdata.question") + " *",
                    value: "",
                    reference: "createitembool"
                },
                {
                    type: "textarea",
                    title: t("wizard_title.assets.reason_not_relevant"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 8,
                                value: false,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "reasoning_not"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.reason_not_relevant_desc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 8,
                                value: false,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "input",
                    title: t("wizard_title.assets.otherdata.asset"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 8,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "title"
                },
                {
                    type: "select",
                    title: t("wizard_title.assets.otherdata.question2"),
                    options: {
                        typeLoad: "Manual",
                        choices: [
                            {
                                label: t("wizard_title.assets.other"),
                                value: "Annet"
                            },
                            {
                                label: t("wizard_title.assets.rnd"),
                                value: "Forskning og utvikling"
                            },
                            {
                                label: t("wizard_title.assets.iprights"),
                                value: "Intellektuell eiendom og åndsverk"
                            },
                            {
                                label: t("wizard_title.assets.software"),
                                value: "Software"
                            },
                            {
                                label: t("wizard_title.assets.network"),
                                value: "Network"
                            },
                            {
                                label: t("wizard_title.assets.personnel"),
                                value: "Personnel"
                            },
                            {
                                label: t("wizard_title.assets.bp"),
                                value: "Business Process"
                            },
                            {
                                label: t("wizard_title.assets.site"),
                                value: "Website"
                            },
                            {
                                label: t("wizard_title.assets.org"),
                                value: "Organization"
                            },
                            {
                                label: t("wizard_title.assets.law.title"),
                                value: "Annet lovverk"
                            },
                            {
                                label: t("wizard_title.assets.hardware.title"),
                                value: "Infrastruktur og maskinvare"
                            },
                            {
                                label: t("wizard_title.assets.pii.title"),
                                value: "Personopplysninger"
                            },
                            {
                                label: t("wizard_title.assets.companydata.title"),
                                value: "Virksomhetsdata"
                            },
                            {
                                label: t("wizard_title.assets.financialdata.title"),
                                value: "Økonomisk informasjon"
                            }
                        ],
                        filters: [
                            {
                                if: "notequals",
                                id: 8,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "othercategory"
                },
                {
                    type: "textarea",
                    title: t("wizard_title.assets.otherdata.desc2"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 8,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "description"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.otherdata.deschelptext"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 8,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "select",
                    title: t("wizard_title.assets.otherdata.conf"),
                    options: {
                        typeLoad: "Manual",
                        choices: getSystemvalueOptionsByKIT("K"),
                        filters: [
                            {
                                if: "notequals",
                                id: 8,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "confidentiality"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.otherdata.confdesc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 8,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "select",
                    title: t("wizard_title.assets.otherdata.integrity"),
                    options: {
                        typeLoad: "Manual",
                        choices: getSystemvalueOptionsByKIT("I"),
                        filters: [
                            {
                                if: "notequals",
                                id: 8,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "integrity"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.otherdata.integritydesc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 8,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                },
                {
                    type: "textarea",
                    title: t("wizard_title.assets.otherdata.reason"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 8,
                                value: true,
                                action: "hide",
                            }
                        ]
                    },
                    reference: "reasoning"
                },
                {
                    type: "text_normal",
                    title: t("wizard_title.assets.otherdata.reasondesc"),
                    options: {
                        filters: [
                            {
                                if: "notequals",
                                id: 8,
                                value: true,
                                action: "hide",
                            }
                        ]
                    }
                }
            ]
        }
    ]

    return tempWizardData;
}

export default OtherinfoTemplate;
