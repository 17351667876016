import React, { useEffect, useState } from "react";

import { Button, Modal } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import moment from "moment";
import { toast } from "react-toastify";

import { useAuth } from "../../../../Auth";
import { useAxios } from "../../../../AxiosHandler";

import Field from "../../Wizard/Field/Field";

import { generateUrl } from "../../../../config";

import "./FilterCreator.scss";

function FilterCreator(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();

  const [filtername, setFiltername] = useState("");
  const [subfilters, setSubfilters] = useState<Array<any>>([]);

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  function handleCreateFilter() {
    if (filtername == undefined || filtername == "") {
      notifyWarning(t("filters.empty_name"));
      return undefined;
    }

    axios
      .post(
        generateUrl("/api/cra/filters"),
        {
          name: filtername,
          type: props.filterType,
          filters: subfilters,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        if (props.onClose != undefined) props.onClose();
        if (props.onCreated != undefined) props.onCreated();
        setSubfilters([]);
        setFiltername("");
      });
  }

  function handleClose() {
    setSubfilters([]);
    setFiltername("");
  }

  function updateSubfilter(key, value, type) {
    let tempsubfilters = subfilters;
    tempsubfilters[key][type] = value;
    if (type == "field") {
      updateSubfilterValues(key, "", "empty");
    }
    setSubfilters(JSON.parse(JSON.stringify(tempsubfilters)));
  }

  function updateSubfilterValues(key, value, type) {
    let tempsubfilters = subfilters;

    if (type == "multiline") {
      let values = value.split("\n");
      tempsubfilters[key]["values"] = values;
    } else {
      tempsubfilters[key]["values"] = [value];
    }

    setSubfilters(JSON.parse(JSON.stringify(tempsubfilters)));
  }

  function removefilter(key) {
    let tempsubfilters = subfilters;
    tempsubfilters.splice(key, 1);
    setSubfilters(JSON.parse(JSON.stringify(tempsubfilters)));
  }

  useEffect(() => {
    moment.locale("no", {
      months: "januar_februar_mars_april_mai_juni_juli_august_september_oktober_november_desember".split(
        "_"
      ),
    });
  }, []);

  useEffect(() => {}, [props.show]);

  return (
    <div className="filtercreator">
      <Modal
        className="filtercreatormodal"
        size="lg"
        show={props.show}
        onHide={() => {
          if (props.onHide != undefined) {
            props.onHide();
          }
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("filters.new_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Field
            title={t("filters.filtername")}
            type="textlanguage"
            value={filtername}
            onChange={(val) => setFiltername(val)}
            options={{
              category: "filters",
            }}
          ></Field>
          <br />
          <hr />
          <p>{t("filters.newfilter.subfilters.title")}</p>
          {subfilters.map((subfilter, key) => (
            <div key={key} data-amount={subfilters.length}>
              <div className="row">
                <div className="col col-sm-4">
                  <Field
                    type="select"
                    options={{
                      choosetext: t("filters.newfilter.subfilters.field"),
                      choices: props.fields,
                    }}
                    value={{ value: subfilter.field }}
                    onChange={(val) => updateSubfilter(key, val.value, "field")}
                  ></Field>
                </div>
                <div className="col col-sm-4">
                  <Field
                    type="select"
                    options={{
                      choosetext: t("filters.newfilter.subfilters.type"),
                      choices: [
                        {
                          label: t("filters.newfilter.subfilters.type_and"),
                          value: "and",
                        },
                        {
                          label: t("filters.newfilter.subfilters.type_or"),
                          value: "or",
                        },
                        {
                          label: t("filters.newfilter.subfilters.type_not"),
                          value: "not",
                        },
                        {
                          label: t("filters.newfilter.subfilters.type_from"),
                          value: "from",
                        },
                        {
                          label: t("filters.newfilter.subfilters.type_to"),
                          value: "to",
                        },
                        {
                          label: t(
                            "filters.newfilter.subfilters.type_fromtoday"
                          ),
                          value: "fromtoday",
                        },
                        {
                          label: t("filters.newfilter.subfilters.type_totoday"),
                          value: "totoday",
                        },
                        {
                          label: t("filters.newfilter.subfilters.type_today"),
                          value: "today",
                        },
                      ],
                    }}
                    value={{ value: subfilter.type }}
                    onChange={(val) => updateSubfilter(key, val.value, "type")}
                  ></Field>
                </div>
                <div className="col col-sm-4">
                  {props.fields.find((i) => i.value == subfilter.field) !=
                  undefined ? (
                    props.fields.find((i) => i.value == subfilter.field)[
                      "type"
                    ] == "date" &&
                    subfilter.type != "fromtoday" &&
                    subfilter.type != "totoday" &&
                    subfilter.type != "today" ? (
                      <Field
                        type="dateselector"
                        value={
                          subfilter.values.length != 0
                            ? subfilter.values.reduce((a, b) => {
                                return a + (b != "" ? "\n" + b : "\n");
                              })
                            : ""
                        }
                        onChange={(val) =>
                          updateSubfilterValues(key, val, "date")
                        }
                        showTimeSelect
                        placeholderText={t(
                          "filters.newfilter.subfilters.datetime"
                        )}
                        todayButton={t("filters.newfilter.subfilters.todaybtn")}
                        minDate={
                          new Date(moment().subtract(3, "years").toDate())
                        }
                      ></Field>
                    ) : null
                  ) : null}
                  {props.fields.find((i) => i.value == subfilter.field) !=
                  undefined ? (
                    props.fields.find((i) => i.value == subfilter.field)[
                      "type"
                    ] == "date" &&
                    (subfilter.type == "fromtoday" ||
                      subfilter.type == "totoday" ||
                      subfilter.type == "today") ? (
                      <div>
                        <Field
                          type="input"
                          placeholder={t("filters.newfilter.subfilters.amount")}
                          value={
                            subfilter.values.length != 0
                              ? subfilter.values.reduce((a, b) => {
                                  return a + (b != "" ? "\n" + b : "\n");
                                })
                              : ""
                          }
                          onChange={(val) =>
                            updateSubfilterValues(key, val, "single")
                          }
                        ></Field>
                        <Field
                          type="select"
                          options={{
                            choosetext: t(
                              "filters.newfilter.subfilters.timespan"
                            ),
                            choices: [
                              {
                                label: t("filters.newfilter.subfilters.hours"),
                                value: "moment-add-hours",
                              },
                              {
                                label: t("filters.newfilter.subfilters.days"),
                                value: "moment-add-days",
                              },
                              {
                                label: t("filters.newfilter.subfilters.months"),
                                value: "moment-add-months",
                              },
                            ],
                          }}
                          value={{ value: subfilter.modifier }}
                          onChange={(val) =>
                            updateSubfilter(key, val.value, "modifier")
                          }
                        ></Field>
                      </div>
                    ) : null
                  ) : null}
                  {props.fields.find((i) => i.value == subfilter.field) !=
                  undefined ? (
                    props.fields.find((i) => i.value == subfilter.field)[
                      "type"
                    ] == "select" ? (
                      <Field
                        type="select"
                        value={{ value: subfilter.values }}
                        onChange={(val) =>
                          updateSubfilterValues(key, val.value, "select")
                        }
                        options={{
                          choosetext: t(
                            "filters.newfilter.subfilters.choose_select"
                          ),
                          choices: props.fields.find(
                            (i) => i.value == subfilter.field
                          )["choices"],
                        }}
                      ></Field>
                    ) : null
                  ) : null}
                  {props.fields.find((i) => i.value == subfilter.field) !=
                  undefined ? (
                    props.fields.find((i) => i.value == subfilter.field)[
                      "type"
                    ] == "string" ? (
                      <Field
                        type="textarea"
                        placeholder={t("filters.newfilter.subfilters.value")}
                        value={
                          subfilter.values.length != 0
                            ? subfilter.values.reduce((a, b) => {
                                return a + (b != "" ? "\n" + b : "\n");
                              })
                            : ""
                        }
                        onChange={(val) =>
                          updateSubfilterValues(key, val, "multiline")
                        }
                      ></Field>
                    ) : null
                  ) : null}
                </div>
              </div>
              {props.fields.find((i) => i.value == subfilter.field) !=
              undefined ? (
                <p>
                  {t("filters.newfilter.subfilters.explainations.field") + " "}
                  {props.fields.find((i) => i.value == subfilter.field)[
                    "label"
                  ] + " "}
                  {subfilter.type == "and"
                    ? " " +
                      t("filters.newfilter.subfilters.explainations.inclall") +
                      " "
                    : ""}
                  {subfilter.type == "or"
                    ? " " +
                      t("filters.newfilter.subfilters.explainations.inclany") +
                      " "
                    : ""}
                  {subfilter.type == "not"
                    ? " " +
                      t("filters.newfilter.subfilters.explainations.notlike") +
                      " "
                    : ""}
                  {subfilter.type == "fromtoday"
                    ? " " +
                      (props.fields.find((i) => i.value == subfilter.field)[
                        "type"
                      ] == "date"
                        ? t(
                            "filters.newfilter.subfilters.explainations.morethandate"
                          )
                        : t(
                            "filters.newfilter.subfilters.explainations.morethan"
                          )) +
                      " "
                    : ""}
                  {subfilter.type == "from"
                    ? " " +
                      (props.fields.find((i) => i.value == subfilter.field)[
                        "type"
                      ] == "date"
                        ? t(
                            "filters.newfilter.subfilters.explainations.morethandate"
                          )
                        : t(
                            "filters.newfilter.subfilters.explainations.morethan"
                          )) +
                      " "
                    : ""}
                  {subfilter.type == "to"
                    ? " " +
                      (props.fields.find((i) => i.value == subfilter.field)[
                        "type"
                      ] == "date"
                        ? t(
                            "filters.newfilter.subfilters.explainations.lessthandate"
                          )
                        : t(
                            "filters.newfilter.subfilters.explainations.lessthan"
                          )) +
                      " "
                    : ""}
                  {subfilter.type == "totoday"
                    ? " " +
                      (props.fields.find((i) => i.value == subfilter.field)[
                        "type"
                      ] == "date"
                        ? t(
                            "filters.newfilter.subfilters.explainations.lessthandate"
                          )
                        : t(
                            "filters.newfilter.subfilters.explainations.lessthan"
                          )) +
                      " "
                    : ""}
                  {subfilter.type == "totoday" ||
                  subfilter.type == "fromtoday" ||
                  subfilter.type == "today"
                    ? subfilter.values != undefined &&
                      subfilter.values.length > 0
                      ? props.fields.find((i) => i.value == subfilter.field)[
                          "type"
                        ] == "date"
                        ? subfilter.values[0] != "" &&
                          subfilter.modifier != undefined
                          ? moment()
                              .add(
                                subfilter.values[0],
                                subfilter.modifier.split("-")[2]
                              )
                              .format("D. MMMM yyyy [kl.] HH.mm")
                          : ""
                        : subfilter.values.join(", ")
                      : ""
                    : ""}
                  {subfilter.type != "totoday" &&
                  subfilter.type != "fromtoday" &&
                  subfilter.type != "today"
                    ? subfilter.values != undefined &&
                      subfilter.values.length > 0
                      ? props.fields.find((i) => i.value == subfilter.field)[
                          "type"
                        ] == "date"
                        ? subfilter.values[0] != ""
                          ? moment(subfilter.values[0]).format(
                              "D. MMMM yyyy [kl.] HH.mm"
                            )
                          : ""
                        : subfilter.values.join(", ")
                      : ""
                    : ""}
                </p>
              ) : null}
              <Button onClick={(evt) => removefilter(key)}>
                {t("filters.newfilter.subfilters.deletebtn")}
              </Button>
              <hr />
            </div>
          ))}
          <Button
            onClick={(evt) =>
              setSubfilters([
                ...subfilters,
                { field: "", type: "", values: [] },
              ])
            }
          >
            {t("filters.newfilter.subfilters.addbtn")}
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(evt) => {
              if (props.onClose != undefined) {
                props.onClose();
              }
              handleClose();
            }}
          >
            {t("filters.newfilter.cancel_without_save")}
          </Button>
          <Button onClick={(evt) => handleCreateFilter()}>
            {t("filters.newfilter.save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FilterCreator;
