import norwegian from "./no_NB/translations.json";
import english from "./en_US/translations.json";
import chinese from "./ch_TW/translations.json";

function GetTexts(locale) {
  switch (locale) {
    case "no":
      return norwegian;
    case "en":
      return english;
    default:
      return norwegian;
  }
}

export default GetTexts;
