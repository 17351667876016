import * as React from 'react';

import './WizardEditor.scss';
import { useState, useEffect } from 'react';
import { useTranslate } from 'react-polyglot';
import { Nav, Row, Col, Card, OverlayTrigger, Tooltip, Modal, InputGroup, Form, Button } from 'react-bootstrap';
import Field from '../Wizard/Field/Field';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function useForceUpdate(){
    const [forcevalue, setForceValue] = useState(0); // integer state
    return () => setForceValue(forcevalue + 1); // update the state to force render
}

(Array.prototype as any).move = function(from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
};

function WizardEditor(props) {
    const t = useTranslate();
    
    const forceUpdate = useForceUpdate();

    const [showEditElementModal, setShowEditElementModal] = useState(false);
    const [editAtElement, setEditAtElement] = useState(999999);
    const [editAtPage, setEditAtPage] = useState(999999);

    const [showAddElementModal, setShowAddElementModal] = useState(false);
    const [insertAtElement, setInsertAtElement] = useState(0);
    const [insertAtPage, setInsertAtPage] = useState(0);
    const [insertPosition, setInsertPosition] = useState("");

    const [activePage, setActivePage] = useState(0);
    const [wizardData, setWizardData] = useState([{}]);

    function sendChangedEvent(updatedata = wizardData) {
        if (props.onChange != undefined) {
            props.onChange(updatedata);
        }
    }

    function pageTabClicked(selectedKey) {
        if (selectedKey == 999999) {
            let tempData = wizardData;
            tempData.push({fields: []});
            setWizardData(tempData);
            forceUpdate();
            setActivePage(tempData.length - 1);
            sendChangedEvent(tempData);
        } else {
            setActivePage(selectedKey);
        }
    }

    function handleDeletePage(pageKey) {
        let tempData = wizardData;
        tempData.splice(pageKey, 1);
        setWizardData(tempData);
        setActivePage(pageKey != 0 ? pageKey - 1 : 0);
        sendChangedEvent(tempData);
        forceUpdate();
    }

    function handleDeleteField(fieldKey, pageKey) {
        if (window.confirm(t("wizardeditor.deleteconfirm"))) {
            let tempData = wizardData;
            tempData[pageKey]["fields"].splice(fieldKey, 1);
            setWizardData(tempData);
            sendChangedEvent(tempData);
            forceUpdate();
        }
    }

    function handleSaveEditField(fieldKey, pageKey, field, value) {
        let tempData = wizardData;
        tempData[pageKey]["fields"][fieldKey][field] = value;
        setWizardData(tempData);
        sendChangedEvent(tempData);
        forceUpdate();
    }

    function handleEditField(fieldKey, pageKey) {
        setEditAtElement(fieldKey);
        setEditAtPage(pageKey);
        setShowEditElementModal(true);
    }

    function handleInsertBelowField(fieldKey, pageKey) {
        setInsertAtElement(fieldKey);
        setInsertAtPage(pageKey);
        setInsertPosition("below");

        setShowAddElementModal(true);
    }

    const handleDragEnd = (result) => {
        let tempWizard = props.data;
        let item = null;
        
        if (!result.destination) {
          return;
        }

        let tempPageFields = tempWizard[result.source.droppableId]["fields"];

        item = tempPageFields[result.source.index];
        tempPageFields.splice(result.source.index, 1);

        tempPageFields.splice(result.destination.index, 0, item);

        tempWizard[result.source.droppableId]["fields"] = tempPageFields;

        sendChangedEvent(JSON.parse(JSON.stringify(tempWizard)));
    }

    function drawEditElementContainer() {
        if (editAtPage != 999999 && wizardData[editAtPage]["fields"][editAtElement] != undefined) {
            let tempElement = wizardData[editAtPage]["fields"][editAtElement];
            return(
                <div className="editelementcontainer">
                    {/*<p>{JSON.stringify(tempElement)}</p>*/}
                    {Object.keys(tempElement).map((key, index) => {
                        let editObjects = Array<JSX.Element>();
                        switch (key) {
                            case "title":
                                editObjects.push(
                                    <div className="editfielditem" key={index}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>{t("wizardeditor.edit_element_title_name")}</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control as="textarea" value={tempElement[key]} onChange={(evt) => {
                                                handleSaveEditField(editAtElement, editAtPage, key, evt.currentTarget.value);
                                            }} />
                                        </InputGroup>
                                    </div>
                                );
                                break;
                            case "options":
                                Object.keys(tempElement[key]).map((optionsKey, optionsIndex) => {
                                    switch (optionsKey) {
                                        case "typeLoad":
                                            editObjects.push(
                                                <div className="editfielditem" key={index + "-" + optionsIndex}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>{t("wizardeditor.edit_element_options_typeload_name")}</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control as="select" value={tempElement[key][optionsKey]} onChange={(evt) => {
                                                            let tempOption = tempElement[key];
                                                            if (evt.currentTarget.value == "Manual") {
                                                                tempOption[optionsKey] = "Manual";
                                                                if (tempElement[key]["choices"] == undefined) {
                                                                    handleSaveEditField(editAtElement, editAtPage, "choices", []);
                                                                }
                                                            } else {
                                                                tempOption[optionsKey] = evt.currentTarget.value;
                                                            }
                                                            handleSaveEditField(editAtElement, editAtPage, key, tempOption);
                                                        }}>
                                                            <option value="Manual">{t("wizardeditor.edit_element_options_typeload_type_manual")}</option>
                                                            <option value="System">{t("wizardeditor.edit_element_options_typeload_type_system")}</option>
                                                            <option value="User">{t("wizardeditor.edit_element_options_typeload_type_user")}</option>
                                                        </Form.Control>
                                                    </InputGroup>
                                                </div>
                                            );
                                            break;
                                        case "multiple":
                                            editObjects.push(
                                                <div className="editfielditem" key={index + "-" + optionsIndex}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>{t("wizardeditor.edit_element_options_multiple_name")}</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Check type="checkbox" checked={tempElement[key][optionsKey]} onChange={(evt) => {
                                                            let tempOption = tempElement[key];
                                                            tempOption[optionsKey] = evt.currentTarget.checked;
                                                            handleSaveEditField(editAtElement, editAtPage, key, tempOption);
                                                        }}/>
                                                    </InputGroup>
                                                </div>
                                            );
                                            break;
                                        case "min":
                                            editObjects.push(
                                                <div className="editfielditem" key={index + "-" + optionsIndex}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>{t("wizardeditor.edit_element_options_min_name")}</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control type="number" value={tempElement[key][optionsKey]} onChange={(evt) => {
                                                            let tempOption = tempElement[key];
                                                            tempOption[optionsKey] = evt.currentTarget.value;
                                                            handleSaveEditField(editAtElement, editAtPage, key, tempOption);
                                                        }}></Form.Control>
                                                    </InputGroup>
                                                </div>
                                            );
                                            break;
                                        case "max":
                                            editObjects.push(
                                                <div className="editfielditem" key={index + "-" + optionsIndex}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>{t("wizardeditor.edit_element_options_max_name")}</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control type="number" value={tempElement[key][optionsKey]} onChange={(evt) => {
                                                            let tempOption = tempElement[key];
                                                            tempOption[optionsKey] = evt.currentTarget.value;
                                                            handleSaveEditField(editAtElement, editAtPage, key, tempOption);
                                                        }}></Form.Control>
                                                    </InputGroup>
                                                </div>
                                            );
                                            break;
                                        case "step":
                                            editObjects.push(
                                                <div className="editfielditem" key={index + "-" + optionsIndex}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>{t("wizardeditor.edit_element_options_step_name")}</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control type="number" value={tempElement[key][optionsKey]} onChange={(evt) => {
                                                            let tempOption = tempElement[key];
                                                            tempOption[optionsKey] = evt.currentTarget.value;
                                                            handleSaveEditField(editAtElement, editAtPage, key, tempOption);
                                                        }}></Form.Control>
                                                    </InputGroup>
                                                </div>
                                            );
                                            break;
                                        case "choices":
                                            editObjects.push(
                                                <div className="editfielditem" key={index + "-" + optionsIndex}>
                                                    { tempElement[key] == undefined || (tempElement[key] != undefined && tempElement[key]["typeLoad"] == "Manual") ?
                                                        <div>
                                                            {tempElement[key] != undefined && tempElement[key]["choices"] != undefined ?
                                                                <DragDropContext onDragEnd={(result) => {
                                                                    let dragging = tempElement[key]["choices"][result.source.index];
                                                                    tempElement[key]["choices"].splice(result.source.index, 1);
                                                                    tempElement[key]["choices"].splice(result.destination.index, 0, dragging);
                                                                    handleSaveEditField(editAtElement, editAtPage, key, tempElement[key]);
                                                                }}>
                                                                    <h3 style={{fontSize: "1rem", fontWeight: 600, color: "#495057"}}>{t("wizardeditor.edit_element_options_choices_title")}</h3>
                                                                    <Droppable key={index} droppableId={"" + index}>
                                                                        { (provided, snapshot) => (
                                                                            <div
                                                                                {...provided.droppableProps}
                                                                                ref={provided.innerRef}
                                                                            >
                                                                                {tempElement[key]["choices"].map((choice, choiceKey) => {return(
                                                                                    <Draggable key={choiceKey} draggableId={"" + choiceKey} index={choiceKey}>
                                                                                        { (provided, snapshot) => (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                className=""
                                                                                            >
                                                                                                <div key={choiceKey} style={{overflow: "auto"}}>
                                                                                                    <i style={{width: "10%", height: "38px", float: "left"}} className="dragicon dripicons-expand"></i>
                                                                                                    <InputGroup className="mb-3" style={{width: "70%", float: "left"}}>
                                                                                                        <Form.Control type="text" value={choice["value"]} onChange={(evt) => {
                                                                                                            tempElement[key]["choices"][choiceKey]["value"] = evt.currentTarget.value;
                                                                                                            tempElement[key]["choices"][choiceKey]["label"] = evt.currentTarget.value;
                                                                                                            handleSaveEditField(editAtElement, editAtPage, key, tempElement[key]);
                                                                                                        }} />
                                                                                                    </InputGroup>
                                                                                                    <Button style={{width: "20%", height: "38px", float: "right"}} onClick={(evt) => {
                                                                                                        tempElement[key]["choices"].splice(choiceKey, 1);
                                                                                                        handleSaveEditField(editAtElement, editAtPage, key, tempElement[key]);
                                                                                                    }}>{t("wizardeditor.edit_element_options_choices_deletetext")}</Button>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                );})}
                                                                                {provided.placeholder}
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </DragDropContext>
                                                            : null}
                                                            <Button onClick={(evt) => {
                                                                if (tempElement[key] == undefined) {
                                                                    tempElement[key] = {};
                                                                }
                                                                if (tempElement[key]["choices"] == undefined) {
                                                                    tempElement[key]["choices"] = [];
                                                                }
                                                                tempElement[key]["choices"].push({label: t("wizardeditor.edit_element_options_choices_addtext"), value: t("wizardeditor.edit_element_options_choices_addtext")});
                                                                
                                                                handleSaveEditField(editAtElement, editAtPage, key, tempElement[key]);
                                                            }}>
                                                                {t("wizardeditor.edit_element_options_choices_addchoice")}
                                                            </Button>
                                                        </div>
                                                    : null }
                                                </div>
                                            );
                                            break;
                                    }
                                    return null;
                                });
                                break;
                        }
                        return editObjects;
                    })}
                </div>
            );
        } else {
            return "";
        }
    }

    function drawElementTypeBox(name, description, type) {
        return(
            <div className="elementtypecontainer" onClick={() => {
                let element = {
                    type: type
                };
                switch (type) {
                    case "text_title": element["title"] = t("wizardeditor.elementtype_title_placeholder"); break;
                    case "text_normal": element["title"] = t("wizardeditor.elementtype_text_placeholder"); break;
                    case "input": element["title"] = t("wizardeditor.elementtype_input_placeholder"); break;
                    case "dateselector": element["title"] = t("wizardeditor.elementtype_dateselector_placeholder"); break;
                    case "selectmulti":
                        element["title"] = t("wizardeditor.elementtype_selectmulti_placeholder");
                        element["options"] = {
                            typeLoad: "Manual",
                            choices: []
                        };
                        break;
                    case "select": 
                        element["title"] = t("wizardeditor.elementtype_select_placeholder");
                        element["options"] = {
                            typeLoad: "Manual",
                            choices: []
                        };
                        break;
                    case "richtext": element["title"] = t("wizardeditor.elementtype_richtext_placeholder"); break;
                    case "textarea": element["title"] = t("wizardeditor.elementtype_textarea_placeholder"); break;
                    case "checkbox": element["title"] = t("wizardeditor.elementtype_checkbox_placeholder"); break;
                    case "slider":
                        element["title"] = t("wizardeditor.elementtype_slider_placeholder");
                        element["options"] = {
                            min: 0,
                            max: 10,
                            step: 1,
                        };
                        break;
                    case "fileupload":
                        element["title"] = t("wizardeditor.elementtype_fileupload_placeholder");
                        element["options"] = {
                            multiple: true,
                        };
                        break;
                    case "bool": element["title"] = t("wizardeditor.elementtype_bool_placeholder"); break;
                    case "bowtie": element["title"] = t("wizardeditor.elementtype_bowtie_placeholder"); break;
                }
                let tempData = wizardData;
                tempData[insertAtPage]["fields"].splice(insertPosition == "below" ? insertAtElement + 1 : insertAtElement, 0, element);
                
                setWizardData(tempData);
                setShowAddElementModal(false);
                sendChangedEvent(tempData);
                forceUpdate();
            }}>
                <h3>{name}</h3>
                <p>{description}</p>
            </div>
        );
    }

    function drawField(field, fieldKey, pageKey) {
        return(
            <div className="fieldcontainer">
                <div className="overlaycontainer">
                    <i className="dragicon dripicons-expand"></i>
                    <OverlayTrigger
                        key={fieldKey + "-" + pageKey + "-addunder"}
                        placement={"top"}
                        overlay={
                            <Tooltip id={`tooltip-${fieldKey + "-" + pageKey + "-addunder"}`}>
                                Legg til element
                            </Tooltip>
                        }
                    >
                        <button className="insertbelowbtn" onClick={() => handleInsertBelowField(fieldKey, pageKey)}><i className="dripicons-plus"></i></button>
                    </OverlayTrigger>
                    <button className="editbtn" onClick={() => handleEditField(fieldKey, pageKey)}>{t("wizardeditor.editbtn")}</button>
                    <button className="deletebtn" onClick={() => handleDeleteField(fieldKey, pageKey)}>{t("wizardeditor.deletebtn")}</button>
                </div>
                <Field
                    value={field["value"]}
                    disabled={true}
                    type={field["type"]}
                    title={field["title"]}
                    options={field["options"]}
                ></Field>
            </div>
        );
    }

    useEffect(() => {
        if (props.data != undefined) {
            setWizardData(props.data);
        }
    }, [props.data]);

    return(
        <div className="wizardeditor">
            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <div className="pageselector">
                                <Nav
                                    variant="tabs"
                                    activeKey={activePage}
                                    onSelect={pageTabClicked}
                                >
                                    {props.data != undefined ?
                                        props.data.map((page, key) => 
                                            <Nav.Item className="pagetab" key={key}>
                                                <Nav.Link eventKey={key}>{t("wizardeditor.pagetabs")} {key + 1}</Nav.Link>
                                            </Nav.Item>
                                        )
                                    : ""}
                                    <Nav.Item>
                                        <Nav.Link eventKey={999999}>{t("wizardeditor.pagetabs_add")}</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="pageeditor">
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    {props.data != undefined ?
                                            props.data.map((page, pageKey) => 
                                                <div key={pageKey} className={activePage != pageKey ? "page hidden" : "page"}>
                                                    <Droppable key={pageKey} droppableId={"" + pageKey}>
                                                        { (provided, snapshot) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                <button className="deletepagebtn" onClick={() => { if (window.confirm(t("wizardeditor.delete_page_confirm"))) { handleDeletePage(pageKey) }}}>{t("wizardeditor.delete_page_btn")}</button>
                                                                { page["fields"].map((field, fieldKey) => 
                                                                    <Draggable key={pageKey + "-" + fieldKey} draggableId={pageKey + "-" + fieldKey} index={fieldKey}>
                                                                        { (provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                className=""
                                                                            >
                                                                                <div key={fieldKey}>
                                                                                    { drawField(field, fieldKey, pageKey) }
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )}
                                                                {provided.placeholder}
                                                                <button className="insertbottombtn" onClick={() => handleInsertBelowField(page["fields"].length - 1, pageKey)}><i className="dripicons-plus"></i></button>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                            )
                                    : ""}
                                </DragDropContext>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal size="lg" show={showAddElementModal} onHide={() => setShowAddElementModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("wizardeditor.new_element_modaltitle")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("wizardeditor.new_element_modaldescription")}</p>
                    <div className="addelementscontainer">
                        <Row>
                            <Col xs={4}>
                                { drawElementTypeBox(t("wizardeditor.elementtype_title_title"), t("wizardeditor.elementtype_title_description"), "text_title") }
                                { drawElementTypeBox(t("wizardeditor.elementtype_text_title"), t("wizardeditor.elementtype_text_description"), "text_normal") }
                            </Col>
                            <Col xs={4}>
                                { drawElementTypeBox(t("wizardeditor.elementtype_input_title"), t("wizardeditor.elementtype_input_description"), "input") }
                                { drawElementTypeBox(t("wizardeditor.elementtype_selectmulti_title"), t("wizardeditor.elementtype_selectmulti_description"), "selectmulti") }
                                { drawElementTypeBox(t("wizardeditor.elementtype_select_title"), t("wizardeditor.elementtype_select_description"), "select") }
                                { drawElementTypeBox(t("wizardeditor.elementtype_richtext_title"), t("wizardeditor.elementtype_richtext_description"), "richtext") }
                                { drawElementTypeBox(t("wizardeditor.elementtype_textarea_title"), t("wizardeditor.elementtype_textarea_description"), "textarea") }
                                { drawElementTypeBox(t("wizardeditor.elementtype_checkbox_title"), t("wizardeditor.elementtype_checkbox_description"), "checkbox") }
                                { drawElementTypeBox(t("wizardeditor.elementtype_slider_title"), t("wizardeditor.elementtype_slider_description"), "slider") }
                                { drawElementTypeBox(t("wizardeditor.elementtype_dateselector_title"), t("wizardeditor.elementtype_dateselector_description"), "dateselector") }
                                { drawElementTypeBox(t("wizardeditor.elementtype_fileupload_title"), t("wizardeditor.elementtype_fileupload_description"), "fileupload") }
                                { drawElementTypeBox(t("wizardeditor.elementtype_bool_title"), t("wizardeditor.elementtype_bool_description"), "bool") }
                                {/* { drawElementTypeBox(t("wizardeditor.elementtype_bowtie_title"), t("wizardeditor.elementtype_bowtie_description"), "bowtie") } */}
                            </Col>
                            <Col xs={4}>
                                { drawElementTypeBox(t("wizardeditor.elementtype_divider_title"), t("wizardeditor.elementtype_divider_description"), "divider") }
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowAddElementModal(false)}>
                        {t("wizardeditor.cancel_new_element")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEditElementModal} onHide={() => setShowEditElementModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("wizardeditor.edit_element_modaltitle")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("wizardeditor.edit_element_modaldescription")}</p>
                    { drawEditElementContainer() }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {setShowEditElementModal(false); forceUpdate();}}>
                        {t("wizardeditor.close_edit_element")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default WizardEditor;