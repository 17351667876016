import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Button } from "react-bootstrap";
import { useTranslate } from "react-polyglot";

import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";

import "../../Systems";
import Wizard from "../../../../Shared/Wizard/Wizard";
import SystemDescriptionTemplate from "../../../../Shared/WizardTemplates/SystemDescriptionTemplate";
import WizardFieldManipulator from "../../../../Shared/WizardHelpers/WizardFieldManipulator";

import { generateUrl } from "../../../../../config";

import "react-toastify/dist/ReactToastify.css";

function SystemDescription(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  let history = useHistory();

  const GetWizardFieldByReference =
    WizardFieldManipulator.GetWizardFieldByReference;
  const GetWizardValuesByReferenceList =
    WizardFieldManipulator.GetWizardValuesByReferenceList;

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  let { systemid } = useParams();

  const [wizardData, setWizardData] = useState<Array<any>>([]);
  const [wizardDataLoaded, setWizardDataLoaded] = useState(false);

  const [system, setSystem] = useState({
    name: "",
    ownerOrg: "",
    ownerSection: "",
    ownerPerson: "",
    responsiblePerson: "",
    operationStatus: "",
    description: "",
    numberofusers: "",
    category: "",
    systemType: "",
    publicLaw: false,
  });

  const [users, setUsers] = useState<any>([]);

  const t = useTranslate();

  const handleBackSystems = (evt) => {
    history.push("/system/" + system["_id"]);
    //history.goBack();
  };

  function valueToPercentage(value, maxvalue) {
    return ((value - 1) / (maxvalue - 1)) * 100;
  }

  function percentageToValue(percentage, maxvalue) {
    return Math.round((maxvalue - 1) * (percentage / 100) + 1);
  }

  const handleSaveSystem = () => {
    if (
      SystemDescriptionTemplate.save(
        t,
        axios,
        auth,
        system.systemType[0],
        wizardData,
        system
      ) != null
    ) {
      SystemDescriptionTemplate.save(
        t,
        axios,
        auth,
        system.systemType[0],
        wizardData,
        system
      ).then((response) => {
        if (response == undefined) {
          notifyWarning(t("system.toast_systemsavefailed"));
        } else {
          fetchData();
          notifySuccess(t("system.toast_systemsaved"));
          history.push("/system/" + system["_id"]);
        }
      });
    }

    /*let values = GetWizardValuesByReferenceList(wizardData, [
            "name",
            "alias",
            "description",
            "systemtype",
            "limitationsdescription",
            "ownerorg",
            "ownersection",
            "ownerperson",
            "responsible",
            "providerinfo",
            "operationstatus",
            "numberofusers",
            "maxnumberofusers",
            "userscategory",
            "systemaccessmethod",
            "category",
            "specialavailabilityperiod",
            "availability",
            "availabilityreasoning",
            "operatingsystem",
            "operatingsystemversion",
            "systemdocumentationlink",
            "systemloginlink"
        ]);

        system["name"] = values.name;
        system["alias"] = values.alias;
        system["description"] = values.description;
        system["systemType"] = values.systemtype;
        system["limitationsdescription"] = values.limitationsdescription;
        
        system["ownerOrg"] = values.ownerorg;
        system["ownerSection"] = values.ownersection;
        system["ownerPerson"] = values.ownerperson;
        system["responsiblePerson"] = values.responsible;
        system["providerinfo"] = values.providerinfo;
        
        system["operationStatus"] = values.operationstatus;
        system["numberofusers"] = values.numberofusers;
        system["maxnumberofusers"] = values.maxnumberofusers;
        system["userscategory"] = values.userscategory;
        system["systemaccessmethod"] = values.systemaccessmethod;
        system["category"] = values.category;
        system["specialavailabilityperiod"] = values.specialavailabilityperiod;
        system["availability"] = valueToPercentage(values.availability, settings["systemvalueTNum"]);
        system["availabilityreasoning"] = values.availabilityreasoning;
        system["operatingsystem"] = values.operatingsystem;
        system["operatingsystemversion"] = values.operatingsystemversion;
        system["systemdocumentationlink"] = encodeURIComponent(values.systemdocumentationlink);
        system["systemloginlink"] = encodeURIComponent(values.systemloginlink);

        if (settings["customfieldsSystem"].length > 0) {
            wizardData[3]["fields"].map((field,key) => {
                if(system["customfields"] != undefined){
                    if (system["customfields"].find(item => item["id"] == field["title"]) == undefined) {
                        system["customfields"].push({
                            id: field["title"],
                            value: field["value"]
                        });
                    } else {
                        system["customfields"].find(item => item["id"] == field["title"])["value"] = field["value"];
                    }
                }
                return null;
            });
        }

        axios.put('/api/cra/systems/' + system["_id"], {
            system: system
        }, {
            headers: {'x-access-token': auth.user["token"]}
        }).then(function (response) {
            if (response == undefined) {
                notifyWarning(t("system.toast_systemsavefailed"));
            } else {
                fetchData();
                notifySuccess(t("system.toast_systemsaved"));
                history.push("/system/" + system["_id"]);
            }
        });*/
  };

  async function fetchData() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systems/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/users/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread(async (...res) => {
          setSystem(res[0].data);
          setUsers(res[1].data);

          //let tempWizardData = await SystemDescriptionTemplate.getSystemDescriptionTemplate(t, res[0].data.systemType[0], res[0].data, res[1].data);
          let tempWizardData = await SystemDescriptionTemplate.getSystemDescriptionTemplate(
            t,
            res[0].data.systemType[0],
            axios,
            auth,
            systemid
          );
          if (!res[0].data.accesses.includes("edit")) {
            for (const data of tempWizardData) {
              data.fields = data.fields.map((field) => {
                return { ...field, disabled: true };
              });
            }
          }

          setWizardData(tempWizardData);

          setWizardDataLoaded(true);
        })
      );
  }

  useEffect(() => {
    if (auth.user["userdata"] == undefined) {
      window.location.href = "/login";
    }
    fetchData();
  }, []);

  return (
    <div className="maincontent">
      <div className="systems-container">
        <div className="pagetitle-container">
          <h1>{t("system_description.system_title")}</h1>
          <Button className="newsystemBtn backbtn" onClick={handleBackSystems}>
            {
              /* t("system_description.system_back") */ t(
                "system.go_to_assessment"
              )
            }
          </Button>
        </div>
        <Card>
          <Card.Body>
            {wizardDataLoaded ? ( // Wait loading Wizard until data is ready
              <Wizard
                data={wizardData}
                options={{ disableComplete: true }}
                onComplete={handleSaveSystem}
              ></Wizard>
            ) : (
              ""
            )}
            <Button
              disabled={!system?.["accesses"]?.includes("edit") ?? true}
              className="newsystemBtn savebtn"
              onClick={handleSaveSystem}
            >
              {t("system_description.system_save")}
            </Button>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default SystemDescription;
