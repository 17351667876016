import React, { useEffect, useState, useRef } from "react";

import { Card, Button, Modal, Form, InputGroup, Nav } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";

import PageTable from "../../Shared/PageTable/PageTable";
import SiteAssistant from "../../Shared/SiteAssistant/SiteAssistant";
import Field from "../../Shared/Wizard/Field/Field";
import Shop from "../../Shared/Shop/Shop";
import DiriFaceDocked from "../../../Assets/Images/diriassistant/diri.svg";
import { useDynamicLanguage } from "../../../DynamicLanguageProvider";
import AccessControl from "./AccessControl/AccessControl";
import LocaleHandler from "../../../LocaleHandler";
import TransferRightsOwnership from "./TransferRightsOwnership";

import { generateUrl } from "../../../config";

import "react-toastify/dist/ReactToastify.css";
import "./Users.scss";

function Users(props) {
  const auth = useAuth();
  const t = useTranslate();
  const setLanguage = useDynamicLanguage().setLanguage;
  const td = useDynamicLanguage().td;
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const { setLocale } = React.useContext(LocaleHandler);
  const myRef = useRef<any>();
  const myInviteRef = React.useRef<any>();

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  const userName = t("users.user");
  const unit = t("users.new_unit");
  const role = t("users.new_role");
  const email = t("users.new_email");
  const mobile = t("users.new_mobile");
  const created = t("users.created");
  const lastSeen = t("users.last_seen");

  const [orgs, setOrgs] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [accessRolesOptions, setAccessRolesOptions] = useState<Array<any>>([]);
  const [allAccessRoles, setAllAccessRoles] = useState<Array<any>>([]);
  const [allAccessRoleNames, setAllAccessRoleNames] = useState<Array<any>>([]);

  const [adminInvite, setAdminInvite] = useState<boolean>(false);
  const [activePage, setActivePage] = useState("");
  const [showShop, setShowShop] = useState(false);

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const notifyAlert = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "alert-toast",
    });

  const [editUser, setEditUser] = useState({
    name: "",
    unit: [""],
    email: "",
    phone: "",
    role: "",
    orgRole: "",
    language: "",
    customfields: [{}],
  } as any);

  const [deleteUser, setDeleteUser] = useState({
    name: "",
    unit: [""],
    email: "",
    phone: "",
    role: "",
    orgRole: "",
    language: "",
  });

  const setEditUserName = (value) => {
    setEditUser((editUser) => ({ ...editUser, name: value }));
  };
  const setEditUserUnit = (value) => {
    setEditUser((editUser) => ({
      ...editUser,
      unit: [value],
      unitMaster: value,
    }));
  };
  const setEditUserEmail = (value) => {
    setEditUser((editUser) => ({ ...editUser, email: value }));
  };
  const setEditUserPhone = (value) => {
    setEditUser((editUser) => ({ ...editUser, phone: value }));
  };
  // const setEditUserPassword = (value) => {
  //   setEditUser((editUser) => ({ ...editUser, password: value }));
  // };
  const setEditUserLanguage = (value) => {
    setEditUser((editUser) => ({ ...editUser, language: value }));
  };
  const setEditUserOrgRole = (value) => {
    setEditUser((editUser) => ({ ...editUser, orgRole: value }));
  };

  const setEditUserAccessRoles = (value) => {
    setEditUser((editUser) => ({ ...editUser, accessRoles: value }));
  };

  const setEditUserRole = (value) => {
    let role = "";
    setIsAdmin(value);
    value != false ? (role = "Admin") : (role = "User");
    setEditUser((editUser) => ({ ...editUser, role: role }));
  };

  const setEditCustomTextfields = (value, key) => {
    let tempCustomefields = editUser["customfields"];

    if (tempCustomefields != undefined) {
      if (tempCustomefields.find((item) => item["id"] == key) == undefined) {
        tempCustomefields.push({
          id: key,
          value: value,
        });
      } else {
        (tempCustomefields.find((item) => item["id"] == key) as Object)[
          "value"
        ] = value;
      }
    } else {
      tempCustomefields = [
        {
          id: key,
          value: value,
        },
      ];
    }
    setEditUser((editUser) => ({
      ...editUser,
      customfields: JSON.parse(JSON.stringify(tempCustomefields)),
    }));
  };

  const [newuserName, setNewUserName] = useState("");
  const [newunit, setNewUnit] = useState("");
  const [newrole, setNewRole] = useState("User");
  const [neworgrole, setNewOrgRole] = useState("");
  const [newemail, setNewEmail] = useState("");
  // const [newpassword, setNewPassword] = useState("");
  const [newphone, setNewPhone] = useState("");
  const [newlanguage, setNewLanguage] = useState("en");
  const [newAccessRoles, setNewAccessRoles] = useState<Array<any>>([]);

  const [show, setShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [inviteShow, setInviteShow] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteAccessRoles, setInviteAccessRoles] = useState<Array<any>>([]);
  const [inviteUnit, setInviteUnit] = useState("");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateTable, setUpdateTable] = useState(0);

  const [countedUsers, setCountedUsers] = useState({});
  const [disabled, setDisabled] = useState(false);

  const [showTransferRightsOwnership, setShowTransferRightsOwnership] =
    useState(false);

  function handleClose() {
    setShow(false);
    cleanNewForm();
  }

  const handleCloseEditModal = () => setShowEditModal(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShow = () => setShow(true);

  const handleShowEditModal = (dataLine, evt) => {
    setEditUser(dataLine);
    setShowEditModal(true);
  };

  const handleShowDeleteModal = (dataLine, evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    setDeleteUser(dataLine);
    setShowDeleteModal(true);
  };

  const updateNewUserName = (event) => setNewUserName(event.target.value);
  const updateNewUnit = (event) => setNewUnit(event.target.value);
  const updateNewEmail = (event) => setNewEmail(event.target.value);
  // const updateNewPassword = (event) => setNewPassword(event.target.value);
  const updateNewPhone = (event) => setNewPhone(event.target.value);
  const updateNewLanguage = (event) => setNewLanguage(event.target.value);
  const updateNewOrgRole = (event) => setNewOrgRole(event.target.value);

  const updateNewRole = (value) => {
    let role = "";
    setIsAdmin(value);
    value != false ? (role = "Admin") : (role = "User");
    setNewRole(role);
  };

  // Empty the form after creating a new user.
  function cleanNewForm() {
    setNewUserName("");
    setNewUnit("");
    setNewRole("");
    setNewEmail("");
    // setNewPassword("");
    setNewPhone("");
    setNewLanguage("en");
    setEditCustomTextfields("", "");
    setIsAdmin(false);
  }

  function handleCreateInvite() {
    //required fields validation
    if (inviteEmail == "") {
      notifyWarning(t("users.toast_users_empty_name"));
      setDisabled(false);
      return undefined;
    }
    if (inviteAccessRoles.length == 0) {
      notifyWarning(t("users.toast_warning_oneroleneeded"));
      setDisabled(false);
      return undefined;
    }
    if (inviteUnit == "") {
      notifyWarning(t("users.toast_users_empty_unit"));
      setDisabled(false);
      return undefined;
    }

    let pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    if (inviteEmail.length != 0) {
      let error = false;
      inviteEmail.split("\n").forEach((newInvitation, key) => {
        if (!pattern.test(newInvitation)) {
          notifyWarning(t("users.invite_invalid_email"));
          error = true;
          setDisabled(false);
        }
      });
      if (error) return undefined;
    }

    axios
      .post(
        generateUrl("/api/orgelements/addinvitation/") + inviteUnit,
        {
          invitationEmail: inviteEmail,
          invitationLevel: adminInvite ? "Admin" : "User",
          invitationAccessRoles: inviteAccessRoles,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        setInviteEmail("");
        setInviteUnit("");
        notifySuccess(t("users.toast_user_invited"));
        setInviteShow(false);
      });
  }

  function checkEmail(userType) {
    setDisabled(true);
    axios
      .all([
        axios.single({
          method: "get",
          url:
            generateUrl("/api/users/userByIdAndEmail/") +
            (userType === "new"
              ? undefined + "/" + (newemail !== "" ? newemail : undefined)
              : editUser["_id"] +
                "/" +
                (editUser["email"] != "" ? editUser["email"] : undefined)),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          if (res[0].data.status === true) {
            notifyWarning(t("myaccount.email_already_in_use"));
            return;
          } else {
            if (userType === "new") {
              handleCreateUser();
            } else {
              handleEditUser();
            }
          }
        })
      );
  }

  function handleOrganizationChange(val) {
    if (window.confirm(t("myaccount.organizationchange_warning")) == true) {
      setEditUserUnit(val);
    }
  }

  function handleCreateUser() {
    //required fields validation
    if (newuserName === "") {
      notifyWarning(t("users.toast_users_empty_name"));
      setDisabled(false);
      return undefined;
    }
    if (newunit === "") {
      notifyWarning(t("users.toast_users_empty_unit"));
      setDisabled(false);
      return undefined;
    }
    if (newAccessRoles.length === 0) {
      notifyWarning(t("users.toast_warning_oneroleneeded"));
      setDisabled(false);
      return undefined;
    }
    if (newemail === "") {
      notifyWarning(t("users.toast_users_empty_email"));
      setDisabled(false);
      return undefined;
    }
    // if (newpassword === "") {
    //   notifyWarning(t("users.toast_users_empty_password"));
    //   setDisabled(false);
    //   return undefined;
    // }
    if (newemail !== "undefined") {
      let pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      if (!pattern.test(newemail)) {
        notifyWarning("Please enter valid email address.");
        setDisabled(false);
        return undefined;
      }
    }
    let newCustomfields: any = [];

    if (
      settings["customfieldsSystem"] !== undefined &&
      settings["customfieldsSystem"].length > 0
    ) {
      settings["customfieldsSystem"].map((field) => {
        newCustomfields.push({
          id: field["key"],
          value: "",
        });
      });
    }

    axios
      .post(
        generateUrl("/api/users"),
        {
          name: newuserName,
          customfields: newCustomfields,
          email: newemail,
          role: newrole,
          orgRole: neworgrole,
          unit: newunit,
          // password: newpassword,
          phone: newphone,
          language: newlanguage,
          accessRoles: newAccessRoles,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        cleanNewForm();
        fetchData();
        notifySuccess(t("users.toast_users_created"));
        handleClose();
        setUpdateTable(updateTable + 1);
        setDisabled(false);
      })
      .catch((error) => {
        // notifyAlert(t("users.invalid_password"));
        setDisabled(false);
      });
  }

  function handleEditUser() {
    //required fields validation
    if (editUser["name"] == "") {
      notifyWarning(t("users.toast_users_empty_name"));
      return undefined;
    }
    if (editUser["unit"] == "") {
      notifyWarning(t("users.toast_users_empty_unit"));
      return undefined;
    }
    if (editUser["accessRoles"].length == 0) {
      notifyWarning(t("users.toast_warning_oneroleneeded"));
      return undefined;
    }
    if (editUser["email"] == "") {
      notifyWarning(t("users.toast_users_empty_email"));
      return undefined;
    }
    if (newemail !== "undefined") {
      let pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      if (!pattern.test(editUser["email"])) {
        notifyWarning("Please enter valid email address.");
        return undefined;
      }
    }
    axios
      .put(
        generateUrl("/api/users/") + editUser["_id"],
        {
          name: editUser["name"],
          customfields: editUser["customfields"],
          email: editUser["email"],
          role: editUser["role"],
          orgRole: editUser["orgRole"],
          unit: editUser["unit"],
          unitMaster: editUser["unitMaster"],
          // password:
          //   editUser["password"] != "" ? editUser["password"] : undefined,
          phone: editUser["phone"],
          language: editUser["language"],
          accessRoles: editUser["accessRoles"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        let localUser = localStorage.getItem("user");
        if (
          localUser != null &&
          editUser["_id"] == JSON.parse(localUser)["userdata"]["_id"]
        ) {
          let localUserdata = localUser != null ? JSON.parse(localUser) : {};
          if (localUserdata != null) {
            localUserdata["userdata"] = res.data;
            localStorage.setItem("user", JSON.stringify(localUserdata));
          }
        }
        setLocale(res.data["language"].toLowerCase());
        setLanguage(res.data["language"].toLowerCase());
        fetchData();
        handleCloseEditModal();
        setUpdateTable(updateTable + 1);
        setDisabled(false);
      })
      .catch((error) => {
        // notifyAlert(t("users.invalid_password"));
        setDisabled(false);
      });
  }

  function handleDeleteUser() {
    axios
      .delete(
        generateUrl("/api/users/") + deleteUser["_id"],
        {},
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        if (res != undefined) {
          fetchData();
          notifySuccess(t("users.toast_users_deleted"));
          handleCloseDeleteModal();
          setUpdateTable(updateTable + 1);
        } else {
          notifyWarning(t("users.toast_users_deleteownuser"));
        }
      });
  }

  const handleKeyDownCreate = (event) => {
    if (event.key === "Enter") {
      handleCreateUser();
    }
  };

  const handleKeyDownEdit = (event) => {
    if (event.key === "Enter") {
      handleEditUser();
    }
  };

  async function fetchData() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/orgelements"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/accessRoles/options"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/accessRoles/allRoleNames"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/users/countedObjects"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setOrgs(res[0].data);
          setAllAccessRoles(res[1].data);
          setAllAccessRoleNames(res[2].data);
          setNewAccessRoles(
            res[1].data
              .filter((role) => role.defaultNewUsers == true)
              ?.map((role) => role?._id) ?? []
          );
          setInviteAccessRoles(
            res[1].data
              .filter((role) => role.defaultNewUsers == true)
              ?.map((role) => role?._id) ?? []
          );
          //setAccessRolesOptions(res[1].data.map(data => { return {label: data.name, value: data._id } }));
          setAccessRolesOptions(res[1].data);
          setCountedUsers(res[3].data);
        })
      )
      .catch((err) => console.log(err));
  }

  function pageTabClicked(selectedKey) {
    setActivePage(selectedKey);
  }

  useEffect(() => {
    if (auth.user["userdata"] == undefined) {
      auth.signout();
    }
    if (
      auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
        (rule) => rule.objType == "users" && rule.objSubType == "users"
      ).actions.read != "allow" &&
      auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
        (rule) => rule.objType == "users" && rule.objSubType == "accesscontrol"
      ).actions.read != "allow"
    ) {
      auth.signout();
    }
    fetchData();
    setActivePage(
      auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
        (rule) => rule.objType == "users" && rule.objSubType == "users"
      ).actions.read == "allow"
        ? "users"
        : auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
            (rule) =>
              rule.objType == "users" && rule.objSubType == "accesscontrol"
          ).actions.read == "allow"
        ? "accesscontrol"
        : ""
    );
  }, []);

  const formatDate = (date: Date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const dayWithZero = day < 10 ? `0${day}` : day;
    const monthWithZero = month < 10 ? `0${month}` : month;
    return `${dayWithZero}.${monthWithZero}.${year}`;
  };

  return (
    <div className="maincontent">
      <SiteAssistant dockedLogo={DiriFaceDocked} featurekey="users" />
      <div className="users-container">
        <div className="pagetitle-container">
          <Nav variant="tabs" activeKey={activePage} onSelect={pageTabClicked}>
            <Nav.Item className="pagetab">
              <Nav.Link
                disabled={
                  auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
                    (rule) =>
                      rule.objType == "users" && rule.objSubType == "users"
                  ).actions.read != "allow"
                }
                eventKey={"users"}
              >
                {t("users.users_tab")}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item data-satarget="accesscontroltab" data-safixed="false">
              <Nav.Link
                disabled={
                  auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
                    (rule) =>
                      rule.objType == "users" &&
                      rule.objSubType == "accesscontrol"
                  ).actions.read != "allow"
                }
                eventKey={"accesscontrol"}
              >
                {t("users.access_control_tab")}
              </Nav.Link>
            </Nav.Item>
          </Nav>

          {/* <h1>{t("users.page_name")}</h1> */}
        </div>
        {activePage == "users" &&
        auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
          (rule) => rule.objType == "users" && rule.objSubType == "users"
        ).actions.read == "allow" ? (
          <Card>
            <Card.Body>
              <h4 className="header-title">{t("users.title")}</h4>
              {countedUsers["countedUsers"] == undefined ||
              countedUsers["countedUsers"] > 0 ? (
                <div className="addUsersBtns">
                  <Button
                    className="newuserBtn"
                    disabled={
                      auth.user["userdata"]?.["actualAccessRole"]?.[
                        "rules"
                      ].find(
                        (rule) =>
                          rule.objType == "users" && rule.objSubType == "users"
                      ).actions.create != "allow" ||
                      countedUsers["countedUsers"] <= 0
                    }
                    onClick={handleShow}
                  >
                    {t("users.new")}
                  </Button>
                  <br />
                  <Button
                    className="newuserBtn"
                    disabled={
                      auth.user["userdata"]?.["actualAccessRole"]?.[
                        "rules"
                      ].find(
                        (rule) =>
                          rule.objType == "users" && rule.objSubType == "users"
                      ).actions.create != "allow" ||
                      countedUsers["countedUsers"] <= 0
                    }
                    onClick={() => setInviteShow(true)}
                  >
                    {t("users.inviteuser_btn")}
                  </Button>
                </div>
              ) : (
                <>
                  <div className="addUsersBtns">
                    <Tooltip
                      title={t("users.limit_exceeded")}
                      placement="bottom"
                    >
                      <div className="lockedElement-help">
                        <span>
                          <i className="dripicons-question" />
                        </span>
                      </div>
                    </Tooltip>
                    <Button
                      className="upgrade-btn"
                      onClick={() => {
                        setShowShop(true);
                      }}
                    >
                      <i className="dripicons-lock" />{" "}
                      {t("users.requires_update")}
                    </Button>
                  </div>
                </>
              )}

              {/* <p>{t("users.remaining") + " " + (countedUsers["countedUsers"] != undefined ? countedUsers["countedUsers"] : t("users.unlimited_amount"))} / {auth.user["userdata"]["actualAccessRole"]["rules"].find(rule => rule.objType == "users" && rule.objSubType == "users")?.limitations?.amount ?? t("users.unlimited_amount")}</p> */}

              <PageTable
                dataUrl={generateUrl("/api/users/getAllPaged/")}
                options={{
                  showFields: [
                    { label: userName, field: "name" },
                    { label: unit, field: "org" },
                    {
                      label: role,
                      field: "roles",
                      override: (dataLine) =>
                        dataLine.roles == ""
                          ? t("users.user_has_not_logged_in")
                          : dataLine.roles,
                    },
                    { label: email, field: "email" },
                    { label: mobile, field: "phone" },
                    {
                      label: created,
                      field: "date",
                      override: (dataLine) =>
                        formatDate(new Date(dataLine.date)),
                    },
                    {
                      label: lastSeen,
                      field: "lastLoggedIn",
                      override: (dataLine) =>
                        dataLine.lastLoggedIn
                          ? formatDate(new Date(dataLine.lastLoggedIn))
                          : "",
                    },
                  ],
                }}
                dataAfter={(dataLine) => (
                  <Button
                    disabled={!dataLine.accesses.includes("delete")}
                    onClick={(evt) => handleShowDeleteModal(dataLine, evt)}
                  >
                    {" "}
                    {t("users.delete")}{" "}
                  </Button>
                )}
                onLineClick={handleShowEditModal}
                hidePagination={false}
                hidePagesize={false}
                hideFilter={false}
                updateTable={updateTable}
                hover={true}
              />
            </Card.Body>
          </Card>
        ) : activePage == "accesscontrol" &&
          auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
            (rule) =>
              rule.objType == "users" && rule.objSubType == "accesscontrol"
          ).actions.read == "allow" ? (
          <AccessControl
            activeUser={localStorageUser}
            orgs={orgs}
            onSave={() => fetchData()}
          ></AccessControl>
        ) : null}
        <Modal
          show={inviteShow}
          onHide={() => setInviteShow(false)}
          onEntered={() => myInviteRef.current.focus()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("users.invite_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t("users.invite_description")}</p>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>{t("users.new_email")} *</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="textarea"
                type="text"
                rows={5}
                ref={myInviteRef}
                placeholder={t("users.new_email")}
                onChange={(evt) => setInviteEmail(evt.currentTarget.value)}
                value={inviteEmail}
              ></Form.Control>
            </InputGroup>

            <Field
              title={t("users.new_unit") + ": *"}
              type="treeselector"
              onChange={(val) => setInviteUnit(val)}
              options={{
                namekey: "name",
                list: orgs,
                selecttext: t("users.selecttext"),
                canceltext: t("users.cancel"),
              }}
            ></Field>
            <Tooltip
              title={
                inviteUnit == "" || inviteUnit == undefined
                  ? t("users.choose_org_first")
                  : ""
              }
              placement="bottom-end"
            >
              <div>
                <Field
                  disabled={inviteUnit == undefined || inviteUnit == ""}
                  type="selectmulti"
                  title={t("users.access_roles") + " *"}
                  value={inviteAccessRoles.map((val) => {
                    return {
                      value: val,
                      label: td(
                        allAccessRoles.find((role) => role._id == val)?.name
                      ),
                    };
                  })}
                  onChange={(val) =>
                    setInviteAccessRoles(val.map((v) => v.value))
                  }
                  options={{
                    selecttext: t("users.selecttext"),
                    choices: accessRolesOptions
                      .filter(
                        (role) =>
                          role.orgId == inviteUnit || role.orgInherit == true
                      )
                      .map((role) => {
                        return { value: role._id, label: td(role.name) };
                      }),
                  }}
                ></Field>
              </div>
            </Tooltip>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel posleft"
              onClick={() => setInviteShow(false)}
            >
              {t("users.cancel")}
            </Button>
            <Button onClick={handleCreateInvite}>
              {t("users.invite_btn")}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show}
          onHide={handleClose}
          onEntered={() => myRef.current.focus()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("users.new_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>{t("users.new_name")} *</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder={t("users.new_name")}
                ref={myRef}
                onChange={updateNewUserName}
                value={newuserName}
                onKeyDown={handleKeyDownCreate}
              ></Form.Control>
            </InputGroup>
            <Field
              title={t("users.new_unit") + " *"}
              type="treeselector"
              onChange={(val) => setNewUnit(val)}
              options={{
                namekey: "name",
                list: orgs,
                selecttext: t("users.selecttext"),
                canceltext: t("users.cancel"),
              }}
            ></Field>

            <Tooltip
              title={
                newunit == "" || newunit == undefined
                  ? t("users.choose_org_first")
                  : ""
              }
              placement="bottom-end"
            >
              <div>
                <Field
                  disabled={newunit == "" || newunit == undefined}
                  type="selectmulti"
                  title={t("users.access_roles") + " *"}
                  value={newAccessRoles.map((val) => {
                    return {
                      value: val,
                      label: td(
                        allAccessRoles.find((role) => role._id == val)?.name
                      ),
                    };
                  })}
                  onChange={(val) => setNewAccessRoles(val.map((v) => v.value))}
                  options={{
                    selecttext: t("users.selecttext"),
                    choices: accessRolesOptions
                      .filter(
                        (role) =>
                          role.orgId == newunit || role.orgInherit == true
                      )
                      .map((role) => {
                        return { value: role._id, label: td(role.name) };
                      }),
                  }}
                ></Field>
              </div>
            </Tooltip>

            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>{t("users.new_email")} *</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder={t("users.new_email")}
                onChange={updateNewEmail}
                value={newemail}
                onKeyDown={handleKeyDownCreate}
              ></Form.Control>
            </InputGroup>
            {/* <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>{t("users.new_password")} *</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                autoComplete="new-password"
                type="password"
                placeholder={t("users.new_password")}
                onChange={updateNewPassword}
                value={newpassword}
                onKeyDown={handleKeyDownCreate}
              ></Form.Control>
            </InputGroup> */}
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>{t("users.new_mobile")}</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder={t("users.new_mobile")}
                onChange={updateNewPhone}
                value={newphone}
                onKeyDown={handleKeyDownCreate}
              ></Form.Control>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>{t("users.language")}</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                onChange={updateNewLanguage}
                value={newlanguage}
                onKeyDown={handleKeyDownCreate}
              >
                <option value="en">{t("users.language_english")}</option>
                <option value="no">{t("users.language_norwegian")}</option>
              </Form.Control>
            </InputGroup>
            {settings["customfieldsUser"] != undefined &&
            settings["customfieldsUser"].length > 0
              ? settings["customfieldsUser"].map((item, key) => {
                  switch (item.type) {
                    case "string":
                      return (
                        <InputGroup className="mb-3" key={key}>
                          <InputGroup.Prepend>
                            <InputGroup.Text>{td(item.key)}</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            onChange={(evt) =>
                              setEditCustomTextfields(
                                evt.target.value,
                                item.key
                              )
                            }
                            defaultValue=""
                          />
                        </InputGroup>
                      );

                    case "dropdown":
                      return (
                        <InputGroup className="mb-3" key={key}>
                          <InputGroup.Prepend>
                            <InputGroup.Text>{td(item.key)}</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            as="select"
                            onChange={(evt) =>
                              setEditCustomTextfields(
                                evt.currentTarget.value,
                                item.key
                              )
                            }
                            defaultValue=""
                            onKeyDown={handleKeyDownCreate}
                          >
                            (<option value="">Gjør en valg</option>)
                            {item["options"] != undefined
                              ? item["options"].map((option, optionkey) => {
                                  return (
                                    <option key={optionkey} value={option}>
                                      {td(option)}
                                    </option>
                                  );
                                })
                              : ""}
                          </Form.Control>
                        </InputGroup>
                      );
                    default:
                      return null;
                  }
                })
              : undefined}
          </Modal.Body>
          <Modal.Footer>
            <Button className="cancel posleft" onClick={handleClose}>
              {t("users.cancel_without_save")}
            </Button>
            <Button disabled={disabled} onClick={() => checkEmail("new")}>
              {t("users.save")}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t("users.users_edit")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>{t("users.new_name")}</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder={t("users.new_name")}
                disabled={!editUser["accesses"]?.includes("edit") ?? true}
                onChange={(evt) => setEditUserName(evt.target.value)}
                value={editUser["name"]}
                onKeyDown={handleKeyDownEdit}
              ></Form.Control>
            </InputGroup>
            <Field
              disabled={
                (!editUser["accesses"]?.includes("edit") ?? true) ||
                (editUser["_id"] == auth.user["userdata"]._id &&
                  (auth.user?.["userdata"]["actualAccessRole"]["rules"].find(
                    (rule) =>
                      rule.objType == "organization" &&
                      rule.objSubType == "organization"
                  )?.actions?.change_own_organization_permanently != "allow" ??
                    true))
              }
              title={t("users.new_unit")}
              type="treeselector"
              onChange={(val) =>
                editUser["_id"] == auth.user["userdata"]._id
                  ? handleOrganizationChange(val)
                  : setEditUserUnit(val)
              }
              value={
                editUser["unitMaster"] != undefined
                  ? editUser["unitMaster"]
                  : editUser["unit"][0]
              }
              options={{
                namekey: "name",
                list: orgs,
                selecttext: t("users.selecttext"),
                canceltext: t("users.cancel"),
              }}
            ></Field>

            <Field
              disabled={
                (!editUser["accesses"]?.includes("edit") ?? true) ||
                (editUser["_id"] == auth.user["userdata"]._id &&
                  (auth.user?.["userdata"]["actualAccessRole"]["rules"].find(
                    (rule) =>
                      rule.objType == "users" &&
                      rule.objSubType == "accesscontrol"
                  )?.actions?.edit != "allow" ??
                    true))
              }
              type="selectmulti"
              title={t("users.access_roles") + " *"}
              value={
                editUser["accessRoles"] != undefined
                  ? editUser["accessRoles"].map((val) => {
                      return {
                        value: val,
                        label: td(
                          allAccessRoles.find((role) => role._id == val) !=
                            undefined
                            ? allAccessRoles.find((role) => role._id == val)
                                .name
                            : allAccessRoleNames.find(
                                (role) => role.value == val
                              )?.label ?? ""
                        ),
                      };
                    })
                  : []
              }
              onChange={(val) =>
                setEditUserAccessRoles(val.map((v) => v.value))
              }
              options={{
                selecttext: t("users.selecttext"),
                choices: accessRolesOptions
                  .filter(
                    (role) =>
                      role.orgId ==
                        (editUser["unitMaster"] != undefined
                          ? editUser["unitMaster"]
                          : editUser["unit"][0]) || role.orgInherit == true
                  )
                  .map((role) => {
                    return { value: role._id, label: td(role.name) };
                  }),
              }}
            ></Field>

            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>{t("users.new_email")}</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled={!editUser["accesses"]?.includes("edit") ?? true}
                type="text"
                placeholder={t("users.new_email")}
                onKeyDown={handleKeyDownEdit}
                onChange={(evt) => setEditUserEmail(evt.target.value)}
                value={editUser["email"]}
              ></Form.Control>
            </InputGroup>
            {/* <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>{t("users.new_password")}</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled={!editUser["accesses"]?.includes("edit") ?? true}
                autoComplete="new-password"
                type="password"
                placeholder={t("users.new_password")}
                onKeyDown={handleKeyDownEdit}
                onChange={(evt) => setEditUserPassword(evt.target.value)}
                value={editUser["password"]}
              ></Form.Control>
            </InputGroup> */}
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>{t("users.new_mobile")}</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled={!editUser["accesses"]?.includes("edit") ?? true}
                type="text"
                placeholder={t("users.new_mobile")}
                onKeyDown={handleKeyDownEdit}
                onChange={(evt) => setEditUserPhone(evt.target.value)}
                value={editUser["phone"]}
              ></Form.Control>
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>{t("users.language")}</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled={!editUser["accesses"]?.includes("edit") ?? true}
                as="select"
                onKeyDown={handleKeyDownEdit}
                onChange={(evt) =>
                  setEditUserLanguage((evt.target as HTMLSelectElement).value)
                }
                value={editUser["language"]}
              >
                <option value="no">{t("users.language_norwegian")}</option>
                <option value="en">{t("users.language_english")}</option>
              </Form.Control>
            </InputGroup>
            {settings["customfieldsUser"] != undefined &&
            settings["customfieldsUser"].length > 0
              ? settings["customfieldsUser"].map((item, key) => {
                  switch (item.type) {
                    case "string":
                      return (
                        <InputGroup className="mb-3" key={key}>
                          <InputGroup.Prepend>
                            <InputGroup.Text>{td(item.key)}</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            disabled={
                              !editUser["accesses"]?.includes("edit") ?? true
                            }
                            onKeyDown={handleKeyDownEdit}
                            onChange={(evt) =>
                              setEditCustomTextfields(
                                evt.target.value,
                                item.key
                              )
                            }
                            value={
                              editUser["customfields"] != undefined
                                ? editUser["customfields"].find(
                                    (field) => field["id"] == item.key
                                  ) != undefined
                                  ? (
                                      editUser["customfields"].find(
                                        (field) => field["id"] == item.key
                                      ) as Object
                                    )["value"]
                                  : ""
                                : ""
                            }
                          />
                        </InputGroup>
                      );

                    case "dropdown":
                      return (
                        <InputGroup className="mb-3" key={key}>
                          <InputGroup.Prepend>
                            <InputGroup.Text>{td(item.key)}</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            disabled={
                              !editUser["accesses"]?.includes("edit") ?? true
                            }
                            as="select"
                            onKeyDown={handleKeyDownEdit}
                            onChange={(evt) =>
                              setEditCustomTextfields(
                                evt.currentTarget.value,
                                item.key
                              )
                            }
                            value={
                              editUser["customfields"] != undefined
                                ? editUser["customfields"].find(
                                    (field) => field["id"] == item.key
                                  ) != undefined
                                  ? (
                                      editUser["customfields"].find(
                                        (field) => field["id"] == item.key
                                      ) as Object
                                    )["value"]
                                  : ""
                                : ""
                            }
                          >
                            (<option value="">{t("users.select")}</option>)
                            {item["options"] != undefined
                              ? item["options"].map((option, optionkey) => {
                                  return (
                                    <option key={optionkey} value={option}>
                                      {td(option)}
                                    </option>
                                  );
                                })
                              : ""}
                          </Form.Control>
                        </InputGroup>
                      );
                    default:
                      return null;
                  }
                })
              : undefined}
          </Modal.Body>
          <Modal.Footer>
            <Button className="cancel posleft" onClick={handleCloseEditModal}>
              {t("users.cancel_without_save")}
            </Button>
            <Button
              disabled={
                (!editUser["accesses"]?.includes("edit") || disabled) ?? true
              }
              onClick={() => checkEmail("edit")}
            >
              {t("users.save")}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t("users.delete_user")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t("users.delete_info")}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="cancel posleft" onClick={handleCloseDeleteModal}>
              {t("users.cancel")}
            </Button>
            <>
              <Button
                onClick={() => {
                  setShowTransferRightsOwnership(true);
                  handleCloseEditModal();
                  fetchData();
                }}
              >
                {t("users.transfer_user")}
                <Tooltip title={t("users.transfer_user_info")} placement="top">
                  <i className="dripicons-information ml-2" />
                </Tooltip>
              </Button>
            </>
            <Button onClick={handleDeleteUser}>{t("users.delete_user")}</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Shop
        show={showShop}
        onHide={() => setShowShop(false)}
        size="lg"
        orgid={auth.user["userdata"]["unit"][0]}
        onPurchaseComplete={() => setShowShop(false)}
      />
      <TransferRightsOwnership
        showTransferRightsOwnership={showTransferRightsOwnership}
        setShowTransferRightsOwnership={(e) => {
          setShowTransferRightsOwnership(e);
          handleCloseDeleteModal();
          fetchData();
        }}
        userSelected={deleteUser["_id"]}
      />
    </div>
  );
}

export default Users;
