import React, { useRef, useEffect } from 'react';

import LocaleHandler from "../../../LocaleHandler";

import "./LangSwitcher.scss";

import enFlag from '../../../Assets/Images/flags/icons8-great-britain-48.png';
import noFlag from '../../../Assets/Images/flags/icons8-norway-48.png';

function LangSwitcher(props) {
    const { setLocale, locale } = React.useContext(LocaleHandler);

    const thiscomponent = useRef(null);

    const [showSwitcher, setShowSwitcher] = React.useState(false);

    function handleLangClick(evt, lang) {
        evt.preventDefault();
        evt.stopPropagation();
        setLocale(lang);
        setShowSwitcher(false);

        props.onChange?.(lang);
    }

    useEffect(() => {

    }, [locale]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (thiscomponent.current && !(thiscomponent.current as any).contains(event.target)) {
                setShowSwitcher(false);
            }
        }
        
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [thiscomponent]);

    return(
        <div className="langswitcher" ref={thiscomponent}>
            <div className="display" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowSwitcher(true);
            }}>
                { locale == "en" ? <img alt="English" src={enFlag}></img> : null }
                { locale == "no" ? <img alt="Norwegian" src={noFlag}></img> : null }
            </div>
            <div className="switcher" style={{
                display: showSwitcher ? "block" : "none",
            }}>
                <img alt="English" onClick={e => handleLangClick(e, "en")} src={enFlag}></img>
                <img alt="Norwegian" onClick={e => handleLangClick(e, "no")} src={noFlag}></img>
            </div>
        </div>
    );
}

export default LangSwitcher;