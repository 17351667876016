import * as React from 'react';
import {
    useHistory,
    useLocation,
  } from "react-router-dom";

  import { toast } from 'react-toastify';

import './AnalysisEditor.scss';
import { Button, InputGroup, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../../Auth';
import { useAxios } from '../../../../AxiosHandler';
import { useTranslate } from 'react-polyglot';
import WizardEditor from '../../../Shared/WizardEditor/WizardEditor';

import SiteAssistant from "../../../Shared/SiteAssistant/SiteAssistant";
import DiriFaceDocked from "../../../../Assets/Images/diriassistant/diri.svg";
import { generateUrl } from '../../../../config';

function AnalysisEditor() {
    const location = useLocation();
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    let history = useHistory();
    const t = useTranslate();

    const [analysisTemplate, setAnalysisTemplate] = useState({ name: t("analysisEditor.new_template_name"), description: t("analysisEditor.new_template_description"), form: [{ fields: [] }], org: auth.user["userdata"]["unit"][0] });
    const [analysisTemplateId, setAnalysisTemplateId] = useState(0);
    const [analysisTemplateName, setAnalysisTemplateName] = useState(t("analysisEditor.new_template_name"));
    const [analysisTemplateDescription, setAnalysisTemplateDescription] = useState(t("analysisEditor.new_template_description"));

    const notifySuccess = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "success-toast"
    });

    const notifyWarning = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "warning-toast"
    });

    function handleEditTemplateName(value) {
        let tempTemplate = analysisTemplate;
        tempTemplate["name"] = value;
        setAnalysisTemplate(tempTemplate);
        setAnalysisTemplateName(value);
        localStorage.setItem("templatechanges", "true");
    }

    function handleEditTemplateDescription(value) {
        let tempTemplate = analysisTemplate;
        tempTemplate["description"] = value;
        setAnalysisTemplate(tempTemplate);
        setAnalysisTemplateDescription(value);
        localStorage.setItem("templatechanges", "true");
    }

    function saveAnalysisTemplate() {
        if (analysisTemplateId != 0) {
            axios.single({
                method: "put",
                url: generateUrl("/api/cra/analysisTemplates/") + analysisTemplateId,
                headers: {'x-access-token': auth.user["token"]},
                data: analysisTemplate
            }).then(res => {
                notifySuccess(t("analysisEditor.toast_analysis_saved"));
                localStorage.setItem("templatechanges", "false");
            });
        } else {
            axios.single({
                method: "post",
                url: generateUrl("/api/cra/analysisTemplates/"),
                headers: {'x-access-token': auth.user["token"]},
                data: analysisTemplate
            }).then(res => {
                notifySuccess(t("analysisEditor.toast_analysis_created"));
                setAnalysisTemplateId(res.data["_id"]);
                fetchData(res.data["_id"]);
                localStorage.setItem("templatechanges", "false");
            });
        }
    }

    function handleTemplateChange(template) {
        let tempTemplate = analysisTemplate;
        tempTemplate["form"] = template
        setAnalysisTemplate(tempTemplate);
        localStorage.setItem("templatechanges", "true");
    }

    function fetchData(templateId) {
        if (templateId != undefined && templateId != 0) {
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/analysisTemplates/") + templateId,
                headers: {'x-access-token': auth.user["token"]}
            }).then(res => {
                setAnalysisTemplate(res.data);
                setAnalysisTemplateName(res.data.name);
                setAnalysisTemplateDescription(res.data.description);
            });
            setAnalysisTemplateId(templateId);
        }
    }

    useEffect(() => {
        if (location["search"].split("?")[1] != undefined) {
            fetchData(location["search"].split("?")[1].split("&")[0].split("=")[1]);
            setAnalysisTemplateId(location["search"].split("?")[1].split("&")[0].split("=")[1]);
        }
    }, []);

    function tryNavigate(location) {
        let navigate = true;
        if (localStorage.getItem("templatechanges") == "true") {
            navigate = window.confirm(t("analysisEditor.confirmback"));
        }
        return navigate;
    }

    useEffect(() => {
        return history.block(tryNavigate);
    }, [history]);

    return(
        <div className="maincontent">
            <SiteAssistant
                dockedLogo={DiriFaceDocked}
                featurekey="analysiseditor"
            />
            <div className="systems-container">
                <div className="pagetitle-container" >
                    <h1>{t("analysisEditor.page_name")}</h1>
                </div>
                <Button style={{marginBottom: "20px"}} onClick={saveAnalysisTemplate}>{t("analysisEditor.savechangesbtn")}</Button>
                <Button style={{marginBottom: "20px", float: "right"}} onClick={() => { history.push("/analysislist")} }>{t("analysisEditor.backbtn")}</Button>
                <InputGroup className="mb-3" style={{ marginBottom: "60px" }}>
                    <InputGroup.Prepend>
                        <InputGroup.Text>{t("analysisEditor.template_name_input")}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control type="text" value={analysisTemplateName} onChange={(evt) => { handleEditTemplateName(evt.currentTarget.value); }} />
                </InputGroup>
                <InputGroup className="mb-3" style={{ marginBottom: "60px" }}>
                    <InputGroup.Prepend>
                        <InputGroup.Text>{t("analysisEditor.template_description_input")}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control type="text" value={analysisTemplateDescription} onChange={(evt) => { handleEditTemplateDescription(evt.currentTarget.value); }} />
                </InputGroup>
                <WizardEditor
                    data={analysisTemplate["form"]}
                    onChange={handleTemplateChange}
                ></WizardEditor>
                    {/* <Button onClick={saveAnalysisTemplate}>{t("analysisEditor.savechangesbtn")}</Button> */}
                <div className="analysiseditor-container">
                    <Button onClick={saveAnalysisTemplate}>{t("analysisEditor.savechangesbtn")}</Button>
                </div>
            </div>
        </div>
    );
}

export default AnalysisEditor;