import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import "../Systems.scss";
import { Card, Button, InputGroup, Form, Modal } from "react-bootstrap";
import { useAuth } from "../../../../Auth";
import { useAxios } from "../../../../AxiosHandler";
import { Bar } from "react-chartjs-2";
import { useTranslate } from "react-polyglot";
import { FormGroup, Switch, Tooltip, Grid } from "@material-ui/core";

import SiteAssistant from "../../../Shared/SiteAssistant/SiteAssistant";
import DiriFaceDocked from "../../../../Assets/Images/diriassistant/diri.svg";
import RiskMatrixChart from "../../../Shared/RiskMatrixChart/RiskMatrixChart";
import SystemInformation from "./SystemInformation/SystemInformation";
import Field from "../../../Shared/Wizard/Field/Field";
import TreatmentEditorModal from "../../../Shared/Modals/Edit/TreatmentEditorModal";
import TreatmentsMatrix from "./TreatmentsMatrix/TreatmentsMatrix";
import Shop from "../../../Shared/Shop/Shop";

import { generateUrl } from "../../../../config";

function System() {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  let history = useHistory();
  const t = useTranslate();

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  let { systemid } = useParams();

  const [system, setSystem] = useState<any>();
  const [orgelements, setOrgelements] = useState<any>();

  const [treatments, setTreatments] = useState([]);

  const [newcoowner, setNewcoowner] = useState("");
  const [privateChecked, setPrivateChecked] = useState(true);

  const [owner, setOwner] = useState({});
  const [coowners, setCoowners] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSystem, setDeleteSystem] = useState({});

  const [showOwnerchangeModal, setShowOwnerchangeModal] = useState(false);
  const [ownerchangeEmail, setOwnerchangeEmail] = useState("");
  const [ownerchangeVerifyEmail, setOwnerchangeVerifyEmail] = useState("");

  const [showSystemPrivacyModal, setShowSystemPrivacyModal] = useState(false);
  // Can be used for System Dashboard Configuration later. Do not delete
  /* const [showSystemConfigModal, setShowSystemConfigModal] = useState(false); */

  const [showCopySystemModal, setShowCopySystemModal] = useState(false);

  const [copyOrgid, setCopyOrgid] = useState("");
  const [copyKeepowner, setCopyKeepowner] = useState(false);
  const [copyKeepcoowners, setCopyKeepcoowners] = useState(false);
  const [copyKeepactionplan, setCopyKeepactionplan] = useState(false);
  const [
    copyKeepriskassessmentparticipants,
    setCopyKeepriskassessmentparticipants,
  ] = useState(false);
  const [copyKeepcreationdate, setCopyKeepcreationdate] = useState(false);

  const [copysystemvalues, setCopysystemvalues] = useState(true);
  const [copyoccurences, setCopyoccurences] = useState(true);
  const [copycauses, setCopycauses] = useState(true);
  const [copyconsequences, setCopyconsequences] = useState(true);
  const [copytreatments, setCopytreatments] = useState(true);

  const [
    copysystemvaluesFieldDescription,
    setCopysystemvaluesFieldDescription,
  ] = useState(false);
  const [copyoccurencesFieldDescription, setCopyoccurencesFieldDescription] =
    useState(false);
  const [copycausesFieldDescription, setCopycausesFieldDescription] =
    useState(false);
  const [
    copyconsequencesFieldDescription,
    setCopyconsequencesFieldDescription,
  ] = useState(false);
  const [copytreatmentsFieldDescription, setCopytreatmentsFieldDescription] =
    useState(false);
  const [
    copytreatmentsFieldDocumentationlink,
    setCopytreatmentsFieldDocumentationlink,
  ] = useState(false);

  const [copySettings, setCopySettings] = useState("");

  const [copying, setCopying] = useState(false);

  const [selectedTreatments, setSelectedTreatments] = useState<Array<any>>([]);
  const [allCauses, setAllCauses] = useState<Array<any>>([]);
  const [allConsequences, setAllConsequences] = useState<Array<any>>([]);
  const [allConsequenceTypes, setAllConsequenceTypes] = useState<Array<any>>(
    []
  );
  const [systemValues, setSystemValues] = useState<Array<any>>([]);
  const [systemOccurences, setSystemOccurences] = useState<Array<any>>([]);

  const [editTreatmentId, setEditTreatmentId] = useState();
  const [showEditTreatmentModal, setShowEditTreatmentModal] = useState(false);
  const [refetchTreatments, setRefetchTreatments] = useState(0);

  const [stepOneCompleted, setStepOneCompleted] = useState(false);
  const [stepTwoCompleted, setStepTwoCompleted] = useState(false);
  const [stepThreeCompleted, setStepThreeCompleted] = useState(false);
  const [stepFourCompleted, setStepFourCompleted] = useState(false);
  const [stepFiveCompleted, setStepFiveCompleted] = useState(false);

  const [assistantGotoKey, setAssistantGotoKey] = useState("");

  const [loadingSteps, setLoadingSteps] = useState(false);

  const [showShop, setShowShop] = useState(false);

  const [showSharingSystemModal, setShowSharingSystemModal] = useState(false);
  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const [showSharePublicWarningModal, setShowSharePublicWarningModal] =
    useState(false);
  const [shareWithUsersOptions, setShareWithUsersOptions] = useState<
    Array<any>
  >([]);
  const [sharePublicConfirmed, setSharePublicConfirmed] = useState(false);
  const [showPublicRow, setShowPublicRow] = useState(false);
  const [systemIsPublic, setSystemIsPublic] = useState(false);
  const [shareWithUser, setShareWithUser] = useState("");
  const [activeSharedWithRows, setActiveSharedWithRows] = useState<Array<any>>(
    []
  );
  const [editSystemAccessRules, setEditSystemAccessRules] = useState<any>({
    shared: [
      {
        type: "public",
        actions: [] as any,
      },
      {
        type: "organization",
        actions: [] as any,
      },
    ],
    users: [] as any,
  });
  const [isTemplate, setIsTemplate] = useState(false);

  const [countedSystems, setCountedSystems] = useState({
    loaded: false,
    countedOrgros: 0,
    countedOther: 0,
    countedSystem: 0,
  });

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const notifyWarning = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "warning-toast",
    });

  const notifyAlert = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "alert-toast",
    });

  function handleShowEditTreatmentModal(evt, treatment) {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    setEditTreatmentId(treatment._id);
    setShowEditTreatmentModal(true);
  }

  function handleShowSharePublicWarningModal() {
    setShowSharePublicWarningModal(true);
  }

  const handleBackSystems = (evt) => {
    // history.goBack();
    history.push("/systems");
  };

  const handleGotoSystemDescription = (evt) => {
    if (system["_id"] != undefined) {
      switch (system.systemType[0]) {
        case "orgros":
          history.push("/system/systemdesc/" + system["_id"]);
          break;

        default:
          history.push("/system/systemdesc/" + system["_id"]);
          break;
      }
    }
  };

  const handleGoToSystemSummary = (evt) => {
    if (system["_id"] != undefined) {
      history.push("/system/systemsummary/" + system["_id"]);
    }
  };

  const handleGotoValueEvaluation = (evt) => {
    /* if (stepOneCompleted) */
    history.push("/system/valueeval/" + system["_id"]);
  };

  const handleGotoBowtie = (evt) => {
    /* if (stepTwoCompleted) */
    history.push("/system/bowtie/" + system["_id"]);
  };

  const handleGotoActionplan = (evt) => {
    /* if (stepThreeCompleted) */
    history.push("/system/actionplan/" + system["_id"]);
  };

  const handleGotoComplete = (evt) => {
    /* if (stepFourCompleted) */
    history.push("/system/complete/" + system["_id"]);
  };

  const handleShowDeleteSystemModal = (evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    setDeleteSystem(system);

    setShowDeleteModal(true);
  };

  const startSystemCopy = () => {
    if (copyOrgid != "") {
      setCopying(true);
      axios
        .post(
          generateUrl("/api/cra/systems/copytoorg"),
          {
            systemid: systemid,
            orgid: copyOrgid,
            options: {
              keepowner: copyKeepowner,
              keepcoowners: copyKeepcoowners,
              keepactionplan: copyKeepactionplan,
              keepriskassessmentparticipants:
                copyKeepriskassessmentparticipants,
              keepcreationdate: copyKeepcreationdate,
              copysystemvalues: copysystemvalues,
              copyoccurences: copyoccurences,
              copycauses: copycauses,
              copyconsequences: copyconsequences,
              copytreatments: copytreatments,
              copysystemvaluesFieldDescription:
                copysystemvaluesFieldDescription,
              copyoccurencesFieldDescription: copyoccurencesFieldDescription,
              copycausesFieldDescription: copycausesFieldDescription,
              copyconsequencesFieldDescription:
                copyconsequencesFieldDescription,
              copytreatmentsFieldDescription: copytreatmentsFieldDescription,
            },
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then((res) => {
          setCopying(false);
          notifySuccess(t("system.toast_systemcopied"));
        })
        .catch((err) => {
          setCopying(false);
          notifyWarning(
            t("system.toast_systemcopyfailed") +
              "racopy-" +
              new Date().getTime().toString().slice(0, 10)
          );
        });
    } else {
      notifyWarning(t("system.toast_systemcopyerror"));
    }
  };
  const handleDeleteSystem = () => {
    if (deleteSystem["systemType"][0] == "orgros") {
      deleteRos(deleteSystem["_id"]);
    } else {
      axios
        .delete(
          generateUrl("/api/cra/systems/") + deleteSystem["_id"],
          {
            name: deleteSystem["_id"],
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then(function (response) {
          notifySuccess(t("system.toast_systemdeleted"));
          setShowDeleteModal(false);
          history.push("/systems");
        });
    }
  };

  const handleOwnerchangeSystem = () => {
    if (ownerchangeEmail == ownerchangeVerifyEmail) {
      axios
        .put(
          generateUrl("/api/cra/systems/changeowner/") + system["_id"],
          {
            newOwnerEmail: ownerchangeEmail,
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then(function (response) {
          if (response != undefined) {
            notifySuccess(t("system.toast_systemownerchanged"));
            setShowOwnerchangeModal(false);
            if (
              system.accesses.includes("edit_ownership") ||
              auth.user["userdata"]["email"] == ownerchangeEmail ||
              (coowners as Array<string>).includes(
                auth.user["userdata"]["email"] as string
              )
            ) {
              fetchData();
            } else {
              history.push("/systems");
            }
          } else {
            notifyWarning(t("system.toast_systemownerchangeerror"));
          }
        });
    } else {
      notifyWarning(t("system.toast_systemownerverifyerror"));
    }
  };

  function togglePrivate(evt) {
    let quickToggle = !privateChecked;
    setPrivateChecked(!privateChecked);

    axios
      .put(
        generateUrl("/api/cra/systems/changeprivate/") + system["_id"],
        {
          private: quickToggle,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        notifySuccess(t("system.toast_privatechanged"));
        if (
          quickToggle == true &&
          system["owner"] != auth.user["userdata"]["_id"] &&
          !(
            system["accessRules"]["users"]
              .map((item) => item["id"])
              .includes(auth.user["userdata"]["_id"]) &&
            system.accesses.includes("edit_ownership")
          )
        ) {
          history.push("/systems");
        } else {
          fetchData();
        }
      });
  }

  async function fetchData() {
    setLoadingSteps(true);
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systems/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systemvalues/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/occurences/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systems/ownership/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/treatments/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/orgelements/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/causes/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/consequences/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/consequencetypes/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/users/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setIsTemplate(res[0].data.isTemplate);
          checkIfPrivate(res[0].data);
          setSystem(res[0].data);
          if (res[0].data.accessRules != undefined) {
            setEditSystemAccessRules(res[0].data.accessRules);
            if (
              res[0].data.accessRules.shared
                .find((i) => i.type == "public")
                .actions.includes("read")
            ) {
              setShowPublicRow(true);
              setSystemIsPublic(true);
            }
          } else {
            setEditSystemAccessRules({
              shared: [
                {
                  type: "public",
                  actions: [] as any,
                },
                {
                  type: "organization",
                  actions: [] as any,
                },
              ],
              users: [],
            });
          }
          setSystemValues(res[1].data);
          setSystemOccurences(res[2].data);
          setOwner(res[3].data["owner"]);
          setCoowners(res[3].data["coowners"]);
          setTreatments(res[4].data);
          setOrgelements(res[5].data);
          res[0].data["private"] != undefined
            ? setPrivateChecked(res[0].data["private"])
            : setPrivateChecked(false);
          setAllCauses(res[6].data);
          setAllConsequences(res[7].data);
          setAllConsequenceTypes(res[8].data);
          setShareWithUsersOptions(
            res[9].data.map((d) => {
              return { label: d.email, value: d.email };
            })
          );

          setStepOneCompleted(
            res[0].data.stepscompleted != undefined
              ? res[0].data.stepscompleted.step1
              : false
          );
          setStepTwoCompleted(
            res[0].data.stepscompleted != undefined
              ? res[0].data.stepscompleted.step2
              : false
          );
          setStepThreeCompleted(
            res[0].data.stepscompleted != undefined
              ? res[0].data.stepscompleted.step3
              : false
          );
          setStepFourCompleted(
            res[0].data.stepscompleted != undefined
              ? res[0].data.stepscompleted.step4
              : false
          );

          if (res[0].data.stepscompleted != undefined) {
            setLoadingSteps(false);
          }

          if (res[0].data.stepscompleted == undefined) {
            axios
              .put(
                generateUrl("/api/cra/systems/updateprogress/") + systemid,
                {
                  step1:
                    res[0].data["name"] != undefined &&
                    res[0].data["name"] != ""
                      ? true
                      : false,
                  step2: res[1].data.length > 0 ? true : false,
                  step3: res[2].data.length > 0 ? true : false,
                  step4:
                    res[0].data["actionplan"] != undefined &&
                    res[0].data["actionplan"]["changedat"] != undefined &&
                    res[0].data["actionplan"]["changedat"] != ""
                      ? true
                      : false,
                },
                {
                  headers: { "x-access-token": auth.user["token"] },
                }
              )
              .then((res) => {
                fetchData();
              });
          }

          updateCheckmarks(res[0].data);
        })
      );
  }

  function fetchExcelSystemreport() {
    axios
      .single({
        method: "post",
        url: generateUrl("/api/cra/reports/fullsystemexcel/") + systemid,
        responseType: "arraybuffer",
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        let file = Buffer.from(res.data);
        const blob = new Blob([file], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download =
          "Systemrapport_" + new Date().toISOString().split(".")[0] + ".xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  // Will be used for docx download later! Do not delete
  function fetchFullSystemreport() {
    axios
      .single({
        method: "post",
        url: generateUrl("/api/cra/reports/fullsystem/") + systemid,
        responseType: "file",
        data: {
          matrix: null,
        },
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        let file = Buffer.from(res.data.data);
        const blob = new Blob([file], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download =
          "Systemrapport_" + new Date().toISOString().split(".")[0] + ".docx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  function updateCheckmarks(system) {
    // Stage 5 complete - System has a valid date in the future
    if (
      system["actionplan"] != undefined &&
      system["actionplan"]["finishedat"] != undefined &&
      system["actionplan"]["finishedat"] != "" &&
      new Date(system["actionplan"]["finishedvaliduntil"]) > new Date()
    ) {
      //setStep5complete(true);
      setStepFiveCompleted(true);
    }
  }

  function declineSharedWithRequest(userid) {
    axios
      .put(
        generateUrl("/api/cra/systems/removeSharedWithRequest/") +
          system["_id"],
        {
          userid,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (res) {
        setEditSystemAccessRules(res.data.accessRules);
        fetchData();
        notifySuccess(t("system.toast_coownerrequestdenied"));
      });
  }

  function approveSharedWithRequest(userid) {
    axios
      .put(
        generateUrl("/api/cra/systems/acceptSharedWithRequest/") +
          system["_id"],
        {
          userid,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (res) {
        setEditSystemAccessRules(res.data.accessRules);
        fetchData();
        notifySuccess(t("system.toast_coownerrequestdenied"));
      });
  }

  // Will be used for sharing type later! Do not delete
  function changeSharing(value) {
    axios
      .put(
        generateUrl("/api/cra/systems/changesharing/") + system["_id"],
        {
          sharing: value,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchData();
        notifySuccess(t("system.toast_sharingchanged"));
      });
  }
  function deleteRos(id) {
    axios
      .post(
        generateUrl("/api/orgelements/deleteros/") + id,
        {},
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        notifySuccess(t("organzation.ros.toasts.ros_deleted"));
        setShowDeleteModal(false);
        history.push("/systems");
      });
  }

  function canDeleteSystem() {
    switch (system.systemType[0]) {
      case "orgros":
        return true;
      default:
        return true;
    }
  }

  //Check if the system is private, if yes, check if user is owner or coowner
  function checkIfPrivate(system) {
    if (system.private != undefined && system.private == true) {
      if (
        system.owner == auth.user["userdata"]["_id"] ||
        system.coowners
          .map((coowner) => coowner._id)
          .includes(auth.user["userdata"]["_id"]) == true ||
        system.accessRules.users
          .find((user) => user.id == auth.user["userdata"]["_id"])
          ?.actions.includes("read")
      ) {
        return;
      } else {
        auth.signout();
      }
    }
  }

  function handleSaveProgression(step, checked) {
    setLoadingSteps(true);
    axios
      .put(
        generateUrl("/api/cra/systems/updateprogress/") + systemid,
        {
          completed: checked,
          step: step,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        fetchData();
      });
  }

  function handleChangeAction(evt, action, id?: String, type?: String) {
    if (id != undefined) {
      if (evt) {
        setEditSystemAccessRules({
          ...editSystemAccessRules,
          users: editSystemAccessRules.users.map((i) => {
            if (i.id == id) {
              if (!i.actions.includes(action)) {
                i.actions.push(action);
              }
              return i;
            } else {
              return i;
            }
          }),
        });
      } else {
        setEditSystemAccessRules({
          ...editSystemAccessRules,
          users: editSystemAccessRules.users.map((i) => {
            if (i.id == id) {
              i.actions = i.actions.filter((a) => a != action);
              return i;
            } else {
              return i;
            }
          }),
        });
      }
    } else if (type != undefined) {
      if (evt) {
        setEditSystemAccessRules({
          ...editSystemAccessRules,
          shared: editSystemAccessRules.shared.map((i) => {
            if (i.type == type) {
              if (!i.actions.includes(action)) {
                i.actions.push(action);
              }
              return i;
            } else {
              return i;
            }
          }),
        });
      } else {
        setEditSystemAccessRules({
          ...editSystemAccessRules,
          shared: editSystemAccessRules.shared.map((i) => {
            if (i.type == type) {
              i.actions = i.actions.filter((a) => a != action);
              return i;
            } else {
              return i;
            }
          }),
        });
      }
    }
  }

  async function addSharedWithUser() {
    let pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    if (!pattern.test(shareWithUser)) {
      notifyWarning(t("system.toast_invite_invalid_email"));
      return undefined;
    }
    await axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/users/") + shareWithUser,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          if (
            res[0].data.id != undefined &&
            !editSystemAccessRules.users
              .map((u) => u.id)
              .includes(res[0].data.id.toString())
          ) {
            editSystemAccessRules.users.push({ ...res[0].data, actions: [] });
            saveSharedWithAccessRules();
          }
          setShareWithUser("");
          setAutocompleteKey(autocompleteKey + 1);
        })
      );
  }

  function isChecked(action, id?: String, type?: String) {
    if (id != undefined) {
      return (
        editSystemAccessRules?.users
          .find((s) => s.id == id)
          ?.actions.includes(action) ?? false
      );
    } else if (type != undefined) {
      return (
        editSystemAccessRules?.shared
          .find((s) => s.type == type)
          ?.actions.includes(action) ?? false
      );
    }
    return false;
  }

  function saveAccessRules() {
    axios
      .put(
        generateUrl("/api/cra/systems/updateAccessRules/") + systemid,
        {
          shared: editSystemAccessRules.shared,
          users: undefined,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        handleIsTemplate();
        setShowSharePublicWarningModal(false);
        setEditSystemAccessRules(res.data.accessRules);
        setShareWithUser("");
        setAutocompleteKey(autocompleteKey + 1);
        notifySuccess(t("system.toast_accessruleschanged"));
      });
  }

  const handleIsTemplate = () => {
    axios
      .put(
        generateUrl("/api/cra/systems/setIsTemplate/") + systemid,
        {
          isTemplate: isTemplate,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        fetchData();
        // notifySuccess(t("settings.toast_savesuccess"));
      })
      .catch((err) => {
        fetchData();
        notifyAlert(t("forgot_password.something_went_wrong"));
      });
  };

  function saveSharedWithAccessRules(userid = undefined) {
    axios
      .put(
        generateUrl("/api/cra/systems/updateAccessRules/") + systemid,
        {
          shared: undefined,
          users: editSystemAccessRules.users.filter(
            (user) => user.id != userid
          ),
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        setShowSharePublicWarningModal(false);
        setEditSystemAccessRules(res.data.accessRules);
        setShareWithUser("");
        setAutocompleteKey(autocompleteKey + 1);
        notifySuccess(t("system.toast_accessruleschanged"));
        fetchData();
      });
  }

  async function getCountedSystemObjects() {
    axios
      .all([
        axios.single({
          method: "get",
          url:
            generateUrl("/api/cra/systems/countedObjects/byOrg/") +
            auth.user["userdata"]["unit"][0],
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then((res) => {
        setCountedSystems({ loaded: true, ...res[0].data });
      });
  }

  useEffect(() => {
    if (auth.user["userdata"] == undefined) {
      auth.signout();
    }
    fetchData();
  }, [refetchTreatments]);
  useEffect(() => {
    if (auth.user["userdata"] == undefined) {
      auth.signout();
    }
    getCountedSystemObjects();
  }, [copying == false]);

  useEffect(() => {
    setLoadingSteps(true);
    if (auth.user["userdata"] == undefined) {
      auth.signout();
    }
    fetchData();
  }, []);

  return (
    <div className="maincontent">
      <SiteAssistant
        dockedLogo={DiriFaceDocked}
        featurekey="system"
        gotoKey={assistantGotoKey}
      />
      <div className="systems-container">
        <div className="pagetitle-container">
          <h1>{t("system.system_page_name")}</h1>
        </div>
        <div className="btns">
          <Button className="newsystemBtn backbtn" onClick={handleBackSystems}>
            <i className="dripicons-arrow-thin-left" />
            {t("system.system_back")}
          </Button>
          {system ? (
            <div className="functionalityBtns">
              <Button onClick={() => setShowSystemPrivacyModal(true)}>
                <span>
                  <i className="dripicons-user" />
                  <i
                    style={{ fontSize: "10px", position: "absolute" }}
                    className="dripicons-lock"
                  />
                </span>
                {t("system.ownership")}
              </Button>
              <Button onClick={() => setShowSharingSystemModal(true)}>
                <span>
                  <i
                    style={{
                      fontSize: "17px",
                      marginRight: "5px",
                      position: "absolute",
                    }}
                    className="dripicons-user-group"
                  />
                  <i
                    style={{ marginLeft: "14px" }}
                    className="dripicons-network-2"
                  />
                </span>
                {t("system.share")}
              </Button>
              <Button
                disabled={
                  !system.accesses.includes("copy") ||
                  auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
                    (rule) =>
                      rule.objType == "system" &&
                      rule.objSubType ==
                        (system.systemType[0] != "other" &&
                        system.systemType[0] != "orgros"
                          ? "system"
                          : system.systemType[0])
                  )?.actions.create != "allow" ||
                  (system.systemType[0] == "other" &&
                    countedSystems.countedOther <= 0) ||
                  (system.systemType[0] == "orgros" &&
                    countedSystems.countedOrgros <= 0) ||
                  (system.systemType[0] != "orgros" &&
                    system.systemType[0] != "other" &&
                    countedSystems.countedSystem <= 0)
                }
                onClick={() => setShowCopySystemModal(true)}
              >
                <i className="dripicons-copy" />
                {t("system.configmodal.copybtn")}
              </Button>
              {/* Will be used for history and chat later, do not delete. */}
              {/* <Button>History</Button>
                            <Button>Chat</Button> */}
              <div className="printBtns">
                <Button className={"printBtnsParent"}>
                  <i className="dripicons-print" />
                  {t("system.printbtn")}
                </Button>
                <div className={"printBtnsChildren"}>
                  {auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
                    (rule) =>
                      rule.objType == "system" &&
                      rule.objSubType ==
                        (system.systemType[0] != "other" &&
                        system.systemType[0] != "orgros"
                          ? "system"
                          : system.systemType[0])
                  )?.actions.risk_assessment_excel_export == "allow" ? (
                    <Button
                      onClick={fetchExcelSystemreport}
                      disabled={
                        auth.user["userdata"]?.["actualAccessRole"]?.[
                          "rules"
                        ].find(
                          (rule) =>
                            rule.objType == "system" &&
                            rule.objSubType ==
                              (system.systemType[0] != "other" &&
                              system.systemType[0] != "orgros"
                                ? "system"
                                : system.systemType[0])
                        )?.actions.risk_assessment_excel_export != "allow"
                      }
                    >
                      {t("system.excel_report")}
                    </Button>
                  ) : (
                    <Button
                      className="upgrade-btn"
                      onClick={() => {
                        setShowShop(true);
                      }}
                    >
                      <i className="dripicons-lock" />{" "}
                      {t("system.requires_update_excel")}
                    </Button>
                  )}

                  {/* Will be used for docx download later. Do not delete */}
                  {/* <Button onClick={fetchFullSystemreport}>{t("system.document_report")}</Button> */}
                  {auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
                    (rule) => rule.objType == "system"
                  )?.actions.risk_assessment_excel_export == "allow" ? (
                    <Button
                      disabled={
                        auth.user["userdata"]?.["actualAccessRole"]?.[
                          "rules"
                        ].find((rule) => rule.objType == "system")?.actions
                          .risk_assessment_excel_export != "allow"
                      }
                      onClick={handleGoToSystemSummary}
                    >
                      {t("system.system_summary_print")}
                    </Button>
                  ) : (
                    <Button
                      className="upgrade-btn"
                      onClick={() => {
                        setShowShop(true);
                      }}
                    >
                      <i className="dripicons-lock" />{" "}
                      {t("system.requires_update_report")}
                    </Button>
                  )}
                </div>
              </div>

              {canDeleteSystem() ? (
                <Button
                  data-systemid={system["_id"]}
                  disabled={!system["accesses"].includes("delete")}
                  onClick={handleShowDeleteSystemModal}
                >
                  <i className="dripicons-document-delete" />
                  {t("system.delete")}
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>

        {system != undefined ? (
          <React.Fragment>
            <div className="row">
              <div className="col-md-6">
                <Card>
                  <Card.Body>
                    <h4 className="header-title">
                      {system["nameReadable"] != undefined ||
                      system["nameReadable"] != ""
                        ? system["nameReadable"]
                        : system["name"] != undefined
                        ? system["name"]
                        : " "}
                    </h4>
                    {/* <Button className="newsystemBtn backbtn" onClick={handleBackSystems}>{t("system.system_back")}</Button> */}
                    <hr />
                    <SystemInformation
                      system={system}
                      orgelements={orgelements}
                    ></SystemInformation>
                    <hr />
                    <div
                      className="pagetitle-container"
                      style={{ marginBottom: "10px" }}
                    >
                      <h1>{t("system.system_systemevaluation_title")}</h1>
                      <p>{t("system.system_systemevaluation_completed")}</p>
                    </div>

                    <div className="step-card-list">
                      <div
                        className={
                          "step-card-list-loading-visible " +
                          (loadingSteps ? "" : "step-card-list-loading-hidden")
                        }
                      >
                        <p>{t("system.system_systemevaluation_savingtext")}</p>
                      </div>
                      <Card
                        data-satarget="systemregistrationstep"
                        className={
                          "system-step-card enabled " +
                          (stepOneCompleted ? "complete " : "")
                        }
                      >
                        <Card.Body>
                          <div
                            className="system-step-title-div"
                            onClick={handleGotoSystemDescription}
                          >
                            <p className="system-step-title">
                              <span className="system-step-steptext">
                                {t("system.system_step1")}
                              </span>
                              <br />
                              {t("system.system_step1_description")}
                            </p>
                          </div>

                          {system.stepscompleted != undefined &&
                          system.stepscompleted.step1 == true ? (
                            <i className="dripicons-checkmark"></i>
                          ) : (
                            ""
                          )}
                          <div
                            data-satarget="systemregistrationwarning"
                            className={
                              "checkcircle " +
                              (!system["accesses"]?.includes("edit") ?? ""
                                ? "disabled"
                                : "")
                            }
                            onClick={() => {
                              if (system["accesses"].includes("edit")) {
                                handleSaveProgression(
                                  "step1",
                                  !stepOneCompleted
                                );
                                if (
                                  (/system/.test(
                                    system.systemType[0].toLowerCase()
                                  ) &&
                                    (system.description == undefined ||
                                      system.description == "")) ||
                                  (system.systemType[0] == "orgros" &&
                                    (system.data.orgdesc == undefined ||
                                      system.data.orgdesc == "")) ||
                                  (system.systemType[0] == "other" &&
                                    (system.data.description == undefined ||
                                      system.data.description == "") &&
                                    system.stepscompleted.step1 == false)
                                ) {
                                  setAssistantGotoKey("9");
                                }
                              }
                            }}
                          ></div>
                        </Card.Body>
                      </Card>

                      <Card
                        data-satarget="systemassetevaluationstep"
                        className={
                          "system-step-card enabled " +
                          (stepTwoCompleted ? "complete " : "")
                        }
                      >
                        <Card.Body>
                          <div
                            className="system-step-title-div"
                            onClick={handleGotoValueEvaluation}
                          >
                            <p className="system-step-title">
                              <span className="system-step-steptext">
                                {t("system.system_step2")}
                              </span>
                              <br />
                              {t("system.system_step2_valuation")}
                            </p>
                          </div>

                          {system.stepscompleted != undefined &&
                          system.stepscompleted.step2 == true ? (
                            <i className="dripicons-checkmark"></i>
                          ) : (
                            ""
                          )}
                          <div
                            data-satarget="systemassetevaluationwarning"
                            className={
                              "checkcircle " +
                              (!system["accesses"]?.includes("edit") ?? ""
                                ? "disabled"
                                : "")
                            }
                            onClick={() => {
                              if (system["accesses"].includes("edit")) {
                                if (
                                  systemValues.length == 0 &&
                                  system.stepscompleted.step2 == false
                                ) {
                                  setAssistantGotoKey("10");
                                }
                                if (system.stepscompleted != undefined) {
                                  handleSaveProgression(
                                    "step2",
                                    !stepTwoCompleted
                                  );
                                }
                              }
                            }}
                          ></div>
                        </Card.Body>
                      </Card>

                      <Card
                        data-satarget="systemriskassessmentstep"
                        className={
                          "system-step-card enabled " +
                          (stepThreeCompleted ? "complete " : "")
                        }
                      >
                        <Card.Body>
                          <div
                            className="system-step-title-div"
                            onClick={handleGotoBowtie}
                          >
                            <p className="system-step-title">
                              <span className="system-step-steptext">
                                {t("system.system_step3")}
                              </span>
                              <br />
                              {t("system.system_step3_risk_vulnerability")}
                            </p>
                          </div>

                          {system.stepscompleted != undefined &&
                          system.stepscompleted.step3 == true ? (
                            <i className="dripicons-checkmark"></i>
                          ) : (
                            ""
                          )}
                          <div
                            data-satarget="systemriskassessmentnwarning"
                            className={
                              "checkcircle " +
                              (!system["accesses"]?.includes("edit") ?? ""
                                ? "disabled"
                                : "")
                            }
                            onClick={() => {
                              if (system["accesses"].includes("edit")) {
                                if (
                                  systemOccurences.length == 0 &&
                                  system.stepscompleted.step3 == false
                                ) {
                                  setAssistantGotoKey("11");
                                }
                                if (system.stepscompleted != undefined) {
                                  handleSaveProgression(
                                    "step3",
                                    !stepThreeCompleted
                                  );
                                }
                              }
                            }}
                          ></div>
                        </Card.Body>
                      </Card>

                      <Card
                        data-satarget="systemrisktreatmentplanstep"
                        className={
                          "system-step-card " +
                          (stepFourCompleted ? "complete " : "") +
                          (!system["accesses"].includes("show_treatment_plan")
                            ? "disabled "
                            : "enabled")
                        }
                      >
                        <Card.Body>
                          <div
                            className="system-step-title-div"
                            onClick={(evt) =>
                              !system["accesses"].includes(
                                "show_treatment_plan"
                              )
                                ? null
                                : handleGotoActionplan(evt)
                            }
                          >
                            <p className="system-step-title">
                              <span className="system-step-steptext">
                                {t("system.system_step4")}
                              </span>
                              <br />
                              {t("system.system_step4_actionplan")}
                            </p>
                          </div>

                          {system.stepscompleted != undefined &&
                          system.stepscompleted.step4 == true ? (
                            <i className="dripicons-checkmark"></i>
                          ) : (
                            ""
                          )}
                          <div
                            data-satarget="systemrisktreatmentplannwarning"
                            className={
                              "checkcircle " +
                              (!system["accesses"]?.includes("edit") ?? ""
                                ? "disabled"
                                : "")
                            }
                            onClick={() => {
                              if (
                                !system["accesses"].includes(
                                  "show_treatment_plan"
                                )
                              ) {
                              } else {
                                if (system["accesses"].includes("edit")) {
                                  if (
                                    system["actionplan"] == undefined ||
                                    (system["actionplan"] != undefined &&
                                      system["actionplan"]["treatments"]
                                        ?.length == 0 &&
                                      system.stepscompleted.step4 == false)
                                  ) {
                                    setAssistantGotoKey("12");
                                  }
                                  if (system.stepscompleted != undefined) {
                                    handleSaveProgression(
                                      "step4",
                                      !stepFourCompleted
                                    );
                                  }
                                }
                              }
                            }}
                          ></div>
                        </Card.Body>
                      </Card>

                      <Card
                        data-satarget="systemcompleteandconfirmstep"
                        className={
                          "system-step-card " +
                          (stepFiveCompleted ? "complete " : "") +
                          (!system["accesses"].includes("show_treatment_plan")
                            ? "disabled "
                            : "enabled")
                        }
                      >
                        <Card.Body>
                          <div
                            className="system-step-title-div"
                            onClick={(evt) =>
                              !system["accesses"].includes(
                                "show_treatment_plan"
                              )
                                ? null
                                : handleGotoComplete(evt)
                            }
                          >
                            <p className="system-step-title">
                              <span className="system-step-steptext">
                                {t("system.system_step5")}
                              </span>
                              <br />
                              {t("system.system_step5_complete")}
                            </p>
                          </div>

                          {stepFiveCompleted ? (
                            <i className="dripicons-checkmark"></i>
                          ) : (
                            ""
                          )}
                          <Tooltip
                            title={t("system.toast_step5_completion_info")}
                            placement="right"
                          >
                            <div
                              className="checkcircle system-step-card-5"
                              onClick={() =>
                                !system["accesses"].includes(
                                  "show_treatment_plan"
                                )
                                  ? null
                                  : notifyWarning(
                                      t("system.toast_step5_completion_info")
                                    )
                              }
                            ></div>
                          </Tooltip>
                        </Card.Body>
                      </Card>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-6">
                {system != undefined ? (
                  <div data-satarget="systemriskmatrix">
                    <RiskMatrixChart
                      systemid={system["_id"]}
                      defaultValue={2}
                      treatmentsCompleted={
                        system["actionplan"] != undefined
                          ? system["actionplan"]["treatments"]
                          : []
                      }
                    ></RiskMatrixChart>
                  </div>
                ) : null}
                <Card data-satarget="systemtreatmentstatus">
                  <Card.Body>
                    <h4 className="header-title">
                      {t("system.treatments_graph.title")}
                    </h4>
                    <hr />
                    <p>{t("system.treatments_graph.description")}</p>
                    <div>
                      <Bar
                        data={{
                          labels: [
                            t("system.treatments_graph.state.open"),
                            t("system.treatments_graph.state.suggested"),
                            t("system.treatments_graph.state.planned"),
                            t("system.treatments_graph.state.processing"),
                            t("system.treatments_graph.state.closed"),
                          ],
                          datasets: [
                            {
                              backgroundColor: [
                                "#c8c8c8",
                                "#993742",
                                "#d8775a",
                                "#facd75",
                                "#5b8266",
                              ],
                              data: [
                                treatments.filter((i) => i["status"] == "open")
                                  .length,
                                treatments.filter(
                                  (i) => i["status"] == "suggested"
                                ).length,
                                treatments.filter(
                                  (i) => i["status"] == "planned"
                                ).length,
                                treatments.filter(
                                  (i) => i["status"] == "processing"
                                ).length,
                                treatments.filter(
                                  (i) => i["status"] == "closed"
                                ).length,
                              ],
                            },
                          ],
                        }}
                        options={{
                          title: {
                            display: true,
                            fontSize: 20,
                          },
                          legend: {
                            display: false,
                            position: "right",
                          },
                          scales: {
                            yAxes: [
                              {
                                stacked: true,
                                ticks: {
                                  suggestedMin: 0,
                                  stepSize: 1,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="row">
              <div data-satarget="systemcontrolmatrix" className="col-md-12">
                <TreatmentsMatrix
                  treatments={treatments}
                  selectedTreatments={selectedTreatments}
                  setSelectedTreatments={setSelectedTreatments}
                  allCauses={allCauses}
                  allConsequences={allConsequences}
                  allConsequenceTypes={allConsequenceTypes}
                  handleShowEditTreatmentModal={handleShowEditTreatmentModal}
                ></TreatmentsMatrix>
              </div>
            </div>
            {/* <div className="row">
                            <div className="col-md-6"> */}
            {/* <Card>
                                    <Card.Body>
                                        <h4 className="header-title">{t("system.report")}</h4>
                                        <hr/>
                                        {auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => 
                                            rule.objType == "system" && 
                                            rule.objSubType == (system.systemType[0] != "other" && system.systemType[0] != "orgros" ? "system" : system.systemType[0])
                                        )?.actions.risk_assessment_excel_export == "allow" ?
                                            <Button 
                                                onClick={fetchExcelSystemreport}
                                                disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => 
                                                        rule.objType == "system" && 
                                                        rule.objSubType == (system.systemType[0] != "other" && system.systemType[0] != "orgros" ? "system" : system.systemType[0])
                                                    )?.actions.risk_assessment_excel_export != "allow"}
                                            >{t("system.excel_report")}</Button>
                                        : 
                                            <Button 
                                                className="upgrade-btn"
                                                onClick={() => { setShowShop(true) }}
                                            ><i className="dripicons-lock" /> {t("system.requires_update_excel")}</Button>
                                        }
                                        <br />
                                        <br /> */}
            {/* Will be used for docx download later. Do not delete */}
            {/* <Button onClick={fetchFullSystemreport}>{t("system.document_report")}</Button> */}
            {/* {auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "system")?.actions.risk_assessment_excel_export == "allow" ?
                                            <Button
                                                disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "system")?.actions.risk_assessment_excel_export != "allow"}
                                                onClick={handleGoToSystemSummary}
                                            >{t("system.system_summary_print")}</Button>
                                        : 
                                            <Button 
                                                className="upgrade-btn"
                                                onClick={() => { setShowShop(true) }}
                                            ><i className="dripicons-lock" /> {t("system.requires_update_report")}</Button>
                                        }
                                    </Card.Body>
                                </Card> */}
            {/* {canDeleteSystem() ?
                                    <Button data-systemid={system["_id"]} disabled={!system["accesses"].includes("delete")} onClick={handleShowDeleteSystemModal}>{t("system.delete")}</Button>
                                : null} */}
            {/* </div>
                        </div> */}
            <Modal
              show={showDeleteModal}
              onHide={() => setShowDeleteModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>{t("system.delete_title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{t("system.delete_alert")}</p>
                <p>{t("system.delete_detail")}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setShowDeleteModal(false)}>
                  {t("system.delete_abort")}
                </Button>
                <Button onClick={handleDeleteSystem}>
                  {t("system.delete_delete")}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showOwnerchangeModal}
              onHide={() => setShowOwnerchangeModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>{t("system.ownerchange_title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{t("system.ownerchange_alert")}</p>
                <p>{t("system.ownerchange_detail")}</p>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      {t("system.ownerchange_emailfield")}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="email"
                    onChange={(evt) =>
                      setOwnerchangeEmail(
                        (evt.target as HTMLInputElement).value
                      )
                    }
                    value={ownerchangeEmail}
                  ></Form.Control>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      {t("system.ownerchange_emailfieldverify")}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="email"
                    onChange={(evt) =>
                      setOwnerchangeVerifyEmail(
                        (evt.target as HTMLInputElement).value
                      )
                    }
                    value={ownerchangeVerifyEmail}
                  ></Form.Control>
                </InputGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setShowOwnerchangeModal(false)}>
                  {t("system.ownerchange_abort")}
                </Button>
                <Button onClick={handleOwnerchangeSystem}>
                  {t("system.ownerchange_change")}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showSystemPrivacyModal}
              onHide={() => setShowSystemPrivacyModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h4 className="header-title">{t("system.ownership_title")}</h4>
                <br />
                <div className="riskMatrixToggle">
                  <FormGroup>
                    <Tooltip
                      title={
                        system.accesses.includes("edit_ownership")
                          ? t("system.ownership_private_tooltip_lockoutwarning")
                          : !system.accesses.includes("edit_ownership")
                          ? t("system.ownership_private_tooltip_locked")
                          : t("system.ownership_private_tooltip")
                      }
                      placement="top"
                    >
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Switch
                            size="small"
                            disabled={
                              !system.accesses.includes("edit_ownership")
                            }
                            checked={privateChecked}
                            onChange={togglePrivate}
                            color="primary"
                            className={"toggleswitch"}
                          />
                        </Grid>
                        <Grid item>{t("system.ownership_private")}</Grid>
                      </Grid>
                    </Tooltip>
                  </FormGroup>
                </div>
                <p>{t("system.ownership_description")}</p>
                <hr />
                <br />
                <div className="ownercontainer">
                  <p>
                    <span style={{ fontWeight: 600 }}>
                      {t("system.ownership_owner")}:
                    </span>{" "}
                    {owner != undefined ? owner["email"] : ""}
                  </p>
                  {system.accesses.includes("edit_ownership") ? (
                    <Button onClick={() => setShowOwnerchangeModal(true)}>
                      {t("system.ownership_ownerchangebtn")}
                    </Button>
                  ) : null}
                </div>

                <hr />
                <div className={"accessControl objectAccessControlDiv"}>
                  <div className={"row"}>
                    <div className="col-md-5">
                      <p>{t("system.access_control")}</p>
                    </div>
                    <div className="col-md-7">
                      <FormGroup>
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>{t("system.make_public")}</Grid>
                          <Grid item>
                            <Switch
                              size="small"
                              disabled={
                                !system.accesses.includes("edit_ownership")
                              }
                              checked={showPublicRow}
                              onChange={() => setShowPublicRow(!showPublicRow)}
                              color="primary"
                              className={"toggleswitch"}
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="objectAccessControlTableParentDiv">
                    <div
                      className={
                        !system.accesses.includes("edit_ownership")
                          ? "disabled"
                          : ""
                      }
                    ></div>
                    <div className="objectAccessControlUsersDiv">
                      <table
                        className={
                          "objectAccessControlTable systemObjectAccessControlTable"
                        }
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>{t("system.read")}</th>
                            <th>{t("system.edit")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            className={
                              "mainRow " + (!showPublicRow ? "hidden" : "")
                            }
                          >
                            <td
                              className="clickable"
                              onClick={() => {
                                !activeSharedWithRows.includes("public")
                                  ? setActiveSharedWithRows([
                                      ...activeSharedWithRows,
                                      "public",
                                    ])
                                  : setActiveSharedWithRows(
                                      activeSharedWithRows.filter(
                                        (item) => item != "public"
                                      )
                                    );
                              }}
                            >
                              <i
                                className={
                                  !activeSharedWithRows.includes("public")
                                    ? "dripicons-chevron-down"
                                    : "dripicons-chevron-up"
                                }
                              />
                            </td>
                            <td colSpan={2}>
                              {t("system.public_outside_org")}
                            </td>
                            <td>
                              <Field
                                type="indeterminatecheckbox"
                                checked={isChecked("read", undefined, "public")}
                                onChange={(evt) =>
                                  handleChangeAction(
                                    evt,
                                    "read",
                                    undefined,
                                    "public"
                                  )
                                }
                                disabled={
                                  !system.accesses.includes("edit_ownership")
                                }
                                parent={false}
                                title={""}
                                titlePlacement="start"
                              ></Field>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                        <tbody
                          className={
                            "systemObjectActionsRows " +
                            (activeSharedWithRows.includes("public") &&
                            showPublicRow
                              ? ""
                              : "hidden")
                          }
                        >
                          <tr>
                            <td colSpan={3}>
                              <p>{t("accessControl.table.copy")}</p>
                            </td>
                            <td colSpan={2}>
                              <Field
                                type="indeterminatecheckbox"
                                checked={isChecked("copy", undefined, "public")}
                                onChange={(evt) =>
                                  handleChangeAction(
                                    evt,
                                    "copy",
                                    undefined,
                                    "public"
                                  )
                                }
                                disabled={
                                  !system.accesses.includes("edit_ownership")
                                }
                                parent={false}
                                title={t("system.allow")}
                                titlePlacement="end"
                              ></Field>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <p>
                                {t("accessControl.table.show_treatmentplan")}
                              </p>
                            </td>
                            <td colSpan={2}>
                              <Field
                                type="indeterminatecheckbox"
                                checked={isChecked(
                                  "show_treatment_plan",
                                  undefined,
                                  "public"
                                )}
                                onChange={(evt) =>
                                  handleChangeAction(
                                    evt,
                                    "show_treatment_plan",
                                    undefined,
                                    "public"
                                  )
                                }
                                disabled={
                                  !system.accesses.includes("edit_ownership")
                                }
                                parent={false}
                                title={t("system.allow")}
                                titlePlacement="end"
                              ></Field>
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr className="mainRow">
                            <td
                              className="clickable"
                              onClick={() => {
                                !activeSharedWithRows.includes("organization")
                                  ? setActiveSharedWithRows([
                                      ...activeSharedWithRows,
                                      "organization",
                                    ])
                                  : setActiveSharedWithRows(
                                      activeSharedWithRows.filter(
                                        (item) => item != "organization"
                                      )
                                    );
                              }}
                            >
                              <i
                                className={
                                  !activeSharedWithRows.includes("organization")
                                    ? "dripicons-chevron-down"
                                    : "dripicons-chevron-up"
                                }
                              />
                            </td>
                            <td colSpan={2}>{t("system.entire_org")}</td>
                            <td>
                              <Field
                                type="indeterminatecheckbox"
                                checked={isChecked(
                                  "read",
                                  undefined,
                                  "organization"
                                )}
                                onChange={(evt) => {
                                  if (evt) {
                                    handleChangeAction(
                                      evt,
                                      "read",
                                      undefined,
                                      "organization"
                                    );
                                  } else {
                                    handleChangeAction(
                                      evt,
                                      "read",
                                      undefined,
                                      "organization"
                                    );
                                    handleChangeAction(
                                      evt,
                                      "edit",
                                      undefined,
                                      "organization"
                                    );
                                  }
                                }}
                                disabled={
                                  !system.accesses.includes("edit_ownership")
                                }
                                parent={false}
                                title={""}
                                titlePlacement="start"
                              ></Field>
                            </td>
                            <td>
                              <Field
                                type="indeterminatecheckbox"
                                checked={isChecked(
                                  "edit",
                                  undefined,
                                  "organization"
                                )}
                                onChange={(evt) => {
                                  if (evt) {
                                    handleChangeAction(
                                      evt,
                                      "read",
                                      undefined,
                                      "organization"
                                    );
                                    handleChangeAction(
                                      evt,
                                      "edit",
                                      undefined,
                                      "organization"
                                    );
                                  } else {
                                    handleChangeAction(
                                      evt,
                                      "edit",
                                      undefined,
                                      "organization"
                                    );
                                  }
                                }}
                                disabled={
                                  !system.accesses.includes("edit_ownership")
                                }
                                parent={false}
                                title={""}
                                titlePlacement="start"
                              ></Field>
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          className={
                            "systemObjectActionsRows " +
                            (activeSharedWithRows.includes("organization")
                              ? ""
                              : "hidden")
                          }
                        >
                          <tr>
                            <td colSpan={3}>
                              <p>{t("accessControl.table.copy")}</p>
                            </td>
                            <td colSpan={2}>
                              <Field
                                type="indeterminatecheckbox"
                                checked={isChecked(
                                  "copy",
                                  undefined,
                                  "organization"
                                )}
                                onChange={(evt) =>
                                  handleChangeAction(
                                    evt,
                                    "copy",
                                    undefined,
                                    "organization"
                                  )
                                }
                                disabled={
                                  !system.accesses.includes("edit_ownership")
                                }
                                parent={false}
                                title={t("system.allow")}
                                titlePlacement="end"
                              ></Field>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <p>
                                {t("accessControl.table.show_treatmentplan")}
                              </p>
                            </td>
                            <td colSpan={2}>
                              <Field
                                type="indeterminatecheckbox"
                                checked={isChecked(
                                  "show_treatment_plan",
                                  undefined,
                                  "organization"
                                )}
                                onChange={(evt) =>
                                  handleChangeAction(
                                    evt,
                                    "show_treatment_plan",
                                    undefined,
                                    "organization"
                                  )
                                }
                                disabled={
                                  !system.accesses.includes("edit_ownership")
                                }
                                parent={false}
                                title={t("system.allow")}
                                titlePlacement="end"
                              ></Field>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <p>
                                {t("accessControl.table.sign_treatmentplan")}
                              </p>
                            </td>
                            <td colSpan={2}>
                              <Field
                                type="indeterminatecheckbox"
                                checked={isChecked(
                                  "sign_treatment_plan",
                                  undefined,
                                  "organization"
                                )}
                                onChange={(evt) =>
                                  handleChangeAction(
                                    evt,
                                    "sign_treatment_plan",
                                    undefined,
                                    "organization"
                                  )
                                }
                                disabled={
                                  !system.accesses.includes("edit_ownership")
                                }
                                parent={false}
                                title={t("system.allow")}
                                titlePlacement="end"
                              ></Field>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <div className="objectAccessControlUsersDiv">
                                        {editSystemAccessRules?.users.length != 0 ?
                                            <div>
                                                {editSystemAccessRules?.users.filter(user => user.accepted == true).length != 0 ? 
                                                    <div>
                                                        <p>{t("system.shared_with")}</p>
                                                        <div className="objectAccessControlTableParentDiv">
                                                            <div className={!system.accesses.includes("edit_ownership") ? "disabled" : ""}>
                                                            
                                                            </div>
                                                            
                                                            <table className={"objectAccessControlTable systemObjectAccessControlTable"}>
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th>{t("system.read")}</th>
                                                                        <th>{t("system.edit")}</th>
                                                                        <th></th>
                                                                        
                                                                    </tr>
                                                                </thead>
                                                                    {editSystemAccessRules?.users.filter(user => user.accepted == true).map((user, key) => {
                                                                        return (
                                                                            <React.Fragment>
                                                                            <tbody>
                                                                                <tr key={key} className="mainRow">
                                                                                    <td className="clickable" onClick={() => {!activeSharedWithRows.includes(user.id) ? setActiveSharedWithRows([...activeSharedWithRows, user.id]) : setActiveSharedWithRows(activeSharedWithRows.filter(item => item != user.id))}}><i className={!activeSharedWithRows.includes(user.id) ? "dripicons-chevron-down" : "dripicons-chevron-up"} /></td>
                                                                                    <td>{user.name != "" ? <p>{user.name}, <br/>{user.email}</p> : <p>{user.email}</p>}</td>
                                                                                    <td>
                                                                                        <Field
                                                                                            type="indeterminatecheckbox"
                                                                                            checked={isChecked("read", user.id, undefined)}
                                                                                            onChange={(evt) => { 
                                                                                                if(evt) {
                                                                                                    handleChangeAction(evt, "read", user.id, undefined)
                                                                                                } else {
                                                                                                    handleChangeAction(evt, "read", user.id, undefined);
                                                                                                    handleChangeAction(evt, "edit", user.id, undefined);
                                                                                                    handleChangeAction(evt, "copy", user.id, undefined);
                                                                                                    handleChangeAction(evt, "edit_ownership", user.id, undefined);
                                                                                                    handleChangeAction(evt, "delete", user.id, undefined);
                                                                                                    handleChangeAction(evt, "show_treatment_plan", user.id, undefined);
                                                                                                    handleChangeAction(evt, "sign_treatment_plan", user.id, undefined);
                                                                                                } }}
                                                                                            disabled={!system.accesses.includes("edit_ownership")}
                                                                                            parent={false}
                                                                                            title={""}
                                                                                            titlePlacement="start"
                                                                                        ></Field>
                                                                                    </td>
                                                                                    <td>
                                                                                        <Field
                                                                                            type="indeterminatecheckbox"
                                                                                            checked={isChecked("edit", user.id, undefined)}
                                                                                            onChange={(evt) => { 
                                                                                                if(evt) {
                                                                                                    handleChangeAction(evt, "read", user.id, undefined);
                                                                                                    handleChangeAction(evt, "edit", user.id, undefined);
                                                                                                } else {
                                                                                                    handleChangeAction(evt, "edit", user.id, undefined);
                                                                                                    handleChangeAction(evt, "copy", user.id, undefined);
                                                                                                    handleChangeAction(evt, "edit_ownership", user.id, undefined);
                                                                                                    handleChangeAction(evt, "delete", user.id, undefined);
                                                                                                    handleChangeAction(evt, "sign_treatment_plan", user.id, undefined);

                                                                                                }}}
                                                                                            disabled={!system.accesses.includes("edit_ownership")}
                                                                                            parent={false}
                                                                                            title={""}
                                                                                            titlePlacement="start"
                                                                                        ></Field>
                                                                                    </td>
                                                                                    <td>
                                                                                    {system.accesses.includes("edit_ownership") ?
                                                                                        <Button onClick={() => {setEditSystemAccessRules({...editSystemAccessRules, users: editSystemAccessRules.users.filter(u => u.id != user.id)}); saveAccessRules(user.id);}}>{t("system.remove")}</Button>
                                                                                    : null}
                                                                                    </td>
                                                                                    
                                                                                </tr>
                                                                            </tbody>
                                                                            <tbody className={"systemObjectActionsRows " + (activeSharedWithRows.includes(user.id) ? "" : "hidden")}>
                                                                                <tr>
                                                                                    <td colSpan={2}><p>{t("accessControl.table.copy")}</p></td>
                                                                                    <td colSpan={3}>
                                                                                        <Field
                                                                                            type="indeterminatecheckbox"
                                                                                            checked={isChecked("copy", user.id, undefined)}
                                                                                            onChange={(evt) => handleChangeAction(evt, "copy", user.id, undefined)}
                                                                                            disabled={!system.accesses.includes("edit_ownership")}
                                                                                            parent={false}
                                                                                            title={t("system.allow")}
                                                                                            titlePlacement="end"
                                                                                        ></Field>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    
                                                                                    <td colSpan={2}><p>{t("accessControl.table.edit_ownership")}</p></td>
                                                                                    <td colSpan={3}>
                                                                                        <Field
                                                                                            type="indeterminatecheckbox"
                                                                                            checked={isChecked("edit_ownership", user.id, undefined)}
                                                                                            onChange={(evt) => handleChangeAction(evt, "edit_ownership", user.id, undefined)}
                                                                                            disabled={!system.accesses.includes("edit_ownership")}
                                                                                            parent={false}
                                                                                            title={t("system.allow")}
                                                                                            titlePlacement="end"
                                                                                        ></Field>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                
                                                                                    <td colSpan={2}><p>{t("system.delete")}</p></td>
                                                                                    <td colSpan={3}>
                                                                                        <Field
                                                                                            type="indeterminatecheckbox"
                                                                                            checked={isChecked("delete", user.id, undefined)}
                                                                                            onChange={(evt) => handleChangeAction(evt, "delete", user.id, undefined)}
                                                                                            disabled={!system.accesses.includes("edit_ownership")}
                                                                                            parent={false}
                                                                                            title={t("system.allow")}
                                                                                            titlePlacement="end"
                                                                                        ></Field>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className={activeSharedWithRows.includes(user.id) ? "" : "hidden"}>
                                                                                    <td colSpan={2}>treatmentplan</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    
                                                                                    <td colSpan={2}><p>{t("accessControl.table.show_treatmentplan")}</p></td>
                                                                                    <td colSpan={3}>
                                                                                        <Field
                                                                                            type="indeterminatecheckbox"
                                                                                            checked={isChecked("show_treatment_plan", user.id, undefined)}
                                                                                            onChange={(evt) => handleChangeAction(evt, "show_treatment_plan", user.id, undefined)}
                                                                                            disabled={!system.accesses.includes("edit_ownership")}
                                                                                            parent={false}
                                                                                            title={t("system.allow")}
                                                                                            titlePlacement="end"
                                                                                        ></Field>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={2}><p>{t("accessControl.table.sign_treatmentplan")}</p></td>
                                                                                    <td colSpan={3}>
                                                                                        <Field
                                                                                            type="indeterminatecheckbox"
                                                                                            checked={isChecked("sign_treatment_plan", user.id, undefined)}
                                                                                            onChange={(evt) => handleChangeAction(evt, "sign_treatment_plan", user.id, undefined)}
                                                                                            disabled={!system.accesses.includes("edit_ownership")}
                                                                                            parent={false}
                                                                                            title={t("system.allow")}
                                                                                            titlePlacement="end"
                                                                                        ></Field>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                            
                                                            </table>
                                                            
                                                        </div>
                                                    </div>
                                                : null}
                                                {system.accesses.includes("edit_ownership") && editSystemAccessRules?.users.filter(user => user.accepted == false).length != 0 ?
                                                    <div>
                                                        <p>{t("system.requested_shared_with")}</p>
                                                        <table className={"objectAccessControlTable"}>
                                                            {editSystemAccessRules?.users.filter(user => user.accepted == false).map((user, key) => {
                                                                return (
                                                                    <tbody>
                                                                        <tr key={key} className="mainRow">
                                                                            <td>{user.name != "" ? <p>{user.name}, <br/>{user.email}</p> : <p>{user.email}</p>}</td>
                                                                            <td><Button onClick={() => approveSharedWithRequest(user.id)}>{t("system.approve")}</Button></td>
                                                                            <td><Button onClick={() => declineSharedWithRequest(user.id) }>{t("system.decline")}</Button></td>
                                                                        </tr>
                                                                    </tbody>
                                                                )
                                                            })}
                                                        </table>
                                                    </div>
                                                : null}  
                                            </div> 
                                        : null}
                                        <div key={autocompleteKey} className="objectAccessControlAddUsersDiv">
                                            <Field
                                                type="input"
                                                value={shareWithUser != undefined ? shareWithUser : ""}
                                                title={t("system.share_with_user")}
                                                onChange={value => setShareWithUser(value)}
                                                disabled={!system.accesses.includes("edit_ownership")}
                                                placeholder={t("system.enter_email")}
                                            ></Field>
                                            <Field
                                                type="autocomplete"
                                                value={shareWithUser != undefined ? shareWithUser : ""}
                                                title={t("system.share_with_user")}
                                                onChange={value => setShareWithUser(value)}
                                                disabled={!system.accesses.includes("edit_ownership")}
                                                options = {{
                                                    list: shareWithUsersOptions,
                                                    minlength: 2,
                                                    noresults: "no matches",
                                                    placeholder: t("system.enter_email")
                                                }}
                                            ></Field>
                                            <Button 
                                                onClick={addSharedWithUser}
                                                disabled={!system.accesses.includes("edit_ownership")}
                                            >{t("system.share")}</Button>
                                        </div>
                                    </div> */}
                  {/* <div>
                                        <br />
                                        {system != undefined && system.accesses.includes("edit_ownership") ? 
                                            <Button 
                                                style={{"float": "right"}} 
                                                onClick={() => ((system.accessRules == undefined || system.accessRules.shared.find(s => s.type == "public")?.actions.includes("read")) && editSystemAccessRules.shared.find(s => s.type == "public").actions.includes("read")) && !systemIsPublic ? handleShowSharePublicWarningModal() : saveAccessRules()}
                                            >
                                                {system != undefined && system.accesses.includes("edit_ownership") ? t("system.save_access_control") : t("system.close")}
                                            </Button>
                                        : null }
                                        <br /><br />
                                    </div> */}
                </div>

                {/* Will be used for sharing later. Do not delete */}
                {/*<InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>{t("system.ownership_sharing")}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control disabled={(auth.user["userdata"]["_id"] != (owner != null ? owner["_id"] : "")) && auth.user["userdata"]["role"] != "Admin"} as="select" onChange={evt => changeSharing((evt.target as HTMLSelectElement).value)} value={system["sharing"]} >
                                        <option value="none">{t("system.ownership_sharing_none")}</option>
                                        <option value="org">{t("system.ownership_sharing_org")}</option>
                                        <option value="public">{t("system.ownership_sharing_public")}</option>
                                    </Form.Control>
                                </InputGroup>*/}

                {/* <p style={{fontWeight: 600}}>{t("system.ownership_coowners")}</p>
                                <Table striped size="sm">
                                    <tbody>
                                        {coowners.map((coowner, key) => { return (
                                            <tr key={key}>
                                                <td>{coowner["email"]}</td>
                                                {system.accesses.includes("edit_ownership") ?
                                                    <td style={{width: "102px"}}><Button onClick={() => removeCoowner(coowner["_id"])}>{t("system.ownership_deletecoownerbtn")}</Button></td>
                                                : null}
                                            </tr>
                                        )})}
                                    </tbody>
                                </Table> */}
                {/* {system.accesses.includes("edit_ownership") ?
                                    <div>
                                        <InputGroup className="mb-3" style={{width: "calc(100% - 140px)", float: "left"}}>
                                            <Form.Control style={{marginLeft: "0px"}} type="email" placeholder={t("system.ownership_addcoownerplaceholder")} onChange={evt => setNewcoowner(evt.target.value)} value={newcoowner} ></Form.Control>
                                        </InputGroup>
                                        <Button style={{width: "140px", height: "38px"}} onClick={addCoowner}>{t("system.ownership_addcoownerbtn")}</Button>
                                    </div>
                                : null}
                                {system["coownerrequests"] != undefined && system["coownerrequests"].length > 0 && auth.user["userdata"]["_id"] == (owner != null ? owner["_id"] : "") ?
                                    <div>
                                        <br/>
                                        <p style={{fontWeight: 600}}>{t("system.ownership_coownerrequests")}</p>
                                        <Table striped size="sm">
                                            <tbody>
                                                {system["coownerrequests"].map((coownerrequest, key) => { return (
                                                    <tr key={key}>
                                                        <td>{coownerrequest["email"]}</td>
                                                        {system.accesses.includes("edit_ownership")?
                                                            <td style={{width: "80px", padding: "4px"}}><Button onClick={() => acceptCoownerrequest(coownerrequest["_id"], coownerrequest["email"])}>{t("system.ownership_acceptcoownerrequest")}</Button></td>
                                                        : null}
                                                        {system.accesses.includes("edit_ownership") ?
                                                            <td style={{width: "80px", padding: "4px"}}><Button onClick={() => denyCoownerrequest(coownerrequest["_id"])}>{t("system.ownership_denycoownerrequest")}</Button></td>
                                                        : null}
                                                    </tr>
                                                )})}
                                            </tbody>
                                        </Table>
                                    </div>
                                : null} */}
                {/* <hr />
                                <h4>{t("system.features_title")}</h4>
                                <p></p>
                                <Button 
                                    disabled={
                                        !system.accesses.includes("copy") || 
                                        auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => 
                                            rule.objType == "system" && rule.objSubType == (system.systemType[0] != "other" && system.systemType[0] != "orgros" ? "system" : system.systemType[0])
                                        )?.actions.create != "allow" ||
                                        (system.systemType[0] == "other" && countedSystems.countedOther <= 0) ||
                                        (system.systemType[0] == "orgros" && countedSystems.countedOrgros <= 0) ||
                                        (system.systemType[0] != "orgros" && system.systemType[0] != "other" && countedSystems.countedSystem <= 0)
                                    } 
                                    onClick={() => setShowCopySystemModal(true)}>
                                    {t("system.configmodal.copybtn")}
                                </Button> */}
                <div className="d-flex info-field">
                  <Field
                    type="indeterminatecheckbox"
                    checked={isTemplate}
                    onChange={() => setIsTemplate(!isTemplate)}
                    disabled={!system.accesses.includes("edit_ownership")}
                    parent={false}
                    title={t("system.mark_as_template")}
                    titlePlacement="end"
                  ></Field>
                  <Tooltip
                    title={t("system.mark_as_template_info")}
                    placement="top"
                  >
                    <i className="dripicons-information" />
                  </Tooltip>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setShowSystemPrivacyModal(false)}>
                  {t("system.configmodal_close")}
                </Button>
                {system != undefined &&
                system.accesses.includes("edit_ownership") ? (
                  <Button
                    style={{ float: "right" }}
                    onClick={() =>
                      (system.accessRules == undefined ||
                        system.accessRules.shared
                          .find((s) => s.type == "public")
                          ?.actions.includes("read")) &&
                      editSystemAccessRules.shared
                        .find((s) => s.type == "public")
                        .actions.includes("read") &&
                      !systemIsPublic
                        ? handleShowSharePublicWarningModal()
                        : saveAccessRules()
                    }
                  >
                    {system != undefined &&
                    system.accesses.includes("edit_ownership")
                      ? t("system.save_access_control")
                      : t("system.close")}
                  </Button>
                ) : null}
              </Modal.Footer>
            </Modal>
            <Modal
              show={showSharingSystemModal}
              onHide={() => setShowSharingSystemModal(false)}
            >
              <Modal.Header closeButton>{t("system.share")}</Modal.Header>
              <Modal.Body>
                <div className={"accessControl objectAccessControlDiv"}>
                  <div className="objectAccessControlUsersDiv">
                    {editSystemAccessRules?.users.length != 0 ? (
                      <div>
                        {editSystemAccessRules?.users.filter(
                          (user) => user.accepted == true
                        ).length != 0 ? (
                          <div>
                            <p>{t("system.shared_with")}</p>
                            <div className="objectAccessControlTableParentDiv">
                              <div
                                className={
                                  !system.accesses.includes("edit_ownership")
                                    ? "disabled"
                                    : ""
                                }
                              ></div>

                              <table
                                className={
                                  "objectAccessControlTable systemObjectAccessControlTable"
                                }
                              >
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th>{t("system.read")}</th>
                                    <th>{t("system.edit")}</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                {editSystemAccessRules?.users
                                  .filter((user) => user.accepted == true)
                                  .map((user, key) => {
                                    return (
                                      <React.Fragment>
                                        <tbody>
                                          <tr key={key} className="mainRow">
                                            <td
                                              className="clickable"
                                              onClick={() => {
                                                !activeSharedWithRows.includes(
                                                  user.id
                                                )
                                                  ? setActiveSharedWithRows([
                                                      ...activeSharedWithRows,
                                                      user.id,
                                                    ])
                                                  : setActiveSharedWithRows(
                                                      activeSharedWithRows.filter(
                                                        (item) =>
                                                          item != user.id
                                                      )
                                                    );
                                              }}
                                            >
                                              <i
                                                className={
                                                  !activeSharedWithRows.includes(
                                                    user.id
                                                  )
                                                    ? "dripicons-chevron-down"
                                                    : "dripicons-chevron-up"
                                                }
                                              />
                                            </td>
                                            <td>
                                              {user.name != "" ? (
                                                <p>
                                                  {user.name}, <br />
                                                  {user.email}
                                                </p>
                                              ) : (
                                                <p>{user.email}</p>
                                              )}
                                            </td>
                                            <td>
                                              <Field
                                                type="indeterminatecheckbox"
                                                checked={isChecked(
                                                  "read",
                                                  user.id,
                                                  undefined
                                                )}
                                                onChange={(evt) => {
                                                  if (evt) {
                                                    handleChangeAction(
                                                      evt,
                                                      "read",
                                                      user.id,
                                                      undefined
                                                    );
                                                  } else {
                                                    handleChangeAction(
                                                      evt,
                                                      "read",
                                                      user.id,
                                                      undefined
                                                    );
                                                    handleChangeAction(
                                                      evt,
                                                      "edit",
                                                      user.id,
                                                      undefined
                                                    );
                                                    handleChangeAction(
                                                      evt,
                                                      "copy",
                                                      user.id,
                                                      undefined
                                                    );
                                                    handleChangeAction(
                                                      evt,
                                                      "edit_ownership",
                                                      user.id,
                                                      undefined
                                                    );
                                                    handleChangeAction(
                                                      evt,
                                                      "delete",
                                                      user.id,
                                                      undefined
                                                    );
                                                    handleChangeAction(
                                                      evt,
                                                      "show_treatment_plan",
                                                      user.id,
                                                      undefined
                                                    );
                                                    handleChangeAction(
                                                      evt,
                                                      "sign_treatment_plan",
                                                      user.id,
                                                      undefined
                                                    );
                                                  }
                                                }}
                                                disabled={
                                                  !system.accesses.includes(
                                                    "edit_ownership"
                                                  )
                                                }
                                                parent={false}
                                                title={""}
                                                titlePlacement="start"
                                              ></Field>
                                            </td>
                                            <td>
                                              <Field
                                                type="indeterminatecheckbox"
                                                checked={isChecked(
                                                  "edit",
                                                  user.id,
                                                  undefined
                                                )}
                                                onChange={(evt) => {
                                                  if (evt) {
                                                    handleChangeAction(
                                                      evt,
                                                      "read",
                                                      user.id,
                                                      undefined
                                                    );
                                                    handleChangeAction(
                                                      evt,
                                                      "edit",
                                                      user.id,
                                                      undefined
                                                    );
                                                  } else {
                                                    handleChangeAction(
                                                      evt,
                                                      "edit",
                                                      user.id,
                                                      undefined
                                                    );
                                                    handleChangeAction(
                                                      evt,
                                                      "copy",
                                                      user.id,
                                                      undefined
                                                    );
                                                    handleChangeAction(
                                                      evt,
                                                      "edit_ownership",
                                                      user.id,
                                                      undefined
                                                    );
                                                    handleChangeAction(
                                                      evt,
                                                      "delete",
                                                      user.id,
                                                      undefined
                                                    );
                                                    handleChangeAction(
                                                      evt,
                                                      "sign_treatment_plan",
                                                      user.id,
                                                      undefined
                                                    );
                                                  }
                                                }}
                                                disabled={
                                                  !system.accesses.includes(
                                                    "edit_ownership"
                                                  )
                                                }
                                                parent={false}
                                                title={""}
                                                titlePlacement="start"
                                              ></Field>
                                            </td>
                                            <td>
                                              {system.accesses.includes(
                                                "edit_ownership"
                                              ) ? (
                                                <Button
                                                  onClick={() => {
                                                    setEditSystemAccessRules({
                                                      ...editSystemAccessRules,
                                                      users:
                                                        editSystemAccessRules.users.filter(
                                                          (u) => u.id != user.id
                                                        ),
                                                    });
                                                    saveSharedWithAccessRules(
                                                      user.id
                                                    );
                                                  }}
                                                >
                                                  {t("system.remove")}
                                                </Button>
                                              ) : null}
                                            </td>
                                          </tr>
                                        </tbody>
                                        <tbody
                                          className={
                                            "systemObjectActionsRows " +
                                            (activeSharedWithRows.includes(
                                              user.id
                                            )
                                              ? ""
                                              : "hidden")
                                          }
                                        >
                                          <tr>
                                            <td colSpan={2}>
                                              <p>
                                                {t("accessControl.table.copy")}
                                              </p>
                                            </td>
                                            <td colSpan={3}>
                                              <Field
                                                type="indeterminatecheckbox"
                                                checked={isChecked(
                                                  "copy",
                                                  user.id,
                                                  undefined
                                                )}
                                                onChange={(evt) =>
                                                  handleChangeAction(
                                                    evt,
                                                    "copy",
                                                    user.id,
                                                    undefined
                                                  )
                                                }
                                                disabled={
                                                  !system.accesses.includes(
                                                    "edit_ownership"
                                                  )
                                                }
                                                parent={false}
                                                title={t("system.allow")}
                                                titlePlacement="end"
                                              ></Field>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={2}>
                                              <p>
                                                {t(
                                                  "accessControl.table.edit_ownership"
                                                )}
                                              </p>
                                            </td>
                                            <td colSpan={3}>
                                              <Field
                                                type="indeterminatecheckbox"
                                                checked={isChecked(
                                                  "edit_ownership",
                                                  user.id,
                                                  undefined
                                                )}
                                                onChange={(evt) =>
                                                  handleChangeAction(
                                                    evt,
                                                    "edit_ownership",
                                                    user.id,
                                                    undefined
                                                  )
                                                }
                                                disabled={
                                                  !system.accesses.includes(
                                                    "edit_ownership"
                                                  )
                                                }
                                                parent={false}
                                                title={t("system.allow")}
                                                titlePlacement="end"
                                              ></Field>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={2}>
                                              <p>{t("system.delete")}</p>
                                            </td>
                                            <td colSpan={3}>
                                              <Field
                                                type="indeterminatecheckbox"
                                                checked={isChecked(
                                                  "delete",
                                                  user.id,
                                                  undefined
                                                )}
                                                onChange={(evt) =>
                                                  handleChangeAction(
                                                    evt,
                                                    "delete",
                                                    user.id,
                                                    undefined
                                                  )
                                                }
                                                disabled={
                                                  !system.accesses.includes(
                                                    "edit_ownership"
                                                  )
                                                }
                                                parent={false}
                                                title={t("system.allow")}
                                                titlePlacement="end"
                                              ></Field>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={2}>
                                              <p>
                                                {t(
                                                  "accessControl.table.show_treatmentplan"
                                                )}
                                              </p>
                                            </td>
                                            <td colSpan={3}>
                                              <Field
                                                type="indeterminatecheckbox"
                                                checked={isChecked(
                                                  "show_treatment_plan",
                                                  user.id,
                                                  undefined
                                                )}
                                                onChange={(evt) =>
                                                  handleChangeAction(
                                                    evt,
                                                    "show_treatment_plan",
                                                    user.id,
                                                    undefined
                                                  )
                                                }
                                                disabled={
                                                  !system.accesses.includes(
                                                    "edit_ownership"
                                                  )
                                                }
                                                parent={false}
                                                title={t("system.allow")}
                                                titlePlacement="end"
                                              ></Field>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={2}>
                                              <p>
                                                {t(
                                                  "accessControl.table.sign_treatmentplan"
                                                )}
                                              </p>
                                            </td>
                                            <td colSpan={3}>
                                              <Field
                                                type="indeterminatecheckbox"
                                                checked={isChecked(
                                                  "sign_treatment_plan",
                                                  user.id,
                                                  undefined
                                                )}
                                                onChange={(evt) =>
                                                  handleChangeAction(
                                                    evt,
                                                    "sign_treatment_plan",
                                                    user.id,
                                                    undefined
                                                  )
                                                }
                                                disabled={
                                                  !system.accesses.includes(
                                                    "edit_ownership"
                                                  )
                                                }
                                                parent={false}
                                                title={t("system.allow")}
                                                titlePlacement="end"
                                              ></Field>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </React.Fragment>
                                    );
                                  })}
                              </table>
                            </div>
                          </div>
                        ) : null}
                        {system.accesses.includes("edit_ownership") &&
                        editSystemAccessRules?.users.filter(
                          (user) => user.accepted == false
                        ).length != 0 ? (
                          <div>
                            <p>{t("system.requested_shared_with")}</p>
                            <table className={"objectAccessControlTable"}>
                              {editSystemAccessRules?.users
                                .filter((user) => user.accepted == false)
                                .map((user, key) => {
                                  return (
                                    <tbody>
                                      <tr key={key} className="mainRow">
                                        <td>
                                          {user.name != "" ? (
                                            <p>
                                              {user.name}, <br />
                                              {user.email}
                                            </p>
                                          ) : (
                                            <p>{user.email}</p>
                                          )}
                                        </td>
                                        <td>
                                          <Button
                                            onClick={() =>
                                              approveSharedWithRequest(user.id)
                                            }
                                          >
                                            {t("system.approve")}
                                          </Button>
                                        </td>
                                        <td>
                                          <Button
                                            onClick={() =>
                                              declineSharedWithRequest(user.id)
                                            }
                                          >
                                            {t("system.decline")}
                                          </Button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })}
                            </table>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    <div
                      key={autocompleteKey}
                      className="objectAccessControlAddUsersDiv"
                    >
                      <Field
                        type="autocomplete"
                        value={shareWithUser != undefined ? shareWithUser : ""}
                        title={t("system.share_with_user")}
                        onChange={(value) => setShareWithUser(value)}
                        disabled={!system.accesses.includes("edit_ownership")}
                        options={{
                          list: shareWithUsersOptions,
                          minlength: 2,
                          noresults: "no matches",
                          placeholder: t("system.enter_email"),
                        }}
                      ></Field>
                      <Button
                        onClick={addSharedWithUser}
                        disabled={!system.accesses.includes("edit_ownership")}
                      >
                        {t("system.share")}
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setShowSharingSystemModal(false)}>
                  {t("system.configmodal_close")}
                </Button>
                {system != undefined &&
                system.accesses.includes("edit_ownership") ? (
                  <Button
                    style={{ float: "right" }}
                    onClick={() => saveSharedWithAccessRules()}
                  >
                    {system != undefined &&
                    system.accesses.includes("edit_ownership")
                      ? t("system.save_access_control")
                      : t("system.close")}
                  </Button>
                ) : null}
              </Modal.Footer>
            </Modal>
            {/* Can be used for System Dashboard Config later. Do not delete */}
            {/* <Modal show={showSystemConfigModal} onHide={() => setShowSystemConfigModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {t("system.config_title")}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Button onClick={() => setShowCopySystemModal(true)}>
                                    {t("system.configmodal.copybtn")}
                                </Button>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => setShowSystemConfigModal(false)}>
                                    {t("system.configmodal_close")}
                                </Button>
                            </Modal.Footer>
                        </Modal> */}
            <Modal
              show={showCopySystemModal}
              onHide={() => setShowCopySystemModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>{t("system.copymodal.title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="copymodal">
                <Field
                  title={t("system.copymodal.copyto.title") + ":"}
                  type="treeselector"
                  onChange={(val) => setCopyOrgid(val)}
                  options={{
                    namekey: "name",
                    list: orgelements,
                    selecttext: t("system.copymodal.copyto.selecttext"),
                    canceltext: t("system.copymodal.copyto.cancel"),
                    placeholder: t("system.copymodal.copyto.placeholder"),
                  }}
                ></Field>
                <hr />
                <p>{t("system.copymodal.copyto.desc")}</p>
                {/* <div className="systemcopyobject">
                                    <Field
                                        title={t("system.copymodal.copyfrom.options.keepcoowners")}
                                        type="checkbox"
                                        onChange={val => setCopyKeepcoowners(val)}
                                        value={copyKeepcoowners}
                                    ></Field>
                                </div> */}
                <div className="systemcopyobject">
                  <Field
                    title={t("system.copymodal.copyfrom.options.keepowner")}
                    type="checkbox"
                    onChange={(val) => setCopyKeepowner(val)}
                    value={copyKeepowner}
                  ></Field>
                </div>
                <div className="systemcopyobject">
                  <Field
                    title={t(
                      "system.copymodal.copyfrom.options.keepactionplan"
                    )}
                    type="checkbox"
                    onChange={(val) => setCopyKeepactionplan(val)}
                    value={copyKeepactionplan}
                  ></Field>
                </div>
                <div className="systemcopyobject">
                  <Field
                    title={t(
                      "system.copymodal.copyfrom.options.keepriskassessmentparticipants"
                    )}
                    type="checkbox"
                    onChange={(val) =>
                      setCopyKeepriskassessmentparticipants(val)
                    }
                    value={copyKeepriskassessmentparticipants}
                  ></Field>
                </div>
                <div className="systemcopyobject">
                  <Field
                    title={t(
                      "system.copymodal.copyfrom.options.keepcreationdate"
                    )}
                    type="checkbox"
                    onChange={(val) => setCopyKeepcreationdate(val)}
                    value={copyKeepcreationdate}
                  ></Field>
                </div>
                <hr />
                <p>{t("system.copymodal.copyto.copysubobjects_desc")}</p>
                <div className="systemcopysubobjects">
                  <div className="systemcopyobject">
                    <Field
                      title={t(
                        "system.copymodal.copyfrom.options.copysystemvalues"
                      )}
                      type="checkbox"
                      onChange={(val) => setCopysystemvalues(val)}
                      value={copysystemvalues}
                    ></Field>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        copySettings != "systemvalues"
                          ? setCopySettings("systemvalues")
                          : setCopySettings("")
                      }
                    >
                      <i className="dripicons-gear" />
                    </span>
                    <div
                      className="systemcopyobjectdetails"
                      style={{
                        maxHeight:
                          copySettings == "systemvalues" ? "100px" : "0px",
                      }}
                    >
                      <Field
                        title={t(
                          "system.copymodal.copyfrom.options.copyitem_desciptions"
                        )}
                        type="checkbox"
                        onChange={(val) =>
                          setCopysystemvaluesFieldDescription(val)
                        }
                        value={copysystemvaluesFieldDescription}
                      ></Field>
                    </div>
                  </div>
                  <div className="systemcopyobject">
                    <Field
                      title={t(
                        "system.copymodal.copyfrom.options.copyoccurences"
                      )}
                      type="checkbox"
                      onChange={(val) => setCopyoccurences(val)}
                      value={copyoccurences}
                    ></Field>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        copySettings != "occurences"
                          ? setCopySettings("occurences")
                          : setCopySettings("")
                      }
                    >
                      <i className="dripicons-gear" />
                    </span>
                    <div
                      className="systemcopyobjectdetails"
                      style={{
                        maxHeight:
                          copySettings == "occurences" ? "100px" : "0px",
                      }}
                    >
                      <Field
                        title={t(
                          "system.copymodal.copyfrom.options.copyitem_desciptions"
                        )}
                        type="checkbox"
                        onChange={(val) =>
                          setCopyoccurencesFieldDescription(val)
                        }
                        value={copyoccurencesFieldDescription}
                      ></Field>
                    </div>
                  </div>
                  <div className="systemcopyobject">
                    <Field
                      title={t("system.copymodal.copyfrom.options.copycauses")}
                      type="checkbox"
                      onChange={(val) => setCopycauses(val)}
                      value={copycauses}
                    ></Field>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        copySettings != "causes"
                          ? setCopySettings("causes")
                          : setCopySettings("")
                      }
                    >
                      <i className="dripicons-gear" />
                    </span>
                    <div
                      className="systemcopyobjectdetails"
                      style={{
                        maxHeight: copySettings == "causes" ? "100px" : "0px",
                      }}
                    >
                      <Field
                        title={t(
                          "system.copymodal.copyfrom.options.copyitem_desciptions"
                        )}
                        type="checkbox"
                        onChange={(val) => setCopycausesFieldDescription(val)}
                        value={copycausesFieldDescription}
                      ></Field>
                    </div>
                  </div>
                  <div className="systemcopyobject">
                    <Field
                      title={t(
                        "system.copymodal.copyfrom.options.copyconsequences"
                      )}
                      type="checkbox"
                      onChange={(val) => setCopyconsequences(val)}
                      value={copyconsequences}
                    ></Field>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        copySettings != "consequences"
                          ? setCopySettings("consequences")
                          : setCopySettings("")
                      }
                    >
                      <i className="dripicons-gear" />
                    </span>
                    <div
                      className="systemcopyobjectdetails"
                      style={{
                        maxHeight:
                          copySettings == "consequences" ? "100px" : "0px",
                      }}
                    >
                      <Field
                        title={t(
                          "system.copymodal.copyfrom.options.copyitem_desciptions"
                        )}
                        type="checkbox"
                        onChange={(val) =>
                          setCopyconsequencesFieldDescription(val)
                        }
                        value={copyconsequencesFieldDescription}
                      ></Field>
                    </div>
                  </div>
                  <div className="systemcopyobject">
                    <Field
                      title={t(
                        "system.copymodal.copyfrom.options.copytreatments"
                      )}
                      type="checkbox"
                      onChange={(val) => setCopytreatments(val)}
                      value={copytreatments}
                    ></Field>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        copySettings != "treatments"
                          ? setCopySettings("treatments")
                          : setCopySettings("")
                      }
                    >
                      <i className="dripicons-gear" />
                    </span>
                    <div
                      className="systemcopyobjectdetails"
                      style={{
                        maxHeight:
                          copySettings == "treatments" ? "100px" : "0px",
                      }}
                    >
                      <Field
                        title={t(
                          "system.copymodal.copyfrom.options.copyitem_desciptions"
                        )}
                        type="checkbox"
                        onChange={(val) =>
                          setCopytreatmentsFieldDescription(val)
                        }
                        value={copytreatmentsFieldDescription}
                      ></Field>
                      <Field
                        title={t(
                          "system.copymodal.copyfrom.options.copyitem_documentationlink"
                        )}
                        type="checkbox"
                        onChange={(val) =>
                          setCopytreatmentsFieldDocumentationlink(val)
                        }
                        value={copytreatmentsFieldDocumentationlink}
                      ></Field>
                    </div>
                  </div>
                </div>
                <hr />
                <Button
                  disabled={
                    copying ||
                    (system.systemType[0] == "other" &&
                      countedSystems.countedOther <= 0) ||
                    (system.systemType[0] == "orgros" &&
                      countedSystems.countedOrgros <= 0) ||
                    (system.systemType[0] != "orgros" &&
                      system.systemType[0] != "other" &&
                      countedSystems.countedSystem <= 0)
                  }
                  onClick={() => startSystemCopy()}
                >
                  {t("system.copymodal.startcopy")}
                </Button>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setShowCopySystemModal(false)}>
                  {t("system.configmodal_close")}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showSharePublicWarningModal}
              onHide={() => {
                setShowSharePublicWarningModal(false);
                setSharePublicConfirmed(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>{t("system.confirm_save")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{t("system.share_public_warning")}</p>
                <div className="widecheckbox">
                  <Field
                    type="checkbox"
                    title={t("system.confirm_share_public")}
                    value={sharePublicConfirmed}
                    onChange={(val) => setSharePublicConfirmed(val)}
                    disabled={false}
                  ></Field>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="cancel posleft"
                  onClick={() => setShowSharePublicWarningModal(false)}
                >
                  {t("system.cancel")}
                </Button>
                <Button
                  disabled={!sharePublicConfirmed}
                  onClick={() => saveAccessRules()}
                >
                  {t("system.save")}
                </Button>
              </Modal.Footer>
            </Modal>
            <TreatmentEditorModal
              systemid={systemid}
              show={showEditTreatmentModal}
              onSave={(savedTreatment) => {
                let index = selectedTreatments
                  .map((t) => t._id)
                  .indexOf(savedTreatment._id);
                selectedTreatments.splice(index, 1, savedTreatment);
                setSelectedTreatments(selectedTreatments);
                setShowEditTreatmentModal(false);
                setRefetchTreatments(refetchTreatments + 1);
              }}
              onHide={() => {
                setShowEditTreatmentModal(false);
                setEditTreatmentId(undefined);
              }}
              onCancel={() => {
                setShowEditTreatmentModal(false);
                setEditTreatmentId(undefined);
              }}
              treatmentId={editTreatmentId}
            ></TreatmentEditorModal>
          </React.Fragment>
        ) : null}
      </div>
      <Shop
        show={showShop}
        onHide={() => setShowShop(false)}
        size="lg"
        orgid={auth.user["userdata"]["unit"][0]}
        onPurchaseComplete={() => setShowShop(false)}
      />
    </div>
  );
}

export default System;
