import React from "react";

import { Button, Spinner } from "react-bootstrap";

import "./Button.scss";

interface ButtonComponentProps {
  type?: string;
  text: string;
  loading: boolean;
  onClick?: () => void;
}

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  type = "submit",
  text,
  loading,
  onClick,
}) => {
  return (
    <Button className="styledBtn" onClick={onClick} type={type}>
      {loading && (
        <Spinner
          as="span"
          variant="primary"
          size="sm"
          role="status"
          aria-hidden="true"
          animation="border"
        />
      )}
      {text}
    </Button>
  );
};

export default ButtonComponent;
