import * as React from 'react';

import { useTranslate } from 'react-polyglot';

import Field from '../../../../../Shared/Wizard/Field/Field';
import DateSelector from '../../../../../Shared/Wizard/Field/Fieldtypes/DateSelector';

function SystemInformationOther(props) {
    const t = useTranslate();
    
    const localStorageUser = localStorage.getItem("user");
    let settings = {};
    if (localStorageUser != null) {
        settings = JSON.parse(localStorageUser)["settings"];
    }

    return(
        <div className="systemtype">
            <Field
                type="input"
                value={props.system.data != undefined ? props.system.data.responsiblePersonReadableOther : ""}
                title={t("system.information.responsibleperson")}
                disabled={true}
            ></Field>
            <DateSelector
                type="input"
                value={props.system.data != undefined ? props.system.data.startdate : ""}
                title={t("system.information.start_date")}
                disabled={true}
            ></DateSelector>
            <Field
                type="textarea"
                value={props.system.data != undefined ? props.system.data.description : ""}
                title={t("system.information.description")}
                disabled={true}
            ></Field>
            <Field
                type="selectmulti"
                value={props.system.data != undefined ? props.system.data.implicatedriskassessments : []}
                title={t("system.information.implicatedriskassessments")}
                disabled={true}
                options={{
                    choices: []
                }}
            ></Field>
        </div>
    );
}
 
export default SystemInformationOther;