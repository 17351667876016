import * as React from 'react';
import { Card, InputGroup, Form, Button, Modal } from 'react-bootstrap';
import { useTranslate } from 'react-polyglot';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../../Auth';
import { useAxios } from '../../../../AxiosHandler';
import {
    useHistory
  } from "react-router-dom";

import { toast } from 'react-toastify';
import LocaleHandler from '../../../../LocaleHandler';
import { generateUrl } from '../../../../config';

//Path /import/systems

function Import() {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const { locale } = React.useContext(LocaleHandler);
    const t = useTranslate();
    let history = useHistory();

    const notifySuccess = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "success-toast"
    });

    const notifyWarning = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "warning-toast"
    });

    const [importedExistingData, setImportedExistingData] = useState([]);
    const [importedNewData, setImportedNewData] = useState([]);
    const [deleteData, setDeleteData] = useState([]);
    const [systems, setSystems] = useState<Array<any>>([]);
    const [tags, setTags] = useState<any>([]);
    const [activeTableRow, setActiveTableRow] = useState("");
    const [disableExecute, setDisableExecute] = useState(false);
    const [nonMatchingIds, setNonMatchingIds] = useState([]);
    const [currentFile, setCurrentFile] = useState<any>();
    const [generalErrors, setGeneralErrors] = useState<any>([]);

    const [showWarningModal, setShowWarningModal] = useState(false);
    //const [showErrorsModal, setShowErrorsModal] = useState(false);

    const deleteCommands = [
        "x",
        "yes",
        "delete",
        "ja",
        "slett"
    ]

    const tableHeaders = [
        t("systems.import.table.id"),
        t("systems.import.table.organisation_path"),
        t("systems.import.table.type"),
        t("systems.import.table.system_name"),
        t("systems.import.table.description"),

        t("systems.import.table.responsible_person"),
        t("systems.import.table.delimitations"),
        t("systems.import.table.start_date"),
        t("systems.import.table.tags"),
        t("systems.import.table.importance"),

        t("systems.import.table.selected_org"),
        t("systems.import.table.industry_code"),
        t("systems.import.table.industry_ssb"),
        t("systems.import.table.employees_count"),
        t("systems.import.table.security_requirements"),
        t("systems.import.table.important_deliveries"),

        t("systems.import.table.implicated_risk_assessments"),

        t("systems.import.table.owner_org"),
        t("systems.import.table.owner_section"),
        t("systems.import.table.owner_person"),
        t("systems.import.table.provider_info"),

        t("systems.import.table.operation_status"),
        t("systems.import.table.no_of_users"),
        t("systems.import.table.max_no_of_users"),
        t("systems.import.table.users_category"),
        t("systems.import.table.system_access_method"),
        t("systems.import.table.category"),

        t("systems.import.table.special_availability_period"),
        t("systems.import.table.availability"),
        t("systems.import.table.availability_reasoning"),

        t("systems.import.table.operating_system"),
        t("systems.import.table.operating_system_version"),

        t("systems.import.table.documentation_link"),
        t("systems.import.table.login_link"),

        //t("systems.import.table.risk_assessment_participants")
    ]

    const handleReadXLSX = (file, executeParam) => {
        const formData = new FormData();
        formData.append('file', file);
        if(executeParam == "true"){
            if(nonMatchingIds.length != 0){
                notifyWarning(t("systems.import.toast.warning_non_matching_ids"));
                return;
            }
            setDisableExecute(true);
        }

        axios.post('/api/exportandimport/eisystems/readXLSX/' + executeParam + "?language=" + locale, formData, {
            headers: {'x-access-token': auth.user["token"]},
        }).then(function (res) {
            console.log(res.data)
            if(res.data.status != "OK"){
                console.log(res.data.generalErrors)
                setGeneralErrors(res.data.generalErrors);
                setDeleteData(res.data.objects.filter(data => data["ID"] != undefined && data["ID"] != "" && data["Delete"] != undefined && systems.filter(system => system["_id"] == data["ID"] && system["accesses"].includes("delete"))[0] != undefined && deleteCommands.includes(data["Delete"].toLowerCase())));
                setNonMatchingIds(res.data.objects.filter(data => data["ID"] != undefined && data["ID"] != "" && systems.filter(system => system["_id"] == data["ID"])[0] == undefined));
                setImportedExistingData(res.data.objects.filter(data => data.ID != undefined && data.ID != "" && systems.filter(system => system["_id"] == data["ID"] && system["accesses"].includes("edit"))[0] != undefined && (data["Delete"] == undefined || !deleteCommands.includes(data["Delete"].toLowerCase()))));
                setImportedNewData(res.data.objects.filter(data => data.ID == undefined || data.ID == "" && (data["Delete"] == undefined || data["Delete"].toLowerCase() != "x")));
            } else {
                /* setErrors(res.data.deleteErrors.concat(res.data.updateErrors));
                if(res.data.deleteErrors.concat(res.data.updateErrors).length != 0) {
                    setShowErrorsModal(true);
                } */
                notifySuccess(t("systems.import.toast.success_import_completed"));
                setDisableExecute(false);
            }
            
        });
    }
    
    async function fetchData() {
        axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/systems?includepublic=true"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/tags/"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
        ]).then(axios.spread((...res) => {
            setSystems(res[0].data);
            setTags(res[1].data.map(i => { return { label: i.name, value: i._id } } ));
        }));
    }

    useEffect(() => {
        if (auth.user["userdata"] == undefined) { window.location.href = "/login"; }
        if (auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "system")?.actions.export_all_risk_assessments != "allow"){
            auth.signout()
        }
        fetchData();
    }, []);

    return(
        <div className="maincontent import-page">
            <div className="pagetitle-container">
                    <h1>{t("systems.import.import_page_name")}</h1>
                </div>
            <Card>
                <Card.Body>
                    <h2>{t("systems.import.import_page_title")}</h2>
                    <p>{t("systems.import.import_information")}</p>
                    <InputGroup className="mb-3">
                        <Form.File
                            name="fileupload"
                            id="Import"
                            label={t("systems.import.import_file")}
                            disabled={false}
                            onChange={(evt) => {
                                let filetype = evt.target.files[0]?.name.split('.') ?? "";
                                if(filetype[1] != undefined && filetype[1].toLowerCase() != "xlsx"){
                                    evt.target.value = null;
                                    notifyWarning(t("systems.import.toast.warning_wrong_filetype"));
                                    return;
                                }
                                setCurrentFile(evt.target.files[0]);
                                handleReadXLSX(evt.target.files[0], "false");
                            }}
                        />
                    </InputGroup>
                </Card.Body>
            </Card>
            {generalErrors.length != 0 ?
                <div>
                    <Card className="warningcard">
                        <Card.Body>
                            <React.Fragment>
                                <i className="dripicons-warning" />
                                {generalErrors.map((error, key) => {
                                    return(
                                        <div key={key}>
                                            {error.errorcode == "valuenotinfield" ? 
                                                <p><strong>{t("systems.import.errors.code." + error.errorcode)}: {error.value.Name}. </strong> {t("systems.import.errors.field_name")}: {error.field}. {t("systems.import.errors.field_value")}: {error.value[error.field]}.  {t("systems.import.errors.accepted_values")}: {error.data.map(data => data).join(", ")}.</p>
                                            : error.errorcode == "idnotfound" ?
                                                <p><strong>{t("systems.import.errors.code." + error.errorcode)}: {error.value.Name}. </strong> {t("systems.import.errors.field_name_unvalid_id")}: {error.field}. {t("systems.import.errors.field_value")}: {error.value[error.field]}. {t("systems.import.errors.idnotfound_desc")}.</p>
                                            : error.errorcode == "systemlimitationexceeded" || error.errorcode == "otherlimitationexceeded" || error.errorcode == "orgroslimitationexceeded" ?
                                                <p><strong>{t("systems.import.errors.code." + error.errorcode)}.</strong> {t("systems.import.errors.limitationexceeded_desc")}</p>
                                            : error.errorcode == "createorgrosnotallowed" || error.errorcode == "createothernotallowed" || error.errorcode == "createsystemnotallowed" ?
                                                <p><strong>{t("systems.import.errors.code." + error.errorcode)}.</strong> {t("systems.import.errors.notallowed_desc")}</p>
                                            : null}
                                        </div>
                                    )
                                })}
                            </React.Fragment>
                        </Card.Body>
                    </Card>
                </div>
            : null}

            {deleteData.length != 0 ?
                <div>
                    <Card className="warningcard">
                        <Card.Body>
                            <React.Fragment>
                                <i className="dripicons-trash" />
                                <p><strong>{t("systems.import.import_file_delete_disclaimer1")}<br/>{t("systems.import.import_file_delete_disclaimer2")}</strong></p>
                            </React.Fragment>
                        </Card.Body>
                    </Card>
                    
                    <Card>
                        <Card.Body>
                            <div>
                                <h3>{t("systems.import.import_file_delete")}</h3>
                                <p>{t("systems.import.import_file_delete_disclaimer")}</p>
                                <table>
                                    <thead>
                                        <tr>
                                            {tableHeaders.map((th, key) => {return <th key={key}>{th}</th>})}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {deleteData.map((data, key) => {
                                            return(<tr key={key} onClick={() => {activeTableRow != "newDataTable" + key ? setActiveTableRow("newDataTable" + key) : setActiveTableRow("")}} className={activeTableRow == "newDataTable" + key ? "active" : "" }>
                                                <td>{data["ID"] != undefined ? data["ID"] : ""}</td>
                                                <td>{data["Organisation path"] != undefined ? data["Organisation path"] : ""}</td>
                                                <td>{data["Type"] != undefined ? data["Type"] : ""}</td>
                                                <td>{data["Name"] != undefined ? data["Name"] : ""}</td>
                                                <td>{data["Description"] != undefined ? data["Description"] : ""}</td>

                                                <td>{data["Responsible"] != undefined ? data["Responsible"] : ""}</td>
                                                <td>{data["Delimitations"] != undefined ? data["Delimitations"] : ""}</td>
                                                <td>{data["Date started"] != undefined ? data["Date started"] : ""}</td>
                                                <td>{data["System tags"] != undefined ? data["System tags"] : ""}</td>
                                                <td>{data["Importance"] != undefined ? data["Importance"] : ""}</td>

                                                {/* Type: orgros */}
                                                <td>{data["Selected organization"] != undefined ? data["Selected organization"] : ""}</td>
                                                <td>{data["Industrial classification"] != undefined ? data["Industrial classification"] : ""}</td>
                                                <td>{data["Standard Industrial Classification"] != undefined ? data["Standard Industrial Classification"] : ""}</td>
                                                <td>{data["Number of employees"] != undefined ? data["Number of employees"] : ""}</td>
                                                <td>{data["Security requirements"] != undefined ? data["Security requirements"] : ""}</td>
                                                <td>{data["Important deliveries"] != undefined ? data["Important deliveries"] : ""}</td>

                                                {/* Type: other */}
                                                <td>{data["Implicated risk assessments"] != undefined ? data["Implicated risk assessments"] : ""}</td>

                                                {/* Type: system */}
                                                <td>{data["Organizational unit"] != undefined ? data["Organizational unit"] : ""}</td>
                                                <td>{data["Organizational sub-unit"] != undefined ? data["Organizational sub-unit"] : ""}</td>
                                                <td>{data["System owner"] != undefined ? data["System owner"] : ""}</td>
                                                <td>{data["Provider information"] != undefined ? data["Provider information"] : ""}</td>
                                                
                                                <td>{data["System status"] != undefined ? data["System status"] : ""}</td>
                                                <td>{data["Number of users today"] != undefined ? data["Number of users today"] : ""}</td>
                                                <td>{data["Maximum number of users"] != undefined ? data["Maximum number of users"] : ""}</td>
                                                <td>{data["Users category"] != undefined ? data["Users category"] : ""}</td>
                                                <td>{data["System access method"] != undefined ? data["System access method"] : ""}</td>
                                                <td>{data["Hosting"] != undefined ? data["Hosting"] : ""}</td>

                                                <td>{data["Periods with higher availability requirements"] != undefined ? data["Periods with higher availability requirements"] == true ? "true" : "false" : ""}</td>
                                                <td>{data["Availability (value is given in a scale from 1 - 100)"] != undefined ? data["Availability (value is given in a scale from 1 - 100)"] : ""}</td>
                                                <td>{data["Availability reasoning"] != undefined ? data["Availability reasoning"] : ""}</td>

                                                <td>{data["Operating system"] != undefined ? data["Operating system"] : ""}</td>
                                                <td>{data["Operating system version"] != undefined ? data["Operating system version"] : ""}</td>

                                                <td>{data["System documentation link"] != undefined ? data["System documentation link"] : ""}</td>
                                                <td>{data["System login link"] != undefined ? data["System login link"] : ""}</td>
                                                
                                                {/* <td>{data["Risk assessment participants"] != undefined ? data["Risk assessment participants"] : ""}</td> */}
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            : null}
            
            {nonMatchingIds.length != 0 ?
                <Card>
                    <Card.Body>
                        <div>
                            <h3>{t("systems.import.import_file_non_matching_ids")}</h3>
                            <p>{t("systems.import.import_file_non_matching_ids_disclaimer")}</p>
                            <table>
                                <thead>
                                    <tr>
                                        {tableHeaders.map((th, key) => {return <th key={key}>{th}</th>})}
                                    </tr>
                                </thead>
                                <tbody>
                                    {nonMatchingIds.map((data, key) => {
                                        return(<tr key={key} onClick={() => {activeTableRow != "nonMatchingIdsTable" + key ? setActiveTableRow("nonMatchingIdsTable" + key) : setActiveTableRow("")}} className={activeTableRow == "nonMatchingIdsTable" + key ? "active" : "" }>
                                            <td>{data["ID"] != undefined ? data["ID"] : ""}</td>
                                            <td>{data["Organisation path"] != undefined ? data["Organisation path"] : ""}</td>
                                            <td>{data["Type"] != undefined ? data["Type"] : ""}</td>
                                            <td>{data["Name"] != undefined ? data["Name"] : ""}</td>
                                            <td>{data["Description"] != undefined ? data["Description"] : ""}</td>

                                            <td>{data["Responsible"] != undefined ? data["Responsible"] : ""}</td>
                                            <td>{data["Delimitations"] != undefined ? data["Delimitations"] : ""}</td>
                                            <td>{data["Date started"] != undefined ? data["Date started"] : ""}</td>
                                            <td>{data["System tags"] != undefined ? data["System tags"] : ""}</td>
                                            <td>{data["Importance"] != undefined ? data["Importance"] : ""}</td>

                                            {/* Type: orgros */}
                                            <td>{data["Selected organization"] != undefined ? data["Selected organization"] : ""}</td>
                                            <td>{data["Industrial classification"] != undefined ? data["Industrial classification"] : ""}</td>
                                            <td>{data["Standard Industrial Classification"] != undefined ? data["Standard Industrial Classification"] : ""}</td>
                                            <td>{data["Number of employees"] != undefined ? data["Number of employees"] : ""}</td>
                                            <td>{data["Security requirements"] != undefined ? data["Security requirements"] : ""}</td>
                                            <td>{data["Important deliveries"] != undefined ? data["Important deliveries"] : ""}</td>

                                            {/* Type: other */}
                                            <td>{data["Implicated risk assessments"] != undefined ? data["Implicated risk assessments"] : ""}</td>

                                            {/* Type: system */}
                                            <td>{data["Organizational unit"] != undefined ? data["Organizational unit"] : ""}</td>
                                            <td>{data["Organizational sub-unit"] != undefined ? data["Organizational sub-unit"] : ""}</td>
                                            <td>{data["System owner"] != undefined ? data["System owner"] : ""}</td>
                                            <td>{data["Provider information"] != undefined ? data["Provider information"] : ""}</td>
                                            
                                            <td>{data["System status"] != undefined ? data["System status"] : ""}</td>
                                            <td>{data["Number of users today"] != undefined ? data["Number of users today"] : ""}</td>
                                            <td>{data["Maximum number of users"] != undefined ? data["Maximum number of users"] : ""}</td>
                                            <td>{data["Users category"] != undefined ? data["Users category"] : ""}</td>
                                            <td>{data["System access method"] != undefined ? data["System access method"] : ""}</td>
                                            <td>{data["Hosting"] != undefined ? data["Hosting"] : ""}</td>

                                            <td>{data["Periods with higher availability requirements"] != undefined ? data["Periods with higher availability requirements"] == true ? "true" : "false" : ""}</td>
                                            <td>{data["Availability (value is given in a scale from 1 - 100)"] != undefined ? data["Availability (value is given in a scale from 1 - 100)"] : ""}</td>
                                            <td>{data["Availability reasoning"] != undefined ? data["Availability reasoning"] : ""}</td>

                                            <td>{data["Operating system"] != undefined ? data["Operating system"] : ""}</td>
                                            <td>{data["Operating system version"] != undefined ? data["Operating system version"] : ""}</td>

                                            <td>{data["System documentation link"] != undefined ? data["System documentation link"] : ""}</td>
                                            <td>{data["System login link"] != undefined ? data["System login link"] : ""}</td>
                                            
                                            {/* <td>{data["Risk assessment participants"] != undefined ? data["Risk assessment participants"] : ""}</td> */}
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                </Card>
            : null } 

            {importedExistingData.length != 0 ?
                <Card>
                    <Card.Body>
                        <div>
                            <h3>{t("systems.import.import_file_changes")}</h3>
                            <table>
                                <thead>
                                    <tr>
                                        {tableHeaders.map((th, key) => {return <th key={key}>{th}</th>})}
                                    </tr>
                                </thead>
                                <tbody>
                                    {importedExistingData.map((data, key) => {
                                        return(<tr key={key} onClick={() => {activeTableRow != "existingDataTable" + key ? setActiveTableRow("existingDataTable" + key) : setActiveTableRow("")}} className={activeTableRow == "existingDataTable" + key ? "active" : "" }>
                                            <td>{data["ID"] != undefined ? data["ID"] : ""}</td>
                                            <td>{data["Organisation path"] != undefined ? data["Organisation path"] : ""}</td>
                                            <td>{data["Type"] != undefined ? data["Type"] : ""}</td>
                                            <td>{data["Name"] != undefined ? data["Name"] : ""}</td>
                                            <td>{data["Description"] != undefined ? data["Description"] : ""}</td>

                                            <td>{data["Responsible"] != undefined ? data["Responsible"] : ""}</td>
                                            <td>{data["Delimitations"] != undefined ? data["Delimitations"] : ""}</td>
                                            <td>{data["Date started"] != undefined ? data["Date started"] : ""}</td>
                                            <td>{data["System tags"] != undefined ? data["System tags"] : ""}</td>
                                            <td>{data["Importance"] != undefined ? data["Importance"] : ""}</td>

                                            {/* Type: orgros */}
                                            <td>{data["Selected organization"] != undefined ? data["Selected organization"] : ""}</td>
                                            <td>{data["Industrial classification"] != undefined ? data["Industrial classification"] : ""}</td>
                                            <td>{data["Standard Industrial Classification"] != undefined ? data["Standard Industrial Classification"] : ""}</td>
                                            <td>{data["Number of employees"] != undefined ? data["Number of employees"] : ""}</td>
                                            <td>{data["Security requirements"] != undefined ? data["Security requirements"] : ""}</td>
                                            <td>{data["Important deliveries"] != undefined ? data["Important deliveries"] : ""}</td>

                                            {/* Type: other */}
                                            <td>{data["Implicated risk assessments"] != undefined ? data["Implicated risk assessments"] : ""}</td>

                                            {/* Type: system */}
                                            <td>{data["Organizational unit"] != undefined ? data["Organizational unit"] : ""}</td>
                                            <td>{data["Organizational sub-unit"] != undefined ? data["Organizational sub-unit"] : ""}</td>
                                            <td>{data["System owner"] != undefined ? data["System owner"] : ""}</td>
                                            <td>{data["Provider information"] != undefined ? data["Provider information"] : ""}</td>
                                            
                                            <td>{data["System status"] != undefined ? data["System status"] : ""}</td>
                                            <td>{data["Number of users today"] != undefined ? data["Number of users today"] : ""}</td>
                                            <td>{data["Maximum number of users"] != undefined ? data["Maximum number of users"] : ""}</td>
                                            <td>{data["Users category"] != undefined ? data["Users category"] : ""}</td>
                                            <td>{data["System access method"] != undefined ? data["System access method"] : ""}</td>
                                            <td>{data["Hosting"] != undefined ? data["Hosting"] : ""}</td>

                                            <td>{data["Periods with higher availability requirements"] != undefined ? data["Periods with higher availability requirements"] == true ? "true" : "false" : ""}</td>
                                            <td>{data["Availability (value is given in a scale from 1 - 100)"] != undefined ? data["Availability (value is given in a scale from 1 - 100)"] : ""}</td>
                                            <td>{data["Availability reasoning"] != undefined ? data["Availability reasoning"] : ""}</td>

                                            <td>{data["Operating system"] != undefined ? data["Operating system"] : ""}</td>
                                            <td>{data["Operating system version"] != undefined ? data["Operating system version"] : ""}</td>

                                            <td>{data["System documentation link"] != undefined ? data["System documentation link"] : ""}</td>
                                            <td>{data["System login link"] != undefined ? data["System login link"] : ""}</td>
                                            
                                            {/* <td>{data["Risk assessment participants"] != undefined ? data["Risk assessment participants"] : ""}</td> */}
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                </Card>
            : null } 

            {importedNewData.length != 0 ?
                <Card>
                    <Card.Body>
                        <div>
                            <h3>{t("systems.import.import_file_creates")}</h3>
                            <table>
                                <thead>
                                    <tr>
                                        {tableHeaders.map((th, key) => {return <th key={key}>{th}</th>})}
                                    </tr>
                                </thead>
                                <tbody>
                                    {importedNewData.map((data, key) => {
                                        return(<tr key={key}onClick={() => {activeTableRow != "newDataTable" + key ? setActiveTableRow("newDataTable" + key) : setActiveTableRow("")}} className={activeTableRow == "newDataTable" + key ? "active" : "" }>
                                            <td>{data["ID"] != undefined ? data["ID"] : ""}</td>
                                            <td>{data["Organisation path"] != undefined ? data["Organisation path"] : ""}</td>
                                            <td>{data["Type"] != undefined ? data["Type"] : ""}</td>
                                            <td>{data["Name"] != undefined ? data["Name"] : ""}</td>
                                            <td>{data["Description"] != undefined ? data["Description"] : ""}</td>

                                            <td>{data["Responsible"] != undefined ? data["Responsible"] : ""}</td>
                                            <td>{data["Delimitations"] != undefined ? data["Delimitations"] : ""}</td>
                                            <td>{data["Date started"] != undefined ? data["Date started"] : ""}</td>
                                            <td>{data["System tags"] != undefined ? data["System tags"] : ""}</td>
                                            <td>{data["Importance"] != undefined ? data["Importance"] : ""}</td>

                                            {/* Type: orgros */}
                                            <td>{data["Selected organization"] != undefined ? data["Selected organization"] : ""}</td>
                                            <td>{data["Industrial classification"] != undefined ? data["Industrial classification"] : ""}</td>
                                            <td>{data["Standard Industrial Classification"] != undefined ? data["Standard Industrial Classification"] : ""}</td>
                                            <td>{data["Number of employees"] != undefined ? data["Number of employees"] : ""}</td>
                                            <td>{data["Security requirements"] != undefined ? data["Security requirements"] : ""}</td>
                                            <td>{data["Important deliveries"] != undefined ? data["Important deliveries"] : ""}</td>

                                            {/* Type: other */}
                                            <td>{data["Implicated risk assessments"] != undefined ? data["Implicated risk assessments"] : ""}</td>

                                            {/* Type: system */}
                                            <td>{data["Organizational unit"] != undefined ? data["Organizational unit"] : ""}</td>
                                            <td>{data["Organizational sub-unit"] != undefined ? data["Organizational sub-unit"] : ""}</td>
                                            <td>{data["System owner"] != undefined ? data["System owner"] : ""}</td>
                                            <td>{data["Provider information"] != undefined ? data["Provider information"] : ""}</td>
                                            
                                            <td>{data["System status"] != undefined ? data["System status"] : ""}</td>
                                            <td>{data["Number of users today"] != undefined ? data["Number of users today"] : ""}</td>
                                            <td>{data["Maximum number of users"] != undefined ? data["Maximum number of users"] : ""}</td>
                                            <td>{data["Users category"] != undefined ? data["Users category"] : ""}</td>
                                            <td>{data["System access method"] != undefined ? data["System access method"] : ""}</td>
                                            <td>{data["Hosting"] != undefined ? data["Hosting"] : ""}</td>

                                            <td>{data["Periods with higher availability requirements"] != undefined ? data["Periods with higher availability requirements"] == true ? "true" : "false" : ""}</td>
                                            <td>{data["Availability (value is given in a scale from 1 - 100)"] != undefined ? data["Availability (value is given in a scale from 1 - 100)"] : ""}</td>
                                            <td>{data["Availability reasoning"] != undefined ? data["Availability reasoning"] : ""}</td>

                                            <td>{data["Operating system"] != undefined ? data["Operating system"] : ""}</td>
                                            <td>{data["Operating system version"] != undefined ? data["Operating system version"] : ""}</td>

                                            <td>{data["System documentation link"] != undefined ? data["System documentation link"] : ""}</td>
                                            <td>{data["System login link"] != undefined ? data["System login link"] : ""}</td>
                                            
                                            {/* <td>{data["Risk assessment participants"] != undefined ? data["Risk assessment participants"] : ""}</td> */}
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                </Card>
            : null }

            <Button
                disabled={disableExecute || generalErrors.length != 0 }
                onClick={() => { deleteData.length == 0 ? handleReadXLSX(currentFile, "true") : setShowWarningModal(true)}}
            > {t("systems.import.import_file_execute")} </Button>
            <p>{disableExecute == true ? t("systems.import.importing") : ""}</p>

            <Modal show={showWarningModal} onHide={() => setShowWarningModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("systems.import.warning_modal.title")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("systems.import.warning_modal.disclaimer")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowWarningModal(false)}>
                        {t("systems.import.warning_modal.cancel")}
                    </Button>
                    <Button onClick={() => {handleReadXLSX(currentFile, "true"); setShowWarningModal(false)}}>
                        {t("systems.import.import_file_execute")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* <Modal show={showErrorsModal} onHide={() => setShowErrorsModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        ERRORS
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {errors.map((err, key ) => {
                            return <p key={key}>NAME: {err.name}, MESSAGE: {err.message}</p>
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={() => setShowErrorsModal(false)}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </div>
        
    );
}


export default Import;