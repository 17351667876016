import * as React from "react";

import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import SimpleBar from "simplebar-react";
import { useTranslate } from "react-polyglot";

import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";

import TaskEditorModal from "../Modals/Edit/TaskEditorModal";
import TaskDeleteModal from "../Modals/Delete/TaskDeleteModal";
import RegAssistant from "./RegAssistant/RegAssistant";
import SystemValueRegAssistant from "./SystemValueRegAssistant/SystemValueRegAssistant";

import { generateUrl } from "../../../config";

import "simplebar/dist/simplebar.min.css";
import "./SiteAssistant.scss";
import "../../../Assets/Dripicons/webfont/webfont.css";

function SiteAssistant(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const history = useHistory();
  const assistant = useRef<any>();
  const t = useTranslate();

  const [seenFeatures, setSeenFeatures] = useState<Array<any>>(
    localStorage.getItem("user") != null &&
      localStorage.getItem("user") != undefined
      ? JSON.parse(localStorage.getItem("user") as any)["userdata"][
          "seenfeatures"
        ]
      : null
  );

  const [feature, setFeature] = useState<any>();
  const [needHelp, setNeedHelp] = useState(false);
  const [assistantState, setAssistantState] = useState("");
  const [currentHelpstep, setCurrentHelpstep] = useState(0);
  const [highlightTargetId, setHightlightTargetId] = useState("");

  const [usertasks, setUserTasks] = useState<Array<any>>([]);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
  const [editTaskId, setEditTaskId] = useState("");
  const [deleteTaskId, setDeleteTaskId] = useState("");

  const [occurenceTypes, setOccurenceTypes] = useState<Array<any>>([]);
  const [treatmentTypes, setTreatmentTypes] = useState<Array<any>>([]);
  const [systemTypes, setSystemTypes] = useState<Array<any>>([]);
  const [chosenRegTemplate, setChosenRegTemplate] = useState("");
  const [countedSystems, setCountedSystems] = useState({
    loaded: false,
    countedOrgros: 0,
    countedOther: 0,
    countedSystem: 0,
  });

  const [fixed, setFixed] = useState(true);
  const [closed, setClosed] = useState(false);

  const [highlightTarget, setHightlightTarget] = useState<Element>();
  const [highlightTargetX, setHightlightTargetX] = useState("");
  const [highlightTargetY, setHightlightTargetY] = useState("");
  const [assistantHeight, setAssistantHeight] = useState("");

  function getCoords(elem) {
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  }

  function getCoordsFixed(elem) {
    var box = elem.getBoundingClientRect();

    return { top: Math.round(box.top), left: Math.round(box.left) };
  }

  const getHighlightTarget = (targetId = highlightTargetId, helpstep = 0) => {
    let target = document.querySelectorAll(
      '[data-satarget="' + targetId + '"]'
    );
    if (target[0] != undefined) {
      let localFixed = false;
      if (
        target[0].attributes["data-safixed"] != undefined &&
        target[0].attributes["data-safixed"].value == "true"
      ) {
        setFixed(true);
        localFixed = true;
      } else {
        setFixed(false);
      }

      let rect = localFixed ? getCoordsFixed(target[0]) : getCoords(target[0]);
      window.scrollTo({
        top: rect.top - window.innerHeight / 2,
        behavior: "smooth",
      });
      setHightlightTargetX(rect.left.toString());
      setHightlightTargetY(rect.top.toString());

      setHightlightTarget(target[0]);
      let logo = document.querySelector(".siteassistantlogo");
      if (isOutsideTopVP('[data-satarget="' + targetId + '"]')) {
        setHightlightTargetX(
          (rect.left + assistant.current.offsetWidth).toString()
        );
        setHightlightTargetY(
          (rect.top + assistant.current.offsetHeight * 2).toString()
        );
        logo?.classList.add("element_outside_top_vp");
      } else {
        logo?.classList.remove("element_outside_top_vp");
      }
    }
    setAssistantHeight(assistant.current.offsetHeight);
  };

  const handleGotoNextHelpstep = (step = currentHelpstep) => {
    step++;
    if (
      step < feature.helpsteps.length &&
      feature.helpsteps[step]["hidebuttons"] != true
    ) {
      if (
        document.querySelectorAll(
          '[data-satarget="' + feature["helpsteps"][step]["highlightid"] + '"]'
        ).length != 0 ||
        feature["helpsteps"][step]["size"] != "floating" ||
        feature["helpsteps"][step]["type"] != "normal"
      ) {
        setCurrentHelpstep(step);
        getHighlightTarget(feature["helpsteps"][step]["highlightid"], step);
        setHightlightTargetId(feature["helpsteps"][step]["highlightid"]);
        setAssistantState(feature["helpsteps"][step]["size"]);

        if (feature["helpsteps"][step]["type"] == "tasks") {
          getUserTasks();
        }

        updateSeenFeatures();
      } else {
        handleGotoNextHelpstep(step);
      }
    } else {
      let logo = document.querySelector(".siteassistantlogo");
      logo?.classList.remove("element_outside_top_vp");
      setClosed(true);
      setAssistantState("closed");
      updateSeenFeatures();
    }
  };

  const handleGotoPrevHelpstep = (step = currentHelpstep) => {
    if (step != 0) {
      step--;
      if (
        document.querySelectorAll(
          '[data-satarget="' + feature["helpsteps"][step]["highlightid"] + '"]'
        ).length != 0 ||
        feature["helpsteps"][step]["size"] != "floating" ||
        feature["helpsteps"][step]["type"] != "normal"
      ) {
        setCurrentHelpstep(step);
        getHighlightTarget(feature["helpsteps"][step]["highlightid"], step);
        setHightlightTargetId(feature["helpsteps"][step]["highlightid"]);
        setAssistantState(feature["helpsteps"][step]["size"]);

        if (feature["helpsteps"][step]["type"] == "tasks") {
          getUserTasks();
        }
      } else {
        handleGotoPrevHelpstep(step);
      }
    }
  };

  const seenAllFeatures = () => {
    let temp = seenFeatures;

    temp = temp.filter((i) => i.key != props.featurekey);
    temp.push({
      key: props.featurekey,
      helpsteps: feature["helpsteps"].map((i) => {
        return { id: i.id };
      }),
    });

    setSeenFeatures(temp);
    axios
      .post(
        generateUrl("/api/cra/features/updateseen"),
        {},
        {
          headers: { "x-access-token": auth.user["token"] },
          data: {
            seenFeatures: temp,
          },
        }
      )
      .then(function (response) {
        let user = JSON.parse(localStorage.getItem("user") as string);
        user["userdata"]["seenfeatures"] = response.data.seenfeatures;
        localStorage.setItem("user", JSON.stringify(user));
      });
  };

  const updateSeenFeatures = () => {
    let temp = seenFeatures;
    if (temp.filter((i) => i["key"] == props.featurekey).length == 0) {
      temp.push({
        key: props.featurekey,
        helpsteps: [
          {
            id: feature["helpsteps"][currentHelpstep]["id"],
          },
        ],
      });
    } else {
      let seenFeatureStep = temp
        .filter((i) => i["key"] == props.featurekey)[0]
        ["helpsteps"].filter(
          (i) => i["id"] == feature["helpsteps"][currentHelpstep]["id"]
        );
      if (seenFeatureStep.length == 0) {
        let helpsteps = temp.filter((i) => i["key"] == props.featurekey)[0][
          "helpsteps"
        ];
        helpsteps.push({
          id: feature["helpsteps"][currentHelpstep]["id"],
        });
      }
    }

    setSeenFeatures(temp);
    axios
      .post(
        generateUrl("/api/cra/features/updateseen"),
        {},
        {
          headers: { "x-access-token": auth.user["token"] },
          data: {
            seenFeatures: temp,
          },
        }
      )
      .then(function (response) {
        let user = JSON.parse(localStorage.getItem("user") as string);
        user["userdata"]["seenfeatures"] = response.data.seenfeatures;
        localStorage.setItem("user", JSON.stringify(user));
      });
  };

  useEffect(() => {
    if (
      assistant.current != undefined &&
      assistant.current.children[0] != undefined &&
      assistant.current.children[0].children[1] != undefined
    ) {
      let textboxheight = assistant.current.children[0].children[1].getBoundingClientRect()
        .height;
      let textboxwidth = assistant.current.children[0].children[1].getBoundingClientRect()
        .width;

      if (
        Number.parseInt(highlightTargetY) < textboxheight + 40 &&
        assistantState != "docked"
      ) {
        assistant.current.children[0].children[1].style.setProperty(
          "bottom",
          Number.parseInt(highlightTargetY) - textboxheight - 40 + "px"
        );
      } else {
        assistant.current.children[0].children[1].style.setProperty(
          "bottom",
          "0px"
        );
      }

      if (assistantState != "docked") {
        if (
          Number.parseInt(highlightTargetX) >
          window.innerWidth - textboxwidth - 70
        ) {
          assistant.current.children[0].children[1].style.setProperty(
            "left",
            (textboxwidth + 30) * -1 + "px"
          );
        } else {
          assistant.current.children[0].children[1].style.setProperty(
            "left",
            "52px"
          );
        }
      } else {
        assistant.current.children[0].children[1].style.setProperty(
          "left",
          (textboxwidth + 30) * -1 + "px"
        );
      }
    }
  }, [highlightTargetY, highlightTarget, assistantState, highlightTargetX]);

  useEffect(() => {
    if (assistantHeight != assistant.current.offsetHeight) {
      setAssistantHeight(assistant.current.offsetHeight);
    }
  }, [assistantHeight, assistantState]);

  useEffect(() => {
    if (props.gotoKey != undefined && props.gotoKey != "") {
      setCurrentHelpstep(props.gotoKey);
      setAssistantState(feature.helpsteps[props.gotoKey]["size"]);
      getHighlightTarget(feature.helpsteps[props.gotoKey]["highlightid"]);
      setNeedHelp(false);
      setClosed(false);
      if (feature.helpsteps[props.gotoKey]["type"] == "tasks") {
        getUserTasks();
      }
      if (props.gotoKeyFinished != undefined) props.gotoKeyFinished();
    }
  }, [props.gotoKey]);

  useEffect(() => {
    if (
      auth.user != undefined &&
      auth.user["id"] &&
      auth.user["userdata"]["unit"][0] != ""
    ) {
      axios
        .all([
          axios.single({
            method: "get",
            url: generateUrl("/api/cra/features/bykey/") + props.featurekey,
            responseType: "json",
            headers: { "x-access-token": auth.user["token"] },
          }),
        ])
        .then(
          axios.spread((...res) => {
            if (res[0].data != undefined) {
              let helpstepFinder = 0;
              let foundNextHelpstep = false;
              res[0].data["helpsteps"].forEach((helpstep) => {
                if (!foundNextHelpstep) {
                  if (
                    JSON.parse(
                      localStorage.getItem("user") != null
                        ? (localStorage.getItem("user") as any)
                        : ""
                    )["userdata"]["seenfeatures"].filter(
                      (i) => i["key"] == props.featurekey
                    )[0] != undefined &&
                    JSON.parse(
                      localStorage.getItem("user") != null
                        ? (localStorage.getItem("user") as any)
                        : ""
                    )
                      ["userdata"]["seenfeatures"].filter(
                        (i) => i["key"] == props.featurekey
                      )[0]
                      ["helpsteps"].map((i) => i["id"])
                      .includes(helpstep["id"])
                  ) {
                    helpstepFinder++;
                  } else {
                    if (helpstep["newpopup"] !== false) {
                      foundNextHelpstep = true;
                    } else {
                      helpstepFinder++;
                    }
                  }
                }
              });

              setCurrentHelpstep(helpstepFinder);
              if (helpstepFinder == res[0].data["helpsteps"].length) {
                setAssistantState("closed");
                setClosed(true);
              } else {
                setAssistantState(
                  res[0].data["helpsteps"][helpstepFinder]["size"]
                );
              }

              setFeature(res[0].data);
              if (res[0].data["helpsteps"][helpstepFinder] != undefined) {
                setHightlightTargetId(
                  res[0].data["helpsteps"][helpstepFinder]["highlightid"]
                );
                if (
                  res[0].data["helpsteps"][helpstepFinder]["type"] == "tasks"
                ) {
                  getUserTasks();
                }
                getHighlightTarget(
                  res[0].data["helpsteps"][helpstepFinder]["highlightid"]
                );
              }
            }
          })
        )
        .catch((err) => console.log(err));
    }
  }, [props.featurekey]);

  const getButtons = () => {
    return (
      <div className="siteassistantbtncontainer">
        {currentHelpstep != 0 &&
        feature.helpsteps[currentHelpstep]["hidebuttons"] != true ? (
          <p
            className="siteassistantbtn siteassistantprevbtn"
            onClick={(evt) => handleGotoPrevHelpstep()}
          >
            {t("diri.prevbtn")}
          </p>
        ) : null}
        {feature.helpsteps[currentHelpstep]["type"] != "systemvaluereg" ? (
          currentHelpstep + 1 < feature.helpsteps.length &&
          feature.helpsteps[currentHelpstep + 1]["hidebuttons"] != true ? (
            <p
              className="siteassistantbtn siteassistantnextbtn"
              onClick={(evt) => handleGotoNextHelpstep()}
            >
              {t("diri.nextbtn")}
            </p>
          ) : (
            <p
              className="siteassistantbtn siteassistantnextbtn"
              onClick={(evt) => handleGotoNextHelpstep()}
            >
              {t("diri.closebtn")}
            </p>
          )
        ) : null}
      </div>
    );
  };

  function getUserTasks() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/tasks/responsible"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          if (res[0].data != undefined) {
            setUserTasks(res[0].data);
          }
        })
      )
      .catch((err) => console.log(err));
  }

  async function getTypes() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/occurenceTypes/public/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then((res) => {
        setOccurenceTypes(res[0].data);
      });
  }

  async function getCountedSystemObjects() {
    axios
      .all([
        axios.single({
          method: "get",
          url:
            generateUrl("/api/cra/systems/countedObjects/byOrg/") +
            auth.user["userdata"]["unit"][0],
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then((res) => {
        setCountedSystems({ loaded: true, ...res[0].data });
      });
  }

  function isOutsideTopVP(selector) {
    let elem = document.querySelector(selector);
    let bounding = elem?.getBoundingClientRect();
    if (bounding != undefined && bounding.top - 58 + window.scrollY < 0) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    getTypes();
  }, [props.occurenceOptions]);

  useEffect(() => {
    if (
      feature?.helpsteps[currentHelpstep]?.["type"]?.includes("registration") &&
      countedSystems.loaded == false
    ) {
      getCountedSystemObjects();
    }
  }, [currentHelpstep]);

  return (
    <div
      className={"siteassistant " + assistantState}
      ref={assistant}
      style={
        assistantState == "floating"
          ? {
              position: fixed ? "fixed" : "absolute",
              left: Number(highlightTargetX) + 20 + "px",
              top: Number(highlightTargetY) - Number(assistantHeight) + "px",
            }
          : {}
      }
    >
      {auth.user != undefined &&
      auth.user["id"] &&
      auth.user["userdata"]["unit"][0] != "" ? (
        feature != undefined ? (
          assistantState == "floating" ? (
            <div className="siteassistantfloating">
              <div
                className="siteassistantlogo"
                style={{ bottom: (Number(assistantHeight) - 74) * -1 + "px" }}
              >
                <div className="siteassistantlogopointer"></div>
                <img src={props.dockedLogo} alt="" />
              </div>

              <div className="siteassistantspeechbubble">
                <div
                  className="siteassistantminimise"
                  onClick={(evt) => {
                    setAssistantState("closed");
                    let logo = document.querySelector(".siteassistantlogo");
                    logo?.classList.remove("element_outside_top_vp");
                  }}
                >
                  <Tooltip title={t("diri.minimise_tooltip")} placement="top">
                    <i className="dripicons-minus" />
                  </Tooltip>
                </div>
                <div
                  className="siteassistantclose"
                  onClick={(evt) => {
                    setAssistantState("closed");
                    seenAllFeatures();
                    setClosed(true);
                    setCurrentHelpstep(feature["helpsteps"].length);
                    let logo = document.querySelector(".siteassistantlogo");
                    logo?.classList.remove("element_outside_top_vp");
                  }}
                >
                  <Tooltip title={t("diri.closebtn_tooltip")} placement="top">
                    <i className="dripicons-cross" />
                  </Tooltip>
                </div>
                {t(feature.helpsteps[currentHelpstep].text)
                  .split("\n")
                  .map((line, linekey) => (
                    <p
                      style={{ whiteSpace: "pre-line", marginBottom: "16px" }}
                      key={linekey}
                    >
                      {line}
                    </p>
                  ))}
                {getButtons()}
              </div>
            </div>
          ) : assistantState == "docked" ? (
            <div className="siteassistantdocked">
              <div className="siteassistantlogo">
                <img src={props.dockedLogo} alt="" />
              </div>
              <div className="siteassistantspeechbubble">
                <div
                  className="siteassistantminimise"
                  onClick={(evt) => {
                    setAssistantState("closed");
                  }}
                >
                  <Tooltip title={t("diri.minimise_tooltip")} placement="top">
                    <i className="dripicons-minus" />
                  </Tooltip>
                </div>
                <div
                  className="siteassistantclose"
                  onClick={(evt) => {
                    setAssistantState("closed");
                    seenAllFeatures();
                    setClosed(true);
                    setCurrentHelpstep(feature["helpsteps"].length);
                    let logo = document.querySelector(".siteassistantlogo");
                    logo?.classList.remove("element_outside_top_vp");
                  }}
                >
                  <Tooltip title={t("diri.closebtn_tooltip")} placement="top">
                    <i className="dripicons-cross" />
                  </Tooltip>
                </div>
                {t(feature.helpsteps[currentHelpstep].text)
                  .split("\\n")
                  .map((line, linekey) => (
                    <p
                      style={{ whiteSpace: "pre-line", marginBottom: "16px" }}
                      key={linekey}
                    >
                      {line}
                    </p>
                  ))}
                {getButtons()}
              </div>
            </div>
          ) : assistantState == "fullscreen" ? (
            <div className="siteassistantfullscreen">
              <div className="siteassistantlogo">
                <img src={props.dockedLogo} alt="" />
              </div>
              <div className="siteassistantspeechbubble">
                <div
                  className="siteassistantminimise"
                  onClick={(evt) => {
                    setAssistantState("closed");
                    setChosenRegTemplate("");
                  }}
                >
                  <Tooltip title={t("diri.minimise_tooltip")} placement="top">
                    <i className="dripicons-minus" />
                  </Tooltip>
                </div>
                <div
                  className="siteassistantclose"
                  onClick={(evt) => {
                    setAssistantState("closed");
                    seenAllFeatures();
                    setClosed(true);
                    setCurrentHelpstep(feature["helpsteps"].length);
                    setChosenRegTemplate("");
                  }}
                >
                  <Tooltip title={t("diri.closebtn_tooltip")} placement="top">
                    <i className="dripicons-cross" />
                  </Tooltip>
                </div>
                <h1 className="siteassistantspeechbubbletitle">
                  {t(feature.helpsteps[currentHelpstep].title)}
                </h1>
                <div className="siteassistantintroductionparagraph">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t(feature.helpsteps[currentHelpstep].text),
                    }}
                  ></div>
                </div>

                <SimpleBar
                  forceVisible="y"
                  autoHide={false}
                  clickOnTrack={false}
                >
                  {feature.helpsteps[currentHelpstep]["type"] == "choices" ? (
                    <div className="siteassistantchoicescontainer row">
                      {feature.helpsteps[currentHelpstep]["choices"] !=
                      undefined
                        ? feature.helpsteps[currentHelpstep]["choices"].map(
                            (choice, key) => (
                              <div
                                key={key}
                                className={
                                  "col col-sm-" +
                                  Math.round(
                                    12 /
                                      feature.helpsteps[currentHelpstep][
                                        "choices"
                                      ].length
                                  )
                                }
                              >
                                <div
                                  onClick={(evt) => {
                                    if (choice["link"] == "next") {
                                      handleGotoNextHelpstep();
                                    } else {
                                      history.push(choice["link"]);
                                    }
                                  }}
                                >
                                  <h3>{t(choice["title"])}</h3>
                                  {t(choice["text"])
                                    .split("\n")
                                    .map((line, textkey) =>
                                      line != "" ? (
                                        <p key={textkey}>{line}</p>
                                      ) : null
                                    )}
                                </div>
                              </div>
                            )
                          )
                        : null}
                    </div>
                  ) : null}

                  {feature.helpsteps[currentHelpstep]["type"] == "tasks" ? (
                    <div className="siteassistanttaskscontainer row">
                      <div className="col col-sm-6">
                        {usertasks.map((task, key) => (
                          <div
                            key={key}
                            className="usertask"
                            onClick={(evt) => {
                              setShowEditTaskModal(true);
                              setEditTaskId(task["_id"]);
                            }}
                          >
                            <div className="taskitemtextarea">
                              <h3 className="taskitemtitle">{task.name}</h3>
                              <p className="taskitemlineone"></p>
                              <p className="taskitemlinetwo">
                                {t(
                                  "tasks.modal_task_create.fields.statuses." +
                                    task.status
                                )}
                              </p>
                              <p className="taskitemlinerightone">
                                {t(
                                  "tasks.modal_task_create.fields.priorities." +
                                    task.priority
                                )}{" "}
                                {t(
                                  "tasks.modal_task_create.fields.priority"
                                ).toLowerCase()}
                              </p>
                              <p
                                className={
                                  "taskitemlinerighttwo " +
                                  (new Date(task.duetime) < new Date()
                                    ? "overdue"
                                    : "")
                                }
                              >
                                {t("tasks.modal_task_create.fields.duetime")}:{" "}
                                {task.duetimeReadable}
                              </p>
                            </div>
                          </div>
                        ))}
                        {usertasks.length == 0 ? (
                          <h3 className="taskitemtitle">
                            {t("diri.assignedtasks.notasks.title")}
                          </h3>
                        ) : null}
                      </div>
                      <TaskEditorModal
                        taskId={editTaskId}
                        show={showEditTaskModal}
                        onCancel={() => setShowEditTaskModal(false)}
                        onHide={() => setShowEditTaskModal(false)}
                        onSave={() => {
                          setShowEditTaskModal(false);
                          getUserTasks();
                        }}
                        onDeleteClick={(val) => {
                          setShowDeleteTaskModal(true);
                          setDeleteTaskId(val);
                        }}
                      ></TaskEditorModal>
                      <TaskDeleteModal
                        deleteTaskId={deleteTaskId}
                        onDeleted={() => {
                          setShowDeleteTaskModal(false);
                          setShowEditTaskModal(false);
                          getUserTasks();
                        }}
                        onCancel={() => setShowDeleteTaskModal(false)}
                        onHide={() => setShowDeleteTaskModal(false)}
                        show={showDeleteTaskModal}
                      ></TaskDeleteModal>
                    </div>
                  ) : null}

                  {feature.helpsteps[currentHelpstep]["type"].includes(
                    "registration"
                  ) ? (
                    <div className="siteassistantregistrationcontainer">
                      <RegAssistant
                        regCompleted={(data) => {
                          updateSeenFeatures();
                          setClosed(true);
                          setAssistantState("closed");
                          if (props.regCompleted != undefined) {
                            props.regCompleted(data);
                          }
                        }}
                        type={feature.helpsteps[currentHelpstep]["type"]}
                        options={props.options}
                        occurenceOptions={occurenceTypes}
                        treatmentOptions={treatmentTypes}
                        systemOptions={systemTypes}
                        chosenTemplate={(data) => {
                          setChosenRegTemplate(data);
                        }}
                        countedSystems={countedSystems}
                      ></RegAssistant>
                    </div>
                  ) : null}

                  {feature.helpsteps[currentHelpstep]["type"] ==
                  "systemvaluereg" ? (
                    <div className="siteassistantregistrationcontainer">
                      <SystemValueRegAssistant
                        regCompleted={(data) => {
                          if (props.regCompleted != undefined) {
                            props.regCompleted(data);
                          }
                        }}
                        systemid={props.systemid}
                        system={props.system}
                      ></SystemValueRegAssistant>
                    </div>
                  ) : null}
                </SimpleBar>
                {chosenRegTemplate != "orgros" &&
                chosenRegTemplate != "system" &&
                chosenRegTemplate != "other"
                  ? getButtons()
                  : null}
              </div>
            </div>
          ) : assistantState == "closed" ? (
            <div className="siteassistantclosed">
              <div
                className="siteassistantlogo"
                onClick={() => {
                  if (
                    feature.helpsteps.filter((i) => i.menu != false).length > 0
                  ) {
                    if (!closed) {
                      setAssistantState(
                        feature.helpsteps[currentHelpstep]["size"]
                      );
                      let logo = document.querySelector(".siteassistantlogo");
                      if (
                        isOutsideTopVP(
                          '[data-satarget="' +
                            feature.helpsteps[currentHelpstep]["highlightid"] +
                            '"]'
                        )
                      ) {
                        logo?.classList.add("element_outside_top_vp");
                      } else {
                        logo?.classList.remove("element_outside_top_vp");
                      }
                    } else {
                      setNeedHelp(!needHelp);
                    }
                  }
                }}
              >
                <img src={props.dockedLogo} alt="" />
              </div>
              {needHelp ? (
                <div className="siteassistantspeechbubble">
                  <div
                    className="siteassistantminimise"
                    onClick={(evt) => {
                      setNeedHelp(!needHelp);
                    }}
                  >
                    <i className="dripicons-minus" />
                  </div>
                  <h3>{t("diri.features.needhelptitle")}</h3>
                  {feature.helpsteps
                    .filter((i) => i.menu != false)
                    .map((i, key) =>
                      document.querySelectorAll(
                        '[data-satarget="' + i["highlightid"] + '"]'
                      ).length != 0 ||
                      i.size != "floating" ||
                      i.type != "normal" ? (
                        <div
                          className="siteassistantspeechbubblehelpelement"
                          key={key}
                        >
                          <p
                            onClick={(evt) => {
                              setCurrentHelpstep(key);
                              setAssistantState(feature.helpsteps[key]["size"]);
                              getHighlightTarget(
                                feature.helpsteps[key]["highlightid"]
                              );
                              setNeedHelp(false);
                              setClosed(false);
                              if (feature.helpsteps[key]["type"] == "tasks") {
                                getUserTasks();
                              }
                            }}
                          >
                            {t(i["title"])}
                          </p>
                        </div>
                      ) : null
                    )}
                </div>
              ) : null}
            </div>
          ) : null
        ) : null
      ) : null}
    </div>
  );
}

export default SiteAssistant;
