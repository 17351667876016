import React, { useEffect, useState } from "react";

import { Card, Button, Modal, Accordion } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
import { useTranslate } from "react-polyglot";

import { useAuth } from "../../../../Auth";
import { useAxios } from "../../../../AxiosHandler";

import Field from "../../../Shared/Wizard/Field/Field";
import { useDynamicLanguage } from "../../../../DynamicLanguageProvider";
import {
  changeAllRule,
  changeAllActionsRule,
  changeSingleActionRule,
  isAllActionsCheckedRule,
  isAllCheckedRule,
} from "./Rules/Rules";

import { generateUrl } from "../../../../config";

import "./AccessControl.scss";

function AccessControl(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;

  const t = useTranslate();
  const td = useDynamicLanguage().td;

  const [showNewRoleModal, setShowNewRoleModal] = useState(false);
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
  const [showEditRoleModal, setShowEditRoleModal] = useState(false);
  const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false);

  const [newRoleName, setNewRoleName] = useState("");
  const [newRoleDescription, setNewRoleDescription] = useState("");
  const [newRoleOrgInherit, setNewRoleOrgInherit] = useState(false);
  const [newDefaultNewUsersRole, setNewDefaultNewUsersRole] = useState(false);
  const [editRole, setEditRole] = useState<any>();
  const [editRoleName, setEditRoleName] = useState("");
  const [editRoleDescription, setEditRoleDescription] = useState("");
  const [editRoleOrgInherit, setEditRoleOrgInherit] = useState(false);
  const [editDefaultNewUsersRole, setEditDefaultNewUsersRole] = useState(false);

  const [deleteRole, setDeleteRole] = useState<any>();
  const [deleteRoleApproved, setDeleteRoleApproved] = useState(false);
  const [saveRolesConfirmed, setSaveRolesConfirmed] = useState(false);

  const [activeRole, setActiveRole] = useState("");
  const [activeRow, setActiveRow] = useState<Array<any>>([]);
  const [activeSubRows, setActiveSubRows] = useState<Array<any>>([]);

  const [checked, setChecked] = useState<Array<any>>([]);
  const [roles, setRoles] = useState<Array<any>>([]);

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  function handleOpenDeleteModal(role) {
    setDeleteRole(role);
    setShowDeleteRoleModal(true);
  }
  function handleCloseDeleteModal() {
    setDeleteRole({});
    setDeleteRoleApproved(false);
    setShowDeleteRoleModal(false);
  }

  function handleOpenEditRoleModal(role) {
    setEditRole(role);
    setEditRoleName(role.name);
    setEditRoleDescription(role.description);
    setEditRoleOrgInherit(role.orgInherit);
    setEditDefaultNewUsersRole(role.defaultNewUsers);
    setShowEditRoleModal(true);
  }

  function handleCloseEditRoleModal() {
    setEditRole({});
    setEditRoleName("");
    setEditRoleOrgInherit(false);
    setEditDefaultNewUsersRole(false);
    setShowEditRoleModal(false);
  }

  function handleCloseNewRoleModal() {
    setShowNewRoleModal(false);
    setNewRoleName("");
    setNewRoleOrgInherit(false);
    setNewDefaultNewUsersRole(false);
  }

  function handleCloseConfirmSaveModal() {
    setEditRole({});
    setEditRoleName("");
    setSaveRolesConfirmed(false);
    setShowConfirmSaveModal(false);
  }

  /**
   * Checked-functions
   * @param {string} role Name of the role
   * @param {string} objType Main object type
   * @param {string} access Rule access level
   * @param {string} actionaccess Action access level
   * Checks all
   * @return {Array}  Returns array of booleans
   */

  function isAllChecked(role, objType, access, actionaccess) {
    return isAllCheckedRule(
      checked,
      role,
      objType,
      access,
      actionaccess,
      isSingleActionChecked,
      isAllActionsChecked
    );
  }

  /**
   * Checked-functions
   * @param {string} role Name of the role
   * @param {string} objType Main object type
   * @param {string} objSubType Object sub type
   * @param {string} access Rule access level
   * @param {string} actionaccess Action access level
   * @param {string} chosenAction The chosen action to be changed
   * Checks all actions
   * @return {Array}  Returns array of booleans
   */
  function isAllActionsChecked(
    role,
    objType,
    access,
    objSubType,
    actionaccess
  ) {
    return isAllActionsCheckedRule(
      checked,
      role,
      objType,
      access,
      objSubType,
      actionaccess,
      isSingleChecked,
      isSingleActionChecked
    );
  }

  /**
   * Checks single
   * @param {string} role Name of the role
   * @param {string} objType Main object type
   * @param {string} objSubType Object sub type
   * @param {string} access Rule access level
   * @return {boolean} Returns a boolean
   */
  function isSingleChecked(role, objType, access, objSubType) {
    switch (objType) {
      default: {
        let accessLevel = checked
          .find((checked) => checked?._id == role)
          ?.rules.find(
            (rule) => rule.objType == objType && rule.objSubType == objSubType
          )?.access;
        if (accessLevel == "w") {
          return accessLevel == access || access == "r";
        } else {
          return accessLevel == access;
        }
      }
    }
  }

  /**
   * Checks single action
   * @param {string} role Name of the role
   * @param {string} objType Main object type
   * @param {string} objSubType Object sub type
   * @param {string} chosenAction The chosen action to be changed
   * @param {string} actionaccess Action access level
   * @return {boolean} Returns a boolean
   */
  function isSingleActionChecked(
    role,
    objType,
    objSubType,
    chosenAction,
    actionaccess
  ) {
    return (
      checked
        .find((checked) => checked?._id == role)
        ?.rules.find(
          (rule) => rule.objType == objType && rule.objSubType == objSubType
        )?.actions?.[chosenAction] == actionaccess ?? [false]
    );
  }

  /**
   * Edits all elements within object type
   * @param {string} role Name of the role
   * @param {boolean} evt The click event from checkbox
   * @param {string} access Rule access level
   * @param {string} actionaccess Action access level
   * @return {Array} Returns array of role objects
   */

  const handleChangeAll = (role, evt, access, actionaccess, objType) => {
    setChecked(
      checked.map((c) => {
        if (c._id == role) {
          return changeAllRule(
            c,
            evt,
            access,
            actionaccess,
            changeAllActionObjects,
            objType
          );
        } else {
          return c;
        }
      })
    );
  };

  /**
   * Edits all values in actions object
   * @param {array} actions Array of the object keys to be changed
   * @param {boolean} evt The click event from checkbox
   * @param {string} actionaccess Action access level
   * @return {object}  Returns actions object
   */
  function changeAllActionObjects(actions, evt, actionaccess) {
    let obj = {};
    actions.forEach((action) => {
      obj[action] = evt == true ? actionaccess : "";
    });
    return obj;
  }

  /**
   * Edits all actions/subactions
   * @param {string} role Name of the role
   * @param {boolean} evt The click event from checkbox
   * @param {string} objType Main object type
   * @param {string} objSubType Object sub type
   * @param {string} access Rule access level
   * @param {string} actionaccess Action access level
   * @param {array} subactions Array of sub actions (optional)
   * @return {Array}  Returns array of role objects
   */
  const handleChangeAllActions = (
    role,
    evt,
    objType,
    objSubType,
    access,
    actionaccess,
    subactions?: Array<any>
  ) => {
    setChecked(
      checked.map((c) => {
        if (c._id == role) {
          return {
            ...c,
            rules: c.rules.map((rule) => {
              if (rule.objType == objType && rule.objSubType == objSubType) {
                return changeAllActionsRule(
                  objType,
                  objSubType,
                  access,
                  rule,
                  evt,
                  changeAllActionObjects,
                  subactions,
                  actionaccess
                );
              } else {
                return rule;
              }
            }),
          };
        } else {
          return c;
        }
      })
    );
  };

  /**
   * Edits single rule
   * @param {boolean} evt The click event from checkbox
   * @param {string} role Name of the role
   * @param {string} objType Main object type
   * @param {string} objSubType Object sub type
   * @param {string} access Rule access level
   * @param {array} actions Array of actions to be changed (optional)
   * @return {Array}  Returns array of role objects
   */
  const handleChangeSingle = (
    evt,
    role,
    objType,
    objSubType,
    access,
    actions?: Array<any>
  ) => {
    setChecked(
      checked.map((c) => {
        if (c._id == role) {
          return {
            ...c,
            rules: c.rules.map((rule) => {
              if (rule.objType == objType && rule.objSubType == objSubType) {
                rule = {
                  ...rule,
                  access: evt == true ? access : access == "w" ? "r" : "",
                  actions:
                    access == "d" && actions != undefined
                      ? changeAllActionObjects(actions, evt, "")
                      : { ...rule.actions },
                };
                return rule;
              } else {
                return rule;
              }
            }),
          };
        } else {
          return c;
        }
      })
    );
  };

  /**
   * Edits single action/subaction
   * @param {boolean} evt The click event from checkbox
   * @param {string} role Name of the role
   * @param {string} objSubType Object sub type
   * @param {string} access Rule access level
   * @param {string} chosenAction The chosen action to be changed
   * @param {string} actionaccess Action access level
   * @return {Array} Returns array of role objects
   */

  const handleChangeSingleAction = (
    evt,
    role,
    objSubType,
    chosenAction,
    actionaccess
  ) => {
    setChecked(
      checked.map((c) => {
        if (c._id == role) {
          return changeSingleActionRule(
            c.objType,
            objSubType,
            c,
            evt,
            actionaccess,
            chosenAction
          );
        } else {
          return c;
        }
      })
    );
  };

  /**
   * Checks all actions with specific name for all rules
   * @param {boolean} evt The click event from checkbox
   * @param {string} chosenAction The name of the action to be changed
   * @param {string} roleID The ID for the role to be changed
   */

  const handleChangeAllActionsByName = (evt, chosenAction, roleID, objType) => {
    setChecked(
      checked.map((c) => {
        if (c._id == roleID) {
          return (c = {
            ...c,
            rules: c.rules.map((rule) => {
              if (rule.objType == objType) {
                return (rule = {
                  ...rule,
                  actions: {
                    ...rule.actions,
                    [chosenAction]: evt == true ? "allow" : "",
                  },
                });
              } else {
                return rule;
              }
            }),
          });
        } else {
          return c;
        }
      })
    );
  };

  function handleDiscardSingleRoleChanges(role) {
    setChecked(
      checked.map((c) => {
        if (c._id == role._id) {
          return (c = role);
        } else {
          return c;
        }
      })
    );
  }

  /**
   * Renders checkbox field
   * @param isChecked Array of booleans if field is parent, or boolean if field is child
   * @param handleOnChange On change handler
   * @param {boolean} parent Whether the field is a parent or not (only parents can be indeterminate)
   * @param {boolean} disabled Whether field is disabled or not
   * @param {string} title Title/label for checkbox
   */

  function renderCheckboxField(
    isChecked,
    handleOnChange,
    parent,
    disabled,
    title,
    role
  ) {
    return (
      <Field
        type="indeterminatecheckbox"
        checked={isChecked}
        onChange={handleOnChange}
        parent={parent}
        disabled={!role?.accesses?.includes("edit") ? true : disabled}
        title={title}
        titlePlacement="start" //if made to parameter: allowed values: top/start/bottom/end
      ></Field>
    );
  }

  function handleCreateAccessRole() {
    axios
      .post(
        generateUrl("/api/cra/accessRoles/"),
        {
          role: newRoleName,
          description: newRoleDescription,
          orgInherit: newRoleOrgInherit,
          defaultNewUsers: newDefaultNewUsersRole,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        fetchData();
        handleCloseNewRoleModal();
        notifySuccess(t("accessControl.toast.success.createdRole"));
        if (props.onSave != undefined) {
          props.onSave();
        }
      });
  }

  function handleSaveAccessRoles() {
    let tempRoles = checked.filter(
      (role) => auth.user["userdata"]["unit"][0] == role.orgId
    );
    axios
      .put(
        generateUrl("/api/cra/accessRoles/updateall/"),
        {
          roles: tempRoles,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        handleCloseConfirmSaveModal();
        setSaveRolesConfirmed(false);
        //setRoles(res.data);
        //setChecked(res.data);
        fetchData();
        notifySuccess(t("accessControl.toast.success.savedRoles"));
        if (props.onSave != undefined) {
          props.onSave();
        }
        axios
          .all([
            axios.single({
              method: "get",
              url: generateUrl("/api/cra/accessRoles/actualAccessRole"),
              responseType: "json",
              headers: { "x-access-token": auth.user["token"] },
            }),
          ])
          .then(
            axios.spread((...res2) => {
              let user = localStorage.getItem("user");
              if (user != null) {
                user = JSON.parse(user);
                if (user != null) {
                  user["userdata"]["actualAccessRole"] = res2[0].data;
                  localStorage.setItem("user", JSON.stringify(user));
                }
              }
              if (auth.user != null) {
                let userObject = {
                  ...auth.user,
                  userdata: {
                    ...auth.user["userdata"],
                    actualAccessRole: res2[0].data,
                  },
                };
                auth.setUser(userObject);
              }
            })
          );
      });
  }

  function handleEditRole() {
    axios
      .put(
        generateUrl("/api/cra/accessRoles/updaterole/") + editRole._id,
        {
          name: editRoleName,
          description: editRoleDescription,
          orgInherit: editRoleOrgInherit,
          defaultNewUsers: editDefaultNewUsersRole,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        let tempRoles = roles.map((role) => {
          return role._id == editRole._id
            ? {
                ...role,
                name: res.data.name,
                description: res.data.description,
                orgInherit: res.data.orgInherit,
              }
            : role;
        });
        let tempChecked = checked.map((role) => {
          return role._id == editRole._id
            ? {
                ...role,
                name: res.data.name,
                description: res.data.description,
                orgInherit: res.data.orgInherit,
              }
            : role;
        });
        setRoles(tempRoles);
        setChecked(tempChecked);
        handleCloseEditRoleModal();
        notifySuccess(t("accessControl.toast.success.savedRoleName"));
        if (props.onSave != undefined) {
          props.onSave();
        }
      })
      .catch((err) => console.log(err));
  }

  function handleDeleteRole() {
    axios
      .delete(
        generateUrl("/api/cra/accessRoles/") + deleteRole._id,
        {},
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then((res) => {
        handleCloseDeleteModal();
        fetchData();
        notifySuccess(t("accessControl.toast.success.deletedRole"));
        if (props.onSave != undefined) {
          props.onSave();
        }
      });
  }

  async function fetchData() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/accessRoles"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setRoles(res[0].data);
          setChecked(res[0].data);
        })
      )
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchData();
  }, []);

  function renderRoles() {
    return (
      <Accordion>
        {roles.map((role, key) => (
          <div key={key}>
            <Accordion.Toggle
              className={
                "clickable " +
                (activeRole == role._id.toString() ? "activeRoleRow" : "")
              }
              as={Card.Header}
              eventKey={role._id.toString()}
              onClick={() => {
                activeRole != role._id.toString()
                  ? setActiveRole(role._id.toString())
                  : setActiveRole("");
              }}
            >
              <div className="row">
                <div className="col-md-8">
                  <h4 className="header-title">{td(role.name)}</h4>
                </div>

                {auth.user["userdata"]["unit"][0] == role.orgId ? (
                  <React.Fragment>
                    {JSON.stringify(role) !=
                    JSON.stringify(checked.find((c) => c._id == role._id)) ? (
                      <div className="col-md-2 main-row-items">
                        <Button
                          onClick={(evt) => {
                            evt.preventDefault();
                            evt.stopPropagation();
                            handleDiscardSingleRoleChanges(role);
                          }}
                        >
                          {t("accessControl.buttons.discard_role_changes")}
                        </Button>
                      </div>
                    ) : (
                      <div className="col-md-2">&nbsp;</div>
                    )}

                    <div
                      className={
                        "col-md-1 main-row-items " +
                        (!role?.accesses?.includes("edit") ? "disabled" : "")
                      }
                    >
                      <i
                        aria-label="Rename"
                        className="dripicons-document-edit"
                        onClick={(evt) => {
                          if (role?.accesses?.includes("edit")) {
                            evt.preventDefault();
                            evt.stopPropagation();
                            handleOpenEditRoleModal(role);
                          }
                        }}
                      />
                    </div>
                    {role.inUse ? (
                      <Tooltip
                        title={t("accessControl.table.role_in_use")}
                        placement="top"
                      >
                        <div className={"col-md-1 main-row-items disabled"}>
                          <i
                            aria-label="Delete"
                            className="dripicons-trash"
                            style={{ color: "grey" }}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        className={
                          "col-md-1 main-row-items " +
                          (!role?.accesses?.includes("delete")
                            ? "disabled"
                            : "")
                        }
                      >
                        <i
                          aria-label="Delete"
                          className="dripicons-trash"
                          onClick={(evt) => {
                            if (role?.accesses?.includes("delete")) {
                              evt.preventDefault();
                              evt.stopPropagation();
                              handleOpenDeleteModal(role);
                            }
                          }}
                        />
                      </div>
                    )}
                  </React.Fragment>
                ) : null}
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={role._id.toString()}>
              <div className={"accessControlTableParentDiv"}>
                <div
                  className={
                    !role?.accesses?.includes("edit") ? "disabled" : "hidden"
                  }
                ></div>
                <div>
                  <p className="roleDesc">{role.description}</p>
                </div>

                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th colSpan={2} style={{ width: "70%" }}></th>
                      <th>{t("accessControl.table.read")}</th>
                      <th>{t("accessControl.table.edit")}</th>
                      <th>{t("accessControl.table.create")}</th>
                      <th>{t("accessControl.table.delete")}</th>
                      {/* <th>{t("accessControl.table.no_access")}</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Rows for object type: system */}
                    <tr className="mainRow">
                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_systems")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_systems",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_systems"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(
                              role._id.toString() + "_systems"
                            )
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                      <td
                        colSpan={2}
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_systems")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_systems",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_systems"
                                )
                              )
                        }
                      >
                        {t("accessControl.table.systems")}
                      </td>
                      <td>
                        {" "}
                        {renderCheckboxField(
                          isAllChecked(role._id, "system", "r", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "r",
                              "allow",
                              "system"
                            ),
                          true,
                          isSingleChecked(role._id, "system", "d", "system") ||
                            isSingleChecked(
                              role._id,
                              "system",
                              "d",
                              "orgros"
                            ) ||
                            isSingleChecked(role._id, "system", "d", "other"),
                          undefined,
                          role
                        )}
                      </td>
                      <td>
                        {" "}
                        {renderCheckboxField(
                          isAllChecked(role._id, "system", "w", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "w",
                              "allow",
                              "system"
                            ),
                          true,
                          isSingleChecked(role._id, "system", "d", "system") ||
                            isSingleChecked(
                              role._id,
                              "system",
                              "d",
                              "orgros"
                            ) ||
                            isSingleChecked(role._id, "system", "d", "other"),
                          undefined,
                          role
                        )}{" "}
                      </td>

                      <td>
                        {" "}
                        {renderCheckboxField(
                          role.rules
                            .filter((rule) => rule.objType == "system")
                            .map((rule) =>
                              isSingleActionChecked(
                                role._id,
                                "system",
                                rule.objSubType,
                                "create",
                                "allow"
                              )
                            ),
                          (evt) =>
                            handleChangeAllActionsByName(
                              evt,
                              "create",
                              role._id,
                              "system"
                            ),
                          true,
                          isSingleChecked(role._id, "system", "d", "system") ||
                            isSingleChecked(
                              role._id,
                              "system",
                              "d",
                              "orgros"
                            ) ||
                            isSingleChecked(role._id, "system", "d", "other"),
                          undefined,
                          role
                        )}
                      </td>
                      <td>
                        {" "}
                        {renderCheckboxField(
                          role.rules
                            .filter((rule) => rule.objType == "system")
                            .map((rule) =>
                              isSingleActionChecked(
                                role._id,
                                "system",
                                rule.objSubType,
                                "delete",
                                "allow"
                              )
                            ),
                          (evt) =>
                            handleChangeAllActionsByName(
                              evt,
                              "delete",
                              role._id,
                              "system"
                            ),
                          true,
                          isSingleChecked(role._id, "system", "d", "system") ||
                            isSingleChecked(
                              role._id,
                              "system",
                              "d",
                              "orgros"
                            ) ||
                            isSingleChecked(role._id, "system", "d", "other"),
                          undefined,
                          role
                        )}
                      </td>

                      {/* <td className="noAccessTd"> {renderCheckboxField(isAllChecked(role.name, "system", "d", "denied"), (evt) => {handleChangeAll(role.name, evt, "d", ""); setActiveSubRows([])}, true, false, undefined, role)} </td> */}
                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_systems")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_systems",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_systems"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(
                              role._id.toString() + "_systems"
                            )
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    className={
                      "subfields " +
                      (activeRow.includes(role._id.toString() + "_systems")
                        ? ""
                        : "hidden")
                    }
                  >
                    {/* Rows for system SYSTEM */}
                    <React.Fragment>
                      <tr>
                        <td
                          className={
                            isSingleChecked(role._id, "system", "d", "system")
                              ? "disabled"
                              : "clickable"
                          }
                          onClick={() =>
                            isSingleChecked(role._id, "system", "d", "system")
                              ? null
                              : !activeSubRows.includes(
                                  role._id.toString() + "_systems" + "_system"
                                )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() + "_systems" + "_system",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_systems" +
                                        "_system"
                                  )
                                )
                          }
                        >
                          <i
                            className={
                              !activeSubRows.includes(
                                role._id + "_systems" + "_system"
                              )
                                ? "dripicons-chevron-down"
                                : "dripicons-chevron-up"
                            }
                          />
                        </td>
                        <td
                          colSpan={2}
                          className={
                            isSingleChecked(role._id, "system", "d", "system")
                              ? "disabled"
                              : "clickable"
                          }
                          onClick={() =>
                            isSingleChecked(role._id, "system", "d", "system")
                              ? null
                              : !activeSubRows.includes(
                                  role._id.toString() + "_systems" + "_system"
                                )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() + "_systems" + "_system",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_systems" +
                                        "_system"
                                  )
                                )
                          }
                        >
                          {t("accessControl.table.systems_system")}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "system",
                              "r",
                              "system",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "system",
                                "read",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "system",
                                "system",
                                "r",
                                "allow"
                              );
                            },
                            true,
                            isSingleChecked(role._id, "system", "d", "system"),
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "system",
                              "w",
                              "system",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "system",
                                "edit",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "system",
                                "system",
                                "w",
                                "allow"
                              );
                            },
                            true,
                            isSingleChecked(role._id, "system", "d", "system"),
                            undefined,
                            role
                          )}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "system",
                              "system",
                              "create",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "system",
                                "create",
                                "allow"
                              ),
                            false,
                            isSingleChecked(role._id, "system", "d", "system"),
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "system",
                              "system",
                              "delete",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "system",
                                "delete",
                                "allow"
                              ),
                            false,
                            isSingleChecked(role._id, "system", "d", "system"),
                            undefined,
                            role
                          )}
                        </td>

                        {/* <td className="noAccessTd"> {renderCheckboxField(isSingleChecked(role._id, "system", "d", "system"), (evt) => {handleChangeSingle(evt, role._id, "system", "system", "d", ["edit", "create", "delete"]); setActiveSubRows(activeSubRows.filter(item => item != role.name + "_systems" + "_system"));}, false, false, undefined, role)}</td> */}
                        <td
                          className={"clickable"}
                          onClick={() =>
                            !activeSubRows.includes(
                              role._id.toString() + "_systems" + "_system"
                            )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() + "_systems" + "_system",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_systems" +
                                        "_system"
                                  )
                                )
                          }
                        >
                          {" "}
                          <i
                            className={
                              !activeSubRows.includes(
                                role._id.toString() + "_systems" + "_system"
                              )
                                ? "dripicons-chevron-down"
                                : "dripicons-chevron-up"
                            }
                          />{" "}
                        </td>
                      </tr>
                      <React.Fragment>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_systems" + "_system"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t("accessControl.table.copy") +
                              " " +
                              t("accessControl.table.requires_create")}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "system",
                                "copy",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "system",
                                  "copy",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_systems" + "_system"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>{t("accessControl.table.edit_ownership")}</td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "system",
                                "edit_ownership",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "system",
                                  "edit_ownership",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_systems" + "_system"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t("accessControl.table.request_access_private")}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "system",
                                "request_access_private",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "system",
                                  "request_access_private",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        {/* TREATMENT PLAN */}
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_systems" + "_system"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td
                            className="clickable"
                            onClick={() =>
                              !activeSubRows.includes(
                                role._id.toString() +
                                  "_systems" +
                                  "_system" +
                                  "_actionplan"
                              )
                                ? setActiveSubRows([
                                    ...activeSubRows,
                                    role._id.toString() +
                                      "_systems" +
                                      "_system" +
                                      "_actionplan",
                                  ])
                                : setActiveSubRows(
                                    activeSubRows.filter(
                                      (item) =>
                                        item !=
                                        role._id.toString() +
                                          "_systems" +
                                          "_system" +
                                          "_actionplan"
                                    )
                                  )
                            }
                          >
                            <i
                              className={
                                !activeSubRows.includes(
                                  role._id.toString() +
                                    "_systems" +
                                    "_system" +
                                    "_actionplan"
                                )
                                  ? "dripicons-chevron-down"
                                  : "dripicons-chevron-up"
                              }
                            />
                          </td>
                          <td
                            className="clickable"
                            onClick={() =>
                              !activeSubRows.includes(
                                role._id.toString() +
                                  "_systems" +
                                  "_system" +
                                  "_actionplan"
                              )
                                ? setActiveSubRows([
                                    ...activeSubRows,
                                    role._id.toString() +
                                      "_systems" +
                                      "_system" +
                                      "_actionplan",
                                  ])
                                : setActiveSubRows(
                                    activeSubRows.filter(
                                      (item) =>
                                        item !=
                                        role._id.toString() +
                                          "_systems" +
                                          "_system" +
                                          "_actionplan"
                                    )
                                  )
                            }
                          >
                            {t("accessControl.table.treatmentplan")}
                          </td>
                          <td>
                            {renderCheckboxField(
                              [
                                isSingleActionChecked(
                                  role._id,
                                  "system",
                                  "system",
                                  "show_treatment_plan",
                                  "allow"
                                ),
                                isSingleActionChecked(
                                  role._id,
                                  "system",
                                  "system",
                                  "sign_treatment_plan",
                                  "allow"
                                ),
                              ],
                              (evt) => {
                                handleChangeAllActions(
                                  role._id,
                                  evt,
                                  "system",
                                  "system",
                                  "w",
                                  "allow",
                                  ["show_treatment_plan", "sign_treatment_plan"]
                                );
                              },
                              true,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td
                            colSpan={6}
                            className="clickable"
                            onClick={() =>
                              !activeSubRows.includes(
                                role._id.toString() +
                                  "_systems" +
                                  "_system" +
                                  "_actionplan"
                              )
                                ? setActiveSubRows([
                                    ...activeSubRows,
                                    role._id.toString() +
                                      "_systems" +
                                      "_system" +
                                      "_actionplan",
                                  ])
                                : setActiveSubRows(
                                    activeSubRows.filter(
                                      (item) =>
                                        item !=
                                        role._id.toString() +
                                          "_systems" +
                                          "_system" +
                                          "_actionplan"
                                    )
                                  )
                            }
                          ></td>
                        </tr>
                        <tr
                          className={
                            "subfieldsrow subactionrow " +
                            (activeSubRows.includes(
                              role._id.toString() +
                                "_systems" +
                                "_system" +
                                "_actionplan"
                            ) &&
                            activeSubRows.includes(
                              role._id.toString() + "_systems" + "_system"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>{t("accessControl.table.show_treatmentplan")}</td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "system",
                                "show_treatment_plan",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "system",
                                  "show_treatment_plan",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "subfieldsrow subactionrow " +
                            (activeSubRows.includes(
                              role._id.toString() +
                                "_systems" +
                                "_system" +
                                "_actionplan"
                            ) &&
                            activeSubRows.includes(
                              role._id.toString() + "_systems" + "_system"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>{t("accessControl.table.sign_treatmentplan")}</td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "system",
                                "sign_treatment_plan",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "system",
                                  "sign_treatment_plan",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                      </React.Fragment>
                    </React.Fragment>

                    {/* Rows for system ORGROS */}
                    <React.Fragment>
                      <tr>
                        <td
                          className={
                            isSingleChecked(role._id, "system", "d", "orgros")
                              ? "disabled"
                              : "clickable"
                          }
                          onClick={() =>
                            isSingleChecked(role._id, "system", "d", "orgros")
                              ? null
                              : !activeSubRows.includes(
                                  role._id.toString() + "_systems" + "_orgros"
                                )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() + "_systems" + "_orgros",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_systems" +
                                        "_orgros"
                                  )
                                )
                          }
                        >
                          <i
                            className={
                              !activeSubRows.includes(
                                role._id + "_systems" + "_orgros"
                              )
                                ? "dripicons-chevron-down"
                                : "dripicons-chevron-up"
                            }
                          />
                        </td>
                        <td
                          colSpan={2}
                          className={
                            isSingleChecked(role._id, "system", "d", "orgros")
                              ? "disabled"
                              : "clickable"
                          }
                          onClick={() =>
                            isSingleChecked(role._id, "system", "d", "orgros")
                              ? null
                              : !activeSubRows.includes(
                                  role._id.toString() + "_systems" + "_orgros"
                                )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() + "_systems" + "_orgros",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_systems" +
                                        "_orgros"
                                  )
                                )
                          }
                        >
                          {" "}
                          {t("accessControl.table.systems_orgros")}{" "}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "system",
                              "r",
                              "orgros",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "orgros",
                                "read",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "system",
                                "orgros",
                                "r",
                                "allow"
                              );
                            },
                            true,
                            isSingleChecked(role._id, "system", "d", "orgros"),
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "system",
                              "w",
                              "orgros",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "orgros",
                                "edit",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "system",
                                "orgros",
                                "w",
                                "allow"
                              );
                            },
                            true,
                            isSingleChecked(role._id, "system", "d", "orgros"),
                            undefined,
                            role
                          )}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "system",
                              "orgros",
                              "create",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "orgros",
                                "create",
                                "allow"
                              ),
                            false,
                            isSingleChecked(role._id, "system", "d", "orgros"),
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "system",
                              "orgros",
                              "delete",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "orgros",
                                "delete",
                                "allow"
                              ),
                            false,
                            isSingleChecked(role._id, "system", "d", "orgros"),
                            undefined,
                            role
                          )}
                        </td>

                        {/* <td className="noAccessTd">{renderCheckboxField(isSingleChecked(role._id, "system", "d", "orgros"), (evt) => {handleChangeSingle(evt, role._id, "system", "orgros", "d", ["edit", "create", "delete", "show_action_plan", "sign_action_plan"]); setActiveSubRows(activeSubRows.filter(item => item != role.name + "_systems" + "_orgros"))}, false, false, undefined, role)}</td> */}
                        <td
                          className={
                            isSingleChecked(role._id, "system", "d", "orgros")
                              ? "disabled"
                              : "clickable"
                          }
                          onClick={() =>
                            isSingleChecked(role._id, "system", "d", "orgros")
                              ? null
                              : !activeSubRows.includes(
                                  role._id.toString() + "_systems" + "_orgros"
                                )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() + "_systems" + "_orgros",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_systems" +
                                        "_orgros"
                                  )
                                )
                          }
                        >
                          {" "}
                          <i
                            className={
                              !activeSubRows.includes(
                                role._id.toString() + "_systems" + "_orgros"
                              )
                                ? "dripicons-chevron-down"
                                : "dripicons-chevron-up"
                            }
                          />{" "}
                        </td>
                      </tr>

                      <React.Fragment>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_systems" + "_orgros"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t("accessControl.table.copy") +
                              " " +
                              t("accessControl.table.requires_create")}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "orgros",
                                "copy",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "orgros",
                                  "copy",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_systems" + "_orgros"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>{t("accessControl.table.edit_ownership")}</td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "orgros",
                                "edit_ownership",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "orgros",
                                  "edit_ownership",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_systems" + "_orgros"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t("accessControl.table.request_access_private")}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "orgros",
                                "request_access_private",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "orgros",
                                  "request_access_private",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        {/* TREATMENT PLAN */}
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_systems" + "_orgros"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td
                            className="clickable"
                            onClick={() =>
                              !activeSubRows.includes(
                                role._id.toString() +
                                  "_systems" +
                                  "_orgros" +
                                  "_actionplan"
                              )
                                ? setActiveSubRows([
                                    ...activeSubRows,
                                    role._id.toString() +
                                      "_systems" +
                                      "_orgros" +
                                      "_actionplan",
                                  ])
                                : setActiveSubRows(
                                    activeSubRows.filter(
                                      (item) =>
                                        item !=
                                        role._id.toString() +
                                          "_systems" +
                                          "_orgros" +
                                          "_actionplan"
                                    )
                                  )
                            }
                          >
                            <i
                              className={
                                !activeSubRows.includes(
                                  role._id.toString() +
                                    "_systems" +
                                    "_orgros" +
                                    "_actionplan"
                                )
                                  ? "dripicons-chevron-down"
                                  : "dripicons-chevron-up"
                              }
                            />
                          </td>
                          <td
                            className="clickable"
                            onClick={() =>
                              !activeSubRows.includes(
                                role._id.toString() +
                                  "_systems" +
                                  "_orgros" +
                                  "_actionplan"
                              )
                                ? setActiveSubRows([
                                    ...activeSubRows,
                                    role._id.toString() +
                                      "_systems" +
                                      "_orgros" +
                                      "_actionplan",
                                  ])
                                : setActiveSubRows(
                                    activeSubRows.filter(
                                      (item) =>
                                        item !=
                                        role._id.toString() +
                                          "_systems" +
                                          "_orgros" +
                                          "_actionplan"
                                    )
                                  )
                            }
                          >
                            {t("accessControl.table.treatmentplan")}{" "}
                          </td>
                          <td>
                            {renderCheckboxField(
                              [
                                isSingleActionChecked(
                                  role._id,
                                  "system",
                                  "orgros",
                                  "show_treatment_plan",
                                  "allow"
                                ),
                                isSingleActionChecked(
                                  role._id,
                                  "system",
                                  "orgros",
                                  "sign_treatment_plan",
                                  "allow"
                                ),
                              ],
                              (evt) => {
                                handleChangeAllActions(
                                  role._id,
                                  evt,
                                  "system",
                                  "orgros",
                                  "w",
                                  "allow",
                                  ["show_treatment_plan", "sign_treatment_plan"]
                                );
                              },
                              true,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td
                            colSpan={6}
                            className="clickable"
                            onClick={() =>
                              !activeSubRows.includes(
                                role._id.toString() +
                                  "_systems" +
                                  "_orgros" +
                                  "_actionplan"
                              )
                                ? setActiveSubRows([
                                    ...activeSubRows,
                                    role._id.toString() +
                                      "_systems" +
                                      "_orgros" +
                                      "_actionplan",
                                  ])
                                : setActiveSubRows(
                                    activeSubRows.filter(
                                      (item) =>
                                        item !=
                                        role._id.toString() +
                                          "_systems" +
                                          "_orgros" +
                                          "_actionplan"
                                    )
                                  )
                            }
                          ></td>
                        </tr>
                        <tr
                          className={
                            "subfieldsrow subactionrow " +
                            (activeSubRows.includes(
                              role._id.toString() +
                                "_systems" +
                                "_orgros" +
                                "_actionplan"
                            ) &&
                            activeSubRows.includes(
                              role._id.toString() + "_systems" + "_orgros"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>{t("accessControl.table.show_treatmentplan")}</td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "orgros",
                                "show_treatment_plan",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "orgros",
                                  "show_treatment_plan",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "subfieldsrow subactionrow " +
                            (activeSubRows.includes(
                              role._id.toString() +
                                "_systems" +
                                "_orgros" +
                                "_actionplan"
                            ) &&
                            activeSubRows.includes(
                              role._id.toString() + "_systems" + "_orgros"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>{t("accessControl.table.sign_treatmentplan")}</td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "orgros",
                                "sign_treatment_plan",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "orgros",
                                  "sign_treatment_plan",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                      </React.Fragment>
                    </React.Fragment>

                    {/* Rows for system OTHER */}
                    <React.Fragment>
                      <tr>
                        <td
                          className={
                            isSingleChecked(role._id, "system", "d", "other")
                              ? "disabled"
                              : "clickable"
                          }
                          onClick={() =>
                            isSingleChecked(role._id, "system", "d", "other")
                              ? null
                              : !activeSubRows.includes(
                                  role._id.toString() + "_systems" + "_other"
                                )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() + "_systems" + "_other",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_systems" +
                                        "_other"
                                  )
                                )
                          }
                        >
                          <i
                            className={
                              !activeSubRows.includes(
                                role._id.toString() + "_systems" + "_other"
                              )
                                ? "dripicons-chevron-down"
                                : "dripicons-chevron-up"
                            }
                          />
                        </td>
                        <td
                          colSpan={2}
                          className={
                            isSingleChecked(role._id, "system", "d", "other")
                              ? "disabled"
                              : "clickable"
                          }
                          onClick={() =>
                            isSingleChecked(role._id, "system", "d", "other")
                              ? null
                              : !activeSubRows.includes(
                                  role._id.toString() + "_systems" + "_other"
                                )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() + "_systems" + "_other",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_systems" +
                                        "_other"
                                  )
                                )
                          }
                        >
                          {t("accessControl.table.systems_other")}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "system",
                              "r",
                              "other",
                              "denied"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "other",
                                "read",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "system",
                                "other",
                                "r",
                                "allow"
                              );
                            },
                            true,
                            isSingleChecked(role._id, "system", "d", "other"),
                            undefined,
                            role
                          )}{" "}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "system",
                              "w",
                              "other",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "other",
                                "edit",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "system",
                                "other",
                                "w",
                                "allow"
                              );
                            },
                            true,
                            isSingleChecked(role._id, "system", "d", "other"),
                            undefined,
                            role
                          )}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "system",
                              "other",
                              "create",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "other",
                                "create",
                                "allow"
                              ),
                            false,
                            isSingleChecked(role._id, "system", "d", "other"),
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "system",
                              "other",
                              "delete",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "other",
                                "delete",
                                "allow"
                              ),
                            false,
                            isSingleChecked(role._id, "system", "d", "other"),
                            undefined,
                            role
                          )}
                        </td>

                        {/* <td className="noAccessTd">{renderCheckboxField(isSingleChecked(role._id, "system", "d", "other"), (evt) => {handleChangeSingle(evt, role._id, "system", "other", "d", ["edit", "create", "delete"]); setActiveSubRows(activeSubRows.filter(item => item != role.name + "_systems" + "_other"));}, false, false, undefined, role)}</td> */}
                        <td
                          className={"clickable"}
                          onClick={() =>
                            !activeSubRows.includes(
                              role._id.toString() + "_systems" + "_other"
                            )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() + "_systems" + "_other",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_systems" +
                                        "_other"
                                  )
                                )
                          }
                        >
                          {" "}
                          <i
                            className={
                              !activeSubRows.includes(
                                role._id.toString() + "_systems" + "_other"
                              )
                                ? "dripicons-chevron-down"
                                : "dripicons-chevron-up"
                            }
                          />{" "}
                        </td>
                      </tr>
                      <React.Fragment>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_systems" + "_other"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t("accessControl.table.copy") +
                              " " +
                              t("accessControl.table.requires_create")}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "other",
                                "copy",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "other",
                                  "copy",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_systems" + "_other"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>{t("accessControl.table.edit_ownership")}</td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "other",
                                "edit_ownership",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "other",
                                  "edit_ownership",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_systems" + "_other"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t("accessControl.table.request_access_private")}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "other",
                                "request_access_private",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "other",
                                  "request_access_private",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        {/* TREATMENT PLAN */}
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_systems" + "_other"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td
                            className="clickable"
                            onClick={() =>
                              !activeSubRows.includes(
                                role._id.toString() +
                                  "_systems" +
                                  "_other" +
                                  "_actionplan"
                              )
                                ? setActiveSubRows([
                                    ...activeSubRows,
                                    role._id.toString() +
                                      "_systems" +
                                      "_other" +
                                      "_actionplan",
                                  ])
                                : setActiveSubRows(
                                    activeSubRows.filter(
                                      (item) =>
                                        item !=
                                        role._id.toString() +
                                          "_systems" +
                                          "_other" +
                                          "_actionplan"
                                    )
                                  )
                            }
                          >
                            <i
                              className={
                                !activeSubRows.includes(
                                  role._id.toString() +
                                    "_systems" +
                                    "_other" +
                                    "_actionplan"
                                )
                                  ? "dripicons-chevron-down"
                                  : "dripicons-chevron-up"
                              }
                            />
                          </td>
                          <td
                            className="clickable"
                            onClick={() =>
                              !activeSubRows.includes(
                                role._id.toString() +
                                  "_systems" +
                                  "_other" +
                                  "_actionplan"
                              )
                                ? setActiveSubRows([
                                    ...activeSubRows,
                                    role._id.toString() +
                                      "_systems" +
                                      "_other" +
                                      "_actionplan",
                                  ])
                                : setActiveSubRows(
                                    activeSubRows.filter(
                                      (item) =>
                                        item !=
                                        role._id.toString() +
                                          "_systems" +
                                          "_other" +
                                          "_actionplan"
                                    )
                                  )
                            }
                          >
                            {t("accessControl.table.treatmentplan")}
                          </td>
                          <td>
                            {renderCheckboxField(
                              [
                                isSingleActionChecked(
                                  role._id,
                                  "system",
                                  "other",
                                  "show_treatment_plan",
                                  "allow"
                                ),
                                isSingleActionChecked(
                                  role._id,
                                  "system",
                                  "other",
                                  "sign_treatment_plan",
                                  "allow"
                                ),
                              ],
                              (evt) => {
                                handleChangeAllActions(
                                  role._id,
                                  evt,
                                  "system",
                                  "other",
                                  "w",
                                  "allow",
                                  ["show_treatment_plan", "sign_treatment_plan"]
                                );
                              },
                              true,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td
                            colSpan={6}
                            className="clickable"
                            onClick={() =>
                              !activeSubRows.includes(
                                role._id.toString() +
                                  "_systems" +
                                  "_other" +
                                  "_actionplan"
                              )
                                ? setActiveSubRows([
                                    ...activeSubRows,
                                    role._id.toString() +
                                      "_systems" +
                                      "_other" +
                                      "_actionplan",
                                  ])
                                : setActiveSubRows(
                                    activeSubRows.filter(
                                      (item) =>
                                        item !=
                                        role._id.toString() +
                                          "_systems" +
                                          "_other" +
                                          "_actionplan"
                                    )
                                  )
                            }
                          ></td>
                        </tr>
                        <tr
                          className={
                            "subfieldsrow subactionrow " +
                            (activeSubRows.includes(
                              role._id.toString() +
                                "_systems" +
                                "_other" +
                                "_actionplan"
                            ) &&
                            activeSubRows.includes(
                              role._id.toString() + "_systems" + "_other"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>{t("accessControl.table.show_treatmentplan")}</td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "other",
                                "show_treatment_plan",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "other",
                                  "show_treatment_plan",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "subfieldsrow subactionrow " +
                            (activeSubRows.includes(
                              role._id.toString() +
                                "_systems" +
                                "_other" +
                                "_actionplan"
                            ) &&
                            activeSubRows.includes(
                              role._id.toString() + "_systems" + "_other"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>{t("accessControl.table.sign_treatmentplan")}</td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "system",
                                "other",
                                "sign_treatment_plan",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "other",
                                  "sign_treatment_plan",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                      </React.Fragment>
                    </React.Fragment>
                  </tbody>
                  <tbody>
                    {/* Rows for object type: USERS */}
                    <tr className="mainRow">
                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_users")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_users",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_users"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(role._id.toString() + "_users")
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                      <td
                        colSpan={2}
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_users")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_users",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_users"
                                )
                              )
                        }
                      >
                        {t("accessControl.table.users.users")}
                      </td>
                      <td>
                        {" "}
                        {renderCheckboxField(
                          isAllChecked(role._id, "users", "r", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "r",
                              "allow",
                              "users"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>
                      <td>
                        {" "}
                        {renderCheckboxField(
                          isAllChecked(role._id, "users", "w", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "w",
                              "allow",
                              "users"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>

                      <td>
                        {" "}
                        {renderCheckboxField(
                          role.rules
                            .filter((rule) => rule.objType == "users")
                            .map((rule) =>
                              isSingleActionChecked(
                                role._id,
                                "users",
                                rule.objSubType,
                                "create",
                                "allow"
                              )
                            ),
                          (evt) =>
                            handleChangeAllActionsByName(
                              evt,
                              "create",
                              role._id,
                              "users"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>
                      <td>
                        {" "}
                        {renderCheckboxField(
                          role.rules
                            .filter((rule) => rule.objType == "users")
                            .map((rule) =>
                              isSingleActionChecked(
                                role._id,
                                "users",
                                rule.objSubType,
                                "delete",
                                "allow"
                              )
                            ),
                          (evt) =>
                            handleChangeAllActionsByName(
                              evt,
                              "delete",
                              role._id,
                              "users"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>

                      {/* <td className="noAccessTd"> {renderCheckboxField(isAllChecked(role.name, "system", "d", "denied"), (evt) => {handleChangeAll(role.name, evt, "d", ""); setActiveSubRows([])}, true, false, undefined, role)} </td> */}
                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_users")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_users",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_users"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(role._id.toString() + "_users")
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    className={
                      "subfields " +
                      (activeRow.includes(role._id.toString() + "_users")
                        ? ""
                        : "hidden")
                    }
                  >
                    {/* USERS */}
                    <React.Fragment>
                      <tr>
                        <td></td>
                        <td colSpan={2}>
                          {t("accessControl.table.users.users_users")}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "users",
                              "users",
                              "read",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "users",
                                "read",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "users",
                                "users",
                                "r",
                                "allow"
                              );
                            },
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "users",
                              "users",
                              "edit",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "users",
                                "edit",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "users",
                                "users",
                                "w",
                                "allow"
                              );
                            },
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "users",
                              "users",
                              "create",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "users",
                                "create",
                                "allow"
                              ),
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "users",
                              "users",
                              "delete",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "users",
                                "delete",
                                "allow"
                              ),
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>

                        <td></td>
                      </tr>
                    </React.Fragment>
                    {/* ACCESS CONTROL */}
                    <React.Fragment>
                      <tr>
                        <td></td>
                        <td colSpan={2}>
                          {t("accessControl.table.users.users_accesscontrol")}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "users",
                              "accesscontrol",
                              "read",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "accesscontrol",
                                "read",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "users",
                                "accesscontrol",
                                "r",
                                "allow"
                              );
                            },
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "users",
                              "accesscontrol",
                              "edit",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "accesscontrol",
                                "edit",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "users",
                                "accesscontrol",
                                "w",
                                "allow"
                              );
                            },
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "users",
                              "accesscontrol",
                              "create",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "accesscontrol",
                                "create",
                                "allow"
                              ),
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "users",
                              "accesscontrol",
                              "delete",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "accesscontrol",
                                "delete",
                                "allow"
                              ),
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>

                        <td></td>
                      </tr>
                    </React.Fragment>
                  </tbody>
                  <tbody>
                    {/* Rows for object type: TREATMENTS */}
                    <tr className="mainRow">
                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(
                            role._id.toString() + "_treatment"
                          )
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_treatment",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_treatment"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(
                              role._id.toString() + "_treatment"
                            )
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                      <td
                        colSpan={2}
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(
                            role._id.toString() + "_treatment"
                          )
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_treatment",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_treatment"
                                )
                              )
                        }
                      >
                        {t("accessControl.table.treatment")}
                      </td>
                      <td>
                        {" "}
                        {renderCheckboxField(
                          isAllChecked(role._id, "treatment", "r", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "r",
                              "allow",
                              "treatment"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>
                      <td>
                        {" "}
                        {renderCheckboxField(
                          isAllChecked(role._id, "treatment", "w", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "w",
                              "allow",
                              "treatment"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>

                      <td>
                        {" "}
                        {renderCheckboxField(
                          role.rules
                            .filter((rule) => rule.objType == "treatment")
                            .map((rule) =>
                              isSingleActionChecked(
                                role._id,
                                "treatment",
                                rule.objSubType,
                                "create",
                                "allow"
                              )
                            ),
                          (evt) =>
                            handleChangeAllActionsByName(
                              evt,
                              "create",
                              role._id,
                              "treatment"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>
                      <td>
                        {" "}
                        {renderCheckboxField(
                          role.rules
                            .filter((rule) => rule.objType == "treatment")
                            .map((rule) =>
                              isSingleActionChecked(
                                role._id,
                                "treatment",
                                rule.objSubType,
                                "delete",
                                "allow"
                              )
                            ),
                          (evt) =>
                            handleChangeAllActionsByName(
                              evt,
                              "delete",
                              role._id,
                              "treatment"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>

                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(
                            role._id.toString() + "_treatment"
                          )
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_treatment",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_treatment"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(
                              role._id.toString() + "_treatment"
                            )
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    className={
                      "subfields " +
                      (activeRow.includes(role._id.toString() + "_treatment")
                        ? ""
                        : "hidden")
                    }
                  >
                    {/* TREATMENTS */}
                    <React.Fragment>
                      <tr>
                        <td
                          className="clickable"
                          onClick={() =>
                            isSingleChecked(
                              role._id,
                              "treatment",
                              "d",
                              "treatment"
                            )
                              ? null
                              : !activeSubRows.includes(
                                  role._id.toString() +
                                    "_treatment" +
                                    "_treatment"
                                )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() +
                                    "_treatment" +
                                    "_treatment",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_treatment" +
                                        "_treatment"
                                  )
                                )
                          }
                        >
                          <i
                            className={
                              !activeSubRows.includes(
                                role._id.toString() +
                                  "_treatment" +
                                  "_treatment"
                              )
                                ? "dripicons-chevron-down"
                                : "dripicons-chevron-up"
                            }
                          />
                        </td>
                        <td
                          colSpan={2}
                          className="clickable"
                          onClick={() =>
                            isSingleChecked(
                              role._id,
                              "treatment",
                              "d",
                              "treatment"
                            )
                              ? null
                              : !activeSubRows.includes(
                                  role._id.toString() +
                                    "_treatment" +
                                    "_treatment"
                                )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() +
                                    "_treatment" +
                                    "_treatment",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_treatment" +
                                        "_treatment"
                                  )
                                )
                          }
                        >
                          {t("accessControl.table.treatment")}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "treatment",
                              "r",
                              "treatment",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "treatment",
                                "read",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "treatment",
                                "treatment",
                                "r",
                                "allow"
                              );
                            },
                            true,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "treatment",
                              "w",
                              "treatment",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "treatment",
                                "edit",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "treatment",
                                "treatment",
                                "w",
                                "allow"
                              );
                            },
                            true,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "treatment",
                              "treatment",
                              "create",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "treatment",
                                "create",
                                "allow"
                              ),
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "treatment",
                              "treatment",
                              "delete",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "treatment",
                                "delete",
                                "allow"
                              ),
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>

                        <td
                          className="clickable"
                          onClick={() =>
                            !activeSubRows.includes(
                              role._id.toString() + "_treatment" + "_treatment"
                            )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() +
                                    "_treatment" +
                                    "_treatment",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_treatment" +
                                        "_treatment"
                                  )
                                )
                          }
                        >
                          <i
                            className={
                              !activeSubRows.includes(
                                role._id.toString() +
                                  "_treatment" +
                                  "_treatment"
                              )
                                ? "dripicons-chevron-down"
                                : "dripicons-chevron-up"
                            }
                          />
                        </td>
                      </tr>
                      <React.Fragment>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_treatment" + "_treatment"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t("accessControl.table.treatment_without_owner")}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "treatment",
                                "treatment",
                                "edit_treatment_without_owner",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "treatment",
                                  "edit_treatment_without_owner",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_treatment" + "_treatment"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t(
                              "accessControl.table.edit_treatment_owner_and_sharing"
                            )}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "treatment",
                                "treatment",
                                "edit_treatment_owner_and_sharing",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "treatment",
                                  "edit_treatment_owner_and_sharing",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                      </React.Fragment>
                    </React.Fragment>
                  </tbody>

                  <tbody>
                    {/* Rows for object type: TASKS */}
                    <tr className="mainRow">
                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_tasks")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_tasks",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_tasks"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(role._id.toString() + "_tasks")
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                      <td
                        colSpan={2}
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_tasks")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_tasks",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_tasks"
                                )
                              )
                        }
                      >
                        {t("accessControl.table.tasks.tasks")}
                      </td>
                      <td>
                        {renderCheckboxField(
                          isAllChecked(role._id, "tasks", "r", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "r",
                              "allow",
                              "tasks"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>
                      <td>
                        {renderCheckboxField(
                          isAllChecked(role._id, "tasks", "w", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "w",
                              "allow",
                              "tasks"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>

                      <td>
                        {" "}
                        {renderCheckboxField(
                          role.rules
                            .filter((rule) => rule.objType == "tasks")
                            .map((rule) =>
                              isSingleActionChecked(
                                role._id,
                                "tasks",
                                rule.objSubType,
                                "create",
                                "allow"
                              )
                            ),
                          (evt) =>
                            handleChangeAllActionsByName(
                              evt,
                              "create",
                              role._id,
                              "tasks"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>
                      <td>
                        {" "}
                        {renderCheckboxField(
                          role.rules
                            .filter((rule) => rule.objType == "tasks")
                            .map((rule) =>
                              isSingleActionChecked(
                                role._id,
                                "tasks",
                                rule.objSubType,
                                "delete",
                                "allow"
                              )
                            ),
                          (evt) =>
                            handleChangeAllActionsByName(
                              evt,
                              "delete",
                              role._id,
                              "tasks"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>

                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_tasks")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_tasks",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_tasks"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(role._id.toString() + "_tasks")
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    className={
                      "subfields " +
                      (activeRow.includes(role._id.toString() + "_tasks")
                        ? ""
                        : "hidden")
                    }
                  >
                    {/* TASKS */}
                    <React.Fragment>
                      <tr>
                        <td></td>
                        <td colSpan={2}>
                          {t("accessControl.table.tasks.tasks")}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "tasks",
                              "r",
                              "tasks",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "tasks",
                                "read",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "tasks",
                                "tasks",
                                "r",
                                "allow"
                              );
                            },
                            true,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "tasks",
                              "w",
                              "tasks",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "tasks",
                                "edit",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "tasks",
                                "tasks",
                                "w",
                                "allow"
                              );
                            },
                            true,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "tasks",
                              "tasks",
                              "create",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "tasks",
                                "create",
                                "allow"
                              ),
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "tasks",
                              "tasks",
                              "delete",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "tasks",
                                "delete",
                                "allow"
                              ),
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>

                        <td></td>
                      </tr>
                    </React.Fragment>
                  </tbody>

                  <tbody>
                    {/* Rows for object type: ORGANIZATION */}
                    <tr className="mainRow">
                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(
                            role._id.toString() + "_organization"
                          )
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_organization",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item !=
                                    role._id.toString() + "_organization"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(
                              role._id.toString() + "_organization"
                            )
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                      <td
                        colSpan={2}
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(
                            role._id.toString() + "_organization"
                          )
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_organization",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item !=
                                    role._id.toString() + "_organization"
                                )
                              )
                        }
                      >
                        {t("accessControl.table.organization.organization")}
                      </td>
                      <td>
                        {renderCheckboxField(
                          isAllChecked(role._id, "organization", "r", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "r",
                              "allow",
                              "organization"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>
                      <td>
                        {renderCheckboxField(
                          isAllChecked(role._id, "organization", "w", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "w",
                              "allow",
                              "organization"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>

                      <td>
                        {" "}
                        {renderCheckboxField(
                          role.rules
                            .filter((rule) => rule.objType == "organization")
                            .map((rule) =>
                              isSingleActionChecked(
                                role._id,
                                "organization",
                                rule.objSubType,
                                "create",
                                "allow"
                              )
                            ),
                          (evt) =>
                            handleChangeAllActionsByName(
                              evt,
                              "create",
                              role._id,
                              "organization"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>
                      <td>
                        {" "}
                        {renderCheckboxField(
                          role.rules
                            .filter((rule) => rule.objType == "organization")
                            .map((rule) =>
                              isSingleActionChecked(
                                role._id,
                                "organization",
                                rule.objSubType,
                                "delete",
                                "allow"
                              )
                            ),
                          (evt) =>
                            handleChangeAllActionsByName(
                              evt,
                              "delete",
                              role._id,
                              "organization"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>

                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(
                            role._id.toString() + "_organization"
                          )
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_organization",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item !=
                                    role._id.toString() + "_organization"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(
                              role._id.toString() + "_organization"
                            )
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    className={
                      "subfields " +
                      (activeRow.includes(role._id.toString() + "_organization")
                        ? ""
                        : "hidden")
                    }
                  >
                    {/* ORGANIZATION */}
                    <React.Fragment>
                      <tr>
                        <td
                          className="clickable"
                          onClick={() =>
                            isSingleChecked(
                              role._id,
                              "organization",
                              "d",
                              "organization"
                            )
                              ? null
                              : !activeSubRows.includes(
                                  role._id.toString() +
                                    "_organization" +
                                    "_organization"
                                )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() +
                                    "_organization" +
                                    "_organization",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_organization" +
                                        "_organization"
                                  )
                                )
                          }
                        >
                          {" "}
                          <i
                            className={
                              !activeSubRows.includes(
                                role._id.toString() +
                                  "_organization" +
                                  "_organization"
                              )
                                ? "dripicons-chevron-down"
                                : "dripicons-chevron-up"
                            }
                          />
                        </td>
                        <td
                          colSpan={2}
                          className="clickable"
                          onClick={() =>
                            isSingleChecked(
                              role._id,
                              "organization",
                              "d",
                              "organization"
                            )
                              ? null
                              : !activeSubRows.includes(
                                  role._id.toString() +
                                    "_organization" +
                                    "_organization"
                                )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() +
                                    "_organization" +
                                    "_organization",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_organization" +
                                        "_organization"
                                  )
                                )
                          }
                        >
                          {t("accessControl.table.organization.organization")}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "organization",
                              "r",
                              "organization",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "organization",
                                "read",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "organization",
                                "organization",
                                "r",
                                "allow"
                              );
                            },
                            true,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "organization",
                              "w",
                              "organization",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "organization",
                                "edit",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "organization",
                                "organization",
                                "w",
                                "allow"
                              );
                            },
                            true,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "organization",
                              "organization",
                              "create",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "organization",
                                "create",
                                "allow"
                              ),
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "organization",
                              "organization",
                              "delete",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "organization",
                                "delete",
                                "allow"
                              ),
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td
                          className="clickable"
                          onClick={() =>
                            !activeRow.includes(
                              role._id.toString() +
                                "_organization" +
                                "_organization"
                            )
                              ? setActiveRow([
                                  ...activeRow,
                                  role._id.toString() +
                                    "_organization" +
                                    "_organization",
                                ])
                              : setActiveRow(
                                  activeRow.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_organization" +
                                        "_organization"
                                  )
                                )
                          }
                        >
                          <i
                            className={
                              !activeSubRows.includes(
                                role._id.toString() +
                                  "_organization" +
                                  "_organization"
                              )
                                ? "dripicons-chevron-down"
                                : "dripicons-chevron-up"
                            }
                          />
                        </td>
                      </tr>
                      <React.Fragment>
                        {/* <tr className={"groupedActionsParent subfieldsrow " + (activeSubRows.includes(role.name + "_organization" + "_organization") ? "" : "hidden")}>
                                                <td></td>
                                                <td>{t("accessControl.table.organization.invite_users_to_organization")}</td>
                                                <td>{renderCheckboxField(isSingleActionChecked(role._id, "organization", "organization", "invite_users_to_organization", "allow"), (evt) => handleChangeSingleAction(evt, role._id, "organization", "invite_users_to_organization", "allow"), false, false, t("accessControl.table.allow"), role)}</td>
                                                <td colSpan={6}></td>
                                            </tr> */}
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() +
                                "_organization" +
                                "_organization"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t(
                              "accessControl.table.organization.generate_api_keys"
                            )}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "organization",
                                "organization",
                                "generate_api_keys",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "organization",
                                  "generate_api_keys",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() +
                                "_organization" +
                                "_organization"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t(
                              "accessControl.table.organization.invite_azure_ad_tenants_to_organization"
                            )}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "organization",
                                "organization",
                                "invite_azure_ad_tenants_to_organization",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "organization",
                                  "invite_azure_ad_tenants_to_organization",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() +
                                "_organization" +
                                "_organization"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t(
                              "accessControl.table.organization.view_as_different_organization"
                            )}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "organization",
                                "organization",
                                "view_as_different_organization",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "organization",
                                  "view_as_different_organization",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() +
                                "_organization" +
                                "_organization"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t(
                              "accessControl.table.organization.change_own_organization_permanently"
                            )}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "organization",
                                "organization",
                                "change_own_organization_permanently",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "organization",
                                  "change_own_organization_permanently",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() +
                                "_organization" +
                                "_organization"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t(
                              "accessControl.table.organization.handle_payment"
                            )}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "organization",
                                "organization",
                                "handle_payment",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "organization",
                                  "handle_payment",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                      </React.Fragment>
                    </React.Fragment>
                  </tbody>
                  <tbody>
                    {/* Rows for object type: SURVEY */}
                    <tr className="mainRow">
                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_survey")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_survey",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_survey"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(role._id.toString() + "_survey")
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                      <td
                        colSpan={2}
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_survey")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_survey",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_survey"
                                )
                              )
                        }
                      >
                        {t("accessControl.table.survey.survey")}
                      </td>
                      <td>
                        {renderCheckboxField(
                          isAllChecked(role._id, "survey", "r", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "r",
                              "allow",
                              "survey"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>
                      <td>
                        {renderCheckboxField(
                          isAllChecked(role._id, "survey", "w", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "w",
                              "allow",
                              "survey"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>

                      <td>
                        {" "}
                        {renderCheckboxField(
                          role.rules
                            .filter((rule) => rule.objType == "survey")
                            .map((rule) =>
                              isSingleActionChecked(
                                role._id,
                                "survey",
                                rule.objSubType,
                                "create",
                                "allow"
                              )
                            ),
                          (evt) =>
                            handleChangeAllActionsByName(
                              evt,
                              "create",
                              role._id,
                              "survey"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>
                      <td>
                        {" "}
                        {renderCheckboxField(
                          role.rules
                            .filter((rule) => rule.objType == "survey")
                            .map((rule) =>
                              isSingleActionChecked(
                                role._id,
                                "survey",
                                rule.objSubType,
                                "delete",
                                "allow"
                              )
                            ),
                          (evt) =>
                            handleChangeAllActionsByName(
                              evt,
                              "delete",
                              role._id,
                              "survey"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>

                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_survey")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_survey",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_survey"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(role._id.toString() + "_survey")
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    className={
                      "subfields " +
                      (activeRow.includes(role._id.toString() + "_survey")
                        ? ""
                        : "hidden")
                    }
                  >
                    {/* SURVEY */}
                    <React.Fragment>
                      <tr>
                        <td
                          className="clickable"
                          onClick={() =>
                            isSingleChecked(role._id, "survey", "d", "survey")
                              ? null
                              : !activeSubRows.includes(
                                  role._id.toString() + "_survey" + "_survey"
                                )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() + "_survey" + "_survey",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_survey" +
                                        "_survey"
                                  )
                                )
                          }
                        >
                          <i
                            className={
                              !activeSubRows.includes(
                                role._id.toString() + "_survey" + "_survey"
                              )
                                ? "dripicons-chevron-down"
                                : "dripicons-chevron-up"
                            }
                          />
                        </td>
                        <td
                          colSpan={2}
                          className="clickable"
                          onClick={() =>
                            isSingleChecked(role._id, "survey", "d", "survey")
                              ? null
                              : !activeSubRows.includes(
                                  role._id.toString() + "_survey" + "_survey"
                                )
                              ? setActiveSubRows([
                                  ...activeSubRows,
                                  role._id.toString() + "_survey" + "_survey",
                                ])
                              : setActiveSubRows(
                                  activeSubRows.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_survey" +
                                        "_survey"
                                  )
                                )
                          }
                        >
                          {t("accessControl.table.survey.survey")}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "survey",
                              "r",
                              "survey",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "survey",
                                "read",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "survey",
                                "survey",
                                "r",
                                "allow"
                              );
                            },
                            true,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "survey",
                              "w",
                              "survey",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "survey",
                                "edit",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "survey",
                                "survey",
                                "w",
                                "allow"
                              );
                            },
                            true,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "survey",
                              "survey",
                              "create",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "survey",
                                "create",
                                "allow"
                              ),
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isSingleActionChecked(
                              role._id,
                              "survey",
                              "survey",
                              "delete",
                              "allow"
                            ),
                            (evt) =>
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "survey",
                                "delete",
                                "allow"
                              ),
                            false,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td
                          className="clickable"
                          onClick={() =>
                            !activeRow.includes(
                              role._id.toString() + "_survey" + "_survey"
                            )
                              ? setActiveRow([
                                  ...activeRow,
                                  role._id.toString() + "_survey" + "_survey",
                                ])
                              : setActiveRow(
                                  activeRow.filter(
                                    (item) =>
                                      item !=
                                      role._id.toString() +
                                        "_survey" +
                                        "_survey"
                                  )
                                )
                          }
                        >
                          <i
                            className={
                              !activeSubRows.includes(
                                role._id.toString() + "_survey" + "_survey"
                              )
                                ? "dripicons-chevron-down"
                                : "dripicons-chevron-up"
                            }
                          />
                        </td>
                      </tr>
                      <React.Fragment>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_survey" + "_survey"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t(
                              "accessControl.table.survey.edit_ownership_and_sharing"
                            )}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "survey",
                                "survey",
                                "edit_ownership_and_sharing",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "survey",
                                  "edit_ownership_and_sharing",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                        <tr
                          className={
                            "groupedActionsParent subfieldsrow " +
                            (activeSubRows.includes(
                              role._id.toString() + "_survey" + "_survey"
                            )
                              ? ""
                              : "hidden")
                          }
                        >
                          <td></td>
                          <td>
                            {t("accessControl.table.survey.answer_survey")}
                          </td>
                          <td>
                            {renderCheckboxField(
                              isSingleActionChecked(
                                role._id,
                                "survey",
                                "survey",
                                "answer_survey",
                                "allow"
                              ),
                              (evt) =>
                                handleChangeSingleAction(
                                  evt,
                                  role._id,
                                  "survey",
                                  "answer_survey",
                                  "allow"
                                ),
                              false,
                              false,
                              t("accessControl.table.allow"),
                              role
                            )}
                          </td>
                          <td colSpan={6}></td>
                        </tr>
                      </React.Fragment>
                    </React.Fragment>
                  </tbody>

                  <tbody>
                    {/* Rows for object type: SETTINGS */}
                    <tr className="mainRow">
                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_settings")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_settings",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_settings"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(
                              role._id.toString() + "_settings"
                            )
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                      <td
                        colSpan={2}
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_settings")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_settings",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_settings"
                                )
                              )
                        }
                      >
                        {t("accessControl.table.settings.settings")}
                      </td>
                      <td>
                        {renderCheckboxField(
                          isAllChecked(role._id, "settings", "r", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "r",
                              "allow",
                              "settings"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>
                      <td>
                        {renderCheckboxField(
                          isAllChecked(role._id, "settings", "w", "allow"),
                          (evt) =>
                            handleChangeAll(
                              role._id,
                              evt,
                              "w",
                              "allow",
                              "settings"
                            ),
                          true,
                          undefined,
                          undefined,
                          role
                        )}
                      </td>

                      <td>
                        {" "}
                        {/* {
                                            renderCheckboxField(
                                                (role.rules.filter(rule => rule.objType == "settings").map(rule => isSingleActionChecked(role._id, "settings", rule.objSubType, "create", "allow"))), 
                                                (evt) => handleChangeAllActionsByName(evt, "create", role._id, "settings"), 
                                                true, 
                                                undefined, 
                                                undefined,
                                                role
                                            )
                                        } */}
                      </td>
                      <td>
                        {" "}
                        {/* {
                                            renderCheckboxField(
                                                (role.rules.filter(rule => rule.objType == "settings").map(rule => isSingleActionChecked(role._id, "settings", rule.objSubType, "delete", "allow"))), 
                                                (evt) => handleChangeAllActionsByName(evt, "delete", role._id, "settings"), 
                                                true, 
                                                undefined, 
                                                undefined, 
                                                role
                                            )
                                        } */}
                      </td>

                      <td
                        className="clickable"
                        onClick={() =>
                          !activeRow.includes(role._id.toString() + "_settings")
                            ? setActiveRow([
                                ...activeRow,
                                role._id.toString() + "_t_settingsasks",
                              ])
                            : setActiveRow(
                                activeRow.filter(
                                  (item) =>
                                    item != role._id.toString() + "_settings"
                                )
                              )
                        }
                      >
                        <i
                          className={
                            !activeRow.includes(
                              role._id.toString() + "_settings"
                            )
                              ? "dripicons-chevron-down"
                              : "dripicons-chevron-up"
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    className={
                      "subfields " +
                      (activeRow.includes(role._id.toString() + "_settings")
                        ? ""
                        : "hidden")
                    }
                  >
                    {/* SETTINGS */}
                    <React.Fragment>
                      <tr>
                        <td></td>
                        <td colSpan={2}>
                          {t("accessControl.table.settings.settings")}
                        </td>

                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "settings",
                              "r",
                              "settings",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "settings",
                                "read",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "settings",
                                "settings",
                                "r",
                                "allow"
                              );
                            },
                            true,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>
                        <td>
                          {renderCheckboxField(
                            isAllActionsChecked(
                              role._id,
                              "settings",
                              "w",
                              "settings",
                              "allow"
                            ),
                            (evt) => {
                              handleChangeSingleAction(
                                evt,
                                role._id,
                                "settings",
                                "edit",
                                "allow"
                              );
                              handleChangeAllActions(
                                role._id,
                                evt,
                                "settings",
                                "settings",
                                "w",
                                "allow"
                              );
                            },
                            true,
                            undefined,
                            undefined,
                            role
                          )}
                        </td>

                        <td>
                          {/* {renderCheckboxField(isSingleActionChecked(role._id, "settings", "settings", "create", "allow"), (evt) => handleChangeSingleAction(evt, role._id, "settings", "create", "allow"), false, undefined, undefined, role)} */}
                        </td>
                        <td>
                          {/* {renderCheckboxField(isSingleActionChecked(role._id, "settings", "settings", "delete", "allow"), (evt) => handleChangeSingleAction(evt, role._id, "settings", "delete", "allow"), false, undefined, undefined, role)} */}
                        </td>
                        <td></td>
                      </tr>
                    </React.Fragment>
                  </tbody>
                </table>
              </div>
            </Accordion.Collapse>
          </div>
        ))}
      </Accordion>
    );
  }

  return (
    <div className="accessControl">
      <Card>
        <Card.Body>
          <Button
            disabled={
              auth.user["userdata"]["actualAccessRole"]["rules"].find(
                (rule) =>
                  rule.objType == "users" && rule.objSubType == "accesscontrol"
              ).actions.create != "allow"
            }
            onClick={() => setShowNewRoleModal(true)}
          >
            {t("accessControl.buttons.create_new_role")}
          </Button>
          <br />
          <br />
          <p>
            {t("accessControl.information.current_org_text") +
              " " +
              (props?.orgs?.find(
                (org) => org?._id == auth.user["userdata"]["unit"][0]
              )?.name ?? "")}
          </p>
          <hr />
          {renderRoles()}
          <hr />
          <Button
            disabled={JSON.stringify(checked) == JSON.stringify(roles)}
            className={"cancel"}
            onClick={() => setChecked(roles)}
          >
            {t("accessControl.buttons.reset")}
          </Button>
          <Button
            disabled={
              auth.user["userdata"]["actualAccessRole"]["rules"].find(
                (rule) =>
                  rule.objType == "users" && rule.objSubType == "accesscontrol"
              ).actions.edit != "allow" ||
              roles.filter(
                (role) => role.orgId == auth.user["userdata"]["unit"][0]
              ).length == 0
            }
            className="saveRoleAccessesBtn"
            onClick={() => {
              setShowConfirmSaveModal(true);
            }}
          >
            {t("accessControl.buttons.save_changes")}
          </Button>
        </Card.Body>
      </Card>
      <Modal
        className="accessControlModal"
        show={showNewRoleModal}
        onHide={() => handleCloseNewRoleModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("accessControl.modals.create_new_role")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Field
            type="textlanguage"
            title={t("accessControl.modals.role_name")}
            value={newRoleName}
            onChange={(val) => setNewRoleName(val)}
            disabled={false}
            options={{
              category: "accesscontrol.role.name",
            }}
          ></Field>
          <br />
          <br />
          <Field
            type="textarea"
            title={t("accessControl.modals.role_description")}
            value={newRoleDescription}
            onChange={(val) => setNewRoleDescription(val)}
            disabled={false}
          ></Field>
          <br />

          <p>{t("accessControl.modals.org_inherit_disclaimer01")}</p>
          <p>{t("accessControl.modals.org_inherit_disclaimer02")}</p>
          <div className="widecheckbox">
            <Field
              type="checkbox"
              title={t("accessControl.modals.org_inherit_confirm")}
              value={newRoleOrgInherit}
              onChange={(val) => setNewRoleOrgInherit(val)}
              disabled={false}
            ></Field>
          </div>

          <p>{t("accessControl.modals.default_new_users_disclaimer01")}</p>
          <div className="widecheckbox">
            <Field
              type="checkbox"
              title={t("accessControl.modals.default_new_users_confirm")}
              value={newDefaultNewUsersRole}
              onChange={(val) => setNewDefaultNewUsersRole(val)}
              disabled={false}
            ></Field>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={() => handleCloseNewRoleModal()}
          >
            {t("accessControl.buttons.cancel")}
          </Button>
          <Button
            onClick={() => {
              handleCreateAccessRole();
            }}
          >
            {t("accessControl.buttons.create")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="accessControlModal"
        show={showEditRoleModal}
        onHide={() => handleCloseEditRoleModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("accessControl.modals.edit_role")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Field
            type="textlanguage"
            title={t("accessControl.modals.role_name")}
            value={editRoleName}
            onChange={(val) => setEditRoleName(val)}
            disabled={false}
            options={{
              category: "accesscontrol.role.name",
            }}
          ></Field>
          <br />
          <br />
          <Field
            type="textarea"
            title={t("accessControl.modals.role_description")}
            value={editRoleDescription}
            onChange={(val) => setEditRoleDescription(val)}
            disabled={!editRole?.accesses?.includes("edit") ?? true}
          ></Field>
          <br />
          <p>{t("accessControl.modals.org_inherit_disclaimer01")}</p>
          <p>{t("accessControl.modals.org_inherit_disclaimer02")}</p>
          <div className="widecheckbox">
            <Field
              type="checkbox"
              title={t("accessControl.modals.org_inherit_confirm")}
              value={editRoleOrgInherit}
              onChange={(val) => setEditRoleOrgInherit(val)}
              disabled={!editRole?.accesses?.includes("edit") ?? true}
            ></Field>
          </div>

          <p>{t("accessControl.modals.default_new_users_disclaimer01")}</p>
          <div className="widecheckbox">
            <Field
              type="checkbox"
              title={t("accessControl.modals.default_new_users_confirm")}
              value={editDefaultNewUsersRole}
              onChange={(val) => setEditDefaultNewUsersRole(val)}
              disabled={!editRole?.accesses?.includes("edit") ?? true}
            ></Field>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={() => handleCloseEditRoleModal()}
          >
            {t("accessControl.buttons.cancel")}
          </Button>

          <Button
            disabled={!editRole?.accesses?.includes("edit") ?? true}
            onClick={() => {
              handleEditRole();
            }}
          >
            {t("accessControl.buttons.save")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteRoleModal} onHide={() => handleCloseDeleteModal()}>
        <Modal.Header closeButton>
          <Modal.Title>{t("accessControl.modals.delete_role")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("accessControl.modals.delete_role_disclaimer01")} "
            {td(deleteRole?.name ?? "")}"?
          </p>
          <p>{t("accessControl.modals.delete_role_disclaimer02")}</p>

          <Field
            type="checkbox"
            title={t("accessControl.modals.delete_role_confirm")}
            value={deleteRoleApproved}
            onChange={(val) => setDeleteRoleApproved(val)}
            disabled={false}
          ></Field>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={() => handleCloseDeleteModal()}
          >
            {t("accessControl.buttons.cancel")}
          </Button>
          <Button
            disabled={
              !deleteRoleApproved ||
              (!deleteRole?.accesses?.includes("delete") ?? true)
            }
            onClick={() => {
              handleDeleteRole();
            }}
          >
            {t("accessControl.buttons.delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showConfirmSaveModal}
        onHide={() => handleCloseConfirmSaveModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("accessControl.modals.confirm_save")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("accessControl.modals.confirm_save_disclaimer01")}</p>
          <p>{t("accessControl.modals.confirm_save_disclaimer02")}</p>
          <div className="widecheckbox">
            <Field
              type="checkbox"
              title={t("accessControl.modals.confirm_save_confirm")}
              value={saveRolesConfirmed}
              onChange={(val) => setSaveRolesConfirmed(val)}
              disabled={false}
            ></Field>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel posleft"
            onClick={() => handleCloseConfirmSaveModal()}
          >
            {t("accessControl.buttons.cancel")}
          </Button>
          <Button
            disabled={!saveRolesConfirmed}
            onClick={() => {
              handleSaveAccessRoles();
            }}
          >
            {t("accessControl.buttons.save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AccessControl;
