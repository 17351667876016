import * as React from "react";

import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { toast } from "react-toastify";

import { useAuth } from "../../../../Auth";
import { useAxios } from "../../../../AxiosHandler";

import { generateUrl } from "../../../../config";

import "react-toastify/dist/ReactToastify.css";

function TreatmentDeleteModal(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();

  const [deleteTreatment, setDeleteTreatment] = useState<any>();

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  let userdata = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
    userdata = JSON.parse(localStorageUser)["userdata"];
  }

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const handleDeleteTreatment = () => {
    axios
      .delete(
        generateUrl("/api/cra/treatments/") + deleteTreatment["_id"],
        {
          name: deleteTreatment["name"],
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        if (props.onSave != undefined) props.onSave();
        if (props.onChange != undefined) {
          props.onChange(deleteTreatment["_id"]);
        }
        props.onHide();
        notifySuccess(t("bowtie.toast_treatment_deleted"));
      });
  };

  const handleEditTreatment = () => {
    if (props.parentItemType == "cause") {
      axios
        .put(
          generateUrl("/api/cra/treatments/") + deleteTreatment["_id"],
          {
            name: deleteTreatment["name"],
            cause: deleteTreatment["cause"].filter(
              (i) => i != props.parentItemId
            ),
            removedFromCause: props.parentItemId,
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then((res) => {
          if (props.onSave != undefined) props.onSave();
          notifySuccess(t("treatments.toast.treatment_edited"));
          if (props.onChange != undefined) {
            props.onChange(deleteTreatment["_id"]);
          }
        });
    } else if (props.parentItemType == "consequence") {
      axios
        .put(
          generateUrl("/api/cra/treatments/") + deleteTreatment["_id"],
          {
            name: deleteTreatment["name"],
            consequence: deleteTreatment["consequence"].filter(
              (i) => i != props.parentItemId
            ),
            removedFromConsequence: props.parentItemId,
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then((res) => {
          if (props.onSave != undefined) props.onSave();
          notifySuccess(t("treatments.toast.treatment_edited"));
          if (props.onChange != undefined) {
            props.onChange(deleteTreatment["_id"]);
          }
        });
    }
  };

  function handleTreatment() {
    if (props.fromBowtie == true) {
      handleEditTreatment();
    } else {
      handleDeleteTreatment();
    }
  }

  const fetchTreatment = () => {
    if (props.treatmentId != undefined) {
      axios
        .all([
          axios.single({
            method: "get",
            url: generateUrl("/api/cra/treatments/") + props.treatmentId,
            responseType: "json",
            headers: { "x-access-token": auth.user["token"] },
          }),
        ])
        .then(
          axios.spread((...res) => {
            if (res[0].data != undefined) {
              let treatment = res[0].data;
              treatment["parents"] = [];
              treatment["cause"].forEach((element) => {
                treatment["parents"].push(element);
              });
              treatment["consequence"].forEach((element) => {
                treatment["parents"].push(element);
              });
              setDeleteTreatment(treatment);
            }
          })
        )
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    fetchTreatment();
  }, []);

  useEffect(() => {
    if (props.show == true) {
      fetchTreatment();
    }
  }, [props.show]);

  return (
    <div>
      {deleteTreatment != undefined ? (
        <Modal
          show={props.show}
          onHide={() => {
            if (props.onHide != undefined) props.onHide();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t("treatments.modal_treatment_delete.title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{deleteTreatment["name"]}</p>
            {props.fromBowtie
              ? t("treatments.modal_treatment_delete.remove_from_bowtie")
              : t("treatments.modal_treatment_delete.info_singleparent")}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel posleft"
              onClick={() => {
                if (props.onCancel != undefined) props.onCancel();
              }}
            >
              {t("treatments.modal_treatment_create.cancelbtn")}
            </Button>
            <Button onClick={handleTreatment}>
              {props.fromBowtie
                ? t("treatments.modal_treatment_delete.removebtn")
                : t("treatments.modal_treatment_delete.deletebtn")}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
}

export default TreatmentDeleteModal;
