import React, { useState, useEffect, useContext, createContext } from "react";
import jwt from 'jwt-decode';
import { config, generateUrl } from "./config";
import { getUserDetails } from './graphService';

import { UserAgentApplication } from 'msal';
import { useAxios } from './AxiosHandler';
import { useAuth } from "./Auth";

import useLocale from "./LocaleHandler";

const authMsalContext = createContext({
	user: {},
	isAuthenticated: false,
	login: () => {},
	logout: () => {},
	deleteAllCookies: () => {},
	checkMsalAuth: ():Promise<boolean> => { return new Promise<boolean>(() => {}); },
});

export function ProvideMsalAuth({ children }) {
	const auth = useProvideMsalAuth();
	return <authMsalContext.Provider value={auth}>{children}</authMsalContext.Provider>;
}

export const useMsalAuth = () => {
  	return useContext(authMsalContext);
};

function useProvideMsalAuth() {
	const auth = useAuth();
    const axiosHandler = useAxios();
	const axios = axiosHandler.axios;
	const locale = useContext(useLocale);
	
	const [userAgentApplication, setUserAgentApplication] = useState(({} as any));
	const [isAuthenticated, setAuthenticated] = useState(false);
	const [user, setUser] = useState({});

	function deleteAllCookies() {
		var cookies = document.cookie.split(";");
	
		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i];
			var eqPos = cookie.indexOf("=");
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}
	}

	async function checkMsalAuth(): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			if (sessionStorage["msal.idtoken"] != undefined) {
				let userObject = {
					id: "",
					auth: "",
					token: "",
					userdata: {},
					settings: {}
				}

				doMsalServerLogin(userObject).then(async (result) => {
					if (result) {
						resolve(true);
					} else {
						resolve(false);
					}
				});
			} else {
				resolve(false);
			}
		});
	}

	async function doMsalServerLogin(userObject) {
		return new Promise(async (resolve, reject) => {
			await axios.post(generateUrl("/api/loginMsal/loginMsal"),
				{},
				{
					headers: {'Authorization': "Bearer " + sessionStorage["msal.idtoken"]}
				}
			)
			.then(function (response) {
				userObject = {
					id: response.data.email,
					auth: response.data.auth,
					token: response.data.token,
					userdata: response.data.userdata,
					settings: response.data.settings
				};
				localStorage.setItem('user', JSON.stringify(userObject));
				
				auth.setUser(() => userObject);
				setAuthenticated(true);
				locale.setLocale(userObject.userdata.language);
				resolve(true);
			}).catch(function (error) {
				localStorage.removeItem('user');
				sessionStorage.removeItem('msal.idtoken');
				setAuthenticated(false);
				resolve(false);
			});
			
			resolve(false);
		});
	}

	async function login() {
		sessionStorage.clear();
		localStorage.clear();
		deleteAllCookies();

		// Login via redirect
		await userAgentApplication.loginRedirect({
			scopes: config.scopes,
			prompt: "select_account"
		});
	}

	const logout = () => {
		userAgentApplication.logout();
	}

	const getAccessToken = async (scopes: string[]): Promise<string> => {
		try {
			// Get the access token silently
			// If the cache contains a non-expired token, this function
			// will just return the cached token. Otherwise, it will
			// make a request to the Azure OAuth endpoint to get a token
			var silentResult = await userAgentApplication.acquireTokenSilent({
				scopes: scopes
			});
	
			return silentResult.accessToken;
		} catch (err) {
			// If a silent request fails, it may be because the user needs
			// to login or grant consent to one or more of the requested scopes
			if (isInteractionRequired(err as any)) {
				var interactiveResult = await userAgentApplication.acquireTokenPopup({
					scopes: scopes
				});
	
				return interactiveResult.accessToken;
			} else {
				throw err;
			}
		}
	}

	const getUserProfile = async () => {
		try {
			var accessToken = await getAccessToken(config.scopes);
	
			if (accessToken) {
				// Get the user's profile from Graph
				var user = await getUserDetails(accessToken);
				setAuthenticated(true);
				setUser({
					displayName: user.displayName,
					email: user.mail || user.userPrincipalName
				});
				return user;
			}
		}
		catch(err) {
			setAuthenticated(false);
			return undefined;
		}
	}
	
	function isInteractionRequired(error: Error): boolean {
		if (!error.message || error.message.length <= 0) {
			return false;
		}

		return (
			error.message.indexOf('consent_required') > -1 ||
			error.message.indexOf('interaction_required') > -1 ||
			error.message.indexOf('login_required') > -1
		);
	}

    useEffect(() => {
        let userAgentApplication = new UserAgentApplication({
			auth: {
				clientId: config.appId,
				redirectUri: config.redirectUri
			},
			cache: {
				cacheLocation: "sessionStorage",
				storeAuthStateInCookie: false
			}
		});
		setUserAgentApplication(userAgentApplication);

		var account = userAgentApplication.getAccount();

		if (account) {
			// Enhance user object with data from Graph
			getUserProfile();
		}
    }, []);

	return {
		user,
		isAuthenticated,
		login,
		logout,
		checkMsalAuth,
		deleteAllCookies,
	};
}