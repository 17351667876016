import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { Card, Button, Table } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import moment from "moment";
import { Bar } from "react-chartjs-2";

import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";

import "../../Systems";
import SystemInformation from "../SystemInformation/SystemInformation";
import PageTable from "../../../../Shared/PageTable/PageTable";
import CostFormatter from "../../../../Shared/NumberFormatters/CostFormatter";
import RiskMatrixChart from "../../../../Shared/RiskMatrixChart/RiskMatrixChart";
import {
  treatmentTypes,
  treatmentClasses,
} from "../../../../../Assets/Constants/Constants";
import TreatmentsMatrix from "../TreatmentsMatrix/TreatmentsMatrix";
import TreatmentEditorModal from "../../../../Shared/Modals/Edit/TreatmentEditorModal";
import { useDynamicLanguage } from "../../../../../DynamicLanguageProvider";
import WizardFieldManipulator from "../../../../Shared/WizardHelpers/WizardFieldManipulator";

import { generateUrl } from "../../../../../config";

import "react-toastify/dist/ReactToastify.css";
import "./SystemSummary.scss";

function SystemSummary(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  let history = useHistory();
  const t = useTranslate();
  const td = useDynamicLanguage().td;
  const GetWizardFieldByReference =
    WizardFieldManipulator.GetWizardFieldByReference;

  const treatmentMatrixDougnutCutOut = 70;

  const [users, setUsers] = useState<any>([]);
  let { systemid } = useParams();
  const [system, setSystem] = useState<any>();
  //const [systemvalues, setSystemvalues] = useState<any>();

  const [treatments, setTreatments] = useState<any>([]);
  const [completeTreatments, setCompleteTreatments] = useState<any>([]);
  const [orgelements, setOrgelements] = useState<any>();
  //const [owner, setOwner] = useState({});
  //const [coowners, setCoowners] = useState([]);

  const [causes, setCauses] = useState<any>([]);
  const [occurences, setOccurences] = useState<any>([]);
  const [consequences, setConsequences] = useState<any>([]);
  const [selectedTreatments, setSelectedTreatments] = useState<Array<any>>([]);

  const [causesDesc, setCausesDesc] = useState<any>([]);
  const [occurencesDesc, setOccurencesDesc] = useState<any>([]);
  const [consequencesDesc, setConsequencesDesc] = useState<any>([]);

  const [allConsequenceTypes, setAllConsequenceTypes] = useState<Array<any>>(
    []
  );
  const [allOccurenceTypes, setAllOccurenceTypes] = useState<Array<any>>([]);

  const [editTreatmentId, setEditTreatmentId] = useState();
  const [showEditTreatmentModal, setShowEditTreatmentModal] = useState(false);
  const [refetchTreatments, setRefetchTreatments] = useState(0);
  const [showTreatmentDescription, setShowTreatmentDescription] =
    useState(true);
  const [showTreatmentTypeAndClass, setShowTreatmentTypeAndClass] =
    useState(true);
  const [showTreatmentDocumentationLink, setShowTreatmentDocumentationLink] =
    useState(true);
  const [showBowtieDescription, setShowBowtieDescription] = useState(true);
  const [showSystemValuesDescription, setShowSystemValuesDescription] =
    useState(true);

  function handleShowEditTreatmentModal(evt, treatment) {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    setEditTreatmentId(treatment._id);
    setShowEditTreatmentModal(true);
  }

  const labelLow = t("treatment.label_low");
  const labelMiddel = t("treatment.label_middel");
  const labelHigh = t("treatment.label_high");
  const labelExtremelyHigh = t("treatment.label_extremely_high");

  const customValueTypes = [
    "systemvaluecommunication",
    "systemvaluecompanydata",
    "systemvalueeconomy",
    "systemvaluehardware",
    "systemvalueotherinfo",
    "systemvalueotherlawinfo",
    "systemvaluepersoninformation",
    "systemvalueusernamepassword",
  ];

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  /*     const customValueTypes = [
        "systemvaluecommunication",
        "systemvaluecompanydata",
        "systemvalueeconomy",
        "systemvaluehardware",
        "systemvalueotherinfo",
        "systemvalueotherlawinfo",
        "systemvaluepersoninformation",
        "systemvalueusernamepassword"
    ] */

  const handleBackSystems = (evt) => {
    history.goBack();
  };

  function percentageToValue(percentage, maxvalue) {
    return Math.round((maxvalue - 1) * (percentage / 100) + 1);
  }

  function valueToText(val, type) {
    if (val <= 1) {
      return settings["grid" + type + "Name1"];
    }
    if (val <= 2) {
      return settings["grid" + type + "Name2"];
    }
    if (val <= 3) {
      return settings["grid" + type + "Name3"];
    }
    if (val <= 4) {
      return settings["grid" + type + "Name4"];
    }
    if (val <= 5) {
      return settings["grid" + type + "Name5"];
    }
    if (val <= 6) {
      return settings["grid" + type + "Name6"];
    }
  }

  function getText(value) {
    switch (value) {
      case 1:
        return labelLow;
      case 2:
        return labelMiddel;
      case 3:
        return labelHigh;
      case 4:
        return labelExtremelyHigh;
    }
  }

  function dateToSimpleString(date) {
    return (
      moment(date).format("yyyy-MM-DD") + " " + moment(date).format("HH:mm")
    );
  }

  async function fetchData() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systems/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systemvalues/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/occurences/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systems/ownership/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/treatments/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/causes/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/consequences/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/users/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/orgelements/"),
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/consequencetypes/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/occurenceTypes/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/riskitems/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setSystem(res[0].data);
          setOccurences(res[2].data);
          //setSystemvalues(res[1].data);
          //setOwner(res[3].data["owner"]);
          //setCoowners(res[3].data["coowners"]);
          let tempCompleteTreatments = JSON.parse(JSON.stringify(res[4].data));
          setCompleteTreatments(tempCompleteTreatments);
          res[4].data.forEach((treatment) => {
            treatment["oldvalue"] = treatment.probabilityreduction;
            treatment["probabilityreduction"] =
              Math.round(
                (treatment["probabilityreduction"] / 100) *
                  (treatment["cause"] == undefined
                    ? settings["gridX"]
                    : settings["gridY"]) *
                  10
              ) / 10;
          });
          setTreatments(res[4].data);
          setCauses(res[5].data);
          setConsequences(res[6].data);
          setUsers(res[7].data);
          setOrgelements(res[8].data);
          setAllConsequenceTypes(res[9].data);
          setAllOccurenceTypes(res[10].data);

          //Add descriptions for causes/events/consequences
          let causeDescArray = [] as any;
          let occurenceDescArray = [] as any;
          let consequenceDescArray = [] as any;
          res[11].data.map((data) =>
            causeDescArray.length == 0 ||
            !causeDescArray.map((a) => a._id).includes(data.cause._id)
              ? causeDescArray.push({
                  _id: data.cause._id,
                  name: data.cause.name,
                  desc: data.cause.description,
                  probability: data.cause.probability,
                })
              : null
          );
          res[11].data.map((data) =>
            occurenceDescArray.length == 0 ||
            !occurenceDescArray.map((a) => a._id).includes(data.occurence._id)
              ? occurenceDescArray.push({
                  _id: data.occurence._id,
                  name:
                    data.occurence.type != undefined
                      ? data.occurence.type
                      : data.occurence.name,
                  desc: data.occurence.name,
                })
              : null
          );
          res[11].data.map((data) =>
            consequenceDescArray.length == 0 ||
            !consequenceDescArray
              .map((a) => a._id)
              .includes(data.consequence._id)
              ? consequenceDescArray.push({
                  _id: data.consequence._id,
                  name:
                    data.consequence.name != ""
                      ? data.consequence.name
                      : data.consequence.category,
                  desc: data.consequence.description,
                  probability: data.consequence.consequenceValue,
                })
              : null
          );
          setCausesDesc(causeDescArray);
          setOccurencesDesc(occurenceDescArray);
          setConsequencesDesc(consequenceDescArray);
        })
      );
  }

  useEffect(() => {
    if (auth.user["userdata"] == undefined) {
      window.location.href = "/login";
    }
    if (
      auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(
        (rule) => rule.objType == "system"
      )?.actions.risk_assessment_report != "allow"
    ) {
      auth.signout();
    } else {
      fetchData();
    }
  }, []);

  return (
    <div className="maincontent">
      {system != undefined ? (
        <div className="systems-container system-summary">
          <div className="pagetitle-container">
            <h1 className="no-print">{t("system.systemSummary.page_title")}</h1>
            <Button className="backbtn no-print" onClick={handleBackSystems}>
              {t("system_description.system_back")}
            </Button>
          </div>
          <div className="systeminformation-div">
            <Card>
              <Card.Body>
                <h3>{system.name}</h3>
                <hr />
                <SystemInformation
                  system={system}
                  orgelements={orgelements}
                ></SystemInformation>
                <hr />
                <h4>{t("system.systemSummary.riskinformation_title")}</h4>
                {system.systemType[0] != "orgros" &&
                system.systemType[0] != "other" ? (
                  <div className="row">
                    <div className="col-md-6">
                      <p>
                        {t("system.systemSummary.riskinformation.date")}:{" "}
                        {dateToSimpleString(system.date)}
                      </p>
                      <p>
                        {t("system.systemSummary.riskinformation.name")}:{" "}
                        {system.name}
                      </p>
                      <p>
                        {t("system.systemSummary.riskinformation.alias")}:{" "}
                        {system.alias}
                      </p>
                      <p>
                        {t("system.systemSummary.riskinformation.ownerOrg")}:{" "}
                        {system.ownerOrg}
                      </p>
                      <p>
                        {t("system.systemSummary.riskinformation.ownerSection")}
                        : {system.ownerSection}
                      </p>
                      <p>
                        {t("system.systemSummary.riskinformation.providerinfo")}
                        : {system.providerinfo}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.operationStatus"
                        )}
                        : {system.operationStatus}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.numberofusers"
                        )}
                        : {system.numberofusers}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.maxnumberofusers"
                        )}
                        : {system.maxnumberofusers}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.userscategory"
                        )}
                        :{" "}
                        {system.userscategory != undefined
                          ? system.userscategory
                              .map((category) => category.value)
                              .join(", ")
                          : null}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.systemaccessmethod"
                        )}
                        :{" "}
                        {system.systemaccessmethod != undefined
                          ? system.systemaccessmethod
                              .map((method, i) => method.value)
                              .join(", ")
                          : null}
                      </p>
                      <p>
                        {t("system.systemSummary.riskinformation.category")}:{" "}
                        {system.category}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.specialavailabilityperiod"
                        )}
                        :{" "}
                        {system.specialavailabilityperiod == true
                          ? t("system.systemSummary.riskinformation.yes")
                          : t("system.systemSummary.riskinformation.no")}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.availabilityreasoning"
                        )}
                        : {system.availabilityreasoning}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.operatingsystem"
                        )}
                        : {system.operatingsystem}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.operatingsystemversion"
                        )}
                        : {system.operatingsystemversion}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.systemdocumentationlink"
                        )}
                        : {system.systemdocumentationlink}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.systemloginlink"
                        )}
                        : {system.systemloginlink}
                      </p>
                    </div>
                  </div>
                ) : system.systemType[0] == "orgros" &&
                  system.data != undefined ? (
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        {t("system.systemSummary.riskinformation.date")}:{" "}
                        {system.data != undefined
                          ? dateToSimpleString(system.data.startdate)
                          : ""}
                      </p>
                      <p>
                        {t("system.systemSummary.riskinformation.name")}:{" "}
                        {system.data != undefined ? system.data.name : ""}
                      </p>
                      <p>
                        {t("system.systemSummary.riskinformation.industrycode")}
                        :{" "}
                        {system.data != undefined
                          ? system.data.industrycode
                          : ""}
                      </p>
                      <p>
                        {t("system.systemSummary.riskinformation.industryssb")}:{" "}
                        {system.data != undefined &&
                        system.data.industryssb != undefined
                          ? t(
                              "diri.regassistant.types.orgros.industryssbselections." +
                                system.data.industryssb
                            )
                          : ""}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.employeescount"
                        )}
                        :{" "}
                        {system.data != undefined
                          ? system.data.employeescount
                          : ""}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.securityrequirements"
                        )}
                        :{" "}
                        {system.data != undefined
                          ? system.data.securityrequirements
                          : ""}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.importantdeliveries"
                        )}
                        :{" "}
                        {system.data != undefined
                          ? system.data.importantdeliveries
                          : ""}
                      </p>
                    </div>
                  </div>
                ) : system.systemType[0] == "other" ? (
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        {t("system.systemSummary.riskinformation.date")}:{" "}
                        {dateToSimpleString(system.date)}
                      </p>
                      <p>
                        {t("system.systemSummary.riskinformation.name")}:{" "}
                        {system.name}
                      </p>
                      <p>
                        {t(
                          "system.systemSummary.riskinformation.implicated_systems"
                        )}
                        :{" "}
                        {system?.data?.implicatedriskassessments
                          ?.map((i) => i.label)
                          .join(", ") ?? ""}
                      </p>
                    </div>
                  </div>
                ) : null}
                {system.customfields != undefined &&
                system.customfields.length > 0 ? (
                  <div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        {system.customfields.map((field, i) => (
                          <p key={i}>
                            {td(field.id)}:{" "}
                            {field.value.label != undefined
                              ? td(field.value.label)
                              : field.value}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
                <hr />
                <div className="row">
                  <div className="col-md-12">
                    <h4>
                      {t(
                        "system.systemSummary.riskinformation.riskassessmentparticipants"
                      )}
                    </h4>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>
                            {t(
                              "system.systemSummary.riskinformation.participant_name"
                            )}
                          </th>
                          <th>
                            {t(
                              "system.systemSummary.riskinformation.participant_role"
                            )}
                          </th>
                          <th>
                            {t(
                              "system.systemSummary.riskinformation.participant_organization"
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {system.systemType[0] != "orgros" &&
                        system.systemType[0] != "other" &&
                        system.riskassessmentparticipants != undefined
                          ? system.riskassessmentparticipants.map(
                              (user, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{user.name}</td>
                                    <td>{user.userrole}</td>
                                    <td>{user.organizationname}</td>
                                  </tr>
                                );
                              }
                            )
                          : system.systemType[0] == "orgros" &&
                            system.data != undefined &&
                            system.data.riskassessmentparticipants != undefined
                          ? system.data.riskassessmentparticipants.map(
                              (user, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{user.name}</td>
                                    <td>{user.userrole}</td>
                                    <td>{user.organizationname}</td>
                                  </tr>
                                );
                              }
                            )
                          : system.systemType[0] == "other" &&
                            system.data != undefined &&
                            system.data.riskassessmentparticipants != undefined
                          ? system.data.riskassessmentparticipants.map(
                              (user, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{user.name}</td>
                                    <td>{user.userrole}</td>
                                    <td>{user.organizationname}</td>
                                  </tr>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12">
                    <div className="page-break">
                      <h4>
                        {system.systemType[0] != "orgros"
                          ? t(
                              "system.systemSummary.riskinformation.description"
                            )
                          : t("system.systemSummary.riskinformation.orgdesc")}
                      </h4>
                      {system.systemType[0] != "orgros" ? (
                        <p>
                          {system.systemType[0] != "other"
                            ? system.description
                            : system.data.description}
                        </p>
                      ) : (
                        <p>
                          {system.data != undefined ? system.data.orgdesc : ""}
                        </p>
                      )}
                    </div>
                    {system.systemType[0] != "orgros" ? (
                      <div className="page-break">
                        <h4>
                          {t(
                            "system.systemSummary.riskinformation.limitationsdescription"
                          )}
                        </h4>
                        <p>
                          {system.systemType[0] != "other"
                            ? system.limitationsdescription
                            : system.data.limitationsdescription}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="systemvalues-div">
            <Card>
              <Card.Body>
                <div className="container-header">
                  <h3>{t("system.systemSummary.systemvalues_title")}</h3>
                  <Button
                    className="no-print"
                    onClick={() =>
                      setShowSystemValuesDescription(
                        !showSystemValuesDescription
                      )
                    }
                  >
                    {showSystemValuesDescription
                      ? t("system.systemSummary.hide_descriptions")
                      : t("system.systemSummary.show_descriptions")}
                  </Button>
                </div>
                <PageTable
                  dataUrl={"/api/cra/systemvalues/getAllPaged/" + systemid}
                  options={{
                    showFields: showSystemValuesDescription
                      ? [
                          {
                            label: t("system.systemSummary.systemvalues.name"),
                            field: "name",
                          },
                          {
                            label: t(
                              "system.systemSummary.systemvalues.valueType"
                            ),
                            field: "valueType",
                            override: (dataLine) =>
                              customValueTypes.includes(dataLine["valueType"])
                                ? t(
                                    "system_asset.type." + dataLine["valueType"]
                                  )
                                : dataLine["valueType"],
                          },
                          {
                            label: t(
                              "system.systemSummary.systemvalues.confidentiality"
                            ),
                            field: "k",
                            override: (dataLine) =>
                              td(
                                settings[
                                  "systemvalueKLevel" +
                                    percentageToValue(
                                      dataLine["k"],
                                      settings["systemvalueKNum"]
                                    )
                                ]
                              ),
                          },
                          {
                            label: t(
                              "system.systemSummary.systemvalues.integrity"
                            ),
                            field: "i",
                            override: (dataLine) =>
                              td(
                                settings[
                                  "systemvalueILevel" +
                                    percentageToValue(
                                      dataLine["i"],
                                      settings["systemvalueINum"]
                                    )
                                ]
                              ),
                          },
                          {
                            label: t(
                              "system.systemSummary.systemvalues.availability"
                            ),
                            field: "t",
                            override: (dataLine) =>
                              td(
                                settings[
                                  "systemvalueTLevel" +
                                    percentageToValue(
                                      dataLine["t"],
                                      settings["systemvalueTNum"]
                                    )
                                ]
                              ),
                          },
                          {
                            label: t(
                              "system.systemSummary.systemvalues.description"
                            ),
                            field: "description",
                            override: (dataLine) =>
                              GetWizardFieldByReference(
                                dataLine.regdata,
                                "description"
                              )?.value ?? "",
                          },
                        ]
                      : [
                          {
                            label: t("system.systemSummary.systemvalues.name"),
                            field: "name",
                          },
                          {
                            label: t(
                              "system.systemSummary.systemvalues.valueType"
                            ),
                            field: "valueType",
                            override: (dataLine) =>
                              customValueTypes.includes(dataLine["valueType"])
                                ? t(
                                    "system_asset.type." + dataLine["valueType"]
                                  )
                                : dataLine["valueType"],
                          },
                          {
                            label: t(
                              "system.systemSummary.systemvalues.confidentiality"
                            ),
                            field: "k",
                            override: (dataLine) =>
                              td(
                                settings[
                                  "systemvalueKLevel" +
                                    percentageToValue(
                                      dataLine["k"],
                                      settings["systemvalueKNum"]
                                    )
                                ]
                              ),
                          },
                          {
                            label: t(
                              "system.systemSummary.systemvalues.integrity"
                            ),
                            field: "i",
                            override: (dataLine) =>
                              td(
                                settings[
                                  "systemvalueILevel" +
                                    percentageToValue(
                                      dataLine["i"],
                                      settings["systemvalueINum"]
                                    )
                                ]
                              ),
                          },
                          {
                            label: t(
                              "system.systemSummary.systemvalues.availability"
                            ),
                            field: "t",
                            override: (dataLine) =>
                              td(
                                settings[
                                  "systemvalueTLevel" +
                                    percentageToValue(
                                      dataLine["t"],
                                      settings["systemvalueTNum"]
                                    )
                                ]
                              ),
                          },
                        ],
                    pageSize: 10000,
                  }}
                  hidePagination={true}
                  hidePagesize={true}
                  hideFilter={true}
                  hover={false}
                  defaultSortField="valueType"
                  defaultSortDirection="asc"
                />
                <hr />
              </Card.Body>
            </Card>
          </div>
          <div className="riskmatrix-div page-break">
            <Card>
              <Card.Body>
                <h3>{t("system.systemSummary.riskmatrix_title")}</h3>
                <div className="page-break">
                  <div className="col-md-6">
                    {system != undefined ? (
                      <div className="page-break">
                        <h4>
                          {t(
                            "system.systemSummary.actionplan.beforeTreatments"
                          )}
                        </h4>
                        <RiskMatrixChart
                          treatmentsCompleted={
                            system["actionplan"] != undefined
                              ? system["actionplan"]["treatments"]
                              : []
                          }
                          systemid={systemid}
                          defaultValue={1}
                        ></RiskMatrixChart>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="page-break">
                  <div className="col-md-6">
                    {system != undefined ? (
                      <div className="page-break">
                        <h4>
                          {t("system.systemSummary.actionplan.afterTreatments")}
                        </h4>
                        <RiskMatrixChart
                          systemid={systemid}
                          treatmentsCompleted={
                            system["actionplan"] != undefined
                              ? system["actionplan"]["treatments"]
                              : []
                          }
                          defaultValue={2}
                        ></RiskMatrixChart>
                      </div>
                    ) : null}
                  </div>
                </div>
                <hr />
              </Card.Body>
            </Card>
          </div>
          <div className="bowtie-div page-break">
            <Card>
              <Card.Body>
                <div className="page-break">
                  <h3>{t("system.systemSummary.bowtie_title")}</h3>
                  <PageTable
                    dataUrl={
                      generateUrl("/api/cra/riskitems/bysystem/getAllPaged/") +
                      systemid
                    }
                    options={{
                      showFields: [
                        {
                          label: t("riskregistry.risk"),
                          field: "risk",
                          override: (lineItem) =>
                            getText(percentageToValue(lineItem.risk, 4)),
                          disableFilter: true,
                        },
                        {
                          label: t("system.systemSummary.bowtie.cause"),
                          field: "causeName",
                        },
                        {
                          label: t("system.systemSummary.bowtie.occurence"),
                          field: "occurenceName",
                        },
                        {
                          label: t("system.systemSummary.bowtie.consequence"),
                          field: "consequenceName",
                        },
                        {
                          label: t("system.systemSummary.bowtie.threats"),
                          field: "threats",
                        },
                        {
                          label: t("system.systemSummary.bowtie.values"),
                          field: "values",
                        },
                        {
                          label: t("system.systemSummary.bowtie.vulnerability"),
                          field: "causeVulnerability",
                        },
                      ],
                      pageSize: 10000,
                    }}
                    hidePagination={true}
                    hidePagesize={true}
                    hideFilter={true}
                    hover={false}
                    /* defaultSortField="risk"
                                        defaultSortDirection="asc"  */
                  />
                  <hr />

                  <div className="container-header">
                    <div className="bowtieDescriptionsHeader">
                      <Button
                        className="no-print"
                        onClick={() =>
                          setShowBowtieDescription(!showBowtieDescription)
                        }
                      >
                        {showBowtieDescription == true
                          ? t("system.systemSummary.hide_descriptions")
                          : t("system.systemSummary.show_descriptions")}
                      </Button>
                    </div>
                  </div>

                  <div className={showBowtieDescription ? "" : "hidden"}>
                    <table className="descriptionTable">
                      <thead>
                        <tr>
                          <th>{t("system.systemSummary.bowtie.cause")}</th>
                          <th>
                            {t("system.systemSummary.bowtie.description")}
                          </th>
                          <th>
                            {t("system.systemSummary.bowtie.cause_probability")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {causesDesc.map((cause, key) => {
                          let tempProbability = percentageToValue(
                            cause["probability"],
                            settings["gridY"]
                          );
                          let probability =
                            tempProbability -
                            treatments
                              .filter(
                                (treatment) =>
                                  treatment["cause"].includes(cause["_id"]) &&
                                  treatment["status"] == "closed"
                              )
                              .map(
                                (treatment) => treatment["probabilityreduction"]
                              )
                              .reduce((total, number) => total + number, 0);
                          return (
                            <tr key={key}>
                              <td>{cause.name}</td>
                              <td>{cause.desc}</td>
                              {settings["gridY"] == 3 ? (
                                <td>
                                  {" "}
                                  {Math.round(probability) <= 0 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName1"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 1 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName1"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 2 ? (
                                    <div>
                                      <span className="probability3">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName2"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 3 ? (
                                    <div>
                                      <span className="probability6">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName3"])}</span>
                                    </div>
                                  ) : null}{" "}
                                </td>
                              ) : settings["gridY"] == 4 ? (
                                <td>
                                  {" "}
                                  {Math.round(probability) <= 0 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName1"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 1 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName1"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 2 ? (
                                    <div>
                                      <span className="probability3">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName2"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 3 ? (
                                    <div>
                                      <span className="probability4">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName3"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 4 ? (
                                    <div>
                                      <span className="probability6">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName4"])}</span>
                                    </div>
                                  ) : null}{" "}
                                </td>
                              ) : settings["gridY"] == 5 ? (
                                <td>
                                  {" "}
                                  {Math.round(probability) <= 0 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName1"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 1 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName1"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 2 ? (
                                    <div>
                                      <span className="probability3">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName2"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 3 ? (
                                    <div>
                                      <span className="probability4">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName3"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 4 ? (
                                    <div>
                                      <span className="probability5">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName4"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 5 ? (
                                    <div>
                                      <span className="probability6">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName5"])}</span>
                                    </div>
                                  ) : null}{" "}
                                </td>
                              ) : settings["gridY"] == 6 ? (
                                <td>
                                  {" "}
                                  {Math.round(probability) <= 0 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName1"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 1 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName1"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 2 ? (
                                    <div>
                                      <span className="probability2">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName2"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 3 ? (
                                    <div>
                                      <span className="probability3">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName3"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 4 ? (
                                    <div>
                                      <span className="probability4">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName4"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 5 ? (
                                    <div>
                                      <span className="probability5">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName5"])}</span>
                                    </div>
                                  ) : Math.round(probability) == 6 ? (
                                    <div>
                                      <span className="probability6">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridYName6"])}</span>
                                    </div>
                                  ) : null}{" "}
                                </td>
                              ) : null}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <table className="descriptionTable">
                      <thead>
                        <tr>
                          <th>{t("system.systemSummary.bowtie.occurence")}</th>
                          <th>
                            {t("system.systemSummary.bowtie.description")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {occurencesDesc.map((occurence, key) => (
                          <tr key={key}>
                            <td>
                              {td(
                                allOccurenceTypes?.find(
                                  (i) => i._id == occurence.name
                                )?.name ?? occurence.name
                              )}
                            </td>
                            <td>{occurence.desc}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <table className="descriptionTable">
                      <thead>
                        <tr>
                          <th>
                            {t("system.systemSummary.bowtie.consequence")}
                          </th>
                          <th>
                            {t("system.systemSummary.bowtie.description")}
                          </th>
                          <th>
                            {t(
                              "system.systemSummary.bowtie.consequence_probability"
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {consequencesDesc.map((consequence, key) => {
                          let tempProbability = percentageToValue(
                            consequence["probability"],
                            settings["gridY"]
                          );
                          let consProbability =
                            tempProbability -
                            treatments
                              .filter(
                                (treatment) =>
                                  treatment["consequence"].includes(
                                    consequence["_id"]
                                  ) && treatment["status"] == "closed"
                              )
                              .map(
                                (treatment) => treatment["probabilityreduction"]
                              )
                              .reduce((total, number) => total + number, 0);
                          return (
                            <tr key={key}>
                              <td>
                                {td(
                                  allConsequenceTypes?.find(
                                    (i) => i._id == consequence.name
                                  )?.name ?? consequence.name
                                )}
                              </td>
                              <td>{consequence.desc}</td>
                              {settings["gridX"] == 3 ? (
                                <td>
                                  {" "}
                                  {Math.round(consProbability) <= 0 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName1"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 1 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName1"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 2 ? (
                                    <div>
                                      <span className="probability3">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName2"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 3 ? (
                                    <div>
                                      <span className="probability6">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName3"])}</span>
                                    </div>
                                  ) : null}{" "}
                                </td>
                              ) : settings["gridX"] == 4 ? (
                                <td>
                                  {" "}
                                  {Math.round(consProbability) <= 0 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName1"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 1 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName1"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 2 ? (
                                    <div>
                                      <span className="probability3">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName2"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 3 ? (
                                    <div>
                                      <span className="probability4">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName3"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 4 ? (
                                    <div>
                                      <span className="probability6">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName4"])}</span>
                                    </div>
                                  ) : null}{" "}
                                </td>
                              ) : settings["gridX"] == 5 ? (
                                <td>
                                  {" "}
                                  {Math.round(consProbability) <= 0 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName1"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 1 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName1"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 2 ? (
                                    <div>
                                      <span className="probability3">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName2"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 3 ? (
                                    <div>
                                      <span className="probability4">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName3"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 4 ? (
                                    <div>
                                      <span className="probability5">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName4"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 5 ? (
                                    <div>
                                      <span className="probability6">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName5"])}</span>
                                    </div>
                                  ) : null}{" "}
                                </td>
                              ) : settings["gridX"] == 6 ? (
                                <td>
                                  {" "}
                                  {Math.round(consProbability) <= 0 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName1"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 1 ? (
                                    <div>
                                      <span className="probability1">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName1"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 2 ? (
                                    <div>
                                      <span className="probability2">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName2"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 3 ? (
                                    <div>
                                      <span className="probability3">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName3"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 4 ? (
                                    <div>
                                      <span className="probability4">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName4"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 5 ? (
                                    <div>
                                      <span className="probability5">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName5"])}</span>
                                    </div>
                                  ) : Math.round(consProbability) == 6 ? (
                                    <div>
                                      <span className="probability6">
                                        &nbsp;
                                      </span>
                                      <span>{td(settings["gridXName6"])}</span>
                                    </div>
                                  ) : null}{" "}
                                </td>
                              ) : null}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <hr />
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="action-div page-break">
            <Card>
              <Card.Body>
                <h4 className="header-title">
                  {t("system.treatments_graph.title")}
                </h4>
                <hr />
                <p>{t("system.treatments_graph.description")}</p>
                <div className="col-md-6">
                  <Bar
                    data={{
                      labels: [
                        t("system.treatments_graph.state.open"),
                        t("system.treatments_graph.state.suggested"),
                        t("system.treatments_graph.state.planned"),
                        t("system.treatments_graph.state.processing"),
                        t("system.treatments_graph.state.closed"),
                      ],
                      datasets: [
                        {
                          backgroundColor: [
                            "#c8c8c8",
                            "#993742",
                            "#d8775a",
                            "#facd75",
                            "#5b8266",
                          ],
                          data: [
                            treatments.filter((i) => i["status"] == "open")
                              .length,
                            treatments.filter((i) => i["status"] == "suggested")
                              .length,
                            treatments.filter((i) => i["status"] == "planned")
                              .length,
                            treatments.filter(
                              (i) => i["status"] == "processing"
                            ).length,
                            treatments.filter((i) => i["status"] == "closed")
                              .length,
                          ],
                        },
                      ],
                    }}
                    options={{
                      title: {
                        display: true,
                        fontSize: 20,
                      },
                      legend: {
                        display: false,
                        position: "right",
                      },
                      scales: {
                        yAxes: [
                          {
                            stacked: true,
                            ticks: {
                              suggestedMin: 0,
                              stepSize: 1,
                            },
                          },
                        ],
                      },
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="treatmentMatrix-div page-break">
            <div className="row">
              <div className="col-md-12">
                <TreatmentsMatrix
                  treatments={treatments}
                  selectedTreatments={selectedTreatments}
                  setSelectedTreatments={setSelectedTreatments}
                  allCauses={causes}
                  allConsequences={consequences}
                  allConsequenceTypes={allConsequenceTypes}
                  handleShowEditTreatmentModal={handleShowEditTreatmentModal}
                ></TreatmentsMatrix>
              </div>
            </div>
            <TreatmentEditorModal
              systemid={systemid}
              show={showEditTreatmentModal}
              onSave={(savedTreatment) => {
                let index = selectedTreatments
                  .map((t) => t._id)
                  .indexOf(savedTreatment._id);
                selectedTreatments.splice(index, 1, savedTreatment);
                setSelectedTreatments(selectedTreatments);
                setShowEditTreatmentModal(false);
                setRefetchTreatments(refetchTreatments + 1);
              }}
              onHide={() => {
                setShowEditTreatmentModal(false);
                setEditTreatmentId(undefined);
              }}
              onCancel={() => {
                setShowEditTreatmentModal(false);
                setEditTreatmentId(undefined);
              }}
              treatmentId={editTreatmentId}
            ></TreatmentEditorModal>
          </div>
          {system.accesses.includes("show_treatment_plan") ? (
            <div className="actionplan-div page-break">
              <Card>
                <Card.Body>
                  <div className="systems-container actionplan-container">
                    <div className="page-break">
                      <h3>{t("system.systemSummary.actionplan_title")}</h3>
                      <hr />
                      <h4>{t("system_actionplan.form.costsummary.title")}</h4>
                      <p>
                        {t("system_actionplan.form.costsummary.description")}
                      </p>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              {t(
                                "system_actionplan.form.costsummary.one_time_cost"
                              )}
                              :{" "}
                            </td>
                            <td>
                              {system.actionplan != undefined ? (
                                <CostFormatter>
                                  {system["actionplan"]["treatments"]
                                    ?.filter(
                                      (t) =>
                                        t != undefined &&
                                        t.onetimecost != undefined &&
                                        t.onetimecost != ""
                                    )
                                    ?.map((t) => Number(t.onetimecost))
                                    ?.reduce((i, j) => i + j, 0) ?? ""}
                                </CostFormatter>
                              ) : null}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {t(
                                "system_actionplan.form.costsummary.yearly_cost"
                              )}
                              :{" "}
                            </td>
                            <td>
                              {system.actionplan != undefined ? (
                                <CostFormatter>
                                  {system["actionplan"]["treatments"]
                                    ?.filter(
                                      (t) =>
                                        t != undefined &&
                                        t.cost != undefined &&
                                        t.cost != ""
                                    )
                                    ?.map((t) => Number(t.cost))
                                    ?.reduce((i, j) => i + j, 0) ?? ""}
                                </CostFormatter>
                              ) : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <Card>
                      <Card.Body>
                        <div className="container-header">
                          <h4>
                            {t("system_actionplan.form.plantreatments_title")}
                          </h4>
                          <div>
                            <Button
                              className="no-print treatmentDescriptionBtn"
                              onClick={() =>
                                setShowTreatmentDescription(
                                  !showTreatmentDescription
                                )
                              }
                            >
                              {showTreatmentDescription == true
                                ? t("system.systemSummary.hide_descriptions")
                                : t("system.systemSummary.show_descriptions")}
                            </Button>
                            <Button
                              className="no-print treatmentDescriptionBtn"
                              onClick={() =>
                                setShowTreatmentTypeAndClass(
                                  !showTreatmentTypeAndClass
                                )
                              }
                            >
                              {showTreatmentTypeAndClass == true
                                ? t("system.systemSummary.hide_class_type")
                                : t("system.systemSummary.show_class_type")}
                            </Button>
                            <Button
                              className="no-print treatmentDescriptionBtn"
                              onClick={() =>
                                setShowTreatmentDocumentationLink(
                                  !showTreatmentDocumentationLink
                                )
                              }
                            >
                              {showTreatmentDocumentationLink == true
                                ? t(
                                    "system.systemSummary.hide_documentation_link"
                                  )
                                : t(
                                    "system.systemSummary.show_documentation_link"
                                  )}
                            </Button>
                          </div>
                        </div>

                        <div className="treatmentslist">
                          {completeTreatments.map((treatment, i) => (
                            <div key={i} className="parent">
                              <div className="column 1 treatmentitem actual">
                                <div className="column 2">
                                  <div className="treatmentcol col2 treatmentcol-2 treatmentname">
                                    <p className="titles">
                                      {t(
                                        "system_actionplan.form.plantreatments.titles.name"
                                      )}
                                    </p>
                                    <p>&nbsp;{treatment.name}</p>
                                  </div>

                                  <div className="treatmentcol col3 treatmentcol-1 treatmentcostonce">
                                    <p className="titles">
                                      {t(
                                        "system_actionplan.form.plantreatments.titles.costonce"
                                      )}
                                    </p>
                                    <p>
                                      &nbsp;
                                      {
                                        <CostFormatter>
                                          {treatment != undefined
                                            ? treatment.onetimecost
                                            : ""}
                                        </CostFormatter>
                                      }
                                    </p>
                                  </div>
                                  <div className="treatmentcol col4 treatmentcol-1 treatmentcostyearly">
                                    <p className="titles">
                                      {t(
                                        "system_actionplan.form.plantreatments.titles.costyearly"
                                      )}
                                    </p>
                                    <p>
                                      &nbsp;
                                      {
                                        <CostFormatter>
                                          {treatment != undefined
                                            ? treatment.cost
                                            : ""}
                                        </CostFormatter>
                                      }
                                    </p>
                                  </div>
                                  <div className="treatmentcol col5 treatmentcol-1 treatmentstatus">
                                    <p className="titles">
                                      {t(
                                        "system_actionplan.form.plantreatments.titles.status"
                                      )}
                                    </p>
                                    <p>
                                      &nbsp;
                                      {treatment["status"] == "open"
                                        ? t("treatment.open")
                                        : ""}
                                      {treatment["status"] == "suggested"
                                        ? t("treatment.suggested")
                                        : ""}
                                      {treatment["status"] == "planned"
                                        ? t("treatment.planned")
                                        : ""}
                                      {treatment["status"] == "processing"
                                        ? t("treatment.processing")
                                        : ""}
                                      {treatment["status"] == "closed"
                                        ? t("treatment.closed")
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="treatmentcol col6 treatmentcol-1 treatmentresponsible">
                                    <p className="titles">
                                      {t(
                                        "system_actionplan.form.plantreatments.titles.responsible"
                                      )}
                                    </p>
                                    <p>
                                      &nbsp;
                                      {users.find(
                                        (u) => u._id == treatment.responsible
                                      )?.name ??
                                        (!treatment.responsible?.includes(
                                          " "
                                        ) && treatment.responsible?.length == 24
                                          ? t(
                                              "system_actionplan.form.plantreatments.nouserfound"
                                            )
                                          : treatment.responsible)}
                                    </p>
                                  </div>
                                  <div className="treatmentcol col7 treatmentcol-1 treatmentduedate">
                                    <p className="titles">
                                      {t(
                                        "system_actionplan.form.plantreatments.titles.duedate"
                                      )}
                                    </p>
                                    <p>
                                      &nbsp;
                                      {treatment.duetime != undefined
                                        ? dateToSimpleString(treatment.duetime)
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="column 1 treatmentitem actual">
                                <div className="column 2">
                                  <div
                                    className={
                                      "treatmentcol treatmentDescription col-md-6 " +
                                      (showTreatmentDescription == false
                                        ? "hidden"
                                        : "")
                                    }
                                  >
                                    <p className="titles treatmentDescriptionField">
                                      {t(
                                        "system_actionplan.form.plantreatments.titles.description"
                                      )}
                                    </p>
                                    <p>
                                      {treatment.description != ""
                                        ? treatment.description
                                        : "N/A"}
                                    </p>
                                  </div>
                                  <div
                                    className={
                                      "treatmentcol treatmentClass col-md-3 " +
                                      (showTreatmentTypeAndClass == false
                                        ? "hidden"
                                        : "")
                                    }
                                  >
                                    <p className="titles">
                                      {t(
                                        "system_actionplan.form.plantreatments.titles.class"
                                      )}
                                    </p>
                                    <p>
                                      &nbsp;
                                      {t(
                                        treatmentClasses?.find(
                                          (treatmentClass) =>
                                            treatmentClass.value ==
                                            treatment.class
                                        )?.label
                                      ) ??
                                        (treatment.class != ""
                                          ? treatment?.class ?? "N/A"
                                          : "N/A")}
                                    </p>
                                  </div>

                                  <div
                                    className={
                                      "treatmentcol treatmentType col-md-3 " +
                                      (showTreatmentTypeAndClass == false
                                        ? "hidden"
                                        : "")
                                    }
                                  >
                                    <p className="titles">
                                      {t(
                                        "system_actionplan.form.plantreatments.titles.type"
                                      )}
                                    </p>
                                    <p>
                                      &nbsp;
                                      {t(
                                        treatmentTypes?.find(
                                          (type) => type.value == treatment.type
                                        )?.label
                                      ) ??
                                        (treatment.class != ""
                                          ? treatment?.type ?? "N/A"
                                          : "N/A")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  "column 1 treatmentitem actual " +
                                  (showTreatmentDocumentationLink == false
                                    ? "hidden"
                                    : "")
                                }
                              >
                                <div className="column 2">
                                  <div className="treatmentcol col8">
                                    <p className="titles">
                                      {t(
                                        "system_actionplan.form.plantreatments.titles.documentation_link"
                                      )}
                                    </p>
                                    <p>
                                      &nbsp;
                                      {treatment.documentationlink != ""
                                        ? treatment?.documentationlink ?? "N/A"
                                        : "N/A"}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="column 1 treatmentitem actual">
                                <div className="treatmentcol col8 treatmentcol-4 causeconsequencelist">
                                  <p className="titles causeconsequencefield">
                                    {t(
                                      "system_actionplan.form.plantreatments.titles.causeconsequence"
                                    )}
                                  </p>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>
                                          {t(
                                            "system_actionplan.form.plantreatments.titles.causeconsequenceitem_name"
                                          )}
                                        </th>
                                        <th>
                                          {t(
                                            "system_actionplan.form.plantreatments.titles.causeconsequenceitem_currisk"
                                          )}
                                        </th>
                                        <th>
                                          {t(
                                            "system_actionplan.form.plantreatments.titles.causeconsequenceitem_toberisk"
                                          )}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {causes
                                        .filter((c) =>
                                          treatment.cause.includes(c._id)
                                        )
                                        .map((cause, key) => (
                                          <tr
                                            key={key}
                                            className="causeconsequenceitem"
                                          >
                                            <td className="name">
                                              <p>&nbsp;{cause.name}</p>
                                            </td>
                                            <td className="currisk">
                                              <p>
                                                &nbsp;
                                                {td(
                                                  valueToText(
                                                    percentageToValue(
                                                      cause.probability,
                                                      settings["gridY"]
                                                    ),
                                                    "Y"
                                                  )
                                                )}
                                              </p>
                                            </td>
                                            <td className="toberisk">
                                              <p>
                                                &nbsp;
                                                {td(
                                                  valueToText(
                                                    percentageToValue(
                                                      cause.probability -
                                                        (treatment.probabilityreduction *
                                                          cause.probability) /
                                                          100,
                                                      settings["gridY"]
                                                    ),
                                                    "Y"
                                                  )
                                                )}
                                              </p>
                                            </td>
                                          </tr>
                                        ))}
                                      {consequences
                                        .filter((c) =>
                                          treatment.consequence.includes(c._id)
                                        )
                                        .map((consequence, key) => (
                                          <tr
                                            key={key}
                                            className="causeconsequenceitem"
                                          >
                                            <td className="name">
                                              <p>
                                                &nbsp;
                                                {consequence.name != ""
                                                  ? consequence.name
                                                  : td(
                                                      allConsequenceTypes.find(
                                                        (i) =>
                                                          i._id ==
                                                          consequence.category
                                                      )?.name
                                                    ) ?? consequence.category}
                                              </p>
                                            </td>
                                            <td className="currisk">
                                              <p>
                                                &nbsp;
                                                {td(
                                                  valueToText(
                                                    percentageToValue(
                                                      consequence.consequenceValue,
                                                      settings["gridX"]
                                                    ),
                                                    "X"
                                                  )
                                                )}
                                              </p>
                                            </td>
                                            <td className="toberisk">
                                              <p>
                                                &nbsp;
                                                {td(
                                                  valueToText(
                                                    percentageToValue(
                                                      consequence.consequenceValue -
                                                        (treatment.probabilityreduction *
                                                          consequence.consequenceValue) /
                                                          100,
                                                      settings["gridX"]
                                                    ),
                                                    "X"
                                                  )
                                                )}
                                              </p>
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default SystemSummary;
