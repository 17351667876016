import React, { useEffect, useRef, useState } from "react";

import { Button, Table, Accordion, Pagination } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import { debounce } from "lodash";

import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";

import Field from "../Wizard/Field/Field";

import "./PageTable.scss";

function PageTable(props) {
  const showLabels = props.options.showFields;
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const t = useTranslate();
  //let history = useHistory();
  const [data, setData] = useState([{}]);
  const [currentPageNumber, setCurrentPage] = useState(0);
  const [pageData, setPageData] = useState([{}]);
  const [pageSize, setPageSize] = useState(
    props.options != undefined && props.options.pageSize != undefined
      ? props.options.pageSize
      : 10
  );
  const [selectedOption, setSelectedOption] = useState(0);
  const [dataUrl] = useState(props.dataUrl);
  const [searchValueArray, setSearchValueArray] = useState(
    props.filters != undefined ? props.filters : []
  );
  const [selectedHeaderField, setSelectedHeaderField] = useState(
    props.defaultSortField != undefined ? props.defaultSortField : "_id"
  );
  const [sortDirection, setSortDirection] = useState(
    props.defaultSortDirection != undefined
      ? props.defaultSortDirection
      : "desc"
  );
  const [loading, setLoading] = useState(true);

  const showItemsRange = [3, 5, 10, 15, 25, 50];

  const setPageSizeChanged = (value) => {
    let newPageSize = parseInt(value);
    let newCurrentPageNumber = 0;
    setPageSize(value);
    setSelectedOption(value);
    fetchData(
      newCurrentPageNumber,
      newPageSize,
      selectedHeaderField,
      sortDirection
    );
  };

  const [valueSearch, setValueSearch] = useState({ value: "", label: "" });

  const handleSearch = (value, label) => {
    let searchValues = searchValueArray;
    let searchValue = searchValues.find((e) => e["field"] == label);

    if (searchValue != undefined) {
      (searchValue["value"] as any) = value;
    }
    setSearchValueArray(searchValues);
    fetchData(0, pageSize);
    setCurrentPage(0);
  };

  const debouncedSearch = useRef(
    debounce((value, label) => setValueSearch({ value, label }), 1000)
  ).current;

  useEffect(() => {
    if (!!valueSearch.value) handleSearch(valueSearch.value, valueSearch.label);
  }, [valueSearch]);

  const handleSorting = (label) => {
    let direction = sortDirection === "asc" ? "desc" : "asc";
    if (label != selectedHeaderField) {
      direction = "asc";
    }
    setSortDirection(direction);
    setSelectedHeaderField(label);
    fetchData(currentPageNumber, pageSize, label, direction);
  };

  function setPagination(pageData) {
    const { pagestotal } = pageData;
    const next = () => {
      let nextPage = Math.min(currentPageNumber + 1, pagestotal);
      setCurrentPage(nextPage);
      fetchData(nextPage, pageSize, selectedHeaderField, sortDirection);
    };

    const prev = () => {
      let prePage = currentPageNumber - 1;
      setCurrentPage(prePage);
      fetchData(prePage, pageSize, selectedHeaderField, sortDirection);
    };

    const first = () => {
      setCurrentPage(0);
      fetchData(0, pageSize, selectedHeaderField, sortDirection);
    };

    const last = () => {
      setCurrentPage(pagestotal - 1);
      fetchData(pagestotal - 1, pageSize, selectedHeaderField, sortDirection);
    };

    return (
      <div className="pagination">
        <Pagination>
          <Pagination.First onClick={first} disabled={currentPageNumber == 0} />
          <Pagination.Prev onClick={prev} disabled={currentPageNumber == 0} />
          <Pagination.Item active>
            {currentPageNumber + 1} / {pagestotal}
          </Pagination.Item>
          <Pagination.Next
            onClick={next}
            disabled={currentPageNumber + 1 == pagestotal}
          />
          <Pagination.Last
            onClick={last}
            disabled={currentPageNumber + 1 == pagestotal}
          />
        </Pagination>
        <select
          className="mdb-select md-form"
          onChange={(e) => setPageSizeChanged(e.target.value)}
          value={selectedOption}
        >
          {showItemsRange != undefined
            ? showItemsRange.map((size) => {
                return (
                  <option key={size} value={size}>
                    {" "}
                    {size}{" "}
                  </option>
                );
              })
            : ""}
        </select>
      </div>
    );
  }

  const getSum = (array: any, field: string) => {
    let sum = 0;

    array.forEach(
      (ele, i) => (sum += parseInt(ele[field].replaceAll("NOK", "")) || 0)
    );
    return sum;
  };

  async function fetchData(
    currentPageNumber = 0,
    pageSize = 10,
    label = "id",
    direction = "asc",
    newUrl = dataUrl
  ) {
    axios
      .single({
        method: "post",
        url: newUrl,
        responseType: "json",
        data: {
          pagenumber: currentPageNumber,
          pagesize: pageSize,
          sorting: { field: label, order: direction },
          exclusiveFilters: props.exclusiveFilters,
          filters: [
            ...(!!props.defaultFilters ? props.defaultFilters : []),
            ...searchValueArray.filter(
              (searchValue) => searchValue["value"] != ""
            ),
            ...(props.permaFilters != undefined ? props.permaFilters : []),
          ],
        },
        headers: { "x-access-token": auth.user["token"] },
      })
      .then((res) => {
        let result = [...res["data"]["pagedata"]];
        if (props.filterDataBy) {
          result = result.filter(
            (e) => e[props.filterDataBy.key] !== props.filterDataBy.value
          );
        }

        if (props.complete) {
          const extraData = {
            status: "total",
            name: "",
            responsibleReadable: "",
            onetimecost: getSum(result, "onetimecost"),
            cost: getSum(result, "cost"),
            bowtie: "",
            dateReadable: "",
            duedate: "",
          };

          setData([...result, extraData]);
        } else {
          setData(result);
        }
        setPageData(res["data"]);
        setCurrentPage(currentPageNumber);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchData(
      currentPageNumber,
      pageSize,
      selectedHeaderField,
      sortDirection,
      props.dataUrl
    );
    setSelectedOption(pageSize);

    if (showLabels != undefined && searchValueArray.length < 1) {
      setSearchValueArray(
        showLabels.map((label) => {
          return { field: label.field, value: "" };
        })
      );
    }
  }, [
    props.dataUrl,
    props.updateTable,
    props.exclusiveFilters,
    props.permaFilters,
  ]);

  return (
    <div className="pagetable">
      {loading ? (
        <div>
          <p className="loadingtext">
            {props.loadingText != undefined
              ? props.loadingText
              : t("pagetable.loading")}
          </p>
        </div>
      ) : (
        <Accordion>
          <div>
            {props.hideFilter == undefined || props.hideFilter == true ? (
              ""
            ) : (
              <Accordion.Toggle
                className="clickable filterbtn"
                as={Button}
                eventKey="0"
              >
                <i className="dripicons-experiment" />
              </Accordion.Toggle>
            )}
            <Table striped hover={props.hover}>
              <thead>
                <tr>
                  {props.dataBefore != undefined ? <th></th> : null}
                  {showLabels != undefined ? (
                    showLabels.map((i, key) => (
                      <th
                        key={key}
                        onClick={() => {
                          handleSorting(i.field);
                        }}
                      >
                        {i.label}{" "}
                        {i.field == selectedHeaderField ? (
                          sortDirection == "asc" ? (
                            <i className="fa fa-sort-alpha-down"></i>
                          ) : (
                            <i className="fa fa-sort-alpha-down-alt"></i>
                          )
                        ) : (
                          ""
                        )}
                      </th>
                    ))
                  ) : (
                    <th />
                  )}
                  {props.dataAfter != undefined ? <th></th> : null}
                </tr>
                <tr>
                  {props.dataBefore != undefined &&
                  props.dataBefore.length > 0 ? (
                    <td className="headerfilter">
                      <div></div>
                    </td>
                  ) : null}
                  {showLabels != undefined && searchValueArray.length > 0 ? (
                    showLabels.map((i, key) => {
                      return (
                        <td key={key} className="headerfilter">
                          <Accordion.Collapse eventKey="0">
                            {i.customFilters == undefined ? (
                              <input
                                type="text"
                                placeholder={
                                  i.disableFilter == undefined
                                    ? t("pagetable.label_filter")
                                    : t("pagetable.label_filter_disabled")
                                }
                                disabled={i.disableFilter != undefined}
                                onChange={(event) =>
                                  debouncedSearch(event.target.value, i.field)
                                }
                              />
                            ) : (
                              <Field
                                type="select"
                                disabled={i.disableFilter != undefined}
                                onChange={(val) =>
                                  debouncedSearch(val.value, i.field)
                                }
                                options={{
                                  choosetext: t(
                                    "pagetable.label_filter_change"
                                  ),
                                  choices: i.customFilters,
                                }}
                              ></Field>
                            )}
                          </Accordion.Collapse>
                        </td>
                      );
                    })
                  ) : (
                    <td></td>
                  )}
                  {props.dataAfter != undefined ? (
                    <td className="headerfilter"></td>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {data.map((dataLine, key) => (
                  <tr
                    className={`${
                      props.hoverExempt != undefined
                        ? props.hoverExempt(dataLine) == true
                          ? "nohover"
                          : ""
                        : ""
                    } ${
                      !!props.selectedItems
                        ? props.selectedItems.filter(
                            (e) => e["_id"] === dataLine["_id"]
                          ).length
                          ? "active"
                          : ""
                        : ""
                    }`}
                    key={key}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      evt.nativeEvent.stopImmediatePropagation();
                      // if (props.onLineClick != undefined) {
                      //   props.onLineClick(dataLine);
                      // }
                    }}
                  >
                    {props.dataBefore != undefined ? (
                      <td>{props.dataBefore(dataLine)}</td>
                    ) : null}
                    {showLabels.map((label, labelkey) => (
                      <td
                        onClick={() => props.onLineClick(dataLine)}
                        className={props.complete ? "total" : ""}
                        key={labelkey}
                        onMouseOver={(evt) => {
                          if (
                            label.maxLength != undefined &&
                            evt.currentTarget.scrollWidth !=
                              evt.currentTarget.clientWidth
                          ) {
                            evt.currentTarget.classList.add(
                              "maxlengthhovering"
                            );
                          }
                        }}
                        onMouseOut={(evt) =>
                          label.maxLength != undefined
                            ? evt.currentTarget.classList.remove(
                                "maxlengthhovering"
                              )
                            : null
                        }
                        style={
                          label.maxLength != undefined
                            ? {
                                marginBottom: "0px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: label.maxLength + "px",
                              }
                            : {}
                        }
                      >
                        {label.override == undefined ? (
                          <p style={{ marginBottom: "0px" }}>
                            {dataLine[label.field]}
                          </p>
                        ) : (
                          label.override(dataLine)
                        )}
                      </td>
                    ))}
                    {props.dataAfter != undefined ? (
                      <td>{props.dataAfter(dataLine)}</td>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {!data.length ? (
            <p className="text-center">
              {props.noDataLabel || "No data available in the table"}
            </p>
          ) : props.hidePagination == undefined ||
            props.hidePagination == true ? (
            ""
          ) : (
            <div> {setPagination(pageData)} </div>
          )}
        </Accordion>
      )}
    </div>
  );
}

export default PageTable;
