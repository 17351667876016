const allSystemsActions = ["read", "edit", "create", "delete", "show_treatment_plan", "sign_treatment_plan", "copy", "edit_ownership", "request_access_private"];
const allSystemsSystemActions = ["read", "edit", "create", "delete", "show_treatment_plan", "sign_treatment_plan", "copy", "edit_ownership", "request_access_private"];
const allSystemsOrgrosActions = ["read", "edit", "create", "delete", "show_treatment_plan", "sign_treatment_plan", "copy", "edit_ownership", "request_access_private"];
const allSystemsOtherActions = ["read", "edit", "create", "delete", "show_treatment_plan", "sign_treatment_plan", "copy", "edit_ownership", "request_access_private"];
const allUsersActions = ["read", "edit", "create", "delete"];
const allUsersUsersActions = ["read", "edit", "create", "delete"];
const allUsersAccesscontrolActions = ["read", "edit", "create", "delete"];
const allTreatmentTreatmentActions = ["read", "edit", "create", "delete", "edit_treatment_without_owner", "edit_treatment_owner_and_sharing"];
const allTasksTasksActions = ["read", "edit", "create", "delete"];
const allSettingsSettingsActions = ["read", "edit", "create", "delete"];
const allOrganizationOrganizationActions = ["read", "edit", "create", "delete", /* "invite_users_to_organization", */ "generate_api_keys", "invite_azure_ad_tenants_to_organization", "view_as_different_organization", "change_own_organization_permanently"];
const allSurveySurveyActions = ["read", "edit", "create", "delete", "edit_ownership_and_sharing", "answer_survey"];

const changeAllRule = (c, evt, access, actionaccess, changeAllActionObjects, objType) => {
    switch(access){
        case "r":
            return c = {
                ...c,
                rules: [ 
                    { 
                        objId: "", 
                        objType: "system", 
                        objSubType: "system",
                        actions: objType == "system" ? {
                            read: evt == true ? "allow" : "",
                            edit: "",
                            create: evt == true ? c.rules[0].actions.create : "",
                            delete: evt == true ? c.rules[0].actions.delete : "",
                            sign_treatment_plan: "",
                            show_treatment_plan: evt == true ? "allow" : "",
                            copy: "",
                            edit_ownership: "",
                            request_access_private: "",
                        } : {...c.rules[0].actions}
                    },
                    { 
                        objId: "", 
                        objType: "system", 
                        objSubType: "orgros", 
                        actions: objType == "system" ? {
                            read: evt == true ? "allow" : "",
                            edit: "",
                            create: evt == true ? c.rules[1].actions.create : "",
                            delete: evt == true ? c.rules[1].actions.delete : "",
                            sign_treatment_plan: "",
                            show_treatment_plan: evt == true ? "allow" : "",
                            copy: "",
                            edit_ownership: "",
                            request_access_private: ""
                        } : {...c.rules[1].actions}
                    },
                    { 
                        objId: "", 
                        objType: "system", 
                        objSubType: "other", 
                        actions: objType == "system" ? {
                            read: evt == true ? "allow" : "",
                            edit: "",
                            create: evt == true ? c.rules[2].actions.create : "",
                            delete: evt == true ? c.rules[2].actions.delete : "",
                            sign_treatment_plan: "",
                            show_treatment_plan: evt == true ? "allow" : "",
                            copy: "",
                            edit_ownership: "",
                            request_access_private: ""
                        } : {...c.rules[2].actions}
                    },
                    { 
                        objId: "", 
                        objType: "users", 
                        objSubType: "users", 
                        actions: objType == "users" ? {
                            read: evt == true ? "allow" : "",
                            edit: "",
                            create: evt == true ? c.rules[3].actions.create : "",
                            delete: evt == true ? c.rules[3].actions.delete : "",
                        } : {...c.rules[3].actions}
                    },
                    { 
                        objId: "", 
                        objType: "users", 
                        objSubType: "accesscontrol", 
                        actions: objType == "users" ? {
                            read: evt == true ? "allow" : "",
                            edit: "",
                            create: evt == true ? c.rules[4].actions.create : "",
                            delete: evt == true ? c.rules[4].actions.delete : "",
                        } : {...c.rules[4].actions}
                    },
                    { 
                        objId: "", 
                        objType: "treatment", 
                        objSubType: "treatment", 
                        actions: objType == "treatment" ? {
                            read: evt == true ? "allow" : "",
                            edit: "",
                            create: evt == true ? (c.rules[5]?.actions.create ?? "") : "",
                            delete: evt == true ? (c.rules[5]?.actions.delete ?? "") : "",
                            edit_treatment_without_owner: "",
                            edit_treatment_owner_and_sharing: "",
                        } : {...c.rules[5].actions}
                    },
                    { 
                        objId: "", 
                        objType: "tasks", 
                        objSubType: "tasks", 
                        actions: objType == "tasks" ? {
                            read: evt == true ? "allow" : "",
                            edit: "",
                            create: evt == true ? (c.rules[6]?.actions.create ?? "") : "",
                            delete: evt == true ? (c.rules[6]?.actions.delete ?? "") : ""
                        } : {...c.rules[6].actions}
                    },
                    { 
                        objId: "", 
                        objType: "settings", 
                        objSubType: "settings", 
                        actions: objType == "settings" ? {
                            read: evt == true ? "allow" : "",
                            edit: "",
                            /* create: evt == true ? (c.rules[7]?.actions.create ?? "") : "",
                            delete: evt == true ? (c.rules[7]?.actions.delete ?? "") : "" */
                        } : {...c.rules[7].actions}
                    },
                    { 
                        objId: "", 
                        objType: "organization", 
                        objSubType: "organization", 
                        actions: objType == "organization" ? {
                            read: evt == true ? "allow" : "",
                            edit: "",
                            create: evt == true ? (c.rules[8]?.actions.create ?? "") : "",
                            delete: evt == true ? (c.rules[8]?.actions.delete ?? "") : "",
                            //invite_users_to_organization: "",
                            generate_api_keys: "",
                            invite_azure_ad_tenants_to_organization: "",
                            view_as_different_organization: "",
                            change_own_organization_permanently: "",
                            handle_payment: "",
                        } : {...c.rules[8].actions}
                    },
                    { 
                        objId: "", 
                        objType: "survey", 
                        objSubType: "survey", 
                        actions: objType == "survey" ? {
                            read: evt == true ? "allow" : "",
                            edit: "",
                            create: evt == true ? (c.rules[9]?.actions.create ?? "") : "",
                            delete: evt == true ? (c.rules[9]?.actions.delete ?? "") : "",
                            edit_ownership_and_sharing: "",
                            answer_survey: "",
                        } : {...c.rules[9].actions}
                    },
                ]
            }
        case "w":
            return c = {
                ...c,
                rules: [ 
                    { 
                        objId: "", 
                        objType: "system", 
                        objSubType: "system",
                        actions: objType == "system" ? {
                            read: evt == true ? "allow" : "allow" ,
                            edit: evt == true ? "allow" : "",
                            create: evt == true ? "allow" : "",
                            delete: evt == true ? "allow" : "",
                            sign_treatment_plan: evt == true ? "allow" : "",
                            show_treatment_plan: evt == true ? "allow" : "allow",
                            copy: evt == true ? "allow" : "",
                            edit_ownership: evt == true ? "allow" : "",
                            request_access_private: evt == true ? "allow" : "",
                        } : {...c.rules[0].actions}
                    },
                    { 
                        objId: "", 
                        objType: "system", 
                        objSubType: "orgros", 
                        actions: objType == "system" ? {
                            read: evt == true ? "allow" : "allow" ,
                            edit: evt == true ? "allow" : "",
                            create: evt == true ? "allow" : "",
                            delete: evt == true ? "allow" : "",
                            sign_treatment_plan: evt == true ? "allow" : "",
                            show_treatment_plan: evt == true ? "allow" : "allow",
                            copy: evt == true ? "allow" : "",
                            edit_ownership: evt == true ? "allow" : "",
                            request_access_private: evt == true ? "allow" : "",
                        } : {...c.rules[1].actions}
                    },
                    { 
                        objId: "", 
                        objType: "system", 
                        objSubType: "other", 
                        actions: objType == "system" ? {
                            read: evt == true ? "allow" : "allow",
                            edit: evt == true ? "allow" : "",
                            create: evt == true ? "allow" : "",
                            delete: evt == true ? "allow" : "",
                            sign_treatment_plan: evt == true ? "allow" : "",
                            show_treatment_plan: evt == true ? "allow" : "allow",
                            copy: evt == true ? "allow" : "",
                            edit_ownership: evt == true ? "allow" : "",
                            request_access_private: evt == true ? "allow" : "",
                        } : {...c.rules[2].actions}
                    },
                    { 
                        objId: "", 
                        objType: "users", 
                        objSubType: "users", 
                        actions: objType == "users" ? {
                            read: evt == true && objType == "users"  ? "allow" : "allow",
                            edit: evt == true && objType == "users"  ? "allow" : "",
                            create: evt == true && objType == "users" ? "allow" : "",
                            delete: evt == true && objType == "users" ? "allow" : "",
                        } : {...c.rules[3].actions}
                    },
                    { 
                        objId: "", 
                        objType: "users", 
                        objSubType: "accesscontrol", 
                        actions: objType == "users" ? {
                            read: evt == true && objType == "users"  ? "allow" : "allow",
                            edit: evt == true && objType == "users"  ? "allow" : "",
                            create: evt == true && objType == "users" ? "allow" : "",
                            delete: evt == true && objType == "users" ? "allow" : "",
                        } : {...c.rules[4].actions}
                    },
                    { 
                        objId: "", 
                        objType: "treatment", 
                        objSubType: "treatment", 
                        actions: objType == "treatment" ? {
                            read: evt == true ? "allow" : "allow",
                            edit: evt == true ? "allow" : "",
                            create: evt == true ? "allow" : "",
                            delete: evt == true ? "allow" : "",
                            edit_treatment_without_owner: evt == true ? "allow" : "",
                            edit_treatment_owner_and_sharing: evt == true ? "allow" : "",
                        } : {...c.rules[5].actions}
                    },
                    { 
                        objId: "", 
                        objType: "tasks", 
                        objSubType: "tasks", 
                        actions: objType == "tasks" ? {
                            read: evt == true ? "allow" : "allow",
                            edit: evt == true ? "allow" : "",
                            create: evt == true ? "allow" : "",
                            delete: evt == true ? "allow" : ""
                        } : {...c.rules[6].actions}
                    },
                    { 
                        objId: "", 
                        objType: "settings", 
                        objSubType: "settings", 
                        actions: objType == "settings" ? {
                            read: evt == true ? "allow" : "allow",
                            edit: evt == true ? "allow" : "",
                            /* create: evt == true ? "allow" : "",
                            delete: evt == true ? "allow" : "" */
                        } : {...c.rules[7].actions}
                    },
                    { 
                        objId: "", 
                        objType: "organization", 
                        objSubType: "organization", 
                        actions: objType == "organization" ? {
                            read: evt == true ? "allow" : "allow",
                            edit: evt == true ? "allow" : "",
                            create: evt == true ? "allow" : "",
                            delete: evt == true ? "allow" : "",
                            //invite_users_to_organization: evt == true ? "allow" : "",
                            generate_api_keys: evt == true ? "allow" : "",
                            invite_azure_ad_tenants_to_organization: evt == true ? "allow" : "",
                            view_as_different_organization: evt == true ? "allow" : "",
                            change_own_organization_permanently: evt == true ? "allow" : "",
                            handle_payment: evt == true ? "allow" : "",
                        } : {...c.rules[8].actions}
                    },
                    { 
                        objId: "", 
                        objType: "survey", 
                        objSubType: "survey", 
                        actions: objType == "survey" ? {
                            read: evt == true ? "allow" : "allow",
                            edit: evt == true ? "allow" : "",
                            create: evt == true ? "allow" : "",
                            delete: evt == true ? "allow" : "",
                            edit_ownership_and_sharing: evt == true ? "allow" : "",
                            answer_survey: evt == true ? "allow" : "",
                        } : {...c.rules[9].actions}
                    },
                ]
            }
        default:
            return c = { 
                ...c,
                rules: [ 
                    { 
                        objId: "", 
                        objType: "system", 
                        objSubType: "system",
                        actions: changeAllActionObjects(allSystemsActions, evt, actionaccess)
                    },
                    { 
                        objId: "", 
                        objType: "system", 
                        objSubType: "orgros", 
                        actions: changeAllActionObjects(allSystemsActions, evt, actionaccess)
                    },
                    { 
                        objId: "", 
                        objType: "system", 
                        objSubType: "other", 
                        actions: changeAllActionObjects(allSystemsActions, evt, actionaccess)
                    }
                ]
            }
    }
}

const changeAllActionsRule = (objType, objSubType, access, rule, evt, changeAllActionObjects, subactions, actionaccess) => {
    switch(objType){
        case "system":
            switch(objSubType){
                case "system":
                    switch(access){
                        case "r":
                            return {
                                ...rule, 
                                actions: {
                                    read: evt == true ? "allow" : "",
                                    edit: "",
                                    create: evt == true ? rule.actions.create : "",
                                    delete: evt == true ? rule.actions.delete : "",
                                    show_treatment_plan: evt == true ? "allow" : "",
                                    sign_treatment_plan: "",
                                    copy: "",
                                    edit_ownership: "",
                                    request_access_private: "",
                                }
                            }
                        case "w":
                            return {
                                ...rule, 
                                actions: evt == true ? 
                                    subactions == undefined || subactions.length == 0 ? 
                                        changeAllActionObjects(allSystemsSystemActions, evt, "allow") 
                                    : 
                                        Object.assign({}, rule.actions, changeAllActionObjects(subactions, evt, "allow"))
                                : subactions == undefined || subactions.length == 0 ? 
                                    {
                                        read: "allow",
                                        edit: "",
                                        create: "",
                                        delete:"",
                                        show_treatment_plan: "allow",
                                        sign_treatment_plan:"",
                                        copy:"",
                                        edit_ownership: "",
                                        request_access_private: "",
                                    }
                                : 
                                    Object.assign({}, rule.actions, changeAllActionObjects(subactions, evt, actionaccess))
                            }
                        default:
                            rule = {
                                ...rule, 
                                actions: changeAllActionObjects(allSystemsSystemActions, evt, "allow")
                            }
                            return rule
                    }
                case "orgros":
                    switch(access){
                        case "r":
                            return rule = {
                                ...rule, 
                                actions: {
                                    read: evt == true ? "allow" : "",
                                    edit: "",
                                    create: evt == true ? rule.actions.create : "",
                                    delete: evt == true ? rule.actions.delete : "",
                                    show_treatment_plan: evt == true ? "allow" : "",
                                    sign_treatment_plan: "",
                                    copy: "",
                                    edit_ownership: "",
                                    request_access_private: "",
                                }
                            }
                        case "w":
                            return rule = {
                                ...rule, 
                                actions: evt == true ? 
                                    subactions == undefined || subactions.length == 0 ? 
                                        changeAllActionObjects(allSystemsOrgrosActions, evt, "allow") 
                                    : 
                                        Object.assign({}, rule.actions, changeAllActionObjects(subactions, evt, "allow"))
                                : subactions == undefined || subactions.length == 0 ? 
                                    {
                                        read: "allow",
                                        edit: "",
                                        create: "",
                                        delete:"",
                                        show_treatment_plan: "allow",
                                        sign_treatment_plan:"",
                                        copy:"",
                                        edit_ownership: "",
                                        request_access_private: "",
                                    }
                                : 
                                    Object.assign({}, rule.actions, changeAllActionObjects(subactions, evt, actionaccess))
                            }
                        default:
                            return rule = {
                                ...rule, 
                                actions: subactions == undefined || subactions.length == 0 ? changeAllActionObjects(allSystemsOrgrosActions, evt, actionaccess) : changeAllActionObjects(subactions, evt, actionaccess) 
                            }
                    }
                case "other":
                    switch(access){
                        case "r":
                            return rule = {
                                ...rule, 
                                actions: {
                                    read: evt == true ? "allow" : "",
                                    edit: "",
                                    create: evt == true ? rule.actions.create : "",
                                    delete: evt == true ? rule.actions.delete : "",
                                    show_treatment_plan: evt == true ? "allow" : "",
                                    sign_treatment_plan: "",
                                    copy: "",
                                    edit_ownership: "",
                                    request_access_private: "",
                                }
                            }
                        case "w":
                            return rule = {
                                ...rule,
                                actions: evt == true ? 
                                    subactions == undefined || subactions.length == 0 ? 
                                        changeAllActionObjects(allSystemsOtherActions, evt, "allow") 
                                    : 
                                        Object.assign({}, rule.actions, changeAllActionObjects(subactions, evt, "allow"))
                                : subactions == undefined || subactions.length == 0 ? 
                                    {
                                        read: "allow",
                                        edit: "",
                                        create: "",
                                        delete:"",
                                        show_treatment_plan: "allow",
                                        sign_treatment_plan:"",
                                        copy:"",
                                        edit_ownership: "",
                                        request_access_private: "",
                                    }
                                : 
                                    Object.assign({}, rule.actions, changeAllActionObjects(subactions, evt, actionaccess))
                            }
                        default: 
                            return rule = {
                                ...rule, 
                                actions: changeAllActionObjects(allSystemsOtherActions, evt, actionaccess)
                            }
                    }
            }
        case "users":
            switch(objSubType){    
                case "users":
                    switch(access){
                        case "r":
                            return rule = {
                                ...rule, 
                                actions: {
                                    read: evt == true ? "allow" : "",
                                    edit: "",
                                    create: evt == true ? rule.actions.create : "",
                                    delete: evt == true ? rule.actions.delete : "",
                                }
                            }
                        case "w":
                            return rule = {
                                ...rule,
                                actions: {
                                    read: evt == true ? "allow" : "allow",
                                    edit: evt == true ? "allow" : "",
                                    create: evt == true ? "allow" : "",
                                    delete: evt == true ? "allow" : "",
                                }
                            }
                        default: 
                            return rule = {
                                ...rule, 
                                actions: changeAllActionObjects(allUsersUsersActions, evt, actionaccess)
                            }
                    }
                case "accesscontrol":
                    switch(access){
                        case "r":
                            return rule = {
                                ...rule, 
                                actions: {
                                    read: evt == true ? "allow" : "",
                                    edit: "",
                                    create: evt == true ? rule.actions.create : "",
                                    delete: evt == true ? rule.actions.delete : "",
                                }
                            }
                        case "w":
                            return rule = {
                                ...rule,
                                actions: {
                                    read: evt == true ? "allow" : "allow",
                                    edit: evt == true ? "allow" : "",
                                    create: evt == true ? "allow" : "",
                                    delete: evt == true ? "allow" : "",
                                }
                            }
                        default: 
                            return rule = {
                                ...rule, 
                                actions: changeAllActionObjects(allUsersAccesscontrolActions, evt, actionaccess)
                            }
                    }
                default:
                    return rule = {
                        ...rule, 
                        actions: subactions == undefined || subactions.length == 0 ? changeAllActionObjects(allSystemsActions, evt, actionaccess) : Object.assign({}, rule.actions, changeAllActionObjects(subactions, evt, actionaccess))
                    }
            }
        case "treatment":
            switch(objSubType){
                case "treatment":
                    switch(access){
                        case "r":
                            return rule = {
                                ...rule, 
                                actions: {
                                    read: evt == true ? "allow" : "",
                                    edit: "",
                                    create: evt == true ? rule.actions.create : "",
                                    delete: evt == true ? rule.actions.delete : "",
                                    edit_treatment_without_owner: "",
                                    edit_treatment_owner_and_sharing: "",
                                }
                            }
                        case "w":
                            return rule = {
                                ...rule,
                                actions: {
                                    read: evt == true ? "allow" : "allow",
                                    edit: evt == true ? "allow" : "",
                                    create: evt == true ? "allow" : "",
                                    delete: evt == true ? "allow" : "",
                                    edit_treatment_without_owner: evt == true ? "allow" : "",
                                    edit_treatment_owner_and_sharing: evt == true ? "allow" : "",
                                }
                            }
                        default: 
                            return rule = {
                                ...rule, 
                                actions: changeAllActionObjects(allTreatmentTreatmentActions, evt, actionaccess)
                            }
                    } 
            }
        case "tasks":
            switch(objSubType){
                case "tasks":
                    switch(access){
                        case "r":
                            return rule = {
                                ...rule, 
                                actions: {
                                    read: evt == true ? "allow" : "",
                                    edit: "",
                                    create: evt == true ? rule.actions.create : "",
                                    delete: evt == true ? rule.actions.delete : ""
                                }
                            }
                        case "w":
                            return rule = {
                                ...rule,
                                actions: {
                                    read: evt == true ? "allow" : "allow",
                                    edit: evt == true ? "allow" : "",
                                    create: evt == true ? "allow" : "",
                                    delete: evt == true ? "allow" : ""
                                }
                            }
                        default: 
                            return rule = {
                                ...rule, 
                                actions: changeAllActionObjects(allTasksTasksActions, evt, actionaccess)
                            }
                    } 
            }
        case "settings":
            switch(objSubType){
                case "settings":
                    switch(access){
                        case "r":
                            return rule = {
                                ...rule, 
                                actions: {
                                    read: evt == true ? "allow" : "",
                                    edit: "",
                                    /* create: evt == true ? rule.actions.create : "",
                                    delete: evt == true ? rule.actions.delete : "" */
                                }
                            }
                        case "w":
                            return rule = {
                                ...rule,
                                actions: {
                                    read: evt == true ? "allow" : "allow",
                                    edit: evt == true ? "allow" : "",
                                    /* create: evt == true ? "allow" : "",
                                    delete: evt == true ? "allow" : "" */
                                }
                            }
                        default: 
                            return rule = {
                                ...rule, 
                                actions: changeAllActionObjects(allSettingsSettingsActions, evt, actionaccess)
                            }
                    } 
            }
        case "organization":
            switch(objSubType){
                case "organization":
                    switch(access){
                        case "r":
                            return rule = {
                                ...rule, 
                                actions: {
                                    read: evt == true ? "allow" : "",
                                    edit: "",
                                    create: evt == true ? rule.actions.create : "",
                                    delete: evt == true ? rule.actions.delete : "",
                                    //invite_users_to_organization: "",
                                    generate_api_keys: "",
                                    invite_azure_ad_tenants_to_organization: "",
                                    view_as_different_organization: "",
                                    change_own_organization_permanently: "",
                                    handle_payment: ""
                                }
                            }
                        case "w":
                            return rule = {
                                ...rule,
                                actions: {
                                    read: evt == true ? "allow" : "allow",
                                    edit: evt == true ? "allow" : "",
                                    create: evt == true ? "allow" : "",
                                    delete: evt == true ? "allow" : "",
                                    //invite_users_to_organization: evt == true ? "allow" : "",
                                    generate_api_keys: evt == true ? "allow" : "",
                                    invite_azure_ad_tenants_to_organization: evt == true ? "allow" : "",
                                    view_as_different_organization: evt == true ? "allow" : "",
                                    change_own_organization_permanently: evt == true ? "allow" : "",
                                    handle_payment: evt == true ? "allow" : "",
                                }
                            }
                        default: 
                            return rule = {
                                ...rule, 
                                actions: changeAllActionObjects(allOrganizationOrganizationActions, evt, actionaccess)
                            }
                    } 
            }
        case "survey":
            switch(objSubType){
                case "survey":
                    switch(access){
                        case "r":
                            return rule = {
                                ...rule, 
                                actions: {
                                    read: evt == true ? "allow" : "",
                                    edit: "",
                                    create: evt == true ? rule.actions.create : "",
                                    delete: evt == true ? rule.actions.delete : "",
                                    edit_ownership_and_sharing: "",
                                    answer_survey: "",
                                }
                            }
                        case "w":
                            return rule = {
                                ...rule,
                                actions: {
                                    read: evt == true ? "allow" : "allow",
                                    edit: evt == true ? "allow" : "",
                                    create: evt == true ? "allow" : "",
                                    delete: evt == true ? "allow" : "",
                                    edit_ownership_and_sharing: evt == true ? "allow" : "",
                                    answer_survey: evt == true ? "allow" : "",
                                }
                            }
                        default: 
                            return rule = {
                                ...rule, 
                                actions: changeAllActionObjects(allSurveySurveyActions, evt, actionaccess)
                            }
                    } 
            }
    }
}

const changeSingleActionRule = (objType, objSubType, c, evt, actionaccess, chosenAction) => {
    switch(objType){
        case "system":
            switch(objSubType){
                case "system":
                    return {
                        ...c, 
                        rules: c.rules.map(rule => { 
                            if(rule.objSubType == objSubType) { 
                                rule = {
                                    ...rule,
                                    actions: {
                                        ...rule.actions, 
                                        [chosenAction]: evt == true ? actionaccess : ""
                                    }
                                }
                                return rule;
                            } else { 
                                return rule
                            } 
                        }) 
                    } 
                case "orgros":
                    return {
                        ...c, 
                        rules: c.rules.map(rule => { 
                            if(rule.objSubType == objSubType) { 
                                rule = {
                                    ...rule, 
                                    actions: {
                                        ...rule.actions, 
                                        [chosenAction]: evt == true ? actionaccess : ""
                                    }
                                }
                                return rule;
                            } else { 
                                return rule
                            } 
                        }) 
                    } 
                case "other":
                    return {
                        ...c, 
                        rules: c.rules.map(rule => { 
                            if(rule.objSubType == objSubType) { 
                                rule = {
                                    ...rule, 
                                    actions: {
                                        ...rule.actions, 
                                        [chosenAction]: evt == true ? actionaccess : ""
                                    }
                                }
                                return rule;
                            } else { 
                                return rule
                            } 
                        }) 
                    } 
            }
        case "users":
            switch(objSubType){
                case "users":
                    return {
                        ...c, 
                        rules: c.rules.map(rule => { 
                            if(rule.objSubType == objSubType) { 
                                rule = {
                                    ...rule, 
                                    actions: {
                                        ...rule.actions, 
                                        [chosenAction]: evt == true ? actionaccess : ""
                                    }
                                }
                                return rule;
                            } else { 
                                return rule
                            } 
                        }) 
                    } 
                case "accesscontrol":
                    return {
                        ...c, 
                        rules: c.rules.map(rule => { 
                            if(rule.objSubType == objSubType) { 
                                rule = {
                                    ...rule, 
                                    actions: {
                                        ...rule.actions, 
                                        [chosenAction]: evt == true ? actionaccess : ""
                                    }
                                }
                                return rule;
                            } else { 
                                return rule
                            } 
                        }) 
                    } 
            }
            case "treatment":
                switch(objSubType){
                    case "treatment":
                        return {
                            ...c, 
                            rules: c.rules.map(rule => { 
                                if(rule.objSubType == objSubType) { 
                                    rule = {
                                        ...rule, 
                                        actions: {
                                            ...rule.actions, 
                                            [chosenAction]: evt == true ? actionaccess : ""
                                        }
                                    }
                                    return rule;
                                } else { 
                                    return rule
                                } 
                            }) 
                        } 
                }
            case "tasks":
                switch(objSubType){
                    case "tasks":
                        return {
                            ...c, 
                            rules: c.rules.map(rule => { 
                                if(rule.objSubType == objSubType) { 
                                    rule = {
                                        ...rule, 
                                        actions: {
                                            ...rule.actions, 
                                            [chosenAction]: evt == true ? actionaccess : ""
                                        }
                                    }
                                    return rule;
                                } else { 
                                    return rule
                                } 
                            }) 
                        } 
                }
            case "settings":
                switch(objSubType){
                    case "settings":
                        return {
                            ...c, 
                            rules: c.rules.map(rule => { 
                                if(rule.objSubType == objSubType) { 
                                    rule = {
                                        ...rule, 
                                        actions: {
                                            ...rule.actions, 
                                            [chosenAction]: evt == true ? actionaccess : ""
                                        }
                                    }
                                    return rule;
                                } else { 
                                    return rule
                                } 
                            }) 
                        } 
                }
            case "organization":
                switch(objSubType){
                    case "organization":
                        return {
                            ...c, 
                            rules: c.rules.map(rule => { 
                                if(rule.objSubType == objSubType) { 
                                    rule = {
                                        ...rule, 
                                        actions: {
                                            ...rule.actions, 
                                            [chosenAction]: evt == true ? actionaccess : ""
                                        }
                                    }
                                    return rule;
                                } else { 
                                    return rule
                                } 
                            }) 
                        } 
                }
            case "survey":
                switch(objSubType){
                    case "survey":
                        return {
                            ...c, 
                            rules: c.rules.map(rule => { 
                                if(rule.objSubType == objSubType) { 
                                    rule = {
                                        ...rule, 
                                        actions: {
                                            ...rule.actions, 
                                            [chosenAction]: evt == true ? actionaccess : ""
                                        }
                                    }
                                    return rule;
                                } else { 
                                    return rule
                                } 
                            }) 
                        } 
                }

        default: 
            return {
                ...c, 
                rules: c.rules.map(rule => { 
                    if(rule.objSubType == objSubType) { 
                        rule = {
                            ...rule, 
                            actions: {
                                ...rule.actions, 
                                [chosenAction]: evt == true ? actionaccess : ""
                            }
                        }
                        return rule;
                    } else { 
                        return rule
                    } 
                }) 
            } 

    }
    
        
}


const isAllActionsCheckedRule = (checked, role, objType, access, objSubType, actionaccess, isSingleChecked, isSingleActionChecked) => {
    switch(objType){
        case "system":
            switch(objSubType){
                case "system":
                    switch(access){
                        case "r": {
                            if(isSingleActionChecked(role, objType, "system", "read", "allow")){
                                return [ isSingleActionChecked(role, objType, "system", "read", "allow") ]
                            } else {
                                return [
                                    isSingleActionChecked(role, objType, "system", "read", "allow"),
                                    isSingleActionChecked(role, objType, "system", "show_treatment_plan", "allow") 
                                ]
                            }
                        }
                        case "w": {
                            if(isSingleActionChecked(role, objType, "system", "edit", "allow")){
                                return [
                                    isSingleActionChecked(role, objType, "system", "edit", "allow"),
                                ]
                            } else {
                                return [
                                    isSingleActionChecked(role, objType, "system", "edit", "allow"),
                                    isSingleActionChecked(role, objType, "system", "sign_treatment_plan", "allow"),
                                    isSingleActionChecked(role, objType, "system", "copy", "allow"),
                                    isSingleActionChecked(role, objType, "system", "edit_ownership", "allow"),
                                    isSingleActionChecked(role, objType, "system", "request_access_private", "allow"),
                                ]
                            }
                            
                        }
                    }
                case "orgros":
                    switch(access){
                        case "r": {
                            if(isSingleActionChecked(role, objType, "orgros", "read", "allow")){
                                return [ isSingleActionChecked(role, objType, "orgros", "read", "allow") ]
                            } else {
                                return [
                                    //isSingleChecked(role, objType, access, "orgros"),
                                    isSingleActionChecked(role, objType, "orgros", "read", "allow"),
                                    isSingleActionChecked(role, objType, "orgros", "show_treatment_plan", "allow") 
                                ]
                            }
                            
                        }
                        case "w": {
                            if(isSingleActionChecked(role, objType, "orgros", "edit", "allow")){
                                return [ isSingleActionChecked(role, objType, "orgros", "edit", "allow") ]
                            } else {
                                return [
                                    isSingleActionChecked(role, objType, "orgros", "edit", "allow"),
                                    isSingleActionChecked(role, objType, "orgros", "sign_treatment_plan", "allow"),
                                    isSingleActionChecked(role, objType, "orgros", "copy", "allow"),
                                    isSingleActionChecked(role, objType, "orgros", "edit_ownership", "allow"),
                                    isSingleActionChecked(role, objType, "orgros", "request_access_private", "allow"),
                                    //isSingleChecked(role, objType, access, "orgros"),
                                    //isSingleActionChecked(role, objType, "orgros", "create", "allow"),
                                    //isSingleActionChecked(role, objType, "orgros", "delete", "allow"),
                                    //(isSingleChecked(role, objType, access, "orgros") && isSingleActionChecked(role, objType, "orgros", "show_treatment_plan", "allow")),
                                ]
                            }
                            
                        }
                    }
                case "other":
                    switch(access){
                        case "r": {
                            if(isSingleActionChecked(role, objType, "other", "read", "allow")){
                                return [ isSingleActionChecked(role, objType, "other", "read", "allow") ]
                            } else {
                                return [
                                    isSingleActionChecked(role, objType, "other", "read", "allow"),
                                    isSingleActionChecked(role, objType, "other", "show_treatment_plan", "allow") 
                                ]
                            }
                        }
                        case "w": {
                            if(isSingleActionChecked(role, objType, "other", "edit", "allow")){
                                return [
                                    isSingleActionChecked(role, objType, "other", "edit", "allow"),
                                ]
                            } else {
                                return [
                                    isSingleActionChecked(role, objType, "other", "edit", "allow"),
                                    isSingleActionChecked(role, objType, "other", "sign_treatment_plan", "allow"),
                                    isSingleActionChecked(role, objType, "other", "copy", "allow"),
                                    isSingleActionChecked(role, objType, "other", "edit_ownership", "allow"),
                                    isSingleActionChecked(role, objType, "other", "request_access_private", "allow"),
                                ]
                            }
                            
                        }
                    }
            }
            case "users":
                switch(objSubType){
                    case "users":
                        switch(access){
                            case "r": {
                                return [ 
                                    isSingleActionChecked(role, objType, "users", "read", "allow"),
                                ]
                            }
                            case "w": {
                                if(isSingleActionChecked(role, objType, "users", "edit", "allow")){
                                    return [
                                        isSingleActionChecked(role, objType, "users", "edit", "allow"),
                                    ]
                                } else {
                                    return [
                                        isSingleActionChecked(role, objType, "users", "edit", "allow"),
                                    ]
                                }
                                
                            }
                        }
                    case "accesscontrol":
                        switch(access){
                            case "r": {
                                if(isSingleActionChecked(role, objType, "accesscontrol", "read", "allow")){
                                    return [ isSingleActionChecked(role, objType, "accesscontrol", "read", "allow") ]
                                } else {
                                    return [
                                        isSingleActionChecked(role, objType, "accesscontrol", "read", "allow"),
                                    ]
                                }
                                
                            }
                            case "w": {
                                if(isSingleActionChecked(role, objType, "accesscontrol", "edit", "allow")){
                                    return [ isSingleActionChecked(role, objType, "accesscontrol", "edit", "allow") ]
                                } else {
                                    return [
                                        isSingleActionChecked(role, objType, "accesscontrol", "edit", "allow"),
                                    ]
                                }
                                
                            }
                        }
                }
            case "treatment":
                switch(objSubType){
                    case "treatment":
                        switch(access){
                            case "r": {
                                return [ 
                                    isSingleActionChecked(role, objType, objSubType, "read", "allow"),
                                ]
                            }
                            case "w": {
                                if(isSingleActionChecked(role, objType, objSubType, "edit", "allow")){
                                    return [
                                        isSingleActionChecked(role, objType, objSubType, "edit", "allow"),
                                    ]
                                } else {
                                    return [
                                        isSingleActionChecked(role, objType, objSubType, "edit", "allow"),
                                        isSingleActionChecked(role, objType, objSubType, "edit_treatment_without_owner", "allow"),
                                        isSingleActionChecked(role, objType, objSubType, "edit_treatment_owner_and_sharing", "allow"),
                                    ]
                                }
                                
                            }
                        }
                }
            case "tasks":
                switch(objSubType){
                    case "tasks":
                        switch(access){
                            case "r": {
                                return [ 
                                    isSingleActionChecked(role, objType, objSubType, "read", "allow"),
                                ]
                            }
                            case "w": {
                                if(isSingleActionChecked(role, objType, objSubType, "edit", "allow")){
                                    return [
                                        isSingleActionChecked(role, objType, objSubType, "edit", "allow"),
                                    ]
                                } else {
                                    return [
                                        isSingleActionChecked(role, objType, objSubType, "edit", "allow")
                                    ]
                                }
                                
                            }
                        }
                }
            case "settings":
                switch(objSubType){
                    case "settings":
                        switch(access){
                            case "r": {
                                return [ 
                                    isSingleActionChecked(role, objType, objSubType, "read", "allow"),
                                ]
                            }
                            case "w": {
                                if(isSingleActionChecked(role, objType, objSubType, "edit", "allow")){
                                    return [
                                        isSingleActionChecked(role, objType, objSubType, "edit", "allow"),
                                    ]
                                } else {
                                    return [
                                        isSingleActionChecked(role, objType, objSubType, "edit", "allow")
                                    ]
                                }
                                
                            }
                        }
                }
            case "organization":
                switch(objSubType){
                    case "organization":
                        switch(access){
                            case "r": {
                                return [ 
                                    isSingleActionChecked(role, objType, objSubType, "read", "allow"),
                                ]
                            }
                            case "w": {
                                if(isSingleActionChecked(role, objType, objSubType, "edit", "allow")){
                                    return [
                                        isSingleActionChecked(role, objType, objSubType, "edit", "allow"),
                                    ]
                                } else {
                                    return [
                                        isSingleActionChecked(role, objType, objSubType, "edit", "allow"),
                                        //isSingleActionChecked(role, objType, objSubType, "invite_users_to_organization", "allow"),
                                        isSingleActionChecked(role, objType, objSubType, "generate_api_keys", "allow"),
                                        isSingleActionChecked(role, objType, objSubType, "invite_azure_ad_tenants_to_organization", "allow"),
                                        isSingleActionChecked(role, objType, objSubType, "view_as_different_organization", "allow"),
                                        isSingleActionChecked(role, objType, objSubType, "change_own_organization_permanently", "allow"),
                                        isSingleActionChecked(role, objType, objSubType, "handle_payment", "allow"),
                                    ]
                                }
                                
                            }
                        }
                }
            case "survey":
                switch(objSubType){
                    case "survey":
                        switch(access){
                            case "r": {
                                return [ 
                                    isSingleActionChecked(role, objType, objSubType, "read", "allow"),
                                ]
                            }
                            case "w": {
                                if(isSingleActionChecked(role, objType, objSubType, "edit", "allow")){
                                    return [
                                        isSingleActionChecked(role, objType, objSubType, "edit", "allow"),
                                    ]
                                } else {
                                    return [
                                        isSingleActionChecked(role, objType, objSubType, "edit", "allow"),
                                        isSingleActionChecked(role, objType, objSubType, "edit_ownership_and_sharing", "allow"),
                                        isSingleActionChecked(role, objType, objSubType, "answer_survey", "allow"),
                                    ]
                                }
                                
                            }
                        }
                }
            
        default:
            return Object.values(checked.find(checked => 
                checked.name == role)?.rules.find(rule => 
                    rule.objType == objType && rule.objSubType == objSubType)?.actions ?? [false]).map(action =>
                         action == actionaccess) ?? [false] 
    }
}

const isAllCheckedRule = (checked, role, objType, access, actionaccess, isSingleActionChecked, isAllActionsChecked) => {
    switch(objType){
        case "system":
            switch(access){
                case "r": {
                    let actionsArray = [] as any;
                    /* let foundRules = checked.find(checked => checked?.name == role)?.rules.filter(rule => rule.objType == objType);
                    actionsArray.push(isSingleActionChecked(role, objType, "orgros", "show_treatment_plan", actionaccess))
                    return foundRules?.map(rule => rule.access == access || rule.access == "w").concat(actionsArray) ?? [false]; */
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "system", actionaccess, "", isSingleActionChecked))
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "orgros", actionaccess, "", isSingleActionChecked))
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "other", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "w": {

                    let actionsArray = [] as any;
                    /* let foundRules = checked.find(checked => checked?.name == role)?.rules.filter(rule => rule.objType == objType);
                    let foundRulesWithActions = checked.find(checked => checked?.name == role)?.rules.filter(rule => rule.objType == objType && rule.actions != undefined && Object.keys(rule.actions).length != 0)
                    foundRulesWithActions?.map(rule => isAllActionsChecked(role, objType, access, rule.objSubType, actionaccess)?.map(item => actionsArray.push(item)))
                    return foundRules?.map(rule => rule.access == access).concat(actionsArray) ?? [false] */
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "system", actionaccess, "", isSingleActionChecked))
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "orgros", actionaccess, "", isSingleActionChecked))
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "other", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "d": {
                    let foundRules = checked.find(checked => checked?.name == role)?.rules.filter(rule => rule.objType == objType);
                    return foundRules?.map(rule => rule.access == access) ?? [false]
                }      
            }
        case "users":
            switch(access){
                case "r": {
                    let actionsArray = [] as any;
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "users", actionaccess, "", isSingleActionChecked))
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "accesscontrol", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "w": {
                    let actionsArray = [] as any;
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "users", actionaccess, "", isSingleActionChecked))
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "accesscontrol", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "d": {
                    let foundRules = checked.find(checked => checked?.name == role)?.rules.filter(rule => rule.objType == objType);
                    return foundRules?.map(rule => rule.access == access) ?? [false]
                }      
            }
        case "treatment":
            switch(access){
                case "r": {
                    let actionsArray = [] as any;
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "treatment", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "w": {
                    let actionsArray = [] as any;
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "treatment", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "d": {
                    let foundRules = checked.find(checked => checked?.name == role)?.rules.filter(rule => rule.objType == objType);
                    return foundRules?.map(rule => rule.access == access) ?? [false]
                }      
            }
        case "tasks":
            switch(access){
                case "r": {
                    let actionsArray = [] as any;
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "tasks", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "w": {
                    let actionsArray = [] as any;
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "tasks", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "d": {
                    let foundRules = checked.find(checked => checked?.name == role)?.rules.filter(rule => rule.objType == objType);
                    return foundRules?.map(rule => rule.access == access) ?? [false]
                }      
            }
        case "settings":
            switch(access){
                case "r": {
                    let actionsArray = [] as any;
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "settings", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "w": {
                    let actionsArray = [] as any;
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "settings", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "d": {
                    let foundRules = checked.find(checked => checked?.name == role)?.rules.filter(rule => rule.objType == objType);
                    return foundRules?.map(rule => rule.access == access) ?? [false]
                }      
            }
        case "organization":
            switch(access){
                case "r": {
                    let actionsArray = [] as any;
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "organization", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "w": {
                    let actionsArray = [] as any;
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "organization", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "d": {
                    let foundRules = checked.find(checked => checked?.name == role)?.rules.filter(rule => rule.objType == objType);
                    return foundRules?.map(rule => rule.access == access) ?? [false]
                }      
            }
        case "survey":
            switch(access){
                case "r": {
                    let actionsArray = [] as any;
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "survey", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "w": {
                    let actionsArray = [] as any;
                    actionsArray = actionsArray.concat(isAllActionsCheckedRule(checked, role, objType, access, "survey", actionaccess, "", isSingleActionChecked))
                    return actionsArray;
                }
                case "d": {
                    let foundRules = checked.find(checked => checked?.name == role)?.rules.filter(rule => rule.objType == objType);
                    return foundRules?.map(rule => rule.access == access) ?? [false]
                }      
            }
            
        default: {
            let actionsArray = [] as any;
            let foundRules = checked.find(checked => checked?.name == role)?.rules.filter(rule => rule.objType == objType);
            let foundRulesWithActions = checked.find(checked => checked?.name == role)?.rules.filter(rule => rule.objType == objType && rule.actions != undefined && Object.keys(rule.actions).length != 0)
            foundRulesWithActions?.map(rule => isAllActionsChecked(role, objType, access, rule.objSubType, actionaccess)?.map(item => actionsArray.push(item)))
            return foundRules?.map(rule => rule.access == "w" ? rule.access == access || access == "r" : rule.access == access).concat(actionsArray) ?? [false] 
        }
    }
}

export {
    changeAllRule,
    changeAllActionsRule,
    changeSingleActionRule,
    isAllActionsCheckedRule,
    isAllCheckedRule
}