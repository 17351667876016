import * as React from "react";

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { Tooltip } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { useTranslate } from "react-polyglot";

import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";

import CRAlogo from "../../../Assets/Images/diri-logo.svg";
import Field from "../Wizard/Field/Field";
import VersionNumber from "../VersionNumber/VersionNumber";

import { generateUrl } from "../../../config";

import packageJson from "../../../../package.json";

import "../../../Assets/Dripicons/webfont/webfont.css";
import "./MainMenu.scss";

const nomenu = ["/login", "/newuser", "/shop", "/loggingin", "/welcome"];

function MainMenu(props) {
  const auth = useAuth();
  const t = useTranslate();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  const history = useHistory();
  const location = useLocation();

  const [username, setUsername] = useState("");
  const [userunit, setUserunit] = useState([]);
  const [userunits, setUserunits] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [menuopen, setMenuopen] = useState(
    document.documentElement.clientWidth < 1100 ? false : true
  );
  const [userrole, setUserrole] = useState("");

  const [loading, setLoading] = useState(true);
  const [showVersionNumber, setShowVersionNumber] = useState(false);

  function logout() {
    auth.signout(true);
    window.location.href = "/login";
  }

  useEffect(() => {
    window["data"] = {};
  }, [location]);

  useEffect(() => {
    if (
      localStorage.getItem("user") != null &&
      JSON.parse(localStorage.getItem("user") as string)["userdata"][
        "unit"
      ][0] != ""
    ) {
      const localStorageUser = JSON.parse(
        localStorage.getItem("user") as string
      );
      setUserrole(localStorageUser["userdata"]["role"][0]);
      setUsername(localStorageUser["userdata"]["name"]);
      setUserunit(localStorageUser["userdata"]["unit"][0]);

      axios
        .all([
          axios.single({
            method: "get",
            url: generateUrl("/api/orgelements/accessibleorgs/me"),
            responseType: "json",
            data: {},
            headers: { "x-access-token": auth.user["token"] },
          }),
        ])
        .then(
          axios.spread((...res) => {
            axios
              .all([
                axios.single({
                  method: "post",
                  url: generateUrl("/api/orgelements/orgnames"),
                  responseType: "json",
                  data: {
                    orgids: res[0].data,
                  },
                  headers: { "x-access-token": auth.user["token"] },
                }),
              ])
              .then(
                axios.spread((...res2) => {
                  setOrgs(res2[0].data != undefined ? res2[0].data : []);
                })
              );
            setUserunits(res[0].data);
          })
        );
      setLoading(false);
    } else {
      //history.push("/login");
      auth.signout();
    }
  }, [localStorage.getItem("user")]);

  function handleChangeUserUnit(unitId) {
    axios
      .post(
        generateUrl("/api/users/me/changeunit"),
        {
          unit: unitId,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (res) {
        axios
          .all([
            axios.single({
              method: "post",
              url: generateUrl("/api/login/updatejwt"),
              data: { userid: res.data._id },
              headers: { "x-access-token": auth.user["token"] },
            }),
          ])
          .then(
            axios.spread((...res2) => {
              let userObject = {
                id: res2[0].data.userdata.email,
                auth: res2[0].data.auth,
                token: res2[0].data.token,
                userdata: res2[0].data.userdata,
                settings: res2[0].data.settings,
              };
              localStorage.setItem("user", JSON.stringify(userObject));
              localStorage.removeItem("unauthorized");
              auth.setUser(() => {
                return userObject;
              });
              history.go(0);
            })
          );
      });
  }

  return !nomenu.some((i) => window.location.pathname.includes(i)) &&
    auth.user != undefined &&
    auth.user["id"] &&
    auth.user["userdata"]["unit"][0] != "" ? (
    <div
      className={
        menuopen ? "menu openmenu no-print" : "menu closedmenu no-print"
      }
    >
      <div className="menutoggle">
        <i
          onClick={() => {
            setMenuopen(!menuopen);
            props.isOpen(!menuopen);
          }}
          className={
            "dripicons-arrow-thin-left" + (menuopen ? " shown" : " hidden")
          }
        ></i>
        <i
          onClick={() => {
            setMenuopen(!menuopen);
            props.isOpen(!menuopen);
          }}
          className={"dripicons-menu" + (menuopen ? " hidden" : " shown")}
        ></i>
      </div>
      <div className="logo">
        <img src={CRAlogo} alt="" />
      </div>
      <div className="orgname">
        {userunits[0] != undefined &&
        userunits[0] != "" &&
        userunits.length > 1 &&
        (auth.user["userdata"]["actualAccessRole"]["rules"].find(
          (rule) =>
            rule.objType == "organization" && rule.objSubType == "organization"
        )?.actions.view_as_different_organization == "allow" ??
          false) ? (
          <div className="mainMenuTreeSelector">
            {/* <Field
                            type="select"
                            value={userunit}
                            onChange={val => handleChangeUserUnit(val.value)}
                            options={{
                                choices: userunits.map(u => {
                                    let org = orgs.find(o => o["id"] == u);
                                    return {
                                        label: org != undefined ? org["name"] : "",
                                        value: u
                                    }
                                })
                            }}
                        ></Field> */}
            <Field
              title={t("users.new_unit")}
              type="treeselector"
              defaultValue={userunit}
              value={userunit}
              onChange={(val) => handleChangeUserUnit(val)}
              options={{
                namekey: "name",
                list: userunits.map((u) => {
                  let org = orgs.find((o) => o["id"] == u);
                  return {
                    _id: org != undefined ? org["id"] : "",
                    name: org != undefined ? org["name"] : "",
                    parent: org != undefined ? org["parent"] : "",
                    value: u,
                  };
                }),
                selecttext: t("users.selecttext"),
                canceltext: t("users.cancel"),
                placeholder: t("main_menu.loading"),
              }}
            ></Field>
          </div>
        ) : (
          <p>
            <span>
              {orgs.find((o) => o["id"] == userunits[0]) != undefined
                ? (orgs.find((o) => o["id"] == userunits[0]) as any)["name"]
                : ""}
            </span>
          </p>
        )}
        {/*<p><span>{orgName}</span></p>*/}
      </div>
      <div className="username">
        <p>
          <span>{t("main_menu.welcomemessage")}</span>
          <br />
          {username}
        </p>
      </div>
      <div className="menuitems">
        <ul>
          <li data-satarget="menudashboard" data-safixed="true">
            <Link to="/">
              <Tooltip
                disableHoverListener={menuopen}
                leaveDelay={500}
                title={t("main_menu.dashboard")}
                placement="right"
                classes={{ tooltip: "menutooltip" }}
              >
                <i className="dripicons-meter" />
              </Tooltip>
              <span>{t("main_menu.dashboard")}</span>
            </Link>
          </li>
          {/* <li>
                            <Link to="/processes"><i className="dripicons-rocket" /><span>{t("main_menu.processes")}</span></Link>
                        </li> */}
          <li>
            <Link to="/systems">
              <Tooltip
                disableHoverListener={menuopen}
                leaveDelay={500}
                title={t("main_menu.systems")}
                placement="right"
                classes={{ tooltip: "menutooltip" }}
              >
                <i className="dripicons-browser" />
              </Tooltip>
              <span>{t("main_menu.systems")}</span>
            </Link>
          </li>
          <li>
            <Link to="/riskregistry">
              <Tooltip
                disableHoverListener={menuopen}
                leaveDelay={500}
                title={t("main_menu.riskregistry")}
                placement="right"
                classes={{ tooltip: "menutooltip" }}
              >
                <i className="dripicons-preview" />
              </Tooltip>
              <span>{t("main_menu.riskregistry")}</span>
            </Link>
          </li>
          <li>
            <Link to="/valueregistry">
              <Tooltip
                disableHoverListener={menuopen}
                leaveDelay={500}
                title={t("main_menu.valueregistry")}
                placement="right"
                classes={{ tooltip: "menutooltip" }}
              >
                <i className="dripicons-view-list" />
              </Tooltip>
              <span>{t("main_menu.valueregistry")}</span>
            </Link>
          </li>
          <li>
            <Link to="/treatments">
              <Tooltip
                disableHoverListener={menuopen}
                leaveDelay={500}
                title={t("main_menu.measures")}
                placement="right"
                classes={{ tooltip: "menutooltip" }}
              >
                <i className="dripicons-heart" />
              </Tooltip>
              <span>{t("main_menu.measures")}</span>
            </Link>
          </li>
          <li>
            <Link to="/analysislist">
              <Tooltip
                disableHoverListener={menuopen}
                leaveDelay={500}
                title={t("main_menu.analysis")}
                placement="right"
                classes={{ tooltip: "menutooltip" }}
              >
                <i className="dripicons-document-new" />
              </Tooltip>
              <span>{t("main_menu.analysis")}</span>
            </Link>
          </li>
          <li>
            <Link to="/tasks">
              <Tooltip
                disableHoverListener={menuopen}
                leaveDelay={500}
                title={t("main_menu.tasks")}
                placement="right"
                classes={{ tooltip: "menutooltip" }}
              >
                <i className="dripicons-to-do" />
              </Tooltip>
              <span>{t("main_menu.tasks")}</span>
            </Link>
          </li>
          <li>
            <Link to="/events">
              <Tooltip
                disableHoverListener={menuopen}
                leaveDelay={500}
                title={t("main_menu.last_events")}
                placement="right"
                classes={{ tooltip: "menutooltip" }}
              >
                <i className="dripicons-alarm" />
              </Tooltip>
              <span>{t("main_menu.last_events")}</span>
            </Link>
          </li>
          <hr />
          <li>
            <a href="https://hjelp.diri.no/" target="_blank">
              <Tooltip
                disableHoverListener={menuopen}
                leaveDelay={500}
                title={t("main_menu.community_and_help")}
                placement="right"
                classes={{ tooltip: "menutooltip" }}
              >
                <i className="dripicons-question" />
              </Tooltip>
              <span>{t("main_menu.community_and_help")}</span>
            </a>
          </li>
          <li>
            <Link to="/myaccount">
              <Tooltip
                disableHoverListener={menuopen}
                leaveDelay={500}
                title={t("main_menu.myaccount")}
                placement="right"
                classes={{ tooltip: "menutooltip" }}
              >
                <i className="dripicons-user" />
              </Tooltip>
              <span>{t("main_menu.myaccount")}</span>
            </Link>
          </li>
          {auth.user["userdata"]["actualAccessRole"]["rules"]
            .filter((role) => role.objType == "users")
            .map((role) => role?.actions.read == "allow" ?? false)
            .includes(true) ? (
            <li data-satarget="menuusers" data-safixed="true">
              <Link to="/users">
                <Tooltip
                  disableHoverListener={menuopen}
                  leaveDelay={500}
                  title={t("main_menu.users")}
                  placement="right"
                  classes={{ tooltip: "menutooltip" }}
                >
                  <i className="dripicons-user-group" />
                </Tooltip>
                <span>{t("main_menu.users")}</span>
              </Link>
            </li>
          ) : null}
          {auth.user["userdata"]["actualAccessRole"]["rules"].find(
            (rule) =>
              rule.objType == "organization" &&
              rule.objSubType == "organization"
          )?.actions.read == "allow" ?? false ? (
            <li>
              <Link to="/organisation">
                <Tooltip
                  disableHoverListener={menuopen}
                  leaveDelay={500}
                  title={t("main_menu.organization")}
                  placement="right"
                  classes={{ tooltip: "menutooltip" }}
                >
                  <i className="dripicons-view-thumb" />
                </Tooltip>
                <span>{t("main_menu.organization")}</span>
              </Link>
            </li>
          ) : null}
          {auth.user["userdata"]["actualAccessRole"]["rules"].find(
            (rule) =>
              rule.objType == "settings" && rule.objSubType == "settings"
          )?.actions.read == "allow" ?? false ? (
            <li>
              <Link to="/settings">
                <Tooltip
                  disableHoverListener={menuopen}
                  leaveDelay={500}
                  title={t("main_menu.settings")}
                  placement="right"
                  classes={{ tooltip: "menutooltip" }}
                >
                  <i className="dripicons-gear" />
                </Tooltip>
                <span>{t("main_menu.settings")}</span>
              </Link>
            </li>
          ) : null}
          <div onClick={() => setShowVersionNumber(true)}>
            <p className="version-number">Version {packageJson.version}</p>
          </div>
          <li className="logoutareapadder" style={{ height: "50px" }}></li>
        </ul>

        <Button className="logoutBtn largescreen" onClick={logout}>
          {t("main_menu.logout")}
        </Button>
        <Button
          className="logoutBtn smallscreen"
          onClick={logout}
          style={{
            color: "white",
            transform: "rotate(180deg)",
            height: "50px",
            width: "42px",
            boxShadow: "0px 0px 10px #ffffff54",
            bottom: "0px",
            left: "-27px",
            backgroundColor: "#1f212d",
          }}
        >
          <Tooltip
            disableHoverListener={menuopen}
            leaveDelay={500}
            title={t("main_menu.logout")}
            placement="right"
            classes={{ tooltip: "menutooltip" }}
          >
            <i className="dripicons-exit"></i>
          </Tooltip>
        </Button>
      </div>
      <VersionNumber
        showVersionNumber={showVersionNumber}
        setShowVersionNumber={setShowVersionNumber}
      />
    </div>
  ) : (
    <div></div>
  );
}

export default MainMenu;
