import * as React from 'react';
import { useHistory } from "react-router-dom";

import './Organisation.scss';
import { Card, Button, Modal, InputGroup, Form, Table, Nav } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../Auth';
import { useAxios } from '../../../AxiosHandler';
import { useTranslate } from 'react-polyglot';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TreeOverview from '../../Shared/TreeOverview/TreeOverview';

import SiteAssistant from "../../Shared/SiteAssistant/SiteAssistant";
import DiriFaceDocked from "../../../Assets/Images/diriassistant/diri.svg";
import Field from '../../Shared/Wizard/Field/Field';
import { useDynamicLanguage } from '../../../DynamicLanguageProvider';
import Shop from '../../Shared/Shop/Shop';
import OrganizationRolesHandler from './OrganizationRolesHandler/OrganizationRolesHandler';
import SAOverview from './Overviews/SAOverview/SAOverview';
import CustomerOverview from './Overviews/CustomerOverview/CustomerOverview';
import PageTable from '../../Shared/PageTable/PageTable';
import { generateUrl } from '../../../config';
 
function Organisation(props) {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const history = useHistory();
    const t = useTranslate();
    const td = useDynamicLanguage().td;
    const myRef = React.useRef<any>();
    const myInviteRef = React.useRef<any>();

    const customerRule = auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "customers" && rule.objSubType == "customers");
    const shopRule = auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "shop" && rule.objSubType == "shop");

    const notifySuccess = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "success-toast"
    });

    const notifyWarning = (text) => toast(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "warning-toast"
    });

    const [assistantGotoKey, setAssistantGotoKey] = useState("");

    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
    const [showDeleteCustomerModal, setShowDeleteCustomerModal] = useState(false);
    const [showRosModal, setShowRosModal] = useState(false);
    const [showDeleteRosModal, setShowDeleteRosModal] = useState(false);
    const [selectedRos, setSelectedRos] = useState<any>();
    const [ros, setRos] = useState<Array<any>>([]);

    const [newOrgName, setNewOrgName] = useState("");
    const [orgName, setOrgName] = useState("");
    const [orgParentId, setOrgParentId] = useState("");
    const [orgParentOptions, setOrgParentOptions] = useState<Array<Object>>([]);

    const [orgs, setOrgs] = useState<Array<Object>>([{}]);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [selectedOrgObject, setSelectedOrgObject] = useState<any>();
    const [customers, setCustomers] = useState<Array<Object>>([{}])
    
    const [show, setShow] = useState(false);
    const [editOrgelementShow, setEditOrgelementShow] = useState(false);

    const [inviteEmail, setInviteEmail] = useState("");
    const [inviteUnit, setInviteUnit] = useState("");
    const [inviteAccessRoles, setInviteAccessRoles] = useState<Array<any>>([]);
    const [accessRolesOptions, setAccessRolesOptions] = useState<Array<any>>([]);
    const [allAccessRoles, setAllAccessRoles] = useState<Array<any>>([]);
    const [invites, setInvites] = useState([]);
    const [tenants, setTenants] = useState<Array<string>>([]);
    const [openTenantJoin, setOpenTenantJoin] = useState<boolean>(false);
    const [adminInvite, setAdminInvite] = useState<boolean>(false);

    const [editCustomer, setEditCustomer] = useState<any>({});
    const [apikeys, setApikeys] = useState<Array<any>>([]);
    const [apikeyNew, setApikeyNew] = useState<any>("");
    const [showNewKeyModal, setShowNewKeyModal] = useState(false);

    const [isTopCustomer, setIsTopCustomer] = useState(false);
    const [showShop, setShowShop] = useState(false);
    const [shopAsOrg, setShopAsOrg] = useState({});

    const [orgRoleOverviewOrg, setOrgRoleOverviewOrg] = useState({});
    const [showOrgRoleOverviewModal, setShowOrgRoleOverviewModal] = useState(false);

    const [countedOrganizations, setCountedOrganizations] = useState<Array<any>>([]);
    const [countedUsers, setCountedUsers] = useState<Array<any>>([]);

    const [allOrganizationRoles, setAllOrganizationRoles] = useState<Array<any>>([]);

    const [showOverridePriceModal, setShowOverridePriceModal] = useState(false);
    const [overridePrice, setOverridePrice] = useState(0);
    const [currentOrg, setCurrentOrg] = useState({});
    const [currentOrgRoleId, setCurrentOrgRoleId] = useState("");
    const [currentSubscriptionId, setCurrentSubscriptionId] = useState("");

    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState<any>('');
    const [showSuccessfulPaymentModal, setShowSuccessfulPaymentModal] = useState(false);
    const [showFailedPaymentModal, setShowFailedPaymentModal] = useState(false);

    const [showRegParentModal, setShowRegParentModal] = useState(false);
    const [isRegParent, setIsRegParent] = useState(false);

    const [updateTable, setUpdateTable] = useState(0);
    const [activePage, setActivePage] = useState("");

    function handleShowOverridePriceModal(org, roleid, price, subscriptionId){
        setShowOverridePriceModal(true);
        setOverridePrice(price);
        setCurrentOrg(org);
        setCurrentOrgRoleId(roleid);
        setCurrentSubscriptionId(subscriptionId);
    }
    function handleCloseOverridePriceModal(){
        setShowOverridePriceModal(false);
        setOverridePrice(0);
        setCurrentOrg({});
        setCurrentOrgRoleId("");
    }
    function handleCloseSuccessfulPaymentModal(){
        setShowSuccessfulPaymentModal(false);
        history.push("/organisation");
    }
    function handleCloseFailedPaymentModal(){
        setShowFailedPaymentModal(false);
        history.push("/organisation");
    }

    function handleShowRegParentModal(org){
        if (customerRule != undefined && customerRule.actions.get_customer_information != "allow") { auth.signout() }
        setSelectedOrg(org._id);
        setIsRegParent(org.regParent);
        setShowRegParentModal(true);
    }

    function pageTabClicked(selectedKey) {
        setActivePage(selectedKey);
    }

    const handleCloseShop = () => {
        setShowShop(false);
    }
    const handleOpenShop = (org) => {
        setShopAsOrg(org);
        setShowShop(true)
    }


    const handleOpenOrgRolesOverviewModal = (org) => {
        if(shopRule != undefined && shopRule.actions.handle_shop_objects != ""){
            fetchOrgRolesOverviewData(org);
        }
        
        setOrgRoleOverviewOrg(org);
        setShowOrgRoleOverviewModal(true);
    }
    const handleCloseOrgRolesOverviewModal = () => {
        setOrgRoleOverviewOrg({});
        setShowOrgRoleOverviewModal(false);
    }


    const handleClose = () => setShow(false);
    const handleShow = (orgId) => {
        setSelectedOrg(orgId);
        setShow(true);
    };
    
    const handleCloseNewKeyModal = () => {
        setShowNewKeyModal(false);
        setApikeyNew("");
    }

    const handleKeyDownCreate = event => {
        if (event.key === 'Enter') {
            handleCreateOrg();
        }
    }

    const handleKeyDownEdit = event => {
        if (event.key === 'Enter') {
            handleNameChangeOrg();
        }
    }

    const handleNameChangeClose = () => setEditOrgelementShow(false);
    const handleNameChangeShow = (orgId, orgName) => {
        let org = (orgs.find(org => org["_id"] == orgId) as any);
        setSelectedOrgObject(org)
        setSelectedOrg(orgId);
        setOrgName(orgName);
        //setVatid(org.vatid);
        setNewOrgName(orgName);
        setEditOrgelementShow(true);
        setTenants(org != undefined ? org["tenants"] != undefined ? org["tenants"]: [] : []);
        setOpenTenantJoin(org != undefined ? org["openTenantJoin"] != undefined ? org["openTenantJoin"]: false : false);

        fetchApikeys(orgId);
        fetchInvites(orgId);
        setOrgParentId(org.parent);
        fetchOrgAccesses(orgId);
    };

    const handleNewOrgNameChange = (evt) => {
        setNewOrgName(evt.target.value);
    };

    const handleOrgNameChange = (evt) => {
        setNewOrgName(evt.target.value);
        setOrgName(evt.target.value);
    };

    const fetchInvites = (orgId) => {
        axios.single({
            method: "get",
            url: generateUrl("/api/orgelements/getinvites/") + orgId,
            responseType: "json",
            headers: {'x-access-token': auth.user["token"]}
        })
        .then((res) => {
            setInvites(res.data);
        });
    }

    const fetchApikeys = (orgId) => {
        axios.single({
            method: "get",
            url: generateUrl("/api/cra/apikeys/") + orgId,
            responseType: "json",
            headers: {'x-access-token': auth.user["token"]}
        })
        .then((res) => {
            setApikeys(res.data);
        });
    }

    const handleCreateOrg = () => {
        axios.post(generateUrl('/api/orgelements'), {
            name: newOrgName,
            parent: selectedOrg != null ? selectedOrg : ""
        }, {
            headers: {'x-access-token': auth.user["token"]}
        })
        .then(function (response) {
            fetchData();
            notifySuccess(t("organzation.toast_organzation_created"));
            setShow(false);
        });
    };

    const handleNameChangeOrg = () => {
        axios.post(generateUrl('/api/orgelements/name/') + selectedOrg, {
            newName: orgName,
            tenants: tenants,
            openTenantJoin: openTenantJoin,
            parent: orgParentId
            //vatid: vatid
        }, {
            headers: {'x-access-token': auth.user["token"]}
        })
        .then(function (response) {
            fetchData();
            setOrgParentId("");
            setEditOrgelementShow(false);
        });
    };

    function removeInvite(email) {
        axios.post(
            generateUrl("/api/orgelements/removeinvitation/") + selectedOrg,
            {
                email,
            },
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            notifySuccess(t("organzation.toast_invite_removed"));
            fetchInvites(selectedOrg);
        });
    }

    function handleCreateInvite() {
        //required fields validation
        if (inviteEmail == "") { notifyWarning(t("users.toast_users_empty_name")); return undefined; }
        if (inviteUnit == "") { notifyWarning(t("users.toast_users_empty_unit")); return undefined; }

        let pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        if (inviteEmail.length != 0) {
            let error = false;
            inviteEmail.split("\n").forEach((newInvitation,key) => {
                if (!pattern.test(newInvitation)) { 
                    notifyWarning(t("users.invite_invalid_email")); 
                    error = true;
                }
            })
            if(error) return undefined;
        }

        axios.post(
            generateUrl("/api/orgelements/addinvitation/") + inviteUnit,
            {
                invitationEmail: inviteEmail,
                invitationLevel: adminInvite ? "Admin" : "User",
                invitationAccessRoles: inviteAccessRoles
            },
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setInviteEmail("");
            notifySuccess(t("users.toast_user_invited"));
            fetchInvites(selectedOrg);
        });
    }

    function handleShowRosModal(org) {
        getRos(org._id);
        setSelectedOrg(org._id);
        setShowRosModal(true);
    }

    function handleShowCustomerModal(org){
        if (customerRule != undefined && customerRule.actions.get_customer_information != "allow") { auth.signout() }
        setSelectedOrg(org._id);
        setIsTopCustomer(org.topCustomer);
        setShowCustomerModal(true);
    }

    function handleShowEditCustomerModal(currentCustomer, org){
        if (customerRule != undefined && customerRule.actions.get_customer_information != "allow") { auth.signout() }
        setSelectedOrg(org._id);
        setIsTopCustomer(org.topCustomer);
        setEditCustomer(currentCustomer);
        setShowEditCustomerModal(true);
    }

    function handleCloseRosModal() {
        setRos([]);
        setShowRosModal(false);
    }

    function handleDeleteRos(selectedRos) {
        setSelectedRos(selectedRos);
        setShowDeleteRosModal(true);
    }

    function createRos() {
        axios.post(
            generateUrl("/api/orgelements/addros/") + selectedOrg,
            {},
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            notifySuccess(t("organzation.ros.toasts.ros_created"));
            getRos();
            fetchData();
        });
    }

    function deleteRos() {
        axios.post(
            generateUrl("/api/orgelements/deleteros/") + selectedRos._id,
            {},
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            notifySuccess(t("organzation.ros.toasts.ros_deleted"));
            setShowDeleteRosModal(false);
            getRos();
            fetchData();
        });
    }

    function getRos(id = selectedOrg) {
        axios.post(
            generateUrl("/api/cra/systems/orgros/byorg/") + id,
            {},
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setRos(res.data);
        });
    }

    function handleOrgDelete(org) {
        let orgHasChildren = orgs.find(i => i["parent"] == org["_id"]) == undefined;
        if (orgHasChildren) {
            if (window.confirm(t("organzation.delete_org_dialog"))) {
                axios.delete("/api/orgelements/" + org["_id"], {
                    orgId: org["_id"]
                }, {
                    headers: {'x-access-token': auth.user["token"]}
                }).then(res => {
                    if (res.data.message == "cannotdelete") {
                        notifyWarning(t("organzation.delete_org.denied_org_not_empty")); 
                    } else {
                        notifySuccess(t("organzation.delete_org.deleted"));
                    }
                    fetchData();
                });
            }
        } else {
            notifyWarning(t("organzation.delete_org.denied_org_not_empty")); 
        }
    }

    function handleAddCustomer(org){
        axios.post(
            generateUrl("/api/cra/customers/"),
            {
                customerNumber: editCustomer["customerNumber"],
                orgId: org,
                contactEmail: editCustomer["contactEmail"],
                contactPhone: editCustomer["contactPhone"],
                contactName: editCustomer["contactName"],
            },{
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setShowCustomerModal(false);
            fetchData();
            if(customerRule != undefined && customerRule.actions.get_customer_information != "allow"){
                fetchCustomers();
            }
        });
    }

    function handleEditCustomer(){
        axios.put(
            generateUrl("/api/cra/customers/") + editCustomer["_id"],
            {
                customerNumber: editCustomer["customerNumber"],
                contactEmail: editCustomer["contactEmail"],
                contactPhone: editCustomer["contactPhone"],
                contactName: editCustomer["contactName"],
                active: editCustomer["active"]
            },{
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setShowEditCustomerModal(false);
            fetchData();
            if(customerRule != undefined && customerRule.actions.get_customer_information != "allow"){
                fetchCustomers();
            }
        });
    }
    
    function handleSetTopCustomer(val){
        axios.put(generateUrl('/api/orgelements/setTopCustomer/') + selectedOrg, {
            isTopCustomer: val
        }, {
            headers: {'x-access-token': auth.user["token"]}
        })
        .then(function (res) {
            setShowEditCustomerModal(false);
            setShowCustomerModal(false);
            fetchData();
            if(customerRule != undefined && customerRule.actions.get_customer_information != "allow"){
                fetchCustomers();
            }
        });
    }

    function handleDeleteCustomer(id){
        axios.delete(
            generateUrl("/api/cra/customers/") + id,
            {},
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setShowEditCustomerModal(false);
            setShowDeleteCustomerModal(false);
            axios.put(generateUrl('/api/orgelements/setTopCustomer/') + selectedOrg, {
                isTopCustomer: false
            }, {
                headers: {'x-access-token': auth.user["token"]}
            })
            .then(function (res2) {
                fetchData();
                if(customerRule != undefined && customerRule.actions.get_customer_information != "allow"){
                    fetchCustomers();
                }
            });
        });
    }

    function addApikey() {
        axios.post(
            generateUrl("/api/cra/apikeys/") + selectedOrg,
            {},
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setApikeyNew(res.data.realkey);
            setShowNewKeyModal(true);
            fetchApikeys(selectedOrg);
        });
    }

    function deleteApikey(keyid) {
        axios.delete(
            generateUrl("/api/cra/apikeys/") + keyid,
            {},
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            fetchApikeys(selectedOrg);
        });
    }

    function clearCustomerForm(){
        setEditCustomer("");
    }

    //STRIPE FUNCTIONS

    function addTrialToOrg(org, role, trialDays){
        axios.post(
            generateUrl("/api/cra/stripe/addtrial/byorg/") + org._id,
            {
                roleid: role._id,
                price: role.price,
                shopEventType: "addedByDiri",
                userid: auth.user["userdata"]["_id"],
                orgid: org._id,
                item: role._id,
                trialDays: trialDays
            },
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setOrgRoleOverviewOrg(res.data.org);
            fetchOrgRolesOverviewData(org);
            notifySuccess(t("organzation.saoverview.orgRoleAdded"));
            setUpdateTable(updateTable + 1);
        });
    }

    /* function removeReceivedByDiri(roleid, orgid){
        axios.put(
            "https://test.diri.ai/api/orgelements/removeReceivedByDiri/byorg/" + orgid,
            {
                roleid: roleid
            },{
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            fetchData();
            notifySuccess(t("organzation.saoverview.orgRoleRemoved"));
            setUpdateTable(updateTable + 1);
        });
    } */

    function saveOverridePrice(){
        axios.put(
            generateUrl("/api/cra/stripe/overridePrice"),
            {
                roleid: currentOrgRoleId,
                newPrice: overridePrice,
                subscriptionid: currentSubscriptionId,
                orgid: currentOrg["_id"]

            },
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            handleCloseOverridePriceModal();
            setOrgRoleOverviewOrg(res.data.org);
            setUpdateTable(updateTable + 1);
            axios.all([
                axios.single({
                    method: "post",
                    url: generateUrl("/api/login/updatejwt"),
                    data: { userid: auth.user["userdata"]["_id"] },
                    headers: {'x-access-token': auth.user["token"]}
                })
            ])
            .then(axios.spread((...res3) => {
                let userObject = {
                    id: res3[0].data.userdata.email,
                    auth: res3[0].data.auth,
                    token: res3[0].data.token,
                    userdata: res3[0].data.userdata,
                    settings: res3[0].data.settings
                };
                localStorage.setItem('user', JSON.stringify(userObject));
                localStorage.removeItem("unauthorized");
                auth.setUser(() => { return userObject });
                fetchOrgRolesOverviewData(res.data.org);
                notifySuccess(t("organzation.saoverview.priceOverride"))
            }));
        });
    }

    function resetToDefaultPrice(subscriptionId){
        axios.put(
            generateUrl("/api/cra/stripe/resetToDefaultPrice"),
            {
                subscriptionId: subscriptionId
            },
            {
                headers: {'x-access-token': auth.user["token"]}
            }
        ).then(res => {
            setUpdateTable(updateTable + 1);
        });
    }

    // --------------

    function handleEditRegParent(value) {
        if(value == true){
            setIsRegParent(value);
            axios.put(
                generateUrl("/api/orgelements/changeRegParent"),
                {
                    orgid: selectedOrg
                },
                {
                    headers: {'x-access-token': auth.user["token"]}
                }
            ).then(res => {
                fetchData();
                setShowRegParentModal(false);
            });
        } else {
            notifyWarning(t("organzation.oneRegParentMustExist"))
        }
    }

    function fetchOrgRolesOverviewData(org) {
        axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/organizationRoles/getAll"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
        ]).then((res) => {
            setAllOrganizationRoles(res[0].data);
        });
    }

    async function fetchCustomers() {
        axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/customers/"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            })
        ]).then((res) => {
            setCustomers(res[0].data)
        });
        
    }
    
    async function fetchData() {
        axios.all([
            axios.single({
                method: "get",
                url: generateUrl("/api/orgelements/fromOrganizations"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/cra/accessRoles/options"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            }),
            axios.single({
                method: "get",
                url: generateUrl("/api/users/countedObjects"),
                responseType: "json",
                headers: {'x-access-token': auth.user["token"]}
            })
        ]).then((res) => {
            setOrgs(res[0].data);
            setAllAccessRoles(res[1].data);
            setInviteAccessRoles(res[1].data.filter(role => role.defaultNewUsers == true)?.map(role => role?._id) ?? [])
            setAccessRolesOptions(res[1].data.map(data => { return {label: data.name, value: data._id } }));
            setCountedUsers(res[2].data)
            axios.post(
                generateUrl("/api/orgelements/countedObjects/byorgids/"),
                {
                    ids: res[0].data.map(d => d._id)
                },
                {
                    headers: {'x-access-token': auth.user["token"]}
                }
            ).then((res) => {
                setCountedOrganizations(res.data.countedObjectsArray);
            });
        });
    }

    async function fetchOrgAccesses(orgId){
        axios.single({
            method: "get",
            url: generateUrl("/api/orgelements/orgAccesses/") + orgId,
            responseType: "json",
            headers: {'x-access-token': auth.user["token"]}
        }).then((res) => {
            setOrgParentOptions(orgs.filter(org => !res.data.includes(org?.["_id"].toString())));
        });
    }

    function printDate(d){
        if(d){
            let date = new Date(new Date(d).getTime() + new Date(d).getTimezoneOffset()/60).getTime();

            let dateYear = new Date(date).getFullYear();
            let dateMonth = new Date(date).getMonth() + 1;
            let dateDay = new Date(date).getDate();
            let dateHour = new Date(date).getHours();
            let dateMinute = new Date(date).getMinutes();

            return dateYear + "-" + ((dateMonth.toString().length == 1) ? ("0" + dateMonth) : dateMonth) + "-" + ((dateDay.toString().length == 1) ? ("0" + dateDay) : dateDay) + " " + ((dateHour.toString().length == 1) ? ("0" + dateHour) : dateHour) + ":" + ((dateMinute.toString().length == 1) ? ("0" + dateMinute) : dateMinute);
        } else {
            return "";
        }
        
    }

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setSuccess(true);
            const sessionID = query.get('session_id');
            setSessionId(sessionID);
            setShowSuccessfulPaymentModal(true);
        }

        if (query.get('canceled')) {
            setSuccess(false);
            setShowFailedPaymentModal(true);
        }
    }, [sessionId]);


    useEffect(() => {
        /* if (!auth.user["userdata"]["role"].includes("Admin")) { auth.signout() } */
        if (auth.user["userdata"] == undefined) { auth.signout() }
        if (auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.read != "allow") { auth.signout() }
        fetchData();
        if(customerRule != undefined && customerRule.actions.get_customer_information != ""){
            fetchCustomers();
        }

        setActivePage("organization");
    }, []);

    return (
        <div className="maincontent">
            
                <Nav
                    variant="tabs"
                    activeKey={activePage}
                    onSelect={pageTabClicked}
                >
                    <Nav.Item className="pagetab">
                        <Nav.Link eventKey={"organization"}>{t("organzation.organization_tab")}</Nav.Link>
                    </Nav.Item>
                    {shopRule != undefined && shopRule.actions.handle_shop_objects != "" ?
                        <>
                            <Nav.Item data-satarget="organizationroleshandlertab" data-safixed="false">
                                <Nav.Link eventKey={"organizationroleshandler"}>{t("organzation.organization_roles_handler_tab")}</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item data-satarget="saoverviewtab" data-safixed="false">
                                <Nav.Link eventKey={"saoverview"}>{t("organzation.saoverview_tab")}</Nav.Link>
                            </Nav.Item> */}
                        </>
                    : null }
                    <Nav.Item data-satarget="customeroverviewtab" data-safixed="false">
                        <Nav.Link eventKey={"customeroverview"}>{t("organzation.customeroverview_tab")}</Nav.Link>
                    </Nav.Item>
                </Nav>
            
            <SiteAssistant
                dockedLogo={DiriFaceDocked}
                featurekey="organisation"
                gotoKey={assistantGotoKey}
                gotoKeyFinished={() => {
                    setAssistantGotoKey("");
                }}
                regCompleted={(data) => {
                    history.push("/system/" + data._id);
                }}
                options={{ selectedOrgId: selectedOrg }}
            />
            {activePage == "organization" ?
                <div className="organisation-container">
                    <div className="pagetitle-container">
                        <h1>{t("organzation.page_name")}</h1>
                    </div>
                    <Card>
                        <Card.Body>
                            <h4 className="header-title">{t("organzation.organzation_overview")}</h4>
                            { orgs != undefined && orgs.length > 0 ?
                                <TreeOverview
                                    data={orgs}
                                    onEditClick={(org) => handleNameChangeShow(org["_id"], org["name"])}
                                    onAddElementClick={(org) => handleShow(org["_id"])}
                                    disabledAddNewOrganization={(org) => {
                                        if(auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.create != "allow" || countedOrganizations?.find(i => i.orgId == org["_id"])?.countedOrgs <= 0){ 
                                            return true;
                                        } 
                                        return false;
                                    }}
                                    addElementText={org => t("organzation.add_new_under") + ` ${org["name"]}` + " (" + t("users.remaining") + " " + (countedOrganizations?.find(i => i.orgId == org["_id"])?.countedOrgs ?? t("organzation.unlimited_amount")) + "/" + (auth.user["userdata"]["actualAccessRole"]["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.limitations?.amount ?? t("organzation.unlimited_amount")) + ")"}
                                    //addElementText={org => t("organzation.add_new_under") + ` ${org["name"]}`}
                                    elementName={org => `${org["name"]}`}
                                    hideEditButton={false}
                                    hideNewButton={false}
                                    accentColor="#facd75"
                                    backgroundColor="rgb(255, 250, 240)"
                                    customButtons={(org) => 
                                        <React.Fragment>
                                            {(customerRule != undefined && customerRule.actions.get_customer_information != "") ?
                                            <>
                                                <span className="rositems" onClick={evt => {
                                                        evt.preventDefault();
                                                        evt.stopPropagation();
                                                        handleOpenOrgRolesOverviewModal(org);
                                                }}>
                                                    
                                                    <i className="dripicons-toggles" />
                                                </span>
                                                <span className="rositems" onClick={evt => {
                                                        evt.preventDefault();
                                                        evt.stopPropagation();
                                                        handleShowRegParentModal(org);
                                                }}>
                                                    {org.regParent ?
                                                        <i className="dripicons-folder-open" />
                                                    : 
                                                        <i className="dripicons-folder" />
                                                    }
                                                    
                                                </span>
                                            </>
                                            : null }
                                            <span className="rositems" onClick={evt => {
                                                    evt.preventDefault();
                                                    evt.stopPropagation();
                                                    handleShowRosModal(org);
                                            }}>
                                                <i className="dripicons-view-thumb" />
                                                <p>{org.ros != undefined ? org.ros.length : 0}</p>
                                            </span>
                                            {org.parent !=  "" ?
                                                (customerRule != undefined && customerRule.actions.get_customer_information != "") ?
                                                    <span className="rositems" onClick={evt => {
                                                            evt.preventDefault();
                                                            evt.stopPropagation();
                                                            let currentCustomer = customers.filter(c => c["organization"] == org["_id"]) != undefined ? customers.filter(c => c["organization"] == org["_id"])[0] : undefined;
                                                            if(currentCustomer != undefined){
                                                                handleShowEditCustomerModal(currentCustomer, org)
                                                            } else {
                                                                handleShowCustomerModal(org);
                                                            }
                                                        }}>
                                                        <i className="dripicons-user"/>
                                                        <i className={org.topCustomer == true ? "dripicons-flag" : customers.filter(c => c["organization"] == org["_id"]) != undefined && customers.filter(c => c["organization"] == org["_id"])[0] != undefined ? (customers.filter(c => c["organization"] == org["_id"])[0]["active"] == true ? "dripicons-checkmark" : "dripicons-dots-3" ): "dripicons-cross"}/>
                                                    </span>
                                                : null 
                                            : 
                                            <span className="">
                                                <i className="dripicons-user"/>
                                                <i className="dripicons-wrong"/>
                                            </span> 
                                            }
                                            {auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization").actions.delete == "allow" ?
                                                <i className="dripicons-trash" onClick={evt => {
                                                    evt.preventDefault();
                                                    evt.stopPropagation();
                                                    handleOrgDelete(org);
                                                }}/>
                                            : null}
                                            <span className="rositems" onClick={evt => {
                                                    evt.preventDefault();
                                                    evt.stopPropagation();
                                                    handleOpenShop(org);
                                            }}>
                                                <p>Diri shop</p>
                                                <i className="dripicons-cart" />
                                            </span>
                                        </React.Fragment>
                                    }
                                ></TreeOverview>
                            : ""}
                        </Card.Body>
                    </Card>
                    <Modal show={show} onHide={handleClose} onEntered={() => myRef.current.focus()}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("organzation.organzation_add")}{selectedOrg == undefined ? "" : " under " + orgs.filter(item => item["_id"] == selectedOrg)[0]?.["name"] ?? ""}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{t("organzation.organzation_name")}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control ref={myRef} onKeyDown={handleKeyDownCreate} onChange={handleNewOrgNameChange} />
                            </InputGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="cancel posleft" onClick={handleClose}>
                                {t("organzation.closed_without_save")}
                            </Button>
                            <Button onClick={handleCreateOrg}>
                                {t("organzation.save")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showNewKeyModal} onHide={handleCloseNewKeyModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("organzation.apikey_new_title")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("organzation.apikey_new_text")}</p>
                            <p>{apikeyNew}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="cancel" onClick={handleCloseNewKeyModal}>
                                {t("organzation.close")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={editOrgelementShow} size="lg" onHide={handleNameChangeClose} onEntered={() => {
                        myRef.current.focus();
                        setInviteUnit((selectedOrg as any));
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("organzation.name_changed")} {selectedOrg == undefined ? "" : orgs.filter(item => item["_id"] == selectedOrg)[0]["name"]}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{t("organzation.organzation_name")} *</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control ref={myRef} disabled={!selectedOrgObject?.accesses?.includes("edit") ?? true} onKeyDown={handleKeyDownEdit} onChange={handleOrgNameChange} value={newOrgName} />
                            </InputGroup>
                            <hr />
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>{t("organzation.invitations")}</h3>
                                    <p>{t("users.invite_description")}</p>
                                    <Table striped size="sm">
                                        <tbody>
                                            {invites.map((invite, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{(typeof invite != "string" ? invite["level"] : "User") + ": " + (typeof invite == "string" ? invite : invite["email"])}</td>
                                                        <td style={{width: "102px"}}><Button disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "users" && rule.objSubType == "users").actions.create != "allow"} onClick={() => removeInvite(typeof invite == "string" ? invite : invite["email"])}>{t("organzation.invite_delete")}</Button></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>{t("users.new_email")}</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control 
                                            as="textarea" 
                                            type="text" 
                                            rows={5} 
                                            ref={myInviteRef} 
                                            placeholder={t("users.new_email")} 
                                            onChange={(evt) => setInviteEmail(evt.currentTarget.value)} 
                                            value={inviteEmail}
                                            disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "users" && rule.objSubType == "users").actions.create != "allow"}
                                        ></Form.Control>
                                    </InputGroup>
                                    {/* <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>{t("organzation.invitations_asadmin")}</InputGroup.Text>
                                            <InputGroup.Checkbox checked={adminInvite} onChange={(evt) => setAdminInvite(evt.target.checked)}/> 
                                        </InputGroup.Prepend>
                                    </InputGroup> */}
                                    <Field
                                        type="selectmulti"
                                        title={t("users.access_roles")}
                                        value={inviteAccessRoles.map(val => { return { value: val, label: td(allAccessRoles.find(role => role._id == val)?.name ) } } ) }
                                        onChange={val => setInviteAccessRoles(val.map(v => v.value))}
                                        options={{
                                            selecttext: t("users.selecttext"),
                                            choices: accessRolesOptions.map(role => {return {value: role.value, label: td(role.label)}})
                                        }}
                                        disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "users" && rule.objSubType == "users").actions.create != "allow"}
                                    ></Field>
                                    <br />
                                    <Button disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "users" && rule.objSubType == "users").actions.create != "allow" || countedUsers["countedUsers"] <= 0} onClick={handleCreateInvite}>
                                        {t("organzation.invitations_addbtn")}
                                    </Button>
                                </div>
                                <div className="col-md-6">
                                    <h3>{t("organzation.tenants")}</h3>
                                    <p>{t("users.tenants_description")}</p>
                                    <Table striped size="sm">
                                        <tbody>
                                            {tenants.map((tenant, key) => { return (
                                                <tr key={key}>
                                                    <td><Form.Control disabled={!selectedOrgObject?.accesses?.includes("invite_azure_ad_tenants_to_organization") ?? true} onChange={(evt) => setTenants([...tenants.map((i, c) => c == key ? evt.target.value : i )])} value={tenant} /></td>
                                                    <td style={{width: "102px"}}><Button disabled={!selectedOrgObject?.accesses?.includes("invite_azure_ad_tenants_to_organization") ?? true} onClick={() => setTenants(tenants.filter(i => i != tenant))}>{t("organzation.tenant_delete")}</Button></td>
                                                </tr>
                                            )})}
                                        </tbody>
                                    </Table>
                                    <Button disabled={!selectedOrgObject?.accesses?.includes("invite_azure_ad_tenants_to_organization") ?? true} onClick={() => { setTenants([...tenants, ""]); }}>
                                        {t("organzation.tenants_addbtn")}
                                    </Button>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>{t("organzation.tenants_opentenantjointoggle")}</InputGroup.Text>
                                            <InputGroup.Checkbox disabled={!selectedOrgObject?.accesses?.includes("invite_azure_ad_tenants_to_organization") ?? true} checked={openTenantJoin} onChange={(evt) => setOpenTenantJoin(evt.target.checked)}/> 
                                        </InputGroup.Prepend>
                                    </InputGroup>
                                </div>
                            </div>
                            {/* { auth.user["userdata"]["role"].includes("Admin") ? */} 
                                <React.Fragment>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3>{t("organzation.apikeys")}</h3>
                                            <p>{t("organzation.apikeys_description")}</p>
                                            <p><a href={window.location.origin + "/rest/swagger.html"} target="blank">Docs</a></p>
                                            <Table striped size="sm">
                                                <tbody>
                                                    {apikeys.map((apikey, key) => { return (
                                                        <tr key={key}>
                                                            <td>{ t("organzation.apikey_created") + ": " + apikey.created }</td>
                                                            <td style={{width: "102px"}}><Button disabled={!selectedOrgObject?.accesses?.includes("generate_api_keys") ?? true} onClick={() => deleteApikey(apikey._id)}>{t("organzation.apikey_delete")}</Button></td>
                                                        </tr>
                                                    )})}
                                                </tbody>
                                            </Table>
                                            {countedOrganizations?.find(i => i.orgId == selectedOrgObject?._id)?.generate_api_keys ?
                                                <Button disabled={(!selectedOrgObject?.accesses?.includes("generate_api_keys") ?? true) || (!countedOrganizations?.find(i => i.orgId == selectedOrgObject?._id)?.generate_api_keys ?? true)} onClick={() => { addApikey(); }}>
                                                    {t("organzation.apikey_addbtn")}
                                                </Button>
                                            : 
                                                <Button 
                                                    className="upgrade-btn" 
                                                    onClick={() => { setShowShop(true) }}
                                                ><i className="dripicons-lock" /> {t("organzation.requires_update")}</Button>
                                            }
                                            
                                        </div>
                                        <div className="col-md-6">
                                        <h3>{t("organzation.changeOrgParent")}</h3>
                                            <Field
                                                type="select"
                                                disabled={(!selectedOrgObject?.accesses?.includes("edit") ?? true) || orgParentOptions.length == 0}
                                                title={t("organzation.changeOrgParent")}
                                                value={orgParentId}
                                                onChange={val => setOrgParentId(val.value)}
                                                options={{
                                                    choices: orgParentOptions.map(org => { return {label: org?.["name"] ?? "", value: org?.["_id"] ?? ""}})
                                                }}
                                            ></Field>
                                        </div>
                                        {/* <div className="col-md-6">
                                        <h3>{t("organzation.taxAndVat")}</h3>
                                        <Field
                                            type="input"
                                            disabled={!selectedOrgObject?.accesses?.includes("edit") ?? true}
                                            title={t("organzation.vatid")}
                                            value={vatid}
                                            onChange={val => setVatid(val)}
                                        ></Field>
                                        </div> */}
                                    </div>
                                </React.Fragment>
                            {/* : null} */}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="cancel posleft" onClick={handleNameChangeClose}>
                                {t("organzation.closed_without_save")}
                            </Button>
                            <Button 
                                disabled={ selectedOrgObject == undefined || 
                                    (!selectedOrgObject.accesses.includes("invite_azure_ad_tenants_to_organization") && 
                                    !selectedOrgObject.accesses.includes("edit")) } 
                                onClick={handleNameChangeOrg}
                            >
                                {t("organzation.save")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showRosModal} onHide={handleCloseRosModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("organzation.ros.title")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <Table striped>
                                    <tbody>
                                        {ros.map((ros, key) => 
                                            <tr key={key}>
                                                <td>{ros.nameReadable != null ? ros.nameReadable : orgs != undefined ? orgs.filter(org => org["_id"] == ros.org).map(org => org["name"]) : ""}</td>
                                                <td>
                                                    <Button style={{float: "right", marginLeft: "8px"}} disabled={!ros["accesses"].includes("delete")} onClick={() => handleDeleteRos(ros)}>
                                                        {t("organzation.ros.deleteros")}
                                                    </Button>
                                                    <Button style={{float: "right"}} onClick={() => history.push("/system/" + ros._id)}>
                                                        {t("organzation.ros.openros")}
                                                    </Button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                            <Button disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "system" && rule.objSubType == "orgros")?.actions.create != "allow"} onClick={() => {
                                setAssistantGotoKey("0");
                                setShowRosModal(false);
                            }}>
                                {t("organzation.ros.createros")}
                            </Button>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => setShowRosModal(false)}>
                                {t("organzation.ros.close")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showDeleteRosModal} onHide={() => setShowDeleteRosModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("organzation.ros.delete.title")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("organzation.ros.delete.text")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="cancel posleft" onClick={() => setShowDeleteRosModal(false)}>
                                {t("organzation.closed_without_save")}
                            </Button>
                            <Button onClick={() => deleteRos()}>
                                {t("organzation.ros.deleteros")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showCustomerModal} onHide={() => {setShowCustomerModal(false); clearCustomerForm();}}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("organzation.customer.title")}
                            </Modal.Title>
                        </Modal.Header>
                        
                        <Modal.Body>
                            <div>
                                <p>{t("organzation.customer.desc")}</p>
                            </div>
                            <Field
                                type="checkbox"
                                value={isTopCustomer}
                                title={t("organzation.customer.top_customer")}
                                onChange={value => { setIsTopCustomer(value); handleSetTopCustomer(value); }}
                                //disabled={editCustomer != undefined && editCustomer["active"] == true ? false : true}
                            ></Field>
                            <p>{t("organzation.customer.top_customer_desc")}</p>
                            <hr />

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{t("organzation.customer.active_customer")}</InputGroup.Text>
                                    <InputGroup.Checkbox 
                                        checked={editCustomer != undefined && editCustomer["active"] != undefined ? editCustomer["active"] : false } 
                                        onChange={(evt) => setEditCustomer({...editCustomer, active: evt.target.checked})}
                                    /> 
                                </InputGroup.Prepend>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{t("organzation.customer.customer_number")}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control 
                                    onChange={evt => setEditCustomer({ ...editCustomer, customerNumber: evt.currentTarget.value })} 
                                    value={editCustomer != undefined && editCustomer["customerNumber"] != undefined ? editCustomer["customerNumber"] : "" }>    
                                </Form.Control>
                            </InputGroup>

                            <Field
                                type="input"
                                value={editCustomer != undefined && editCustomer["contactEmail"] != undefined ? editCustomer["contactEmail"] : ""}
                                title={t("organzation.customer.contact_email")}
                                onChange={value => setEditCustomer({ ...editCustomer, contactEmail: value })}
                            ></Field>
                            <Field
                                type="input"
                                value={editCustomer != undefined && editCustomer["contactPhone"] != undefined ? editCustomer["contactPhone"] : ""}
                                title={t("organzation.customer.contact_phone")}
                                onChange={value => setEditCustomer({ ...editCustomer, contactPhone: value })}
                            ></Field>
                            <Field
                                type="input"
                                value={editCustomer != undefined && editCustomer["contactName"] != undefined ? editCustomer["contactName"] : ""}
                                title={t("organzation.customer.contact_name")}
                                onChange={value => setEditCustomer({ ...editCustomer, contactName: value })}
                            ></Field>

                        </Modal.Body>

                        <Modal.Footer>
                            <Button onClick={() => {setShowCustomerModal(false); clearCustomerForm();}}>
                                {t("organzation.closed_without_save")}
                            </Button>
                            <Button onClick={() => handleAddCustomer(selectedOrg)}>
                                {t("organzation.customer.add_customer")}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showEditCustomerModal} onHide={() => {setShowEditCustomerModal(false); clearCustomerForm();}}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("organzation.customer.title")}
                            </Modal.Title>
                        </Modal.Header>
                        
                        <Modal.Body>
                            <div>
                                <p>{t("organzation.customer.edit_customer_desc")}</p>
                            </div>
                            <Field
                                type="checkbox"
                                value={isTopCustomer}
                                title={t("organzation.customer.top_customer")}
                                onChange={value => { setIsTopCustomer(value); handleSetTopCustomer(value); }}
                                //disabled={editCustomer != undefined && editCustomer["active"] == true ? false : true}
                            ></Field>
                            <p>{t("organzation.customer.top_customer_desc")}</p>
                            <hr />
                            
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{t("organzation.customer.active_customer")}</InputGroup.Text>
                                    <InputGroup.Checkbox 
                                        checked={editCustomer != undefined && editCustomer["active"] != undefined ? editCustomer["active"] : false } 
                                        onChange={(evt) => setEditCustomer({...editCustomer, active: evt.target.checked})}
                                    /> 
                                </InputGroup.Prepend>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{t("organzation.customer.customer_number")}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control 
                                    onChange={evt => setEditCustomer({ ...editCustomer, customerNumber: evt.currentTarget.value })} 
                                    value={editCustomer != undefined && editCustomer["customerNumber"] != undefined ? editCustomer["customerNumber"] : "" }
                                    disabled={editCustomer != undefined && editCustomer["active"] == true ? false : true}>    
                                </Form.Control>
                            </InputGroup>

                            <Field
                                type="input"
                                value={editCustomer != undefined && editCustomer["contactEmail"] != undefined ? editCustomer["contactEmail"] : ""}
                                title={t("organzation.customer.contact_email")}
                                onChange={value => setEditCustomer({ ...editCustomer, contactEmail: value })}
                                disabled={editCustomer != undefined && editCustomer["active"] == true ? false : true}
                            ></Field>
                            <Field
                                type="input"
                                value={editCustomer != undefined && editCustomer["contactPhone"] != undefined ? editCustomer["contactPhone"] : ""}
                                title={t("organzation.customer.contact_phone")}
                                onChange={value => setEditCustomer({ ...editCustomer, contactPhone: value })}
                                disabled={editCustomer != undefined && editCustomer["active"] == true ? false : true}
                            ></Field>
                            <Field
                                type="input"
                                value={editCustomer != undefined && editCustomer["contactName"] != undefined ? editCustomer["contactName"] : ""}
                                title={t("organzation.customer.contact_name")}
                                onChange={value => setEditCustomer({ ...editCustomer, contactName: value })}
                                disabled={editCustomer != undefined && editCustomer["active"] == true ? false : true}
                            ></Field>

                        </Modal.Body>

                        <Modal.Footer>
                            <Button onClick={() => setShowDeleteCustomerModal(true)}>
                                {t("organzation.customer.delete_customer")}
                            </Button>
                            <Button onClick={() => {setShowEditCustomerModal(false); clearCustomerForm();}}>
                                {t("organzation.closed_without_save")}
                            </Button>
                            <Button onClick={() => handleEditCustomer()}>
                                {t("organzation.save")}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showDeleteCustomerModal} onHide={() => {setShowDeleteCustomerModal(false);}}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("organzation.customer.title")}
                            </Modal.Title>
                        </Modal.Header>
                        
                        <Modal.Body>
                            <div>
                                <p>{t("organzation.customer.delete_customer_desc")}</p>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button onClick={() => {setShowDeleteCustomerModal(false);}}>
                                {t("organzation.closed_without_save")}
                            </Button>
                            <Button onClick={() => handleDeleteCustomer(editCustomer["_id"])}>
                                {t("organzation.customer.delete_customer")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Shop
                        show={showShop}
                        onHide={() => handleCloseShop()}
                        size="lg"
                        orgid={shopAsOrg["_id"] ? shopAsOrg["_id"].toString() : auth.user["userdata"]["unit"][0]}
                        onPurchaseComplete={(data) => {
                            handleCloseShop();
                        }}
                    />
                    <Modal show={showOrgRoleOverviewModal} size="lg" onHide={handleCloseOrgRolesOverviewModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {orgRoleOverviewOrg?.["name"]}
                            </Modal.Title>
                        </Modal.Header>
                        
                        <Modal.Body>
                            <div className="SAOverview-container">
                                <h3>{t("organzation.saoverview.active")}</h3>
                                {orgRoleOverviewOrg?.["_id"] != undefined ?
                                    <PageTable
                                        dataUrl={generateUrl("/api/cra/stripe/getAllPaged/subscriptions/byorg/active/") + orgRoleOverviewOrg["_id"]}
                                        options={{
                                            showFields: [
                                                { label: t("customeroverview.table.name"), field: "name"},
                                                { label: t("customeroverview.table.price"), field: "price", override: dataLine => dataLine["price"] == "receivedByDiri" ? t("customeroverview.table.receivedByDiri") : dataLine["price"] == "trial" ? t("customeroverview.table.trial") : (dataLine["price"] + " " + (dataLine["subscription"]["plan"]["interval"] == "month" ? t("shop.perMonth") : t("shop.perYear")) + " (" + t("shop.excludingVAT") + ")")},
                                                //{ label: t("customeroverview.table.status"), field: "status", override: dataLine => !dataLine["subscription"]["cancel_at_period_end"] ? t("customeroverview.table.statuses." + dataLine["status"]) : t("customeroverview.table.statuses.cancelAtPeriodEnd") },
                                                { label: t("customeroverview.table.status"), field: "status", override: dataLine => t("customeroverview.table.statuses." + dataLine["status"])},
                                                { label: t("customeroverview.table.startedDate"), field: "startedDate"},
                                                { label: t("customeroverview.table.nextPayment"), field: "nextPayment"},
                                                { label: t("customeroverview.table.startedByUser"), field: "startedByUser", override: dataLine => dataLine["startedByUser"] == "receivedByDiri" ? t("customeroverview.table.receivedByDiri") : dataLine["startedByUser"]},
                                                { label: t("customeroverview.table.defaultPaymentMethod"), field: "defaultPaymentMethod"},
                                                { label: t("customeroverview.table.pricePlanName"), field: "pricePlanName"},
                                            ]
                                        }}
                                        dataAfter={dataLine => dataLine["price"] != "receivedByDiri" ? 
                                            <>
                                                <Button onClick={() => handleShowOverridePriceModal(orgRoleOverviewOrg, dataLine.orgRole._id, dataLine.subscription.plan.amount/100, dataLine.subscription.id)}>
                                                    {t("organzation.saoverview.overridePrice")}
                                                </Button>
                                                <Button onClick={() => resetToDefaultPrice(dataLine.subscription.id)}>
                                                    {t("organzation.saoverview.resetToDefaultPrice")}
                                                </Button>
                                            </>
                                            : null
                                            /* <Button 
                                                onClick={() => removeReceivedByDiri(dataLine.orgRole._id, orgRoleOverviewOrg["_id"])}
                                                disabled={auth.user["userdata"]?.["actualAccessRole"]?.["rules"].find(rule => rule.objType == "organization" && rule.objSubType == "organization")?.actions.handle_payment != "allow"}
                                            >{t("customeroverview.table.remove")}</Button> */
                                        }
                                        hidePagination= {false}
                                        hidePagesize= {false}
                                        hideFilter= {false}
                                        hover={true}
                                        updateTable={updateTable}
                                    />
                                : null}
                                {/* <table className="subscription-table">
                                    <thead>
                                        <tr>
                                            <th>Order</th>
                                            <th>{t("customeroverview.table.name")}</th>
                                            <th>{t("customeroverview.table.price")}</th>
                                            <th>{t("customeroverview.table.status")}</th>
                                            <th>{t("customeroverview.table.startedDate")}</th>
                                            <th>{t("customeroverview.table.nextPayment")}</th>
                                            <th>{t("customeroverview.table.startedByUser")}</th>
                                            <th>{t("customeroverview.table.defaultPaymentMethod")}</th>
                                            <th>{t("customeroverview.table.customerInfo")}</th>
                                            <th>{t("customeroverview.table.actions")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orgSubscriptions.filter(sub => sub.subscription.status == "active").map((sub, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{td(ownedOrganizationRoles.find(r => r._id == sub.orgRole)?.name)}</td>
                                                    <td>{td(sub.orgRole.name)}</td>
                                                    <td>{sub.subscription.plan.amount/100 + " " + sub.subscription.plan.currency}</td>
                                                    <td>{t("customeroverview.table.statuses." + sub.subscription.status)}</td>
                                                    <td>{printDate(sub.subscription.start_date*1000)}</td>
                                                    <td>{printDate(sub.subscription.current_period_end*1000)}</td>
                                                    <td>{sub.startedByUser}</td>
                                                    <td>
                                                        <Button onClick={() => handleShowOverridePriceModal(orgRoleOverviewOrg, sub.orgRole._id, sub.subscription.plan.amount/100, sub.subscription.id)}>
                                                            {t("organzation.saoverview.overridePrice")}
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {receivedByDiri.map((received, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{td(ownedOrganizationRoles.find(r => r._id == sub.orgRole)?.name)}</td>
                                                    <td>{td(received.role.name)}</td>
                                                    <td>{t("customeroverview.table.receivedByDiri")}</td>
                                                    <td>{t("customeroverview.table.statuses.active")}</td>
                                                    <td>{printDate(received.event.date)}</td>
                                                    <td>---</td>
                                                    <td>Diri</td>
                                                    <td>---</td>
                                                    <td>---</td>
                                                    <td><Button onClick={() => removeReceivedByDiri(received.role._id, received.event.orgid)}>{t("customeroverview.table.remove")}</Button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table> */}
                                {/* <Table>
                                    <thead>
                                        <tr>
                                            <th>{t("organzation.saoverview.name")}</th>
                                            <th colSpan={3}>{t("organzation.saoverview.price")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orgRoleOverviewOrg?.["orgRoles"]?.map((orgRole, key) => {
                                            let actualOrganizationRole = allOrganizationRoles.find(r => r._id.toString() == orgRole.id.toString());
                                            return (
                                                <tr key={key}>
                                                    <td>{td(actualOrganizationRole?.name)}</td>
                                                    <td>Kr. {orgRole.price},– {orgRole.price != actualOrganizationRole?.price ? "(" + t("organzation.saoverview.originalPrice") + ": kr. " + actualOrganizationRole?.["price"] + ",–)" : ""}</td>
                                                    <td>
                                                        <Button onClick={() => handleShowOverridePriceModal(orgRoleOverviewOrg, orgRole.id, orgRole.price)}>
                                                            {t("organzation.saoverview.overridePrice")}
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        {addedByDiriShopEventsIds.includes(orgRole.shopEventId) ?
                                                            <Button onClick={() => removeOrganizationRoleFromOrg(orgRole.id, orgRoleOverviewOrg["_id"])}>
                                                                {t("organzation.saoverview.remove")}
                                                            </Button>
                                                        : null }
                                                    </td>
                                                </tr>
                                            ) 
                                        })}
                                        <tr>
                                            <td>{t("organzation.saoverview.totalPrice")}</td>
                                            <td colSpan={3}>Kr. {orgRoleOverviewOrg?.["orgRoles"]?.map(r => r.price).reduce((a, b) => a + b, 0)},–</td>
                                        </tr>
                                    </tbody>
                                </Table> */}
                                <hr />
                                <div>
                                    <h3>{t("organzation.saoverview.shop_content")}</h3>
                                    <PageTable
                                        dataUrl={generateUrl("/api/cra/organizationRoles/getAllPaged/getAll/")}
                                        options={{
                                            showFields: [
                                                { label: t("organzation.saoverview.name"), field: "roleName"},
                                                { label: t("organzation.saoverview.price"), field: "price", override: (dataLine) => (dataLine.price/100) + " EUR" + " (" + t("shop.excludingVAT") + ")"},
                                                { 
                                                    label: t("organzation.saoverview.visibility_in_shop"), 
                                                    field: "hidden", 
                                                    override: (dataLine) => dataLine["hidden"] != undefined && dataLine["hidden"] == true ? t("organzation.saoverview.hidden") : t("organzation.saoverview.visible"),
                                                    customFilters: [
                                                        { label: t("organzation.saoverview.visible"), value: false },
                                                        { label: t("organzation.saoverview.hidden"), value: true },
                                                    ]
                                                },
                                            ]
                                        }}
                                        dataAfter={(dataLine) => 
                                            <>
                                                <Button 
                                                    disabled={orgRoleOverviewOrg?.["orgRoles"]?.map(role => role.id.toString()).includes(dataLine._id.toString())} 
                                                    onClick={() => addTrialToOrg(orgRoleOverviewOrg, dataLine, 30)}
                                                > {t("organzation.saoverview.addFreeSubTrial")} </Button>
                                                <br />
                                                <Button 
                                                    disabled={orgRoleOverviewOrg?.["orgRoles"]?.map(role => role.id.toString()).includes(dataLine._id.toString())} 
                                                    onClick={() => addTrialToOrg(orgRoleOverviewOrg, dataLine, null)}
                                                > {t("organzation.saoverview.addUnlimitedFreeSubTrial")} </Button>
                                            </>
                                        }
                                        hidePagination= {false}
                                        hidePagesize= {false}
                                        hideFilter= {false}
                                        //updateTable={updateTable}
                                        //hover={true}
                                    />
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button onClick={handleCloseOrgRolesOverviewModal}>
                                {t("organzation.close")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showOverridePriceModal} onHide={() => handleCloseOverridePriceModal()}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("organzation.saoverview.overridePriceModal.title")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("organzation.saoverview.overridePriceModal.disclaimer01") + " " + td(allOrganizationRoles.find(r => r._id.toString() == currentOrgRoleId.toString())?.name) + " " + t("organzation.saoverview.overridePriceModal.disclaimer02") + " " + currentOrg["name"]}</p>
                            <Field
                                type="input"
                                title={t("organzation.saoverview.overridePrice")}
                                value={overridePrice}
                                onChange={val => setOverridePrice(parseInt(val))}
                            ></Field>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="cancel posleft" onClick={() => handleCloseOverridePriceModal()}>{t("organzation.saoverview.overridePriceModal.cancel")}</Button>
                            <Button onClick={() => saveOverridePrice()}>{t("organzation.saoverview.overridePriceModal.save")}</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            : activePage == "organizationroleshandler" ?
                shopRule != undefined && shopRule.actions.handle_shop_objects != "" ?
                    <OrganizationRolesHandler></OrganizationRolesHandler> 
                : null
            /* : activePage == "saoverview" ?
                shopRule != undefined && shopRule.actions.handle_shop_objects != "" ?
                    <SAOverview></SAOverview> 
                : null */
            : activePage == "customeroverview" ?
                <CustomerOverview></CustomerOverview>
            : null}
            <Modal show={showSuccessfulPaymentModal} onHide={() => handleCloseSuccessfulPaymentModal()}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("organzation.successfulPayment")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="" onClick={() => handleCloseSuccessfulPaymentModal()}>{t("organzation.close")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showFailedPaymentModal} onHide={() => handleCloseFailedPaymentModal()}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("organzation.canceledPayment")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="" onClick={() => handleCloseFailedPaymentModal()}>{t("organzation.close")}</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRegParentModal} onHide={() => setShowRegParentModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="widecheckbox">
                        <Field
                            type="checkbox"
                            value={isRegParent}
                            title={t("organzation.regparent.regparent")}
                            onChange={(value) => { handleEditRegParent(value) } }
                            disabled={isRegParent}
                        ></Field>
                    </div>
                    
                    <p>{t("organzation.regparent.reg_parent_desc")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="cancel " onClick={() => setShowRegParentModal(false)}>{t("organzation.closed_without_save")}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Organisation;