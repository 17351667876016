import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Button, Modal } from "react-bootstrap";
import { useTranslate } from "react-polyglot";
import moment from "moment";

import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";

import Field from "../../../../Shared/Wizard/Field/Field";
import RiskMatrixChart from "../../../../Shared/RiskMatrixChart/RiskMatrixChart";
import TreatmentEditorModal from "../../../../Shared/Modals/Edit/TreatmentEditorModal";
import CostFormatter from "../../../../Shared/NumberFormatters/CostFormatter";
import SiteAssistant from "../../../../Shared/SiteAssistant/SiteAssistant";
import DiriFaceDocked from "../../../../../Assets/Images/diriassistant/diri.svg";
import { useDynamicLanguage } from "../../../../../DynamicLanguageProvider";

import { generateUrl } from "../../../../../config";

import "react-toastify/dist/ReactToastify.css";
import "./Actionplan.scss";

function Actionplan(props) {
  const auth = useAuth();
  const axiosHandler = useAxios();
  const axios = axiosHandler.axios;
  let history = useHistory();
  const t = useTranslate();
  const td = useDynamicLanguage().td;

  const notifySuccess = (text) =>
    toast(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "success-toast",
    });

  const localStorageUser = localStorage.getItem("user");
  let settings = {};
  if (localStorageUser != null) {
    settings = JSON.parse(localStorageUser)["settings"];
  }

  let { systemid } = useParams();

  const [system, setSystem] = useState<any>();

  const [saveWarningModalShow, setSaveWarningModalShow] = useState(false);

  const [causes, setCauses] = useState<any>([]);
  const [consequences, setConsequences] = useState<any>([]);
  const [treatments, setTreatments] = useState<any>([]);

  const [users, setUsers] = useState<any>([]);

  const [occurences, setOccurences] = useState<any>([]);
  const [allConsequenceTypes, setAllConsequenceTypes] = useState<Array<any>>(
    []
  );

  const [showTreatmentEditor, setShowTreatmentEditor] = useState(false);
  const [editingTreatmentId, setEditingTreatmentId] = useState();

  const [assistantkey, setAssistantkey] = useState("");

  const handleBackSystems = (evt) => {
    history.push("/system/" + system["_id"]);
  };

  const handleSaveSystem = () => {
    axios
      .put(
        generateUrl("/api/cra/systems/") + system["_id"],
        {
          system: system,
        },
        {
          headers: { "x-access-token": auth.user["token"] },
        }
      )
      .then(function (response) {
        fetchData();
        notifySuccess(t("system.toast_systemactionplansaved"));
        setSaveWarningModalShow(false);
        setChanged(false);
        // history.push("/system/" + system["_id"]);
      });
  };
  async function fetchSystem() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/systems/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/treatments/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          if (res[0].data.accesses.includes("show_treatment_plan")) {
            let tempSystem = res[0].data;
            if (tempSystem["actionplan"] == undefined) {
              tempSystem["actionplan"] = {
                text: "",
                treatments: [],
                signedby: "",
                signedat: "",
                changedat: new Date().toISOString(),
                finishedat: "",
                validuntil: "",
              };
            }

            if (
              res[0].data.actionplan != undefined &&
              res[0].data.actionplan.treatments != undefined
            ) {
              const updatedTreatments = res[1].data.filter((d) =>
                res[0].data.actionplan.treatments
                  .map((t) => t._id.toString())
                  .includes(d._id.toString())
              );
              res[0].data.actionplan.treatments = updatedTreatments;
            }
            setSystem(res[0].data);
            setTreatments(res[1].data);
            axios
              .all([
                axios.single({
                  method: "post",
                  url: generateUrl("/api/users/names"),
                  data: res[1].data
                    .map((i) => i.responsible)
                    .filter((i) => !i?.includes(" ") && i?.length == 24),
                  responseType: "json",
                  headers: { "x-access-token": auth.user["token"] },
                }),
              ])
              .then(
                axios.spread((...res2) => {
                  setUsers(res2[0].data);
                })
              );

            fetchData();
          } else {
            auth.signout();
          }
        })
      );
  }

  async function fetchData() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/causes/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/consequences/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/treatments/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/occurences/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/consequencetypes/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setCauses(res[0].data);
          setConsequences(res[1].data);

          setOccurences(res[3].data);
          setAllConsequenceTypes(res[4].data);
        })
      );
  }

  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (changed) {
      handleSaveSystem();
    }
  }, [changed]);

  async function fetchTreatments() {
    axios
      .all([
        axios.single({
          method: "get",
          url: generateUrl("/api/cra/treatments/bysystem/") + systemid,
          responseType: "json",
          headers: { "x-access-token": auth.user["token"] },
        }),
      ])
      .then(
        axios.spread((...res) => {
          setTreatments(res[0].data);
          setChanged(true);
        })
      );
  }

  function toggleTreatmentInPlan(treatment, val) {
    let tempSystem = JSON.parse(JSON.stringify(system));
    if (val == false) {
      tempSystem["actionplan"]["treatments"] = tempSystem["actionplan"][
        "treatments"
      ].filter((t) => t._id != treatment._id);
      axios
        .put(
          generateUrl("/api/cra/treatments/changeStatusFromActionplan/") +
            treatment["_id"],
          {
            systemid: system._id,
            status:
              treatment["status"] == "planned" ? "open" : treatment["status"],
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then((res) => {
          fetchTreatments();
        });
    } else {
      treatment["status"] =
        treatment["status"] != "closed" && treatment["status"] != "processing"
          ? "planned"
          : treatment["status"];
      tempSystem["actionplan"]["treatments"].push(treatment);
      axios
        .put(
          generateUrl("/api/cra/treatments/changeStatusFromActionplan/") +
            treatment["_id"],
          {
            systemid: system._id,
            status: treatment["status"],
          },
          {
            headers: { "x-access-token": auth.user["token"] },
          }
        )
        .then((res) => {
          fetchTreatments();
        });
    }
    setSystem(tempSystem);
  }

  function valueToText(val, type) {
    if (val <= 1) {
      return settings["grid" + type + "Name1"];
    }
    if (val <= 2) {
      return settings["grid" + type + "Name2"];
    }
    if (val <= 3) {
      return settings["grid" + type + "Name3"];
    }
    if (val <= 4) {
      return settings["grid" + type + "Name4"];
    }
    if (val <= 5) {
      return settings["grid" + type + "Name5"];
    }
    if (val <= 6) {
      return settings["grid" + type + "Name6"];
    }
  }

  function percentageToValue(percentage, maxvalue) {
    return (maxvalue - 1) * (percentage / 100) + 1;
  }

  function dateToSimpleString(date) {
    return (
      moment(date).format("yyyy-MM-DD") + " " + moment(date).format("HH:mm")
    );
  }

  useEffect(() => {
    if (auth.user["userdata"] == undefined) {
      window.location.href = "/login";
    }
    //fetchData();
    fetchSystem();
  }, []);

  useEffect(() => {
    if (system != undefined) {
      setAssistantkey("actionplan");
    }
  }, [system]);

  return (
    <div className="maincontent">
      {assistantkey != "" ? (
        <SiteAssistant dockedLogo={DiriFaceDocked} featurekey={assistantkey} />
      ) : null}
      {system != undefined ? (
        <div className="systems-container actionplan-container">
          <div className="pagetitle-container d-block">
            <h1>{t("system_actionplan.system_title")}</h1>
            <Button
              className="newsystemBtn backbtn"
              onClick={handleBackSystems}
            >
              {
                /* t("system_description.system_back") */ t(
                  "system.go_to_assessment"
                )
              }
            </Button>
          </div>
          <React.Fragment>
            <div className="toprow" style={{ maxWidth: "70%" }}>
              <div className="row">
                <div className="col-md-6">
                  <Card>
                    <Card.Body>
                      <div>
                        <h2>{t("system_actionplan.form.costsummary.title")}</h2>
                        <p>
                          {t("system_actionplan.form.costsummary.description")}
                        </p>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                {" "}
                                {t(
                                  "system_actionplan.form.costsummary.onetimepayment"
                                )}
                                :{" "}
                              </td>
                              <td>
                                <CostFormatter>
                                  {system["actionplan"]["treatments"]
                                    ?.filter(
                                      (t) =>
                                        t != undefined &&
                                        t.onetimecost != undefined &&
                                        t.onetimecost != null &&
                                        t.onetimecost != ""
                                    )
                                    ?.map((t) =>
                                      Number(
                                        t.onetimecost != null
                                          ? t.onetimecost
                                          : ""
                                      )
                                    )
                                    ?.reduce((i, j) => i + j, 0) ?? ""}
                                </CostFormatter>
                              </td>
                            </tr>
                            <tr>
                              <td data-satarget="costs" data-safixed="true">
                                {t(
                                  "system_actionplan.form.costsummary.yearlypayment"
                                )}
                                :{" "}
                              </td>
                              <td>
                                <CostFormatter>
                                  {system["actionplan"]["treatments"]
                                    ?.filter(
                                      (t) =>
                                        t != undefined &&
                                        t.cost != undefined &&
                                        t.cost != null &&
                                        t.cost != ""
                                    )
                                    ?.map((t) =>
                                      Number(t.cost != null ? t.cost : "")
                                    )
                                    ?.reduce((i, j) => i + j, 0) ?? ""}
                                </CostFormatter>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-6">
                  <RiskMatrixChart
                    systemid={systemid}
                    treatmentsCompleted={
                      system["actionplan"] != undefined
                        ? system["actionplan"]["treatments"]
                        : []
                    }
                    defaultValue={3}
                    label2={t("system.riskmatrix_planned")}
                  ></RiskMatrixChart>
                  <div
                    style={{ position: "absolute", bottom: "80px" }}
                    data-satarget="risks"
                    data-safixed="true"
                  ></div>
                </div>
              </div>
            </div>
            <div className="row treatmentlistcontainer">
              <div className="col-md-12">
                <Card>
                  <Card.Body>
                    <h3 data-satarget="plantreatments">
                      {t("system_actionplan.form.plantreatments_title")}
                    </h3>
                    <p>
                      {t("system_actionplan.form.plantreatments_description")}
                    </p>
                    <hr />
                    <div className="treatmentslist">
                      {treatments.map((treatment, treatmentkey) => (
                        <div key={treatmentkey} className="parent">
                          <div className="column 1 treatmentitem actual">
                            <div className="treatmentcol col1 treatmentcol-05 treatmentselected no-print">
                              <p className="titles">
                                {t(
                                  "system_actionplan.form.plantreatments.titles.selected"
                                )}
                              </p>
                              <Field
                                disabled={
                                  !system["accesses"]?.includes("edit") ?? true
                                }
                                type="checkbox"
                                value={system["actionplan"]["treatments"]
                                  .map((t) => t._id)
                                  .includes(treatment._id)}
                                onChange={(val) => {
                                  toggleTreatmentInPlan(treatment, val);

                                  // if (
                                  //   system["actionplan"]["treatments"].filter(
                                  //     (t) =>
                                  //       t.responsible == undefined ||
                                  //       t.responsible == "" ||
                                  //       t.duetime == undefined ||
                                  //       t.duetime == null ||
                                  //       t.duetime == ""
                                  //   ).length > 0
                                  // ) {
                                  //   setSaveWarningModalShow(true);
                                  // } else {
                                  //   handleSaveSystem();
                                  // }
                                  // handleSaveSystem();
                                }}
                              ></Field>
                            </div>
                            <div
                              className="column 2"
                              onClick={() => {
                                setEditingTreatmentId(treatment._id);
                                setShowTreatmentEditor(true);
                              }}
                            >
                              <div className="treatmentcol col2 treatmentcol-2 treatmentname">
                                <p className="titles">
                                  {t(
                                    "system_actionplan.form.plantreatments.titles.name"
                                  )}
                                </p>
                                <p>&nbsp;{treatment.name}</p>
                              </div>

                              <div className="treatmentcol col3 treatmentcol-1 treatmentcostonce">
                                <p className="titles">
                                  {t(
                                    "system_actionplan.form.plantreatments.titles.costonce"
                                  )}
                                </p>
                                <p>
                                  &nbsp;
                                  {
                                    <CostFormatter>
                                      {treatment.onetimecost != undefined &&
                                      treatment.onetimecost != null &&
                                      treatment.onetimecost != ""
                                        ? treatment.onetimecost
                                        : ""}
                                    </CostFormatter>
                                  }
                                </p>
                              </div>
                              <div className="treatmentcol col4 treatmentcol-1 treatmentcostyearly">
                                <p className="titles">
                                  {t(
                                    "system_actionplan.form.plantreatments.titles.costyearly"
                                  )}
                                </p>
                                <p>
                                  &nbsp;
                                  {
                                    <CostFormatter>
                                      {treatment.cost != undefined &&
                                      treatment.cost != null &&
                                      treatment.cost != ""
                                        ? treatment.cost
                                        : ""}
                                    </CostFormatter>
                                  }
                                </p>
                              </div>
                              <div className="treatmentcol col5 treatmentcol-1 treatmentstatus">
                                <p className="titles">
                                  {t(
                                    "system_actionplan.form.plantreatments.titles.status"
                                  )}
                                </p>
                                <p>
                                  &nbsp;
                                  {treatment["status"] == "open"
                                    ? t("treatment.open")
                                    : ""}
                                  {treatment["status"] == "suggested"
                                    ? t("treatment.suggested")
                                    : ""}
                                  {treatment["status"] == "planned"
                                    ? t("treatment.planned")
                                    : ""}
                                  {treatment["status"] == "processing"
                                    ? t("treatment.processing")
                                    : ""}
                                  {treatment["status"] == "closed"
                                    ? t("treatment.closed")
                                    : ""}
                                </p>
                              </div>
                              <div className="treatmentcol col6 treatmentcol-1 treatmentresponsible">
                                <p className="titles">
                                  {t(
                                    "system_actionplan.form.plantreatments.titles.responsible"
                                  )}
                                </p>
                                <p>
                                  &nbsp;
                                  {users.find(
                                    (u) => u._id == treatment.responsible
                                  )?.name ??
                                    (!treatment.responsible?.includes(" ") &&
                                    treatment.responsible?.length == 24
                                      ? t(
                                          "system_actionplan.form.plantreatments.nouserfound"
                                        )
                                      : treatment.responsible)}
                                </p>
                              </div>
                              <div className="treatmentcol col7 treatmentcol-1 treatmentduedate">
                                <p className="titles">
                                  {t(
                                    "system_actionplan.form.plantreatments.titles.duedate"
                                  )}
                                </p>
                                <p>
                                  &nbsp;
                                  {treatment.duetime != undefined
                                    ? dateToSimpleString(treatment.duetime)
                                    : ""}
                                </p>
                              </div>
                              {/*<div className="treatmentcol treatmentcol-2 treatmentoccurence">
                                                                    <p>{occurences.find(o => treatment.occurence.includes(o._id))?.name ?? ""}</p>
                                                                </div>*/}
                            </div>
                          </div>
                          <div
                            className="column 1 treatmentitem actual"
                            onClick={() => {
                              setEditingTreatmentId(treatment._id);
                              setShowTreatmentEditor(true);
                            }}
                          >
                            <div className="treatmentcol col8 treatmentcol-4 causeconsequencelist">
                              <p className="titles causeconsequencefield">
                                {t(
                                  "system_actionplan.form.plantreatments.titles.causeconsequence"
                                )}
                              </p>
                              <table>
                                <thead>
                                  <tr>
                                    <th>
                                      {t(
                                        "system_actionplan.form.plantreatments.titles.causeconsequenceitem_name"
                                      )}
                                    </th>
                                    <th>
                                      {t(
                                        "system_actionplan.form.plantreatments.titles.causeconsequenceitem_currisk"
                                      )}
                                    </th>
                                    <th>
                                      {t(
                                        "system_actionplan.form.plantreatments.titles.causeconsequenceitem_toberisk"
                                      )}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {causes
                                    .filter((c) =>
                                      treatment.cause.includes(c._id)
                                    )
                                    .map((cause, causekey) => (
                                      <tr
                                        key={causekey}
                                        className="causeconsequenceitem"
                                      >
                                        <td className="name">
                                          <p>&nbsp;{cause.name}</p>
                                        </td>
                                        <td className="currisk">
                                          <p>
                                            &nbsp;
                                            {td(
                                              valueToText(
                                                percentageToValue(
                                                  cause.probability,
                                                  settings["gridY"]
                                                ),
                                                "Y"
                                              )
                                            )}
                                          </p>
                                        </td>
                                        <td className="toberisk">
                                          <p>
                                            &nbsp;
                                            {td(
                                              valueToText(
                                                percentageToValue(
                                                  cause.probability -
                                                    (treatment.probabilityreduction *
                                                      cause.probability) /
                                                      100,
                                                  settings["gridY"]
                                                ),
                                                "Y"
                                              )
                                            )}
                                          </p>
                                        </td>
                                      </tr>
                                    ))}
                                  {consequences
                                    .filter((c) =>
                                      treatment.consequence.includes(c._id)
                                    )
                                    .map((consequence, consequencekey) => (
                                      <tr
                                        key={consequencekey}
                                        className="causeconsequenceitem"
                                      >
                                        <td className="name">
                                          <p>
                                            &nbsp;
                                            {consequence.name != ""
                                              ? consequence.name
                                              : td(
                                                  allConsequenceTypes.find(
                                                    (i) =>
                                                      i._id ==
                                                      consequence.category
                                                  )?.name
                                                ) ?? consequence.category}
                                          </p>
                                        </td>
                                        <td className="currisk">
                                          <p>
                                            &nbsp;
                                            {td(
                                              valueToText(
                                                percentageToValue(
                                                  consequence.consequenceValue,
                                                  settings["gridX"]
                                                ),
                                                "X"
                                              )
                                            )}
                                          </p>
                                        </td>
                                        <td className="toberisk">
                                          <p>
                                            &nbsp;
                                            {td(
                                              valueToText(
                                                percentageToValue(
                                                  consequence.consequenceValue -
                                                    (treatment.probabilityreduction *
                                                      consequence.consequenceValue) /
                                                      100,
                                                  settings["gridX"]
                                                ),
                                                "X"
                                              )
                                            )}
                                          </p>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <Modal
              show={saveWarningModalShow}
              onHide={() => {
                setSaveWarningModalShow(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {t("system_actionplan.form.submitcontrol.title")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{t("system_actionplan.form.submitcontrol.text")}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="cancel posleft"
                  onClick={() => {
                    setSaveWarningModalShow(false);
                  }}
                >
                  {t("system_actionplan.form.submitcontrol.cancelbtn")}
                </Button>
                <Button onClick={() => handleSaveSystem()}>
                  {t("system_actionplan.form.submitcontrol.savebtn")}
                </Button>
              </Modal.Footer>
            </Modal>
            <TreatmentEditorModal
              show={showTreatmentEditor}
              onSave={(treatment) => {
                setShowTreatmentEditor(false);
                //handleSaveSystem();
                let tempSystem = JSON.parse(JSON.stringify(system));
                tempSystem["actionplan"]["treatments"] = tempSystem[
                  "actionplan"
                ]["treatments"].filter((t) => t._id != treatment._id);
                tempSystem["actionplan"]["treatments"].push(treatment);
                setSystem(tempSystem);
                fetchTreatments();
              }}
              onHide={() => {
                setShowTreatmentEditor(false);
                setEditingTreatmentId(undefined);
              }}
              onCancel={() => {
                setShowTreatmentEditor(false);
                setEditingTreatmentId(undefined);
              }}
              treatmentId={editingTreatmentId}
            ></TreatmentEditorModal>
          </React.Fragment>
        </div>
      ) : null}
    </div>
  );
}

export default Actionplan;
